import React, {useEffect, useState} from 'react';
import Card from "react-bootstrap/Card";
import './styles/NavBarCard.css';
import BlueShirt from '../../../assets/images/blue-shirt.png';
import {get} from '../../../Axios/get';
import moment from 'moment-timezone';
import {FOOTBALL} from '../../../Axios/EndPoints';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faChevronRight} from '@fortawesome/free-solid-svg-icons/faChevronRight';
import {Link} from 'react-router-dom';
import {COLORS_RGBA} from '../../../Configs/Targets';
import {getHostname} from '../../../CustomFunctions/getHostname';
import {MATCH_STATES} from "../../../Configs/Constants";

const UpComingMatch = () => {

    const [upComingMatchLoading, setUpComingMatchLoading] = useState(true);
    const [upComingMatchData, setUpComingMatchData] = useState(null);

    const fetchMatches = async () => {

        let fetchData = [];

        let URL = FOOTBALL.HomepageMatchesEndPoint;
        await get(URL)
            .then((res) => {

                setUpComingMatchData(res.data.data);
                setUpComingMatchLoading(false);
            })
            .catch((error) => console.error('error while getting matchesData =', error));

    };
    const hostName = window.location.hostname;
    const domainObject = getHostname(hostName);
    const target = domainObject.host;

    useEffect(() => {
        fetchMatches().then(() => {
        });
    }, [])

    return (
        <>
            {upComingMatchData?.length > 0 &&
                <Card className="navbar-card w-100 rounded-0 p-0"
                      style={{
                          marginTop: '5%',
                          // backgroundImage: `url(${upComingMatchData[0]?.next_match?.venue?.stadium_image})`,
                          backgroundSize: 'cover',
                          background: `linear-gradient(0deg, ${COLORS_RGBA[target] + '0.3)'}, ${COLORS_RGBA[target] + '0.3)'}), url(${upComingMatchData[0]?.next_match?.venue?.stadium_image})`,
                      }}
                >
                    <Card.Body className={'w-100'}>
                        <div className="mb-auto">
                            <Card.Title id={'next-match-title'}
                                        className={'next-match-text-style text-nowrap'}>NEXT
                                MATCH</Card.Title>
                            <Card.Subtitle
                                className="mb-3 mt-1 text-center next-match-date-text-style ps-0">{moment(
                                upComingMatchData[0]?.next_match?.datetime)
                                .format('ddd DD MMM')}</Card.Subtitle>
                            <div className="card-text text-center d-flex justify-content-center align-items-center row">
                                <div className="col-4 text-center d-flex justify-content-end align-items-center">
                                    <div className={'next-match-home-team-text sfProDisplayFont font-14  me-2'}>
                                        {upComingMatchData[0]?.next_match?.home_team?.name}
                                    </div>
                                    <img src={upComingMatchData[0]?.next_match?.home_team?.icon || BlueShirt}
                                         width="29"
                                         height={'29'} style={{objectFit: 'contain'}} alt={'home team icon'}/>
                                </div>
                                <div
                                    className="col-2 text-center d-flex justify-content-center align-items-center w-fit">
                                    <div className={'next-match-center-team-text text-nowrap sfProDisplayFont font-18'}>
                                        {moment(upComingMatchData[0]?.next_match?.datetime).format('HH:mm')}
                                    </div>
                                </div>
                                <div className="col-4 text-center d-flex justify-content-start align-items-center">
                                    <img src={upComingMatchData[0]?.next_match?.guest_team?.icon || BlueShirt}
                                         width="29"
                                         height={'29'} style={{objectFit: 'contain'}} alt={'away team icon'}/>
                                    <div className={'next-match-away-team-text sfProDisplayFont font-14 text-nowrap ms-2'}>
                                        {upComingMatchData[0]?.next_match?.guest_team?.name}
                                    </div>
                                </div>
                            </div>
                            {/*<Card.Text className="text-center d-flex justify-content-center">
                                <p className={'next-match-home-team-text'}>
                                    {upComingMatchData[0]?.next_match?.home_team?.name}
                                </p>
                                <img src={upComingMatchData[0]?.next_match?.home_team?.club_logo || BlueShirt}
                                     width="55"
                                     height={'55'} style={{objectFit: 'contain'}} alt={'home team icon'}/>
                                <p className={'next-match-center-team-text text-nowrap'}>
                                    {moment(upComingMatchData[0]?.next_match?.datetime).format('HH:mm')}
                                </p>
                                <img src={upComingMatchData[0]?.next_match?.guest_team?.club_logo || BlueShirt}
                                     width="55"
                                     height={'55'} style={{objectFit: 'contain'}} alt={'away team icon'}/>
                                <p className={'next-match-away-team-text'}>
                                    {upComingMatchData[0]?.next_match?.guest_team?.name}
                                </p>
                            </Card.Text>*/}
                            <Card.Subtitle
                                className="text-center justify-content-center d-flex"><p
                                className={'pt-2 next-match-league-text'}>{upComingMatchData[0]?.clean_name}</p>
                            </Card.Subtitle>
                        </div>
                    </Card.Body>
                    <Card.Footer
                        className={'w-100 d-flex align-items-end justify-content-end p-0'}>
                        <div
                            className="text-end d-flex justify-content-end align-items-end">
                            <Link
                                to={'/'}
                                // to={`/football/matches/${upComingMatchData[0]?.id}/preview`}
                                className="pe-2 pb-3 d-flex align-items-end text-decoration-none">
                                <p className={'next-match-preview-text'}>
                                    Preview
                                    <span className={'ms-2'}>
                                        <FontAwesomeIcon size={'sm'} beat icon={faChevronRight}/>
                                    </span>
                                </p>
                            </Link>
                        </div>
                    </Card.Footer>
                </Card>
            }
        </>
    );
}

export default UpComingMatch;
