import Loader from '../Loader/Loader';
import {Tab, Tabs} from 'react-bootstrap';
import SingleFootballMatchContentPreview
  from './SubComponentsSingleFootballPage/SingleFootballMatchContentPreview';
import SingleFootballMatchContentH2H
  from './SubComponentsSingleFootballPage/SingleFootballMatchContentH2H';
import SingleFootballMatchContentSocial
  from './SubComponentsSingleFootballPage/SingleFootballMatchContentSocial';
import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useLocation, useParams} from 'react-router-dom';
import {FANZINE} from '../../Axios/EndPoints';
import {get} from '../../Axios/get';
import {setSingleFootballMatch} from '../../redux/actions/FooballLeagueActions';
import {SCREEN_SIZE} from '../../Configs/Constants';

const SingleFootballMatchPreviewMain = () => {
  let store = useSelector((state) => state);
  let dispatch = useDispatch();
  const params = useParams();
  const queryParams = useLocation();
  const [matchData, setMatchData] = useState(null);
  const [matchDataLoading, setMatchDataLoading] = useState(true);
  const [isMobile, setIsMobile] = useState(false);
  const [currentActive, setCurrentActive] = useState(queryParams.pathname.split('/')[4])

  const addDefaultSrc = (ev) => {
    ev.target.src = require("../../assets/images/default-league.png");
  };

  useEffect(() => {
    if (window.innerWidth <= SCREEN_SIZE.MOBILE) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, [window.innerWidth, isMobile]);

  useEffect(() => {
    (store?.football?.singleFootballMatch === [] || matchData === null) && fetchMatchDetails();
    setMatchDataLoading(false);
    return (() => {
    })
  }, [])


  const updateActiveTab = () => {
    if (queryParams.pathname.split('/')[4]) {
      setCurrentActive(queryParams.pathname.split('/')[4])
    } else {
      setCurrentActive('preview')
    }
  }

  useEffect(() => {
    updateActiveTab()
  }, [queryParams.pathname,queryParams])

  const handleURLUpdate = (paramsToAdd) => {
    let newUrl = '';

    if (queryParams.pathname.split('/')[4] === '') {
      newUrl = queryParams.pathname + '/' + paramsToAdd
    } else {
      queryParams.pathname.split('/')[4] = ''
      newUrl = '/' + queryParams.pathname.split('/')[1] + '/' + queryParams.pathname.split('/')[2]
          + '/' + queryParams.pathname.split('/')[3] + '/' + paramsToAdd
    }

    window.history.pushState({}, null, newUrl);
    setCurrentActive(paramsToAdd);

  }

  const fetchMatchDetails = () => {
    let Params = queryParams.search;
    if (!Params) {
      Params = ``;
    }

    get(FANZINE.SingleMatchEndPoint + params.id + FANZINE.MatchesPreviewSubEndPoint)
        .then((response) => {
          // eslint-disable-next-line array-callback-return
          setMatchData(response?.data?.data);
          dispatch(setSingleFootballMatch(response?.data?.data));
          setMatchDataLoading(false);
        })
        .catch((err) => {
          console.error(err);
        });
  };

  const renderPreviewTab = () => {
    return (
        <Tab eventKey="preview" title="Preview">
          <SingleFootballMatchContentPreview isMobile={isMobile}
                                             currentActive={currentActive === '' ? 'preview' : currentActive}
                                             addDefaultSrc={addDefaultSrc}/>
        </Tab>
    )
  }

  const renderH2HTab = () => {
    return (
        <Tab eventKey="h2h" title="H2H">
          <SingleFootballMatchContentH2H isMobile={isMobile}
                                         currentActive={currentActive}
                                         addDefaultSrc={addDefaultSrc}/>
        </Tab>
    )
  }

  const renderSocialTab = () => {
    return (
        <Tab eventKey="social" title="Social" aria-label={'Social'}>
          <SingleFootballMatchContentSocial isMobile={isMobile} currentActive={currentActive}/>
        </Tab>
    )
  }

  const renderPreviewMatchTabs = () => {
    return (
        <Tabs
            defaultActiveKey={currentActive}
            activeKey={currentActive}
            onSelect={(e) => handleURLUpdate(e)}
            id="fill-tab-example"
            transition={false}
            className={`mb-2 font-14 text-black text-decoration-none ${ isMobile && 'd-none'}`}
        >
          {
            renderPreviewTab()
          }
          {
            renderH2HTab()
          }
          {
            renderSocialTab()
          }
        </Tabs>
    )
  }

  return (
      <div>
        <Loader loading={matchDataLoading}/>
        {renderPreviewMatchTabs()}
      </div>
  )

}

export default SingleFootballMatchPreviewMain;
