import {useEffect, useState} from 'react';
import {HomepageStandings} from '../../../Axios/EndPoints';
import {get} from '../../../Axios/get';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faAngleUp} from '@fortawesome/free-solid-svg-icons';
import {faAngleDown} from '@fortawesome/free-solid-svg-icons/faAngleDown';
import ItemLoader from '../../Loader/ItemLoader';
import {
  COLORS, COLORS_RGBA,
  FONT_COLOR,
  NAV_CLASS,
  TEXT_COLOR,
} from '../../../Configs/Targets';
import {getHostname} from '../../../CustomFunctions/getHostname';

const SingleMatchLeagueStandings = (props) => {

  const {singleMatchData, isMobile} = props;

  const [fullTable, setFullTable] = useState(false);
  const [standings, setStanding] = useState(null);
  const [moreStandings, setMoreStanding] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isGroup, setIsGroup] = useState(false);

  useEffect(() => {
    fetchStandingsData();
  }, [singleMatchData]);

  const fetchStandingsData = () => {

    let params = '?league_id=' + singleMatchData?.league_id || '1204';

    get(HomepageStandings + params).then((res) => {
      setStanding(res.data.standings);
    }).catch(error => console.error('error =', error));
  };

  const renderMobilePartialTable = (standingBgColor, textColor, color) => {
    return (
        <>
          <div className={`bebasFont font-weight-800 text-header`}
               style={{fontSize: '30px'}}>
            {singleMatchData?.alt_name || 'English Premier League'}
          </div>
          <div className={`mt-3`}>

          </div>
          <table>
            <thead>
            <tr className={`bg-fixtures rounded-bottom-custom text-center sfProDisplayFont small`}>
              <td></td>
              <td style={{width: '130px'}}>Teams</td>
              <td>P</td>
              <td>W</td>
              <td>D</td>
              <td>L</td>
              <td>F/A</td>
              <td>GD</td>
              <td>Pts</td>
            </tr>
            </thead>
            <tbody>
            {standings?.map((value) => (
                <>
                  <tr className={`text-light-black sfProDisplayFont small`}
                      style={{backgroundColor: standingBgColor}}>
                    <td className={`text-center`}><span
                        className={'sfProDisplayFont'}>{value.position}. &nbsp;</span>
                    </td>
                    <td className={`p-2`}>
                      <img src={value.team.icon} height={30} alt=""/>
                      <span
                          className={'sfProDisplayFont ms-2'}>&nbsp; {value.team.short_name}</span>
                    </td>
                    <td className={`text-center`}>{value.pl}</td>
                    <td className={`text-center`}>{value.win}</td>
                    <td className={`text-center`}>{value.draw}</td>
                    <td className={`text-center`}>{value.lose}</td>
                    <td className={`text-center`}>{value.gd2}</td>
                    <td className={`text-center`}>{value.gd}</td>
                    <td className={`text-center`}>{value.pts}</td>
                  </tr>
                </>
            ))}
            </tbody>
          </table>
          {moreStandings?.length &&
              <li className={`p-3 bg-white text-end sfProDisplayFontBold ${textColor} rounded-bottom-custom btn`}
                  onClick={() => {
                    setFullTable(!fullTable);
                  }}
              >
                {fullTable ? 'Collapse' : 'Full Table'} &nbsp;&nbsp;&nbsp;
                <FontAwesomeIcon icon={fullTable ? faAngleUp : faAngleDown}
                                 color={color} size={'1x'}/>
              </li>}
        </>
    );
  };

  const renderMobileFullTable = (standingBgColor, textColor, color) => {
    return (
        <>
          <div className={`bebasFont font-weight-800 text-header`}
               style={{fontSize: '30px'}}>
            {singleMatchData?.alt_name || 'English Premier League'}
          </div>
          <div className={`mt-3`}>

          </div>
          <table>
            <thead>
            <tr className={`bg-fixtures rounded-bottom-custom text-center sfProDisplayFont small`}>
              <td></td>
              <td style={{width: '130px'}}>Teams</td>
              <td>P</td>
              <td>W</td>
              <td>D</td>
              <td>L</td>
              <td>F/A</td>
              <td>GD</td>
              <td>Pts</td>
            </tr>
            </thead>
            <tbody>
            {moreStandings?.length &&
                moreStandings?.map(((value, index, array) => (
                    <tr className={`text-light-black sfProDisplayFont small`}
                        style={value.background}>
                      <td className={`ps-1 text-center`}><span
                          className={'sfProDisplayFont'}>{value.position}. &nbsp;</span>
                      </td>
                      <td className={`p-2`}>
                        <img src={value.team.icon} height={20}
                             alt=""/>
                        <span className={'sfProDisplayFont ms-2'}>
                                                        &nbsp; {value.team.short_name}
                                                    </span>
                      </td>
                      <td className={`text-center`}>{value.pl}</td>
                      <td className={`text-center`}>{value.win}</td>
                      <td className={`text-center`}>{value.draw}</td>
                      <td className={`text-center`}>{value.lose}</td>
                      <td className={`text-center`}>{value.gd2}</td>
                      <td className={`text-center`}>{value.gd}</td>
                      <td className={`text-center`}>{value.pts}</td>
                    </tr>
                )))}

            </tbody>
          </table>
          {moreStandings?.length &&
              <li className={`p-3 bg-white text-end sfProDisplayFontBold ${textColor} rounded-bottom-custom btn`}
                  onClick={() => {
                    setFullTable(!fullTable);
                    window.scroll({
                      top: 1850,
                      behavior: 'smooth',
                    });
                  }}
              >
                {fullTable ? 'Collapse' : 'See full table'} &nbsp;&nbsp;&nbsp;
                <FontAwesomeIcon icon={fullTable ? faAngleUp : faAngleDown}
                                 color={color}
                                 size={'1x'}/>
              </li>}
          {/**/}
        </>
    );
  };

  const renderLaptop = (
      standingBgColor, textColor, color, navColor, fontColor, Value) => {
    return (
        <>
          <div className="col-12 border-1 border-secondary rounded">
            <div className={` rounded-custom-1 text-center p-1`}>
                        <span className={`${fontColor} text-header`}>
                            <span
                                className={'sfProDisplayFont font-16 text-black'}
                                style={{fontWeight: '600'}}
                            >
                                        {Value[0]?.group ?
                                            'Group ' + Value[0]?.group :
                                            singleMatchData?.alt_name +
                                            ' Table' ||
                                            'English Premier League Table'
                                        }
                            </span>
                          {/*To DO add Later
                            <FontAwesomeIcon icon={faSortDown} color={'#FFFFFF'} size={'1x'} style={{verticalAlign: '0'}}/>*/}
                        </span>
            </div>
            <ul className={'bg-fixtures rounded-bottom-custom text-center standings-list list-unstyled p-0'}>
              <ItemLoader loading={loading}/>
              <li className={'bg-white p-2'}>
                <div className={'row p-1'}>
                  <div
                      className={'col-3 ms-2 text-end text-light-black sfProDisplayFont text-nowrap font-12'}>
                    Teams
                  </div>
                  <div
                      className={'col text-end text-light-black sfProDisplayFont font-12 ms-4'}>
                  </div>
                  <div
                      className={'col text-end text-light-black sfProDisplayFont font-12 m-0 p-0'}>P
                  </div>
                  <div
                      className={'col text-end text-light-black sfProDisplayFont font-12 m-0 p-0'}>GD
                  </div>
                  <div
                      className={'col text-end text-light-black sfProDisplayFontBold font-12 m-0 ps-0'}>Pts
                  </div>
                </div>
              </li>
              {
                (Value || standings)?.map(((value, index, array) => {
                  let bgColor = '';
                  if (value?.team?.id === singleMatchData?.home_team?.id) {
                    bgColor = singleMatchData?.home_team?.hex_color;
                  }

                  if (value?.team?.id === singleMatchData?.guest_team?.id) {
                    bgColor = singleMatchData?.guest_team?.hex_color;
                  }

                  return (
                      <li key={index}
                          className={` ${index + 1 ===
                          (Value || standings).length ?
                              'rounded-bottom-custom' :
                              ''} ${bgColor === '' && 'bg-white'} p-2`}
                          style={{
                            background: bgColor !== '' &&
                                value?.team?.hex_color,
                            color: bgColor !== '' && value?.team?.font_color,
                          }}
                      >
                        <div className={'row p-1'}>
                          <div
                              className={`col-4 ms-2 ${bgColor === '' &&
                              'text-dark'} text-start text-nowrap font-12 ps-1`}>
                            {value?.position}. &nbsp;
                            <img
                                className={'ms-2'}
                                width={27}
                                style={{objectFit:'contain'}}

                                src={value.team.icon} height={20} alt=""/>
                            <span className={'sfProDisplayFont'}
                                  style={{fontWeight: '500'}}
                            >
                                                        &nbsp; {value?.team.name}
                                                    </span>
                          </div>
                          <div className={'col text-end text-light-black '}>
                            {() => {
                              if (value.arrow === 'up') {
                                return (<FontAwesomeIcon icon={faAngleUp}
                                                         color={'#0e7d45'}
                                                         size={'1x'}/>);
                              }
                              if (value.arrow === 'down') {
                                return (
                                    <FontAwesomeIcon icon={faAngleDown}
                                                     color={'#e02020'}
                                                     size={'1x'}/>);
                              }
                            }}
                          </div>
                          <div
                              className={`col text-end ${bgColor === '' &&
                              'text-light-black'} sfProDisplayFont font-12 m-0 p-0`}>{value?.pl}</div>
                          <div
                              className={`col text-end ${bgColor === '' &&
                              'text-light-black'} sfProDisplayFont font-12 m-0 p-0`}>{value?.gd}</div>
                          <div
                              className={`col text-end ${bgColor === '' &&
                              'text-light-black'} sfProDisplayFontBold font-12 m-0 ps-0`}>{value?.pts}</div>
                        </div>
                      </li>
                  );
                }))
              }
              {
                  moreStandings?.length &&
                  moreStandings?.map(((value, index, array) => {
                    return (
                        <li className={`p-3 ${fullTable ? '' : 'd-none'}`}>
                          <div className={'row'}>
                            <div
                                className={'col-4 text-start text-dark text-nowrap font-14'}>
                              <span
                                  className={'sfProDisplayFont'}>{value.position}. &nbsp;</span>
                              <img src={value.team.icon} height={20} alt=""/>
                              <span className={'sfProDisplayFont'}>
                                                &nbsp; {value.team.name}
                                            </span>
                            </div>
                            <div className={'col text-end text-light-black'}>
                              {() => {
                                if (value.arrow === 'up') {
                                  return (<FontAwesomeIcon icon={faAngleUp}
                                                           color={'#0e7d45'}
                                                           size={'1x'}/>);
                                }
                                if (value.arrow === 'down') {
                                  return (
                                      <FontAwesomeIcon icon={faAngleDown}
                                                       color={'#e02020'}
                                                       size={'1x'}/>);
                                }
                              }}
                            </div>
                            <div
                                className={'col text-end text-light-black sfProDisplayFont font-14'}>{value.mp}</div>
                            <div
                                className={'col text-end text-light-black sfProDisplayFont font-14'}>{value.gd}</div>
                            <div
                                className={'col text-end text-light-black sfProDisplayFont font-14'}>{value.pts}</div>
                          </div>
                        </li>
                    );
                  }))
              }
              {/*{moreStandings.length &&
                            <li className={`p-3 bg-white text-center sfProDisplayFontBold ${textColor} rounded-bottom-custom btn`}
                                onClick={() => {
                                    setFullTable(!fullTable)
                                }}
                            >
                                {fullTable ? 'Collapse' : 'See full table'} &nbsp;&nbsp;&nbsp;
                                <FontAwesomeIcon icon={fullTable ? faAngleUp : faAngleDown} color={color}
                                                 size={'1x'}/>
                            </li>}*/}
            </ul>
          </div>
        </>
    );
  };

  const withData = () => {
    const hostName = window.location.hostname;
    const domainObject = getHostname(hostName);
    const target = domainObject.host;
    const navColor = NAV_CLASS[target];
    const textColor = TEXT_COLOR[target];
    const fontColor = FONT_COLOR[target];
    const color = COLORS[target];
    const standingBgColor = COLORS_RGBA[target] + '0.3)';

    let isGroup = standings && standings[0]?.group !== '';
    let tmp = [];
    if (standings !== null && standings !== undefined) {
      if (standings && isGroup) {
        let arr = [];
        let allGroups = standings.map((item)=>item?.group) //gets all groups if any

        for (let i = 0; i < standings?.length; i++) {
          if (standings[i]?.group === allGroups[i]) {
            arr.push(standings[i]);
            if (arr.length === 4) {
              tmp.push(arr);
              arr = [];
            }
          }
        }
      } else if (standings && !isGroup) {
        let arr =[]
        for (let i = 0; i < standings?.length; i++) {

          arr.push(standings[i]);
        }
        tmp.push(arr)
      }

    }

    const renderDefault = () => {
      return (
          isMobile ?
              !fullTable && standings ?
                  renderMobilePartialTable(standingBgColor, textColor, color) :
                  renderMobileFullTable(standingBgColor, textColor, color) :
              renderLaptop(standingBgColor, textColor, color, navColor,
                  fontColor)
      );
    };

    const renderGroups = () => {
      return (
          standings && tmp.map((value, index) => (
              <>
                {renderLaptop(standingBgColor, textColor, color, navColor,
                    fontColor, value)
                }
              </>
          ))
      );
    };

    return (
        <>
          {tmp === [] && renderDefault()}
          {tmp !== [] && renderGroups()}
        </>
    );
  };

  const render = () => {
    return (
        <>
          {
            standings === null ? 'getting data..' :
                withData()}
        </>
    );
  };

  return render();

};

export default SingleMatchLeagueStandings;
