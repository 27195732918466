import {Col, FormControl, FormLabel, Row} from "react-bootstrap";
import {useEffect, useState} from "react";
import '../assets/styles/fanzine.css';
import Loader from "../Components/Loader/Loader";
import {post} from "../Axios/post";
import {ContactUsEndpoint} from "../Axios/EndPoints";
import {SCREEN_SIZE} from "../Configs/Constants";
import validator from "validator/es";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEnvelope, faMapMarkerAlt} from "@fortawesome/free-solid-svg-icons";

const ContactUs = () => {

    let [loading, setLoading] = useState(false);
    let [name, setName] = useState('');
    let [email, setEmail] = useState('');
    let [phone, setPhone] = useState('');
    let [subject, setSubject] = useState('');
    let [message, setMessage] = useState('');
    let [isMobile, setIsMobile] = useState('');

    const [errorName, setErrorName] = useState(false);
    const [errorEmail, setErrorEmail] = useState(false);
    const [errorPhone, setErrorPhone] = useState(false);
    const [errorSubject, setErrorSubject] = useState(false);

    function onSubmit() {
        let canBeSubmitted = true;

        if (name === '') {
            canBeSubmitted = false;
            setErrorName(true);
        }
        if (email === '' || !validator.isEmail(email)) {
            canBeSubmitted = false;
            setErrorEmail(true);
        }
        if (phone === '' || !validator.isMobilePhone(phone)) {
            canBeSubmitted = false;
            setErrorPhone(true);
        }
        if (subject === '') {
            canBeSubmitted = false;
            setErrorSubject(true);
        }

        if (canBeSubmitted) {
            setLoading(true);
            let formData = {
                to: ['support@fanzine.com', 'junaid@codestudio.com.pk',],
                name: name,
                phone: phone,
                email: email,
                subject: subject,
                body: message,
            };

            post(ContactUsEndpoint, formData).then((response) => {
                setName('');
                setEmail('');
                setPhone('');
                setSubject('');
                setMessage('');
                setLoading(false);
            });
        }
    }

    useEffect(() => {
        // Add reCaptcha
        const script = document.createElement("script")
        script.src = "https://www.google.com/recaptcha/api.js?render=6LfWvMAhAAAAAPr6XLLaX05ZmCrmMtAJhkZXpC2z"
        // script.src = "https://www.google.com/recaptcha/api.js"
        // script.addEventListener("load", handleLoaded)
        document.body.appendChild(script)
    }, [])

    useEffect(() => {

    }, []);

    useEffect(() => {
        if (window.screen.width <= SCREEN_SIZE.MOBILE) {
            setIsMobile(true);
        } else {
            setIsMobile(false);
        }
    }, [window.screen.width, isMobile]);

    const render = () => {
        return (
            <>
                <div
                    style={{backgroundRepeat:'round'}}
                    className={`contact-bottom mt-n3 pt-5`}>
                    <div className={`container-fluid`}>
                        <div className={`${!isMobile ? 'row' : ''} mt-n3 pt-5`}>
                            <div className={`col-md-6 col-sm-12 offset-md-2 offset-sm-0 font-24 sfProDisplayFontBold text-white`}>
                                <div
                                    className={`text-start font-48 prometoFont text-white flex-column justify-content-center d-flex`}>
                                    Get in Touch
                                </div>
                                <div className={`container`}>
                                    <Loader loading={loading}/>
                                    <form className={`p-3 pb-5`}>
                                        <Row className={`mt-3`}>
                                            <Col>
                                                <FormLabel>Name</FormLabel>
                                                <FormControl
                                                    isInvalid={errorName}
                                                    type="text"
                                                    id="name"
                                                    aria-describedby="name"
                                                    placeholder="Name"
                                                    className={`contact-us-text-input mb-3`}
                                                    value={name}
                                                    onChange={(e) => {
                                                        setErrorName(false);
                                                        setName(e.target.value);
                                                    }}
                                                />
                                                <FormControl.Feedback type={'invalid'} className={`mb-4`}>Please input name</FormControl.Feedback>
                                            </Col>
                                            <Col>
                                                <FormLabel>Email</FormLabel>
                                                <FormControl
                                                    isInvalid={errorEmail}
                                                    type="email"
                                                    id="email"
                                                    aria-describedby="email"
                                                    placeholder="Email"
                                                    className={`contact-us-text-input mb-3`}
                                                    value={email}
                                                    onChange={(e) => {
                                                        setErrorEmail(false);
                                                        setEmail(e.target.value);
                                                    }}
                                                />
                                                <FormControl.Feedback type={'invalid'} className={`mb-4`}>Please input a valid email</FormControl.Feedback>
                                            </Col>
                                        </Row>
                                        <Row className={`mt-3`}>
                                            <Col>
                                                <FormLabel>Telephone</FormLabel>
                                                <FormControl
                                                    isInvalid={errorPhone}
                                                    type="number"
                                                    id="phone"
                                                    aria-describedby="phone"
                                                    placeholder="Telephone"
                                                    className={`contact-us-text-input mb-3`}
                                                    value={phone}
                                                    onChange={(e) => {
                                                        setErrorPhone(false);
                                                        setPhone(e.target.value);
                                                    }}
                                                />
                                                <FormControl.Feedback type={'invalid'} className={`mb-4`}>Please input a valid phone number</FormControl.Feedback>
                                            </Col>
                                        </Row>
                                        <Row className={`mt-3`}>
                                            <Col>
                                                <FormLabel>Subject</FormLabel>
                                                <FormControl
                                                    isInvalid={errorSubject}
                                                    id="subject"
                                                    aria-describedby="subject"
                                                    placeholder="Subject"
                                                    className={`contact-us-text-input mb-3`}
                                                    value={subject}
                                                    onChange={(e) => {
                                                        setErrorSubject(false);
                                                        setSubject(e.target.value);
                                                    }}
                                                />
                                                <FormControl.Feedback type={'invalid'} className={`mb-4`}>Please input subject</FormControl.Feedback>
                                            </Col>
                                        </Row>
                                        <Row className={`mt-3`}>
                                            <Col>
                                                <label>
                                                    Message
                                                </label>
                                                <textarea className={`form-control`} rows={10} value={message} onChange={(e) => {
                                                    setMessage(e.target.value)
                                                }}>{message}</textarea>
                                            </Col>
                                        </Row>
                                        <Row className={`mt-3`}>
                                            <Col>
                                                <button type={"button"} className={`btn float-start text-black btn-lg ps-5 pe-5 pt-3 pb-3`} style={{backgroundColor: '#74dbef'}} onClick={onSubmit}>
                                                    Submit
                                                </button>
                                            </Col>
                                            <Col>
                                                <div
                                                    className="g-recaptcha float-end"
                                                    data-sitekey="6LfWvMAhAAAAAPr6XLLaX05ZmCrmMtAJhkZXpC2z"
                                                    data-size="invisible"
                                                >
                                                </div>
                                            </Col>
                                        </Row>
                                    </form>
                                </div>
                            </div>
                            <div className={`${isMobile ? 'container ps-4 pb-3' : 'col-md-4'} text-white text-start pt-5 mt-5`}>
                                <div className={`sfProDisplayFontBold font-18 mt-3 mb-5 d-flex `}>
                                    <FontAwesomeIcon icon={faEnvelope} color={'#74dbef'} size={'2x'}/>
                                    <a href={'mailto:support@fanzine.com'} className={'ms-5'}>support@fanzine.com</a>
                                </div>
                                <div className={`sfProDisplayFont font-18 mt-3 mb-5 d-flex`}>
                                    <FontAwesomeIcon icon={faMapMarkerAlt} color={'#74dbef'} size={'2x'} className={'align-middle justify-content-center'}/>
                                    <span className={'ms-5'}>
                                        Fanzine
                                        <br/>
                                        85 Great Portland Street,
                                        <br/>
                                        London,
                                        <br/>
                                         W1W 7LT
                                        <br/>
                                        United Kingdom
                                    </span>
                                </div>
                                <iframe
                                        src={"https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d631.3417109816689!2d-0.1427710715411954!3d51.518380386077006!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48761ad59639f895%3A0x920103fa75a677ef!2sGreat%20Portland%20St%2C%20London%20W1W%207LT%2C%20UK!5e1!3m2!1sen!2s!4v1673866310512!5m2!1sen!2s"}
                                        width="370" height="260" className={'border rounded'} allowFullScreen="" loading="lazy"/>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
            ;
    }

    return render();
}

export default ContactUs;
