import {useEffect, useState} from "react";
import CalendarContainerItem from "./CalendarContainerItem";
import moment from "moment";
import {RiArrowLeftSLine, RiArrowRightSLine} from "react-icons/ri";
import {Link, useParams, useSearchParams} from 'react-router-dom';

const CalendarContainer = (props) => {
    const {selectedDay, isMobile, sport,isFanzineClubMatches,team} = props;

    const [dateCollection, setDateCollection] = useState("");
    const [searchParams,] = useSearchParams();
    const [selectedDate, setSelectedDate] = useState(sport === 'nfl' ? searchParams.get('week') : searchParams.get('date') || props.selectedDate)
    const [isWeek, setIsWeek] = useState(sport === 'nfl');

    const updateDateCollection = (numberOfDays, clickType) => {
        let tempCollection = [];

        // console.log('selected date in calendar item =', props.selectedDate,searchParams.get('date'))
        if (clickType === "left") {
            //   console.log("clickType", clickType);
            let firstDate = moment(selectedDate);
            if (dateCollection.length > 0) {
                firstDate = dateCollection[0];
            }
            for (let i = 1; i > 0; i--) {
                tempCollection.push(moment(firstDate).subtract(i, "d")._d);
            }
            // console.log("tempCollection =", tempCollection);
            dateCollection.pop();
            setDateCollection((dateCollection) => [
                tempCollection[0],
                ...dateCollection,
            ]);
            itemClicked(moment(selectedDate).subtract(1, 'days'));
        } else if (clickType === "right") {
            let lastDate = moment(selectedDate);
            if (dateCollection.length > 0) {
                lastDate = dateCollection[dateCollection.length - 1];
            }
            for (let i = 1; i < 2; i++) {
                tempCollection.push(moment(lastDate).add(i, "d")._d);
            }
            dateCollection.shift();
            setDateCollection((dateCollection) => [
                ...dateCollection,
                tempCollection[0],
            ]);
            itemClicked(moment(selectedDate).add(1, 'days'));
        }
        // console.log("selectedDate =", moment(selectedDate).format("YYYY-MM-DD"));

        // pushes the number of days minus current date
        if (!clickType) {
            for (let i = numberOfDays; i > 0; i--) {
                tempCollection.push(moment(selectedDate || Date.now()).subtract(i, "d")._d);
            }

            // pushes the current date
            tempCollection.push(moment(selectedDate || Date.now())._d);

            // pushes the number of days after current date
            for (let i = 1; i <= numberOfDays; i++) {
                tempCollection.push(moment(selectedDate || Date.now()).add(i, "d")._d);
            }

            setDateCollection(...dateCollection, tempCollection);
        }
    };


    const itemClicked = (e) => {
        selectedDay(e);
        setSelectedDate(e)
        localStorage.setItem('selectedDate', e)
    };

    useEffect(() => {
        if (sport === 'nfl') {
            setIsWeek(true);
            setSelectedDate(searchParams.get('week') || props.selectedDate)
        }

        if (dateCollection === "") {
            // enter number of days to have a range in calendar
            // if 2 selected the range will be from (currentDate - 2 to currentDate + 2)
            updateDateCollection(2);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.selectedDate, searchParams.get('date'), searchParams.get('week')]);

    return (
        <>
            <div
                style={{
                    // padding: isMobile && 10,
                    marginTop: isMobile && 10,
                    position: isMobile && 'fixed',
                }}
                className={`container ${isMobile ? 'bg-black pt-4' : 'bg-white p-4'} `}>
                {/*{moment((selectedDate || new Date())).format('MMMM')}*/}
                <div className={`row ${(isMobile && team) ? 'pt-5 mt-2' : isMobile ? 'pt-2 mt-2 ' : ''}`}>
                    <Link
                        className={`col-1 d-flex align-items-center justify-content-start text-black`}
                        style={{cursor: 'pointer'}}
                        onClick={() => {
                            updateDateCollection(0, "left");
                        }}
                        to={
                            isFanzineClubMatches ?
                                `/${sport}/${team}/matches?date=${moment(selectedDate).subtract(1, 'days').format("YYYY-MM-DD")}`
                                :
                        `/${sport}/matches?date=${moment(selectedDate).subtract(1, 'days').format("YYYY-MM-DD")}`}
                    >
                        <div className={'bg-background'}
                             style={{borderRadius: '50%'}}
                        >
                            <RiArrowLeftSLine size={isMobile ? 20 : 30} style={{margin: isMobile ? "2px" : "5px"}}/>
                        </div>
                    </Link>
                    <div className="col-10">
                        <div className={`${isMobile ? 'slider-mobile-news-container' : "row"}`}>
                            {dateCollection != "" &&
                                dateCollection.map((value, index) => (
                                    <CalendarContainerItem
                                        key={index}
                                        date={value}
                                        isFanzineClubMatches={isFanzineClubMatches}
                                        team={team}
                                        selectedDate={selectedDate}
                                        setSelectedDate={setSelectedDate}
                                        itemClicked={itemClicked}
                                        isMobile={isMobile}
                                        sport={sport}
                                    />
                                ))}
                        </div>
                    </div>
                    <Link
                        className="col-1 d-flex align-items-center justify-content-end text-black"
                        style={{cursor: 'pointer'}}
                        onClick={() => {
                            updateDateCollection(0, "right");
                        }}
                        to={
                        isFanzineClubMatches ?
                            `/${sport}/${team}/matches?date=${moment(selectedDate).add(1, 'days').format("YYYY-MM-DD")}`
                            :
                        `/${sport}/matches?date=${moment(selectedDate).add(1, 'days').format("YYYY-MM-DD")}`}>
                        <div className={'bg-background'}
                             style={{borderRadius: '50%'}}
                        >
                            <RiArrowRightSLine size={isMobile ? 20 : 30} style={{margin: isMobile ? "2px" : "5px"}}/>
                        </div>
                    </Link>
                </div>
            </div>
        </>
    );
};

export default CalendarContainer;
