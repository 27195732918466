import {AiOutlineLeft, AiOutlineRight} from 'react-icons/ai';
import React, {useEffect, useRef, useState} from 'react';
import {get} from '../../Axios/get';
import {CRICKET, FBS, FOOTBALL, MLB, NBA, NCAA, NFL} from '../../Axios/EndPoints';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '../../assets/styles/fanzineMatchSlider.css';
import '../../assets/slider/TopMatchesSlider.css';
import moment from 'moment-timezone';
import TopMatchSliderItem from './TopMatchSliderItem';
import {useLocation, useSearchParams} from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import {getHostname} from '../../CustomFunctions/getHostname';
import {COLORS, FONT_COLOR, NAV_CLASS, TEXT_COLOR} from '../../Configs/Targets';

const TopMatchesSlider = (props) => {
  const {isMobile, teamData} = props;
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const [matchesLoading, setMatchesLoading] = useState(true);
  const [matchesData, setMatchesData] = useState([]);
  const [currentDisplayDate, setCurrentDisplayDate] = useState();
  let [selectedSport, setSelectedSport] = useState('');
  const slider = useRef(null);
  const [todayMatchSlideIndex, setTodayMatchSlideIndex] = useState(1);
  const [sliderCurrentState, setSliderCurrentDate] = useState(1);

  const fetchMatches = async () => {
    let id = (searchParams.get('team_id') || searchParams.get('league_id') ||
        teamData?.clubId ||
        teamData?.id || '');
    let parameters = searchParams.get('league_id') ?
        '?league_id=' + id :
        '?team_id=' + id;

    let URL = '';

    switch (location.pathname.split('/')[1]) {
      case 'football':
        URL += FOOTBALL.HomepageMatchesEndPoint;
        selectedSport = 'football';
        break;
      case 'cricket':
        URL += CRICKET.HomepageMatchesEndPoint;
        selectedSport = 'cricket';
        break;
      case 'nfl':
        URL += NFL.HomepageMatchesEndPoint;
        selectedSport = 'nfl';
        break;
      case 'nba':
        URL += NBA.HomepageMatchesEndPoint;
        selectedSport = 'nba';
        break;
      case 'ncaam':
        URL += NCAA.HomepageMatchesEndPoint;
        selectedSport = 'ncaam';
        break;
      case 'ncaaf':
        URL += FBS.HomepageMatchesEndPoint;
        selectedSport = 'ncaaf';
        break;
      case 'mlb':
        URL += MLB.HomepageMatchesEndPoint;
        selectedSport = 'mlb';
        break;
      default:
        URL += FOOTBALL.HomepageMatchesEndPoint;
        selectedSport = 'football';
        break;

    }
    if (id !== '') {
      URL += parameters;
    }
    await get(URL)
        .then((res) => {
          let fetchData = [];
          switch (location.pathname.split('/')[1]) {
            case 'football':
              Object.values((res.data.data[0]?.matches ?
                  res.data.data[0]?.matches :
                  res.data.data)).map((value) => {
                fetchData.push(value);
              });
              break;
            case 'cricket':
              Object.values((res.data.data[0]?.matches ?
                  res.data.data[0]?.matches :
                  res.data.data)).map((value) => {
                fetchData.push(value);
              });
              break;
            case 'nfl':
              Object.values((res.data.data?.matches ?
                  res.data.data?.matches :
                  res.data.data)).map((value) => {
                fetchData.push(value);
              });
              break;
            case 'nba':
              Object.values((res.data.data[0]?.matches ?
                  res.data.data[0]?.matches :
                  res.data.data)).map((value) => {
                fetchData.push(value);
              });
              break;
            case 'ncaa':
              Object.values((res.data.data[0]?.matches ?
                  res.data.data[0]?.matches :
                  res.data.data)).map((value) => {
                fetchData.push(value);
              });
              break;
            case 'mlb':
              Object.values((res.data.data[0]?.matches ?
                  res.data.data[0]?.matches :
                  res.data.data)).map((value) => {
                fetchData.push(value);
              });
              break;
            default:
              Object.values((res.data.data[0]?.matches ?
                  res.data.data[0]?.matches :
                  res.data.data)).map((value) => {
                fetchData.push(value);
              });
              break;
          }
          fetchData.sort((a, b) => moment(a.datetime).diff(moment(b.datetime)));
          let tmpIndex = null;
          let prevDays = null;
          let nextDays = null;
          const currentDate = moment(new Date()).format('YYYY-MM-DD');
          fetchData.map((value, index) => {
            const dataDate = moment(new Date(value?.date)).format('YYYY-MM-DD');

            if (moment(currentDate).diff(moment(dataDate), 'days') === 0) {
              tmpIndex = index;
            } else {
              if (moment(currentDate).diff(moment(dataDate), 'days') > 0 &&
                  prevDays === null) {
                prevDays = {
                  index: index,
                  value: dataDate,
                  diff: moment(currentDate).diff(moment(dataDate), 'days'),
                };

              } else if (moment(currentDate).diff(moment(dataDate), 'days') <
                  0 && nextDays === null) {

                nextDays = {
                  index: index,
                  value: dataDate,
                  diff: moment(currentDate).diff(moment(dataDate), 'days'),
                };
              }
            }

            if (nextDays?.diff < prevDays?.diff) {
              tmpIndex = nextDays?.index;
            } else if (prevDays?.diff < nextDays?.diff) {
              tmpIndex = prevDays?.index;
            } else {
              tmpIndex = 0;
            }

            // if(currentDate === dataDate && tmpIndex === null)
            // {
            //     tmpIndex = index;
            //     console.log('index=',index)
            // }
          });
          setTodayMatchSlideIndex(tmpIndex === null ? 0 : tmpIndex);
          setCurrentDisplayDate(fetchData[tmpIndex === null ? 0 : tmpIndex]);
          setMatchesData(fetchData);
          setMatchesLoading(false);
        })
        .catch((error) => console.error('error while getting matchesData =',
            error));

  };

  useEffect(() => {
    setMatchesLoading(true);
    fetchMatches();
  }, [location.pathname, teamData]);

  useEffect(() => {

  }, [currentDisplayDate]);

  const customArrows = (target) => {
    return (
        <div className=" slider-arrow">
          {customLeftArrow(target)}
          {customRightArrow(target)}
        </div>
    );
  };

  const customLeftArrow = (target) => {

    const handlePrevSlide = () => {
      (7 <= (sliderCurrentState + 7)) &&
      slider?.current?.slickPrev()    };

    return (
        <div className="slider-arrow left shadow">
          <div
              className="arrow-btn prev d-flex"
              onClick={handlePrevSlide}
          >
            <div
                style={{
                  borderRight: `15px solid ${COLORS[target]}`,
                  cursor: 'pointer',
                }}
                className={`d-flex ${target === 'fanzine' ?
                    'text-white' :
                    FONT_COLOR[target]} justify-content-center align-items-center ${target ===
                'fanzine' ? 'bg-black' : NAV_CLASS[target] + '-dark'}`}>
              <AiOutlineLeft
                  className={['theyellows', 'wearewolves'].includes(target) ?
                      TEXT_COLOR[target] :
                      FONT_COLOR[target]}/>
            </div>
            <div
                className={`row container pt-1 ps-2 robotoCondensedBold text-center ${target ===
                'fanzine' ? 'text-black' : FONT_COLOR[target]} justify-content-center 
						${target === 'fanzine' ? 'bg-fanzine-dark' : NAV_CLASS[target]}`}
            >
              <div className={'col-12'}>
                <h6 className={`robotoCondensedBold ${target === 'fanzine' ?
                    'text-black' :
                    FONT_COLOR[target]} ${isMobile ?
                    'font-9' :
                    'font-14'}`}>
                  {moment(
                      moment(currentDisplayDate?.datetime).format('YYYY-MM-DD'))
                      .format('ddd') || 'Mon'}
                </h6>
              </div>
              <div className={'col-12'}>
                <h3 className={`robotoCondensedBold ${target === 'fanzine' ?
                    'text-black' :
                    FONT_COLOR[target]} ${isMobile ?
                    'font-11' :
                    'font-30'}`}>
                  {moment(
                      moment(currentDisplayDate?.datetime).format('YYYY-MM-DD'))
                      .format('DD') || '26'}
                </h3>
              </div>
              <div className={'col-12'}>
                <h6 className={`robotoCondensedBold ${target === 'fanzine' ?
                    'text-black' :
                    FONT_COLOR[target]} ${isMobile ?
                    'font-9' :
                    'font-14'}`}>
                  {moment(
                      moment(currentDisplayDate?.datetime).format('YYYY-MM-DD'))
                      .format('MMM') || 'Dec'}
                </h6>
              </div>
            </div>
          </div>
        </div>
    );
  };
  const customRightArrow = (target) => {

    const handleNextSlide = () => {
      (sliderCurrentState + 7) < matchesData?.length
     &&
      slider?.current?.slickNext();

    };

    return (<div
        className="shadow arrow-btn next d-flex"
        onClick={handleNextSlide}
    >
      <div
          style={{
            borderLeft: `4px solid ${COLORS[target]}`,
            cursor: 'pointer',
            zIndex: 9,
          }}
          className={`d-flex ${target === 'fanzine' ?
              'text-white' :
              FONT_COLOR[target]} justify-content-center align-items-center 
				${target === 'fanzine' ? 'bg-black' : NAV_CLASS[target] + '-dark'}`}>
        <AiOutlineRight
            className={['theyellows', 'wearewolves'].includes(target) ?
                TEXT_COLOR[target] :
                FONT_COLOR[target]}/>
      </div>
    </div>);
  };

  const container = () => {
    const hostName = window.location.hostname;
    const domainObject = getHostname(hostName);
    const target = domainObject.host;

    let settings = {
      infinite: false,
      dots: false,
      draggable: true,
      useCSS: true,
      initialSlide: todayMatchSlideIndex || 1,
      slidesToShow: isMobile ? 2.5 : (matchesData.length < 7 ? matchesData.length : 7),
      cssEase: 'linear',
      // centerPadding: '50',
      arrows: false,
      slidesToScroll: 1,
      lazyLoad: false,
      autoplay: false,
      afterChange: state => {
        setSliderCurrentDate(state);
        setCurrentDisplayDate(matchesData[state]);
      },
      className: 'shadow slick-track',
      variableWidth: false,
      variableHeight: false,
    };

    let currDate = null;
    let displayArray = [];
    let tempArr = [];
    if (displayArray?.length === 0) {
      currDate = moment(matchesData[0]?.datetime).format('YYYY-MM-DD');

      for (let i = 0; i < matchesData?.length; i++) {
        if (moment(matchesData[i]?.datetime).format('YYYY-MM-DD') ===
            currDate) {
          displayArray.push(false);
        } else {
          displayArray.push(true);
          currDate = moment(matchesData[i]?.datetime).format('YYYY-MM-DD');
        }
        tempArr.push(moment(matchesData[i]?.datetime).format('YYYY-MM-DD'));
      }
    }

    return (
        <div className={`justify-content-center w-100 ${!props.isOtherSports &&
        'pt-4'} ms-5 p-0 `}>
          {customArrows(target)}
          <Slider ref={slider} {...settings}>
            {matchesData?.map((value, index) => ((
                <TopMatchSliderItem displayArray={displayArray}
                                    display={value?.date !== currentDisplayDate}
                                    dateValue={value}
                                    selectedSport={selectedSport}
                                    dateIndex={index}
                                    currentDate={currentDisplayDate}
                                    setCurrentDate={setCurrentDisplayDate}
                                    isMobile={isMobile}
                                    target={target}
                />)))}
          </Slider>
        </div>
    );
  };

  const renderMatchesSkeleton = () => {
    return (
        <Skeleton count={5} height={'100px'} width={'225px'} inline={true}
                  className={'ms-2'}/>
    );
  };

  const render = () => {
    return (<div style={{width: matchesData?.length < 7 ? 'auto' : ''}}
                 className={`container ps-5 pe-5`}>
      {!matchesLoading && matchesData?.length > 0 && container()}
      {/*{matchesLoading && <ItemLoader loading={matchesLoading}/>}*/}
      {matchesLoading && renderMatchesSkeleton()}
    </div>);
  };

  return render();

};

export default TopMatchesSlider;
