const PlayIcon = (props) => {
    const {fill} = props;

    const render = () => {
        return (
            <div {...props}>
                <svg xmlns="http://www.w3.org/2000/svg" width="48.521" height="48.522" viewBox="0 0 48.521 48.522">
                    <defs>
                        <filter id="73fpz2gipa" x="0" y="0" width="48.521" height="48.522" filterUnits="userSpaceOnUse">
                            <feOffset dy="3"/>
                            <feGaussianBlur stdDeviation="3" result="blur"/>
                            <feFlood flood-opacity=".051"/>
                            <feComposite operator="in" in2="blur"/>
                            <feComposite in="SourceGraphic"/>
                        </filter>
                    </defs>
                    <g data-name="Component 122 – 1">
                        <g filter="url(#73fpz2gipa)">
                            <path data-name="Rectangle 6802" transform="translate(9 6)" fill={fill}
                                  d="M0 0h30.521v30.522H0z"/>
                        </g>
                        <path data-name="Polygon 2" d="M7.5 0 15 12H0z" transform="rotate(90 8.161 21.839)"
                              fill="#fff"/>
                    </g>
                </svg>
            </div>
        );
    }

    return render();
}

export default PlayIcon;
