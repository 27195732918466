import DefaultImage from '../../../assets/images/Default-image.jpg'
import FanzineSportsGoalUpNext from './FanzineSportsGoalUpNext';
import React, {useEffect, useState} from 'react';
import {useLocation, useSearchParams} from 'react-router-dom';
import {
  FOOTBALL,
} from '../../../Axios/EndPoints';
import {get} from '../../../Axios/get';
import ReactPlayer from 'react-player'
import FanzineSportsVideoComponent from './FanzineSportsVideoComponent';
import GoogleAd from '../../../Components/Ads/GoogleAd';
import axios from 'axios';
import MobileBackground from '../../../assets/images/LoginBG.png';
import DesktopBackground from '../../../assets/images/fanzineSportsHomeBG.png';
import {
  FANZINESPORTS_HOMEPAGE_LEAGUES
} from '../../../Constants/JS/FanzineSportsLeague';
import {useSelector} from 'react-redux';


const FanzineSportsGoalHighlights = (props) => {

  const {isMobile, data, leagueId,endpoint} = props;
  const LoginStore = useSelector(state => state);

  const location = useLocation();

  const [searchParams, setSearchParams] = useSearchParams()
  const [latestVideos, setLatestVideos] = useState([])
  const [latestVideosLoading, setLatestVideosLoading] = useState(true)

  let [apiPage, setApiPage] = useState(1);
  let [restrictApiCall, setRestrictApiCall] = useState(true);

  const [selectedVideo,setSelectedVideo] = useState(null);

  const handleScroll = (e) => {
    const pageBottom = document.body.scrollHeight -
        document.documentElement.clientHeight - 10 <=
        document.documentElement.scrollTop;
    if (pageBottom) {
      setApiPage((prevState) => {
        return prevState + 1;
      });
      setRestrictApiCall(false);
    }
  };

  const fetchLatestVideos = (leagueID,isNew) => {


    const url = endpoint ? `${endpoint}` :`/Football/videos/highlights/goal?limit=15&league_id=${leagueID}`

      get(url).then((response) => {
        // eslint-disable-next-line array-callback-return
        let tempData = response.data;
        tempData.sort(function(a, b) {
          let keyA = new Date(a?.published_at?.date),
              keyB = new Date(b?.published_at?.date);
          // Compare the 2 dates
          if (keyA < keyB) return 1;
          if (keyA > keyB) return -1;
          return 0;
        });
        if (isNew) {
          setSelectedVideo(tempData[0]);
          setLatestVideos(tempData);
        } else {
          setLatestVideos([...latestVideos, ...tempData]);
        }

        // setSelectedVideo(tempData[0]);
        // setLatestVideos(tempData);
        setLatestVideosLoading(false);
      }).catch((err) => {
        console.error(err);
      });
  };

  useEffect(()=>{
    if(props?.selectedVideo)
    {
      setSelectedVideo(props?.selectedVideo);
    }

    return(()=>{
      if(props?.setSelectedVideo)
      {
        props?.setSelectedVideo(null);
      }
    })

  },[props])



  useEffect(() => {
    setRestrictApiCall(true);
    const leagueSlug = location.pathname.split('/')[1];
    let league_id = 1204;
    for(let i= 0;i<FANZINESPORTS_HOMEPAGE_LEAGUES?.length;i++)
    {
      if(leagueSlug === FANZINESPORTS_HOMEPAGE_LEAGUES[i]?.slug)
      {
        league_id = FANZINESPORTS_HOMEPAGE_LEAGUES[i]?.leagueID;
      }
    }

    window.addEventListener('scroll', handleScroll);
    setApiPage(1);
    setLatestVideosLoading(true);
    LoginStore?.fanzineSports?.isLogin && fetchLatestVideos(league_id,true)
    return () => {
      window.removeEventListener('scroll', handleScroll);
      setSelectedVideo(null);
      setLatestVideos(null)
      setLatestVideosLoading(true);
      setApiPage(1);
    };

  }, [location.pathname,endpoint,searchParams.get('league_id'), searchParams.get('team_id'), props.videosEndpoint, data])

  useEffect(() => {
    setLatestVideosLoading(true);

    const leagueSlug = location.pathname.split('/')[1];
    let league_id = 1204;
    for(let i= 0;i<FANZINESPORTS_HOMEPAGE_LEAGUES?.length;i++)
    {
      if(leagueSlug === FANZINESPORTS_HOMEPAGE_LEAGUES[i]?.slug)
      {
        league_id = FANZINESPORTS_HOMEPAGE_LEAGUES[i]?.leagueID;
      }
    }

    !restrictApiCall && fetchLatestVideos(league_id,false);
  }, [location.pathname, apiPage, restrictApiCall]);



  const renderFanzineVideosMobile = () =>
  {
    return(
        <div className={"container"}>
          <div className={"row justify-content-center"}>
            <div className={"col-11"}>
              <GoogleAd id={'4009154580'}/>
            </div>
          </div>
          <div className={"row"}>
            <div className={"col-12"}>
              {latestVideos?.map((items, index) => {

                return (index !== 0 && index % 4 === 0) ? (
                    <div className={"d-flex justify-content-center"}>
                      <GoogleAd id={'4009154580'}/>
                    </div>
                ) : (
                    <FanzineSportsVideoComponent isMobile={isMobile} data={items} setSelectedVideo={setSelectedVideo} selectedVideo={selectedVideo} />
                );
              })}
            </div>
          </div>
        </div>
    )
  }


  const renderMain = () => {
    return isMobile ?
        renderFanzineVideosMobile()
        : (
            <div
                style={{
                  backgroundImage: `url(${isMobile ? MobileBackground : DesktopBackground})`,
                  backgroundPosition: 'center',
                  backgroundSize:   'cover',
                  backgroundRepeat: 'no-repeat'

                }}

                className={`container-fluid ${isMobile ? 'pt-2' : ''} `}
            >
              <div className={'row  d-flex justify-content-center'}>
                <div className={'col-md-6 col-lg-6 col-xl-6 col-xxl-6 mb-5'}>
                  <p
                      className={`font-30 pt-4 pb-3 m-0 text-start robotoCondensedBold text-white`}>
                    {'GOAL HIGHLIGHTS'}
                    <hr
                        className={'bg-white text-white w-25'}
                    style={{
                      opacity:1,
                      background:'white',
                      borderBottom:'1px solid white',
                    }}
                    />
                  </p>
                  <ReactPlayer
                      height={'480px'}
                      width={'auto'}
                      controls={true}
                      playing={true}
                      url={selectedVideo?.url} />
                </div>
                <div
                    style={{height: '519px'}}
                    className={
                      'col-md-3 col-lg-3 col-xl-3 col-xxl-3 overflow-auto mt-5 pt-1'
                    }
                >
                  <div>
                    <p className={`font-20 pt-4 pb-3 m-0 text-start robotoCondensedBold text-white`}>
                      {'UP NEXT'}
                    </p>
                  </div>
                  {latestVideos?.map((items, index) => {
                    return (
                        <FanzineSportsGoalUpNext
                            isGoal={props?.endpoint}
                            item={items}
                            selectedVideo={selectedVideo}
                            setSelectedVideo={setSelectedVideo}
                            news={items?.title || `${items.player.short_name} Goal | ${items?.result} ${items?.match?.local_team + ' vs ' + items?.match?.away_team}`}
                            src={items?.preview_imag || DefaultImage}
                            index={index}
                        />
                    );
                  })}
                </div>
              </div>
            </div>
        );
  };

  return renderMain();
};

export default FanzineSportsGoalHighlights;
