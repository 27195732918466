import {withRouter} from "../../../CustomFunctions/withRouter";
import {useDispatch, useSelector} from "react-redux";
import {useWindowSize} from "../../../CustomFunctions/useWindowSize";
import {SCREEN_SIZE, SHOPIFY} from "../../../Configs/Constants";
import React, {useEffect, useState} from "react";
import {COLORS, COLORS_RGBA, LOADING_GIF, NAV_CLASS, TEXT_COLOR} from "../../../Configs/Targets";
import {Banner} from "../../../Components/ClubShop/Banner";
import {Link} from "react-router-dom";
import {ShopifyGraphQlQueries} from "../../../Configs/ShopifyGraphQlQueries";
import {graphql} from "../../../Axios/graphql";
import {setCartItems, setGrandTotal} from "../../../redux/actions/CartActions";
import {getHostname} from "../../../CustomFunctions/getHostname";
import Loader from "../../../Components/Loader/Loader";

const Checkout = () => {
    // Hooks
    const cart = useSelector((state) => state);
    const dispatch = useDispatch();
    let windowSize = useWindowSize();

    /*States*/
    let [subtotal, setSubtotal] = useState(0);
    let [discount, setDiscount] = useState(false);
    let [discountType, setDiscountType] = useState(null);
    let [discountValue, setDiscountValue] = useState(null);
    let [discountCode, setDiscountCode] = useState(null);
    let [shopifyCart, setShopifyCart] = useState(null);
    let [loading, setLoading] = useState(true);
    /*States Ends*/

    /*Variables Start*/
    const hostName = window.location.hostname;
    const domainObject = getHostname(hostName);
    const target = domainObject.host;
    let bgColor = NAV_CLASS[target], textColor = TEXT_COLOR[target], color = COLORS[target],
        colorRgba = COLORS_RGBA[target], topBannerStyle = {
            width: '100%',
            height: '144px',
            background: `linear-gradient(to bottom, ${colorRgba}0.71) 45%, ${colorRgba}1)), url(${require('../../../assets/images/shop_top_banner.png')})`,
            backgroundSize: 'cover'
        }, loadingGif = LOADING_GIF[target];
    let isMobile = false;
    if (windowSize.width <= SCREEN_SIZE.MOBILE) {
        isMobile = true;
    }
    /*Variables Ends*/

    /* Use Effect Start */
    useEffect(() => {
        setLoading(true);
        getShopifyCart();
    }, []);
    /* Use Effect End */

    /*API Call*/
    /*API Call Ends*/

    /*Event Methods*/

    const getShopifyCart = () => {
        let checkoutId = localStorage.getItem("checkoutId");
        let data = {checkout_id: checkoutId};
        graphql(ShopifyGraphQlQueries(SHOPIFY.GET_SHOPIFY_CART, null, null, data))
            .then((response) => {
                let tempCartItems = [];
                if (typeof response.data.data !== 'undefined') {
                    response.data.data.node.lineItems.edges.map((item) => {
                        let tempVariant = {
                            id: item.node.variant.id,
                            title: item.node.variant.title,
                            price: item.node.variant.price?.amount,
                            image: item.node.variant.image,
                        };
                        let node = {
                            id: item.node.variant.product.id,
                            product_name: item.node.title,
                            variant: tempVariant,
                            size: item.node.variant.selectedOptions[0].value,
                            color: item.node.variant.selectedOptions[1] ? item.node.variant.selectedOptions[1].value : "",
                            lineItemId: item.node.id,
                            quantity: item.node.quantity,
                        };
                        tempCartItems.push(node);

                    });
                    if (response.data.data.node.discountApplications.edges.length === 0) {
                        setSubtotal(Math.round(parseFloat(response.data.data.node.subtotalPrice?.amount) * 100) / 100);
                    } else {
                        let tempSubtotal = 0;
                        setDiscountCode(response.data.data.node.discountApplications.edges[0].node.code);
                        setDiscountValue(response.data.data.node.discountApplications.edges[0].node.value.percentage);
                        setDiscount(true);
                        response.data.data.node.lineItems.edges.map((item) => {
                            tempSubtotal += parseFloat(item.node.variant.price?.amount || item.node.variant.price);
                        })
                        setSubtotal(tempSubtotal);
                    }
                    dispatch(setCartItems(tempCartItems));
                    let total = Math.round((response.data.data.node.subtotalPrice?.amount || response.data.data.node.subtotalPrice) * 100) / 100;
                    dispatch(setGrandTotal(total));
                    setShopifyCart(response.data.data.node);
                    if (response.data.data.node.discountApplications.edges.length === 0) {
                        setSubtotal(parseFloat((response.data.data.node.subtotalPrice?.amount || response.data.data.node.subtotalPrice)));
                    }
                } else {
                    document.getElementById('navbarShop').click();
                    document.getElementById('navbarShop').click();
                }
                setLoading(false);
            })
            .catch((err) => {
                console.error(err);
            })
    }

    const handlePromoDiscount = () => {
        setLoading(true);
        let discountCode = document.getElementById('applyDiscountCodeCheckout').value;
        let checkoutId = localStorage.getItem("checkoutId");
        let data = {checkout_id: checkoutId, discount_code: discountCode};
        graphql(ShopifyGraphQlQueries(SHOPIFY.APPLY_DISCOUNT_CODE, null, null, data))
            .then((response) => {
                let edges = response.data.data.checkoutDiscountCodeApply.checkout.discountApplications.edges
                if (edges.length > 0) {
                    localStorage.removeItem("checkoutUrl");
                    localStorage.setItem('checkoutUrl', response.data.data.checkoutDiscountCodeApply.checkout.webUrl);
                    let discount = edges[0].node.value;
                    setDiscountType(discount.__typename);
                    setDiscountValue(discount.percentage);
                    let total = subtotal - (subtotal * (discount.percentage / 100));
                    setGrandTotal(Math.round(total * 100) / 100);
                    setDiscount(true);
                    setDiscountCode(edges[0].node.code);
                    getShopifyCart();
                }
            })
            .catch((err) => {
                console.error(err);
            })
    }

    const removePromoDiscount = () => {
        setLoading(true);
        let checkoutId = localStorage.getItem("checkoutId");
        let data = {checkout_id: checkoutId}
        graphql(ShopifyGraphQlQueries(SHOPIFY.REMOVE_DISCOUNT_CODE, null, null, data))
            .then((response) => {
                getShopifyCart();
            })
            .catch((err) => {
                console.error(err);
            })
        setDiscount(false);
        document.getElementById('RemoveDiscountCodeCheckout').value = "";
        setGrandTotal(subtotal);
    }

    const handleCheckout = () => {
        let checkoutUrl = localStorage.getItem("checkoutUrl");
        localStorage.removeItem('checkoutUrl');
        window.location.href = checkoutUrl;
    }
    /*Event Methods Ends*/

    /*item event methods*/
    const incrementQuantity = (event) => {
        setLoading(true);
        let variantId = event.currentTarget.dataset.id;
        let currentItem = shopifyCart.lineItems.edges.filter((item) => item.node.variant.id === variantId);
        let quantity = currentItem[0].node.quantity;
        let tempQuantity = quantity + 1;
        let checkoutId = localStorage.getItem("checkoutId");

    }

    const decrementQuantity = (event) => {
        setLoading(true);
        let variantId = event.currentTarget.dataset.id;
        let currentItem = shopifyCart.lineItems.edges.filter((item) => item.node.variant.id === variantId);
        let quantity = currentItem[0].node.quantity;
        let price = parseFloat(currentItem[0].node.variant.price);
        let tempQuantity = quantity - 1;
        if (tempQuantity >= 1) {
            let checkoutId = localStorage.getItem("checkoutId");
            let data = {checkout_id: checkoutId, variant_quantity: tempQuantity, linkItem_id: currentItem[0].node.id};
            graphql(ShopifyGraphQlQueries(SHOPIFY.CHECKOUT_LINE_ITEMS_UPDATE, null, null, data))
                .then((response) => {
                    localStorage.removeItem("checkoutUrl");
                    localStorage.setItem('checkoutUrl', response.data.data.checkoutLineItemsUpdate.checkout.webUrl);
                    setSubtotal(subtotal - parseFloat(currentItem[0].node.variant.price));
                    getShopifyCart();
                })
                .catch((err) => {
                    console.error(err);
                })
            // dispatch(setGrandTotal(parseFloat(cart.cart.grandTotal) - Math.round((price * parseInt(tempQuantity)) * 100) / 100));
        }
    }

    const removeItem = (id) => {
        setLoading(true);
        let currentItem = shopifyCart.lineItems.edges.filter((item) => item.node.variant.id === id);
        let checkoutId = localStorage.getItem("checkoutId");
        let data = {checkout_id: checkoutId, lineItem_id: currentItem[0].node.id};
        graphql(ShopifyGraphQlQueries(SHOPIFY.CHECKOUT_LINE_ITEMS_REMOVE, null, null, data))
            .then((response) => {
                localStorage.removeItem("checkoutUrl");
                localStorage.setItem('checkoutUrl', response.data.data.checkoutLineItemsRemove.checkout.webUrl);
                getShopifyCart();
            })
            .catch((err) => {
                console.error(err);
            })
        let cartAfterFilter = cart.cart.cartItems.filter((item, index) => item.variant.id !== id);
        if (cartAfterFilter.length > 0) {
            dispatch(setCartItems(cartAfterFilter));

        } else {
            getShopifyCart();
            dispatch(setCartItems(cartAfterFilter));
            dispatch(setGrandTotal(0));
            document.getElementById('navbarShop').click();
            document.getElementById('navbarShop').click();
        }


    }
    /*Item event methods end*/

    /*Render Methods*/
    const render = () => {
        return (
            <>
                <Loader loading={loading}/>
                <Banner breadcrums={true} grandTotal={Math.round(cart.grandTotal * 100) / 100}/>
                <div className={'container sfProDisplayFontBold mt-3 p-3 bg-grey'}>
                    {shopifyCart && shopifyCart.lineItems.edges.length > 0 ? (
                        <div className={`container d-flex m-auto p-2 row bg-white`}>
                            {!isMobile &&
                                <>
                                    {shopifyCart.lineItems.edges.map((cartItem) => (
                                        // <Item item={cartItem} subtotal={calculateSubtotal} updateShopify={getShopifyCart}/>
                                        <>
                                            <div className={`col-3 justify-content-start`}>
                                                <div className={`col-12 bebasFont font-18`}>
                                                    Product
                                                </div>
                                                <div className={`col-12`}>
                                                    <div className={`row`}>
                                                        <div className={`col-6`}>
                                                            <Link to={`/shop/product/${cartItem.node.variant.product.handle}`}>
                                                                <img src={cartItem.node.variant.image.src} width={`120`}/>
                                                            </Link>
                                                        </div>
                                                        <div className={`col-6`}>
                                                            <div style={{color: "grey", fontSize: "14px"}}>
                                                                {cartItem.node.title}
                                                            </div>
                                                            {cartItem.node.variant.selectedOptions.map((option) => (
                                                                <div style={{color: "lightgrey", fontSize: "12px"}}>
                                                                    {option.name}: {option.value}
                                                                </div>
                                                            ))}
                                                            <br/>
                                                            <div className={`bg-green text-center w-75 text-white`}>
                                                                In Stock
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={`col-3`}>
                                                <div className={`bebasFont font-18 d-flex justify-content-center`}>
                                                    Price
                                                </div>
                                                <div className={`${textColor} d-flex justify-content-center mt-5 font-xxl`}
                                                     id={`price`}>
                                                    £{cartItem.node.variant.price}
                                                </div>
                                            </div>
                                            <div className={`col-4`}>
                                                <div className={`bebasFont font-18 d-flex justify-content-center`}>
                                                    Quantity
                                                </div>
                                                <div className={`col-12 d-flex align-items-baseline`}>
                                                    {cartItem.node.quantity === 1 ? (
                                                        <button
                                                            className={`mt-5 w-25 m-3 fanzine-select p-1 text-black bg-grey`}
                                                            id={`decrement-btn${cartItem.node.variant.id}`}
                                                            disabled="disabled"
                                                            onClick={decrementQuantity}>-
                                                        </button>
                                                    ) : (
                                                        <button
                                                            className={`mt-5 w-25 m-3 fanzine-select p-1 text-black bg-white`}
                                                            id={`decrement-btn${cartItem.node.variant.id}`}
                                                            data-id={cartItem.node.variant.id}
                                                            onClick={decrementQuantity}>-
                                                        </button>
                                                    )}

                                                    <input className="form-control h-25 remove-control" type="number"
                                                           id={`quantity${cartItem.node.variant.id}`}
                                                           style={{textAlign: "center"}}
                                                           value={cartItem.node.quantity}/>
                                                    <button className="mt-5 w-25 m-3 fanzine-select p-1 bg-white text-black"
                                                            data-id={cartItem.node.variant.id}
                                                            onClick={incrementQuantity}>+
                                                    </button>
                                                </div>
                                                <div className={`d-flex justify-content-center`}>
                                                    <div className={`sfProDisplayFont font-14`}
                                                         onClick={() => removeItem(cartItem.node.variant.id)}
                                                         role={`button`}>
                                                        Remove
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={`col-2 justify-content-center`}>
                                                <div className={`bebasFont font-18 d-flex justify-content-center`}>
                                                    Total
                                                </div>
                                                <div className={`${textColor} d-flex justify-content-center mt-5 font-xxl`}
                                                     id={`total${cartItem.node.variant.id}`}>
                                                    £{Math.round((parseFloat(cartItem.node.variant.price) * cartItem.node.quantity) * 100) / 100}
                                                </div>
                                            </div>
                                            <div className="dropdown-divider"/>
                                        </>
                                    ))}
                                    <div className={`row mt-3`}>
                                        <div className={`col-7`}></div>
                                        <div className={`col-5`}>
                                            <div className="input-group mb-3">
                                                {shopifyCart.discountApplications.edges.length > 0 ? (
                                                    <>
                                                        <input type="text"
                                                               className="sfProDisplayFont form-control text-center"
                                                               placeholder="Enter Discount Code"
                                                               id={`RemoveDiscountCodeCheckout`}
                                                               aria-label="Enter Discount Code"
                                                               aria-describedby="button-addon2"
                                                               autoComplete="off" value={discountCode} disabled="disabled"/>
                                                        <button className="btn promo-code-redeem-btn sfProDisplayFont"
                                                                type="button"
                                                                id="button-addon2"
                                                                onClick={removePromoDiscount}>Remove
                                                        </button>
                                                    </>
                                                ) : (
                                                    <>
                                                        <input type="text"
                                                               className="sfProDisplayFont form-control text-center"
                                                               placeholder="Enter Discount Code"
                                                               id={`applyDiscountCodeCheckout`}
                                                               aria-label="Enter Discount Code"
                                                               aria-describedby="button-addon2"
                                                               autoComplete="off"/>
                                                        <button className="btn promo-code-redeem-btn sfProDisplayFont"
                                                                type="button"
                                                                id="button-addon2"
                                                                onClick={handlePromoDiscount}>Redeem
                                                        </button>
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className={`row mt-3`}>
                                        <div
                                            className={`col-8 font-18 text-gray d-flex justify-content-end align-items-center`}>
                                            Subtotal:
                                        </div>
                                        <div className={`col-4 ${textColor} d-flex justify-content-end font-xxl`}>
                                            £{subtotal}
                                        </div>


                                    </div>
                                    {shopifyCart.discountApplications.edges.length > 0 ? (
                                        <>
                                            <div className={`row mt-3`}>
                                                <div
                                                    className={`col-8 font-18 text-gray d-flex justify-content-end align-items-center`}>
                                                    Discount:
                                                </div>
                                                <div className={`col-4 d-flex justify-content-end font-18`}>
                                                    -{discountValue}% {discountCode}
                                                </div>
                                            </div>
                                            <div className={`row mt-3`}>
                                                <div
                                                    className={`col-8 font-18 text-gray d-flex justify-content-end align-items-center`}>
                                                    Total:
                                                </div>
                                                <div className={`col-4 ${textColor} d-flex justify-content-end font-xxl`}>
                                                    £{shopifyCart.subtotalPrice}
                                                </div>
                                            </div>
                                        </>
                                    ) : (<></>)}
                                    <div className={`row mt-3`}>
                                        <div
                                            className={`col-12 font-18 text-gray d-flex justify-content-end align-items-center`}>
                                            Tax Included
                                        </div>
                                        <div
                                            className={`col-12 font-18 text-gray d-flex justify-content-end align-items-center`}>
                                            Shipping is calculated at checkout
                                        </div>
                                    </div>
                                    <div className={`row mt-3`}>
                                        <div className="col-12 font-18 d-flex justify-content-end">
                                            <Link to={`/shop`} className="btn bg-grey btn-lg w-40">Continue Shopping</Link>
                                        </div>
                                        <div className="mt-3 col-12 font-18 d-flex justify-content-end">
                                            <button type="button" className={`btn ${bgColor} text-white btn-lg w-40`}
                                                    onClick={handleCheckout}>
                                                Checkout Securely
                                                <span className={`float-end`}> > </span>
                                            </button>
                                        </div>
                                    </div>
                                </>}
                            {isMobile &&
                                <>
                                    <>
                                        {loading ? (
                                            <>
                                                <div className={`container loader`}>
                                                    <img src={loadingGif}
                                                         alt="loader" width="150"/>
                                                </div>
                                            </>
                                        ) : (<></>)}
                                        {shopifyCart.lineItems.edges.map((cartItem) => (
                                            <>
                                                {/*<MiniCartItem item={cartItem} subtotal={() => calculateSubtotal} reload={() => reloadCart}/>*/}
                                                <div className={`sfProDisplayFontBold font-12 ${textColor}`}>Product</div>
                                                <div className={`mt-3 mb-1 col-12 justify-content-start`}>
                                                    <div className={`col-12`}>
                                                        <div className={`row`}>
                                                            <div className={`col-4`}>
                                                                <Link to={`/shop/product/${cartItem.node.id}`}>
                                                                    <img src={cartItem.node.variant.image.src} width={`100`}
                                                                         alt="variant-image"/>
                                                                </Link>

                                                                <div className={`d-flex justify-content-center text-black`}>
                                                                    <div className={`sfProDisplayFont font-11 mt-1`}
                                                                         onClick={() => removeItem(cartItem.node.variant.id)}
                                                                         role={`button`}>
                                                                        Remove
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className={`col-6 ms-4`}>
                                                                <div
                                                                    className={`d-flex justify-content-start text-start text-wrap text-mini-cart sfProDisplayFontBold font-12 col-12`}>
                                                                    {cartItem.node.title}
                                                                </div>
                                                                <div className={`mt-2`}>
                                                                    {cartItem.node.variant.selectedOptions.map((option) => (
                                                                        <div
                                                                            className={`d-flex justify-content-start text-mini-cart sfProDisplayFont font-12 font-weight-500`}>
                                                                            {option.name}: {option.value}
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                                <div className={`bg-green text-center w-75 font-12 text-white`}>
                                                                    In Stock
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className={`row`}>
                                                            <div className={`col-4 d-flex justify-content-center align-items-center`}>
                                                                <div className={`row`}>
                                                                    {cartItem.node.quantity === 1 ? (
                                                                        <button
                                                                            className={`col-2 me-2 d-flex align-items-center justify-content-center fanzine-select p-1 text-black bg-grey`}
                                                                            id={`decrement-btn${cartItem.node.variant.id}`}
                                                                            disabled="disabled"
                                                                            onClick={decrementQuantity} style={{
                                                                            height: "25px",
                                                                            border: "solid 1px #c9c9c9",
                                                                            boxShadow: "none",
                                                                        }}>-
                                                                        </button>
                                                                    ) : (
                                                                        <button
                                                                            className={`col-2 me-2 d-flex align-items-center justify-content-center fanzine-select p-1 text-black bg-white`}
                                                                            id={`decrement-btn${cartItem.node.variant.id}`}
                                                                            data-id={cartItem.node.variant.id}
                                                                            onClick={decrementQuantity} style={{
                                                                            height: "25px",
                                                                            border: "solid 1px #c9c9c9",
                                                                            boxShadow: "none",
                                                                        }}>-
                                                                        </button>
                                                                    )}

                                                                    <input className="col form-control remove-control" type="number"
                                                                           id={`quantity${cartItem.node.variant.id}`}
                                                                           style={{textAlign: "center", height: "25px"}}
                                                                           value={cartItem.node.quantity}/>
                                                                    <button className="col-2 ms-2 d-flex align-items-center justify-content-center fanzine-select p-1 bg-white text-black"
                                                                            data-id={cartItem.node.variant.id}
                                                                            onClick={incrementQuantity} style={{
                                                                        height: "25px",
                                                                        border: "solid 1px #c9c9c9",
                                                                        boxShadow: "none",
                                                                    }}>+
                                                                    </button>
                                                                </div>
                                                            </div>
                                                            <div className={`col-8 d-flex justify-content-end align-items-center`}>
                                                                <div className={`mt-n5 mb-4 font-12`} style={{position: "absolute"}}>Price</div>
                                                                <div className={`${textColor} font-20`}
                                                                     id={`total${cartItem.node.variant.id}`}>
                                                                    £{Math.round((parseFloat(cartItem.node.variant.price.amount || cartItem.node.variant.price) * cartItem.node.quantity) * 100) / 100}
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>

                                                <div className={`mt-3 mb-1 col-2 d-flex align-items-center`} style={{marginLeft: "-10px"}}>

                                                </div>
                                                <div className="dropdown-divider"/>
                                            </>
                                        ))}
                                        <div className={`row mt-3`}>
                                            <div className={`col-12`}>
                                                <div className="input-group mb-3">
                                                    {discount ? (
                                                        <>
                                                            <input type="text" className={`sfProDisplayFont form-control text-center ${isMobile ? "promo-input-mobile" : ""}`}
                                                                   placeholder="Enter Discount Code"
                                                                   id={`RemoveDiscountCodeCheckout`}
                                                                   aria-label="Enter Discount Code" aria-describedby="button-addon2"
                                                                   autoComplete="off" value={discountCode} disabled="disabled"
                                                                   style={{height: "33px !important", borderRadius: "0 !important"}}/>
                                                            <button className={`btn ${isMobile ? "promo-code-redeem-btn-mobile" : "promo-code-redeem-btn"} sfProDisplayFont`}
                                                                    type="button"
                                                                    id="button-addon2"
                                                                    onClick={removePromoDiscount}>Remove
                                                            </button>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <input type="text" className={`sfProDisplayFont form-control text-center ${isMobile ? "promo-input-mobile" : ""}`}
                                                                   placeholder="Enter Discount Code"
                                                                   id={`applyDiscountCodeCheckout`}
                                                                   aria-label="Enter Discount Code" aria-describedby="button-addon2"
                                                                   autoComplete="off"
                                                                   style={{height: "33px !important", borderRadius: "0 !important"}}/>
                                                            <button className={`btn ${isMobile ? "promo-code-redeem-btn-mobile" : "promo-code-redeem-btn"} sfProDisplayFont`}
                                                                    type="button"
                                                                    id="button-addon2"
                                                                    onClick={handlePromoDiscount}>Redeem
                                                            </button>
                                                        </>
                                                    )}

                                                </div>
                                            </div>
                                        </div>
                                        <div className={`mt-3`}>
                                            <div
                                                className={`font-11 sfProDisplayFontBold ${textColor} d-flex justify-content-end align-items-center`}>
                                                Subtotal:
                                            </div>
                                            <div className={`${textColor} d-flex justify-content-end font-20`}>
                                                £{subtotal}
                                            </div>

                                        </div>
                                        {shopifyCart.discountApplications.edges.length > 0 ? (
                                            <>
                                                <div className={`mt-3`}>
                                                    <div
                                                        className={`font-11 ${textColor} d-flex justify-content-end align-items-center`}>
                                                        Discount:
                                                    </div>
                                                    <div className={`d-flex ${textColor} justify-content-end font-20 text-black`}>
                                                        -{discountValue}% {discountCode}
                                                    </div>
                                                </div>
                                                <div className={`mt-3`}>
                                                    <div
                                                        className={`font-11 ${textColor} d-flex justify-content-end align-items-center`}>
                                                        Total:
                                                    </div>
                                                    <div className={`${textColor} d-flex justify-content-end font-20`}>
                                                        £{shopifyCart.subtotalPrice}
                                                    </div>
                                                </div>
                                            </>
                                        ) : (
                                            <></>
                                        )
                                        }
                                        <div className={`mt-3`}>
                                            <div
                                                className={`font-10 text-gray d-flex justify-content-end align-items-center`}>
                                                Tax included and shipping calculated at checkout
                                            </div>
                                            {/*<div className={`col-11 font-18 text-gray d-flex justify-content-end align-items-center`}>*/}
                                            {/*    Shipping is calculated at checkout*/}
                                            {/*</div>*/}
                                        </div>
                                        <div className="mt-3 col-12 font-18">
                                            <Link to={`/shop`} className={`btn bg-grey text-white btn-lg w-100 continue-shopping-btn`}>Continue Shopping</Link>
                                        </div>
                                        <div className="mt-3 col-12 font-18">
                                            <button type="button" className={`btn ${bgColor} text-white btn-lg w-100`}
                                                    onClick={handleCheckout} style={{
                                                color: "#757273",
                                                borderRadius: "0"
                                            }}>
                                                Checkout Securely
                                                <span className={`float-end`}> > </span>
                                            </button>
                                        </div>
                                    </>
                                </>
                            }
                        </div>
                    ) : (
                        <>
                            <div className={`bebasFont`}>
                                {
                                    loading ? 'Fetching...' : 'No Items found.'
                                }
                            </div>
                        </>
                    )
                    }
                </div>
            </>
        );
    }

    /*Render Methods Ends*/
    return render();

}

export default withRouter(Checkout);
