import Slider from "react-slick";
import React, {useEffect, useRef, useState} from 'react';
import { SCREEN_SIZE } from "../../Configs/Constants";
import "../../assets/slider/HomePageCarousel.css";

const Carousel = (props) => {
  const { isMobile, imageOneBig, imageOne, imageTwo, imageThree, imageFour,isAdvertisement,isAppSponsorShip,imageOneText,imageTwoText,imageThreeText,imageFourText } = props;

  const [nextArrowPadding, setNextArrowPadding] = useState("24.5%");
  const [prevArrowPadding, setPrevArrowPadding] = useState("21.5%");
  const [isLargeScreen, setIsLargeScreen] = useState(false);

  useEffect(() => {
    if (window.screen.width >= 2550) {
      setIsLargeScreen(true);
      setPrevArrowPadding("21%");
      setNextArrowPadding("23.5%");
    } else if (window.screen.width >= 1360 && window.screen.width < 1920) {
      setPrevArrowPadding("22.5%");
      setNextArrowPadding("25.5%");
    }
  }, [window.screen.width]);

  function SampleNextArrow(props) {
    const { className, onClick } = props;
    let style = {
      height: isMobile ? "36px" : "100%",
      paddingLeft: isMobile ? "0px" : nextArrowPadding,
      marginLeft: isMobile ? "0px" : "25px",
    };

    return (
      <div
        className={className}
        style={{
          ...style,
          display: "block",
          background: "white",
          opacity: "0.2",
          zIndex: 10,
        }}
        onClick={onClick}
      />
    );
  }

  function SamplePrevArrow(props) {
    const { className, onClick } = props;

    let style = {
      height: isMobile ? "36px" : "100%",
      paddingLeft: isMobile ? "0px" : prevArrowPadding,
      marginLeft: isMobile ? "0px" : "25px",
    };

    return (
      <div
        className={className}
        style={{
          ...style,
          display: "block",
          background: "white",
          opacity: "0.2",
          zIndex: 10,
        }}
        onClick={onClick}
      />
    );
  }

  const renderSlide1 = () => {

    const height = isLargeScreen ? "auto" : "590px";

    return (
      <div
          className={"ps-1 pe-1"}>
        <span className={'text-white text-center Montserrat-Regular d-flex justify-content-center'}>
          {imageOneText}
        </span>
        <img
          className={"img-fluid"}
          style={{
            height: height,
            objectFit: imageOneBig ? "cover" : "contain",
          }}
          src={imageOne}
          alt={"img-1"}
        />
      </div>
    );
  };
  const renderSlide2 = () => {
    return (
      <div className={"ps-1 pe-1"}>
        <span className={'text-white text-center Montserrat-Regular d-flex justify-content-center'}>
          {imageTwoText}
        </span>
        <img
          className={"img-fluid"}
          style={{
            height: isLargeScreen ? "auto" : "590px",
            objectFit: "contain",
          }}
          src={imageTwo}
          alt={"img-1"}
        />
      </div>
    );
  };
  const renderSlide3 = () => {
    return (
      <div className={"ps-1 pe-1"}>
                <span className={'text-white text-center Montserrat-Regular d-flex justify-content-center'}>
          {imageThreeText}
        </span>
        <img
          className={"img-fluid"}
          style={{
            height: isLargeScreen ? "auto" : "590px",
            objectFit: "contain",
          }}
          src={imageThree}
          alt={"img-1"}
        />
      </div>
    );
  };
  const renderSlide4 = () => {
    return (
      <div className={"ps-1 pe-1"}>
                <span className={'text-white text-center Montserrat-Regular d-flex justify-content-center'}>
          {imageFourText}
        </span>
        <img
          className={"img-fluid"}
          style={{
            height: isLargeScreen ? "auto" : "590px",
            objectFit: "contain",
          }}
          src={imageFour}
          alt={"img-1"}
        />
      </div>
    );
  };

  const settings = {
    dots: true,
    className: isMobile ? "" : "ms-auto me-auto",
    centerMode: isAppSponsorShip ? false : true,
    infinite:  true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          arrows: false,
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 480,
        settings: {
          arrows: false,
          slidesToShow: 1,
        },
      },
    ],
    // centerPadding: "50px",
    // height:'590px',
    swipe: true,

    slidesToShow: isMobile ? 1 : 1.68,
    speed: 500,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

  return (
    <div className={`${isAdvertisement ? '' : 'bg-black'} container-fluid`}>
      <Slider style={{ width: imageOneBig ? '362px' : "98%" }} {...settings}>
        {imageOne && renderSlide1()}
        {imageTwo && renderSlide2()}
        {imageThree && renderSlide3()}
        {imageFour && renderSlide4()}
      </Slider>
    </div>
  );
};

export default Carousel;
