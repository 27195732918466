import React, {useEffect, useState} from 'react';
import Card from 'react-bootstrap/Card';
import '../../Cards/styles/NavbarCard.css'
import '../../../assets/styles/styles.css';
import PropTypes from 'prop-types';
import {getHostname} from '../../../CustomFunctions/getHostname';
import {FONT_COLOR} from '../../../Configs/Targets';
import {useDispatch} from 'react-redux';
import thumbnailSrc from '../../../assets/images/Default-image.jpg';

const FanzineSportsHighlightsCard = (props) => {

    const {isMobile,video,isFanzineSportsNews,videoHeight,extraClass,isTypeGoal} = props;
    let dispatch = useDispatch();

    let [isOpen, setIsOpen] = useState(false);
    let [stopMain, setStopMain] = useState(true);
    let [videoData, setVideoData] = useState(props.video);
    const [isVideoReady, setIsVideoReady] = useState(false);

    const hostName = window.location.hostname;
    const domainObject = getHostname(hostName);
    const target = domainObject.host;
    const textColor = props?.league?.font_color ? props?.league?.font_color : target === 'fanzine' ? 'text-white' : FONT_COLOR[target];

    const handleVideoReady = () => {
        setIsVideoReady(true);
    };
    const openModal = () => {
        setIsOpen(true);
        setStopMain(false);
    };

    const addDefaultSrc = (ev) => {
        ev.target.src = require('../../../assets/images/default_publisher_icon.png');
    };

    useEffect(() => {
        setVideoData(props?.video);
    }, [props?.video]);

    const render = () => {
        let extraClass = props?.extraClass;
        let videoHeight = props?.videoHeight;
        let isMain = typeof props?.main !== 'undefined';
        // let isMobile = typeof props.isMobile !== 'undefined';
        let titleClass = isMain && !isMobile ? 'h3' : 'small';
        let bottomClass = isMain && !isMobile ? 'h5' : 'small';
        let publisherImageHeight = isMain && props?.isMobile ? 30 : 15;
        let titleWidth = isMobile && isMain ? 'w-100' : 'w-100';
        if (!videoData) {
            return (<></>);
        }

        return (
            <a target={'_blank'} rel={'noreferrer'} href={`${isFanzineSportsNews ? videoData?.url : videoData?.video}`} className={`text-decoration-none text-white cursor-pointer ${extraClass}`}>
                <Card
                    style={{height: '151px', width: '203px'}}
                    className="col border-0 bg-transparent m-2">
                    <Card.ImgOverlay
                        className={`video-overlay`}
                        onClick={openModal}
                    />
                        <Card.Text
                            className={`text-white`}
                        >
                            {!isVideoReady && <video
                                poster={thumbnailSrc}
                                onLoadedData={handleVideoReady}
                                preload="metadata"
                                style={{
                                    objectFit: 'cover',
                                    height: '151px',
                                    width: '203px',
                                }}
                            >
                                <source src={isFanzineSportsNews ?
                                    videoData?.url :
                                    videoData.video} type="video/mp4"/>
                            </video>}
                            {isVideoReady && <video
                                onLoadedData={handleVideoReady}
                                preload="metadata"
                                style={{
                                    objectFit: 'cover',
                                    height: '151px',
                                    width: '203px',
                                }}
                             src={isFanzineSportsNews ?
                                videoData?.url :
                                videoData.video}
                            >
                            </video>
                            }                        </Card.Text>
                </Card>
                <div className="row p-2">
                    {isTypeGoal && <div className={'font-fanzine sfProDisplayFontBold'}>
                        {videoData.minute}' {isFanzineSportsNews ?
                        videoData?.player?.short_name :
                        videoData.player?.name || ''}
                    </div>
                    }
                    <div className={'text-white sfProDisplayFont'}>
                        {isFanzineSportsNews ? videoData?.match?.local_team : videoData.home_team?.name || ''}
                        {' ' +  (isTypeGoal ? videoData?.result?.split('-')[0] : videoData.home_team?.score || '')}
                    {isTypeGoal ?  '-' : ' vs '}
                        {isTypeGoal ? videoData?.result?.split('-')[1] :  videoData.away_team?.score || ''}
                        {' ' + (isFanzineSportsNews ? videoData?.match?.away_team : videoData.away_team?.name || '')}
                    </div>
                </div>
            </a>
        );
    };

    return render();
};

FanzineSportsHighlightsCard.propTypes = {
    videoHeight: PropTypes.string.isRequired,
    main: PropTypes.bool,
    extraClass: PropTypes.string.isRequired,
    isMobile: PropTypes.bool,
};

export default FanzineSportsHighlightsCard;
