import React, {useEffect, useState} from "react";
import {get} from "../../Axios/get";
import {FANZINE} from "../../Axios/EndPoints";
import {Link, useLocation, useParams, useSearchParams} from "react-router-dom";
import Loader from "../../Components/Loader/Loader";
import {SCREEN_SIZE} from "../../Configs/Constants";
import {Breadcrumb} from "react-bootstrap";
import Card from "react-bootstrap/Card";

const FanzineFanaticDetails = () => {
    const [restrictApiCall, setRestrictApiCall] = useState(true);
    const [productData, setProductData] = useState(null);
    const [searchParams, setSearchParams] = useSearchParams();
    const [apiPage, setApiPage] = useState(1);
    const queryParams = useLocation();
    const params = useParams();
    const [isMobile, setIsMobile] = useState(false);
    const [loading, setLoading] = useState(true)
    const [countryName, setCountryName] = useState('')

    const addDefaultSrc = (e) => {
        e.target.value = require('../../assets/images/Default.jpeg');
    }

    document.body.style = 'background: #ffffff !important;';

    const handleScroll = (e) => {
        console.log('documentScroll', document.documentElement.scrollHeight, document.documentElement.scrollTop, document.documentElement.clientHeight);
        const pageBottom =
            document.documentElement.scrollHeight - document.documentElement.clientHeight - 1 <=
            (document.documentElement.scrollTop || document.body.scrollTop);
        if (pageBottom) {
            setApiPage((prevState) => {
                return prevState + 1;
            });
            setRestrictApiCall(false);
        }
    };

    const fetchProducts = () => {
        setRestrictApiCall(true);
        let searchparams = queryParams.search;
        if (searchparams === '') {
            searchparams = "?";
        }
        let page = `&page=${apiPage}`;
        let limit = searchparams === '' ? `&limit=12` : `?limit=12`;
        let teamID = params?.id;
        let parameters = searchparams === '' ? searchparams : limit + page

        get(FANZINE.FanaticsDetailEndPoint + teamID + parameters)
            .then((res) => {
                if (productData === null) {
                    setProductData(res?.data?.data)
                    setCountryName(res.data.data[0].team.name)
                } else {
                    setProductData([...productData, ...res.data.data])
                }
                setLoading(false);
            })
            .catch((error) => {
                console.error('error in product details =', error)
                setLoading(false)
            })
    }


    useEffect(() => {
        setLoading(true);
        setRestrictApiCall(true);
        window.addEventListener("scroll", handleScroll);
        fetchProducts();
        return () => window.removeEventListener("scroll", handleScroll);
    }, [queryParams.search, params.id, searchParams]);

    useEffect(() => {
        // setApiPage(1);
        !restrictApiCall && fetchProducts();
    }, [apiPage, restrictApiCall])


    useEffect(() => {
        if (window.screen.width <= SCREEN_SIZE.MOBILE) {
            setIsMobile(true);
        } else {
            setIsMobile(false);
        }
    }, [window.screen.width, isMobile]);

    const renderBreadCrumbs = () => {
        return (
            <div className={`${isMobile ? '' : 'mt-3 d-none'} container d-md-block d-lg-block d-xl-block d-xxl-block`}>
                <Breadcrumb>
                    <Breadcrumb.Item>
                        <Link to={'/shop'}
                              className={'text-black text-decoration-none bebasFont font-20 font-weight-800'}>
                            Home
                        </Link>
                    </Breadcrumb.Item>
                    {'props.productId' && <Breadcrumb.Item>
                        <Link to={`/shop/product/${params.id}`}
                              className={`text-decoration-none bebasFont font-20 text-light-gray`}>
                            {countryName === '' ? 'country Name' : countryName}
                        </Link>
                    </Breadcrumb.Item>}
                </Breadcrumb>
            </div>
        )
    }

    const renderProductItem = () => {
        let imageStyle = {
            height: isMobile ? '135px' : '230px',
            objectFit: isMobile ? 'contain' : 'contain',
        }

        return (
            <div
                className={'pb-5'}>
                <div className={'row'}>
                    {productData != null && productData?.map((value, index) => (
                        <a
                            key={index}
                            rel={'noreferrer'}
                            href={`${value.url}`}
                            target={'_blank'}
                            className={isMobile ? 'col-6 mt-3 text-decoration-none align-items-stretch d-flex' : "text-decoration-none align-items-stretch d-flex col-lg-3 col-md-3 col-sm-6 col-xs-6 mt-4"}>
                            <div className={'text-dark'}
                            >
                                <Card className="border-0  rounded-custom h-100">
                                    <Card.Img
                                        variant="top"
                                        className={'img rounded-custom'}
                                        src={value?.image}
                                        alt="Card image"
                                        onError={addDefaultSrc}
                                        style={imageStyle}
                                    />
                                    <Card.Body>
                                        <Card.Title>
                                            <p className={`sfProDisplayFont text-center ${isMobile ? 'font-12' : 'font-16'}`}>
                                                {value?.name}
                                            </p>
                                        </Card.Title>
                                        <Card.Text>
                                            {JSON.parse(value?.discount_percentage) === 0 ?
                                                <p className={`sfProDisplayFontBold text-center  ${isMobile ? 'font-12' : 'font-16'}`}>
                                                    £ {value?.original_price}
                                                </p>
                                                :
                                                <div className={'justify-content-center d-flex'}>
                                                    <span style={{opacity: '0.5', textDecoration: 'line-through'}}
                                                          className={`me-2 text-center sfProDisplayFontBold ${isMobile ? 'font-12' : 'font-16'}`}>
                                                        £ {value?.original_price}
                                                    </span>
                                                    <span className={`ms-2 sfProDisplayFontBold text-center font-fanzine ${isMobile ? 'font-12' : 'font-16'}`}>
                                                        £ {value?.current_price}
                                                    </span>
                                                </div>
                                            }
                                        </Card.Text>
                                    </Card.Body>

                                </Card>
                            </div>
                        </a>
                    ))
                    }
                </div>
            </div>
        )
    }

    const render = () => {
        return (
            <div className={`container pt-5 ${isMobile && 'mt-n5'} `}>
                {renderBreadCrumbs()}
                <Loader loading={loading}/>
                {renderProductItem()}
            </div>
        );
    }

    return render();
}

export default FanzineFanaticDetails;
