import TopMatchesSlider from '../../Components/Slider/TopMatchesSlider';
import {useLocation} from 'react-router-dom';
import React, {useEffect, useState} from 'react';
import {SCREEN_SIZE} from '../../Configs/Constants';
import {useSelector} from 'react-redux';
import {COLORS, FONT_COLOR, TEAM_NAME,} from '../../Configs/Targets';
import {getHostname} from '../../CustomFunctions/getHostname';
import MoreStories from '../../Components/Layout/MoreStories';
import Shop from '../Shop';
import RightNewsAds from '../../Components/Layout/News/RightNewsAds';
import NewsSectionHeading from '../../Components/NewsSectionHeading';
import CampaignPopup from '../../Components/Campaigns/CampaignPopup';
import LeagueCodePopup from '../../Components/Campaigns/LeagueCodePopup';
import {get} from '../../Axios/get';
import Cookies from "universal-cookie";
import {CampaignEndPoint} from '../../Axios/EndPoints';
import LatestSection from "../../Components/Layout/LatestSection/LatestSection";
import Podcasts from '../../Components/Layout/PodcastSection/Podcasts';
import Standings from '../../Components/Standings/Standings';
import SpecificLeagueSection from '../../Components/Layout/SpecificLeagueSection/SpecificLeagueSection';
import ResultsFixtures from "../../Components/ResultsFixtures/ResultsFixtures";
import {Ad} from "react-ad-manager";

const Homepage = () => {

    const store = useSelector((state) => state);

    const location = useLocation();
    const cookies = new Cookies();

    const hostName = window.location.hostname;
    const domainObject = getHostname(hostName);
    const target = domainObject.host;

    const [isMobile, setIsMobile] = useState(false);
    const [clubDetails, setClubDetails] = useState(null);
    const [page, setPage] = useState(1);
    const [campaign, setCampaign] = useState([]);
    const [campaignModalShow, setCampaignModalShow] = useState(false);
    const [campaignLeagueCodeModalShow, setCampaignLeagueCodeModalShow] = useState(false);
    const [crowdFundingModalShow, setCrowdFundingModalShow] = useState(true);

    const [isThereShopItems, setIsThereShopItems] = useState(false);


    const fetchCampaigns = () => {
        get(CampaignEndPoint)
            .then((response) => {
                let campaignData = response.data.data;
                // console.log("campaign", campaignData.length);
                let tempCampaign = [];
                let campaign = null;
                if (campaignData.length) {
                    campaignData.map((data) => {
                        campaign = {
                            id: data.id,
                            campaign_id: data.campaign_id,
                            type: data.type,
                            leagueCode: data.league_code,
                            image: data.image,
                            title: data.title,
                            description: data.description,
                            questions: data.questions,
                        };
                        if (data.display_page === "home") {
                            tempCampaign.push(campaign);
                        }
                    });
                    const hostName = window.location.hostname;
                    const domainObject = getHostname(hostName);
                    const target = domainObject.host;
                    if (cookies.get(`fanzine_campaign_${campaign.campaign_id}_${target}`) !== "1" && !campaignModalShow) {
                        setCampaignModalShow(true);
                    }
                }
                setCampaign(tempCampaign);
            })
            .catch((err) => {
                console.log("campaign_err", err);
            });
    };


    useEffect(() => {
        if (window.screen.width <= SCREEN_SIZE.MOBILE) {
            setIsMobile(true);
        } else {
            setIsMobile(false);
        }
    }, [window.screen.width, isMobile]);

    useEffect(() => {

        let tmp = store.misc.appInit || {};
        tmp.clubName = (tmp.team_name || TEAM_NAME[target]) === 'HALA-MADRID' ? 'Real Madrid' : (tmp.team_name || TEAM_NAME[target]);
        tmp.clubId = tmp.team_id;
        setClubDetails(tmp);
    }, [store.misc.appInit]);

    useEffect(() => {
        campaign.length <= 0 && fetchCampaigns();
    }, []);

    const renderCampaign = () => {
        return (
            <div>
                {/*{*/}
                {/*    <CrowdFundingModal*/}
                {/*        isMobile={isMobile}*/}
                {/*        modalShow={crowdFundingModalShow}*/}
                {/*        setModalShow={setCrowdFundingModalShow}*/}
                {/*        />*/}
                {/*}*/}
                {
                    <CampaignPopup
                        show={campaignModalShow}
                        onHide={() => setCampaignModalShow(false)}
                        showLeageCode={campaignLeagueCodeModalShow}
                        setCampaignLeagueCodeModalShow={setCampaignLeagueCodeModalShow}
                        onHideLeagueCode={() => setCampaignLeagueCodeModalShow(false)}
                        campaign={campaign}
                    />
                }
                <LeagueCodePopup
                    show={campaignLeagueCodeModalShow}
                    onHide={() => setCampaignModalShow(false)}
                    showLeageCode={campaignLeagueCodeModalShow}
                    setCampaignLeagueCodeModalShow={setCampaignLeagueCodeModalShow}
                    onHideLeagueCode={() => setCampaignLeagueCodeModalShow(false)}
                    campaign={campaign}
                />
            </div>
        )
    };


    const renderClubPage = () => {

        return (
            <section className={'bg-white pt-4'}>
                {renderCampaign()}
                {!isMobile && <TopMatchesSlider isMobile={isMobile}/>}
                {/*<TopStories title={'Latest News'} isMobile={isMobile} location={location}*/}
                {/*target={target} clubDetails={clubDetails}*/}
                {/*/>*/}

                {clubDetails?.team_id &&
                    <LatestSection data={clubDetails} isMobile={isMobile} target={target} showMoreStories={false}/>}

                {/*{clubDetails?.team_id &&<LatestNews data={clubDetails} isMobile={isMobile} target={target} showMoreStories={false}/>}*/}

                {/*{isMobile && target && clubDetails && <UpComingMatch/>}*/}

                {isMobile && <ResultsFixtures/>}
                {isMobile && <Standings isComponent={true} isHalfTable={true}/>}
                {isMobile && target && clubDetails && <Podcasts data={clubDetails} isMobile={isMobile}
                                                                target={target}/>}

                <SpecificLeagueSection isMobile={isMobile} newsOnly={true} leagueId={clubDetails?.league_gs_id}/>

                {/*Section 2 - Shop, Social*/}
                <div className={`${isMobile ? 'w-100' : 'container'} mt-2 bg-white`}>
                    <div className={`mt-2 ${!isMobile && 'd-flex'}`}>
                        <div className="col-xxl-8 col-xl-8 col-lg-8 col-md-8 col-sm-8">
                            <NewsSectionHeading title={'Shop'} bgColor={COLORS[target]}
                                                fontColor={FONT_COLOR[target]}/>
                            <Shop isMobile={isMobile} target={target}/>
                        </div>
                        <RightNewsAds isMobile={isMobile}/>
                    </div>
                </div>
                <div className={`${isMobile ? 'w-100' : 'container'}`}>
                    <MoreStories data={clubDetails} setPage={setPage} target={target}/>
                </div>
            </section>
        );
    };

    const renderHomePageMain = () => {
        return renderClubPage();
    };

    return renderHomePageMain();

};

export default Homepage;
