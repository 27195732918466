import React, {Component} from 'react'

class RecommendationUnit extends Component {

    componentDidMount() {
        (window._taboola = window._taboola || []).push({
            article: 'auto',
            /*url: window.location.href,*/
            mode: 'thumbnails-a',
            container: 'taboola-below-article-thumbnails-1',
            placement: 'Below Article Thumbnails 1',
            target_type: 'mix'
        });
        window._taboola.push({flush: true});
    }

    render() {
        return (
            <div id="taboola-below-article-thumbnails-1"/>
        );
    }
}

export default RecommendationUnit;