import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {getHostname} from './CustomFunctions/getHostname';
import Favicon from 'react-favicon';
import {LOGO_SMALL} from './Configs/Targets';
import store from '../src/redux/store'
import {Provider} from 'react-redux';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import "react-loading-skeleton/dist/skeleton.css";
import './index.css';

const hostName = window.location.hostname;
const domainObject = getHostname(hostName);
const target = domainObject.host;


ReactDOM.hydrate(
    <>
      <Favicon url={LOGO_SMALL[target]}/>
      <Provider store={store}>
      <App />
      </Provider>
    </>,
    document.getElementById('root')
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
