import {useState} from "react";
import {useEffect} from "react";
import {SCREEN_SIZE} from "../../../Configs/Constants";
import { Col, Container, Row} from "react-bootstrap";
import ClubSquadsCard from "../../../Components/FanzineSports/ClubSquads/ClubSquadsCard";

const ClubSquads = () =>
{
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (window.screen.width <= SCREEN_SIZE.MOBILE) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }

  }, [window.screen.width, isMobile]);

  const renderMobileView = () => {
    return(
      <>
        <Container style={{ backgroundColor: '#ededed' }} className="pt-5 pb-3">
          <span className={"sfProDisplayFontBold font-size-18"}>GOALKEEPERS</span>
          <Row className="justify-content-between">
            {
              [1,2,3,4].map((item, index) => {
                return(

                  <Col key={index}  xs={6} style={{width: "167.5px", height: "167.5px"}}>
                    <ClubSquadsCard/>
                  </Col>
                )
              })
            }
          </Row>

          <span className={"sfProDisplayFontBold font-size-18"}>DEFENDERS</span>
          <Row className="justify-content-between">
            {
              [1,2,3,4].map((item, index) => {
                return(

                  <Col key={index}  xs={6} style={{width: "167.5px", height: "167.5px"}}>
                    <ClubSquadsCard/>
                  </Col>
                )
              })
            }
          </Row>

          <span className={"sfProDisplayFontBold font-size-18"}>MIDFIELDERS</span>
          <Row className="justify-content-between">
            {
              [1,2,3,4,5,6].map((item, index) => {
                return(

                  <Col key={index}  xs={6} style={{width: "167.5px", height: "167.5px"}}>
                    <ClubSquadsCard/>
                  </Col>
                )
              })
            }
          </Row>

          <span className={"sfProDisplayFontBold font-size-18"}>ATTACKERS</span>
          <Row className="justify-content-between">
            {
              [1,2].map((item, index) => {
                return(

                  <Col key={index}  xs={6} style={{width: "167.5px", height: "167.5px"}}>
                    <ClubSquadsCard/>
                  </Col>
                )
              })
            }
          </Row>
        </Container>
      </>
    )
  }

 return isMobile ? renderMobileView() : null;
}

export default ClubSquads;
