import greenCheck from "../../assets/images/greenCheck.png";
import { BsCheckLg } from "react-icons/bs";
import desktop from "../../assets/images/desktop.png";
import {useEffect, useState} from 'react';
const Opportunities = (props) => {
  const { isMobile } = props;
  const [backgroundSize,setBackGroundSize] = useState('900px');

  useEffect(() => {
    if (window.screen.width >= 2550) {
      setBackGroundSize('950px')
    } else if (window.screen.width >= 1360 && window.screen.width < 1920) {
      setBackGroundSize('900px')
    }
  }, [window.screen.width]);

  const renderDesktopView = () => {
    return (
      <div
          style={{height:backgroundSize}}
          className={"container-fluid blackBackgroundTwo flex-grow-1 "}>
        <div className={"container "}>
          <div className={"row "}>
            <div className={"col-md-12 col-lg-12 col-xl-12 col-xxl-12 mt-5"}>
              <div className={"row justify-content-center"}>
                <span className={"text-white Montserrat-Bold font-48 font-weight-800 text-center"}>
                  OPPORTUNITIES
                </span>
              </div>
            </div>
          </div>
          <div className={'row d-flex align-items-center pt-5 justify-content-center'}>
            <img alt={'oppourtunities'}
            src={require('../../assets/images/oppourtunities.png')}
                 className={'img-fluid'}
                 style={{
                   objectFit:'contain',
                   height:650,
                   width:'auto'
                 }}

            />

          </div>
        </div>
      </div>
    );
  };

  const renderMobileView = () => {
    return (
      <div className={"blackBackground container-fluid"}>
        <div className={"row"}>
          <span
            className={"pb-5 Montserrat-Bold font-25 text-white font-weight-800 text-center mt-5"}
          >
            OPPORTUNITIES
          </span>
          <div className={'col-12 pb-3'}>
            <div className={'row'}>
              <div className={'col-4'}>
                <img src={require('../../assets/images/mobile_ad.png')}
                alt={'mobile ad 1'}
                     style={{
                       width:100,
                       height:100,
                       objectFit:'contain',
                     }}
                />
              </div>
              <div className={'col-8 p-0 m-0'}>
                <h4
                    style={{
                      color:'#f28ac5'
                    }}
                    className={'Montserrat-Bold p-0 m-0'}>
                  App Advertising
                </h4>
                <p
                    style={{
                      color:'#878787'
                }}
                    className={'Montserrat-Regular'}>
                  Banner, Interstitial, Native and
                  Video ads. Notifications & Sponsorship
                </p>
              </div>
            </div>
          </div>
          <div className={'col-12 pb-3'}>
            <div className={'row'}>
              <div className={'col-4'}>
                <img src={require('../../assets/images/mobile_ad2.png')}
                alt={'mobile ad 1'}
                     style={{
                       width:100,
                       height:100,
                       objectFit:'contain',
                     }}
                />
              </div>
              <div className={'col-8 p-0 m-0'}>
                <h4
                    style={{
                      color:'#ffdd00'
                    }}
                    className={'Montserrat-Bold p-0 m-0'}>
                  Web Advertising
                </h4>
                <p
                    style={{
                      color:'#878787'
                }}
                    className={'Montserrat-Regular'}>
                  Display, Banner, Native and Video ads..
                </p>
              </div>
            </div>
          </div>
          <div className={'col-12 pb-3'}>
            <div className={'row'}>
              <div className={'col-4'}>
                <img src={require('../../assets/images/mobile_ad3.png')}
                alt={'mobile ad 1'}
                     style={{
                       width:100,
                       height:100,
                       objectFit:'contain',
                     }}
                />
              </div>
              <div className={'col-8 p-0 m-0'}>
                <h4
                    style={{
                      color:'#3ad682'
                    }}
                    className={'Montserrat-Bold p-0 m-0'}>
                  Social Advertising
                </h4>
                <p
                    style={{
                      color:'#878787'
                }}
                    className={'Montserrat-Regular'}>
                  Affiliate links, Promoted posts and
                  Campaigns.
                </p>
              </div>
            </div>
          </div>
          <div className={'col-12 pb-3'}>
            <div className={'row'}>
              <div className={'col-4'}>
                <img src={require('../../assets/images/mobile_ad4.png')}
                alt={'mobile ad 1'}
                     style={{
                       width:100,
                       height:100,
                       objectFit:'contain',
                     }}
                />
              </div>
              <div className={'col-8 p-0 m-0'}>
                <h4
                    style={{
                      color:'#3f9fe0'
                    }}
                    className={'Montserrat-Bold p-0 m-0'}>
                  Email Marketing
                </h4>
                <p
                    style={{
                      color:'#878787'
                }}
                    className={'Montserrat-Regular'}>
                  First party data, club specific
                  and weekly campaigns.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderMain = () =>
    isMobile ? renderMobileView() : renderDesktopView();

  return renderMain();
};

export default Opportunities;
