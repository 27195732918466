import * as Icon from "react-bootstrap-icons";
import {Breadcrumb} from "react-bootstrap";
import {Link, useLocation} from 'react-router-dom';
import React, {useEffect, useState} from "react";
import {COLORS, COLORS_RGBA, NAV_CLASS, TEXT_COLOR} from "../../Configs/Targets";
import {shopPageImages} from "../Carousel/images";
import {SCREEN_SIZE} from "../../Configs/Constants";
import {useSelector} from "react-redux";
import {useWindowSize} from "../../CustomFunctions/useWindowSize";
import {MiniCart} from "../Checkout/MiniCart";
import {getHostname} from "../../CustomFunctions/getHostname";

export const Banner = (props) => {

    const cart = useSelector((state) => state);
    const location = useLocation()
    let windowSize = useWindowSize();

    let [minTotalForFreeDelivery, setMinTotalForFreeDelivery] = useState(null);
    let [cartQuantity, setCartQuantity] = useState(null);
    let [showMiniCart, setShowMiniCart] = useState(true);
    let [freeShippingBanner, setFreeShippingBanner] = useState(true);
    const [currencyCodeLocal, setCurrencyCodeLocal] = useState('GBP');
    const [currencySymbolLocal, setCurrencySymbolLocal] = useState('£');

    const hostName = window.location.href;
    const host = window.location.hostname;
    const domainObject = getHostname(host);
    let target = domainObject.host;
    let bgColor = NAV_CLASS[target], textColor = TEXT_COLOR[target], color = COLORS[target],
        colorRgba = COLORS_RGBA[target], topBannerStyle = {
            width: '100%',
            height: '144px',
            background: `linear-gradient(to bottom, ${colorRgba}0.71) 45%, ${colorRgba}1)), url(${require('../../assets/images/shop_top_banner.png')})`,
            backgroundSize: 'cover'
        }, currentItem = shopPageImages[1];
    let calculatedMinimumPrice = 0;
    let isMobile = false;
    if (windowSize.width <= SCREEN_SIZE.MOBILE) {
        isMobile = true;
    }

    const setMinAmountForFreeDelivery = () => {
        if (typeof cart.timezone.timezone !== 'undefined' && cart.timezone.timezone.length !== 0) {
            let priceBasedShippingRates = cart.timezone.timezone.price_based_shipping_rates;
            let minPrice = priceBasedShippingRates.filter((value) => value.name === "Free Delivery");
            setMinTotalForFreeDelivery(parseFloat(minPrice[0].min_order_subtotal * 100) / 100);
        } else {
            setMinTotalForFreeDelivery(70);
        }
    }

    const minPriceForFreeShipping = (grandTotal) => {
        let value = minTotalForFreeDelivery - grandTotal;
        // console.log("cart?.misc?.currentCurrency >>>", cart?.misc?.currentCurrency)
        let currency = cart?.misc?.currentCurrency || "";
        let allCurrencies = cart?.misc?.currencies || "";
        let currentCurrency =  target === 'fanzine' ? '' : allCurrencies[currency?.code?.toLowerCase()];
        return Math.floor((Math.round(value * 100) / 100) * (currentCurrency || 1));
    }

    const calculateCartItems = () => {
        let cartItemQuantity = 0;
        cart.cart.cartItems.length && cart.cart.cartItems.map((item) => {
            cartItemQuantity += item.quantity ? item.quantity : 0;
        })
        return cartItemQuantity;
    }


    useEffect(() => {
        setMinAmountForFreeDelivery();
        calculateCartItems();
        if (hostName.split("/").pop() === "checkout") {
            setShowMiniCart(false);
        }
        if (hostName.split("/").pop() === "shop") {
            setFreeShippingBanner(false);
        }
    }, [cart.cart.cartItems]);

    useEffect(() => {
        setMinAmountForFreeDelivery();
        if (hostName.split("/").pop() === "checkout") {
            setShowMiniCart(false);
        }
        if (hostName.split("/").pop() === "shop") {
            setFreeShippingBanner(false);
        }
    }, []);

    useEffect(() => {
        setMinAmountForFreeDelivery();
        if (hostName.split("/").pop() === "checkout") {
            setShowMiniCart(false);
        }
        if (hostName.split("/").pop() === "shop") {
            setFreeShippingBanner(false);
        }
        setCurrencyCodeLocal(cart?.misc?.currentCurrency?.code || 'GBP');
        setCurrencySymbolLocal(cart?.misc?.currentCurrency?.symbol || '£');
    }, [cart.timezone.timezone, cart?.misc?.currentCurrency]);


    const render = () => {
        return (
            <>
                <div className={'shop-top-banner mt-2'} style={topBannerStyle}>
                    <div className={'container h-100'}>
                        <div className={`row h-100`} style={{paddingTop: '12px'}}>
                            <div
                                className={`col bebasFont text-white d-flex flex-column justify-content-center text-start`}
                                style={{fontSize: '44px', paddingTop: '12px'}}>Shop
                            </div>
                            {minPriceForFreeShipping(cart?.cart?.grandTotal) > 0 ? <div
                                className={`col bebasFont text-yellow d-flex flex-column justify-content-center text-center h2`} style={{paddingTop: '10px'}}>
                                only {currencySymbolLocal} {minPriceForFreeShipping(cart?.cart?.grandTotal)} away from free shipping
                            </div> : <div
                                className={`col bebasFont text-yellow d-flex flex-column justify-content-center text-center h2 text-nowrap`} style={{paddingTop: '10px'}}>
                                CONGRATULATIONS! YOU NOW HAVE FREE SHIPPING.
                            </div>}
                            <div
                                className={`col text-white d-flex flex-column justify-content-center text-end font-weight-100 text-nowrap`}>
                                <div className={'sfProDisplayFont d-inline-flex justify-content-end'}>
                                    <div
                                        className={`bg-white p-2 rounded-circle me-2 ${cart?.cart?.cartItems && (cart?.cart?.cartItems?.length && showMiniCart) ? "cart" : ""}`}>
                                        <Link to="/checkout" style={{textDecoration: "none", color: "#fff"}}>
                                            <Icon.Cart4 color={colorRgba + 1} size='30'/>
                                        </Link>
                                        <div className={`mini-cart`} style={{width: "700px"}}>
                                            <MiniCart checkoutSet={props.checkoutSet} setShowMiniCart={setShowMiniCart}/>
                                        </div>
                                    </div>
                                    <div className={'d-flex flex-column justify-content-center'}>
                                        <Link to="/checkout" style={{textDecoration: "none", color: "#fff"}}>
                                            <div className={'d-flex flex-row justify-content-center cart'}>
                                                <span className={'font-weight-100'}>Cart</span>
                                                <span
                                                    className={'font-weight-100 ps-1'}>({calculateCartItems()})</span>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {freeShippingBanner ? minPriceForFreeShipping(cart.cart.grandTotal) > 0 ? <div
                    className={`col-12 bebasFont text-black bg-yellow justify-content-center text-center h2 pt-3 pb-3 d-block d-md-none d-lg-none d-xl-none d-xxl-none mb-0`}>
                    only {currencySymbolLocal} {minPriceForFreeShipping(cart.cart.grandTotal)} away from free shipping
                </div> : <div
                    className={`col-12 bebasFont text-black bg-yellow justify-content-center text-center h2 pt-3 pb-3 d-block d-md-none d-lg-none d-xl-none d-xxl-none mb-0 text-nowrap`}>
                    CONGRATULATIONS! YOU NOW HAVE FREE SHIPPING.
                </div> : <></>}

                {props.breadcrums ?
                    <div className={'container mt-3 d-none d-md-block d-lg-block d-xl-block d-xxl-block'}>
                        <Breadcrumb>
                            <Breadcrumb.Item>
                                <Link to={'/shop'}
                                      className={'text-black text-decoration-none bebasFont font-weight-800'}>
                                    Home
                                </Link>
                            </Breadcrumb.Item>
                            {props.type && <Breadcrumb.Item>
                                <Link to={target === 'fanzine' ? `/${location?.pathname?.split('/')[1]}/shop/${props.type}` : `/shop/${props.type}`}
                                      className={`text-decoration-none bebasFont text-black`}>
                                    {props.type}

                                </Link>
                            </Breadcrumb.Item>}
                            {props.productId && <Breadcrumb.Item>
                                <Link to={target === 'fanzine' ? `/${location?.pathname?.split('/')[1]}/shop/product/${props.productId}` : `/shop/product/${props.productId}`}
                                      className={`text-decoration-none bebasFont text-light-gray`}>
                                    {props.product}
                                </Link>
                            </Breadcrumb.Item>}
                        </Breadcrumb>
                    </div> : <></>}
            </>
        );
    }
    return render();
}
