import {useEffect} from 'react';
import {withRouter} from "../CustomFunctions/withRouter";
import {useLocation} from "react-router-dom";

const ScrollToTop = (props) => {
    const location = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);

    return (
        <>{props.children}</>
    );
}

export default withRouter(ScrollToTop);