import notifications from "../../assets/images/Notifications.png";
import popularPages from "../../assets/images/PopularPages.png";
import affiliateLink from "../../assets/images/social_ad_1.png";
import campaigns from "../../assets/images/social_ad_2.png";
import selfPromotion from "../../assets/images/social_ad_3.png";
import Carousel from "./Carousel";
import {useEffect, useState} from 'react';
const SocialAdvertising = (props) => {
  const { isMobile } = props;
  const [backgroundSize,setBackGroundSize] = useState('1000px');

  useEffect(() => {
    if (window.screen.width >= 2550) {
      setBackGroundSize('1000px')
    } else if (window.screen.width >= 1360 && window.screen.width < 1920) {
      setBackGroundSize('950px')
    }
  }, [window.screen.width]);
  const renderDesktopView = () => {
    return (
      <div
          style={{
            height:backgroundSize,
          }}
          className={"container-fluid blackBackgroundTwo flex-grow-1 "}>
        <div className={"container "}>
          <div className={"row pb-4"}>
            <div className={"col-md-12 col-lg-12 col-xl-12 col-xxl-12 mt-5"}>
              <div className={"row justify-content-center"}>
                <span
                    style={{fontWeight:'bold'}}
                    className={"font-fanzine font-50 Montserrat-Bold text-center"}>
                  SOCIAL &nbsp;
                                  <span
                                      style={{fontWeight:'bold'}}
                                      className={"text-white font-50 Montserrat-Bold text-center"}>
                  ADVERTISING
                </span>
                </span>
              </div>

              {/*<div className={"row justify-content-center"}>*/}
              {/*  <span className={"font-fanzine font-26 text-center"}>*/}
              {/*    Advertise your brand or product to our large social media*/}
              {/*    audience*/}
              {/*  </span>*/}
              {/*</div>*/}
            </div>
          </div>
          <div className={"row"}>
            <div className={"col-md-4 col-lg-4 col-xl-4 col-xxl-4"}>
              <div className={"row"}>
                <span
                  className={"font-34  text-center Montserrat-Regular text-white"}
                >
                  Affiliate Links
                </span>
                <div
                  className={"row justify-content-center align-items-center"}
                >
                  <img
                    className={"w-100 mt-n5"}
                    src={require('../../assets/images/affliatedLink.png')}
                    alt={"affiliateLink"}
                    style={{ objectFit: "contain", height: "806px", width:'465px' }}
                  />
                </div>
              </div>
            </div>
            <div className={"col-md-4 col-lg-4 col-xl-4 col-xxl-4"}>
              <div className={"row"}>
                <span
                  className={"font-34  text-center Montserrat-Regular text-white"}
                >
                  Campaigns
                </span>
                <div
                  className={"row justify-content-center align-items-center"}
                >
                  <img
                    className={"w-100 mt-n5"}
                    src={require('../../assets/images/campaign.png')}
                    alt={"campaigns"}
                    style={{ objectFit: "contain", height: "812px", width:'442px' }}
                  />
                </div>
              </div>
            </div>
            <div className={"col-md-4 col-lg-4 col-xl-4 col-xxl-4"}>
              <div className={"row"}>
                <span
                  className={"font-34  text-center Montserrat-Regular text-white"}
                >
                  Self Promotion
                </span>
                <div
                  className={"row justify-content-center align-items-center"}
                  style={{ height: "535px" }}
                >
                  <img
                    className={"w-100 mt-n5"}
                    src={require('../../assets/images/softPromotion.png')}
                    alt={"selfPromotion"}
                    style={{ objectFit: "contain", height: "804px", width:'469px' }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  const renderMobileView = () => {
    return (
      <div
          style={{height:'780px'}}
          className={"blackBackground container"}>
        <div className={"row"}>
          <span
            className={"font-25 Montserrat-Bold text-white  text-center mt-5"}
          >
            SOCIAL ADVERTISING
          </span>
          <span className={"font-fanzine Montserrat-Regular font-16  text-center"}>
            Advertise your brand or product to our large social media audience
          </span>
        </div>
        <Carousel
            isAppSponsorShip={true}
            isAdvertisement={true}
            // imageOneText={'Affiliated Links'}
            // imageTwoText={'Affiliated Links'}
            // imageThreeText={'Affiliated Links'}
            isMobile={isMobile}
          imageOne={affiliateLink}
          imageTwo={campaigns}
          imageThree={selfPromotion}
        />
      </div>
    );
  };

  const renderMain = () => {
    return isMobile ? renderMobileView() : renderDesktopView();
  };

  return renderMain();
};

export default SocialAdvertising;
