import axios from "axios";
import {SHOPIFY_HEADERS} from "../Configs/Constants";
import {STORE_BASE_URL} from "./EndPoints";

export const graphql = (data, method = 'POST') => {
    let headers = SHOPIFY_HEADERS;
    if (method === 'POST') {
        return axios.post(STORE_BASE_URL, data,
            {headers});
    }
    if (method === 'GET') {
        return axios.get(STORE_BASE_URL,
            {headers});
    }
}