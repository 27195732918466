import {useSelector} from 'react-redux';
import {useEffect, useState} from 'react';
import PreviewUnavailablePlayers from './PreviewUnavailablePlayers';
import PreviewRecentForm from './PreviewRecentForm';
import PreviewSocial from './PreviewNews';
import {SCREEN_SIZE} from '../../../Configs/Constants';
const SingleFootballMatchContentPreview = (props) => {
  let store = useSelector((state) => state);
  const {addDefaultSrc} = props
  let h2h, match_prediction, recent_form, sidelined, standings
  let [isMobile, setIsMobile] = useState(false);
  const isCurrent = props.currentActive === 'preview';

  let matchData = [];
  if (store?.football?.singleFootballMatch !== []) {
    matchData = store?.football?.singleFootballMatch;
  }

  useEffect(() => {
    if (window.innerWidth <= SCREEN_SIZE.MOBILE) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, [window.innerWidth, isMobile]);


  if (matchData) {

    h2h = matchData.h2h;
    match_prediction = matchData.match_prediction
    recent_form = matchData.recent_form
    sidelined = matchData.sidelined
    standings = matchData.standings
  }


  const render = () => {
    return (
        <div>
          <div className={'mb-5'}>
            <PreviewUnavailablePlayers isMobile={isMobile} sidelined={sidelined} addDefaultSrc={addDefaultSrc}/>
            <PreviewRecentForm
                matchData={matchData?.match}
                isCurrent={isCurrent}
                isMobile={isMobile} recent_form={recent_form} addDefaultSrc={addDefaultSrc}/>
          </div>
          <PreviewSocial isCurrent={isCurrent} />
        </div>
    )
  }


  return render();

}
export default SingleFootballMatchContentPreview;
