import {CartActionTypes} from "../constants/CartActionTypes";


export const setCartItems = (data) => {
    return {
        type: CartActionTypes.SET_CART_ITEMS,
        payload: data,
    }
}

export const setGrandTotal = (data) => {
    return {
        type: CartActionTypes.SET_GRAND_TOTAL,
        payload: data,
    }
}

export const setCheckoutId = (data) => {
    return {
        type: CartActionTypes.SET_CHECKOUT_ID,
        payload: data,
    }
}