import React, {useEffect} from 'react';
import SkySportsAd from '../../Ads/SkySportsAd';
import NewsOnSlider from '../../Slider/NewsOnSlider';
import GoogleAd from '../../Ads/GoogleAd';
import RightNewsAds from '../../Layout/News/RightNewsAds';
import FanzineSportsNewsCard
  from '../FanzineSportsNewsCard/FanzineSportsNewsCard';
import {
  CLUBS_MOBILE_BG,
  COLORS,
  FONT_COLOR,
  NAV_CLASS,
  TEXT_COLOR,
} from '../../../Configs/Targets';
import {getHostname} from '../../../CustomFunctions/getHostname';
import FanzineSportsNewsOnSlider
  from '../FanzineSportsNewsOnSlider/FanzineSportsNewsOnSlider';
import FanzineSportsVideosOnSlider
  from '../FanzineSportsVideosOnSlider/FanzineSportsVideosOnSlider';
import {useLocation} from 'react-router-dom';

const FanzineSportsNewsLayout = (props) => {
  const {news, isMobile, title, leagues, latestNews, transferNews, isClubWebsite} = props;
  let LeagueName = '';
  const location = useLocation();
  const hostName = window.location.hostname;
  const domainObject = getHostname(hostName);
  const target = domainObject.host;
  const navColor = NAV_CLASS[target];
  const textColor = TEXT_COLOR[target];
  const fontColor = FONT_COLOR[target];
  const MobileBG = CLUBS_MOBILE_BG[target];


  useEffect(()=>{

    console.log('navColor =',navColor)
    console.log('textColor =',textColor)
    console.log('fontColor =',fontColor)
    console.log('isClubWebsite =',isClubWebsite)

  },[location,news,latestNews])


  const renderNews = (data, tmpTitle) => {


    return (
        <div className={`container ${isMobile ? isClubWebsite ? `${'MobileBG'}` : 'pt-1 bg-black' : 'mt-1'}`}>
            <div className={`row`}>
              <div
                  className={`col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12`}>
                <div className={`row ${ !isClubWebsite ? 'pt-5' : ''}`}>
                  {!isClubWebsite && <div
                      className={'position-absolute mt-n2r pb-1  text-white sfProDisplayFontBold font-20'}
                  >
                    <img src={require(
                        '../../../assets/images/fanzineSports/clock-fill@3x.png')}
                         alt={'icon'} height={19} width={19}
                         className={'me-2'}/>
                    {tmpTitle}
                  </div>
                  }
                  {(data !== undefined && data !== null) &&
                      data?.map((newsInData, index) => {
                        let bigImage = (index === 0);
                        return index === 0 &&
                            (
                                <div className={isClubWebsite ? `${'navColor'}` : 'bg-black'}>
                                  <div
                                      className={`m-0 ${bigImage ?
                                          'col-12 mt-4 p-1' :
                                          'col-xl-6 col-lg-6 col-md-6 p-1'} col-sm-12 col-xs-12`}
                                  >
                                    {index === 3 ? <SkySportsAd/> :
                                        <FanzineSportsNewsCard
                                            news={newsInData}
                                            isClubWebsite={isClubWebsite}
                                            imageHeight={bigImage && isMobile ?
                                                '405px' :
                                                '245px'}
                                        />
                                    }
                                  </div>
                                </div>

                            );
                      })
                  }
                  <FanzineSportsNewsOnSlider id={1}
                                             isClubWebsite={isClubWebsite}
                                             isMobile={isMobile}
                                             // bgColor={COLORS[target]}
                                             fontColor={FONT_COLOR[target]}
                                             imageHeight={'245px'}
                                             data={data}
                                             loading={false}
                                             leagueName={''}
                  />
                  {isClubWebsite  && isMobile &&
                    (
                        <div className={`${''}` }>
                          <div
                              className={`m-0 col-12 mt-4 pb-4  col-sm-12 col-xs-12`}
                          >
                            {
                                <FanzineSportsNewsCard
                                    news={data[data.length-1]}
                                    isClubWebsite={isClubWebsite}
                                    imageHeight={'405px'}
                                />
                            }
                          </div>
                        </div>

                    )
                  }
                  {/*{(data === undefined || data === null) && <LatestNews isMobile={isMobile} isNewsPage={true} limit={leagueCount}/>}*/}
                </div>
              </div>
            </div>
        </div>
    );
  };

  const renderSpecificLeagueNews = (data, latestNews, transferNews) => {

    return data?.map((singleLeague, singleIndex) => {
      return (
          <>
            <NewsOnSlider isMobile={isMobile} id={singleIndex}
                          bgColor={singleLeague[0]?.leagueBG}
                          fontColor={singleLeague[0]?.leagueCL}
                          matchData={singleLeague} loading={false}
                          leagueName={title === 'all' ?
                              singleLeague[0]?.leagueName :
                              title}
            />
          </>
      );
    });

  };

  const renderMain = (data, latestNews, transferNews) => {
    return (title === 'all' ?
        renderSpecificLeagueNews(data, latestNews, transferNews) :
        renderNews(data, title));
  };

  return renderMain(news, latestNews, transferNews);

};
export default FanzineSportsNewsLayout;
