import React, {useEffect, useState} from 'react';

const GoogleAd = (props) => {

    const {minHeight, height, width,isRightNewsAds} = props;

    const [isError, setIsError] = useState(false);

    useEffect(() => {
        try {
            (window.adsbygoogle = window.adsbygoogle || []).push({});
            setIsError(false);
        } catch (e) {
            setIsError(true);
        }
    }, [props, isError]);

    const render = () => {
        if (isError) {
            return (
                <div className={'mb-3'} style={{
                  margin: isRightNewsAds ?  '' : '10px auto 10px auto',
                    textAlign: 'center',
                    width: width || '100%',
                    overflow: 'hidden',
                    minHeight: minHeight || '250px',
                    maxHeight: minHeight || 'auto',
                    height: height || 'auto',
                }}>
                    <ins className={'adsbygoogle'}
                         style={{
                             display: 'block',
                             margin: isRightNewsAds ?  '' : '10px auto 10px auto',
                             textAlign: 'center',
                             width: width || '100%',
                             overflow: 'hidden',
                             minHeight: minHeight || '250px',
                             maxHeight: minHeight || 'auto',
                             height: height || 'auto',
                         }}
                         data-ad-client={'ca-pub-1362215158154361'}
                         data-ad-layout="in-article"
                         data-ad-slot={props.id}
                        // data-ad-format={'auto'}
                        // data-adtest={'on'}
                         data-full-width-responsive="true"
                    />
                </div>
            );
        }
        return (
            <div className={'mb-3'} style={{

                margin: '10px auto 10px auto',
                textAlign: 'center',
                width: width || '100%',
                overflow: 'hidden',
                minHeight: minHeight || '250px',
                maxHeight: minHeight || 'auto',
                height: height || 'auto',
            }}>
                <ins className={'adsbygoogle'}
                     style={{
                         display: 'block',
                         margin: '10px auto 10px auto',
                         textAlign: 'center',
                         width: width || '100%',
                         overflow: 'hidden',
                         minHeight: minHeight || '250px',
                         maxHeight: minHeight || 'auto',
                         height: height || 'auto',
                     }}
                     data-ad-client={'ca-pub-1362215158154361'}
                     data-ad-layout="in-article"
                     data-ad-slot={props.id}
                     data-ad-format={'auto'}
                    // data-adtest={'on'}
                     data-full-width-responsive="true"
                />
            </div>
        );
    };

    return render();

};

export default GoogleAd;
