import {Link, useLocation} from 'react-router-dom';
import MobileNewsMenuItem from './MobileNewsMenuItem';
import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {TEXT_COLOR} from '../../Configs/Targets';
import {getHostname} from '../../CustomFunctions/getHostname';

export const GenderNavbar = (props) => {

  const location = useLocation();
  const {
    isMobile,
    setParams,
    selectedTeamType,
    setSelectedTeamType,
    isFanzineClubMatches,
  } = props;
  const store = useSelector(state => state.football);


  const [currentActive, setCurrentActive] = useState('All');
  const [sportName, setSportName] = useState((location.pathname.split('/'))[1]);
  let queryParams = useLocation();
  const hostName = window.location.hostname;
  const domainObject = getHostname(hostName);
  const target = domainObject.host;

  useEffect(() => {
    //
    // store?.fanzineTeamsLeagues.filter((value)=>{
    //   return value?.teamTypes
    // })

    setSportName((location.pathname.split('/'))[1]);

  }, [props]);

  const renderMobileMenu = () => {

    let sportName = '';
    let selectedSports = '';

    switch (location.pathname.split('/')[1]) {
      case 'football':
        sportName = 'football';
        selectedSports = store?.fanzineTeamsLeagues.filter((value) => {
          return value?.name.toLowerCase() === 'football';
        });

        break;
      case 'cricket':
        sportName = 'cricket';
        selectedSports = store?.fanzineTeamsLeagues.filter((value) => {
          return value?.name.toLowerCase() === 'cricket';
        });

        break;
      case 'nfl':
        sportName = 'nfl';
        selectedSports = store?.fanzineTeamsLeagues.filter((value) => {
          return value?.name.toLowerCase() === 'nfl';
        });

        break;
      case 'nba':
        sportName = 'nba';
        selectedSports = store?.fanzineTeamsLeagues.filter((value) => {
          return value?.name.toLowerCase() === 'nba';
        });

        break;
      case 'mlb':
        sportName = 'mlb';
        selectedSports = store?.fanzineTeamsLeagues.filter((value) => {
          return value?.name.toLowerCase() === 'mlb';
        });

        break;
      default:
        sportName = 'football';
        selectedSports = store?.fanzineTeamsLeagues.filter((value) => {
          return value?.name.toLowerCase() === 'football';
        });

        break;
    }

    const handleClick = (value) => {
      console.log('I am clicked =', value);
      // setParams(value);
    };

    return (
        <div className={`slider-mobile-news-container`}>
          {
            sportName === 'football' ? null :
                <MobileNewsMenuItem
                    handleClick={handleClick}
                    isGenderNavbar={true}
                    inactiveFontColor={'#000000'} isMobile={isMobile}
                    setCurrentActive={setCurrentActive} title={'All'}
                    uri={`All`}
                    selected={currentActive === 'All'}
                >
                </MobileNewsMenuItem>
          }
          {((sportName === 'football' || sportName === 'cricket') &&
                  selectedSports?.length > 0) &&
              Object.keys(selectedSports[0]?.teamTypes)?.map((value, index) => (
                  <MobileNewsMenuItem
                      handleClick={handleClick}
                      isGenderNavbar={true}
                      inactiveFontColor={'#000000'} isMobile={isMobile}
                      setCurrentActive={setCurrentActive}
                      title={selectedSports[0]?.teamTypes[value]}
                      uri={`${value}`}
                      selected={currentActive === value}
                  >
                  </MobileNewsMenuItem>
              ))
          }
          {((sportName !== 'football' || sportName !== 'cricket') &&
                  selectedSports?.length > 0) &&
              selectedSports[0]?.conferences?.map((value, index) => (
                  <MobileNewsMenuItem
                      handleClick={handleClick}
                      isGenderNavbar={true}
                      inactiveFontColor={'#000000'} isMobile={isMobile}
                      setCurrentActive={setCurrentActive} title={value?.name}
                      uri={`/${value?.id}`}
                      selected={currentActive === value}
                  >
                  </MobileNewsMenuItem>
              ))
          }

        </div>
    );
  };

  const renderBigView = () => {

    return (
        <>
          <div
              className={`${isMobile ?
                  'col-7' :
                  'd-flex align-items-center col-xxl-10 col-xl-10 col-lg-9 col-md-8 col-sm-7'}`}>
            {
              isMobile ? renderMobileMenu() :
                  (
                      <div className={`d-flex flex-row  justify-content-start`}>
                        <Link to={`/${sportName}/+''}`}
                              className={`text-white font-weight-500 pe-5  text-decoration-underline-fanzine`}>
                          Men
                        </Link>
                        <Link to={`/${sportName}/news`}
                              className={`text-white font-weight-500 pe-5 text-decoration-underline-fanzine`}>
                          Women
                        </Link>
                        <Link to={`/${sportName}/teams`}
                              className={`text-white font-weight-500 pe-5 text-decoration-underline-fanzine`}>
                          Youth
                        </Link>
                      </div>
                  )
            }
          </div>
        </>
    );
  };


  const renderNewMobileMenu = () => {
    return (
        <div className={'d-flex ms-5 me-5 shadow-nav-dark justify-content-around align-items-center'}
             style={{
               borderRadius:'21px',
               height: '43px',
               backgroundColor: '#e4e4e4'
             }}
        >
          {
              store?.navbarTeamTypes && store?.navbarTeamTypes.map((item) => {
                if (item.display_on.includes('news')) {
                  return (
                      <div
                          onClick={()=>handleSelectTeamType(item.teamType)}
                          className={'text-decoration-none'}
                      >
                        <div className={`${TEXT_COLOR[target]} bebasFont font-18 ps-3 pe-3`}
                             style={{
                               fontWeight: (item.teamType ===
                                   `${selectedTeamType}`) ? '800' : '500',
                               backgroundColor: (item.teamType ===
                                   `${selectedTeamType}`) ? '#fff' : '#e4e4e4',
                               borderRadius: '21px',
                               boxShadow: (item.teamType ===
                                   `${selectedTeamType}`) && '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
                             }}
                        >
                          {item.name}
                        </div>
                      </div>
                  )
                }
              })
          }
        </div>
    );

    // old design
    // return (
    //     <div className={'slider-mobile-news-container shadow-nav-dark'} style={{marginTop: `80px`}}>
    //         {
    //             store.football.navbarTeamTypes && store.football.navbarTeamTypes.map((item) => {
    //                 if (item.display_on.includes('news')) {
    //                     return (
    //                         <MobileNewsMenuItem title={item.name} uri={`/news?teamType=${item.teamType}`} selected={queryParams.search === `?teamType=${item.teamType}`}/>
    //                     )
    //                 }
    //             })
    //         }
    //         {store.football.navbarLeagues && store.football.navbarLeagues.map((item) => (
    //             <MobileNewsMenuItem title={item.name} uri={`/news?league_id=${item.league_id}`} selected={queryParams.search === `?league_id=${item.league_id}`}/>
    //         ))}
    //     </div>
    // );
  }

  const handleSelectTeamType = (value)=>
  {

    // if(value === 'team')
    // {
      setSelectedTeamType(value);
    // }
    // else
    // {
    //   setSelectedTeamType(value)
    // }
    // else if(value === 'team_women')
    // {
    //   setSelectedTeamType('women');
    // }
    // else if(value === 'team_academy')
    // {
    //   setSelectedTeamType('academy');
    // }
  }
  const render = () => {
    return (
        <div
            className={`${isMobile ?
                'w-100' :
                'ps-5'}  d-flex align-items-center fanzine-football-nav`}
            style={{
              height: '3em',
              // position: isMobile && 'fixed',
              // marginTop: (isMobile && selectedTeamType === 'all') ? 140 : (isMobile && selectedTeamType !== 'all') ? 80 : 0,
            }}
        >
          <div
              className={`${isMobile ? '' : 'ps-5'} container`}>
            <div className={`${isMobile ? 'd-flex justify-content-center' : 'ps-5'} row ${isMobile ?
                '' :
                'ms-3'}`}
                 style={{
                   position: 'relative',
                 }}
                 id={'subNavbar'}
            >
              {(isMobile && !isFanzineClubMatches) ? renderMobileMenu()
                  :
                  (isMobile && isFanzineClubMatches) ?
                      renderNewMobileMenu()
                      // (
                      //     <div
                      //         className={`d-flex flex-row  justify-content-start`}>
                      //       <div
                      //           onClick={()=>handleSelectTeamType('all')}
                      //           className={`text-dark font-weight-500 pe-5  text-decoration-underline-fanzine ${selectedTeamType ===
                      //           'all' ?
                      //               'font-weight-800' :
                      //               'font-weight-300'}`}>
                      //         All
                      //       </div>
                      //       <div
                      //           onClick={()=>handleSelectTeamType('men')}
                      //           className={`text-dark font-weight-500 pe-5 text-decoration-underline-fanzine ${selectedTeamType ===
                      //           'men' ?
                      //               'font-weight-800' :
                      //               'font-weight-300'}`}>
                      //         Men
                      //       </div>
                      //       <div
                      //           onClick={()=>handleSelectTeamType('women')}
                      //           className={`text-dark font-weight-500 pe-5 text-decoration-underline-fanzine ${selectedTeamType ===
                      //           'women' ?
                      //               'font-weight-800' :
                      //               'font-weight-300'}`}>
                      //         Women
                      //       </div>
                      //     </div>
                      // )
                      :
                      (
                          ''
                      )
              }
            </div>
          </div>
        </div>
    );
  };

  return isMobile && render();
};
