import {FanzineSportsActionTypes} from "../constants/FanzineSports";

const initialState = {
	isLogin: false,
}

export const FanzineSportsReducer = (state = initialState, action) => {
	switch (action.type) {
		case FanzineSportsActionTypes.SET_IS_LOGIN:
			return {...state, isLogin: action.payload};
		default:
			return state;
	}
}
