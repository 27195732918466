import {ShopifyActionTypes} from "../constants/ShopifyActionTypes";

export const setProductTags = (data) => {
    return {
        type: ShopifyActionTypes.SET_PRODUCT_TAGS,
        payload: data,
    }
}
export const setProductTypes = (data) => {
    return {
        type: ShopifyActionTypes.SET_PRODUCT_TYPES,
        payload: data,
    }
}

export const setCategories = (data) => {
    return {
        type: ShopifyActionTypes.SET_CATEGORIES,
        payload: data,
    }
}

export const setProductColors = (data) => {
    return {
        type: ShopifyActionTypes.SET_PRODUCT_COLORS,
        payload: data,
    }
}

export const setContainerImages = (data) => {
    return {
        type: ShopifyActionTypes.SET_CONTAINER_IMAGES,
        payload: data,
    }
}

export const setBannerImages = (data) => {
    return {
        type: ShopifyActionTypes.SET_BANNER_IMAGES,
        payload: data,
    }
}

export const setAllProducts = (data) => {
    return {
        type: ShopifyActionTypes.SET_PRODUCTS,
        payload: data,
    }
}

export const setRandomProducts = (data) => {
    return {
        type: ShopifyActionTypes.SET_RANDOM_PRODUCTS,
        payload: data,
    }
}
