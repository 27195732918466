import TypesOfSports from "./TypesOfSports";
import Description from "./Description";
// import BecomingTheBiggest from "../../Pages/HomePage/BecomingTheBiggest";
import BecomingTheBiggest from "./BecomingTheBiggest";
const WhoWeAre = (props) => {
  const { isMobile } = props;

  const renderMain = () => {
    return (
      <div className={"container-fluid w-100 blackBackgroundTwo flex-grow-1"}>
        <div className={"row pb-5 pt-5"}>
          <div className={"col-md-12 col-lg-12 col-xl-12 col-xxl-12"}>
            <p className={"d-flex justify-content-center flex-wrap"}>
              <span
                  style={{
                    fontWeight: 'bold',
                  }}
                className={`text-center Montserrat-Bold text-white ${
                  isMobile ? "font-25" : "font-50"
                } px-1`}
              >
                Fanzine is a
                              <span
                                  style={{
                                    fontWeight: 'bold',
                                  }}
                                  className={`text-center Montserrat-Bold font-fanzine ${
                                      isMobile ? "font-24" : "font-50"
                                  } px-1`}
                              >{' '}sports media{' '}
              </span>
            company.
              </span>
            </p>
          </div>
        </div>

        <div className={"container"}>
          <BecomingTheBiggest isWhoWeAre={true} isMobile={isMobile} />
          <div className={`row ${isMobile ? 'pt-2' : 'pt-4'}`}>
            <TypesOfSports sport={"FOOTBALL"} isMobile={isMobile} />
            <TypesOfSports sport={"NFL"} isMobile={isMobile} />
            <TypesOfSports sport={"NBA"} isMobile={isMobile} />
            <TypesOfSports sport={"CRICKET"} isMobile={isMobile} />
            <TypesOfSports sport={"MLB"} isMobile={isMobile} />
            <TypesOfSports sport={"NCAA"} isMobile={isMobile} />
          </div>
          <div className={`row ${isMobile ? 'pt-1 pb-5' : 'pb-5 pt-5'} d-flex justify-content-around`}>
            <Description
              heading={"50+"}
              bodyText={"APPS & WEBSITES"}
              isMobile={isMobile}
            />
            <Description
              heading={"197"}
              bodyText={"COUNTRIES"}
              isMobile={isMobile}
            />
            <Description
              heading={"446M+"}
              bodyText={"IMPRESSIONS"}
              isMobile={isMobile}
            />
          </div>
        </div>
      </div>
    );
  };

  return renderMain();
};

export default WhoWeAre;
