import {ShopifyActionTypes} from "../constants/ShopifyActionTypes";

const initialState = {
    productTags: [],
    productTypes: [],
    categories: [],
    productColors: [],
    containerImages: [],
    bannerImages: [],
    allProducts: [],
    randomProducts: [],
}

export default function ShopifyReducer(state = initialState, action) {
    switch (action.type) {
        case ShopifyActionTypes.SET_PRODUCT_TAGS:
            return {...state, productTags: action.payload};
        case ShopifyActionTypes.SET_PRODUCT_TYPES:
            return {...state, productTypes: action.payload};
        case ShopifyActionTypes.SET_CATEGORIES:
            return {...state, categories: action.payload};
        case ShopifyActionTypes.SET_PRODUCT_COLORS:
            return {...state, productColors: action.payload};
        case ShopifyActionTypes.SET_CONTAINER_IMAGES:
            return {...state, containerImages: action.payload};
        case ShopifyActionTypes.SET_BANNER_IMAGES:
            return {...state, bannerImages: action.payload};
        case ShopifyActionTypes.SET_PRODUCTS:
            return {...state, allProducts: action.payload};
        case ShopifyActionTypes.SET_RANDOM_PRODUCTS:
            return {...state, randomProducts: action.payload};
        default:
            return state;
    }
}
