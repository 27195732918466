import {get} from '../../../Axios/get';
import {useEffect, useState} from 'react';
import {useSearchParams} from 'react-router-dom';
import {FOOTBALL, PodcastEndPoint} from '../../../Axios/EndPoints';
import {Button} from 'react-bootstrap';
import {AiOutlineLeft, AiOutlineRight} from 'react-icons/ai';
import {getHostname} from "../../../CustomFunctions/getHostname";
import {COLORS, FONT_COLOR} from "../../../Configs/Targets";
import PodcastCard from "../../Cards/PodcastCard";

const Podcasts = (props) => {
	const {isMobile, data} = props;
	const [searchParams, setSearchParams] = useSearchParams()
	const [latestPodcasts, setPodcasts] = useState([])
	const [latestPodcastsLoading, setPodcastsLoading] = useState(true)

	const fetchPodcasts = (endpoint = null) => {
		let league_id = (searchParams.get('team_id') || searchParams.get('league_id') || data?.clubId || '');
		let parameters = searchParams.get('league_id') ?
			'&league_id=' + league_id : searchParams.get('team_id') || data?.clubId ?
				'&team_id=' + league_id :
				'';
		let url = endpoint ? endpoint : (searchParams.get('league_id') ?
			PodcastEndPoint + parameters : searchParams.get('team_id') || data?.clubId ?
				PodcastEndPoint + parameters :
				PodcastEndPoint + parameters);
		get(url).then((response) => {
			setPodcasts(response.data);
			setPodcastsLoading(false);
		}).catch((err) => {
			console.error(err);
		});
	};


	useEffect(() => {
		setPodcastsLoading(true);
		// if (props.podcastsData) {
		//     setPodcasts(props.podcastsData);
		//     setPodcastsLoading(props.loading);
		// } else {
		fetchPodcasts(props.podcastsEndpoint)
		// }
	}, [searchParams.get('league_id'), searchParams.get('team_id'), props.podcastsEndpoint, data])


	const renderLeftButton = (id) => {

		let style =
			{
				width: '63px',
				height: '68px',
				backgroundColor: '#fff',
				color: 'black',
				border: '0px',
				borderRadius: '0px',
			};

		const handleLeftClick = () => {
			document.getElementById('podcastSliderContainer' + id).scrollLeft -= 400;

		};

		return (
			<div
				style={{
					marginLeft: '-3em',
					marginRight: '-2em',
					zIndex: '1',
					marginTop: '-4em',
				}}
				onClick={handleLeftClick}
			>
				<Button className={''} style={style}>
					<AiOutlineLeft/>
				</Button>
			</div>
		);
	};

	const renderRightButton = (id) => {

		let style =
			{
				width: '63px',
				height: '68px',
				backgroundColor: '#fff',
				color: 'black',
				border: '0px',
				borderRadius: '0px',
			};

		const handleRightClick = () => {
			document.getElementById('podcastSliderContainer' + id).scrollLeft += 400;
		};

		return (
			<div
				onClick={handleRightClick}
				style={{
					marginLeft: '-3em',
					zIndex: '1',
					marginTop: '-4em',

				}}
			>
				<Button
					style={style}
				> <AiOutlineRight/>
				</Button>
			</div>
		);
	};

	const renderPodcasts = (podcastData, title) => {
		const hostName = window.location.hostname;
		const domainObject = getHostname(hostName);
		const target = domainObject.host;
		let style = {
			background: target === 'fanzine' ? 'linear-gradient(to bottom, #74dbef, #0092af)' : COLORS[target],
		};

		return (
			<div className={`w-100 mb-5 ${target === 'fanzine' ? 'text-black' : FONT_COLOR[target]}`} style={style}>
				<div className={'container'}>
					<div className={`${isMobile ? 'w-100' : 'container'}`}>
						<div className={`row ${isMobile ? '' : 'ps-5 pe-5'}`}>
							<p className={'font-30 pt-4 pb-3 m-0 text-start robotoCondensedBold font-italic'}>
								{title}
							</p>
							<div className={'d-flex justify-content-center align-items-center'}>
								{!isMobile && renderLeftButton(podcastData[0]?.id)}
								<div
									id={'podcastSliderContainer' + podcastData[0]?.id}
									className={`m-1 slider-mobile-container ${podcastData?.length <= 3 ? 'justify-content-center' : 'overflow-scroll-x'}`}
								>
									{podcastData?.map((podcast) => (
										<PodcastCard podcast={podcast} isFanzineAllFootball={true}
										             podcastHeight={isMobile ? '100px' : '280px'} isMain={false}
										             extraClass={'m-2'}
										             league={'Latest Podcasts'}
										             sport={props.sport || 'football'}
										             isMobile={isMobile}
										             target={target}
										/>
									),)}
								</div>
								{!isMobile && renderRightButton(podcastData[0]?.id)}
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	};

	const renderMain = () => {

		return latestPodcasts?.length > 0 && renderPodcasts(latestPodcasts, `${data ? 'Latest ' + (data?.team_name || data?.clubName || data?.name) + ' Podcasts' : 'Latest Podcasts'}`);

	};

	return renderMain();

}

export default Podcasts;
