import React from 'react';
import {Ad} from "react-ad-manager";

export default class GoogleAdManagerClass extends React.Component {

    constructor(props, context) {
        super(props, context);
        this.state = {isError: false};
    }

    componentWillUnmount() {
        window.googletag.cmd.push(function () {
            window.googletag.destroySlots();
        });
    }

    render() {
        return (
            <>
                {
                    this.props.isMobile ?
                        <div className={'w-100 d-flex text-center justify-content-center pt-3 mt-5'}>
                            <Ad
                                adUnit='/22662261938/Bet365_Leaderboard_test'
                                name='div-gpt-ad-1699543960089-0'
                                size={[[320, 100], [320, 50], [300, 50], [300, 250], 'fluid']}
                                refreshTimer={50000}
                            />
                        </div>
                        :
                        <div className={'w-100 d-flex text-center justify-content-center pt-2 mt-5'}>
                            <Ad
                                adUnit='/22662261938/Megaboard_ad_test'
                                name='div-gpt-ad-1699526301217-0'
                                size={[[960, 200], [970, 90], [980, 90], [980, 120], 'fluid']}
                                refreshTimer={50000}
                            />
                        </div>
                }
            </>
        );
    }
}
