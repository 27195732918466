import Card from 'react-bootstrap/Card';
import React, {useEffect, useState} from 'react';
import {Link, useLocation, useSearchParams} from 'react-router-dom';
import {get} from '../../Axios/get';
import {FANZINE} from '../../Axios/EndPoints';
import {SCREEN_SIZE} from '../../Configs/Constants';
import Slider from 'react-slick';
import {useSelector} from 'react-redux';
import FanzineShopNavBar from '../../Components/Navbar/FanzineShopNavBar';
import ItemLoader from '../../Components/Loader/ItemLoader';
import Loader from '../../Components/Loader/Loader';

const FanzineFanatics = () => {
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const [wcTeams, setWCTeams] = useState(null);
  const [bannerData, setBannerData] = useState(null);
  const [storeData, setStoreData] = useState(null);
  const [preparedStoreData, setPreparedStoreData] = useState(null);
  let [screenWidth, setScreenWidth] = useState(window.screen.width);
  let [isMobile, setIsMobile] = useState(false);
  const [currentSelectedLeague, setCurrentSelectedLeague]= useState('premier league');
  const [loading,setLoading]= useState(true);

  const teamStore = useSelector(state => state?.misc?.targetsApps);

  const addDefaultSrc = (e) => {
    e.target.value = require('../../assets/images/Default.jpeg');
  };

  const fetchBannerData = () => {
    get(FANZINE.FanaticsShopNowEndPoint)
        .then((res) => {
          let data = [];

          res.data.data.map((banner) => {
            if (window.screen.width <= SCREEN_SIZE.MOBILE &&
                banner.show_on_mobile) {
              data.push(banner);
            }
            if (!(window.screen.width <= SCREEN_SIZE.MOBILE) &&
                banner.show_on_web) {
              data.push(banner);
            }
          });

          if (!data.length) {
            data.push(res.data.data[0]);
          }

          setBannerData(data);
        })
        .catch((error) => {
          console.error(error);
        });
  };

  const getSelectedLeagueData = () => {

    const paramsLeagueName = searchParams.get('league_name');
    if (!paramsLeagueName && teamStore) {
      for (let i = 0; i < preparedStoreData?.length; i++) {
        if (preparedStoreData[i]?.leagueName?.toLowerCase() ==='premier league')
        {
          return preparedStoreData[i]?.teams;
        }
      }
    }

    for (let i = 0; i < preparedStoreData?.length; i++) {
      if (preparedStoreData[i]?.leagueName?.toLowerCase() ===
          paramsLeagueName?.toLowerCase()) {
        return preparedStoreData[i]?.teams;
      }
    }
    return [];

  };

  useEffect(() => {
    fetchBannerData();
    // fetchTeams();
  }, []);

  useEffect(() => {
    let preparedStoreData = [];

    if (teamStore && storeData === null && preparedStoreData?.length === 0) {
      let prevLeagueId = 0;
      for (let i = 0; i < teamStore?.length; i++) {
        if (i === 0 && !teamStore[i]?.image?.includes('default-league')) {
          prevLeagueId = teamStore[i]?.league_gs_id;
          let tmp = {
            leagueName: teamStore[i]?.league_name,
            leagueId: teamStore[i]?.league_gs_id,
            teams: [],
          };
          tmp.teams.push(teamStore[i]);
          preparedStoreData.push(tmp);
        } else {
          if (prevLeagueId === teamStore[i]?.league_gs_id && !teamStore[i]?.image?.includes('default-league')) {
            let index = preparedStoreData?.findIndex(value => {
              return value.leagueId === teamStore[i].league_gs_id;
            });
            preparedStoreData[index]?.teams.push(teamStore[i]);
          } else if(!teamStore[i]?.image?.includes('default-league')) {
            prevLeagueId = teamStore[i]?.league_gs_id;
            let tmp = {
              leagueName: teamStore[i]?.league_name,
              leagueId: teamStore[i]?.league_gs_id,
              teams: [],
            };
            tmp.teams.push(teamStore[i]);
            preparedStoreData.push(tmp);
          }
        }
      }
      setPreparedStoreData(preparedStoreData);
      setStoreData(teamStore);
      setLoading(false)

    }
  }, [teamStore]);

  useEffect(()=>{
    if(searchParams.get('league_name'))
    {
      setLoading(false)
      setCurrentSelectedLeague(searchParams.get('league_name'));
    }

    return(()=>
    {
      setLoading(true);
    })

  },[searchParams.get('league_name')])

  useEffect(() => {
    setScreenWidth(window.screen.width);
  }, [screenWidth]);

  useEffect(() => {
    if (window.screen.width <= SCREEN_SIZE.MOBILE) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, [window.screen.width, isMobile]);

  const renderSliderBannerMobile = (imageStyle) => {
    let settings = {
      infinite: true,
      dots: true,
      slidesToShow: 1,
      cssEase: 'linear',
      centerPadding: '100',
      arrows: false,
      slidesToScroll: 1,
      lazyLoad: false,
      autoplay: false,
    };
    return (
        <div className="image-carousel mb-5"
             style={{marginTop: '-50px !important'}}>
          <Slider {...settings}>
            {bannerData.map((banner) => (
                <div key={`banner_item_${banner.id}`} className={isMobile ?
                    'slide' :
                    'slide ms-5 me-5 ps-5 pe-5'}>
                  <div className={`row`}>
                    {renderBannerCard(imageStyle, banner)}
                  </div>
                </div>
            ))}
          </Slider>
        </div>
    );
  };
  const renderBannerCard = (imageStyle, banner) => {
    return (
        <Card className="border-0 bg-transparent">
          <Card.Img
              variant="top"
              className={isMobile ? '' : 'img-fluid'}
              src={isMobile && banner ?
                  banner.image :
                  require('../../assets/images/fanaticMain.png')}
              alt="Card image"
              onError={addDefaultSrc}
              style={imageStyle}
          />
          <Card.ImgOverlay
              className={`d-flex align-items-center flex-column justify-content-center `}>
            <Card.Text className={`text-white bebasFont mt-auto ${isMobile ?
                'h1' :
                'h2'}`}>
              <p className={'bebasFont text-center'} style={{
                webkitTextStroke: '2px #fff',
                webkitTextFillColor: 'transparent',
                fontSize: isMobile ? '70px' : '500%',
                opacity: isMobile ? '0.95' : '0.85',
              }}>
                Licensed <br/> Products
              </p>
            </Card.Text>
            <img className={'mt-auto border-0'}
                 src={require(
                     '../../assets/images/Fanatics_Primary_Color_RGB.png')}
                 alt={'fanatics'} height={'45px'}/>
            <a href={banner != null && banner.url} target={'_blank'}
               className={'btn sfProDisplayFontBold mt-auto rounded-0 '}
               color={'none'}
               style={{color: 'white', background: '#e53d2e'}} rel="noreferrer">
              SHOP NOW
            </a>
          </Card.ImgOverlay>
        </Card>
    );
  };
  const renderFanzineTargetApps = () => {
    let imageStyle = {
      height: isMobile ? '135px' : '230px',
      minHeight: isMobile ? '135px' : '230px',
      objectFit: 'contain',
    };

    return (
        <div
            className={`pb-5 ${isMobile && 'pt-3 container'}`}>
          <div className={'row'}>
            {loading && <Loader loading={loading} />}
            {!loading && storeData != null &&
                getSelectedLeagueData()?.map((value, index) => {
                  return value?.web_default_app_url?.split(
                      '//')[1]?.split('.')[1] === 'coys' ? <></> :  (
                      <Link
                          key={index}
                          to={`/${value?.web_default_app_url?.split(
                              '//')[1]?.split('.')[1]}/shop`}
                          className={isMobile ?
                              'col-6 mt-2' :
                              'col-lg-3 col-md-3 col-sm-6 col-xs-6 mt-4'}>
                        <div className={'text-decoration-none text-dark'}>
                          <Card className="bg-transparent">
                            <Card.Img
                                variant="top"
                                className={'img'}
                                src={value?.image?.replace('testcdn', 'cdn')}
                                alt="Card image"
                                onError={addDefaultSrc}
                                style={imageStyle}
                            />
                            <Card.ImgOverlay
                                className={`d-flex align-items-baseline pb-0 mb-0 flex-column justify-content-end fanatics-teams-overlay`}>
                              <Card.Text
                                  className={`d-flex align-items-baseline pb-0 mb-0 flex-column justify-content-end text-white bebasFont ${isMobile ?
                                      'h1' :
                                      'h2'}`}>
                                <p className={`d-flex align-items-baseline pb-0 mb-0 flex-column justify-content-end bebasFont ${isMobile ?
                                    'font-26' :
                                    'font-32'}`}>
                                  {value?.team_name !== '' ?
                                      value?.team_name :
                                      value?.team_seo_name?.replaceAll('-',
                                          ' ')}
                                </p>
                              </Card.Text>
                            </Card.ImgOverlay>
                          </Card>
                        </div>
                      </Link>
                  );
                })
            }                </div>
        </div>
    );
  };

  return (
      <div>
        <FanzineShopNavBar
            leagueNameFromParams={searchParams?.get('league_name')}
            isMobile={isMobile} preparedStoreData={preparedStoreData}/>
        <div className={isMobile ? 'pt-5' : 'container'}>
          {/*{renderLicensedProducts()}*/}
          {renderFanzineTargetApps()}
        </div>

      </div>
  );

};

export default FanzineFanatics;
