import './App.css';
import {get} from './Axios/get';
import {AppsList, FANZINE, ProductColors, ShippingZonesEndPoint, ShopImagesEndpoint, SportsList, StoreCategories, WebLinks,} from './Axios/EndPoints';
import {
    setDefaultLeagues,
    setFanzineLeagues,
    setFootballLeagues,
    setNavbarFanzineTeamsLeagues,
    setNavbarLeagues,
    SetNavBarLeagueSlugs,
    setNavbarTeamTypes,
} from './redux/actions/FooballLeagueActions';
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {graphql} from './Axios/graphql';
import {ShopifyGraphQlQueries} from './Configs/ShopifyGraphQlQueries';
import {SHOPIFY} from './Configs/Constants';
import {setBannerImages, setCategories, setContainerImages, setProductColors, setProductTags, setProductTypes,} from './redux/actions/StoreActions';
import {setTimeZone} from './redux/actions/TimeZoneActions';
import {setAppInit, setCurrencies, setCurrentCurrency, setCurrentIp, setTargetApps} from './redux/actions/MiscActions';
import {getHostname} from './CustomFunctions/getHostname';
import AppRoutes from './Routes/AppRoutes';
import {initializeApp} from "firebase/app";
import {getAnalytics} from "firebase/analytics";
import {FIREBASE_CONFIG} from "./Configs/FirebaseConfigsTargets";
import axios from "axios";

const App = () => {

    let store = useSelector((state) => state);
    let dispatch = useDispatch();

    let [reduxStore, setReduxStore] = useState(false);
    let [loading, setLoading] = useState(true);

    const fetchFanzineFootballLeaguesTeams = () => {
        get(FANZINE.NavBarEndPoint).then((response) => {
            let NavBarLeague = response.data.data;

            let currentLeague = '';
            let previousLeague = '';
            let tempApps = [
                {
                    name: 'All Football',
                    link: '/football',
                    clubLink: '/football',
                    external: false,
                },
            ];

            let leagueWithTeams = [];
            console.log("NavBarLeague: ", NavBarLeague)
            for (let i = 0; i < NavBarLeague?.length; i++) {
                for (let j = 0; j < NavBarLeague[i]?.leagues?.length; j++) {
                    let link = `/${NavBarLeague[i].name.toLowerCase()}/` +
                        (NavBarLeague[i]?.leagues[j]?.slug ?
                            NavBarLeague[i]?.leagues[j]?.slug :
                            NavBarLeague[i]?.leagues[j]?.name.replaceAll(' ', '-')
                                ?.toLowerCase());
                    Object.assign(NavBarLeague[i]?.leagues[j], {
                        link: link,
                        clubLink: link,
                    });
                    for (let k = 0; k < NavBarLeague[i]?.leagues[j]?.teams?.length; k++) {
                        let slug = (NavBarLeague[i]?.leagues[j]?.teams[k].slug ?
                            NavBarLeague[i]?.leagues[j]?.teams[k].slug :
                            NavBarLeague[i]?.leagues[j]?.teams[k].name.replaceAll(' ',
                                '-')
                                ?.toLowerCase());
                        Object.assign(NavBarLeague[i]?.leagues[j]?.teams[k], {
                            link: `/${NavBarLeague[i].name.replaceAll(' ', '-').toLowerCase()}/` + slug,
                            clubLink: `/${NavBarLeague[i].name.replaceAll(' ', '-').toLowerCase()}/` + slug,
                            slug: slug
                        });
                    }
                }
            }
            dispatch(setNavbarFanzineTeamsLeagues(NavBarLeague));
            setReduxStore(true);
            setLoading(false);
        }).catch((err) => {
            console.error(err);
        });

        get(FANZINE.AllTeamsEndPoint).then((response) => {
            let NavBarLeague = response.data.data;

            let currentLeague = '';
            let previousLeague = '';
            let tempApps = [
                {
                    name: 'All Football',
                    link: '/football',
                    external: false,
                },
            ];

            let leagueWithTeams = [];

            for (let j = 0; j < NavBarLeague?.length; j++) {
                // if(NavBarLeague[j]?.name === "Club Friendlies" || NavBarLeague[j]?.name === "EFL-Cup"){
                //     continue;
                // }
                Object.assign(NavBarLeague[j], {
                    link: '/football/' + NavBarLeague[j]?.name.replaceAll(' ', '-')?.toLowerCase(),
                });
                for (let k = 0; k < NavBarLeague[j]?.teams?.length; k++) {
                    Object.assign(NavBarLeague[j]?.teams[k], {
                        link: '/football/' + NavBarLeague[j]?.teams[k].name.replaceAll(' ', '-')?.toLowerCase(),
                    });
                    let tmp = {
                        club: NavBarLeague[j]?.teams[k].slug.replaceAll(' ', '-'),
                        clubId: NavBarLeague[j]?.teams[k].id,
                        clubIcon: NavBarLeague[j]?.teams[k].icon,
                        clubName: NavBarLeague[j]?.teams[k].name,
                        clubLink: '/football/' +
                            NavBarLeague[j]?.teams[k].slug,
                        league: NavBarLeague[j]?.name.replaceAll(' ', '-'),
                        leagueId: NavBarLeague[j]?.id || null,
                        leagueLink: '/football/' +
                            NavBarLeague[j]?.name.replaceAll(' ', '-'),
                    };

                    leagueWithTeams.push(tmp);
                }
            }
            dispatch(SetNavBarLeagueSlugs(leagueWithTeams));
            setReduxStore(true);
            setLoading(false);
        }).catch((err) => {
            console.error('SetNavBarLeagueSlugs', err);
        });
    };

    const fetchFanzineFootballLeague = async () => {
        await get(FANZINE.LeaguesEndPoint + '?web=1').then((response) => {
            let leagues = response.data.leagues;
            dispatch(setFanzineLeagues(leagues));
            setReduxStore(true);
        }).catch((err) => {
            console.error(err);
        });
    };

    const fetchFootballLeaguesTeamTypes = () => {
        get(SportsList).then((response) => {
            // console.log('sports list =', response.data)
            let leagues = response.data[0].leagues;
            let teamTypes = response.data[0].teamTypes;
            dispatch(setFootballLeagues(leagues));
            let tempNewsLeagues = [];
            // eslint-disable-next-line array-callback-return
            leagues.map((data) => {
                if (data.show_on_news) {
                    tempNewsLeagues.push(data);
                    if (data.default) {
                        dispatch(setDefaultLeagues(data));
                    }
                }
            });
            let tempNewsTeamTypes = [];
            // eslint-disable-next-line array-callback-return
            teamTypes.map((data) => {
                tempNewsTeamTypes.push(data);
                if (data.default) {
                    dispatch(setDefaultLeagues(data));
                }
            });
            tempNewsLeagues.sort((a, b) => a.sort - b.sort);
            dispatch(setNavbarLeagues(tempNewsLeagues));
            dispatch(setNavbarTeamTypes(tempNewsTeamTypes));
            setReduxStore(true);
            setLoading(false);
        }).catch((err) => {
            console.error(err);
        });
    };

    const fetchProductTags = () => {
        graphql(ShopifyGraphQlQueries(SHOPIFY.PRODUCT_TAGS)).then((response) => {
            let productTags = response.data.data.productTags.edges;
            if (productTags.length) {
                let tempProductTags = [];
                // eslint-disable-next-line array-callback-return
                productTags.map((value) => {
                    if (value.node.toLowerCase() !== 'accessories') {
                        tempProductTags.push(value.node, value.node.toLowerCase());
                    }
                });
                dispatch(setProductTags(tempProductTags));
            }
        });
    };

    const fetchProductTypes = () => {
        graphql(ShopifyGraphQlQueries(SHOPIFY.PRODUCT_TYPES)).then((response) => {
            let productTypes = response.data.data.productTypes.edges;
            if (productTypes.length) {
                let tempProductTypes = [];
                productTypes.map((value) =>
                    tempProductTypes.push(value.node, value.node.toLowerCase()),
                );
                dispatch(setProductTypes(tempProductTypes));
            }
        });
    };

    const fetchCategories = () => {
        get(StoreCategories).then((response) => {
            dispatch(setCategories(response.data));
        });
    };

    const fetchProductColors = () => {
        get(ProductColors).then((response) => {
            let tempColors = {};
            response.data.map((value) => (tempColors[value.name] = value.hex));
            dispatch(setProductColors(tempColors));
        });
    };

    const fetchShippingZones = () => {
        get(ShippingZonesEndPoint).then((response) => {
            if (response.data.code === 200) {
                let tempShippingZone = [];
                let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
                if (timezone === 'Europe/London') {
                    tempShippingZone = response.data.body.shipping_zones.filter(
                        (value) => value.name === 'UK Mainland',
                    );
                } else {
                    tempShippingZone = response.data.body.shipping_zones.filter(
                        (value) => value.name === 'Rest of World',
                    );
                }
                dispatch(setTimeZone(tempShippingZone[0]));
            }
        }).catch((err) => {
            console.error('Shipping Zone API error: ', err);
        });
    };

    const fetchShopImages = () => {
        get(ShopImagesEndpoint).then((response) => {
            if (response.data.success) {
                dispatch(setContainerImages(response.data.data.containerImages));
                dispatch(setBannerImages(response.data.data.bannerImages));
            }
        }).catch((err) => {
            console.error(err);
        });
    };

    const fetchLinkDetails = () => {
        get(WebLinks).then((response) => {
            if (response.data.success) {
                dispatch(setAppInit(response.data.data));
            }
        }).catch((err) => {
            console.error(err);
        });
    };

    const fetchApps = () => {
        get(AppsList).then((response) => {
            if (response.data.success) {
                dispatch(setTargetApps(response.data.data));
            }
        }).catch((err) => {
            console.error(err);
        });
    };

    const fetchCurrencies = () => {
        axios.get(`https://cdn.jsdelivr.net/gh/fawazahmed0/currency-api@1/latest/currencies/gbp.json`)
            .then((response) => {
                dispatch(setCurrencies(response.data.gbp));
            })
    }

    const fetchIpAndCurrencyCode = () => {
        axios.get(`http://www.geoplugin.net/json.gp`)
            .then((response) => {
                dispatch(setCurrentIp(response.data.geoplugin_request));
                dispatch(setCurrentCurrency({
                    code: response.data.geoplugin_currencyCode,
                    symbol: response.data.geoplugin_currencySymbol,
                }));
            });
    }

    const fetchCountryToRedirect = () => {
        axios.get(`https://geolocation-db.com/json/`)
            .then((response) => {
                // console.log('response =', response?.data)
                if(response?.data?.country_code === "NG")
                {
                    window.location.replace('https://fanzinesports.com');
                }
            });
    }

    useEffect(() => {
        const hostName = window.location.hostname;
        const domainObject = getHostname(hostName);
        const target = domainObject.host;
        if (['fanzinesports', 'fanzine'].indexOf(target) <= -1) {
            initClubs();
        }
        if (target === 'fanzine') {
            initFanzine();
        }
        if(target === 'fanzinesports')
        {
            initFanzineSports();
        }
        initMisc();
        // localStorage.removeItem('checkoutUrl')
        if (FIREBASE_CONFIG[target]) {
            const firebaseConfig = FIREBASE_CONFIG[target];

            // Initialize Firebase
            const app = initializeApp(firebaseConfig);
            const analytics = getAnalytics(app);
        }
    }, []);

    const initMisc = () => {
        if (typeof store.misc.currencies === 'undefined' || store.misc.currencies === null) {
            fetchCurrencies();
        }
        if (typeof store.misc.currentCurrency === 'undefined' || store.misc.currentCurrency === null) {
            fetchIpAndCurrencyCode();
        }
    }

    const initClubs = () => {
        if (
            typeof store.football.footballLeagues === 'undefined' ||
            store.football.footballLeagues.length === 0 ||
            store.football === 0
        ) {
            setLoading(true);
            fetchFootballLeaguesTeamTypes();
        }
        if (
            typeof store.shopify.productTags === 'undefined' ||
            store.shopify.productTags.length === 0
        ) {
            fetchProductTags();
        }
        if (
            typeof store.shopify.productTypes === 'undefined' ||
            store.shopify.productTypes.length === 0
        ) {
            fetchProductTypes();
        }
        if (
            typeof store.shopify.categories === 'undefined' ||
            store.shopify.categories.length === 0
        ) {
            fetchCategories();
        }
        if (
            typeof store.shopify.productColors === 'undefined' ||
            store.shopify.productColors.length === 0
        ) {
            fetchProductColors();
        }
        if (
            typeof store.timezone.timezone === 'undefined' ||
            store.timezone.timezone.length === 0
        ) {
            fetchShippingZones();
        }
        if (
            typeof store.misc.appInit === 'undefined' ||
            store.misc.appInit === null
        ) {
            fetchLinkDetails();
        }
        if (
            typeof store.shopify.containerImages === 'undefined' ||
            store.shopify.containerImages.length === 0
        ) {
            fetchShopImages();
        }

    }

    const initFanzine = () => {
        if (
            typeof store.football.fanzineTeamsLeagues === 'undefined' ||
            store.football.fanzineTeamsLeagues.length === 0 ||
            store.football === 0
        ) {
            setLoading(true);
            fetchFanzineFootballLeaguesTeams();
            fetchFanzineFootballLeague();
        }
        if (
            typeof store.misc.appInit === 'undefined' ||
            store.misc.appInit === null
        ) {
            fetchLinkDetails();
        }
        if (typeof store.misc.targetsApps === 'undefined' || store.misc.targetsApps === null) {
            fetchApps();
        }
        if (
            typeof store.shopify.productTags === 'undefined' ||
            store.shopify.productTags.length === 0
        ) {
            fetchProductTags();
        }
        if (
            typeof store.shopify.productTypes === 'undefined' ||
            store.shopify.productTypes.length === 0
        ) {
            fetchProductTypes();
        }
        // if (
        //     typeof store.shopify.categories === 'undefined' ||
        //     store.shopify.categories.length === 0
        // ) {
        //     fetchCategories();
        // }
        // if (
        //     typeof store.shopify.productColors === 'undefined' ||
        //     store.shopify.productColors.length === 0
        // ) {
        //     fetchProductColors();
        // }
        if (
            typeof store.timezone.timezone === 'undefined' ||
            store.timezone.timezone.length === 0
        ) {
            fetchShippingZones();
        }
        if (
            typeof store.misc.appInit === 'undefined' ||
            store.misc.appInit === null
        ) {
            fetchLinkDetails();
        }
        // if (
        //     typeof store.shopify.containerImages === 'undefined' ||
        //     store.shopify.containerImages.length === 0
        // ) {
        //     fetchShopImages();
        // }
        // fetchCountryToRedirect();
    };

    const initFanzineSports = () => {
        if (
            typeof store.football.fanzineTeamsLeagues === 'undefined' ||
            store.football.fanzineTeamsLeagues.length === 0 ||
            store.football === 0
        ) {
            setLoading(true);
            fetchFanzineFootballLeaguesTeams();
            fetchFanzineFootballLeague();
        }
        if (
            typeof store.misc.appInit === 'undefined' ||
            store.misc.appInit === null
        ) {
            fetchLinkDetails();
        }
        if (typeof store.misc.targetsApps === 'undefined' || store.misc.targetsApps === null) {
            fetchApps();
        }
        // if (
        //     typeof store.shopify.productTags === 'undefined' ||
        //     store.shopify.productTags.length === 0
        // ) {
        //     fetchProductTags();
        // }
        // if (
        //     typeof store.shopify.productTypes === 'undefined' ||
        //     store.shopify.productTypes.length === 0
        // ) {
        //     fetchProductTypes();
        // }
        // if (
        //     typeof store.shopify.categories === 'undefined' ||
        //     store.shopify.categories.length === 0
        // ) {
        //     fetchCategories();
        // }
        // if (
        //     typeof store.shopify.productColors === 'undefined' ||
        //     store.shopify.productColors.length === 0
        // ) {
        //     fetchProductColors();
        // }
        if (
            typeof store.timezone.timezone === 'undefined' ||
            store.timezone.timezone.length === 0
        ) {
            fetchShippingZones();
        }
        if (
            typeof store.misc.appInit === 'undefined' ||
            store.misc.appInit === null
        ) {
            fetchLinkDetails();
        }
        // if (
        //     typeof store.shopify.containerImages === 'undefined' ||
        //     store.shopify.containerImages.length === 0
        // ) {
        //     fetchShopImages();
        // }
    };


    return <AppRoutes loading={loading} reduxStore={reduxStore}/>;
};

export default App;
