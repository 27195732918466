import React from "react";
import PropTypes from "prop-types";

const FilterItems = (props) => {

    let setMainFiltersScreen = props.setMainFiltersScreen;
    let setItemFiltersScreen = props.setItemFiltersScreen;
    let selectedFilters = props.selectedFilters;
    let title = props.title;

    const render = () => {
        return (
            <>
                <div className={`sfProDisplayFont font-16 d-flex justify-content-start align-items-center ps-4 pe-4 pt-4`}
                     style={{height: "9%"}} onClick={() => {
                    setMainFiltersScreen(false);
                    setItemFiltersScreen(true);
                }}>
                    {title}
                </div>
                <div className={'sfProDisplayFont font-16 d-flex justify-content-start align-items-center ps-4 pe-4 pb-4 small text-muted'}>
                    {selectedFilters.join(', ')}
                </div>
                <div className={`d-flex justify-content-end`}>
                    <div style={{
                        border: "solid 0.8px #dfdfdf",
                        width: "97%",
                    }}/>
                </div>
            </>
        );
    }

    return render();

}

FilterItems.propTypes = {
    setMainFiltersScreen: PropTypes.func.isRequired,
    setItemFiltersScreen: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    selectedFilters: PropTypes.array.isRequired,
}

export default FilterItems;