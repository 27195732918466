import React from "react";
import {getHostname} from "../../CustomFunctions/getHostname";
import {LOADING_GIF} from "../../Configs/Targets";
import PropTypes from "prop-types";

const Loader = (props) => {

    const hostName = window.location.hostname;
    const domainObject = getHostname(hostName);
    const target = domainObject.host;
    const loadingGif = LOADING_GIF[target];

    return props.loading ? (
        <div className={`opaque-loader`}>
            <img src={loadingGif}
                 alt="loader" width="150"/>
        </div>
    ) : null;

}

Loader.propTypes = {
    loading: PropTypes.bool.isRequired
}

export default Loader;