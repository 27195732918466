import {MiscActionTypes} from "../constants/MiscActionTypes";

const initialState = {
    appInit: null,
    singleVideo: null,
    campaignAnswered: null,
    targetsApps: null,
    currencies: null,
    currentIp: null,
    currentCurrency: null,
}

export const MiscReducer = (state = initialState, action) => {
  switch (action.type) {
      case MiscActionTypes.SET_APP_INIT:
          return {...state, appInit: action.payload};
      case MiscActionTypes.SET_SINGLE_VIDEO:
          return {...state, singleVideo: action.payload};
      case MiscActionTypes.SET_CAMPAIGN_ANSWERED:
          return {...state, campaignAnswered: action.payload};
      case MiscActionTypes.SET_TARGETS_APPS:
          return {...state, targetsApps: action.payload};
      case MiscActionTypes.SET_CURRENCIES:
          return {...state, currencies: action.payload};
      case MiscActionTypes.SET_CURRENT_IP:
          return {...state, currentIp: action.payload};
      case MiscActionTypes.SET_CURRENT_CURRENCY:
          return {...state, currentCurrency: action.payload};
      default:
          return state;
  }
}
