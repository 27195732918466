import {useLocation, useNavigate, useParams, useSearchParams} from "react-router-dom";
import {getHostname} from "../../../CustomFunctions/getHostname";
import {useSelector} from "react-redux";
import React, {useEffect, useState} from "react";
import {SCREEN_SIZE} from "../../../Configs/Constants";
import {get} from "../../../Axios/get";
import {FANZINE, HomepageStandings, HomePageVideoEndPoint, NewsEndPoint} from "../../../Axios/EndPoints";
import axios from "axios";
import moment from "moment/moment";
import {chunk} from "../../../Components/Helper/chunk";
import FanzineNewsCard from "../../../Components/Cards/FanzineNewsCard";
import GoogleAd from "../../../Components/Ads/GoogleAd";
import {Dropdown, Form} from "react-bootstrap";
import {MakeTitleCase} from "../../../CustomFunctions/makeTitleCase";
import {COLORS, COLORS_RGB, COLORS_RGBA, FONT_COLOR, NAV_CLASS, TEXT_COLOR} from "../../../Configs/Targets";
import ItemLoader from "../../../Components/Loader/ItemLoader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleDown, faAngleUp} from "@fortawesome/free-solid-svg-icons";
import RightNewsAds from "../../../Components/Layout/News/RightNewsAds";
import {SubNavbar} from "../../../Components/Navbar/SubNavbar";
import Loader from "../../../Components/Loader/Loader";

const ClubTable = (props) =>{
  const {singleMatchData} = props;

  const location = useLocation();
  const navigate = useNavigate();
  const hostName = window.location.hostname;
  const domainObject = getHostname(hostName);
  const target = domainObject.host;

  const href = window.location.host;

  let {team} = useParams();
  const store = useSelector((state) => state);
  const [teamData, setTeamData] = useState(null);
  const [additionalTeamData, setAdditionalTeamData] = useState(null);

  let [searchParams, setSearchParams] = useSearchParams();
  const [fullTable, setFullTable] = useState(false);
  const [standings, setStanding] = useState(null);
  const [moreStandings, setMoreStanding] = useState(null);
  const [selectLeagueData, setSelectLeagueData] = useState(null);
  const [selectedLeague, setSelectedLeague] = useState([]);
  const [leagues, setLeagues] = useState([]);
  const [loading, setLoading] = useState(true);
  const [youMightLike, setYouMightLike] = useState([]);
  let [isMobile, setIsMobile] = useState(false);

  //
  const [allTeams, setAllTeams] = useState(null)
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  let src = 'https://cdn.fanzine.com/img/leagues/icons_new/3dRFqBUtMN5C2JfH2tUI3yt9XqFBCDEiCndE4kPQ.png';

  //fetching All Teams API.
  useEffect(() => {
    const fetchData = () => {
      setIsLoading(true);

      get(FANZINE.AllTeamsEndPoint).then((response) => {
        setAllTeams(response?.data?.data)
        console.log("response: ", response?.data?.data)
        // setLeague(response.data.data);
        // setSelectedLeague('Premier League');
        // setDropdownTitle('Premier League');
      }).catch((err) => {
        console.error(err);
      });
    };

    fetchData();
  }, []);

  const resultArray = allTeams.flatMap((teamOne) => {
    const matchedTeamB = teamOne.teams.find((teamB) => teamB.id === teamOne.id);

    if (matchedTeamB) {
      return { teamOne, matchedTeamB };
    }

    return [];
  });

  console.log(resultArray);



  useEffect(() => {
    store.football?.navbarTeamSlugsId?.length &&
    store?.football?.navbarTeamSlugsId?.map((value, index) => {
        if (team === value?.club) {
          setTeamData(value);
          store?.misc?.targetsApps?.map((teamValue, teamIndex) => {
            if (value?.clubId === teamValue?.team_id) {
              setAdditionalTeamData(teamValue);
              fetchStandingsData(teamValue)
            }
          });
        }
      },
    );

  }, [team, store.football?.navbarTeamSlugsId, teamData, location.pathname]);

  useEffect(() => {

    let tempSelectedLeague = leagues?.find((league) => {
      return league.league_id == searchParams.get('league_id');
    });
    setSelectedLeague(tempSelectedLeague);

    if(!team && target !== 'fanzine')
    {
      fetchStandingsData();
    }

    if (!team && target === 'fanzine') {
      fetchStandingsData();
    }

  }, [store,searchParams.get('league_id'), additionalTeamData]);

  useEffect(() => {
    fetchFanzineFootballLeague();
    fetchYouMightLike();
  }, []);

  useEffect(() => {
    if (window.screen.width <= SCREEN_SIZE.MOBILE) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, [window.innerWidth, isMobile]);

  const fetchFanzineFootballLeague = async () => {
    await get(FANZINE.LeaguesEndPoint + '?web=1').then((response) => {
      let responseLeagues = response?.data?.leagues;
      setLeagues(responseLeagues);
    }).catch((err) => {
      console.error(err);
    });
  };

  const fetchStandingsData = (additionalTeamData) => {
    let params = '?league_id=';
    if (searchParams.get('league_id')) {
      params += searchParams.get('league_id');
      for (let i = 0; i < leagues; i++) {
        if (params == leagues[i].league_id) {
          setSelectedLeague(leagues[i]);
        }
      }
    } else
    {
      params +=
        (selectedLeague?.league_id ? selectedLeague?.league_id : target ===
        'fanzine' ? '1204' : store?.misc?.appInit?.league_gs_id);
    }


    if (team) {
      const teamHostName = additionalTeamData?.web_default_app_url?.split(
        '.')[1];


      params = '?league_id=' + additionalTeamData?.league_gs_id;

      let CLUB_URL = `https://api2.${teamHostName ? teamHostName : 'fanzine'}.com${HomepageStandings}`;


      axios.get(CLUB_URL + '?with_resent=true').then((res) => {
        setStanding(res.data.standings);
        setSelectLeagueData(res.data);
        setLoading(false);
      }).catch(error => console.error('error =', error));
    } else {
      get(HomepageStandings + params + '&with_resent=true').then((res) => {
        setStanding(res.data.standings);
        setSelectLeagueData(res.data);
        setLoading(false);
      }).catch(error => console.error('error =', error));

    }
  };

  const fetchYouMightLike = (isNew) => {
    let searchParams = '?main_site=1&snack=1';
    let apiPage = 1;
    let page = `&page=${apiPage}`;
    let limit = `&limit=20`;

    let newsRes = get(NewsEndPoint + searchParams + limit + page);
    let videosRes = get(HomePageVideoEndPoint + searchParams + limit + page);

    axios.all([newsRes, videosRes])
      .then(axios.spread((responseNews, responseVideos) => {
        let tempNewsData = [];
        let tempVideosData = [];
        let tempData = [];
        responseNews.data.map((data) => {
          let news = {
            news_id: data.news_id,
            slug: data.slug,
            news_image: data.image,
            news_title: data.title,
            news_url: data.url,
            news_time: moment.tz(data.datetime, 'Europe/London')
              .local()
              .startOf('seconds')
              .fromNow(),
            news_date: moment(data.datetime).format('D MMM YYYY, H:mm'),
            news_publisher: {
              publisher_name: data.publisher.name,
              publisher_icon: data.publisher.icon,
            },
            is_snack: data.is_snack,
            type: 'news',
          };
          tempNewsData.push(news);
        });
        responseVideos.data.map((data) => {
          let news = {
            news_id: data.news_id,
            slug: data.id,
            news_image: data.preview_image,
            news_title: data.title,
            news_url: data.url,
            news_time: moment.tz(data.published_at.date, 'Europe/London')
              .local()
              .startOf('seconds')
              .fromNow(),
            news_date: moment(data.published_at.date)
              .format('D MMM YYYY, H:mm'),
            news_publisher: {
              publisher_name: data.publisher.name,
              publisher_icon: data.publisher.icon,
            },
            is_snack: data.is_snack,
            type: 'videos',
          };
          tempVideosData.push(news);
        });

        for (let i = 0; i < tempNewsData.length; i++) {
          let ctr = i + 1;
          if (ctr % 2 === 0) {
            tempData.push(tempVideosData[i]);
          } else {
            tempData.push(tempNewsData[i]);
          }
        }

        if (isNew) {
          setYouMightLike(tempData);
        } else {
          setYouMightLike([...youMightLike, ...tempData]);
        }
        setLoading(false);
      }))
      .catch(error => console.log(error));
  };

  function renderYouMightLike() {
    return (
      <div className="row">
                <span
                  className={'bebasFont font-weight-bold h3'}>You Might Like</span>
        {
          youMightLike.length ?
            (
              chunk(youMightLike, 2).map((value, index) => (
                <div className={'col-12'}>
                  <FanzineNewsCard
                    isNewDesign={true}
                    moreStories={true}
                    news={value}
                    isSingleNewsPage={true}
                  />
                  {
                    (index + 1) % 2 === 0 && (
                      <div
                        style={{
                          border: '1px black solid',
                        }}
                        className="d-flex justify-content-center align-items-center h1 mt-3">
                        <GoogleAd id={'4009154580'}/>
                      </div>
                    )
                  }
                </div>
              ))
            )
            : null
        }

        <div
          style={{
            border: '1px black solid',
            height: '200px',
          }}
          className="d-flex justify-content-center align-items-center h1 mt-3">
          <GoogleAd id={'4009154580'}/>
        </div>
      </div>
    );
  }

  const CustomMenu = React.forwardRef(
    ({children, style, className, 'aria-labelledby': labeledBy}, ref) => {
      const [value, setValue] = useState('');

      return (
        <div
          ref={ref}
          style={style}
          className={className}
          aria-labelledby={labeledBy}
        >
          <Form.Control
            autoFocus
            className="p-2 my-2 w-100"
            placeholder="Type to filter..."
            onChange={(e) => setValue(e.target.value)}
            value={value}
          />
          <ul className="list-unstyled w-100">
            {React.Children.toArray(children).filter(
              (child) =>
                !value || child.props.children.toLowerCase().startsWith(value.toLowerCase()),
            )}
          </ul>
        </div>
      );
    },
  );

  const renderLeagueSelectDropDown = () => {

    const handleLeagueSelect = (e) => {
      const to = {pathname: location.pathname, search: 'league_id=' + e};
      navigate(to, {replace: true});

      setLoading(true);
    };


    // let tmp;
    // for (let i = 0; i < leagues?.length; i++) {
    //   if (leagues[i].league_id === parseInt(e)) {
    //     tmp = leagues[i];
    //   }
    // }
    // setSelectedLeague(tmp);

    return (
      <div>
        {isMobile && <Dropdown
          style={{
            borderRadius: '32px',
            background: '#f3f3f3',
            width: isMobile ? '179px' : 'fit-content',
            height: isMobile ? '57px' : '63px',
            color: 'black',
            padding: isMobile ?
              '10px 0px 0px 0px' :
              '10px 26.2px 10px 16px',
            margin: isMobile ? '1px 6px 0px' : '16px 6px 18px',
            border: '0px',
          }}
          onSelect={handleLeagueSelect}
        >
          <Dropdown.Toggle variant="none"
                           className={'sfProDisplayFontBold d-flex align-items-center w-fit-content'}
                           id="dropdown-basic">
            {/*<img alt={'league'} src={(selectedLeague?.icon || src)}*/}
            {/*     style={{*/}
            {/*       marginLeft: '-10px',*/}
            {/*       width: '20px',*/}
            {/*       height: '20px',*/}
            {/*     }}*/}
            {/*>*/}
            {/*</img>*/}
            &nbsp;
            &nbsp;
            <span className={'d-flex align-items-center'}>
                            {MakeTitleCase(selectedLeague?.name ||
                              'Premier League')}
                        </span>
          </Dropdown.Toggle>

          <Dropdown.Menu className={'shadow-nav'}>
            {
              leagues?.map((league, index) => (
                <Dropdown.Item key={index}
                               eventKey={league?.league_id}>
                  <img alt={'league'} src={(league?.icon || src)}
                       style={{
                         marginRight: '10px',
                         width: '20px',
                         height: '20px',
                       }}
                  >
                  </img>
                  {MakeTitleCase(league?.name)}
                </Dropdown.Item>
              ))
            }
          </Dropdown.Menu>
        </Dropdown>}

        {!isMobile && <div className={'mt-4 robotoFont'}>
          <p
            style={{
              width: '493px',
              height: '37px',
              margin: '0 5.7px 25px 16px',
              fontSize: '28px',
              fontWeight: '900',
              textAlign: 'left',
              color: '#000',
            }}
          >
            {selectedLeague?.name ? selectedLeague?.name : 'Premier League'} Table 2022-23
          </p>
        </div>
        }

        {!isMobile && <Dropdown
          onSelect={handleLeagueSelect}
          className={`w-100 border-0 d-flex justify-content-start mt-4`}>
          <Dropdown.Toggle id="dropdown-custom-components"
                           className={'btn w-25 bg-lighter-grey border-0 rounded-top-custom rounded-bottom-custom p-2 text-black'}>
            {MakeTitleCase(selectedLeague?.name ||
              'Premier League')}
          </Dropdown.Toggle>

          <Dropdown.Menu as={CustomMenu} className={`w-100 p-2`}>
            {
              leagues?.map((value, index) => (
                <Dropdown.Item eventKey={value?.league_id}

                >
                  {MakeTitleCase(value?.name)}
                </Dropdown.Item>
              ))
            }
          </Dropdown.Menu>
        </Dropdown>
        }


      </div>
    );

  };

  const renderRightAds = () => {

    let numberOfAds = [1, 2, 3, 4];

    return (
      <div className={'col-4'}>
        <div className={`row container pt-2`}>
          {youMightLike.map((news, index) => {

            return (
              <div
                className={`mb-3 col-xl-12 m-0 p-1 col-lg-12 col-md-12 col-sm-12 col-xs-12`}
              >
                <FanzineNewsCard
                  isMobile={isMobile}
                  classProps={''}
                  newsItem={news}
                  isNewDesign={true}
                  isNewsPage={false}
                  sport={'football'}
                />
                {index % 2 === 0 && <div
                  style={{
                    border: '1px black solid',
                  }}
                  className="d-flex justify-content-center align-items-center h1 mt-3">
                  <GoogleAd id={'4009154580'}/>
                </div>}
              </div>
            );
          })}
        </div>
      </div>);
  };

  const renderMobileGroup = (value) => {
    return (<table className={`table table-responsive ${team && 'mt-5'}`}>

        <thead>
        <tr className={`bg-fixtures rounded-bottom-custom text-center sfProDisplayFont small`}>
          <td>#</td>
          <td style={{width: '130px'}}>Teams</td>
          <td>P</td>
          <td>W</td>
          <td>D</td>
          <td>L</td>
          <td>F/A</td>
          <td>GD</td>
          <td>Pts</td>
        </tr>
        </thead>
        <tbody>
        {(value || standings)?.map((value, index) => {

          let bgColor = ''
          let fontColor = ''
          if (value?.team?.id === additionalTeamData?.team_id) {
            bgColor = value?.team?.hex_color
            fontColor = '#fff' || value?.team?.font_color
          }
          if (target !== 'fanzine') {
            fontColor = store.misc.appInit && store.misc.appInit.team_id == value?.team?.id ? '#fff' : '';
            bgColor = store.misc.appInit && store.misc.appInit.team_id == value?.team?.id ? COLORS_RGB[target] : '';

          }


          return (
            <>
              <tr
                style={{
                  background: bgColor !== '' ? bgColor : '#fff',
                  color: fontColor !== '' ? fontColor : '#000'
                }}
                className={`sfProDisplayFont small`}>
                <td className={`text-center`}
                    style={{
                      borderLeft: `5px solid ${index <
                      selectLeagueData?.bands?.promotion_quantity ?
                        selectLeagueData?.bands?.promotion_color
                        :
                        index < (parseInt(
                            selectLeagueData?.bands?.promotion_quantity) +
                          parseInt(
                            selectLeagueData?.bands?.below_promotion_quality)) ?
                          selectLeagueData?.bands?.below_promotion_color
                          :
                          index <
                          ((value?.length || standings?.length) -
                            parseInt(
                              selectLeagueData?.bands?.above_relegation_quantity)) ?
                            'transparent' ||
                            selectLeagueData?.bands?.above_relegation_color
                            :
                            selectLeagueData?.bands?.relegation_color
                      }`,
                    }}
                ><span
                  className={'sfProDisplayFont'}>{value.position}.</span>
                </td>
                <td className={`p-1`}>
                  <img src={value.team.icon} height={30} width={28} alt=""/>
                  <span
                    className={'sfProDisplayFont ps-2'}>{value.team.short_name}</span>
                </td>
                <td className={`text-center`}>{value.pl}</td>
                <td className={`text-center`}>{value.win}</td>
                <td className={`text-center`}>{value.draw}</td>
                <td className={`text-center`}>{value.lose}</td>
                <td className={`text-center`}>{value.gd2}</td>
                <td className={`text-center`}>{value.gd}</td>
                <td className={`text-center`}>{value.pts}</td>
              </tr>
            </>
          );
        })}
        </tbody>
      </table>
    );
  };

  const renderMobilePartialTable = (
    standingBgColor, textColor, color, groupData) => {

    return (
      <>
        <div className={` rounded-custom-1 text-center p-1 ${target !== 'fanzine' ? '' : 'pt-5'}`}>
          {target === 'fanzine' && !team && <span className={`${textColor} text-header`}>
                        <span
                          className={'sfProDisplayFont font-16 text-black'}
                          style={{fontWeight: '600'}}
                        >
                            {renderLeagueSelectDropDown()}
                        </span>
            {/*To DO add Later
                            <FontAwesomeIcon icon={faSortDown} color={'#FFFFFF'} size={'1x'} style={{verticalAlign: '0'}}/>*/}
                    </span>
          }          </div>
        <div className={`mt-3`}>
        </div>
        {
          loading ?
            <ItemLoader loading={loading}/>
            :
            !groupData && renderMobileGroup()}

        {!loading && groupData && standings &&
          groupData.map((value, index) => (
            <div className={`mb-2 mt-2`}>
              <div className={'p-1 text-nowrap bg-background d-flex align-items-center'}>
                <img alt={'league'} src={(selectedLeague?.icon || src)}
                     style={{
                       width: '20px',
                       height: '20px',
                     }}
                     className={'ms-2'}
                >
                </img>
                &nbsp;
                &nbsp;
                {value[0]?.group !== '' ? `Group ${value[0]?.group}` : typeof selectedLeague?.name !== 'undefined' ? selectedLeague?.name : 'Premier League'}
              </div>
              {renderMobileGroup(value)}
            </div>
          ))}

        {moreStandings?.length &&
          <li className={`p-3 bg-white text-end sfProDisplayFontBold ${textColor} rounded-bottom-custom btn`}
              onClick={() => {
                setFullTable(!fullTable);
              }}
          >
            {fullTable ? 'Collapse' : 'Full Table'} &nbsp;&nbsp;&nbsp;
            <FontAwesomeIcon icon={fullTable ? faAngleUp : faAngleDown}
                             color={color} size={'1x'}/>
          </li>}
      </>
    );
  };

  const renderMobileFullTable = (standingBgColor, textColor, color) => {
    return (
      <>
        <div className={` rounded-custom-1 text-center p-1 `}>
                    <span className={`${textColor} text-header`}>
                        <span
                          className={'sfProDisplayFont font-16 text-black'}
                          style={{fontWeight: '600'}}
                        >
                            {renderLeagueSelectDropDown()}
                        </span>
                      {/*To DO add Later
                            <FontAwesomeIcon icon={faSortDown} color={'#FFFFFF'} size={'1x'} style={{verticalAlign: '0'}}/>*/}
                    </span>
        </div>
        <div className={`mt-3`}>

        </div>
        <table className={`table table-responsive`}>
          <thead>
          <tr className={`bg-fixtures rounded-bottom-custom text-center sfProDisplayFont small`}>
            <td></td>
            <td style={{width: '130px'}}>Teams</td>
            <td>P</td>
            <td>W</td>
            <td>D</td>
            <td>L</td>
            <td>F/A</td>
            <td>GD</td>
            <td>Pts</td>
          </tr>
          </thead>
          <tbody>
          {moreStandings?.length &&
            moreStandings?.map(((value, index, array) => (
              <tr className={`text-light-black sfProDisplayFont small`}
                  style={value.background}>
                <td className={`ps-1 text-center`}><span
                  className={'sfProDisplayFont'}>{value.position}. &nbsp;</span>
                </td>
                <td className={`p-2`}>
                  <img src={value.team.icon} height={20}
                       alt=""/>
                  <span className={'sfProDisplayFont ms-2'}>
                                        &nbsp; {value.team.short_name}
                                    </span>
                </td>
                <td className={`text-center`}>{value.pl}</td>
                <td className={`text-center`}>{value.win}</td>
                <td className={`text-center`}>{value.draw}</td>
                <td className={`text-center`}>{value.lose}</td>
                <td className={`text-center`}>{value.gd2}</td>
                <td className={`text-center`}>{value.gd}</td>
                <td className={`text-center`}>{value.pts}</td>
              </tr>
            )))}

          </tbody>
        </table>
        {moreStandings?.length &&
          <li className={`p-3 bg-white text-end sfProDisplayFontBold ${textColor} rounded-bottom-custom btn`}
              onClick={() => {
                setFullTable(!fullTable);
                window.scroll({
                  top: 1850,
                  behavior: 'smooth',
                });
              }}
          >
            {fullTable ? 'Collapse' : 'See full table'} &nbsp;&nbsp;&nbsp;
            <FontAwesomeIcon icon={fullTable ? faAngleUp : faAngleDown}
                             color={color}
                             size={'1x'}/>
          </li>}
        {/**/}
      </>
    );
  };

  const renderEmblems = () => {
    return (
      <li

        style={{
          color: '#767676',
          borderLeft: '5px solid transparent',
        }}
        className={'p-2 mt-2 bg-white'}>
        <div className={'row p-1'}>
          <div
            style={{color: '#999999'}}
            className={'col-2 text-center d-flex align-items-center sfProDisplayFont text-nowrap font-12'}>
            <div style={{
              background: selectLeagueData?.bands?.promotion_color || '#009245'
              , height: '10px', width: '10px',
            }}/>
            &nbsp;
            Champions League
          </div>
          <div
            style={{color: '#999999'}}
            className={'col-2 text-center d-flex align-items-center sfProDisplayFont text-nowrap font-12'}>
            <div style={{
              background: selectLeagueData?.bands?.below_promotion_color || '#034694',
              height: '10px',
              width: '10px',
            }}/>
            &nbsp;
            Europa League
          </div>
          <div
            style={{color: '#999999'}}
            className={'col-2 text-center d-flex align-items-center sfProDisplayFont text-nowrap font-12'}>
            <div style={{

              background: selectLeagueData?.bands?.relegation_color || '#ef0107',
              height: '10px',
              width: '10px',
            }}/>
            &nbsp;
            Relegation
          </div>
        </div>
      </li>
    );
  };

  const renderLeaugeTableHeading = (value) => {
    let groupName = '';
    if (value?.length < 10) {
      groupName = 'Group ' + value[0].group;
    }

    return (
      <li
        style={{
          background: '#f3f3f3',
          color: '#767676',
          borderLeft: '5px solid transparent',
        }}
        className={'p-2 mt-4'}>
        {groupName !== '' &&
          <p className={`sfProDisplayFontBold p-0 m-0 text-start border-bottom`}>
            {groupName}
          </p>
        }
        <div className={'row p-1 ps-3 pe-3'}
        >
          <div
            className={'col-1 text-center  sfProDisplayFont text-nowrap font-14'}>
            Position
          </div>
          <div
            style={{
              marginLeft: '-10px',
              marginRight: '5px'
            }}
            className={'col-2 text-start sfProDisplayFont text-nowrap font-14'}>
            Team
          </div>
          <div
            className={'col-1 text-center  sfProDisplayFont font-14 m-0 p-0'}>PL
          </div>
          <div
            className={'col-1 text-center sfProDisplayFont font-14 m-0 p-0'}>W
          </div>
          <div
            className={'col-1 text-center sfProDisplayFont font-14 m-0 ps-0'}>D
          </div>
          <div
            className={'col-1 text-center sfProDisplayFont font-14 m-0 p-0'}>L
          </div>
          <div
            className={'col-1 text-center sfProDisplayFont font-14 m-0 p-0'}>+/-
          </div>
          <div
            className={'col-1 text-center sfProDisplayFont font-14 m-0 p-0'}>GD
          </div>
          <div
            className={'col-1 text-center sfProDisplayFont font-14 m-0 ps-0'}>Points
          </div>
          <div
            className={'col-1 text-center sfProDisplayFont font-14 m-0 ps-0'}>Form
          </div>
        </div>
      </li>
    );
  };

  const renderLeagueTable = (Value) => {

    return (Value || standings)?.map(((value, index, array) => {
      let bgColor = '';
      let fontColor = '';
      if (value?.team?.id === singleMatchData?.home_team?.id) {
        bgColor = singleMatchData?.home_team?.hex_color;
      }

      if (value?.team?.id === singleMatchData?.guest_team?.id) {
        bgColor = singleMatchData?.guest_team?.hex_color;
      }

      if (value?.team?.id === additionalTeamData?.team_id) {
        bgColor = value?.team?.hex_color
        fontColor = 'text-white' || value?.team?.font_color
      }

      if (target !== 'fanzine') {
        fontColor = store.misc.appInit && store.misc.appInit.team_id == value?.team?.id ? 'text-white' : '';
        bgColor = store.misc.appInit && store.misc.appInit.team_id == value?.team?.id ? COLORS_RGB[target] : '';
      }

      return (
        <li key={index} className={`bg-hover-grey ${target === 'fanzine' ? 'pt-1' : 'pt-1'} pb-1 ps-3 pe-3`}
            style={{
              background: bgColor !== '' ?
                bgColor
                : '#fff',
            }}
        >
          <div className={'row p-1'}
            // style={{
            //   borderLeft: `5px solid ${value?.position <= parseInt(selectLeagueData?.bands?.promotion_quantity) ? '#009245' :
            //       value?.position <= (parseInt(selectLeagueData?.bands?.promotion_quantity) + parseInt(selectLeagueData?.bands?.below_promotion_quality)) ?
            //           '#034694' :
            //           value?.position >= (selectLeagueData?.standings?.length - parseInt(selectLeagueData?.bands?.relegation_quantity)) ?
            //               '#ef0107' :
            //               'transparent'} `,
            // }}
               style={{
                 borderLeft: `5px solid ${index <
                 selectLeagueData?.bands?.promotion_quantity ?
                   selectLeagueData?.bands?.promotion_color
                   :
                   (index <
                     ((parseInt(selectLeagueData?.bands?.promotion_quantity) +
                       parseInt(
                         selectLeagueData?.bands?.below_promotion_quality)) === parseInt(selectLeagueData?.bands?.promotion_quantity) ?
                       parseInt(selectLeagueData?.bands?.promotion_quantity) + 1 :
                       parseInt(selectLeagueData?.bands?.promotion_quantity) +
                       parseInt(
                         selectLeagueData?.bands?.below_promotion_quality))
                   ) ?
                     selectLeagueData?.bands?.below_promotion_color
                     :
                     index <
                     ((Value?.length || standings?.length) -
                       parseInt(
                         selectLeagueData?.bands?.above_relegation_quantity)) ?
                       'transparent' ||
                       selectLeagueData?.bands?.above_relegation_color
                       :
                       selectLeagueData?.bands?.relegation_color
                 }`,
               }}

          >
            <div
              className={`col-1 ${fontColor !== '' ?
                fontColor
                :
                'text-dark'} text-center text-nowrap font-16`}>
              {value?.position}.
            </div>
            <div
              style={{
                marginLeft: '-10px',
                marginRight: '5px'
              }}

              className={'col-2 text-start'}>
              <img
                width={27}
                style={{objectFit: 'contain'}}

                src={value.team.icon} height={20} alt=""/>
              &nbsp;
              <span
                className={`sfProDisplayFont font-16 text-nowrap ${fontColor !== '' ? fontColor : 'text-dark'}`}
                style={{fontWeight: '500'}}
              >
                                {value?.team.short_name}
                            </span>
            </div>

            <div
              className={`col-1 text-center ${fontColor !== '' ? fontColor :
                'text-light-black'} sfProDisplayFont font-16 m-0 p-0`}>{value?.pl}</div>
            <div
              className={`col-1 text-center ${fontColor !== '' ? fontColor :
                'text-light-black'} sfProDisplayFont font-16 m-0 p-0`}>{value?.win}</div>
            <div
              className={`col-1 text-center ${fontColor !== '' ? fontColor :
                'text-light-black'} sfProDisplayFont font-16 m-0 ps-0`}>{value?.draw}</div>
            <div
              className={`col-1 text-center ${fontColor !== '' ? fontColor :
                'text-light-black'} sfProDisplayFont font-16 m-0 p-0`}>{value?.lose}</div>
            <div
              className={`col-1 text-center ${fontColor !== '' ? fontColor :
                'text-light-black'} sfProDisplayFont font-16 m-0 p-0`}>{value?.overall_gs}-{value?.overall_ga}</div>
            <div
              className={`col-1 text-center ${fontColor !== '' ? fontColor :
                'text-light-black'} sfProDisplayFont font-16 m-0 p-0`}>{value?.gd}</div>
            <div
              className={`col-1 text-center ${fontColor !== '' ? fontColor :
                'text-light-black'} sfProDisplayFont font-16 m-0 ps-0`}>{value?.pts}</div>
            <div
              className={`col-2 text-center ${fontColor !== '' ? fontColor :
                'text-light-black'} sfProDisplayFont font-12 m-0 ps-0`}>
              <div className={'d-flex align-items-center'}>
                {value?.team?.recent_games?.map((recentValue) => {
                  return (<div
                    style={{
                      borderRadius: '50%',
                      margin: '0px 5px 0px 0px',
                      padding: recentValue?.general_result === 'win' ? '2px 7px 1px 5px'
                        : '2px 7px 0px 7px',
                      width: '21px',
                      height: '21px',
                      background: recentValue?.general_result === 'win' ? '#0fb958'
                        :
                        recentValue?.general_result === 'lose' ? '#dc1422' : '#aeaeae'
                      ,
                    }}
                    className={'text-white'}
                  >
                    {recentValue?.general_result === 'win' ? ' W '
                      :
                      recentValue?.general_result === 'lose' ?
                        ' L ' :
                        ' D '}
                  </div>)

                })}
              </div>
            </div>
          </div>
        </li>
      );
    }));
  };

  const renderLaptopTable = (
    standingBgColor, textColor, color, navColor, fontColor, Value,
    groupData) => {


    return (
      <div className={'row container ms-auto me-auto'}>


        <div className="col-8">
          <div className={` rounded-custom-1 text-center p-1`}>
            {target === 'fanzine' && !team && <span className={`${fontColor} text-header`}>
                            <span
                              className={'sfProDisplayFont font-16 text-black'}
                              style={{fontWeight: '600'}}
                            >
                                {renderLeagueSelectDropDown()}
                            </span>
              {/*To DO add Later
                            <FontAwesomeIcon icon={faSortDown} color={'#FFFFFF'} size={'1x'} style={{verticalAlign: '0'}}/>*/}
                        </span>
            }            </div>
          <ul className={`bg-fixtures rounded-bottom-custom text-center standings-list list-unstyled p-0 ${target !== 'fanzine' && 'pt-5'}`}>
            {!groupData && renderLeaugeTableHeading()}
            {
              loading ?
                <ItemLoader loading={loading}/>
                :
                !groupData && renderLeagueTable(Value)}

            {!loading && groupData && standings &&
              groupData.map((value, index) => (
                <div className={`mb-2 mt-2`}>
                  {renderLeaugeTableHeading(value)}
                  {renderLeagueTable(value)}
                </div>
              ))}

            {
              moreStandings?.length &&
              moreStandings?.map(((value, index, array) => {
                return (
                  <li className={`p-3 ${fullTable ? '' : 'd-none'}`}>
                    <div className={'row'}>
                      <div
                        className={'col-4 text-start text-dark text-nowrap font-14'}>
                                                <span
                                                  className={'sfProDisplayFont'}>{value.position}. &nbsp;</span>
                        <img src={value.team.icon} height={20} alt=""/>
                        <span className={'sfProDisplayFont'}>
                                                    &nbsp; {value.team.name}
                                                </span>
                      </div>
                      <div className={'col text-end text-light-black'}>
                        {() => {
                          if (value.arrow === 'up') {
                            return (<FontAwesomeIcon icon={faAngleUp}
                                                     color={'#0e7d45'}
                                                     size={'1x'}/>);
                          }
                          if (value.arrow === 'down') {
                            return (
                              <FontAwesomeIcon icon={faAngleDown}
                                               color={'#e02020'}
                                               size={'1x'}/>);
                          }
                        }}
                      </div>
                      <div
                        className={'col text-end text-light-black sfProDisplayFont font-14'}>{value.mp}</div>
                      <div
                        className={'col text-end text-light-black sfProDisplayFont font-14'}>{value.gd}</div>
                      <div
                        className={'col text-end text-light-black sfProDisplayFont font-14'}>{value.pts}</div>
                    </div>
                  </li>
                );
              }))
            }
            {[1204, 1221, 1229, 1399].includes(selectedLeague?.league_id) && renderEmblems()}
          </ul>
        </div>
        {!isMobile && <RightNewsAds isMobile={isMobile}
                                    limit={target !== 'fanzine' ? store?.misc?.appInit?.league_gs_id : (selectedLeague?.league_id ===
                                      '1005' ||
                                      selectedLeague?.league_id ===
                                      '1007') ? 7 : 3}
                                    leagueId={selectedLeague?.league_id ||
                                      '1204'}/>}
      </div>
    );
  };

  const withData = () => {
    const hostName = window.location.hostname;
    const domainObject = getHostname(hostName);
    const target = domainObject.host;
    const navColor = NAV_CLASS[target];
    const textColor = TEXT_COLOR[target];
    const fontColor = FONT_COLOR[target];
    const color = COLORS[target];
    const standingBgColor = COLORS_RGBA[target] + '0.3)';

    let isGroup = standings && standings[0]?.group !== '';
    let tmp = [];
    if (standings !== null && standings !== undefined) {
      if (standings && isGroup) {
        let arr = [];
        let allGroups = standings.map((item) => item?.group); //gets all groups if any
        for (let i = 0; i < standings?.length; i++) {
          if (standings[i]?.group === allGroups[i]) {
            arr.push(standings[i]);
            if (arr.length === 4) {
              tmp.push(arr);
              arr = [];
            }
          }
        }
      } else if (standings && !isGroup) {
        let arr = [];
        for (let i = 0; i < standings?.length; i++) {
          arr.push(standings[i]);
        }
        tmp.push(arr);
      }
    }

    console.log('tmp =', tmp)

    for (let i = 0; i < tmp?.length; i++) {
      tmp[i]?.sort(function (a, b) {
        return a.position - b.position;
      });
    }


    const renderDefault = () => {

      return (
        <div>
          {isMobile ?
            !fullTable && standings ?
              renderMobilePartialTable(standingBgColor, textColor,
                color, tmp) :
              renderMobileFullTable(standingBgColor, textColor, color,
                tmp) :
            renderLaptopTable(standingBgColor, textColor, color, navColor,
              fontColor, '', tmp)
          }
        </div>
      );
    };

    const renderGroups = () => {
      return (
        standings && tmp.map((value, index) => (
          <>
            {renderLaptopTable(standingBgColor, textColor, color, navColor,
              fontColor, value)
            }
          </>
        ))
      );
    };

    return (
      <>
        {renderDefault()}
        {/*{tmp !== [] && renderGroups()}*/}
      </>
    );
  };

  const render = () => {
    return (
      <div className={'bg-white pt-5 pb-5'}>
        {
          (typeof team === 'undefined') ?
            target === 'fanzine' && <SubNavbar isMobile={isMobile}/>
            :
            target === 'fanzine' && <SubNavbar data={teamData} isMobile={isMobile} isClub={team}/>
        }
        {loading &&
          <Loader loading={loading}/>
        }

        {
          (standings === null) ? ''
            :
            withData()
        }
      </div>
    );
  };

  return render();

}

export default ClubTable;
