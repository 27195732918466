import NewNavbar from '../../../../Components/NewNavbar/NewNavbar';
import React, {useEffect, useRef, useState} from 'react';
import {ButtonGroup, Spinner, Table, Toast, ToggleButton,} from 'react-bootstrap';
import {COLORS_RGBA, FONT_COLOR, LOADING_GIF, NAV_CLASS, TEXT_COLOR,} from '../../../../Configs/Targets';
import {getHostname} from '../../../../CustomFunctions/getHostname';
import {graphql} from '../../../../Axios/graphql';
import {ShopifyGraphQlQueries} from '../../../../Configs/ShopifyGraphQlQueries';
import {PRODUCT_COLORS, SCREEN_SIZE, SHOPIFY,} from '../../../../Configs/Constants';
import {setCartItems, setGrandTotal,} from '../../../../redux/actions/CartActions';
import {ProductSizeChart} from '../../../../Axios/EndPoints';
import {get} from '../../../../Axios/get';
import {shuffleArray} from '../../../../CustomFunctions/shuffleArray';
import {useDispatch, useSelector} from 'react-redux';
import {useWindowSize} from '../../../../CustomFunctions/useWindowSize';
import {shopPageImages} from '../../../../Components/Carousel/images';
import {useParams} from 'react-router-dom';
import {withRouter} from '../../../../CustomFunctions/withRouter';
import ProductComponent from '../../../../Components/ClubShop/ProductComponent';
import {setRandomProducts} from '../../../../redux/actions/StoreActions';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faInfoCircle} from '@fortawesome/free-solid-svg-icons';

const NewSingleProductPage = (props) => {
    const store = useSelector(state => state);
    const [addToCartLoading, setAddToCartLoading] = useState(false);
    const [showSizeGuide, setShowSizeGuide] = useState(false);
    const [showMiniCart, setShowMiniCart] = useState(false);
    const [bigImage, setBigImage] = useState(null);
    const sizeTarget = useRef(null);
    const miniCartTarget = useRef(null);
    // const showSizeGuide = false;

    const dispatch = useDispatch();
    const cart = useSelector((state) => state);
    let windowSize = useWindowSize();

    const hostName = window.location.hostname;
    const domainObject = getHostname(hostName);
    let target = domainObject.host;
    let bgColor = NAV_CLASS[target], textColor = TEXT_COLOR[target],
        fontColor = FONT_COLOR[target],
        colorRgba = COLORS_RGBA[target], topBannerStyle = {
            width: '100%',
            height: '144px',
            background: `linear-gradient(to bottom, ${colorRgba}0.71) 45%, ${colorRgba}1)), url(${require(
                '../../../../assets/images/shop_top_banner.png')})`,
            backgroundSize: 'cover',
        }, currentItem = shopPageImages[1], loadingGif = LOADING_GIF[target];
    ;
    let productId = props.match.params.id;
    let params = useParams();
    let isMobile = false;

    if (windowSize.width <= SCREEN_SIZE.MOBILE) {
        isMobile = true;
    }
    /* Variables End */

    /* Use Effect Start */
    /* Use Effect End */

    const [showToast, setShowToast] = useState(false);

    const [selectedSideImage, setSelectedSideImage] = useState('');
    const [selectedColorImage, setSelectedColorImage] = useState('');
    const [productColors, setProductColors] = useState(null);
    const [productColorSizes, setProductColorSizes] = useState(null);
    const [selectedColor, setSelectedColor] = useState('');
    const [selectedMainImage, setSelectedMainImage] = useState(null);
    const [selectedSize, setSelectedSize] = useState('');
    const [productDetail, setProductDetail] = useState(null);
    const [sizes, setSizes] = useState(null);
    const [cartItems, setCartItem] = useState(cart.cart.cartItems);
    const [selectedItemPrice, setSelectedItemPrice] = useState(null);
    const [showSize, setShowSize] = useState(false);
    const [productTagType, setProductTagType] = useState('');
    const [sizeChart, setSizeChart] = useState(null);
    const [oneSize, setOneSize] = useState(true);
    const [checkoutId, setCheckoutId] = useState(false);
    const [loading, setLoading] = useState(false);
    const [productModel, setProductModel] = useState(false);
    const [productStyle, setProductStyle] = useState(false);
    const [productFinish, setProductFinish] = useState(false);
    const [isAccessory, setIsAccessory] = useState(false);
    const [selectedPhoneCoverModel, setSelectedPhoneCoverModel] = useState('');
    const [selectedPhoneCoverStyle, setSelectedPhoneCoverStyle] = useState('');
    const [selectedPhoneCoverFinish, setSelectedPhoneCoverFinish] = useState('');
    const [selectedCushionSize, setSelectedCushionSize] = useState('');
    const [selectedCushionMaterial, setSelectedCushionMaterial] = useState('');
    const [selectedFramedPrintSize, setSelectedFramedPrintSize] = useState('');
    const [selectedFramedPrintFrame, setSelectedFramedPrintFrame] = useState('');
    const [selectedScarfColor, setSelectedScarfColor] = useState('');
    const [selectedScarfSize, setSelectedScarfSize] = useState('');
    const [selectedSnoodColor, setSelectedSnoodColor] = useState('');
    const [selectedSnoodSize, setSelectedSnoodSize] = useState('');
    const [selectedMugsSize, setSelectedMugsSize] = useState('');
    const [selectedMugsColor, setSelectedMugsColor] = useState('');
    const [selectedNotebookSize, setSelectedNotebookSize] = useState('');
    const [selectedNotebookStyle, setSelectedNotebookStyle] = useState('');
    const [selectedCoasterQuantity, setSelectedCoasterQuantity] = useState('');
    const [selectedTowelSize, setSelectedTowelSize] = useState('');
    const [itemQuantity, setItemQuantity] = useState(1);
    const [randomProducts, setRandomProductsLocal] = useState([]);

    const getShopifyCart = () => {
        let checkoutId = localStorage.getItem('checkoutId');
        let data = {checkout_id: checkoutId};
        graphql(ShopifyGraphQlQueries(SHOPIFY.GET_SHOPIFY_CART, null, null, data))
            .then((response) => {
                if (response.data.data !== undefined && response.data.data.node !==
                    undefined && response.data.data.node.lineItems !== undefined) {
                    console.log('get shopify cart in product detail: ', response);
                    let tempCartItems = [];
                    response.data.data.node.lineItems.edges.map((item) => {
                        let tempVariant = {
                            id: item?.node?.variant?.id,
                            title: item?.node?.variant?.title,
                            price: item?.node?.variant?.price?.amount,
                            image: item?.node?.variant?.image,
                        };
                        let node = {
                            id: item?.node?.variant?.product?.id,
                            product_name: item?.node?.title,
                            variant: tempVariant,
                            size: item?.node?.variant?.selectedOptions[0]?.value,
                            color: item?.node?.variant?.selectedOptions[1] ?
                                item?.node?.variant?.selectedOptions[1] :
                                '',
                            lineItemId: item?.node?.id,
                            quantity: item?.node?.quantity,
                        };
                        tempCartItems.push(node);

                    });
                    // setShowMiniCart(true);
                    setAddToCartLoading(false);
                    setShowToast(true);
                    dispatch(setCartItems(tempCartItems));
                } else {
                    localStorage.removeItem('checkoutUrl');
                }

            })
            .catch((err) => {
                console.log(err);
            });
    };

    const fetchData = () => {
        setLoading(true);
        graphql(ShopifyGraphQlQueries(SHOPIFY.PRODUCT_DETAIL, null, params.id))
            .then((response) => {

                let productDetail = response.data.data.productByHandle;
                let tempColors = [];
                let tempColorSizes = [];
                let tempProductColors = [];
                if (productDetail) {
                    setLoading(false);
                    if (productDetail.productType === 'Accessories' &&
                        productDetail.tags.includes('Phone Cases')) {
                        setIsAccessory(true);
                        setSelectedPhoneCoverModel(productDetail.options[0].values[0]);
                        setSelectedPhoneCoverStyle(productDetail.options[1].values[0]);
                        setSelectedPhoneCoverFinish(productDetail.options[2].values[0]);
                        setSelectedItemPrice(productDetail.variants.edges[0].node);
                    }
                    if (productDetail.productType === 'Accessories' &&
                        productDetail.tags.includes('Cushions')) {
                        setIsAccessory(true);
                        setSelectedCushionMaterial(productDetail.options[0].values[0]);
                        setSelectedCushionSize(productDetail.options[1].values[0]);
                    }
                    if (productDetail.productType === 'Accessories' &&
                        productDetail.tags.includes('Wall Art')) {
                        setIsAccessory(true);
                        setSelectedFramedPrintSize(productDetail.options[0].values[0]);
                        setSelectedFramedPrintFrame(productDetail.options[1].values[0]);
                    }
                    if (productDetail.productType === 'Accessories' &&
                        productDetail.tags.includes('Scarves')) {
                        setIsAccessory(true);
                        setSelectedScarfSize(productDetail.options[0].values[0]);
                        setSelectedScarfColor(productDetail.options[1].values[0]);
                    }
                    if (productDetail.productType === 'Accessories' &&
                        productDetail.tags.includes('Snood')) {
                        setIsAccessory(true);
                        setSelectedSnoodSize(productDetail.options[0].values[0]);
                        setSelectedSnoodColor(productDetail.options[1].values[0]);
                    }
                    console.log('productDetail: ', productDetail);
                    if (productDetail.productType === 'Accessories' &&
                        productDetail.tags.includes('Mugs')) {
                        setIsAccessory(true);
                        setSelectedMugsSize(productDetail.options[0].values[0]);
                        setSelectedItemPrice(productDetail.variants.edges[0].node);
                        if (productDetail.options[1]) {
                            setSelectedMugsColor(productDetail.options[1].values[0]);
                        }
                    }
                    if (productDetail.productType === 'Accessories' &&
                        productDetail.tags.includes('Notebooks')) {
                        setIsAccessory(true);
                        setSelectedNotebookSize(productDetail.options[0].values[0]);
                        setSelectedNotebookStyle(productDetail.options[1].values[0]);
                    }
                    if (productDetail.productType === 'Accessories' &&
                        productDetail.tags.includes('Coasters')) {
                        setIsAccessory(true);
                        setSelectedItemPrice(productDetail.variants.edges[0].node);
                        setSelectedCoasterQuantity(productDetail.options[0].values[0]);
                    }
                    if (productDetail.productType === 'Accessories' &&
                        productDetail.tags.includes('Towels')) {
                        setIsAccessory(true);
                        setSelectedTowelSize(productDetail.options[0].values[0]);
                        setSelectedItemPrice(productDetail.variants.edges[0].node);
                    }
                    setProductDetail(productDetail);
                    setSelectedMainImage(productDetail.images.edges[0].node.src);
                    setBigImage(productDetail.images.edges[0].node.src);
                    tempColors = productDetail.options[1].values;
                    tempColors.map((color) => {
                        tempColorSizes.push();
                        tempColorSizes[`${color}`] = productDetail.variants.edges.filter(
                            (variant) => variant.node.title.split(
                                    ' / ')[variant.node.title.split(' / ').length - 1] ===
                                color);
                    });
                    setProductColorSizes(tempColorSizes);
                    tempColors.map((color) => {
                        tempProductColors.push(tempColorSizes[color][0].node);
                    });
                    setProductColors(tempProductColors);
                    setProductColorSizes(tempColorSizes);
                    setSizes(tempColorSizes[tempColors[0]]);
                    if (tempColorSizes[tempColors[0]][0].node.title.split(' / ')[0] ===
                        'One Size') {
                        setOneSize(false);
                    }
                    setSelectedColor(tempColors[0]);
                    setSelectedItemPrice(tempColorSizes[tempColors[0]][0].node);

                    let tagForSizeGuide = productDetail.tags[0] === 'Featured' ?
                        productDetail.tags[1] :
                        productDetail.tags[0];
                    setProductTagType(
                        tagForSizeGuide + ' ' + productDetail.productType);

                }
            })
            .catch((err) => {
                console.error(err);
            });
    };

    const fetchBestSelling = () => {
        graphql(ShopifyGraphQlQueries('BEST_SELLING'))
            .then((response) => {
                let products = response.data.data['collectionByHandle']['products'].edges;
                if (products.length) {
                    let tempBestSelling = [];
                    products.map((value) => {
                        let product = value.node;
                        let variants = [];
                        PRODUCT_COLORS.map((colors, index) => {
                            let tmp = product.variants.edges.filter((val) => {
                                return val.node.title.includes(colors) ? val.node : '';
                            });
                            if (tmp !== '' && tmp?.length > 0) {
                                variants.push(tmp);
                            }
                        });
                        tempBestSelling.push({
                            id: product?.id,
                            name: product?.title,
                            src: product?.images && product?.images?.edges[0]?.node?.src,
                            alt: product?.images && product?.images?.edges[0]?.node?.alt,
                            handle: product?.handle,
                            price: product['variants']?.edges[0]?.node?.price?.amount,
                            oldPrice: product['variants']?.edges[0]?.node?.compareAtPrice?.amount,
                            variants: variants,
                        });
                        return tempBestSelling;
                    });
                    dispatch(setRandomProducts(tempBestSelling));
                }
            });
    };

    const fetchSizeChart = () => {
        if (!sizeChart) {
            let tagType = convertToSlug(productTagType);
            let url = ProductSizeChart + tagType;
            get(url)
                .then((response) => {
                    if (response.status === 200) {
                        setSizeChart(response.data.table);
                    } else {
                    }
                    setShowSize(true);
                })
                .catch((err) => {
                    console.error(err);
                    setShowSize(false);
                });
        }
    };
    /* API Call End */

    /* Event Methods Start */

    const convertToSlug = (string) => {
        return string
            .toLowerCase()
            .replace(/ /g, '-')
            .replace(/[^\w-]+/g, '')
            ;
    };
    const addToCart = () => {
        setShowSize(false);
        setAddToCartLoading(true);
        if (selectedSize !== '' ||
            (selectedCushionSize !== '' && selectedCushionMaterial !== '') ||
            (selectedPhoneCoverModel !== '' && selectedPhoneCoverStyle !== '' &&
                selectedPhoneCoverFinish !== '') ||
            (selectedScarfColor !== '' && selectedScarfSize !== '') ||
            (selectedNotebookSize !== '' && selectedNotebookStyle !== '') ||
            (selectedFramedPrintFrame !== '' && selectedFramedPrintSize !== '') ||
            (selectedSnoodSize !== '' && selectedSnoodColor !== '') ||
            (selectedMugsSize !== '' || selectedMugsColor !== '') ||
            selectedCoasterQuantity !== '' || selectedTowelSize !== '') {
            setLoading(true);
            let selectedVariant = {};
            console.log('productDetail: ', productDetail);
            if (productDetail.productType !== 'Accessories') {
                if (selectedColor === '') {
                    alert('Please select the Options first.');
                }
                selectedVariant = productColorSizes[selectedColor].filter(
                    (value) => value.node.title === selectedSize + ' / ' +
                        selectedColor)[0].node;
            }
            if (productDetail.productType === 'Accessories' &&
                productDetail.tags.includes('Phone Cases')) {
                selectedVariant = productDetail.variants.edges.filter(
                    (value) => value.node.title === selectedPhoneCoverModel + ' / ' +
                        selectedPhoneCoverStyle + ' / ' +
                        selectedPhoneCoverFinish)[0].node;
            }
            if (productDetail.productType === 'Accessories' &&
                productDetail.tags.includes('Cushions')) {
                selectedVariant = productDetail.variants.edges.filter(
                    (value) => value.node.title === selectedCushionMaterial + ' / ' +
                        selectedCushionSize)[0].node;
            }
            if (productDetail.productType === 'Accessories' &&
                productDetail.tags.includes('Wall Art')) {
                selectedVariant = productDetail.variants.edges.filter(
                    (value) => value.node.title === selectedFramedPrintSize + ' / ' +
                        selectedFramedPrintFrame)[0].node;
            }
            if (productDetail.productType === 'Accessories' &&
                productDetail.tags.includes('Scarves')) {
                selectedVariant = productDetail.variants.edges.filter(
                    (value) => value.node.title === selectedScarfSize + ' / ' +
                        selectedScarfColor)[0].node;
            }
            if (productDetail.productType === 'Accessories' &&
                productDetail.tags.includes('Snood')) {
                selectedVariant = productDetail.variants.edges.filter(
                    (value) => value.node.title === selectedSnoodSize + ' / ' +
                        selectedSnoodColor)[0].node;
            }
            if (productDetail.productType === 'Accessories' &&
                productDetail.tags.includes('Mugs')) {
                if (selectedMugsColor !== '') {
                    selectedVariant = productDetail.variants.edges.filter(
                        (value) => value.node.title === selectedMugsSize + ' / ' +
                            selectedMugsColor)[0].node;
                } else {
                    selectedVariant = productDetail.variants.edges.filter(
                        (value) => value.node.title === selectedMugsSize)[0].node;
                }
            }
            if (productDetail.productType === 'Accessories' &&
                productDetail.tags.includes('Notebooks')) {
                selectedVariant = productDetail.variants.edges.filter(
                    (value) => value.node.title === selectedNotebookSize + ' / ' +
                        selectedNotebookStyle)[0].node;
            }
            if (productDetail.productType === 'Accessories' &&
                productDetail.tags.includes('Coasters')) {
                selectedVariant = productDetail.variants.edges.filter(
                    (value) => value.node.title === selectedCoasterQuantity)[0].node;
            }
            if (productDetail.productType === 'Accessories' &&
                productDetail.tags.includes('Towels')) {
                selectedVariant = productDetail.variants.edges.filter(
                    (value) => value.node.title === selectedTowelSize)[0].node;
            }
            /*let item = {
                id: productDetail.id,
                product_name: productDetail.title,
                variant: selectedVariant,
                size: selectedSize,
                color: selectedColor,
            };*/
            let filterCheck = cart.cart.cartItems.filter(
                (value) => value.variant.id === selectedVariant.id);
            console.log('variant id: ', selectedVariant.id);
            if (filterCheck.length === 0) {
                let checkoutId = localStorage.getItem('checkoutId');
                if (!checkoutId) {
                    let data = {variant_quantity: 1, variant_id: selectedVariant.id};
                    graphql(
                        ShopifyGraphQlQueries(SHOPIFY.CHECKOUT_CREATE, null, null, data))
                        .then((response) => {
                            if (response.data.data !== undefined &&
                                response.data.data.checkoutCreate !== undefined) {
                                localStorage.setItem('checkoutId',
                                    response.data.data.checkoutCreate.checkout.id);
                                localStorage.setItem('checkoutUrl',
                                    response.data.data.checkoutCreate.checkout.webUrl);
                                setCheckoutId(true);
                                setLoading(false);
                                getShopifyCart();
                            } else {
                                localStorage.removeItem('checkoutId');
                                setLoading(false);
                                setAddToCartLoading(false);
                                setCheckoutId(false);
                                dispatch(setCartItems([]));
                                console.log('Coming Here When Adding New Cart.');
                                console.log('creating new cart:', response);
                                alert('Something went wrong. Please try again.');
                            }

                        }).catch((err) => {
                        console.error(err);
                    });
                } else {
                    let data = {
                        checkout_id: checkoutId,
                        variant_quantity: 1,
                        variant_id: selectedVariant.id,
                    };
                    graphql(
                        ShopifyGraphQlQueries(SHOPIFY.CHECKOUT_LINE_ITEMS_ADD, null, null,
                            data))
                        .then((response) => {
                            if (response.data.data !== undefined &&
                                response.data.data.checkoutLineItemsAdd !== undefined) {
                                localStorage.removeItem('checkoutUrl');
                                // console.log('response.data.data.checkoutLineItemsAdd.checkout.webUrl =', response.data.data.checkoutLineItemsAdd.checkout)
                                localStorage.setItem('checkoutUrl', response.data.data.checkoutLineItemsAdd.checkout.webUrl);
                                setCheckoutId(true);
                                setLoading(false);
                                getShopifyCart();
                            } else {
                                localStorage.removeItem('checkoutUrl');
                                localStorage.removeItem('checkoutId');
                                setLoading(false);
                                setAddToCartLoading(false);
                                setCheckoutId(false);
                                dispatch(setCartItems([]));
                                console.log('coming here.');
                                alert('Something went wrong. Please try again.');
                            }

                        })
                        .catch((err) => {
                            console.error(err);
                        });
                }
                /*setCartItem([...cartItems, ...[item]]);
                dispatch(setCartItems([...cartItems, ...[item]]));*/

                dispatch(setGrandTotal(parseFloat(cart.cart.grandTotal) +
                    parseFloat(selectedVariant.price)));
            } else {
                let checkoutId = localStorage.getItem('checkoutId');
                let tempQuantity = itemQuantity + 1;
                setItemQuantity(itemQuantity + 1);
                console.log('lineitem id: ', filterCheck);
                let data = {
                    checkout_id: checkoutId,
                    variant_quantity: tempQuantity,
                    linkItem_id: filterCheck[0].lineItemId,
                };
                graphql(ShopifyGraphQlQueries(SHOPIFY.CHECKOUT_LINE_ITEMS_UPDATE, null,
                    null, data))
                    .then((response) => {
                        if (response.data.data !== undefined &&
                            response.data.data.checkoutLineItemsUpdate !== undefined) {
                            localStorage.removeItem('checkoutUrl');
                            localStorage.setItem('checkoutUrl',
                                response.data.data.checkoutLineItemsUpdate.checkout.webUrl);
                            /*setSubtotal(subtotal + parseFloat(currentItem[0].node.variant.price));
                            getShopifyCart();*/
                            setCheckoutId(true);
                            setLoading(false);
                            getShopifyCart();
                        } else {
                            localStorage.removeItem('checkoutId');
                            setLoading(false);
                            setAddToCartLoading(false);
                            setCheckoutId(false);
                            dispatch(setCartItems([]));
                            alert('Something went wrong. Please try again.');
                        }

                    })
                    .catch((err) => {
                        console.error(err);
                    });
                // setLoading(false);
            }
        } else {
            // setLoading(false)
            setAddToCartLoading(false);
            alert('Please select the Options first.');
        }
    };

    const selectImage = (img) => {
        setSelectedSideImage(img.currentTarget.id);
        setSelectedMainImage(img.currentTarget.src);
    };

    const selectColor = (img) => {
        console.log('img =', img);
        setSelectedColorImage(img.currentTarget.value);
        setSizes(productColorSizes[img.currentTarget.value]);
        isMobile && setSelectedMainImage(
            img.currentTarget[img.currentTarget.selectedIndex].dataset.src);
        !isMobile && setSelectedMainImage(
            img.currentTarget[img.currentTarget.selectedIndex].dataset.src);
        setSelectedColor(img.currentTarget.value);
    };

    const selectSize = (e) => {
        setSelectedSize(e.currentTarget.value);
        let selectedItem = productColorSizes[selectedColor]
            .filter((value) => value.node.title === e.currentTarget.value + ' / ' +
                selectedColor);
        setSelectedItemPrice(selectedItem[0].node);
    };

    const showSizeGuideTable = () => {
        fetchSizeChart();
        if (showSize) {
            setShowSize(false);
        } else {
            setShowSize(true);
        }
    };

    const setSelectedAccessoriesOption = (event) => {
        let key = event.currentTarget.dataset.option;
        let selectedItem;
        console.log('selectedItem: ', productDetail);
        if (productDetail.tags.includes('Phone Cases')) {
            switch (key) {
                case 'Model':
                    setSelectedPhoneCoverModel(event.currentTarget.value);
                    try {
                        selectedItem = productDetail.variants
                            .filter(
                                (value) => value.node.title === event.currentTarget.value +
                                    ' / ' + selectedPhoneCoverStyle + ' / ' +
                                    selectedPhoneCoverFinish);
                    } catch (e) {
                        selectedItem = productDetail.variants.edges
                            .filter(
                                (value) => value.node.title === event.currentTarget.value +
                                    ' / ' + selectedPhoneCoverStyle + ' / ' +
                                    selectedPhoneCoverFinish);
                    }
                    setSelectedItemPrice(selectedItem[0].node);
                    setSelectedMainImage(selectedItem[0].node.image.src);
                    break;
                case 'Style':
                    setSelectedPhoneCoverStyle(event.currentTarget.value);
                    try {
                        selectedItem = productDetail.variants
                            .filter(
                                (value) => value.node.title === selectedPhoneCoverModel +
                                    ' / ' + event.currentTarget.value + ' / ' +
                                    selectedPhoneCoverFinish);
                    } catch (e) {
                        selectedItem = productDetail.variants.edges
                            .filter(
                                (value) => value.node.title === selectedPhoneCoverModel +
                                    ' / ' + event.currentTarget.value + ' / ' +
                                    selectedPhoneCoverFinish);
                    }
                    setSelectedItemPrice(selectedItem[0].node);
                    break;
                case 'Finish':
                    setSelectedPhoneCoverFinish(event.currentTarget.value);
                    try {
                        selectedItem = productDetail.variants
                            .filter(
                                (value) => value.node.title === selectedPhoneCoverModel +
                                    ' / ' + selectedPhoneCoverStyle + ' / ' +
                                    event.currentTarget.value);
                    } catch (e) {
                        selectedItem = productDetail.variants
                            .filter(
                                (value) => value.node.title === selectedPhoneCoverModel +
                                    ' / ' + selectedPhoneCoverStyle + ' / ' +
                                    event.currentTarget.value);
                    }
                    setSelectedItemPrice(selectedItem[0].node);
                    break;
                default:
                    return false;
            }
        }
        if (productDetail.tags.includes('Cushions')) {
            switch (key) {
                case 'Material':
                    setSelectedCushionMaterial(event.currentTarget.value);
                    break;
                case 'Size':
                    setSelectedCushionSize(event.currentTarget.value);
                    selectedItem = productDetail.variants.edges
                        .filter((value) => value.node.title === selectedCushionMaterial +
                            ' / ' + event.currentTarget.value);
                    setSelectedItemPrice(selectedItem[0].node);
                    break;
                default:
                    return false;
            }
        }
        if (productDetail.tags.includes('Wall Art')) {
            console.log('productColorSizes', productColorSizes,
                event.currentTarget.value, selectedColor, key);
            switch (key) {
                case 'Frame':
                    setSelectedFramedPrintFrame(event.currentTarget.value);
                    break;
                case 'Size':
                    setSelectedFramedPrintSize(event.currentTarget.value);
                    let selectedItem = productColorSizes[selectedColor]
                        .filter(
                            (value) => value.node.title === event.currentTarget.value +
                                ' / ' + selectedColor);
                    setSelectedItemPrice(selectedItem[0].node);
                    break;
                default:
                    return false;
            }
        }
        if (productDetail.tags.includes('Scarves')) {
            switch (key) {
                case 'Color':
                    setSelectedScarfColor(event.currentTarget.value);
                    break;
                case 'Size':
                    setSelectedScarfSize(event.currentTarget.value);
                    break;
                default:
                    return false;
            }
        }
        if (productDetail.tags.includes('Snoods')) {
            switch (key) {
                case 'Color':
                    setSelectedSnoodColor(event.currentTarget.value);
                    break;
                case 'Size':
                    setSelectedSnoodSize(event.currentTarget.value);
                    break;
                default:
                    return false;
            }
        }
        if (productDetail.tags.includes('Mugs')) {
            switch (key) {
                case 'Size':
                    setSelectedMugsSize(event.currentTarget.value);
                    break;
                default:
                    return false;
            }
        }
        if (productDetail.tags.includes('Notebooks')) {
            switch (key) {
                case 'Size':
                    setSelectedNotebookSize(event.currentTarget.value);
                    break;
                case 'Style':
                    setSelectedNotebookStyle(event.currentTarget.value);
                    break;
                default:
                    return false;
            }
        }
        if (productDetail.tags.includes('Coasters')) {
            switch (key) {
                case 'Quantity':
                    setSelectedCoasterQuantity(event.currentTarget.value);
                    selectedItem = productDetail.variants.edges
                        .filter(
                            (value) => value.node.title === event.currentTarget.value);
                    setSelectedItemPrice(selectedItem[0].node);
                    break;
                default:
                    return false;
            }
        }
        if (productDetail.tags.includes('Towels')) {
            switch (key) {
                case 'Size':
                    setSelectedTowelSize(event.currentTarget.value);
                    selectedItem = productDetail.variants.edges
                        .filter(
                            (value) => value.node.title === event.currentTarget.value);
                    setSelectedItemPrice(selectedItem[0].node);
                    break;
                default:
                    return false;
            }
        }
    };
    /* Event Methods End */

    useEffect(() => {

    }, []);
    useEffect(() => {
        fetchData();
        cart.shopify.randomProducts.length <= 0 && fetchBestSelling();
    }, [props.match.params.id]);
    useEffect(() => {

    }, [randomProducts]);
    useEffect(() => {
        setRandomProductsLocal(
            shuffleArray(cart.shopify.randomProducts).slice(0, 4));
    }, [cart.shopify.randomProducts]);
    useEffect(() => {

    }, [loading, addToCartLoading]);

    const renderAccessoryOptions = () => {
        return (
            isAccessory && productDetail && productDetail.options.map((option) => (
                <span
                    className={`mt-2 mb-4 w-100 col-4 bebasFont justify-content-start ${textColor} d-block`}>
                {option.name}
                    <select
                        className="form-select w-100 color-list fanzine-select text-dark"
                        onChange={setSelectedAccessoriesOption}
                        data-option={option.name}>
                    {option.values.map((value, index) => (
                        <option value={value}>
                            {value}
                        </option>

                    ))}
                </select>
            </span>
            ))
        );
    };

    const renderColorsList = () => {
        return (
            <span
                className={`mt-2 mb-4 w-100 col-4 bebasFont justify-content-start ${textColor} d-block`}>
                Color
                <select
                    className="form-select w-100 color-list fanzine-select text-dark"
                    onChange={selectColor}>
                    {productColors && productColors.map((value, index) => (
                        <option value={value.title.split(' / ')[1]}
                                data-src={value.image.src}>
                            {value.title.split(' / ')[1]}
                        </option>

                    ))}
                </select>
            </span>
        );
    };

    const renderSizes = () => {
        return (
            <>
                {!isAccessory && <>
                    <div className={`d-flex flex-row w-100 mb-4`}>
                    <span
                        className={`mt-2 d-flex flex-row w-50 bebasFont justify-content-start ${textColor}`}>
                        Select Size
                    </span>
                        {oneSize ? (<span
                            className={`btn d-flex flex-row w-50 bebasFont justify-content-end small text-light-black`}>
                        <div
                            className={isMobile ?
                                `${bgColor} text-white ps-4 pe-2 pt-1 pb-1 rounded-pill text-end` :
                                'shadow-nav'}
                            onClick={showSizeGuideTable}>
                            Size Guide {' '}
                            <FontAwesomeIcon icon={faInfoCircle}/>
                        </div>
                    </span>) : <></>}
                    </div>
                    {sizeChart &&
                        <div className={`${showSize ? '' : 'd-none'} text-center`}>
                            <Table striped bordered hover className="bg-white"
                                   width="900">
                                <thead className="bg-black text-white">
                                <tr>
                                    {sizeChart.title.map((item) => (
                                        <th width="750">{item}</th>
                                    ))}
                                </tr>
                                </thead>
                                <tbody>
                                {Object.values(sizeChart.size).map((item) => (
                                    <tr>
                                        {item.map((size) => (
                                            <td>{size}</td>
                                        ))}
                                    </tr>
                                ))}
                                </tbody>
                            </Table>
                        </div>}
                    <div className={`d-flex flex-row w-100 flex-wrap`}>
                        <ButtonGroup>
                            <div className="row pe-2">
                                {
                                    sizes && sizes.map((value, index) => {
                                        return (
                                            /*<ProductSizeToggleButton index={index} value={value} isMobile={isMobile}/>*/
                                            <div className="col-2 mt-2">
                                                <ToggleButton
                                                    key={index}
                                                    id={`radio-${index}`}
                                                    type="radio"
                                                    variant={selectedSize === value ?
                                                        'dark' :
                                                        'light'}
                                                    name="radio"
                                                    value={value.node.title.split(' / ')[0]}
                                                    checked={selectedSize ===
                                                        value.node.title.split(' / ')[0]}
                                                    className={`ms-1 me-1 sizeBox ${selectedSize ===
                                                    value.node.title.split(' / ')[0] && bgColor}`}
                                                    onChange={selectSize}
                                                >
                                                    {value.node.title.split(' / ')[0]}
                                                </ToggleButton>
                                            </div>
                                        );
                                    })
                                }
                            </div>
                        </ButtonGroup>
                    </div>
                </>}
                <div className={`d-flex flex-row w-100 mt-3 ${isMobile ?
                    'justify-content-center' :
                    ''}`}>
                    <span
                        className={`btn btn-dark p-2 text-uppercase ${bgColor} border-0 sfProDisplayFont ${isMobile ?
                            'w-auto rounded-pill btn-lg p-3' :
                            'small w-25 rounded'} ${fontColor}`}
                        onClick={addToCart}>Add To Cart</span>
                </div>
            </>
        );
    };

    const render = () => {
        return (
            <>
                <NewNavbar/>
                <div>
                    <div className={'container mt-5'}>
                        <div className={'row position-relative'}>
                            <div
                                className={'col-sm-12 col-xs-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 mt-5'}>
                                <div className={'row'}>
                                    <div
                                        className={'col-sm-12 col-xs-12 col-md-8 col-lg-8 col-xl-8 col-xxl-8'}>
                                        {
                                            bigImage !== null &&
                                            <img
                                                src={selectedMainImage}
                                                alt={'product'}
                                                style={{
                                                    objectFit: 'cover',
                                                    height: 930,
                                                    width: '100%',
                                                }}
                                            />
                                        }
                                        <div className={'row mt-3'}>
                                            {
                                                productDetail &&
                                                productDetail.images.edges.map((items, index) => {
                                                    let imageId = `sideImage${items?.node?.id}`;
                                                    let activeClass = '';
                                                    if (selectedSideImage === imageId) {
                                                        activeClass = 'shadow-nav-dark';
                                                    }

                                                    return (
                                                        <>
                                                            {
                                                                index !== 0 &&
                                                                <div
                                                                    className={'col-sm-12 col-xs-12 col-md-3 col-lg-3 col-xl-3 col-xxl-3'}>
                                                                    <img
                                                                        id={imageId}
                                                                        data-id={index}
                                                                        className={'cursor-pointer'}
                                                                        onClick={selectImage}
                                                                        src={items?.node?.src}
                                                                        alt={items?.node?.altText}
                                                                        // alt={"product"}
                                                                        style={{
                                                                            objectFit: 'cover',
                                                                            height: 214,
                                                                            width: 214,
                                                                        }}
                                                                    />
                                                                </div>
                                                            }
                                                        </>
                                                    );
                                                })
                                            }
                                        </div>
                                    </div>
                                    <div
                                        className={'col-sm-12 col-xs-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4 d-flex flex-column'}>
                                        <span
                                            className={'SFProDisplay-Regular fw-bold'}
                                            style={{
                                                fontSize: 30,
                                                color: '#000000',
                                            }}>{productDetail &&
                                            productDetail?.title}</span>
                                        <div className={'d-flex flex-row'}>
                                            <span
                                                className={`SFProDisplay-Regular fw-bold ${selectedItemPrice?.compareAtPrice ?
                                                    'text-muted text-decoration-line-through' :
                                                    'text-black'}`} style={{
                                                fontSize: 20,
                                            }}>
                                                £{selectedItemPrice?.compareAtPrice ?
                                                selectedItemPrice?.compareAtPrice?.amount :
                                                selectedItemPrice?.price?.amount ? selectedItemPrice?.price?.amount : selectedItemPrice?.price}
                                                {/*GetConvertedPrice(selectedItemPrice?.price,store)}*/}
                                            </span>
                                            {
                                                selectedItemPrice?.compareAtPrice &&
                                                <span
                                                    className={`SFProDisplay-Regular fw-bold ms-2`}
                                                    style={{fontSize: 20}}>£{selectedItemPrice?.price?.amount}</span>
                                            }
                                        </div>

                                        {/*<div className={'d-flex'}>*/}
                                        {/*    <div className={'d-flex flex-column'}>*/}
                                        {/*        <span*/}
                                        {/*            className={'SFProDisplay-Regular fw-bold mt-3 ps-1'}*/}
                                        {/*            style={{*/}
                                        {/*                fontSize: 14,*/}
                                        {/*                color: '#000000',*/}
                                        {/*            }}>*/}
                                        {/*            Colour*/}
                                        {/*        </span>*/}
                                        {/*        <select aria-label="Default select example"*/}
                                        {/*                className={'form-select  color-list fanzine-select text-dark'}*/}
                                        {/*                onChange={selectColor}*/}
                                        {/*                style={{width: 203}}>*/}
                                        {/*            {productColors &&*/}
                                        {/*                productColors.map((value, index) => (*/}
                                        {/*                    <option value={value.title.split(' / ')[1]}*/}
                                        {/*                            data-src={value.image.src}>*/}
                                        {/*                        {value.title.split(' / ')[1]}*/}
                                        {/*                    </option>*/}

                                        {/*                ))}*/}
                                        {/*        </select>*/}
                                        {/*    </div>*/}
                                        {/*    <div*/}
                                        {/*        className={'d-flex flex-column ms-2 position-relative'}>*/}
                                        {/*        <div*/}
                                        {/*            className={'d-flex align-items-center justify-content-between'}>*/}
                                        {/*            <span*/}
                                        {/*                className={'SFProDisplay-Regular fw-bold mt-3 ps-1'}*/}
                                        {/*                style={{*/}
                                        {/*                    fontSize: 14,*/}
                                        {/*                    color: '#000000',*/}
                                        {/*                }}>*/}
                                        {/*                Size*/}
                                        {/*            </span>*/}
                                        {/*            <span*/}
                                        {/*                className={'SFProDisplay-Regular fw-bold mt-3 cursor-pointer'}*/}
                                        {/*                style={{*/}
                                        {/*                    fontSize: 14,*/}
                                        {/*                    color: TEXT_COLORS[target],*/}
                                        {/*                }}*/}
                                        {/*                ref={sizeTarget}*/}
                                        {/*                onClick={showSizeGuideTable}*/}
                                        {/*            >Size Guide</span>*/}

                                        {/*            {sizeChart && <div*/}

                                        {/*                onBlur={showSizeGuideTable}*/}
                                        {/*                id="size-guide-table"*/}
                                        {/*                style={{marginLeft: '97%', zIndex: 10}}*/}
                                        {/*                className={`${showSize ?*/}
                                        {/*                    '' :*/}
                                        {/*                    'd-none'} mt-5 ps-5 position-absolute text-center`}>*/}
                                        {/*                <Table striped bordered hover className="bg-white"*/}
                                        {/*                       width="900">*/}
                                        {/*                    <thead className="bg-black text-white">*/}
                                        {/*                    <tr>*/}
                                        {/*                        {sizeChart.title.map((item) => (*/}
                                        {/*                            <th width="750">{item}</th>*/}
                                        {/*                        ))}*/}
                                        {/*                    </tr>*/}
                                        {/*                    </thead>*/}
                                        {/*                    <tbody>*/}
                                        {/*                    {Object.values(sizeChart.size).map((item) => (*/}
                                        {/*                        <tr>*/}
                                        {/*                            {item.map((size) => (*/}
                                        {/*                                <td>{size}</td>*/}
                                        {/*                            ))}*/}
                                        {/*                        </tr>*/}
                                        {/*                    ))}*/}
                                        {/*                    </tbody>*/}
                                        {/*                </Table>*/}
                                        {/*            </div>}*/}

                                        {/*        </div>*/}

                                        {/*        <select*/}
                                        {/*            className={'form-select  color-list fanzine-select text-dark'}*/}
                                        {/*            aria-label="Default select example"*/}
                                        {/*            onChange={selectSize}*/}
                                        {/*            style={{width: 203}}>*/}
                                        {/*            <option value="">Select Size</option>*/}
                                        {/*            {sizes && sizes.map((value, index) => (*/}

                                        {/*                <option*/}
                                        {/*                    value={value.node.title.split(' / ')[0]}>*/}
                                        {/*                    {value.node.title.split(' / ')[0]}*/}
                                        {/*                </option>*/}
                                        {/*            ))}*/}
                                        {/*        </select>*/}
                                        {/*    </div>*/}
                                        {/*</div>*/}
                                        <div className={`d-flex row`}>
                                            {isAccessory && productDetail &&
                                                productDetail.options.map((option, index) => (
                                                    <span
                                                        key={index}
                                                        className={`mt-2 ${index === 1 ?
                                                            'ms-5' :
                                                            ''} col-4 bebasFont justify-content-start text-gray d-block`}>
                            {option.name}
                                                        <div className={`${isMobile ?
                                                            'col-12' :
                                                            'col-6'}`}>
                                <select
                                    className="form-select w-165 color-list fanzine-select"
                                    onChange={setSelectedAccessoriesOption}
                                    data-option={option.name}>
                                {option.values.map((value, index) => (
                                    <option value={value}>
                                        {value}
                                    </option>

                                ))}
                                </select>
                            </div>
                        </span>
                                                ))}
                                            {!isAccessory && <>
                        <span
                            className={`mt-1 col-6 robotoCondensedBold justify-content-start text-black d-block`}>
                            Colour
                            <div className={`${isMobile ? 'col-12' : 'col-8'}`}>
                                <select
                                    className="form-select w-165 color-list fanzine-select"
                                    onChange={selectColor}>
                                {productColors &&
                                    productColors.map((value, index) => (
                                        <option
                                            value={value.title.split(' / ')[1]}
                                            data-src={value.image.src}>
                                            {value.title.split(' / ')[1]}
                                        </option>

                                    ))}
                                </select>
                            </div>
                        </span>
                                                <span
                                                    className={`col-6 robotoCondensedBold justify-content-start text-black`}>
                            <span className={'m-0 p-0'}>
                            <span className={'m-0 p-0'}>
                            Size
                            </span>
                            <span>
                                                      {oneSize && !isAccessory ?
                                                          <span
                                                              className={`btn ms-5 col-7 p-0 robotoCondensedBold justify-content-center font-10 text-blue`}>
                        <div
                            className={isMobile ?
                                'bg-transparent text-white text-end font-weight-600' :
                                `bg-transparent font-weight-600 robotoCondensedBold  ${showSize ? 'text-black' : textColor} `}
                            onClick={showSizeGuideTable}
                        >
                            Size Guide {' '}
                            <FontAwesomeIcon icon={faInfoCircle}/>
                        </div>
                    </span> : <></>}
                                {sizeChart && <div id="size-guide-table"
                                                   className={`${showSize ?
                                                       '' :
                                                       'd-none'} text-center position-absolute mt-5`}>
                                    <Table striped bordered hover
                                           className="bg-white"
                                           style={{width: '350px'}}
                                    >
                                        <thead className="bg-black text-white">
                                        <tr>
                                            {sizeChart.title.map((item) => (
                                                <th>{item}</th>
                                            ))}
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {Object.values(sizeChart.size).map((item) => (
                                            <tr>
                                                {item.map((size) => (
                                                    <td>{size}</td>
                                                ))}
                                            </tr>
                                        ))}
                                        </tbody>
                                    </Table>
                                </div>}
                            </span>
                            </span>
                            <div className={`${isMobile ? 'col-12' : 'col-8'}`}>
                                <select
                                    className="form-select w-165 fanzine-select"
                                    onChange={selectSize}>
                                    <option value="">Select Size</option>
                                    {sizes && sizes.map((value, index) => (

                                        <option value={value.node.title.split(
                                            ' / ')[0]}>
                                            {value.node.title.split(' / ')[0]}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </span>
                                            </>}
                                        </div>

                                        <div
                                            className={'col-sm-12 col-xs-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 mt-3 position-relative'}>
                                            <button className={`w-100 py-3 ${bgColor} `} style={{
                                                borderRadius: 6,
                                                borderColor: 'white',
                                            }}
                                                    disabled={addToCartLoading}
                                                    ref={miniCartTarget}
                                                    onClick={addToCart}
                                            >
                                                <span
                                                    className={`SFProDisplay-Regular ${fontColor}`}
                                                    style={{
                                                        fontSize: 20,
                                                        color: fontColor,
                                                    }}>
                                                    {addToCartLoading ?
                                                        <Spinner
                                                            animation="border"/>
                                                        :
                                                        'Add to Cart'}
                                                </span>
                                            </button>

                                            <Toast onClose={() => setShowToast(false)}
                                                   show={showToast} delay={3000} autohide>
                                                <Toast.Body>Added To Cart!</Toast.Body>
                                            </Toast>

                                        </div>

                                        <div
                                            className={'col-sm-12 col-xs-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12'}>
                                            <div className={'d-flex flex-column'}>
                                                <span
                                                    className={'SFProDisplay-Regular fw-bold mt-3'}
                                                    style={{
                                                        fontSize: 14,
                                                        color: '#000000',
                                                    }}>Description</span>
                                                <span className={'SFProDisplay-Regular font-15 mt-3'}
                                                      style={{color: '#707070'}}>
                                                    {productDetail && <div
                                                        style={{color: '#707070'}}
                                                        dangerouslySetInnerHTML={{__html: productDetail.descriptionHtml}}/>}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={'container mt-5 pb-5'}>
                                        <div className={`bebasFont font-40`}>
                                            You may also like
                                        </div>
                                        <div className={'row'}>
                                            {
                                                randomProducts.map((value) => {
                                                    return (
                                                        <div
                                                            className={'col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-6'}>
                                                            <ProductComponent item={value}
                                                                              showColors={true}
                                                                              showDetail={true}/>
                                                        </div>
                                                    );
                                                })
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    };

    return render();
};

export default withRouter(NewSingleProductPage);
