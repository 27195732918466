import React, {useEffect, useState} from 'react';
import Card from 'react-bootstrap/Card';
import './styles/NavbarCard.css';
import '../../assets/styles/styles.css';
import YoutubeEmbed from '../YoutubeEmbed';
import PropTypes from 'prop-types';
import HTMLEllipsis from 'react-lines-ellipsis/lib/html.modern';
import {getHostname} from '../../CustomFunctions/getHostname';
import {COLORS, FONT_COLOR} from '../../Configs/Targets';
import {useDispatch} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import moment from "moment";
import PlayIcon from "../PlayIcon";

const VideoCard = (props) => {

    const {isMobile, setSelectedVideo, isClub} = props;
    let dispatch = useDispatch();
    const navigate = useNavigate()
    let [isOpen, setIsOpen] = useState(false);
    let [stopMain, setStopMain] = useState(true);
    let [videoData, setVideoData] = useState(props.video);

    const hostName = window.location.hostname;
    const domainObject = getHostname(hostName);
    const target = domainObject.host;
    const textColor = props?.league?.font_color ? props?.league?.font_color : target === 'fanzine' ? 'text-black' : props?.showOnSlider ? FONT_COLOR[target] : 'text-black';
    const playIconColor = COLORS[target];

    const openModal = () => {
        setIsOpen(true);
        setStopMain(false);
    };

    const updateMainVideo = () => {
        let mainVideo = props.mainVideo;
        let setMainVideo = props.setMainVideo;
        let mainVideoRef = props.mainVideoRef;
        let newMainVideo = videoData;

        newMainVideo['url'] = newMainVideo['url'] + '?autoplay=1';

        setMainVideo(newMainVideo);
        setVideoData(mainVideo);
        mainVideoRef.current.scrollIntoView({behavior: 'smooth', block: 'start'});
        window.scrollBy(0, -100);
    };

    const addDefaultSrc = (ev) => {
        ev.target.src = require('../../assets/images/default_publisher_icon.png');
    };

    useEffect(() => {
        setVideoData(props.video);
    }, [props.video]);

    const render = () => {
        let extraClass = props.extraClass;
        let videoHeight = props.videoHeight;
        let isMain = typeof props.main !== 'undefined';
        // let isMobile = typeof props.isMobile !== 'undefined';
        let titleClass = isMain && !isMobile ? 'h3' : 'small';
        let bottomClass = isMain && !isMobile ? 'h5' : 'small';
        let publisherImageHeight = isMain && props.isMobile ? 30 : 15;
        let titleWidth = isMobile && isMain ? 'w-100' : 'w-100';

        let newsTime = moment.tz(videoData?.published_at.date, 'Europe/London').local().startOf('seconds').fromNow();
        newsTime = newsTime.replace('an', '1');
        newsTime = newsTime.replace('hours', 'h');
        newsTime = newsTime.replace('hour', 'h');
        newsTime = newsTime.replace('minutes', 'm');
        newsTime = newsTime.replace('minute', 'm');
        newsTime = newsTime.replace('days', 'd');
        newsTime = newsTime.replace('day', 'd');

        if (!videoData) {
            return (<></>);
        }

        const handleClick = () => {
            // console.log('videoData =', videoData);
            // dispatch(setSingleVideo(videoData));
            if (setSelectedVideo) {
                setSelectedVideo(videoData);
                window.scrollTo(0, 0);
            } else {
                navigate(isClub ? `/video/${videoData?.id}` : `/${props.sport || 'football'}/video/${videoData?.id}`);

                // navigate(`/${props.sport || 'football'}/video/${videoData?.id}`);
            }
        };

        return (
            <div
                onClick={handleClick}
                /*to={`/${props.sport || 'football'}/video/${videoData?.id}`}*/
                className={`text-decoration-none text-black cursor-pointer ${extraClass}`}>
                <Card
                    style={{
                        width: props.isFanzineAllFootball && (isMobile ? props?.showOnSlider ? '240px' : '240px' : '360px'),
                        borderRadius: (!props?.showOnSlider && isMobile) ? '15px' : '0px',
                        // height: videoHeight
                    }}
                    className={` ${(isMobile && !props?.showOnSlider) ? 'col-6 p-0 border-radius-15px' : 'col'} border-0 bg-transparent`}>
                    {
                        isMain ?
                            <YoutubeEmbed videoUrl={videoData.url}
                                          videoHeight={videoHeight} stopVideo={stopMain}
                                          autoplay={videoData.autoplay}/> :
                            <img

                                src={videoData.preview_image}
                                style={{
                                    borderRadius: (!props?.showOnSlider && isMobile) ? '15px' : '0px',
                                    objectFit: props.isFanzineAllFootball && 'cover', height: videoHeight
                                }}
                                alt={'Fanzine'}/>
                    }

                    {
                        !isMain &&
                        (<Card.ImgOverlay
                            className={`${(!props?.showOnSlider && isMobile) ? 'border-radius-15px' : ''} d-flex align-items-start flex-column justify-content-end ${props.isFanzineAllFootball ?
                                'video-overlay-slider-1' :
                                'news-overlay'} `} onClick={openModal}>
                            <div className={'video-overlay-slider'}>
                                <PlayIcon fill={playIconColor}/>
                                {/*<img src={require('../../assets/images/play_button.png')} alt={'play_button'} height={50} width={50} color={'red'}/>*/}
                            </div>
                            <Card.Text
                                className={`text-white ${props?.isFanzineAllFootball ?
                                    (isMobile ? 'robotoFont font-12' : 'robotoCondensedBold font-18') :
                                    'robotoCondensedBold'}`}>
                                {
                                    !props?.isFanzineAllFootball &&
                                    <> <img onError={addDefaultSrc}
                                            src={videoData.publisher?.icon} alt="News"
                                            height={20}/> &nbsp; </>
                                }
                                {props?.isFanzineAllFootball ?
                                    (!isMobile ?
                                        <HTMLEllipsis
                                            unsafeHTML={videoData?.title}
                                            maxLine="3"
                                            ellipsis="..."
                                            basedOn="letters"
                                            style={{
                                                height: '2em',
                                                color: props?.league?.font_color ? props?.league?.font_color : FONT_COLOR[target],
                                            }}
                                            className={'robotoFont font-12'}
                                        />
                                        : '') :
                                    videoData.publisher?.name}
                            </Card.Text>
                        </Card.ImgOverlay>)
                    }
                </Card>
                <div className={`${(isMobile && !props?.showOnSlider) ? 'col-12' : 'row'} p-0`}>
                    <div className={`justify-content-between ${isMain ?
                        'col-9' :
                        'col-12'}`}>
                        <div>
                            {props?.isFanzineAllFootball && isMobile &&
                                <HTMLEllipsis
                                    unsafeHTML={videoData?.title}
                                    maxLine="2"
                                    ellipsis="..."
                                    basedOn="letters"
                                    style={{
                                        // height: '3.5em',
                                        color: props?.league?.font_color ? props?.league?.font_color : FONT_COLOR[target],
                                        className: `roboto-medium ${textColor}`,
                                        // marginBottom: '1em',
                                        fontsize: (isMobile && !props.showOnSlider) ? '10px' : '13px',
                                    }}
                                    className={`${(isMobile && !props.showOnSlider) ? 'sfProDisplayFont' : 'robotoFont'} text-start text-wrap ${textColor}`}
                                />}
                        </div>
                        <div className={'d-flex align-items-center justify-content-between'}>
                            <div className={'d-flex align-items-center justify-content-between'}>
                                {isMobile && <img src={videoData?.publisher?.icon}
                                                  style={{
                                                      width: '20px',
                                                      objectFit: 'contain',
                                                  }}
                                                  alt={'publisher-icon'}
                                />
                                }
                                &nbsp;
                                &nbsp;
                                <div style={{
                                    height: !isMobile && '2em',
                                    color: props?.league?.font_color ? props?.league?.font_color : (isMobile && !props.showOnSlider) ? 'gray' : FONT_COLOR[target],
                                    className: `robotoFont ${textColor}`,
                                }}
                                     className={`${isMobile ? 'robotoFont font-10' : 'robotoFont font-14'} ${textColor}`}>
                                    {props?.isFanzineAllFootball ?
                                        videoData.publisher?.name :
                                        videoData?.title}
                                </div>
                                {/*<HTMLEllipsis
                                    unsafeHTML={props?.isFanzineAllFootball ?
                                        videoData.publisher?.name :
                                        videoData?.title}
                                    maxLine="1"
                                    ellipsis="..."
                                    basedOn="letters"
                                    style={{
                                        height: !isMobile && '2em',
                                        color: props?.league?.font_color ? props?.league?.font_color : (isMobile && !props.showOnSlider) ? 'gray' : FONT_COLOR[target],
                                        className: `robotoFont ${textColor}`,
                                    }}
                                    className={`${isMobile ? 'robotoFont font-10' : 'robotoFont font-14'} ${textColor}`}
                                />*/}
                            </div>
                            <div className={'d-flex align-items-center justify-content-end float-end'}>
                                {
                                    props.isFanzineAllFootball &&
                                    <span
                                        style={{
                                            color: props?.league?.font_color ? props?.league?.font_color : FONT_COLOR[target],
                                        }}
                                        className={`${isMobile ? 'robotoFont font-10' : 'robotoFont font-14'} ${textColor}`}>
                                    {
                                        newsTime
                                    }
                                </span>}
                            </div>
                        </div>
                        <span className={`text-muted robotoFont ${bottomClass}`}>
                            {
                                isMain ?
                                    (
                                        <>
                                            <div className={`row`}>
                                                <div
                                                    className={`col-9 d-flex justify-content-start`}>
                                                    <span
                                                        className={`d-flex align-items-center`}>
                                                        <img
                                                            onError={addDefaultSrc}
                                                            src={videoData.publisher.icon}
                                                            alt="News"
                                                            height={publisherImageHeight}/>
                                                    </span>
                                                    &nbsp; <span
                                                    className={`d-flex align-items-center robotoFont font-16 ${textColor}`}>{videoData.publisher.name}</span>
                                                </div>
                                            </div>
                                        </>
                                    ) :
                                    (
                                        <span className={`${isMobile ?
                                            'float-start' :
                                            'float-end'} text-muted font-16 font-weight-800 ${isMobile ? 'robotoFont' : 'robotoCondensedBold'} ${textColor}`}>

                                        </span>
                                    )
                            }

                        </span>
                    </div>

                    {
                        isMain && (
                            <div className={`col-3 d-flex justify-content-end p-2`}>
                                <span
                                    className={`d-flex align-items-start robotoCondensedBold font-16 font-weight-800 ${textColor}`}>
                                    {
                                        newsTime
                                    }
                                </span>
                            </div>
                        )
                    }
                </div>
            </div>
        );
    };

    return render();
};

VideoCard.propTypes = {
    videoHeight: PropTypes.string.isRequired,
    main: PropTypes.bool,
    extraClass: PropTypes.string.isRequired,
    isMobile: PropTypes.bool,
};

export default VideoCard;
