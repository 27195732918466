import blueCheck from "../../assets/images/blueCheck.png";

const BiggestAccounts = (props) => {
  const { teamName, teamHandle } = props;
  const renderMain = () => {
    return (
      <div className={"p-0 m-0 col-2 col-xs-2 col-sm-2 col-md-2 col-lg-2 col-xl-2 col-xxl-2"}>
        <div className={'d-flex align-items-center justify-content-start'}>
        <span
            style={{fontWeight:'bold'}}
            className={"font-24 Montserrat-Bold text-white"}>
          {teamName}
        </span>
          &nbsp;
          &nbsp;
          <img
              className={'mt-0 p-0'}
              src={blueCheck}
              alt={"blue check"}
              style={{
                width: "24px",
                height: "24px",
                objectFit: "contain",
              }}
          />
        </div>
        <div className={'d-flex align-items-center justify-content-start'}>
          <p className={"font-18 Montserrat-Regular text-nowrap grey-one"}>{teamHandle}</p>
        </div>
      </div>
    );
  };

  return renderMain();
};

export default BiggestAccounts;
