import React from "react";
import Card from "react-bootstrap/Card";
import '../../../Components/Cards/styles/NavbarCard.css';
import '../../../assets/styles/styles.css';
import PropTypes from "prop-types";
import {NavLink, useLocation} from 'react-router-dom';
import {getHostname} from '../../../CustomFunctions/getHostname';

class ShopCategoryCard extends React.Component {

  render() {

    const hostName = window.location.hostname;
    const domainObject = getHostname(hostName);
    const target = domainObject.host;

    let {name, src, link, mainCategory} = this.props;
    let imageStyle = {};
    if (mainCategory) {
      imageStyle = {
        height: "174.2px",
        objectFit: "cover",
      }
    }
    return (
        <div className={`${mainCategory && mainCategory ? "col" : "col-6"} rounded-custom mt-3`}>
          <NavLink to={target === 'fanzine' ? `/${window.location.pathname.split('/')[1]}/${link}` : `/shop/${link}`} className={'text-decoration-none text-dark'}>
            <Card className="border-0 bg-transparent m-1">
              <Card.Img
                  variant="top"
                  className={'rounded-custom img-fluid'}
                  src={src}
                  alt="Card image"
                  // style={imageStyle}
              />
              <Card.ImgOverlay className={`d-flex align-items-start flex-column justify-content-end ${mainCategory ? "shop-category-overlay" : "shop-category-overlay"} rounded-custom`}>
                <Card.Text className={`text-white sfProDisplayFont h1`}>
                                <span className={'bebasFont'}>
                                    {name}
                                </span>
                </Card.Text>
              </Card.ImgOverlay>
            </Card>
          </NavLink>
        </div>
    );
  }
}

ShopCategoryCard.propTypes = {
  name: PropTypes.string.isRequired, src: PropTypes.string.isRequired,
}

export default ShopCategoryCard;
