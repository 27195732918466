import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck} from "@fortawesome/free-solid-svg-icons";
import {faCircle} from "@fortawesome/free-solid-svg-icons/faCircle";
import {ColorIcon} from "../ColorIcon/ColorIcon";
import {useSelector} from "react-redux";

const FilterItemOptions = (props) => {
    const store = useSelector(state => state);

    let title = props.title;
    let filterTitle = props.filterTitle;
    let setSelectedFilters = props.setSelectedFilters;
    let selectedFilters = props.selectedFilters;
    let selectedAll = props.selected;
    let productColorHex = store.shopify.productColors;

    const [selected, setSelected] = useState(selectedFilters.indexOf(title) >= 0 || selectedAll);

    const setSelectedFunc = () => {
        if (selected) {
            setSelectedFilters(prevState => {
                let tempState = prevState;
                tempState.push(title);
                let uniqueTemp = [];
                tempState.forEach(function (item) {
                    if (uniqueTemp.indexOf(item) < 0) {
                        uniqueTemp.push(item);
                    }
                });
                return uniqueTemp;
            });
        } else {
            setSelectedFilters(prevState => {
                let tempState = prevState.filter(function (person) {
                    return person !== title
                });
                let uniqueTemp = [];
                tempState.forEach(function (item) {
                    if (uniqueTemp.indexOf(item) < 0) {
                        uniqueTemp.push(item);
                    }
                });
                return uniqueTemp;
            });
        }
    }

    useEffect(() => {
        setSelectedFunc();
    }, [selected]);

    useEffect(() => {
        setSelected(selectedFilters.indexOf(title) >= 0 || selectedAll);
    }, [props.selected]);

    const render = () => {
        return (
            <div onClick={() => {
                setSelected(!selected);
                // props.setSelectedChanged(true)
            }}>
                <div className={`sfProDisplayFont font-16 d-inline-flex justify-content-start align-items-center p-4`}
                     style={{height: "9%"}}>
                    {
                        props.isColors && <ColorIcon color={title} colorHex={productColorHex} filters={true}/>
                    }
                    {title}
                </div>
                {
                    selected &&
                    <div
                        className={`col-6 sfProDisplayFont d-inline-flex flex-column justify-content-center align-items-end float-end text-gray p-4`}>
                        <FontAwesomeIcon className={`ms-2`} icon={faCheck} size={`1x`}/>
                    </div>
                }
                <div className={`d-flex justify-content-end`}>
                    <div style={{
                        border: "solid 0.8px #dfdfdf",
                        width: "97%",
                    }}/>
                </div>
            </div>
        );
    }

    return render();

}

FilterItemOptions.propTypes = {
    title: PropTypes.string.isRequired,
    isColors: PropTypes.bool,
    color: PropTypes.string,
    selected: PropTypes.bool,
    setSelectedFilters: PropTypes.func.isRequired,
    selectedFilters: PropTypes.array.isRequired,
    setSelectedAll: PropTypes.func,
}

export default FilterItemOptions;