import React from "react";
import PropTypes from "prop-types";
import '../assets/styles/youtubeEmbed.css';
import '../assets/styles/styles.css';

class YoutubeEmbed extends React.Component {

    render() {
        let videoUrl = this.props.videoUrl;
        let videoHeight = this.props.videoHeight;
        return (
            <div className="video-responsive rounded-custom">
                <iframe
                    style={{width: '100%'}}
                    height={videoHeight}
                    src={videoUrl}
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    title="Embedded youtube"
                />
            </div>
        );
    }
}

YoutubeEmbed.propTypes = {
    videoUrl: PropTypes.string.isRequired,
    videoHeight: PropTypes.string.isRequired,
    autoplay: PropTypes.bool,
};

export default YoutubeEmbed;
