
import appsponsor1 from '../../assets/images/appsponsor1.png'
import appsponsor2 from '../../assets/images/appsponsor2.png'
import appsponsor3 from '../../assets/images/appsponsor3.png'

import Carousel from "./Carousel";
import {useEffect, useState} from 'react';

const AppSponsorship = (props) => {
  const { isMobile } = props;

  const [backgroundSize,setBackGroundSize] = useState('1000px');

  useEffect(() => {
    if (window.screen.width >= 2550) {
      setBackGroundSize('1050px')
    } else if (window.screen.width >= 1360 && window.screen.width < 1920) {
      setBackGroundSize('1000px')
    }
  }, [window.screen.width]);

  const renderDesktopView = () => {
    return (
      <div
          style={{
            height:backgroundSize,
          }}
          className={"container-fluid blackBackgroundTwo flex-grow-1 "}>
        <div className={"container "}>
          <div className={"row pb-5"}>
            <div className={"col-md-12 col-lg-12 col-xl-12 col-xxl-12 mt-5"}>
              <div className={"row justify-content-center"}>
                <span
                    style={{fontWeight:'bold'}}
                    className={"font-fanzine font-50 text-center Montserrat-Bold"}>APP &nbsp;
                  <span
                      style={{fontWeight:'bold'}}
                      className={"text-white font-50 text-center Montserrat-Bold"}>SPONSORSHIP</span>
                </span>
              </div>
              {/*<div className={"row justify-content-center"}>*/}
              {/*  <span className={"font-fanzine font-26 text-center"}>*/}
              {/*    Sponsor our sport apps with your brand*/}
              {/*  </span>*/}
              {/*</div>*/}
            </div>
          </div>
          <div className={"row"}>
            <div className={"col-md-4 col-lg-4 col-xl-4 col-xxl-4"}>
                <span
                  className={"font-34 d-flex justify-content-center pb-4  text-center Montserrat-Regular text-white"}
                >
                  Notifications
                </span>
                <img
                    src={require('../../assets/images/notificat.png')}
                  className={"w-100 mt-n5"}

                  alt={"notifications"}
                    style={{ objectFit: "contain", height: "770px",width:'412px' }}
                />
            </div>
            <div className={"col-md-4 col-lg-4 col-xl-4 col-xxl-4"}>
                <span
                  className={"font-34 d-flex justify-content-center pb-5 text-center Montserrat-Regular text-white"}
                >
                  Homepage Display
                </span>
                <div
                  className={"row justify-content-center align-items-center "}
                >
                  <img
                    className={"w-100 mt-4"}
                    src={require('../../assets/images/HomePageDisplay.png')}
                    alt={"popularPages"}
                    style={{ objectFit: "contain", height: "640px",width:'412px' }}
                  />
              </div>
            </div>
            <div className={"col-md-4 col-lg-4 col-xl-4 col-xxl-4"}>
                <span
                  className={"font-34 d-flex justify-content-center pb-5  text-center Montserrat-Regular text-white"}
                >
                  Popular Pages
                </span>
                  <img
                    className={"w-100 mt-4"}
                    src={require('../../assets/images/popularPage.png')}
                    alt={"popularPages"}
                    style={{ objectFit: "contain", height: "640px",width:'412px' }}
                  />
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderMobileView = () => {
    return (
      <div style={{
        height:'750px',
      }} className={"blackBackground container"}>
        <div className={"row"}>
          <span
            className={"font-25 Montserrat-Bold text-white font-weight-800 text-center mt-5"}
          >
            APP SPONSORSHIP
          </span>
          <span className={"font-fanzine font-16 Montserrat-Regular text-center"}>
            Sponsor our sport apps with your brand
          </span>
        </div>
        <Carousel
            isAppSponsorShip={true}
            isAdvertisement={true}
            imageOneBig={true}
          isMobile={isMobile}
            // imageOneText={'Notification'}
            // imageTwoText={'Notification'}
            // imageThreeText={'Notification'}
          imageOne={appsponsor1}
          imageTwo={appsponsor2}
            imageThree={appsponsor3}
        />
      </div>
    );
  };

  const renderMain = () => {
    return isMobile ? renderMobileView() : renderDesktopView();
  };

  return renderMain();
};

export default AppSponsorship;
