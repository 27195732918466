import {
  CRICKET,
  FBS,
  FOOTBALL,
  MLB,
  NBA,
  NCAA,
  NFL,
} from '../../../Axios/EndPoints';
import {get} from '../../../Axios/get';
import {useEffect, useState} from 'react';
import {useLocation, useSearchParams} from 'react-router-dom';
import {FONT_COLOR} from '../../../Configs/Targets';
import VideoCardSlider from './videoCardSlider';
import {getHostname} from '../../../CustomFunctions/getHostname';
import VideoCardMoreStories from './VideoCardMoreStories';

const MoreStories = (props) =>
{
  const {isMobile,data,leagueId,selectedVideo,setSelectedVideo} = props;
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams()
  const [latestVideos, setLatestVideos] = useState([])
  const [latestVideosLoading, setLatestVideosLoading] = useState(true)

  const hostName = window.location.hostname;
  const domainObject = getHostname(hostName);
  const target = domainObject.host;
  const fetchLatestVideos = (endpoint = null) => {

    // let league_id = (leagueId || searchParams.get('team_id') || searchParams.get('league_id') || data?.clubId || '');
    // let parameters = leagueId ? '&league_id='+ leagueId : searchParams.get('league_id') ?
    //     '&league_id=' + league_id : searchParams.get('team_id') || data?.clubId ?
    //         '&team_id=' + league_id :
    //         '&main_site=1';
    // console.log('endpoint =',endpoint)
    // if(endpoint?.includes('undefined'))
    // {
    //   endpoint = endpoint.replace('?team_id=undefined','')
    // }
    //
    // let url = endpoint ? endpoint : FOOTBALL.LatestFanzineVideosEndPoint + parameters


    let id = (leagueId || searchParams.get('team_id') || searchParams.get('league_id') ||
        data?.clubId ||
        data?.id || '');

    let parameters = leagueId ? '?league_id='+id : searchParams.get('league_id') ?
        '?league_id=' + id :
        '?team_id=' + id;

    let URL = '';

    switch (location.pathname.split('/')[1]) {
      case 'football':
        URL += FOOTBALL.LatestFanzineVideosEndPoint;
        break;
      case 'cricket':
        URL += CRICKET.VideoEndPoint;
        break;
      case 'nfl':
        URL += NFL.VideoEndPoint;
        break;
      case 'nba':
        URL += NBA.VideoEndPoint;
        break;
      case 'ncaam':
        URL += NCAA.VideoEndPoint;
        break;
      case 'ncaaf':
        URL += FBS.VideoEndPoint;
        break;
      case 'mlb':
        URL += MLB.VideoEndPoint;
        break;
      default:
        URL += FOOTBALL.LatestFanzineVideosEndPoint;
        break;

    }

    if (id !== '') {
      URL += parameters;
    }

    get(URL).then((response) => {
      // eslint-disable-next-line array-callback-return
      let tempData = response.data;
      tempData.sort(function (a, b) {
        let keyA = new Date(a.published_at.date),
            keyB = new Date(b.published_at.date);
        // Compare the 2 dates
        if (keyA < keyB) return 1;
        if (keyA > keyB) return -1;
        return 0;
      });
      // setSelectedVideo(tempData[0]);
      setLatestVideos(tempData);
      setLatestVideosLoading(false);
    }).catch((err) => {
      console.error(err);
    });
  };


  useEffect(() => {
    setLatestVideosLoading(true);
    fetchLatestVideos(props.videosEndpoint);

  }, [searchParams.get('league_id'), searchParams.get('team_id'), props.videosEndpoint])


  const renderMain = () =>
  {
    return(
        <div className={'bg-background container'}>
          <div
              className={`p-2 m-1 d-flex row`}>
          {
            latestVideos?.map((value,index)=>{

              return(
                  <VideoCardMoreStories
                      setSelectedVideo={setSelectedVideo}
                      selectedVideo={selectedVideo}
                      video={value} isFanzineAllFootball={true}
                                   videoHeight={isMobile ? '100px' : '180px'} isMain={false}
                                   extraClass={`${value === 'fanzine' ? 'text-black' : FONT_COLOR[target]} m-2`}
                                   league={'Latest Videos'}
                                   sport={props.sport || 'football'}
                                   isMobile={isMobile}
                                   target={target}
                  />
              )

            })
          }
          </div>
        </div>
    )
  }

  return renderMain();


}

export default MoreStories;
