import {Card, Image} from 'react-bootstrap';
import {COLORS, FONT_COLOR, TEXT_COLOR} from '../../../Configs/Targets';
import {getHostname} from '../../../CustomFunctions/getHostname';
import LinesEllipsis from 'react-lines-ellipsis';
import NewsSectionHeading from '../../NewsSectionHeading';
import {NavLink, useLocation} from 'react-router-dom';

const NewNewsFanzineCard = (props) => {

    const {key, news, isMobile, showNewsSide, showTitle, title} = props;
    const hostName = window.location.hostname;
    const domainObject = getHostname(hostName);
    let target = domainObject.host;

    const location = useLocation();
    const addDefaultSrc = (ev) => {
        ev.target.src = require(
            '../../../assets/images/football-shots-you-need-to-know.jpg');
    };

    const addDefaultPublisherIcon = (ev) => {
        ev.target.src = require('../../../assets/images/default_publisher_icon.png');
    };


    const renderNewsBelowImage = () => {
        return (
            <Card className={'border-radius-none border-0 shadow-nav mb-2'}>
                {showTitle && !(location.pathname.includes('/news') && isMobile) &&
                    <NewsSectionHeading title={title} bgColor={COLORS[target]}
                                        fontColor={FONT_COLOR[target]}/>
                }
                <Card.Img variant="top"
                          onError={addDefaultSrc}
                          className={'border-radius-none'}
                          src={news?.news_image}/>
                <Card.Body>
                    <Card.Title
                        className={'sfProDisplayFontBold font-22'}>
                        <LinesEllipsis
                            text={news?.news_title}
                            maxLine='3'
                            ellipsis='...'
                            trimRight
                            basedOn='letters'
                        />
                        {/*{news?.news_title}*/}
                    </Card.Title>
                    <Card.Text className={'text-black'}>
                        <div className={'d-flex align-items-center'}>
                            <div
                                className={'w-50 d-flex justify-content-start align-items-center'}>
                                <div className={'w-auto'}>
                                    <img
                                        style={{
                                            width: '25px',
                                            height: 'auto',
                                            objectFit: 'contain',
                                        }}
                                        src={news?.news_publisher?.publisher_icon}
                                        onError={addDefaultPublisherIcon}
                                        alt={'publisher-image'}/>
                                </div>
                                <div className={'w-auto ms-2'}>
                                    <div
                                        className={`sfProDisplayFont font-12 d-flex justify-content-start ${TEXT_COLOR[target]}`}>
                                        <LinesEllipsis
                                            text={news?.news_publisher?.publisher_name}
                                            maxLine='2'
                                            ellipsis='...'
                                            trimRight
                                            basedOn='letters'
                                        />
                                        {/*{news?.news_publisher?.publisher_name}*/}
                                    </div>
                                </div>
                            </div>
                            <div className={'w-50'}>
                                <div
                                    className={`sfProDisplayFont font-12 d-flex justify-content-end ${TEXT_COLOR[target]}`}>
                                    {news?.news_time}
                                </div>
                            </div>
                        </div>
                    </Card.Text>
                </Card.Body>
            </Card>
        );
    };

    const renderNewsOnSide = () => {
        return (
            <Card className={'border-radius-none border-0 shadow-nav mb-2'}>
                <Card.Body>
                    <Card.Text className={'text-black'}>
                        <div className={'d-flex align-items-center'}>
                            <div
                                style={{
                                    height: 104,
                                    width: 134,
                                }}
                            >
                                <Image
                                    style={{
                                        height: 104,
                                        width: 134,
                                        objectFit: 'cover'
                                    }}
                                    onError={addDefaultSrc}
                                    src={news?.news_image} alt={'new-image'}></Image>
                            </div>
                            <div className={'w-70 ps-2'}
                                 style={{height: '104px'}}
                            >
                                <div className={'d-flex'}>
                                    <div
                                        className={'w-50 d-flex justify-content-center align-items-center'}>
                                        <div className={'w-100 d-flex'}>
                                            <img
                                                style={{
                                                    width: '25px',
                                                    height: 'auto',
                                                    objectFit: 'contain',
                                                }}
                                                onError={addDefaultPublisherIcon}
                                                src={news?.news_publisher?.publisher_icon}
                                                alt={'publisher-image'}/>
                                            <div
                                                className={`sfProDisplayFont font-12 d-flex text-nowrap justify-content-start ${TEXT_COLOR[target]}`}>
                                                &nbsp;
                                                &nbsp;
                                                {news?.news_publisher?.publisher_name}
                                            </div>
                                        </div>
                                    </div>

                                </div>

                                <div className={'sfProDisplayFont font-16'}>
                                    {/*{news?.news_title}*/}
                                    <LinesEllipsis
                                        text={news?.news_title}
                                        maxLine='3'
                                        ellipsis='...'
                                        trimRight
                                        basedOn='letters'
                                    />
                                </div>
                                <div className={'w-50 d-flex align-items-end justify-content-start'}>
                                    <div
                                        className={`sfProDisplayFont font-12 ${TEXT_COLOR[target]}`}>
                                        {news?.news_time}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Card.Text>
                </Card.Body>
            </Card>
        );
    };

    const renderCard = () => {
        return (
            <div>
                {showNewsSide ? renderNewsOnSide() : renderNewsBelowImage()}
            </div>
        );
    }

    const renderSnack = () => {

        const sport = location.pathname.split('/')[1];

        let link = '';
        if (typeof news?.main_team !== 'undefined' && news?.main_team) {
            link = `/${sport}/${news?.main_team}/${news?.slug}`;
        } else {
            link = `/${sport}/${news?.slug}`;
        }
        return (
            <NavLink to={link} className={'text-decoration-none text-dark'}>
                {renderCard()}
            </NavLink>
        );
    };

    const renderNewsNow = () => {
        const sport = location.pathname.split('/')[1] || 'football';

        let link = '';
        if (target === 'fanzine') {
            if (typeof news?.main_team !== 'undefined' && news?.main_team) {
                link = `/${sport}/${news?.main_team}/${news?.slug}`;
            } else {
                link = `/${sport}/news/${news?.slug}`;
            }
        } else {
            link = `/news/${news?.slug}`;
        }
        return (<a
            href={link}
            className={'text-decoration-none text-dark'}
            target="_blank"
            rel="noreferrer"
        >
            {renderCard()}
        </a>);
    };

    return (
        <>
            {news && news.is_snack && target === 'fanzine' ? renderSnack() : renderNewsNow()}
        </>
    );

};

export default NewNewsFanzineCard;
