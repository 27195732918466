import {Container, Nav, Navbar} from 'react-bootstrap';
import React, {useEffect, useRef, useState} from 'react';
import NewMiniCart from '../NewMiniCart/NewMiniCart';
import {NavLink, useLocation} from 'react-router-dom';
import {COLORS, COLORS_RGBA, NAV_CLASS, TEXT_COLOR, TEXT_COLORS,} from '../../Configs/Targets';
import {getHostname} from '../../CustomFunctions/getHostname';
import {useDispatch, useSelector} from 'react-redux';
import {graphql} from '../../Axios/graphql';
import {ShopifyGraphQlQueries} from '../../Configs/ShopifyGraphQlQueries';
import {SCREEN_SIZE, SHOPIFY} from '../../Configs/Constants';
import {setCartItems, setGrandTotal} from '../../redux/actions/CartActions';
import {CartSVG} from '../../Constants/JS/CartSVG';
import {useWindowSize} from '../../CustomFunctions/useWindowSize';
import {shopPageImages} from '../Carousel/images';

const NewNavbar = (props) => {
    const dispatch = useDispatch();
    const [showSearchDropdown, setShowSearchDropdown] = useState(false);
    const newTarget = useRef(null);
    const showNavbar = false;
    const hostName = window.location.hostname;
    const domainObject = getHostname(hostName);
    const target = domainObject.host;
    const navColorDark = COLORS[target];
    const hoverColor = NAV_CLASS[target] + '-hover-color';
    const textColor = TEXT_COLOR[target];
    const [showMiniCart, setShowMiniCart] = useState(false);

    const location = useLocation();

    const cart = useSelector((state) => state);
    let windowSize = useWindowSize();

    let [minTotalForFreeDelivery, setMinTotalForFreeDelivery] = useState(null);
    let [cartQuantity, setCartQuantity] = useState(null);
    let [freeShippingBanner, setFreeShippingBanner] = useState(true);
    const [currencyCodeLocal, setCurrencyCodeLocal] = useState('GBP');
    const [currencySymbolLocal, setCurrencySymbolLocal] = useState('£');

    const cartStore = useSelector(state => state.cart);
    const [cartData, setCartData] = useState(null);

    const host = window.location.hostname;
    let bgColor = NAV_CLASS[target],
        color = COLORS[target],
        colorRgba = COLORS_RGBA[target],
        topBannerStyle = {
            width: '100%',
            height: '144px',
            background: `linear-gradient(to bottom, ${colorRgba}0.71) 45%, ${colorRgba}1)), url(${require(
                '../../assets/images/shop_top_banner.png')})`,
            backgroundSize: 'cover',
        }, currentItem = shopPageImages[1];

    let calculatedMinimumPrice = 0;
    let isMobile = false;
    if (windowSize.width <= SCREEN_SIZE.MOBILE) {
        isMobile = true;
    }

    const setMinAmountForFreeDelivery = () => {
        if (typeof cart.timezone.timezone !== 'undefined' &&
            cart.timezone.timezone.length !== 0) {
            let priceBasedShippingRates = cart.timezone.timezone.price_based_shipping_rates;
            let minPrice = priceBasedShippingRates.filter(
                (value) => value.name === 'Free Delivery');
            setMinTotalForFreeDelivery(
                parseFloat(minPrice[0].min_order_subtotal * 100) / 100);
        } else {
            setMinTotalForFreeDelivery(70);
        }
    };

    const minPriceForFreeShipping = (grandTotal) => {
        let value = minTotalForFreeDelivery - grandTotal;
        let currency = cart?.misc?.currentCurrency;
        let allCurrencies = cart?.misc?.currencies;
        let currentCurrency = allCurrencies ? allCurrencies[currency?.code?.toLowerCase()] : '1';
        return (((value * 100) / 100) * (currentCurrency || 1)).toFixed(2) || value;
    };

    const calculateCartItems = () => {
        let cartItemQuantity = 0;
        cart.cart.cartItems.length && cart.cart.cartItems.map((item) => {
            cartItemQuantity += item.quantity ? item.quantity : 0;
        });
        return cartItemQuantity;
    };

    useEffect(() => {
        setMinAmountForFreeDelivery();
        calculateCartItems();
        if (hostName.split('/').pop() === 'checkout') {
            setShowMiniCart(false);
        }
        if (hostName.split('/').pop() === 'shop') {
            setFreeShippingBanner(false);
        }
    }, [cart.cart.cartItems]);

    useEffect(() => {
        setMinAmountForFreeDelivery();
        if (hostName.split('/').pop() === 'checkout') {
            setShowMiniCart(false);
        }
        if (hostName.split('/').pop() === 'shop') {
            setFreeShippingBanner(false);
        }
    }, []);

    useEffect(() => {
        setMinAmountForFreeDelivery();
        if (hostName.split('/').pop() === 'checkout') {
            setShowMiniCart(false);
        }
        if (hostName.split('/').pop() === 'shop') {
            setFreeShippingBanner(false);
        }
        setCurrencyCodeLocal(cart?.misc?.currentCurrency?.code || 'GBP');
        setCurrencySymbolLocal(cart?.misc?.currentCurrency?.symbol || '£');
    }, [cart.timezone.timezone, cart?.misc?.currentCurrency]);

    const getShopifyCart = () => {
        let checkoutId = localStorage.getItem('checkoutId');
        let data = {checkout_id: checkoutId};
        graphql(ShopifyGraphQlQueries(SHOPIFY.GET_SHOPIFY_CART, null, null, data))
            .then((response) => {
                let tempCartItems = [];
                if (typeof response.data.data !== 'undefined') {
                    if (response.data.data.node === null) {
                        localStorage.removeItem('checkoutId');
                        localStorage.removeItem('checkoutUrl');
                        getShopifyCart();
                    }
                    response.data.data.node.lineItems.edges.map((item) => {
                        let tempVariant = {
                            id: item.node.variant.id,
                            title: item.node.variant.title,
                            price: item.node.variant.price,
                            image: item.node.variant.image,
                        };
                        let node = {
                            id: item.node.variant.product.id,
                            product_name: item.node.title,
                            variant: tempVariant,
                            size: item.node.variant.selectedOptions[0].value,
                            color: item.node.variant.selectedOptions[1] ?
                                item.node.variant.selectedOptions[1].value :
                                '',
                            lineItemId: item.node.id,
                            quantity: item.node.quantity,
                        };
                        tempCartItems.push(node);

                    });
                    if (response.data.data.node.discountApplications.edges.length ===
                        0) {
                    } else {
                        let tempSubtotal = 0;
                        response.data.data.node.lineItems.edges.map((item) => {
                            tempSubtotal += parseFloat(item.node.variant.price);
                        });
                    }
                    dispatch(setCartItems(tempCartItems));
                    let total = Math.round(
                        response.data.data.node.subtotalPrice * 100) / 100;
                    dispatch(setGrandTotal(total));
                    if (response.data.data.node.discountApplications.edges.length ===
                        0) {
                    }
                }
            })
            .catch((err) => {
                console.error(err);
            });
    };

    useEffect(() => {
        setCartData(cartStore);
        cartStore.cartItems.length === 0 && cartData === null && getShopifyCart();
    }, [cartStore]);

    const handleMiniCart = () => {
        setShowMiniCart(!showMiniCart);
    };

    const handleMouseHover = (e) => {

    };

    const renderNewNavbar = () => {
        const isFanzine = target === 'fanzine';

        return (
            <Navbar collapseOnSelect expand="lg" bg="light" variant="light"
                    className={'bg-white fixed-top position-relative shadow pb-0'}
                    style={{zIndex: 10, marginTop: '5%', height: 75}}>
                <Container>
                    <Navbar.Toggle
                        aria-controls="responsive-navbar-nav border border-2"/>
                    <Navbar.Collapse id="responsive-navbar-nav ">
                        <Nav className={'d-flex justify-content-between flex-grow-1'}>
                            <Nav>
                                <NavLink
                                    to={isFanzine ? '/' + location.pathname.split('/')[1] + '/shop/Mens' : '/shop/Mens'}
                                    id={'menNavbar'}
                                    className={`text-decoration-none ${hoverColor} text-gray p-2 me-3 ${location?.pathname?.includes(
                                        'Mens') ?
                                        'font-weight-800' :
                                        ''} `}>Men</NavLink>
                                <NavLink
                                    to={isFanzine ? '/' + location.pathname.split('/')[1] + '/shop/Womens' : '/shop/Womens'}
                                    id={'womenNavbar'}
                                    className={`text-decoration-none ${hoverColor} text-gray p-2 me-3 ${location?.pathname?.includes(
                                        'Womens') ?
                                        'font-weight-800' :
                                        ''} `}>Women</NavLink>
                                <NavLink
                                    to={isFanzine ? '/' + location.pathname.split('/')[1] + '/shop/Childrens' : '/shop/Childrens'}
                                    id={'kidNavbar'}
                                    className={`text-decoration-none ${hoverColor} text-gray p-2 me-3 ${location?.pathname?.includes(
                                        'Childrens') ?
                                        'font-weight-800' :
                                        ''} `}>Kids</NavLink>
                                <NavLink
                                    to={isFanzine ? '/' + location.pathname.split('/')[1] + '/shop/Accessories' : '/shop/Accessories'}
                                    id={'accNavbar'}
                                    className={`text-decoration-none ${hoverColor} text-gray p-2 me-3 ${location?.pathname?.includes(
                                        'Accessories') ?
                                        'font-weight-800' :
                                        ''} `}>Accessories</NavLink>
                            </Nav>

                            {(target !== 'coys' && target !== 'fanzine') &&
                                <div id="responsive-navbar-nav ">
                                    {minPriceForFreeShipping(cart.cart.grandTotal) > 0 ?
                                        <div className={`h-100 text-dark pt-2 h6`}>
                                            ONLY {currencySymbolLocal} {minPriceForFreeShipping(
                                            cart.cart.grandTotal)} AWAY FROM <strong>FREE
                                            SHIPPING</strong>
                                        </div>
                                        :
                                        <div className={`h-100 text-dark pt-2 h6`}>
                                            CONGRATULATIONS! YOU NOW HAVE <strong>FREE SHIPPING</strong>.
                                        </div>
                                    }
                                </div>
                            }

                            <Nav className={'position-relative'}>
                                {/*<InputGroup>
                                    <div className={"position-absolute"} style={{left: 150, top: 5}}>
                                        <Icon.Search/>
                                    </div>

                                    <Input
                                        ref={newTarget}
                                        onClick={() => setShowSearchDropdown(!showSearchDropdown)}
                                        placeholder={"Search"}
                                    />
                                </InputGroup>
                                <Overlay target={newTarget.current} show={showSearchDropdown} placement="bottom">
                                    {(props) => (
                                        <Tooltip id="overlay-example" {...props} >
                                            {renderSearchDropDown()}
                                        </Tooltip>
                                    )}
                                </Overlay>*/}
                            </Nav>
                            {target !== 'coys' &&
                                <Nav>
                                    <div className={'cursor-pointer me-2'}
                                         onClick={() => setShowMiniCart(!showMiniCart)}
                                    >
                                        <CartSVG
                                            height={31}
                                            width={29}
                                            fill={['theyellows', 'wearewolves'].includes(target) ?
                                                TEXT_COLORS[target] :
                                                navColorDark}
                                        />
                                    </div>
                                    <NavLink eventKey={2}
                                             className={`text-decoration-none d-flex align-items-center`}
                                             style={{color: TEXT_COLORS[target]}}
                                             to={target === 'fanzine' ? `/${location.pathname.split('/')[1]}/${location.pathname.split('/')[2]}/checkout` : '/checkout'}>
                                        Bag({cartData?.cartItems?.length || 0})
                                    </NavLink>
                                </Nav>
                            }              </Nav>
                    </Navbar.Collapse>
                </Container>
                {
                    (showMiniCart) && cartData?.cartItems?.length > 0 &&
                    !location.pathname.includes('checkout') &&
                    <NewMiniCart handleMiniCart={handleMiniCart}
                                 showMiniCart={props?.showMiniCart || showMiniCart}
                                 setShowMiniCart={props?.setShowMiniCart ||
                                     setShowMiniCart}/>
                }
                {/*{*/}
                {/*	showNavbar &&*/}
                {/*	<div style={{position: "absolute", top:81, left: 1420}}>*/}
                {/*		<NewMiniCart />*/}
                {/*	</div>*/}
                {/*}*/}
            </Navbar>
        );
    };

    const renderMain = () => {
        return renderNewNavbar();
    };

    return renderMain();
};

export default NewNavbar;
