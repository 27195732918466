import banner from "../../assets/images/banner.png";
import interstitial from "../../assets/images/Interstitial.png";
import native from "../../assets/images/native.png";
import video from "../../assets/images/video.png";
import Carousel from "./Carousel";
import {useEffect, useState} from 'react';
const AppAdvertising = (props) => {
  const { isMobile } = props;

  const [backgroundSize,setBackGroundSize] = useState('950px');

  useEffect(() => {
    if (window.screen.width >= 2550) {
      setBackGroundSize('1000px')
    } else if (window.screen.width >= 1360 && window.screen.width < 1920) {
      setBackGroundSize('850px')
    }
  }, [window.screen.width]);

  const renderDesktopView = () => {
    return (
      <div
          style={{
            height:backgroundSize,
          }}
          className={"container-fluid blackBackgroundTwo flex-grow-1 "}>
        <div className={"container "}>
          <div className={"row "}>
            <div className={"col-md-12 col-lg-12 col-xl-12 col-xxl-12 mt-5"}>
              <div className={"row justify-content-center pb-5 mb-3"}>
                <span
                    style={{fontWeight:'bold'}}
                    className={"font-50 Montserrat-Bold text-center font-fanzine"}>APP &nbsp;
                                <span
                                    style={{fontWeight:'bold'}}
                                    className={"text-white font-50 Montserrat-Bold text-center"}>ADVERTISING</span>
                </span>
              </div>

              {/*<div className={"row justify-content-center pb-5"}>*/}
              {/*  <span className={"font-fanzine font-26 text-center"}>*/}
              {/*    Place your adverts in our sport apps*/}
              {/*  </span>*/}
              {/*</div>*/}
            </div>
          </div>
          <div className={"row"}>
            <div className={"col-md-3 col-lg-3 col-xl-3 col-xxl-3"}>
              <div className={"row"}>
                <span
                  className={"font-36 pb-4  text-center Montserrat-Regular text-white"}
                >
                  Banner
                </span>
                <img src={banner} alt={"banner"} />
              </div>
            </div>
            <div className={"col-md-3 col-lg-3 col-xl-3 col-xxl-3"}>
              <div className={"row"}>
                <span
                  className={"font-36 pb-4  text-center Montserrat-Regular text-white"}
                >
                  Interstitial
                </span>
                <img src={interstitial} alt={"interstitial"} />
              </div>
            </div>
            <div className={"col-md-3 col-lg-3 col-xl-3 col-xxl-3"}>
              <div className={"row"}>
                <span
                  className={"font-36 pb-4  text-center Montserrat-Regular text-white"}
                >
                  Native
                </span>
                <img src={native} alt={"native"} />
              </div>
            </div>
            <div className={"col-md-3 col-lg-3 col-xl-3 col-xxl-3"}>
              <div className={"row"}>
                <span
                  className={"font-36 pb-4  text-center Montserrat-Regular text-white"}
                >
                  Video
                </span>
                <img src={video} alt={"video"} />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderMobileView = () => {
    return (
      <div style={{height:'800px'}} className={"blackBackground container"}>
        <div className={"row pb-4 "}>
          <span
            className={"font-25 text-white Montserrat-Bold font-weight-800 text-center mt-5"}
          >
            APP ADVERTISING
          </span>
          <span className={"font-fanzine Montserrat-Regular font-16 text-center"}>
            Place your adverts in our sport apps
          </span>
        </div>
          <Carousel
              isAppSponsorShip={true}
              isAdvertisement={true}
              isMobile={isMobile}
              // imageOneText={'Banner'}
              // imageTwoText={'Banner'}
              // imangeThreeText={'Banner'}
              imageOne={banner}
              imageTwo={interstitial}
              imageThree={native}
              imageFour={video}
          />
      </div>
    );
  };

  const renderMain = () => {
    return isMobile ? renderMobileView() : renderDesktopView();
  };

  return renderMain();
};

export default AppAdvertising;
