const images = [
    {
        id: 1,
        src:
            "https://images.unsplash.com/photo-1627745193246-1fa1c9404b21?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=750&q=80",
        alt: "The world",
        news: 'Who is Charlie Patino? Arsenal’s ‘lovely kid’ who keeps making headlines after £10k move'
    },
    {
        id: 2,
        src:
            "https://images.unsplash.com/photo-1631116617822-e100bd7e6e06?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=750&q=80",
        alt: "Train",
        news: 'Who is Charlie Patino? Arsenal’s ‘lovely kid’ who keeps making headlines after £10k move'
    },
    {
        id: 3,
        src:
            "https://images.unsplash.com/photo-1496181133206-80ce9b88a853?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=751&q=80",
        alt: "Laptop",
        news: 'Who is Charlie Patino? Arsenal’s ‘lovely kid’ who keeps making headlines after £10k move'
    }
];
export {images};

const newsPageImages = [
    {
        id: 1,
        src:
            "https://images.unsplash.com/photo-1627745193246-1fa1c9404b21?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=750&q=80",
        alt: "The world",
        news: 'Who is Charlie Patino? Arsenal’s ‘lovely kid’ who keeps making headlines after £10k move'
    }
];
export {newsPageImages};

const shopImages = [
    {
        id: 1,
        src:
            require('../../assets/images/storenew.png'),
        alt: "The world"
    },
    {
        id: 2,
        src:
            require('../../assets/images/storenew.png'),
        alt: "Train"
    },
    {
        id: 3,
        src:
            require('../../assets/images/storenew.png'),
        alt: "Laptop"
    },
];
export {shopImages};

const shopPageImages = [
    {
        id: 1,
        src:
            require('../../assets/images/shop_t_shirt.png'),
        alt: "The world",
        bestSeller: true,
        colors: [
            "Red",
            "White",
            "Grey",
            "yellow",
            "Black",
            "blue",
            "Charcoal"
        ],
        price: "£20.99",
    },
    {
        id: 2,
        src:
            require('../../assets/images/shop_caps.png'),
        alt: "Premium Gunners Hoodie",
        name: "Premium Gunners Hoodie",
        onSale: true,
        price: "£42.99",
        oldPrice: "£42.99",
    },
    {
        id: 3,
        src:
            require('../../assets/images/shop_t_shirt.png'),
        alt: "Laptop",
        onSale: true
    },
    {
        id: 4,
        src:
            require('../../assets/images/shop_caps.png'),
        alt: "Laptop"
    },
    {
        id: 5,
        src:
            require('../../assets/images/shop_t_shirt.png'),
        alt: "Laptop"
    },
    {
        id: 6,
        src:
            require('../../assets/images/shop_caps.png'),
        alt: "Laptop"
    },
];
export {shopPageImages};