import './customFanzineSports.css';
import {useDispatch, useSelector} from 'react-redux';
import {useEffect, useState} from 'react';
import {setIsLogin} from '../../../redux/actions/FanzineSportsActions';
import {useNavigate} from 'react-router-dom';
import {FANZINE_SPORTS} from '../../../Axios/EndPoints';
import {post} from '../../../Axios/post';
import {Form, InputGroup, Spinner} from 'react-bootstrap';
import {SCREEN_SIZE} from '../../../Configs/Constants';
import MobileBackground from '../../../assets/images/LoginBG.png';
import DesktopBackground from '../../../assets/images/LoginBGDesktop.png';
import CountryCodes from '../../../Constants/JSON/countryPhoneCodes.json';

const Login = () => {
  const LoginStore = useSelector(state => state);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [mobile, setMobile] = useState('');
  const [mobileCode, setMobileCode] = useState('+234');
  const [loading, setLoading] = useState(false);
  let [isMobile, setIsMobile] = useState(false);
  let [loginError, setLoginError] = useState('');

  useEffect(() => {
    if (window.innerWidth <= SCREEN_SIZE.MOBILE) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, [window.innerWidth, isMobile]);

  const handleLogin = () => {
    setLoading(true);
    let data = new FormData();

    if (mobile === '') {
      alert('Enter A Valid Number!');
      setLoading(false);
      return;
    }

    let countryCode = mobileCode.replace('+', '');

    let mobileNo = countryCode + mobile;
    data.append('number', mobileNo);

    post(FANZINE_SPORTS.LOGIN, data, false, '')
        .then((res) => {

          if (res.data.success) {
            dispatch(setIsLogin(true));
            navigate('/premier-league');
          }

          setLoading(false);
        })
        .catch((error) => {

          console.log('error while logging  =', error);
          console.log('error while logging in =', error.response.data.message);
          setLoading(false);
          // alert(error.response.data.message);
          setLoginError(error.response.data.message);
        });
  };

  useEffect(() => {

  }, [LoginStore]);

  const renderMain = () => {
    return (
        <div className={'pt-5'}>
          <div className={`d-flex ${isMobile ?
              'justify-content-center' :
              'container justify-content-start'} align-items-center`}>
            <img src={require('../../../assets/images/fanzineSportsLogo.png')}
                 style={{
                   height: isMobile ? 44 : 85,
                   width: isMobile ? 103 : 198,
                   objectFit: 'contain',
                 }}
                 alt={'Fanzine Sports Logo'}
            />
          </div>
          <div className={` ${isMobile ? 'pt-5' : 'pt-5 mt-4'}`}>
            <h3
                className={`text-white D-DIN-Bold ${isMobile ?
                    'font-30' :
                    'font-50'} text-center`}>
              THE HOME OF SPORT
            </h3>
          </div>
          <div className={` ${isMobile ?
              'pt-0 ps-4 pe-4 pb-4' :
              'pt-5 ps-4 pe-4 pb-4'} `}>
            <div className={`p-0 login-form ${isMobile ?
                'container w-100' :
                ''}`}>
              <h1 className={`ps-4 ${'pt-4'} sfProDisplayFontBold`}>Login</h1>
              <div className="p-2">
                {
                    loginError !== '' &&
                    (<div className={'alert alert-danger'}>
                      {loginError}
                    </div>)
                }
                <InputGroup
                    className={'d-flex justify-content-center align-items-center'}>
                  <div className={'row p-3'}>
                    <div className={'col-4'}>
                      <p className={` ${isMobile ?
                          'font-8' :
                          'font-14'} p-0 m-0 text-gray text-start`}>Country
                        Code</p>
                      <Form.Select
                          style={{
                            backgroundColor: '#e8e9ec',
                            borderBottomRightRadius: 0,
                            borderBottomLeftRadius: 0,
                            borderTopLeftRadius: 0,
                            borderTopRightRadius: 0,
                          }}
                          className={'p-1'}
                          value={mobileCode}
                          onChange={(e) => setMobileCode(e.target.value)}
                          aria-label="Default select example">
                        <option value={mobileCode}>{mobileCode === '' ?
                            isMobile ? '+234' : 'Nigeria +234' :
                            mobileCode}</option>
                        {
                          CountryCodes.map((value, index) => {
                            return (
                                <option key={index}
                                        value={value?.dial_code}>{(value?.name +
                                    ' ' + value?.dial_code)}</option>
                            );
                          })
                        }
                      </Form.Select>
                    </div>
                    <div className={'col-8 '}>
                      <p className={`${isMobile ?
                          'font-8' :
                          'font-14'} p-0 m-0 text-gray text-start`}>Phone
                        Number</p>
                      <Form.Control
                          type="tel"
                          maxLength={10}
                          className={'border-0'}
                          style={{
                            backgroundColor: '#e8e9ec',
                          }}
                          onKeyPress={(e) => {
                            if (e.key === 'Enter') {
                              handleLogin();
                            }
                          }}
                          value={mobile}
                          onChange={(e) => (!isNaN(parseInt(e.target.value)) ||
                                  e.target.value === '') &&
                              setMobile(e.target.value)}
                          aria-label="Text input with dropdown button"
                      />
                    </div>
                    <div className={'col-12 pt-4'}>
                      <div className="action">
                        <button
                            className={'d-flex border-radius-none align-items-center justify-content-center '}
                            style={{
                              background: '#74dbef',
                              color: '#fff',
                              height: 40,
                            }}
                            onClick={handleLogin}>
                          {loading ? <Spinner
                                  as="span"
                                  animation="border"
                                  size="sm"
                                  role="status"
                                  aria-hidden="true"
                              />
                              :
                              'LOGIN'
                          }
                        </button>
                      </div>
                    </div>
                  </div>
                </InputGroup>
                {/*<a href="#" className="link">Forgot Your Password?</a>*/}
              </div>
            </div>
          </div>
        </div>
    );
  };

  return (<div
      style={{
        marginTop: isMobile ? '-18%' : '-4%',
        height: isMobile ? '100vh' : '101vh',
        backgroundImage: `url(${isMobile ?
            MobileBackground :
            DesktopBackground})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
      }}>
    {renderMain()}
  </div>);

};

export default Login;
