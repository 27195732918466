import React from "react";
import Card from "react-bootstrap/Card";
import '../../../../Components/Cards/styles/NavbarCard.css';
import '../../../../assets/styles/styles.css';
import PropTypes from "prop-types";
import {NavLink} from "react-router-dom";
import {getHostname} from '../../../../CustomFunctions/getHostname';

class NewShopCategoryCard extends React.Component {

	render() {
		const hostName = window.location.hostname;
		const domainObject = getHostname(hostName);
		const target = domainObject.host;

		let {name, src, link, mainCategory, categories, whoAreYouBuyingFor} = this.props;
		let imageStyle = {};
		if (mainCategory) {
			imageStyle = {
				height: "410px",
				objectFit: "cover",
			}
		}

		return (
			<div className={`${mainCategory && mainCategory ? "col" : "col-6"} mt-3 border-0`}>
				<NavLink to={`${ target === 'fanzine' ? `${link}` :  `/shop/${link}`}`} className={'text-decoration-none text-dark border-0'}>
					<Card className="border-0 bg-transparent m-1"
					      style={{
								border: 0,
					}}>
						<Card.Img
							// variant="top"
							className={' border-0'}
							src={src}
							alt="Card image"
							height = {categories && 410 || whoAreYouBuyingFor && 623}
							resizeMode={"cover"}
							style={{
								objectFit: "cover",
							}}
							// style={imageStyle}
						/>
						<Card.ImgOverlay className={`d-flex flex-column justify-content-center align-items-center ${mainCategory ? "shop-category-overlay" : "shop-category-overlay"} border-0`}>
							<Card.Text className={`text-white sfProDisplayFont h1 border-0`}>
                                <span className={'bebasFont'}>
                                    {name}
                                </span>
							</Card.Text>
						</Card.ImgOverlay>
					</Card>
				</NavLink>
			</div>
		);
	}
}

NewShopCategoryCard.propTypes = {
	name: PropTypes.string.isRequired, src: PropTypes.string.isRequired,
}

export default NewShopCategoryCard;
