const BecomingTheBiggest = (props) => {
  const { isMobile,isWhoWeAre } = props;

  const renderMobileView = () => {
    return (
      <div
      >
        <div
          className={
            "row justify-content-center bg-fanzine-dark align-self-center mb-2"
          }
          style={{ width: isWhoWeAre ? "100%" :  "80%", marginLeft: "auto", marginRight: "auto" }}
        >
          <div className={"col-sm-12 col-xs-12"}>
            <div className={"row justify-content-center"}>
              <span className={`' ${isWhoWeAre ? 'font-30 Montserrat-Bold text-nowrap' : 'font-24'} font-weight-800 text-center'`}>
                BECOMING THE
              </span>
            </div>
          </div>
        </div>

        <div
          className={
            "row justify-content-center align-self-center mb-2"
          }
          style={{ width: isWhoWeAre ? "100%" :  "80%", marginLeft: "auto", marginRight: "auto" }}
        >
          <div className={"col-sm-12 col-xs-12"}>
            <div className={"row justify-content-center bg-fanzine-dark"}>
              <span className={`' ${isWhoWeAre ? 'font-30 Montserrat-Bold text-nowrap' : 'font-24'} font-weight-800 text-center'`}>
                BIGGEST SPORTS
              </span>
            </div>
          </div>
        </div>

        <div
          className={
            "row justify-content-center align-self-center mb-2"
          }
          style={{ width: isWhoWeAre ? "100%" :  "80%", marginLeft: "auto", marginRight: "auto" }}
        >
          <div className={"col-sm-12 col-xs-12"}>
            <div className={"row justify-content-center bg-fanzine-dark "}>
              <span className={`' ${isWhoWeAre ? 'font-30 Montserrat-Bold text-nowrap' : 'font-24'} font-weight-800 text-center'`}>
                FANS COMMUNITY
              </span>
            </div>
          </div>
        </div>

        <div
          className={
            "row justify-content-center align-self-center mb-5"
          }
          style={{ width: isWhoWeAre ? "100%" :  "80%", marginLeft: "auto", marginRight: "auto" }}
        >
          <div className={"col-sm-12 col-xs-12"}>
            <div className={"row justify-content-center bg-fanzine-dark"}>
              <span className={`' ${isWhoWeAre ? 'font-30 Montserrat-Bold text-nowrap' : 'font-24'} font-weight-800 text-center'`}>
                IN THE WORLD.
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  };
  const renderDesktopView = () => {
    return (
      <>
        <div className={"row mb-3"}>
          <div className={"col"}>
            <div className={"row justify-content-center bg-fanzine-dark "}>
              <span style={{
                fontWeight:'bold',
              }} className={"font-60 Montserrat-Bold text-center"}>
                BECOMING THE BIGGEST SPORTS
              </span>
            </div>
          </div>
        </div>
        <div className={"row mb-5"}>
          <div className={"col"}>
            <div className={"row justify-content-center bg-fanzine-dark "}>
              <span style={{
                fontWeight:'bold',
              }} className={"font-60 Montserrat-Bold text-center"}>
                FANS COMMUNITY IN THE WORLD
              </span>
            </div>
          </div>
        </div>
      </>
    );
  };
  const renderMain = () => {
    return isMobile ? renderMobileView() : renderDesktopView();
  };

  return renderMain();
};

export default BecomingTheBiggest;
