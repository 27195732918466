const Description = (props) => {
  const { heading, bodyText, isMobile } = props;

  const renderMobileView = () => {
    return (
      <div
        className={"col-md-3 col-lg-3 col-xl-3 col-xxl-3 mt-5"}
        style={{ width: "33%" }}
      >
        <p className={"font-30 Montserrat-Bold text-center text-white"}>{heading}</p>
        <p className={"font-10 text-white text-center Montserrat-Regular"}>{bodyText}</p>
      </div>
    );
  };

  const renderDesktopView = () => {
    return (
      <div className={"col-md-3 col-lg-3 col-xl-3 col-xxl-3"}>
        <p
            style={{
              fontWeight:'bold',
            }}
            className={"font-65 Montserrat-Bold text-center text-white"}>{heading}</p>
        <p className={"font-23 Montserrat-Regular text-center text-white"}>{bodyText}</p>
      </div>
    );
  };

  const renderMain = () => {
    return isMobile ? renderMobileView() : renderDesktopView();
  };

  return renderMain();
};

export default Description;
