import {useSelector} from "react-redux";
import {getHostname} from "../../CustomFunctions/getHostname";
import {useLocation} from "react-router-dom";
import {useEffect, useState} from "react";
import {SCREEN_SIZE} from "../../Configs/Constants";
import {get} from "../../Axios/get";
import {PodcastEndPoint} from "../../Axios/EndPoints";
import PodcastCard from "../../Components/Cards/PodcastCard";
import {TEXT_COLORS} from "../../Configs/Targets";
import GoogleAd from "../../Components/Ads/GoogleAd";
import Loader from "../../Components/Loader/OpaqueLoader";
import GoogleAdManager from "../../Components/Ads/GoogleAdManager";

const PodcastsPage = (props) => {
    const {} = props;

    const store = useSelector((state) => state);
    const hostName = window.location.hostname;
    const domainObject = getHostname(hostName);
    const target = domainObject.host;
    const location = useLocation();

    const [isMobile, setIsMobile] = useState(false);
    const [podcasts, setPodcasts] = useState([]);
    const [loading, setLoading] = useState(true);
    let [apiPage, setApiPage] = useState(1);
    let [restrictApiCall, setRestrictApiCall] = useState(true);

    const handleScroll = (e) => {
        const pageBottom = document.body.scrollHeight -
            document.documentElement.clientHeight - 100 <=
            document.documentElement.scrollTop;
        console.log('podcastPageBottom', pageBottom, document.body.scrollHeight, document.documentElement.clientHeight, document.documentElement.scrollTop);
        if (pageBottom) {
            setApiPage((prevState) => {
                return prevState + 1;
            });
            setRestrictApiCall(false);
        }
    };

    const fetchPodcasts = (isNew) => {
        get(PodcastEndPoint + `?page=${apiPage}&limit=18`)
            .then((response) => {
                console.log('response?.data?.data', response?.data);
                if (isNew) {
                    setPodcasts(response?.data);
                } else {
                    setPodcasts([...podcasts, ...response?.data]);
                }
                setLoading(false);
            });
    }

    useEffect(() => {
        if (window.screen.width <= SCREEN_SIZE.MOBILE) {
            setIsMobile(true);
        } else {
            setIsMobile(false);
        }
    }, [window.screen.width, isMobile]);

    useEffect(() => {
        setRestrictApiCall(true);
        window.addEventListener('scroll', handleScroll);
        setApiPage(1);
        setLoading(true);
        fetchPodcasts(true);
        return () => {
            window.removeEventListener('scroll', handleScroll);
            setPodcasts([])
            setLoading(true);
            setApiPage(1);
        };
    }, []);

    useEffect(() => {
        setLoading(true);
        !restrictApiCall && fetchPodcasts(false);
    }, [apiPage, restrictApiCall]);

    const renderMobile = () => {
        return (
            <section className={'mb-5 pb-5'}>
                {
                    podcasts?.map((value, index) => {
                        let ad = (<></>);
                        if ((index > 8 && index % 4 === 0) || index === 4) {
                            ad = (<GoogleAd id={'4009154580'}/>);
                        }
                        if (index === 0) {
                            ad = (<GoogleAdManager isMobile={isMobile} id={isMobile ? '1704953268243' : '1704953333715'}/>);
                        }

                        return (
                            <>
                                <PodcastCard podcastHeight={'245px'} extraClass={''} isMobile={isMobile} podcast={value}
                                             league={{font_color: TEXT_COLORS[target]}} isClub={true}
                                />
                                {ad}
                            </>
                        );
                    })
                }
            </section>
        );
    }
    const renderDesktop = () => {
        return (
            <section className={'mt-5 pt-5 mb-5 pb-5'}>
                Podcasts page Desktop
            </section>
        );
    }
    const render = () => {
        if (loading) {
            return (
                <Loader loading={loading}/>
            );
        }
        return isMobile ? renderMobile() : renderDesktop();
    }

    return render();

}

export default PodcastsPage;
