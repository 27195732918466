import React, {useState} from "react";
import PropTypes, {array, object} from "prop-types";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft, faCheck} from "@fortawesome/free-solid-svg-icons";
import FilterItemOptions from "./FilterItemOptions";
import {useSelector} from "react-redux";

const FilterItemOptionScreen = (props) => {

    const store = useSelector(state => state);

    let setMainFiltersScreen = props.setMainFiltersScreen;
    let setItemFiltersScreen = props.setItemFiltersScreen;
    let setSelectedFilters = props.setSelectedFilters;
    let setHasFiltersChanged = props.setHasFiltersChanged;
    let selectedFilters = props.selectedFilters;
    let title = props.title;
    let target = props.target;
    let items = props.items;
    let isColorScreen = props.isColorScreen;

    const [selectAll, setSelectedAll] = useState(null);

    const render = () => {
        return (
            <div className={`col bg-white ps-0 pe-0 pt-0 pb-4 shadow-nav-dark`} style={{
                zIndex: "999",
                height: "max-content",
            }}>
                <div className={`row w-100`} style={{height: "50px"}}>
                    <div
                        className={`col-6 ps-4 sfProDisplayFontBold font-16 d-flex align-items-center justify-content-start`}>
                        <FontAwesomeIcon className={`me-2`} icon={faArrowLeft} size="1x" onClick={() => {
                            setItemFiltersScreen(false);
                            setMainFiltersScreen(true);
                        }}/>
                        {title}
                    </div>
                    <div className={`col-6 sfProDisplayFont d-flex align-items-center justify-content-end text-gray`}
                         onClick={() => {
                             if (selectAll === null) {
                                 setSelectedAll(true);
                             } else {
                                 if (selectAll) {
                                     setSelectedFilters([]);
                                 }
                                 setSelectedAll(!selectAll);
                             }
                         }}
                    >
                        All <FontAwesomeIcon className={`ms-2`} icon={faCheck} size={`1x`}/>
                    </div>
                </div>
                <div className={`bg-grey`} style={{height: "2%"}}/>
                {items && !isColorScreen && items.map((value) => (
                        <FilterItemOptions title={value.shopify_name ? value.shopify_name : value} filterTitle={value.filter_name ? value.filter_name : value} setSelectedFilters={setSelectedFilters}
                                           selected={selectAll} setSelectedAll={setSelectedAll} selectedFilters={selectedFilters} setSelectedChanged={props.setSelectedChanged}/>
                    )
                )}
                {items && isColorScreen && items.map((value, index) => (
                    <>
                        <FilterItemOptions title={value}
                                           setSelectedFilters={setSelectedFilters}
                                           selected={selectAll}
                                           setSelectedAll={setSelectedAll}
                                           selectedFilters={selectedFilters}
                                           isColors={true}
                                           color={store.shopify.productColors[value] ? store.shopify.productColors[value] : value}
                                           setSelectedChanged={props.setSelectedChanged}
                        />
                    </>
                ))}
                <div className={`text-center`} style={{marginTop: "9rem"}}>
                    <button className={`btn bg-${target} text-white sfProDisplayFontBold font-18`} style={{
                        borderRadius: "0",
                        width: "315px",
                    }} onClick={() => {
                        setHasFiltersChanged(true);
                    }}>View Items
                    </button>
                </div>

            </div>
        );
    }

    return render();

}

FilterItemOptionScreen.propTypes = {
    setMainFiltersScreen: PropTypes.func.isRequired,
    setItemFiltersScreen: PropTypes.func.isRequired,
    setSelectedFilters: PropTypes.func.isRequired,
    selectedFilters: PropTypes.array.isRequired,
    title: PropTypes.string.isRequired,
    target: PropTypes.string.isRequired,
    isColorScreen: PropTypes.bool,
    items: PropTypes.oneOfType([array, object]),
}

export default FilterItemOptionScreen;