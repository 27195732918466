import {CartActionTypes} from "../constants/CartActionTypes";

const initialState = {
    cartItems: [],
    grandTotal: 0,
    checkoutId: null,
}

export default function CartReducer(state = initialState, action) {
    switch (action.type) {
        case CartActionTypes.SET_CART_ITEMS:
            return {...state, cartItems: action.payload};
        case CartActionTypes.SET_GRAND_TOTAL:
            return {...state, grandTotal: action.payload};
        case CartActionTypes.SET_CHECKOUT_ID:
            return {...state, checkoutId: action.payload};
        default:
            return state;
    }

}