import {BrowserRouter, Route, Routes} from "react-router-dom";
import ScrollToTop from "../Components/ScrollToTop";
import Footer from "../Components/Footer";
import FanzineSportsNavBar from '../Components/Navbar/FanzineSportsNavBar';
import FanzineSportsHome from '../Pages/FanzineSports/Home/FanzineSportsHome';
import SingleNewsPage from "../Pages/Football/SingleNewsPage";
import Login from "../Pages/FanzineSports/Login/Login";
import FanzineSportsFootballTeams
    from '../Pages/FanzineSports/Teams/FanzineSportsFootballTeams';
import Club from '../Pages/FanzineSports/Club/Club';

const FanzineSportsRoutes = () => {
    return (
        <BrowserRouter>
            <ScrollToTop>
                <Routes>
                    <Route exact path="/" element={<FanzineSportsHome/>}/>
                    <Route exact path="/:league" element={<FanzineSportsHome/>}/>
                    <Route exact path="/login" element={<Login/>}/>
                    <Route exact path="/teams" element={<FanzineSportsFootballTeams/>}/>
                    <Route
                        exact
                        path="/:team/overview"
                        element={<Club/>}
                    />
                    <Route
                        exact
                        path="/:team/news"
                        element={<Club/>}
                    />
                    <Route
                        exact
                        path="/:team/matches"
                        element={<Club/>}
                    />
                    <Route
                        exact
                        path="/:team/table"
                        element={<Club/>}
                    />
                    <Route
                        exact
                        path="/:team/squad"
                        element={<Club/>}
                    />
                    <Route path="/news/:id" element={<SingleNewsPage/>}/>
                    <Route path="/:sport/news/:id" element={<SingleNewsPage/>}/>
                    <Route path="*" element={<Login/>}/>
                </Routes>
            </ScrollToTop>
        </BrowserRouter>
    );
}

export default FanzineSportsRoutes;
