import {useParams} from 'react-router-dom';
import {useEffect, useState} from 'react';
import {FANZINE} from '../../../Axios/EndPoints';
import {get} from '../../../Axios/get';

const PreviewRecentForm = (props) => {
  let params = useParams()
  const {addDefaultSrc, recent_form, isMobile, isCurrent,matchData} = props;
  const [recentForm, setRecentForm]= useState(null)

  useEffect(()=>{
    isCurrent && fetchMatchWidgets()
  },[isCurrent])

  const fetchMatchWidgets = () =>
  {
    get(FANZINE.SingleMatchEndPoint+params.id+FANZINE.MatchesWidgetsSubEndPoint)
        .then((res)=>{

          setRecentForm(res.data.widgets.recent_form.data)
        })
        .catch((error)=>console.error(error))
  }


  return (
      <div className={'bg-white p-3 rounded-custom'}>
        <div className={'row '}>
          <p className={'sfProDisplayFontLight font-22 text-center'}>
            Recent Form
          </p>
        </div>
        <div className={'row'}>
          <div className={`col ${isMobile && ''}`} >
            {
              recentForm?.home_team?.all_games?.map((value, index) => (
                  <div
                      className={`row  ${index === 0 ? 'mt-1' : 'mt-4'} ${isMobile ? '' : 'center-items'} text-decoration-none text-black`}
                      key={index}
                      style={{cursor: 'pointer'}}
                  >
                    <div
                        className={
                          `${isMobile ? 'font-12 text-start d-flex col-5' : 'font-14 text-end col'}  justify-content-center sfProDisplayFontBold  fixed`}
                        style={{color: '#252525'}}>
                      {value?.localTeam?.name.slice(0, 3).toUpperCase().replace(' ', '')}
                      <img
                          className={`${isMobile ? 'ms-1' : 'ms-2'}`}
                          height={isMobile ? 20 : 25}
                          width={'auto'}
                          alt={"team badge"}
                          src={value?.localTeam.icon}
                          onError={addDefaultSrc}
                      ></img>
                    </div>

                    <div
                        className={`text-center ${isMobile ? 'font-12 col-2' : 'font-14 col-3'} sfProDisplayFontBold`}>
                      <div className={'row'}
                           style={{width: 'max-content', marginLeft: isMobile ? '' : 'inherit'}}>
                        <div
                            className={'col-12 justify-content-center align-items-center border-radius-3px'}
                            style={{
                              background:  matchData?.home_team?.id === value?.localTeam?.id ? value?.general_result ==='win' ? 'green' : value?.general_result ==='draw' ? 'gray': 'red'
                                  :
                                  matchData?.home_team?.id === value?.visitorTeam?.id ? value?.general_result ==='win' ? 'green' : value?.general_result ==='draw' ? 'gray': 'red' : 'green',
                              color: 'white'
                            }}>
                          {value?.score}
                        </div>
                      </div>
                    </div>

                    <div
                        className={
                          `${isMobile ? 'font-12 col-5' : 'font-14 col'} justify-content-center sfProDisplayFontBold  text-end fixed`}
                        style={{display: 'flex', alignItems: 'center', color: '#252525'}}>
                      <img
                          className={`${isMobile ? 'ms-3 me-1' : 'me-2'}`}
                          height={isMobile ? 20 : 25}
                          alt={"team badge"}
                          src={value?.visitorTeam?.icon}
                          onError={addDefaultSrc}
                      ></img>
                      {value?.visitorTeam?.name.slice(0, 3).toUpperCase().replace(' ', '')}
                    </div>
                  </div>
              ))
            }
          </div>
          {!isMobile && <hr
              style={{
                height: 'auto',
                width: 'auto',
                borderWidth: '0',
                padding: '1px',
                margin: '1px'
              }}
          />
          }
          <div className={`col ${isMobile && ''}`}>
            {
              recentForm?.away_team?.all_games?.map((value, index) => (
                  <div
                      className={`row  ${index === 0 ? 'mt-1' : 'mt-4'} ${isMobile ? '' : 'center-items'} text-decoration-none text-black`}
                      key={index}
                      style={{cursor: 'pointer'}}
                  >
                    <div
                        className={
                          `${isMobile ? 'font-12 text-start d-flex col-5' : 'font-14 text-end col'}  justify-content-center sfProDisplayFontBold  fixed`}
                        style={{color: '#252525'}}>
                      {value?.localTeam?.name.slice(0, 3).toUpperCase().replace(' ', '')}
                      <img
                          className={`${isMobile ? 'ms-1' : 'ms-2'}`}
                          height={isMobile ? 20 : 25}
                          width={'auto'}
                          alt={"team badge"}
                          src={value?.localTeam.icon}
                          onError={addDefaultSrc}
                      ></img>
                    </div>

                    <div
                        className={`text-center ${isMobile ? 'font-12 col-2' : 'font-14 col-3'} sfProDisplayFontBold`}>
                      <div className={'row'}
                           style={{width: 'max-content', marginLeft: isMobile ? '' : 'inherit'}}>
                        <div
                            className={'col-12 justify-content-center align-items-center border-radius-3px'}
                            style={{
                              background: matchData?.guest_team?.id === value?.localTeam?.id ? value?.general_result ==='win' ? 'green' : value?.general_result ==='draw' ? 'gray': 'red'
                                  :
                                  matchData?.guest_team?.id === value?.visitorTeam?.id ? value?.general_result ==='win' ? 'green' : value?.general_result ==='draw' ? 'gray': 'red' : 'green' ,
                              color: 'white'
                            }}>
                          {value?.score}
                        </div>
                      </div>
                    </div>

                    <div
                        className={
                          `${isMobile ? 'font-12 col-5' : 'font-14 col'} justify-content-center sfProDisplayFontBold  text-end fixed`}
                        style={{display: 'flex', alignItems: 'center', color: '#252525'}}>
                      <img
                          className={`${isMobile ? 'ms-3 me-1' : 'me-2'}`}
                          height={isMobile ? 20 : 25}
                          alt={"team badge"}
                          src={value?.visitorTeam?.icon}
                          onError={addDefaultSrc}
                      ></img>
                      {value?.visitorTeam?.name.slice(0, 3).toUpperCase().replace(' ', '')}
                    </div>
                  </div>
              ))
            }
          </div>
        </div>
      </div>
  )

}

export default PreviewRecentForm;
