import 'react-bootstrap-typeahead/css/Typeahead.css';
import '../../assets/styles/footballTeams.css';
import React, {useEffect, useState} from 'react';
import {SCREEN_SIZE} from '../../Configs/Constants';
import {Card, Dropdown, Form} from 'react-bootstrap';
import GoogleAd from '../../Components/Ads/GoogleAd';
import {useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import RightNewsAds from '../../Components/Layout/News/RightNewsAds';
import Accordion from 'react-bootstrap/Accordion';
import {SubNavbar} from '../../Components/Navbar/SubNavbar';
import {get} from '../../Axios/get';
import {FANZINE} from '../../Axios/EndPoints';
import GoogleAdManager from "../../Components/Ads/GoogleAdManager";

const CustomMenu = React.forwardRef(
    ({children, style, className, 'aria-labelledby': labeledBy}, ref) => {
        const [value, setValue] = useState('');

        return (
            <div
                ref={ref}
                style={style}
                className={className}
                aria-labelledby={labeledBy}
            >
                <Form.Control
                    autoFocus
                    className="p-2 my-2 w-100"
                    placeholder="Type to filter..."
                    onChange={(e) => setValue(e.target.value)}
                    value={value}
                />
                <ul className="list-unstyled w-100">
                    {React.Children.toArray(children).filter(
                        (child) =>
                            !value || child.props.children.toLowerCase().startsWith(value.toLowerCase()),
                    )}
                </ul>
            </div>
        );
    },
);

const FootballTeams = () => {

    const store = useSelector((state) => state);
    const [isMobile, setIsMobile] = useState(false);
    const [selected, setSelected] = useState([]);
    const [league, setLeague] = useState([]);
    const [selectedLeague, setSelectedLeague] = useState('');
    const [selectedLeagueToDisplay, setSelectedLeagueToDisplay] = useState([]);
    const [dropdownTitle, setDropdownTitle] = useState('Select League');

    const fetchLeagueWithTeams = () => {
        get(FANZINE.AllTeamsEndPoint).then((response) => {
            setLeague(response.data.data);
            setSelectedLeague('Premier League');
            setDropdownTitle('Premier League');
        }).catch((err) => {
            console.error(err);
        });
    };

    useEffect(() => {
        if (window.screen.width <= SCREEN_SIZE.MOBILE) {
            setIsMobile(true);
        } else {
            setIsMobile(false);
        }

    }, [window.screen.width, isMobile]);

    useEffect(() => {
        fetchLeagueWithTeams();

    }, []);

    useEffect(() => {
        sportSelect(selectedLeague);
    }, [selectedLeague]);

    const sportSelect = (currentLeague) => {
        let preparedLeagueName = currentLeague?.replaceAll(' ', '-').toLowerCase();

        let leg = league?.filter(value => {
            return value?.name?.replaceAll(' ', '-')?.toLowerCase() ===
                preparedLeagueName;
        });

        setSelectedLeagueToDisplay(leg);
    };

    const renderMobileAccordian = () => {

        return (
            <div>
                {league?.map((item, index) => {
                        return item?.name !== 'All Football' && (
                            <Accordion>
                                <Accordion.Header>
                                    {item?.name}
                                </Accordion.Header>
                                {
                                    <Accordion.Body>
                                        <div className={'row'}>
                                            {item?.teams?.map((value) => {
                                                return (
                                                    <div
                                                        className={'col-lg-4 col-md-4 col-sm-12 col-xs-12'}>
                                                        <Link to={`/football/${value?.slug}`}
                                                              className={'text-decoration-none text-black'}>
                                                            <img style={{
                                                                height: '34px',
                                                                width: '29px',
                                                                objectFit: 'contain',
                                                            }}
                                                                 src={value?.icon}
                                                                 alt={value?.name + ' logo'}>
                                                            </img>
                                                            &nbsp;
                                                            &nbsp;
                                                            <span>
                                                                {value?.name}
                                                            </span>
                                                        </Link>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </Accordion.Body>
                                }
                            </Accordion>
                        );
                    },
                )
                }
            </div>
        );
    };

    const renderLaptopView = () => {
        return (
            <div className={'container'}>
                {selectedLeagueToDisplay?.map((item, index) => (
                    <div
                        key={index}
                        className={'row'}>
                        {/*<h3 className={'robotoFont mt-4 mb-4'}>{item?.name}</h3>*/}

                        {item?.teams.map((value, valueIndex) => (
                            <div className={'col-6 mb-4'}>
                                <Link
                                    key={valueIndex}
                                    to={`/football/${value?.slug}`}
                                    className={'text-decoration-none text-black'}>
                                    <Card.Img
                                        style={{
                                            height: '34px',
                                            width: '29px',
                                            objectFit: 'contain',
                                        }}
                                        src={value?.icon}
                                    />
                                    &nbsp;
                                    &nbsp;
                                    <span>{value?.name}</span>
                                </Link>
                            </div>
                        ))}

                    </div>
                ))}
            </div>
        );
    };

    const renderLeftCard = () => {

        return (
            <div
                className={`col-xxl-8 col-lg-8 col-md-8 col-sm-12 col-xs-12 mb-5 ${isMobile &&
                'mt-5 pt-5'} `}>
                <Card>
                    {!isMobile &&
                        <Card.Header className={`border-0`}>
                            <div className={`container p-2`}>
                                <h1 className={`robotoFont font-weight-800 font-28`}>Football Teams</h1>
                            </div>
                            <div className={'typeaheadStyles'}>
                                <Dropdown className={`w-100 border-0`}>
                                    <Dropdown.Toggle id="dropdown-custom-components" className={'btn w-25 bg-lighter-grey border-0 rounded-top-custom rounded-bottom-custom p-2 text-black'}>
                                        {dropdownTitle}
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu as={CustomMenu} className={`w-100 p-2`}>
                                        {
                                            league?.map((value, index) => (
                                                <Dropdown.Item eventKey={index + 1}
                                                               data-value={value?.name}
                                                               onClick={(event) => {
                                                                   setDropdownTitle(event.target.dataset.value);
                                                                   setSelectedLeague(event.target.dataset.value);
                                                               }}
                                                >
                                                    {value?.name}
                                                </Dropdown.Item>
                                            ))
                                        }
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        </Card.Header>
                    }
                    <Card.Body className={`border-0`}>
                        <div className={'container'}>
                            {isMobile ? renderMobileAccordian() : renderLaptopView()}
                        </div>
                    </Card.Body>
                </Card>
            </div>
        );
    };

    const renderRightNewsAds = () => {
        return <RightNewsAds isMobile={isMobile}/>;
    };

    const renderMain = () => {
        return (
            <div className={`w-100 ${isMobile && 'mt-n3'}`}>
                <SubNavbar isMobile={isMobile} items={['news', 'teams', 'matches']}/>
                <div className={'container mt-2'}>
                    <GoogleAdManager isMobile={isMobile} id={isMobile ? '1704953268243' : '1704953333715'}/>
                    <div className={'row'}>
                        {renderLeftCard()}
                        {!isMobile && renderRightNewsAds()}
                    </div>
                </div>
            </div>
        );
    };
    return renderMain();
};

export default FootballTeams;
