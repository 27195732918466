import React, {useCallback, useEffect, useState} from 'react';
import {get} from '../../Axios/get';
import {FOOTBALL, NBA, NFL, MLB, CRICKET, NCAA} from '../../Axios/EndPoints';
import {useSelector} from "react-redux";
import GoogleAdManager from "../../Components/Ads/GoogleAdManager";
import moment from "moment-timezone";
import {Card} from 'react-bootstrap';
import Loader from "../../Components/Loader/Loader";

const NewHomePage = (props) => {
    const {isMobile} = props;

    const {football} = useSelector((state) => state);
    const {fanzineTeamsLeagues = []} = football || {};

    // console.log("fanzineTeamsLeagues >>>", fanzineTeamsLeagues)

    const [selectedSport, setSelectedSport] = useState('All');
    const [latestNews, setLatestNews] = useState([]);
    // console.log("latestNews at home page is  >>>>", latestNews);

    const [loading, setLoading] = useState(false);
    const [newsPageLoading, setNewsPageLoading] = useState(false);

    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);

    // console.log("newsPageLoading >>>", newsPageLoading, " with page >>>", page, " with has more data >>>", hasMore)

    const sportEndpoints = {
        All: [
            {endpoint: FOOTBALL.LatestNewsEndPoint, sport: 'football'},
            {endpoint: NBA.NewsEndPoint, sport: 'nba'},
            {endpoint: NFL.NewsEndPoint, sport: 'nfl'},
            {endpoint: MLB.NewsEndPoint, sport: 'mlb'},
            {endpoint: CRICKET.NewsEndPoint, sport: 'cricket'},
            {endpoint: NCAA.NewsEndPoint, sport: 'ncaa'},
        ],
        Football: {endpoint: FOOTBALL.LatestNewsEndPoint, sport: 'football'},
        NBA: {endpoint: NBA.NewsEndPoint, sport: 'nba'},
        NFL: {endpoint: NFL.NewsEndPoint, sport: 'nfl'},
        MLB: {endpoint: MLB.NewsEndPoint, sport: 'mlb'},
        Cricket: {endpoint: CRICKET.NewsEndPoint, sport: 'cricket'},
        NCAAM: {endpoint: NCAA.NewsEndPoint, sport: 'ncaa'},
        Womens: {endpoint: FOOTBALL.LatestNewsEndPoint, sport: 'football'},
    };
    const handleSportClick = (sportName) => {
        setSelectedSport(sportName);
        setPage(1);
        setHasMore(true);
        setLatestNews([]);
    };
    const shuffleArray = (array) => {
        for (let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [array[i], array[j]] = [array[j], array[i]];
        }
        return array;
    };

    const fetchLatestNews = async (endpoints, page = 1) => {
        if (page === 1) {
            setLoading(true);
        } else {
            setNewsPageLoading(true);
        }

        try {
            let tempNewsData = [];

            if (selectedSport === 'All') {
                const requests = endpoints?.map(({endpoint, sport}) =>
                    get(`${endpoint}?limit=50&page=${page}`).then(response => ({
                        sport,
                        data: response?.data,
                    }))
                );
                const responses = await Promise.all(requests);
                responses?.forEach(({sport, data}) => {
                    const newsData = data?.map((newsItem) => ({
                        ...newsItem,
                        sport,
                        is_snack: newsItem?.is_snack,
                        news_id: newsItem?.news_id,
                        slug: newsItem?.slug,
                        news_image: newsItem?.image,
                        news_title: newsItem?.title,
                        news_url: newsItem?.url,
                        news_time: moment.tz(newsItem?.datetime, 'Europe/London').local().startOf('seconds').fromNow(),
                        news_date: moment(newsItem?.datetime).format('D MMM YYYY, H:mm'),
                        news_publisher: {
                            publisher_name: newsItem?.publisher?.name,
                            publisher_icon: newsItem?.publisher?.icon,
                        },
                    }));
                    tempNewsData = tempNewsData?.concat(newsData);
                });
                tempNewsData = shuffleArray(tempNewsData);
            } else {
                const {endpoint, sport} = endpoints;
                const limit = selectedSport === "Womens" ? `?limit=50&page=${page}&teamType=team_women` : `?limit=50&page=${page}`;
                const response = await get(endpoint + limit);
                tempNewsData = response?.data?.map((newsItem) => ({
                    ...newsItem,
                    sport,
                    is_snack: newsItem?.is_snack,
                    news_id: newsItem?.news_id,
                    slug: newsItem?.slug,
                    news_image: newsItem?.image,
                    news_title: newsItem?.title,
                    news_url: newsItem?.url,
                    news_time: moment.tz(newsItem?.datetime, 'Europe/London').local().startOf('seconds').fromNow(),
                    news_date: moment(newsItem?.datetime).format('D MMM YYYY, H:mm'),
                    news_publisher: {
                        publisher_name: newsItem?.publisher.name,
                        publisher_icon: newsItem?.publisher.icon,
                    },
                }));
            }

            setLatestNews(prevNews => page === 1 ? tempNewsData : [...prevNews, ...tempNewsData]);
            setHasMore(tempNewsData?.length > 0);
        } catch (err) {
            console.error(err);
        } finally {
            setNewsPageLoading(false);
            setLoading(false);
        }
    };

    useEffect(() => {
        const endpoint = sportEndpoints[selectedSport];
        fetchLatestNews(endpoint, page);
    }, [selectedSport, page]);

    const handleScroll = useCallback(() => {
        if (window.innerHeight + document.documentElement.scrollTop !== document.documentElement.offsetHeight || loading || !hasMore) return;
        setPage(prevPage => prevPage + 1);
    }, [loading, hasMore]);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [handleScroll]);


    const addDefaultSrc = (ev) => {
        ev.target.src = require(
            '../../assets/images/football-shots-you-need-to-know.jpg');
    };
    const renderLargeCard = (imageHeight, news, BigImage) => {
        return (
            <>
                <Card
                    className={`col mt-2 border-0`}
                    style={{
                        height: isMobile ?
                            ('210px') :
                            (imageHeight || '245px'),
                        width: isMobile ? 'auto' : 'auto',
                        marginLeft: '10px',
                    }}
                >
                    <Card.Img
                        variant="top"
                        className={'rounded-0'}
                        height={isMobile ?
                            ('210px') :
                            (imageHeight || '245px')}
                        width={isMobile ? 'auto' : 'auto'}
                        src={news && news?.news_image}
                        alt={news && news?.news_title}
                        onError={addDefaultSrc}
                        style={{
                            objectFit: 'cover',
                            borderRadius: '15px',
                            width: isMobile ? 'auto' : 'auto',
                        }}
                    />
                    {
                        <Card.ImgOverlay
                            style={{
                                lineHeight: 1,
                            }}
                            className={`d-flex align-items-start flex-column justify-content-end ${news?.type ===
                            'videos' ? 'video-overlay' : 'news-overlay'}`}
                        >
                            <Card.Text
                                className={`text-white text-start jost text-wrap w-100 d-flex flex-column gap-2`}
                            >
                                <span
                                    className={`jost me-2 font-weight-500 ${isMobile ?
                                        'font-14' :
                                        'font-20'}`}
                                    dangerouslySetInnerHTML={{
                                        __html: news && news?.news_publisher?.publisher_name,
                                    }}
                                />
                                <span
                                    className={`jost font-weight-500 ${isMobile ?
                                        'font-24' :
                                        'font-32'}`}
                                    dangerouslySetInnerHTML={{__html: news && news?.news_title}}
                                />
                            </Card.Text>
                        </Card.ImgOverlay>
                    }
                </Card>
            </>
        );
    };
    const renderSmallCard = (imageHeight, news, BigImage) => {
        return (
            <>
                <Card
                    className={`col mt-2 border-0 bg-light-black`}
                    style={{
                        height: isMobile ?
                            ('105px') :
                            ('290px'),
                        width: isMobile ? 'auto' : 'auto',
                        marginLeft: '10px',
                    }}
                >
                    <Card.Body className={`p-0 m-0 d-flex ${isMobile ? "flex-row w-100" : "flex-column"}`}>
                        <Card.Img
                            variant={"top"}
                            className={'rounded-0'}
                            height={isMobile ? "105px" : imageHeight}
                            // width={isMobile ? 'w-30' : 'auto'}
                            src={news && news?.news_image}
                            alt={news && news?.news_title}
                            onError={addDefaultSrc}
                            style={{
                                objectFit: 'cover',
                                width: isMobile ? '40%' : 'auto',
                            }}
                        />

                        <div style={{width: isMobile ? "60%" : "auto"}}
                             className={"d-flex flex-column py-1 px-2 text-start gap-1"}>
                            <div className={"d-flex align-items-center justify-content-between"}>
                                <span
                                    className={`jost font-weight-500 font-fanzine ${isMobile ?
                                        'font-14' :
                                        'font-16'}`}
                                    dangerouslySetInnerHTML={{
                                        __html: news && news?.news_publisher?.publisher_name,
                                    }}
                                />
                                <span
                                    className={`jost font-weight-400 font-fanzine ${isMobile ?
                                        'font-14' :
                                        'font-14'}`}
                                >
                                    {news && news?.news_time
                                        ?.replace('minutes', 'mins')
                                        ?.replace('ago', '')
                                        ?.replace('an', '1')
                                        ?.replace('a day', '1 day')}
                                </span>
                            </div>
                            <div className={""}>
                                <span
                                    className={`jost font-weight-500 text-white text-truncate-custom ${isMobile ? "font-13" : "font-16"}`}
                                    dangerouslySetInnerHTML={{__html: news && news?.news_title}}
                                />
                            </div>
                        </div>
                    </Card.Body>

                </Card>
            </>
        );
    };

    const renderSingleSport = (sport) => {
        if (sport.name === 'NCAAF') return null;

        return (
            <div role={"button"} key={sport.name}
                 className={`d-flex rounded align-items-center px-3 py-2 ${selectedSport === sport.name ? 'bg-fanzine-dark text-black' : 'bg-light-black text-white'}`}
                 onClick={() => handleSportClick(sport.name)}>
                {sport.name}
            </div>
        );
    };

    const renderSports = () => {
        const sportLimit = 8;

        if (selectedSport === 'All') {
            return <div
                className={`${isMobile ? "w-100" : "row mt-4"}`}
            >
                {
                    latestNews !== undefined && latestNews !== null && latestNews?.map((newsInData, index) => {
                        const bigImage = isMobile ? index % 9 === 0 : index % 17 === 0; // Large card at index 0, 17, 34, etc.

                        // const sportPath = selectedSport.toLowerCase();
                        const sportPath = newsInData?.sport;

                        return (
                            <div
                                key={index}
                                className={`m-0 ${bigImage ? `col-12 p-1` : 'col-xl-3 col-lg-3 col-md-3 p-1'} col-sm-12 col-xs-12`}
                            >
                                {bigImage && index !== 0 && (
                                    <div className="col-12 p-1 w-100">
                                        <GoogleAdManager isMobile={isMobile}
                                                         id={isMobile ? '1704953268243' : '1704953333715'}/>
                                    </div>
                                )}
                                <a
                                    href={`/${sportPath}/news/${newsInData?.slug}`}
                                    className={'text-decoration-none text-dark'} target={"_blank"} rel="noreferrer">
                                    {bigImage
                                        ? renderLargeCard(bigImage && !isMobile ? '690px' : '245px', newsInData, bigImage)
                                        : renderSmallCard(bigImage && !isMobile ? '170px' : '160px', newsInData, bigImage)}
                                </a>

                            </div>

                        );
                    })
                }
                {
                    newsPageLoading &&
                    <>
                        {/*<div className={"text-center text-white"}>Loading</div>*/}
                        <Loader loading={newsPageLoading}/>
                    </>
                }
            </div>;
        } else {
            const filteredSports = fanzineTeamsLeagues?.filter(sport => sport.name === selectedSport);
            return filteredSports?.slice(0, sportLimit).map((sport, index) => (
                <div key={index} className={`${isMobile ? "w-100" : "row mt-4"}`}>
                    {
                        latestNews !== undefined && latestNews !== null && latestNews?.map((newsInData, index) => {
                            const bigImage = isMobile ? index % 9 === 0 : index % 17 === 0; // Large card at index 0, 17, 34, etc.
                            // const sportPath = selectedSport.toLowerCase();
                            const sportPath = newsInData?.sport;

                            return (
                                <div
                                    key={index}
                                    className={`m-0 ${bigImage ? `col-12 p-1` : 'col-xl-3 col-lg-3 col-md-3 p-1'} col-sm-12 col-xs-12`}
                                >
                                    {bigImage && index !== 0 && (
                                        <div className="col-12 p-1 w-100">
                                            <GoogleAdManager isMobile={isMobile}
                                                             id={isMobile ? '1704953268243' : '1704953333715'}/>
                                        </div>
                                    )}
                                    <a
                                        href={`/${sportPath}/news/${newsInData?.slug}`}
                                        className={'text-decoration-none text-dark'} target={"_blank"} rel="noreferrer">
                                        {bigImage
                                            ? renderLargeCard(bigImage && !isMobile ? '690px' : '245px', newsInData, bigImage)
                                            : renderSmallCard(bigImage && !isMobile ? '170px' : '160px', newsInData, bigImage)}
                                    </a>
                                </div>
                            );
                        })
                    }
                    {
                        newsPageLoading &&
                        <>
                            {/*<div className={"text-center text-white"}>Loading</div>*/}
                            <Loader loading={newsPageLoading}/>
                        </>
                    }
                </div>
            ));
        }
    };
    const renderSportFilters = () => {
        return (
            <>
                <div role={"button"}
                     className={`d-flex rounded align-items-center px-3 py-2 ${selectedSport === 'All' ? 'bg-fanzine-dark text-black' : 'bg-light-black text-white'}`}
                     onClick={() => handleSportClick('All')}>
                    All
                </div>
                {fanzineTeamsLeagues.map((sport) => (
                    sport.name !== 'NCAAF' && renderSingleSport(sport)
                ))}
            </>
        );
    };


    const renderMain = () => {
        return (
            <div className={`bg-black ${isMobile ? "py-3" : "py-5"}`}>
                {
                    !isMobile &&
                    <GoogleAdManager isMobile={isMobile} id={isMobile ? '1704953268243' : '1704953333715'}/>
                }
                <div
                    className={`container d-flex flex-row align-items-center gap-3 ${isMobile ? "overflow-scroll" : "flex-wrap"}`}>
                    {renderSportFilters()}
                </div>
                {
                    isMobile &&
                    <GoogleAdManager isMobile={isMobile} id={isMobile ? '1704953268243' : '1704953333715'}/>
                }
                <div className={`container d-flex flex-row align-items-center gap-3`}>
                    {
                        loading ?
                            <>
                                <Loader loading={loading}/>
                            </> :
                            <>
                                {renderSports()}
                            </>
                    }

                </div>
            </div>
        );
    };

    return renderMain();
};

export default NewHomePage;
