import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {NavLink, useLocation} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {FONT_COLOR, NAV_CLASS, TEXT_COLOR} from '../../Configs/Targets';
import {getHostname} from '../../CustomFunctions/getHostname';
import {ColorIcon} from './ColorIcon/ColorIcon';
import BottomColorButtons from './BottomColorButtons';
import {GetConvertedPrice} from '../../CustomFunctions/getConvertedPrice';

const ProductComponent = (props) => {
  const store = useSelector(state => state);

  const [imageSrc, setImageSrc] = useState(props?.item?.src);
  const location = useLocation();

  const render = () => {
    const hostName = window.location.hostname;
    const domainObject = getHostname(hostName);
    const target = domainObject.host;
    let arrowColor = NAV_CLASS[target] + '-before';
    let showArrows = 'block';
    let item = props?.item;
    let showDetail = props?.showDetail;
    let showColors = props?.showColors;
    let showPriceFirst = props?.showPriceFirst;
    let priceColor = props?.priceColor ? props?.priceColor : '#000000';
    let productColorHex = store?.shopify?.productColors;


    // console.log('item =', item,showColors,showDetail,showPriceFirst)
    return (
        <>
          <NavLink to={target === 'fanzine' ?
              `/${location.pathname.split('/')[1]}/shop/product/${item?.handle}`
              : `/shop/product/${item?.handle}`}
                   className={'text-decoration-none text-black'}>
            <div key={item?.id} className={'slide ms-1 me-1'}>
              {item?.onSale && <div className="slick-slide-title-right">
                                <span
                                    className={'bg-sale text-dark rounded-circle small p-3 bebasFont'}>
                                    Sale
                                </span>
              </div>}
              <img src={imageSrc || require('../../assets/images/default-league.png')} alt={item?.alt || 'default image'}
                   className={'img-fluid border border-5'} style={{
                height: props?.newShopItems ? 446 : 'auto',
                width: 446,
                objectFit:  props?.shopItemCarousel ? 'contain' : 'cover',
              }}/>
              {item?.bestSeller && <div className="slick-slide-label-left">
                                <span
                                    className={'bg-yellow text-blue small pt-0 ps-1 pe-1 pb-0 sfProDisplayFont'}>
                                    Best Seller
                                </span>
              </div>}
            </div>
          </NavLink>
          {
              showDetail &&
              (<div className={'container'}>
                {showPriceFirst &&
                    (<div
                        className={'text-center sfProDisplayFont font-weight-800'}>
                      {item?.oldPrice && (<span
                          className={'text-decoration-line-through me-3 small'}
                          style={{color: '#929191'}}>
                                                was £{item?.oldPrice}
                                            </span>)}
                      <span style={{color: priceColor}}>
                                                £{item?.price?.amount || '£0.00'}
                                            </span>
                    </div>)
                }
                {
                    !showPriceFirst && (
                        <div
                            className={`text-center sfProDisplayFontBold font-weight-800 ${ /*FONT_COLOR[target]*/ priceColor} `}>
                          {/*{GetConvertedPrice(item.price,store)}*/}
                          £{item?.price?.amount ? item?.price?.amount : item?.price}
                        </div>
                    )
                }
                <div
                    className={'text-center text-black robotoFont font-weight-400'}>
                  {item?.name}
                </div>
                {
                    props.showColors &&
                    (
                        <div
                            className={'text-center sfProDisplayFont font-weight-800'}>
                          {
                              item?.colors && item?.colors.map((value) => {
                                return (
                                    /*<FontAwesomeIcon icon={faCircle} color={productColorHex[value]} className={`bg-dark rounded-circle shadow-nav ms-1 me-1`} size={'1x'}
                                                     onClick={() => {
                                                         setImageSrc(item.colorImages[value])
                                                     }} style={{cursor: "pointer"}}
                                                     title={value}/>*/
                                    <div onClick={() => {
                                      setImageSrc(item?.colorImages[value]);
                                    }} style={{display: 'inline-block'}}>
                                      <ColorIcon color={value}
                                                 colorHex={productColorHex}
                                                 filters={false}/>
                                    </div>
                                );
                              })
                          }
                        </div>
                    )
                }
              </div>)
          }

          {
              // props.newShopItems && <BottomColorButtons data={props} />
          }
        </>

    );
  };

  return render();
};

ProductComponent.propTypes = {
  item: PropTypes.object,
  showColors: PropTypes.bool,
};

export default ProductComponent;
