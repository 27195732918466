export const FANZINESPORTS_HOMEPAGE_LEAGUES =
    [
      {
        leagueName: 'Premier League',
        slug: 'premier-league',
        main:true,
        leagueID: 1204,
      },
      {
        leagueName: 'La Liga',
        slug: 'la-liga',
        main:true,
        leagueID: 1399,
      },
      {
        leagueName: 'Bundesliga',
        slug: 'bundesliga',
        main:true,
        leagueID: 1229,
      },
      {
        leagueName: 'Serie A',
        slug: 'serie-a',
        main:true,
        leagueID: 1269,
      },
      {
        leagueName: 'Ligue 1',
        slug: 'ligue-1',
        main:true,
        leagueID: 1221,
      },
      {
        main:true,
        leagueID: 1005,
        slug: 'champions-league',
        leagueName: 'Champions League',
      },
      {
        main:true,
        leagueID: 1007,
        slug: 'europa-league',
        leagueName: 'Europa League',
      },
      {
        main:false,
        leagueID: 1205,
        slug: 'championship',
        leagueName: 'Championship',
      },
      {
        main:false,
        leagueID: 1352,
        slug: 'primeira-liga',
        leagueName: 'Primeira Liga',
      },
      {
        main:false,
        leagueID: 1440,
        slug: 'mls',
        leagueName: 'MLS',
      },
      {
        main:false,
        leagueID: 1531,
        slug: 'euro-qualifiers',
        leagueName: 'Euro Qualifiers',
      },
      {
        main:false,
        leagueID: 1344,
        slug: 'ekstraklasa',
        leagueName: 'Ekstraklasa',
      },
      {
        main:false,
        leagueID: 1322,
        slug: 'eredivisie',
        leagueName: 'Eredivisie',
      },
      {
        main:false,
        leagueID: 1264,
        slug: 'coppa-italia',
        leagueName: 'Coppa Italia',
      },
      {
        main:false,
        leagueID: 1226,
        slug: 'dfb-pokal',
        leagueName: 'DFB-Pokal',
      },
      {
        main:false,
        leagueID: 1198,
        slug: 'fa-cup',
        leagueName: 'FA Cup',
      },
      {
        main:false,
        leagueID: 18853,
        slug: 'uefa-europa-conference-league',
        leagueName: 'UEFA Europa Conference League',
      },
      {
        main:false,
        leagueID: 4529,
        slug:'uefa-nations-league',
        leagueName: 'UEFA Nations League',
      },
    ];
