import React from 'react';

export const SkySportsVideoItem = (props) => {


  // var reviveId = "e6404a5432b1988ea2e71ec092e8608a-fluid";
  var reviveId = "e6404a5432b1988ea2e71ec092e8608a-fluid";


  // if( window.reviveAsync && window.reviveAsync[reviveId] ){
  //   window.reviveAsync[reviveId].apply(window.reviveAsync[reviveId].detect());
  // }

  if(window.reviveAsync && window.reviveAsync[reviveId]){
    window.reviveAsync[reviveId].apply(window.reviveAsync[reviveId].detect());
  }

  return(
      <ins data-fluid-zoneid={props.zoneId} data-fluid-id={'e6404a5432b1988ea2e71ec092e8608a'}></ins>
  )

  /*return(
      <ins data-fluid-zoneid="16544" data-fluid-id="e6404a5432b1988ea2e71ec092e8608a"></ins>
  )*/


}

