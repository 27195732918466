import ReachTop from "./ReachTop";
import ReachBottom from "./ReachBottom";
const Reach = (props) => {
  const { isMobile } = props;
  const renderMain = () => {
    return (
      <>
        <ReachTop isMobile={isMobile} />
        <ReachBottom isMobile={isMobile} />
      </>
    );
  };

  return renderMain();
};

export default Reach;
