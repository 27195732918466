import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import React, {useEffect, useState} from 'react';
import {getHostname} from '../../CustomFunctions/getHostname';
import {COLORS, NAV_CLASS, TEXT_COLORS} from '../../Configs/Targets';

const MobileNewsMenuItem = (props) => {

    let title = props.title;
    let uri = props.uri;
    let isMobile = props.isMobile;
    let inactiveFontColor = typeof props.inactiveFontColor !== 'undefined' ? props.inactiveFontColor : '#707070';

    const hostName = window.location.hostname;
    const domainObject = getHostname(hostName);
    const target = domainObject.host;
    const navColorHover = NAV_CLASS[target] + '-hover-color';
    let color = props?.isFanzineSports ? '#fff' : TEXT_COLORS[target] || '000000';
    if (target === 'fanzine') {
        color = '#000000';
    }
    const textColor = color;

    const [borderBottomSelected, setBorderBottomSelected] = useState('');
    const [boldSelected, setBoldSelected] = useState('');
    const selected = props.selected;

    const handleClick = () => {
        props?.setCurrentActive(uri.toLowerCase());
    };

    useEffect(() => {
        if (selected) {
            setBorderBottomSelected(`5px solid ${isMobile ? '#FFFFFF' : color}`);
            setBoldSelected(`bold`);
        } else {
            setBorderBottomSelected('none');
            setBoldSelected(``);
        }
    }, [selected]);

    const render = () => {
        return (
            <div
                onClick={props?.handleClick? ()=> props?.handleClick(uri) : handleClick}
                className={`slider-mobile-news-menu text-left d-flex flex-column justify-content-center ${props.isMobile ? 'pt-0 me-4' : ''} ${selected && !isMobile ? textColor : 'text-white'}`}
                style={{height: props?.isGenderNavbar ? '40px' : props?.isMobile ? '45px' : `50px`, borderBottom: borderBottomSelected}}>
                {!props?.handleClick  && <Link to={uri}
                       className={`dropdown-item ${selected && !isMobile ?
                           textColor :
                           'text-white'}  text-decoration-none acuminFont h-100 d-flex flex-column justify-content-center`}>
                    <div
                        className={`flex-row justify-content-start  align-middle ${selected && !isMobile ?
                            textColor :
                            'text-white'}`}
                        style={{
                            fontWeight: boldSelected,
                            color: selected ? textColor : inactiveFontColor,
                        }}
                    >
                        {title}
                        {props.children}
                    </div>
                </Link>}
                {props?.handleClick && <div
                       className={`dropdown-item ${selected && !isMobile ?
                           textColor :
                           'text-white'}  text-decoration-none acuminFont h-100 d-flex flex-column justify-content-center`}>
                    <div
                        className={`flex-row justify-content-start  align-middle ${selected && !isMobile ?
                            textColor :
                            'text-white'}`}
                        style={{
                            fontWeight: boldSelected,
                            color: selected ? textColor : inactiveFontColor,
                        }}
                    >
                        {title}
                        {props.children}
                    </div>
                </div>
                }            </div>
        );
    };

    return render();

};

MobileNewsMenuItem.propTypes = {
    title: PropTypes.string.isRequired,
    uri: PropTypes.string.isRequired,
    inactiveFontColor: PropTypes.string,
    selected: PropTypes.bool.isRequired,
};

export default MobileNewsMenuItem;
