import {PieChart} from 'react-minimal-pie-chart';
import React from 'react';
const PossessionCircle = (props) => {
  const {value, homeColor,guestColor}= props;

  return (
      <div className={'container pb-2'}>
        <p className={'text-center font-12 sfProDisplayFont'}>Possession</p>
        <div className={'row'}>
          <div
              className={'col-2 d-flex align-items-center justify-content-center'}>
            <p className={'sfProDisplayFontBold font-18'}>{value?.home_value}%</p>
          </div>
          <div className={'col-8 d-flex justify-content-center'}>
            <PieChart
                startAngle={90}
                // paddingAngle={2}
                lengthAngle={360}
                data={[
                  {
                    title: 'home',
                    value: value?.home_value,
                    color: homeColor,
                  },
                  {
                    title: 'away',
                    value: value?.guest_value,
                    color: guestColor,
                  },
                ]}
            />
          </div>
          <div
              className={'col-2 d-flex align-items-center justify-content-center'}>
            <p className={'sfProDisplayFontBold font-18'}>{value?.guest_value}%</p>
          </div>
        </div>
      </div>
  );
};

export default PossessionCircle;
