import React, {useEffect, useState} from "react";
import {Container} from "react-bootstrap";
// import NewsCard from "./Components/NewsCard";
import FanzineNewsCard from '../../Components/Cards/FanzineNewsCard';
import {get} from "../../Axios/get";
import {CRICKET} from "../../Axios/EndPoints";
import moment from "moment-timezone";
import PropTypes from "prop-types";
import {SCREEN_SIZE} from "../../Configs/Constants";
import Loader from "../../Components/Loader/Loader";
import '../../assets/styles/customContainer.css';
import axios from "axios";
import MatchCard from "../../Components/Cards/MatchCard";
import Skeleton from "react-loading-skeleton";
import {SiteMetaHelmet} from "../../Components/SiteMetaHelmet";
import GoogleAd from "../../Components/Ads/GoogleAd";
import TopMatchesSlider from '../../Components/Slider/TopMatchesSlider';
import {SubNavbar} from '../../Components/Navbar/SubNavbar';
import {useParams} from 'react-router-dom';

const FanzineCricket = props => {
  let {team} = useParams();
  let [apiPage, setApiPage] = useState(1);
  let [apiLimit, setApiLimit] = useState(48);
  let [newsData, setNewsData] = useState([]);
  let [restrictApiCall, setRestrictApiCall] = useState(true);
  let [loading, setLoading] = useState(true);
  let [isMobile, setIsMobile] = useState(false);
  let [matchDataCricket, setMatchDataCricket] = useState([]);
  let [matchDataCricketLoading, setMatchDataCricketLoading] = useState(true);
  let [selectedTeam, setSelectedTeam] = useState(null);


  const handleScroll = (e) => {
    const pageBottom = document.body.scrollHeight - document.documentElement.clientHeight - 10 <= document.documentElement.scrollTop;
    if (pageBottom) {
      setApiPage((prevState) => {
        return prevState + 1;
      });
      setRestrictApiCall(false);
    }
  }

  const fetchNews = (isNew) => {
    setLoading(true);
    let page = `&page=${apiPage}`;
    let limit = `?limit=${apiLimit}`;

    let newsRes = get(CRICKET.NewsEndPoint + limit + page);
    let videosRes = get(CRICKET.VideoEndPoint + limit + page);

    axios.all([newsRes, videosRes])
        .then(axios.spread((responseNews, responseVideos) => {
          let tempNewsData = [];
          let tempVideosData = [];
          let tempData = [];
          responseNews.data.map((data) => {
            let news = {
              news_id: data.news_id,
              slug: data.slug,
              news_image: data.image,
              news_title: data.title,
              news_url: data.url,
              news_time: moment.tz(data.datetime, 'Europe/London').local().startOf('seconds').fromNow(),
              news_date: moment(data.datetime).format('D MMM YYYY, H:mm'),
              news_publisher: {
                publisher_name: data.publisher.name, publisher_icon: data.publisher.icon,
              },
              is_snack: data.is_snack,
              type: 'news',
            };
            tempNewsData.push(news);
          });
          responseVideos.data.map((data) => {
            let news = {
              news_id: data.news_id,
              slug: data.id,
              news_image: data.preview_image,
              news_title: data.title,
              news_url: data.url,
              news_time: moment.tz(data.published_at.date, 'Europe/London').local().startOf('seconds').fromNow(),
              news_date: moment(data.published_at.date).format('D MMM YYYY, H:mm'),
              news_publisher: {
                publisher_name: data.publisher.name,
                publisher_icon: data.publisher.icon,
              },
              is_snack: data.is_snack,
              type: 'videos',
            };
            tempVideosData.push(news);
          });

          for (let i = 0; i < tempNewsData.length; i++) {
            let ctr = i + 1;
            if (ctr % 3 === 0) {
              tempData.push(tempVideosData[i]);
            } else {
              tempData.push(tempNewsData[i]);
            }
          }

          if (isNew) {
            setNewsData(tempData);
          } else {
            setNewsData([...newsData, ...tempData]);
          }
          setLoading(false);
        }))
        .catch(error => console.log(error));
  }

  const fetchCricketFixtures = () => {
    get(CRICKET.HomepageMatchesEndPoint)
        .then((response) => {
          let tempMatchData = [];
          // eslint-disable-next-line array-callback-return
          response.data.data.map((leagues) => {
            leagues.matches.map((data) => {
              let match = {
                id: data.id,
                league_id: data.league_id,
                leagueName: data.series.name,
                state: data.state_id,
                status: data.status,
                date: data.date,
                datetime: data.datetime,
                time: data.time,
                teams: {
                  home: {
                    name: data.local_team.name,
                    icon: (data.local_team.icon).includes('cricket.png') ? require('../../assets/images/cricket_white.png') : data.local_team.icon,
                    score: data.local_team.total_score,
                  },
                  away: {
                    name: data.visitor_team.name,
                    icon: (data.visitor_team.icon).includes('cricket.png') ? require('../../assets/images/cricket_white.png') : data.visitor_team.icon,
                    score: data.visitor_team.total_score,
                  }
                },
              };
              tempMatchData.push(match);
            });
          })
          setMatchDataCricket(tempMatchData);
          setMatchDataCricketLoading(false);
        })
        .catch((err) => {
          console.error(err);
        })
  }

  useEffect(() => {
    setLoading(true);
    setRestrictApiCall(true);
    window.addEventListener('scroll', handleScroll);
    setApiPage(1);
    fetchNews();
    fetchCricketFixtures();
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    !restrictApiCall && fetchNews();
  }, [apiPage, restrictApiCall]);

  useEffect(() => {
    if (window.screen.width <= SCREEN_SIZE.MOBILE) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, [window.screen.width, isMobile]);

  const renderNews = (news, sport, isMobile) => {
    return (
        <>
          <SiteMetaHelmet sport={'Cricket'} urlEndpoint={'cricket'}/>
          <TopMatchesSlider/>
          <Container className={`${isMobile && 'mt-xxl-5 mt-xl-5 mt-lg-5 mt-md-5 mt-sm-1 mt-2'} mb-5`}>
            <div className={'customContainer'}>

              <div className="row">
                <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
                  <div className={`row`}>
                    <div className={`mt-4 col-xl-8 col-lg-8 col-md-8 col-sm-12 col-xs-12`}>
                      <FanzineNewsCard classProps={''} imageHeight={'500px'} news={news[0]} isNewsPage={true} sport={'cricket'}/>
                    </div>
                    <div className={`mt-4 col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12`}>
                      <div className={`col-12`}>
                        <FanzineNewsCard isMobile={isMobile} classProps={''} news={news[1]} sport={'cricket'}/>
                      </div>
                      <div className={`mt-2 pt-1 col-12`}>
                        <FanzineNewsCard isMobile={isMobile} classProps={''} news={news[2]} sport={'cricket'}/>
                      </div>
                    </div>
                  </div>
                  <div className={`row`}>
                    <div className={`mt-4 col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12`}>
                      <FanzineNewsCard isMobile={isMobile} classProps={''} news={news[3]} sport={'cricket'}/>
                    </div>
                    <div className={`mt-4 col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12`}>
                      <FanzineNewsCard isMobile={isMobile} classProps={''} news={news[4]} sport={'cricket'}/>
                    </div>
                    <div className={`mt-4 col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12`}>
                      <FanzineNewsCard isMobile={isMobile} classProps={''} news={news[5]} sport={'cricket'}/>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Container>
          {/*{!matchDataCricketLoading ? renderMatches(matchDataCricket, 'cricket') : renderMatchesSkeleton()}*/}
          <Container className={`mt-xxl-5 mt-xl-5 mt-lg-5 mt-md-5 mt-sm-1 mt-2 mb-5`}>
            <div className={'customContainer'}>

              <div className="row">
                <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
                  <div className={`row`}>
                    {
                      newsData.map((news, index) => {
                        if ([0, 1, 2, 3, 4, 5].includes(index)) {
                          return null;
                        }
                        return (
                            <>
                              <div className={`mt-3 pt-3 col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12`}>
                                <FanzineNewsCard isMobile={isMobile} classProps={''} news={news} isNewsPage={true} sport={'cricket'}/>
                              </div>
                              {(index + 1) % 9 === 0 ?
                                  (<div className={'billboard d-flex justify-content-center flex-column align-items-center mt-4 mb-4 col-xxl-10 col-xl-10 col-lg-10' +
                                      ' col-md-10 col-sm-10 offset-xxl-1 offset-xl-1 offset-lg-1 offset-md-1 offset-sm-1'}>
                                    <GoogleAd id={'4009154580'}/>
                                  </div>) : null}
                            </>
                        );
                      })
                    }
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </>
    );
  }

  const renderMatches = (matchData, sport) => {
    return (
        <div className={'container-fluid  mb-5'}>
          <Loader loading={loading}/>

          <div className={`slider-mobile-container ${matchData.length <= 3 ? 'justify-content-center' : 'overflow-scroll-x'}`}
          >
            {
              matchData.map((match) => (<MatchCard isMobile={isMobile} isNewsPage={false} classProps={''} singleMatch={match} sport={sport}/>))
            }
          </div>
        </div>
    );
  }

  const renderMatchesSkeleton = () => {
    return (
        <Skeleton count={9} height={'200px'} width={'250px'} inline={true} className={'ms-2'}/>
    );
  }

  const render = () => {
    return (<>
      <Loader loading={loading}/>
      <SubNavbar isMobile={isMobile} teamData={selectedTeam} isClub={team}/>
      <div className="d-flex justify-content-center align-items-center h1">
        <GoogleAd id={'4009154580'}/>
      </div>
      {newsData.length && renderNews(newsData, 'Cricket', isMobile)}

    </>);
  }

  return render();
}

FanzineCricket.propTypes =
    {
      snack: PropTypes.bool,
    }
;

export default FanzineCricket;
