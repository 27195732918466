
import React, {useEffect, useState} from 'react';
import {SCREEN_SIZE} from '../../Configs/Constants';
import {SiteMetaHelmet} from '../../Components/SiteMetaHelmet';
import {SubNavbar} from '../../Components/Navbar/SubNavbar';
import GoogleAd from '../../Components/Ads/GoogleAd';
import {useSelector} from 'react-redux';
import {useLocation} from 'react-router-dom';
import {get} from '../../Axios/get';
import {CRICKET, FANZINE, NBA, NFL} from '../../Axios/EndPoints';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faAngleUp} from '@fortawesome/free-solid-svg-icons';
import {faAngleDown} from '@fortawesome/free-solid-svg-icons/faAngleDown';
import ItemLoader from '../../Components/Loader/ItemLoader';
import {getHostname} from '../../CustomFunctions/getHostname';
import {
  COLORS,
  COLORS_RGBA,
  FONT_COLOR,
  NAV_CLASS,
  TEXT_COLOR,
} from '../../Configs/Targets';
import {Button} from 'react-bootstrap';
import RightNewsAds from '../../Components/Layout/News/RightNewsAds';

const CricketStandings = () => {
  // store
  const allSportsStore = useSelector(
      state => state.football.fanzineTeamsLeagues);

  // states
  let [isMobile, setIsMobile] = useState(false);
  let [selectedTeam, setSelectedTeam] = useState(null);
  let [fullTable, setFullTable] = useState(false);
  let [standings, setStanding] = useState(null);
  let [moreStandings, setMoreStanding] = useState(null);
  let [loading, setLoading] = useState(true);
  let [conferences, setConferences] = useState(null);
  let [selectedConference, setSelectedConferece] = useState(null);

  // hooks call
  const location = useLocation();

  // fetch data Methods
  const fetchStandingsData = () => {

    let params = '?by=conference';
    let URL = FANZINE.SportsStandingsEndPoint.replace('__sport__', 'Cricket');

    get(URL + params).then((res) => {

      let tempConferences = [];

      res?.data?.map((value) => {
        let tmpResponse = [];

        tmpResponse = value?.standings;


        tmpResponse.sort(function(a, b) {
          return b?.won - a?.won;
        });

        let temp = {
          league_name: value?.league_name,
          league_id: value?.league_id,
          standings: tmpResponse,
        };
        tempConferences.push(temp);
      });
      setSelectedConferece(tempConferences[0] ? tempConferences[0] : null);
      setConferences(tempConferences);
      setStanding(res.data);
      setLoading(false);

    }).catch(error => console.error('error =', error));
  };

  // useEffect
  useEffect(() => {
    if (window.screen.width <= SCREEN_SIZE.MOBILE) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, [window.screen.width, isMobile]);

  useEffect(() => {

    let sportName = location.pathname.split('/')[1];
    let teamName = location.pathname.split('/')[2];

    let selectedSport = [];
    let teamDataToBeSend = [];

    selectedSport = allSportsStore?.filter(sport => {
      return sport?.name?.toLowerCase() === sportName;
    });

    for (let i = 0; i < selectedSport[0]?.leagues?.length; i++) {
      for (let j = 0; j < selectedSport[0]?.leagues[i]?.teams?.length; j++) {
        if (selectedSport[0]?.leagues[i]?.teams[j].name.toLowerCase()
                .replaceAll(' ', '-') ===
            teamName) {
          teamDataToBeSend = selectedSport[0]?.leagues[i]?.teams[j];
        }
      }
    }

    Object.assign(teamDataToBeSend, {
      sportIcon: selectedSport[0]?.icon || selectedSport?.icon,
      name: teamDataToBeSend.name || 'Cricket',
    });

    setSelectedTeam(teamDataToBeSend || null);

  }, [allSportsStore]);

  useEffect(() => {
    fetchStandingsData();
  }, []);

  const handleConferenceSelect = (value) => {
    setSelectedConferece(value);
  };

  // render Methods
  const renderConferenceButtons = () => {
    return (
        <div className={isMobile ? '' : 'container'}>
          {
            conferences?.map((value, index) => (
                <Button className={isMobile ? 'p-0' : 'p-2'}
                        key={index}
                        onClick={() => handleConferenceSelect(value)}
                        style={{
                          borderRadius: '21px',
                          fontSize: isMobile ? '12px' : '14px',
                          height: isMobile ? '37px' : '42px',
                          width: isMobile ? '137px' : '200px',
                          margin: isMobile ?
                              '20px 1px 20px 14px' :
                              '32px 20px 41px 11px',
                          padding: isMobile ? '11px 21px' : '12px 71px 11px',
                          backgroundColor: selectedConference?.league_name ===
                          value?.league_name ? '#74dbef' : '#f3f3f3',
                          border: selectedConference?.league_name !==
                          value?.league_name ?
                              'solid 0.5px #bebebe' :
                              '0px solid transparent',
                        }}
                        variant={'outline-dark'}
                >
                  {value?.league_name}
                </Button>
            ))
          }
        </div>);
  };
  const renderMobilePartialTable = (standingBgColor, textColor, color) => {
    return (
        <div className={'w-100 ms-auto me-auto'}>
          {/*<div className={`bebasFont font-weight-800 text-header`}*/}
          {/*     style={{fontSize: '30px'}}>*/}
          {/*  {'English Premier League'}*/}
          {/*</div>*/}
          {renderConferenceButtons()}
          <table className={'pb-2 mb-2'}>
            <thead>
            <tr
                style={{
                  color: '#a3a3a3',
                }}
                className={`bg-white font-14 rounded-bottom-custom text-center sfProDisplayFont small`}>
              <td>#</td>
              <td>Teams</td>
              <td>W</td>
              <td>L</td>
              <td>T</td>
              <td>PCT</td>
              <td>STRK</td>
            </tr>
            </thead>
            <tbody>
            {(selectedConference !== null &&
                selectedConference?.standings)?.map((value, index) => (
                <>
                  <tr className={`text-light-black sfProDisplayFont small`}
                      style={{backgroundColor: 'white'}}>
                    <td className={`text-center p-2 font-14 `}><span
                        className={'sfProDisplayFont font-14'}>{index + 1}.</span>
                    </td>
                    <td>
                      <img src={value.team.icon} height={22} alt=""/>
                      <span
                          className={'sfProDisplayFont ms-2 font-14'}>{value.team.short_name}</span>
                    </td>
                    <td className={`text-center p-2 font-14`}>{value?.won}</td>
                    <td className={`text-center p-2 font-14`}>{value?.lost}</td>
                    <td className={`text-center p-2 font-14`}>
                      {value?.ties}
                    </td>
                    <td className={`text-center p-2 font-14`}>{value?.win_percentage}</td>
                    <td className={`text-center p-2 font-14`}>{value?.streak}</td>
                  </tr>
                </>
            ))}
            </tbody>
          </table>
          {moreStandings?.length &&
              <li className={`p-3 bg-white text-end sfProDisplayFontBold ${textColor} rounded-bottom-custom btn`}
                  onClick={() => {
                    setFullTable(!fullTable);
                  }}
              >
                {fullTable ? 'Collapse' : 'Full Table'} &nbsp;&nbsp;&nbsp;
                <FontAwesomeIcon icon={fullTable ? faAngleUp : faAngleDown}
                                 color={color} size={'1x'}/>
              </li>}
        </div>
    );
  };
  const renderMobileFullTable = (standingBgColor, textColor, color) => {
    return (
        <>
          <div className={`bebasFont font-weight-800 text-header`}
               style={{fontSize: '30px'}}>
            {'English Premier League'}
          </div>
          <div className={`mt-3`}>

          </div>
          <table>
            <thead>
            <tr className={`bg-fixtures rounded-bottom-custom text-center sfProDisplayFont small`}>
              <td></td>
              <td style={{width: '130px'}}>Teams</td>
              <td>P</td>
              <td>W</td>
              <td>D</td>
              <td>L</td>
              <td>F/A</td>
              <td>GD</td>
              <td>Pts</td>
            </tr>
            </thead>
            <tbody>
            {moreStandings?.length &&
                moreStandings?.map(((value, index, array) => (
                    <tr className={`text-light-black sfProDisplayFont small`}
                        style={value.background}>
                      <td className={`ps-1 text-center`}><span
                          className={'sfProDisplayFont'}>{value.position}. &nbsp;</span>
                      </td>
                      <td className={`p-2`}>
                        <img src={value.team.icon} height={20}
                             alt=""/>
                        <span className={'sfProDisplayFont ms-2'}>
                                                        &nbsp; {value.team.short_name}
                                                    </span>
                      </td>
                      <td className={`text-center`}>{value.pl}</td>
                      <td className={`text-center`}>{value.win}</td>
                      <td className={`text-center`}>{value.draw}</td>
                      <td className={`text-center`}>{value.lose}</td>
                      <td className={`text-center`}>{value.gd2}</td>
                      <td className={`text-center`}>{value.gd}</td>
                      <td className={`text-center`}>{value.pts}</td>
                    </tr>
                )))}

            </tbody>
          </table>
          {moreStandings?.length &&
              <li className={`p-3 bg-white text-end sfProDisplayFontBold ${textColor} rounded-bottom-custom btn`}
                  onClick={() => {
                    setFullTable(!fullTable);
                    window.scroll({
                      top: 1850,
                      behavior: 'smooth',
                    });
                  }}
              >
                {fullTable ? 'Collapse' : 'See full table'} &nbsp;&nbsp;&nbsp;
                <FontAwesomeIcon icon={fullTable ? faAngleUp : faAngleDown}
                                 color={color}
                                 size={'1x'}/>
              </li>}
          {/**/}
        </>
    );
  };

  const renderLaptop = (
      standingBgColor, textColor, color, navColor, fontColor, Value, ind) => {

    return (
        <div className={'container'}>
          <div className={` rounded-custom-1 text-start ps-5`}>
                        <span className={`${fontColor} text-header`}>
                            <span
                                className={'sfProDisplayFont font-28 text-black'}
                                style={{fontWeight: '800'}}
                            >
                                        {selectedConference?.standings ?
                                            selectedConference?.league_name :
                                            ''
                                        }
                            </span>
                          {/*To DO add Later
                            <FontAwesomeIcon icon={faSortDown} color={'#FFFFFF'} size={'1x'} style={{verticalAlign: '0'}}/>*/}
                        </span>
          </div>

          <div className="col-12 border-1 border-secondary rounded">
            {renderConferenceButtons()}
            <ul className={'bg-fixtures rounded-bottom-custom text-center standings-list list-unstyled p-0'}>
              <ItemLoader loading={loading}/>
              <li className={'bg-white p-2'}>
                <div className={'row p-1'}>
                  <div
                      className={'col-1 text-start text-light-black sfProDisplayFontBold text-nowrap font-12'}>
                    #
                  </div>
                  <div
                      className={'col-1  text-start text-light-black sfProDisplayFontBold text-nowrap font-12'}>
                    Team
                  </div>
                  <div
                      className={'col text-center text-light-black sfProDisplayFontBold font-12 ms-4'}>
                    W
                  </div>
                  <div
                      className={'col text-center text-light-black sfProDisplayFontBold font-12 m-0 p-0'}>L
                  </div>
                  <div
                      className={'col text-center text-light-black sfProDisplayFontBold font-12 m-0 p-0'}>T
                  </div>
                  <div
                      className={'col text-center text-light-black sfProDisplayFontBold font-12 m-0 ps-0'}>PCT
                  </div>
                  <div
                      className={'col text-center text-light-black sfProDisplayFontBold font-12 m-0 ps-0'}>PF
                  </div>
                  <div
                      className={'col text-center text-light-black sfProDisplayFontBold font-12 m-0 ps-0'}>PA
                  </div>
                  <div
                      className={'col text-center text-light-black sfProDisplayFontBold font-12 m-0 ps-0'}>Home
                  </div>
                  <div
                      className={'col text-center text-light-black sfProDisplayFontBold font-12 m-0 ps-0'}>Away
                  </div>
                  <div
                      className={'col text-center text-light-black sfProDisplayFontBold font-12 m-0 ps-0'}>Streak
                  </div>
                </div>
              </li>
              {
                (selectedConference !== null &&
                    selectedConference?.standings)?.map(
                    ((value, index) => {
                      let bgColor = '';
                      return (
                          <li key={index}
                              className={` ${index + 1 ===
                              (value?.team?.name).length ?
                                  'rounded-bottom-custom' :
                                  ''} ${bgColor === '' && 'bg-white'} p-2`}
                              style={{
                                background: bgColor !== '' &&
                                    value?.team?.hex_color,
                                color: bgColor !== '' &&
                                    value?.team?.font_color,
                              }}
                          >
                            <div className={'row p-1'}>
                              <div
                                  className={`col-1 ${bgColor === '' &&
                                  'text-dark'} text-start text-nowrap font-12 `}>
                                {index + 1 || value?.position}.
                              </div>
                              <div
                                  className={'col-1  text-start text-light-black sfProDisplayFontBold text-nowrap font-12'}>

                                <img
                                    width={27}
                                    style={{objectFit: 'contain'}}

                                    src={value?.team?.icon} height={20} alt=""/>
                                <span className={'sfProDisplayFont'}
                                      style={{fontWeight: '500'}}
                                >
                                                        &nbsp; {value?.team?.name}
                                                    </span>
                              </div>
                              <div
                                  className={'col text-center text-light-black sfProDisplayFontBold font-12 ms-4'}>
                                {value?.won}
                              </div>
                              <div
                                  className={'col text-center text-light-black sfProDisplayFontBold font-12 m-0 p-0'}>
                                {value?.lost}
                              </div>
                              <div
                                  className={'col text-center text-light-black sfProDisplayFontBold font-12 m-0 p-0'}>
                                {value?.ties}
                              </div>
                              <div
                                  className={'col text-center text-light-black sfProDisplayFontBold font-12 m-0 ps-0'}>
                                {value?.win_percentage}
                              </div>
                              <div
                                  className={'col text-center text-light-black sfProDisplayFontBold font-12 m-0 ps-0'}>
                                {value?.points_for}
                              </div>
                              <div
                                  className={'col text-center text-light-black sfProDisplayFontBold font-12 m-0 ps-0'}>
                                {value?.points_against}
                              </div>
                              <div
                                  className={'col text-center text-light-black sfProDisplayFontBold font-12 m-0 ps-0'}>{value?.home_record}
                              </div>
                              <div
                                  className={'col text-center text-light-black sfProDisplayFontBold font-12 m-0 ps-0'}>{value?.road_record}
                              </div>
                              <div
                                  className={'col text-center text-light-black sfProDisplayFontBold font-12 m-0 ps-0'}>{value?.streak}
                              </div>
                            </div>
                          </li>
                      );
                    }))
              }
              {
                  moreStandings?.length &&
                  moreStandings?.map(((value, index, array) => {
                    return (
                        <li className={`p-3 ${fullTable ? '' : 'd-none'}`}>
                          <div className={'row'}>
                            <div
                                className={'col-4 text-start text-dark text-nowrap font-14'}>
                              <span
                                  className={'sfProDisplayFont'}>{value.position}. &nbsp;</span>
                              <img src={value.team.icon} height={20} alt=""/>
                              <span className={'sfProDisplayFont'}>
                                                &nbsp; {value.team.name}
                                            </span>
                            </div>
                            <div className={'col text-end text-light-black'}>
                              {() => {
                                if (value.arrow === 'up') {
                                  return (<FontAwesomeIcon icon={faAngleUp}
                                                           color={'#0e7d45'}
                                                           size={'1x'}/>);
                                }
                                if (value.arrow === 'down') {
                                  return (
                                      <FontAwesomeIcon icon={faAngleDown}
                                                       color={'#e02020'}
                                                       size={'1x'}/>);
                                }
                              }}
                            </div>
                            <div
                                className={'col text-end text-light-black sfProDisplayFont font-14'}>{value.mp}</div>
                            <div
                                className={'col text-end text-light-black sfProDisplayFont font-14'}>{value.gd}</div>
                            <div
                                className={'col text-end text-light-black sfProDisplayFont font-14'}>{value.pts}</div>
                          </div>
                        </li>
                    );
                  }))
              }
              {/*{moreStandings.length &&
                            <li className={`p-3 bg-white text-center sfProDisplayFontBold ${textColor} rounded-bottom-custom btn`}
                                onClick={() => {
                                    setFullTable(!fullTable)
                                }}
                            >
                                {fullTable ? 'Collapse' : 'See full table'} &nbsp;&nbsp;&nbsp;
                                <FontAwesomeIcon icon={fullTable ? faAngleUp : faAngleDown} color={color}
                                                 size={'1x'}/>
                            </li>}*/}
            </ul>
          </div>
        </div>
    );
  };

  const withData = () => {
    const hostName = window.location.hostname;
    const domainObject = getHostname(hostName);
    const target = domainObject.host;
    const navColor = NAV_CLASS[target];
    const textColor = TEXT_COLOR[target];
    const fontColor = FONT_COLOR[target];
    const color = COLORS[target];
    const standingBgColor = COLORS_RGBA[target] + '0.3)';

    let isGroup = standings && standings[0]?.matches !== [];
    let tmp = [];
    if (standings !== null && standings !== undefined) {
      if (standings && isGroup) {
        let arr = [];
        let allGroups = standings.map((item) => item?.matches); //gets all groups if any

        for (let i = 0; i < standings?.length; i++) {
          if (standings[i]?.matches === allGroups[i]) {
            arr.push(standings[i]);
            if (arr.length === 4) {
              tmp.push(arr);
              arr = [];
            }
          }
        }
      } else if (standings && !isGroup) {
        let arr = [];
        for (let i = 0; i < standings?.length; i++) {

          arr.push(standings[i]);
        }
        tmp.push(arr);
      }

    }

    const renderDefault = () => {

      return ((isMobile && standings) ?
          !fullTable ?
              renderMobilePartialTable(standingBgColor, textColor, color) :
              renderMobileFullTable(standingBgColor, textColor, color) :
          renderLaptop(standingBgColor, textColor, color, navColor,
              fontColor, standings));
    };

    const renderGroups = () => {

      return (
          standings && standings.map((value, index) => (
              <>
                {
                  renderLaptop(standingBgColor, textColor, color, navColor,
                      fontColor, value, index)
                }
              </>
          ))
      );
    };

    return loading ?
        (
            <ItemLoader loading={loading}/>
        )
        : (
            <div className={isMobile ? '' : 'container'}>
              <div className={isMobile ? '' : 'row'}>
                <div className={isMobile ? '' : 'col-8'}>
                  {tmp?.length === 0 && renderDefault()}
                  {tmp?.length > 0 && renderGroups()}
                </div>
                {!isMobile && <RightNewsAds
                    sportEndpoint={CRICKET.NewsEndPoint}
                    isMobile={isMobile}
                    limit={4}
                />}

              </div>
            </div>
        );
  };

  const renderMain = () => {
    return (
        <div>
          <SiteMetaHelmet sport={'CRICKET'} urlEndpoint={'cricket'}/>
          {/*<Loader loading={loading}/>*/}
          <SubNavbar isMobile={isMobile} teamData={selectedTeam}/>
          <div className="d-flex justify-content-center align-items-start h1">
            <GoogleAd id={'4009154580'}/>
          </div>
          {standings !== null && standings !== undefined && withData()}
        </div>
    );
  };
  return renderMain();
};

export default CricketStandings;
