import {Helmet} from "react-helmet";

export const SiteMetaHelmet = (props) => {
    return (
        <Helmet>
            <title>Fanzine - Latest {props.sport} Sports News</title>
            <meta content={`Fanzine: Latest ${props.sport} Sports News`} name={`name`}/>
            <meta content={`%PUBLIC_URL%/logo192.png`} name={`image`}/>
            <meta content={`Fanzine - Latest ${props.sport} news, scores, results and rumors. Live scores, standings and stats for the ${props.sport} and around the world.`} name={`description`}/>

            <meta content={`https://fanzine.com/${props.urlEndpoint}`} property={`og:url`}/>
            <meta content={`website`} property={`og:type`}/>
            <meta content={`Fanzine: Latest ${props.sport} Sports News`} property={`og:title`}/>
            <meta content={`Fanzine - Latest ${props.sport} news, scores, results and rumors. Live scores, standings and stats for the ${props.sport} and around the world.`}
                  property={`og:description`}/>
            <meta content={`%PUBLIC_URL%/logo192.png`} property={`og:image`}/>

            <meta content={`summary_large_image`} name={`twitter:card`}/>
            <meta content={`Fanzine: Latest ${props.sport} Sports News`} name={`twitter:title`}/>
            <meta content={`Fanzine - Latest ${props.sport} news, scores, results and rumors. Live scores, standings and stats for the ${props.sport} and around the world.`}
                  name={`twitter:description`}/>
            <meta content={`%PUBLIC_URL%/logo192.png`} name={`twitter:image`}/>
        </Helmet>
    )
}