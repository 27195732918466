import React from 'react';
import {Form} from 'react-bootstrap';

const ReusableCheckbox = (props) => {
  const {
    checkboxName,
    id,
    className,
    selectedRadio,
    setSelectedRadio,
  } = props;

  const handleCheck = (e) => {
    setSelectedRadio(e.target.value);

  };

  return (
      <div className={'d-flex align-items-center mb-3 opacity-100'}>
        <Form.Check
            value={checkboxName}
            checked={selectedRadio === checkboxName}
            onClick={handleCheck}
            label={checkboxName}
            type={'radio'}
            className={` ${className} text-dark `}
            id={`${checkboxName}-${id}`}
        />
      </div>
  );
};

export default ReusableCheckbox;
