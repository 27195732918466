import React, {useEffect, useState} from 'react';
import Card from 'react-bootstrap/Card';
import './styles/NavbarCard.css';
import '../../assets/styles/styles.css';
import PropTypes from 'prop-types';
import HTMLEllipsis from 'react-lines-ellipsis/lib/html.modern';
import {getHostname} from '../../CustomFunctions/getHostname';
import {FONT_COLOR} from '../../Configs/Targets';
import {useDispatch} from 'react-redux';
import moment from "moment-timezone";
import {Link} from "react-router-dom";

const PodcastCard = (props) => {

    const {isMobile, isClub} = props;
    let dispatch = useDispatch();

    let [isOpen, setIsOpen] = useState(false);
    let [stopMain, setStopMain] = useState(true);
    let [podcastData, setPodcastData] = useState(props.podcast);

    const hostName = window.location.hostname;
    const domainObject = getHostname(hostName);
    const target = domainObject.host;
    const textColor = props?.league?.font_color ? props?.league?.font_color : FONT_COLOR[target];

    const addDefaultSrc = (ev) => {
        ev.target.src = require('../../assets/images/default_publisher_icon.png');
    };

    useEffect(() => {
        setPodcastData(props.podcast);
    }, [props.podcast]);

    const renderClub = (extraClass, podcastHeight, titleClass, bottomClass, publisherImageHeight, titleWidth) => {
        return (
            <Link to={`/podcasts/${podcastData?.id}`}
                  className={`text-decoration-none text-black cursor-pointer ${extraClass} mb-3 shadow`}>
                <Card
                    style={{
                        width: props.isFanzineAllFootball && (isMobile ? '170px' : '360px'),
                    }}
                    className="col border-0 bg-transparent">
                    <img src={podcastData.image_url}
                         onError={addDefaultSrc}
                         style={{objectFit: props.isFanzineAllFootball && 'cover', height: 'auto'}}
                         alt={'Fanzine'}/>
                </Card>
                <div className="row p-3 shadow mb-3">
                    <div className={`justify-content-between col-12`}>
                        <div>
                            <HTMLEllipsis
                                unsafeHTML={podcastData?.title}
                                maxLine="2"
                                ellipsis="..."
                                basedOn="letters"
                                style={{
                                    height: '3.5em',
                                    color: '#000000' || props?.league?.font_color ? props?.league?.font_color : FONT_COLOR[target],
                                    marginBottom: '0.25em',
                                    fontsize: '13px'
                                }}
                                className={`sfProDisplayFont font-22 text-start text-wrap ${'text-black' || textColor}`}
                            />
                        </div>
                        <div className={`${titleClass} ${titleWidth} d-flex justify-content-start`}>
                            <img src={podcastData.image_url}
                                 onError={addDefaultSrc}
                                 style={{objectFit: 'contain', height: '28px'}}
                                 alt={'Fanzine'}/>
                            <span
                                dangerouslySetInnerHTML={{__html: podcastData.publisher_name}}
                                style={{
                                    height: '2em',
                                    color: props?.league?.font_color ? props?.league?.font_color : FONT_COLOR[target],
                                    className: `sfProDisplayFont ${textColor}`,
                                }}
                                className={`${isMobile ? 'sfProDisplayFont font-12' : 'sfProDisplayFont font-14'} ${textColor} w-fit-content text-nowrap align-self-center ps-2 pt-1`}
                            />
                            <div
                                style={{
                                    color: props?.league?.font_color ? props?.league?.font_color : FONT_COLOR[target],
                                }}
                                className={`${isMobile ? 'sfProDisplayFont font-12' : 'sfProDisplayFont font-14'} ${textColor} text-end w-100 align-self-center pt-1`}
                            >
                                {moment.tz(podcastData?.published_at.date, 'Europe/London')
                                    .local()
                                    .startOf('seconds')
                                    .fromNow().replaceAll('ago', '')}
                            </div>
                        </div>
                    </div>
                </div>
            </Link>
        );
    }

    const render = () => {
        let extraClass = props.extraClass;
        let podcastHeight = props.podcastHeight;
        let isMain = typeof props.main !== 'undefined';
        // let isMobile = typeof props.isMobile !== 'undefined';
        let titleClass = isMain && !isMobile ? 'h3' : 'small';
        let bottomClass = isMain && !isMobile ? 'h5' : 'small';
        let publisherImageHeight = isMain && props.isMobile ? 30 : 15;
        let titleWidth = isMobile && isMain ? 'w-100' : 'w-100';
        if (!podcastData) {
            return (<></>);
        }

        if (isClub) {
            return renderClub(extraClass, podcastHeight, titleClass, bottomClass, publisherImageHeight, titleWidth);
        }

        return (
            <div to={`/${props.sport || 'football'}/podcast/${podcastData?.id}`}
                 className={`text-decoration-none text-black cursor-pointer ${extraClass}`}>
                <Card
                    style={{
                        width: props.isFanzineAllFootball && (isMobile ? '170px' : '360px'),
                    }}
                    className="col border-0 bg-transparent">
                    <img src={podcastData.image_url}
                         onError={addDefaultSrc}
                         style={{objectFit: props.isFanzineAllFootball && 'cover', height: podcastHeight}}
                         alt={'Fanzine'}/>
                </Card>
                <div className="row p-2">
                    <div className={`justify-content-between ${isMain ?
                        'col-9' :
                        'col-12'}`}>
                        <div>
                            <HTMLEllipsis
                                unsafeHTML={podcastData?.title}
                                maxLine="2"
                                ellipsis="..."
                                basedOn="letters"
                                style={{
                                    height: '3.5em',
                                    color: props?.league?.font_color ? props?.league?.font_color : FONT_COLOR[target],
                                    className: `roboto-medium ${textColor}`,
                                    marginBottom: '1em',
                                    fontsize: '13px'
                                }}
                                className={`robotoFont text-start text-wrap ${textColor}`}
                            />
                        </div>
                        <div className={`${titleClass} ${titleWidth} d-flex justify-content-between`}>
							<span
                                dangerouslySetInnerHTML={{__html: podcastData.publisher_name}}
                                style={{
                                    height: '2em',
                                    color: props?.league?.font_color ? props?.league?.font_color : FONT_COLOR[target],
                                    className: `robotoFont ${textColor}`,
                                }}
                                className={`${isMobile ? 'robotoFont font-12' : 'robotoFont font-14'} ${textColor}`}
                            />
                            {
                                props.isFanzineAllFootball &&
                                <span
                                    style={{
                                        color: props?.league?.font_color ? props?.league?.font_color : FONT_COLOR[target],
                                    }}
                                    className={`${isMobile ? 'robotoFont font-12' : 'robotoFont font-14'} ${textColor}`}
                                >
									{moment.tz(podcastData?.published_at.date, 'Europe/London')
                                        .local()
                                        .startOf('seconds')
                                        .fromNow().replaceAll('ago', '')}
                                        </span>
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    return render();
};

PodcastCard.propTypes = {
    podcastHeight: PropTypes.string.isRequired,
    main: PropTypes.bool,
    extraClass: PropTypes.string.isRequired,
    isMobile: PropTypes.bool,
    isClub: PropTypes.bool,
    podcast: PropTypes.object,
};

export default PodcastCard;
