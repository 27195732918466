import {FootballLeagueActionTypes} from "../constants/FootballLeaguesActionTypes";

const initialState = {
    footballLeagues: [],
    navbarLeagues: [],
    navbarTeamTypes: [],
    tablaLeagues: null,
    defaultLeague: null,
    currentTeamId: null,
    currentTeamName: null,
    fanzineTeamsLeagues: [],
    fanzineLeagues: [],
    singleFootballMatch: [],
    navbarTeamSlugsId: [],
}

export default function footballLeagueReducer(state = initialState, action) {
    switch (action.type) {
        case FootballLeagueActionTypes.SET_FOOTBALL_LEAGUE:
            return {...state, footballLeagues: action.payload};
        case FootballLeagueActionTypes.SET_NAVBAR_LEAGUES:
            return {...state, navbarLeagues: action.payload};
        case FootballLeagueActionTypes.SET_NAVBAR_TEAM_TYPES:
            return {...state, navbarTeamTypes: action.payload};
        case FootballLeagueActionTypes.SET_TABLE_LEAGUES:
            return {...state, navbarLeagues: action.payload};
        case FootballLeagueActionTypes.SET_DEFAULT_LEAGUES:
            return {...state, defaultLeague: action.payload};
        case FootballLeagueActionTypes.SET_CURRENT_TEAM_ID:
            return {...state, currentTeamId: action.payload};
        case FootballLeagueActionTypes.SET_CURRENT_TEAM_NAME:
            return {...state, currentTeamName: action.payload};
        case FootballLeagueActionTypes.SET_NAVBAR_LEAGUES_TEAMS_FANZINE:
            return {...state, fanzineTeamsLeagues: action.payload};
        case FootballLeagueActionTypes.SET_NAVBAR_TEAM_SLUG_ID:
            return {...state, navbarTeamSlugsId: action.payload};
        case FootballLeagueActionTypes.SET_FANZINE_LEAGUES:
            return {...state, fanzineLeagues: action.payload};
        case FootballLeagueActionTypes.SET_SINGLE_FOOTBALL_MATCH:
            return {...state, singleFootballMatch: action.payload};

        default:
            return state;
    }
}

/*
export function navBarLeaguesReducer(state = initialState, action) {
    switch (action.type) {
        case FootballLeagueActionTypes.SET_NAVBAR_LEAGUES:
            return {...state, navbarLeagues: action.payload};
        default:
            return state;
    }
}

export function tableLeaguesReducer(state = initialState, action) {
    switch (action.type) {
        case FootballLeagueActionTypes.SET_TABLE_LEAGUES:
            return {...state, navbarLeagues: action.payload};
        default:
            return state;
    }
}

export function defaultLeagueReducer(state = initialState, action) {
    switch (action.type) {
        case FootballLeagueActionTypes.SET_DEFAULT_LEAGUES:
            return {...state, defaultLeague: action.payload};
        default:
            return state;
    }
}*/
