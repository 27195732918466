import React, {Component} from "react";
import '../../assets/styles/snack_ads.css';

class Billboard extends Component {

    componentDidMount() {
        (window.units = window.units || []).push({});
        try {
            window.killSlot([this.props.id]);
            window.refreshBid([this.props.id]);
        } catch (e) {
            
        }
    }

    render() {
        return (
            <div className={'billboard'}>
                <div id={this.props.id}/>
            </div>
        );
    };

}

export default Billboard;