import {useState} from 'react';
import Social from '../../Layout/Social/Social';

const SingleFootballMatchContentSocial = (props) =>
{
  const [isSocial, setSocial] = useState(true);
  const [loading,setLoading] = useState(true)
  const isCurrent = props.currentActive === 'social';

  return(
      <div>
        <Social
            isSinglePage={true}
            isFanzine={true}
            isNewsPage={true}
            isCurrent={isCurrent}
            isSocial={isSocial}
            setLoading={setLoading}
        />
      </div>
  )

}

export default SingleFootballMatchContentSocial;
