import React, {useEffect, useState} from "react";
import "./styles/custom.css";
import {FONT_COLOR, NAV_CLASS} from "../../Configs/Targets";
import "../../assets/styles/footballTargets.css";
import {MATCH_STATES, SCREEN_SIZE} from "../../Configs/Constants";
import {get} from "../../Axios/get";
import {HomepageFixtures} from "../../Axios/EndPoints";
import moment from "moment";
import {getHostname} from "../../CustomFunctions/getHostname";
import {useDispatch} from "react-redux";
import {setCurrentTeamId, setCurrentTeamName,} from "../../redux/actions/FooballLeagueActions";
import ItemLoader from "../Loader/ItemLoader";
import Card from "react-bootstrap/Card";
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronRight} from "@fortawesome/free-solid-svg-icons/faChevronRight";
import BlueShirt from '../../assets/images/blue-shirt.png';
import {Col, Row} from "react-bootstrap";

const ResultsFixtures = (props) => {
    const dispatch = useDispatch();

    let isFanzineAllFootball = props.isFanzineAllFootball;
    let setNextMatch = props.setNextMatch;
    let setHomePageStandings = props.setHomePageStandings;

    let [screenWidth, setScreenWidth] = useState(window.innerWidth);
    let [fixtures, setFixtures] = useState([]);
    let [loading, setLoading] = useState(true);

    const fetchFixtures = () => {
        get(HomepageFixtures + "?web=1")
            .then((response) => {

                if (isFanzineAllFootball) {
                    setNextMatch(response.data.data);
                    setLoading(false);
                } else {
                    let prevMatch = response.data.data[0].prev_match;
                    let matches = response.data.data[0].matches;
                    let teamId = response.data.data[0].current_team_id;
                    let teamName = response.data.data[0].current_team_name;
                    dispatch(setCurrentTeamId(teamId));
                    dispatch(setCurrentTeamName(teamName));
                    let tempFixtures = [];
                    tempFixtures.push({
                        id: prevMatch.id,
                        football_api_id: prevMatch.football_api_id,
                        league: {
                            id: prevMatch.league_id,
                            name: prevMatch.league_name,
                        },
                        opponent: {
                            name:
                                prevMatch.home_team.id === teamId
                                    ? prevMatch.guest_team.name
                                    : prevMatch.home_team.name,
                            icon:
                                prevMatch.home_team.id === teamId
                                    ? prevMatch.guest_team.club_logo
                                        ? prevMatch.guest_team.club_logo
                                        : prevMatch.guest_team.icon
                                    : prevMatch.home_team.club_logo
                                        ? prevMatch.home_team.club_logo
                                        : prevMatch.home_team.icon,
                        },
                        home: {
                            name: prevMatch.home_team.name,
                            icon: prevMatch.home_team.club_logo
                                ? prevMatch.home_team.club_logo
                                : prevMatch.home_team.icon,
                        },
                        away: {
                            name: prevMatch.guest_team.name,
                            icon: prevMatch.guest_team.club_logo
                                ? prevMatch.guest_team.club_logo
                                : prevMatch.guest_team.icon,
                        },
                        venue: prevMatch.home_team.id === teamId ? "(H)" : "(A)",
                        color: MATCH_STATES.IN_PLAY.includes(prevMatch.state)
                            ? ""
                            : prevMatch.win_team_id === teamId
                                ? "#0e7d45"
                                : prevMatch.win_team_id === 0
                                    ? ""
                                    : "#e02020",
                        total: prevMatch.total,
                        state: prevMatch.state,
                        date: prevMatch.date,
                        dateTime: prevMatch.datetime,
                        formattedDate: moment(prevMatch.datetime).format("ddd MMM D"),
                        formattedTime: moment(prevMatch.datetime).format("H:mm"),
                        isPrevious: true,
                    });
                    matches.reverse().map((data) =>
                        tempFixtures.push({
                            id: data.id,
                            football_api_id: data.football_api_id,
                            league: {
                                id: data.league_id,
                                name: data.league_name,
                            },
                            opponent: {
                                name:
                                    data.home_team.id === teamId
                                        ? data.guest_team.name
                                        : data.home_team.name,
                                icon:
                                    data.home_team.id === teamId
                                        ? data.guest_team.club_logo
                                            ? data.guest_team.club_logo
                                            : data.guest_team.icon
                                        : data.home_team.club_logo
                                            ? data.home_team.club_logo
                                            : data.home_team.icon,
                            },
                            home: {
                                name: data.home_team.name,
                                icon: data.home_team.club_logo
                                    ? data.home_team.club_logo
                                    : data.home_team.icon,
                            },
                            away: {
                                name: data.guest_team.name,
                                icon: data.guest_team.club_logo
                                    ? data.guest_team.club_logo
                                    : data.guest_team.icon,
                            },
                            venue: data.home_team.id === teamId ? "(H)" : "(A)",
                            total: data.total,
                            state: data.state,
                            date: data.date,
                            dateTime: data.datetime,
                            formattedDate: moment(data.datetime).format("ddd MMM D"),
                            formattedTime: moment(data.datetime).format("H:mm"),
                        })
                    );
                    console.log("tempFixtures123: ", tempFixtures);
                    setFixtures(tempFixtures);
                    // setNextMatch(response.data.data[0].next_match);
                    // setHomePageStandings(response.data.data[0].standings.standings);
                    setLoading(false);
                }
            })
            .catch((err) => {
                console.error(err);
            });
    };

    useEffect(() => {
        setScreenWidth(window.innerWidth);
    }, [screenWidth]);

    useEffect(() => {
        fetchFixtures();
    }, []);

    const renderMatchOld = (value, scoreFontSize) => {
        return (
            <>
                <li className={`p-3`}>
                    <div className={"row"}>
                        <div
                            className={`col-3 text-center mt-2 mb-2 text-light-black sfProDisplayFont font-16 font-weight-500`}
                        >
                            {/*{value.formattedDate} &nbsp;| &nbsp;{value.league.name}*/}
                            <img src={value.opponent.icon} width="45" alt=""/>
                        </div>
                        <div
                            className={`col text-start sfProDisplayFont ${scoreFontSize}`}
                            style={{color: value.color}}
                        >
                            <div className={`sfProDisplayFontBold font-14`}>
                                {value.formattedDate}
                            </div>
                            <div
                                className={`sfProDisplayFont font-14 `}
                                style={value.isPrevious ? {} : {color: "#969696"}}
                            >
                                {value.formattedTime}
                                &nbsp;&nbsp;|&nbsp;&nbsp;{value.league.name}
                                <br/>
                                {value.isPrevious &&
                                    value.venue === "(A)" &&
                                    value.opponent.name + " "}
                                {value.isPrevious && `${value.total}\t`}
                                {(!value.isPrevious || value.venue === "(H)") &&
                                    value.opponent.name + " "}
                                {value.venue}
                            </div>
                            {/*<img src={value.home.icon} height={10} alt=""/>*/}

                            {/*<img src={value.opponent.icon} height={10} alt=""/>*/}

                            {/*<br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{value.isPrevious && ('(' + value.total + ')')}*/}
                        </div>
                    </div>
                    {/*<div className={`divider mt-3`}/>*/}
                </li>
            </>
        );
    }

    const renderMatch = (value) => {
        return (
            <Card className="w-100 rounded-0 p-0 mb-2 border-0 shadow-nav mb-4">
                <Card.Body className={'w-100 pb-0'}>
                    <div className="mb-0">
                        <Card.Subtitle className="mb-3 mt-1 text-center text-gray ps-0 sfProDisplayFont font-12">
                            <Row>
                                <Col></Col>
                                <Col className={'text-center text-nowrap'}>
                                    {value?.league?.name}
                                </Col>
                                <Col className={'text-end'}>
                                    {moment(value?.date).format('ddd DD MMM')}
                                </Col>
                            </Row>
                        </Card.Subtitle>
                        <div className="card-text text-center d-flex justify-content-center align-items-center row">
                                <div className="col-4 text-center d-flex justify-content-end align-items-center">
                                    <div className={'text-black sfProDisplayFont font-14  me-2'}>
                                        {value?.home?.name}
                                    </div>
                                    <img src={value?.home?.icon || BlueShirt}
                                         width="29"
                                         height={'29'} style={{objectFit: 'contain'}} alt={'home team icon'}/>
                                </div>
                                <div className="col-2 text-center d-flex justify-content-center align-items-center w-fit">
                                    <div className={'text-black sfProDisplayFont font-18'}>
                                        {MATCH_STATES.FINISHED.includes(value.state) ? value.total : value.formattedTime}
                                    </div>
                                </div>
                                <div className="col-4 text-center d-flex justify-content-start align-items-center">
                                    <img src={value?.away?.icon || BlueShirt}
                                         width="29"
                                         height={'29'} style={{objectFit: 'contain'}} alt={'away team icon'}/>
                                    <div className={'text-black sfProDisplayFont font-14 text-nowrap ms-2'}>
                                        {value?.away?.name}
                                    </div>
                                </div>
                        </div>
                    </div>
                    <div
                        className="text-center d-flex justify-content-center align-items-center">
                        <Link
                            to={`/football/matches/${value.id}`}
                            // to={`/football/matches/${value[0]?.id}/preview`}
                            className="ps-4 pe-2 pb-0 pt-3 d-flex align-items-center text-decoration-none">
                            <p className={'text-gray'}>
                                Match {value.dateTime > moment().format('YYYY-MM-DD HH:ii:ss') ? 'Preview' : 'Overview'}
                                <span className={'ms-2'}>
                                    <FontAwesomeIcon size={'sm'} beat icon={faChevronRight}/>
                                </span>
                            </p>
                        </Link>
                    </div>
                </Card.Body>
            </Card>
        );
    }

    const withData = () => {
        const hostName = window.location.hostname;
        const domainObject = getHostname(hostName);
        const target = domainObject.host;
        const navColor = NAV_CLASS[target];
        const fontColor = FONT_COLOR[target];
        let scoreFontSize = screenWidth < SCREEN_SIZE.LAPTOP ? "small" : "font-16";

        return (
            <div className="col-12 border-1 border-secondary rounded bg-white">
                <div className={"rounded-custom-1 text-start p-1 ps-3"}>
                    <span className={`text-black bebasFont text-header`}>
                        FIXTURES & RESULTS
                    </span>
                </div>
                <ul className={"bg-fixtures text-center list-unstyled p-0"}>
                    <ItemLoader loading={loading}/>
                    {fixtures.map((value, index, fixtures) => (renderMatch(value, scoreFontSize)))}
                </ul>
            </div>
        );
    };

    const withoutData = () => {
        return <div className={`bebasFont text-black`}></div>;
    };

    const render = () => {
        if (fixtures) {
            return withData();
        }

        return withoutData();
    };

    return render();
};

export default ResultsFixtures;
