import {Container} from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton';

const TopStoriesSkeleton = () => {

  return (
      <Container
          className={`mt-xxl-5 mt-xl-5 mt-lg-5 mt-md-5 mt-sm-1 mt-2 mb-5`}>
        <div className={'customContainer'}>
          <div
              className={`col-12 robotoCondensedBold font-22 text-black mt-3`}
          >
          </div>
          <div className={'row'}>
            <div className={'col-8'}>
              <Skeleton count={1} height={ '500px'}/>
            </div>
            <div className={'col-4'}>
              <Skeleton count={1} height={ '245px'}/>
              <Skeleton count={1} height={ '245px'}/>
            </div>
          </div>
          <div className={'container ps-5 pe-5'}>
            <div className={'row p-0 m-0'}>
              <div className={'col-8'}>
                <Skeleton count={1} height={ '600px'}/>
              </div>
              <div className={'col-4'}>
                <Skeleton count={1} height={ '300px'}/>
                <Skeleton count={1} height={ '300px'}/>
              </div>
            </div>
          </div>
          {/*<Skeleton count={3} height={ '300px'} width={'32%'} inline={true} className={'ms-3'}/>*/}
        </div>
      </Container>
  );
};

export default TopStoriesSkeleton;
