import {Card} from 'react-bootstrap';
import {NavLink, useLocation} from 'react-router-dom';
import {TEXT_COLORS} from '../../../Configs/Targets';
import {getHostname} from '../../../CustomFunctions/getHostname';
import {faQuoteRight} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

const GossipCard = (props) => {
    const {
        index,
        gossip,
        imageHeight,
        cardTextSize,
        isNewsPage,
        publisherIconSize,
        isMain,
        newsOnSlider,
        isMobile,
        showNewsBelow,
        fontColor,
        bgColor,
    } = props;

    const location = useLocation();

    const hostName = window.location.hostname;
    const domainObject = getHostname(hostName);
    const target = domainObject.host;

    const gossipOverImage = (gossip, publisherIconSize, index) => {
        return (
            <div className={isMobile ? '' : 'p-3'}>
                <div
                    className={`d-flex justify-content-end ${index % 2 === 0 ? 'text-white' : fontColor}`}
                    style={{
                        lineHeight: isMobile ? 1.43 : '28px',
                        padding: '7.9px 6.4px 12px 10px',
                    }}
                >
                    <FontAwesomeIcon icon={faQuoteRight}
                                     style={{color: index % 2 === 0 ? '#ffffff' : TEXT_COLORS[target],}}/>
                    {/*{gossip?.url_name}*/}
                </div>
                <div
                    className={`sfProDisplayFont ${isMobile ? 'font-14' : 'font-20'}`}
                    style={{
                        lineHeight: isMobile ? 1.43 : '28px',
                        fontWeight: 500,
                        padding: '7.9px 6.4px 12px 10px',
                    }}
                    // dangerouslySetInnerHTML={{__html: gossip && gossip?.text}}
                >
                    {gossip?.text}
                </div>
                <div
                    className={`robotoCondensedBold ${isMobile ? 'font-14' : 'font-20'} ${index % 2 === 0 ? 'text-white' : fontColor}`}
                    style={{
                        lineHeight: isMobile ? 1.43 : '28px',
                        padding: '7.9px 6.4px 12px 10px',
                    }}
                >
                    {gossip?.url_name}
                </div>
            </div>);
    };

    const renderCard = (gossip, imageHeight, cardTextSize, isNewsPage, publisherIconSize, isMain, index) => {

        return (
            <>
                <Card
                    className={`${'slider-mobile-match-card'} col`}
                    style={{
                        borderRadius: 0,
                        width: isMobile ? '259px' : '400px',
                        height: isMobile ? '227px' : '250px',
                        border: `1px solid ${index % 2 !== 0 ? bgColor : '#ffffff'}`,
                        backgroundColor: index % 2 === 0 ? bgColor : '#ffffff'
                    }}
                >
                    {
                        <Card.Text
                            className={`${index % 2 === 0 ? 'text-white' : fontColor} text-start robotoCondensedBold text-wrap font-12 w-100`}
                        >
                            {(isNewsPage && isMobile) || showNewsBelow ?
                                null :
                                gossipOverImage(gossip, publisherIconSize, index)}
                        </Card.Text>
                    }
                </Card>
                {/*{(isNewsPage && isMobile) || showNewsBelow ? gossipBelowImage(gossip, publisherIconSize) : null}*/}
            </>
        );
    };

    const renderSnack = (
        gossip, mainImageStyle, cardTextSize, isNewsPage, publisherIconSize,
        isMobile, textColor, isMain) => {

        const sport = location.pathname.split('/')[1];

        let link = gossip?.url;

        return (
            <NavLink to={link} className={'text-decoration-none text-dark'}>
                {isMobile ?
                    renderCard(gossip, mainImageStyle, cardTextSize, isNewsPage,
                        publisherIconSize, isMain) :
                    renderCard(gossip, mainImageStyle, cardTextSize, isNewsPage,
                        publisherIconSize, isMain)}
            </NavLink>
        );
    };

    const renderNewsNow = (
        news, imageHeight, cardTextSize, isNewsPage, publisherIconSize, isMobile,
        textColor, isMain, index) => {
        const sport = location.pathname.split('/')[1] || 'football';

        let link = '';
        if (typeof news.main_team !== 'undefined' && news.main_team) {
            link = `/${sport}/${news.main_team}/${news.slug}`;
        } else {
            link = `/${sport}/news/${news.slug}`;
        }
        return (
            <a
                href={news.url}
                className={'text-decoration-none text-dark'}
                target="_blank"
                rel="noreferrer"
            >
                {renderCard(news, imageHeight, cardTextSize, isNewsPage, publisherIconSize, isMain, index)}
            </a>
        );
    };

    return !gossip ?
        renderSnack(gossip, imageHeight, 'cardTextSize', isNewsPage,
            publisherIconSize, 'isMobile', 'textColor', isMain) :
        renderNewsNow(gossip, imageHeight, 'cardTextSize', isNewsPage,
            publisherIconSize, 'isMobile', 'textColor', isMain, index);

    // return renderCard();
};

GossipCard.defaultProps = {
    bgColor: 'transparent',
}

export default GossipCard;
