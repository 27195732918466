export const AndroidSVG = (props) => {

  return (
      <svg id="android-logo" xmlns="http://www.w3.org/2000/svg"
           width={props.width}
           className={props.className}
           height={props.height} viewBox="0 0 28.488 32.558">
        <defs>
          {/*<style*/}
          {/*    dangerouslySetInnerHTML={{__html: `fill: ${props.fill}; `}}/>*/}
        </defs>
        <path id="android-filled-2" data-name="android-filled" className={props.className} fill={props.fill}
              d="M131.5,80.164a1.019,1.019,0,0,0,1.019,1.016h1.164v2.879a1.306,1.306,0,1,0,2.611,0V81.178h2.032v2.879a1.306,1.306,0,1,0,2.611,0V81.178H142.1a1.019,1.019,0,0,0,1.019-1.016V70.713H131.5Zm8.538-14.348,1.074-1.554a.2.2,0,0,0-.036-.273.194.194,0,0,0-.265.062l-1.114,1.616a6.686,6.686,0,0,0-4.82,0l-1.114-1.614a.192.192,0,0,0-.265-.062.2.2,0,0,0-.036.273l1.074,1.554a5.058,5.058,0,0,0-3.056,4.055h11.62A5.077,5.077,0,0,0,140.033,65.816Zm-5.2,2.451a.643.643,0,1,1,.643-.643A.643.643,0,0,1,134.828,68.267Zm4.983,0a.643.643,0,1,1,.643-.643A.643.643,0,0,1,139.811,68.267Zm5.341,2.408A1.314,1.314,0,0,0,143.846,72v5.174a1.306,1.306,0,1,0,2.611,0V72A1.309,1.309,0,0,0,145.152,70.675Zm-15.746,0A1.314,1.314,0,0,0,128.1,72v5.174a1.306,1.306,0,1,0,2.611,0V72A1.314,1.314,0,0,0,129.405,70.675Z"
              transform="translate(-128.1 -63.96)"/>
      </svg>

  );
};
