import audienceDemographic from '../../assets/images/v2/component8511@3x.png';
import demographics from '../../assets/images/demographics.png';
import male from '../../assets/images/male.png';
import female from '../../assets/images/female.png';
import phone from '../../assets/images/phone.png';
import macbook from '../../assets/images/macbook.png';
import {useEffect, useState} from 'react';

const Demographics = (props) => {
  const {isMobile} = props;
  const [backgroundSize,setBackGroundSize] = useState('850px');

  useEffect(() => {
    if (window.screen.width >= 2550) {
      setBackGroundSize('850px')
    } else if (window.screen.width >= 1360 && window.screen.width < 1920) {
      setBackGroundSize('850px')
    }
  }, [window.screen.width]);

  const renderDesktopView = () => {
    return (
        <div
            style={{
              height: backgroundSize,
            }}
            className={'container-fluid blackBackgroundTwo flex-grow-1 '}>
          <div className={'container '}>
            <div className={'row '}>
              <div className={'col-md-12 col-lg-12 col-xl-12 col-xxl-12 mt-5'}>
                <div className={'row justify-content-center text-center'}>
              <span style={{
                fontWeight:'bold',
                color: '#fbb400',
              }} className={'font-50 Montserrat-Bold'}
              >AUDIENCE
                &nbsp;
                <span style={{
                  fontWeight:'bold',
                }}
                    className={'text-white font-50 Montserrat-Bold'}>STATISTICS</span>
</span>
                </div>
              </div>
            </div>
            <div className={'row mt-5'}>
              <div className={'col-md-6 col-lg-6 col-xl-6 col-xxl-6 '}>
                <img
                    src={audienceDemographic}
                    style={{
                      height:430,
                    }}
                    className={'img-fluid'}
                    alt={'audience demographic'}
                />
              </div>

              <div className={'pb-5 col-md-6 col-lg-6 col-xl-6 col-xxl-6'}>
                <img src={demographics}
                     alt={'demographics'}
                     className={'img-fluid'}/>
              </div>
              <div className={'d-flex align-items-center justify-content-center'}>
                <div className={'row text-center'} >
                <div className={'col-xs-3 col-sm-3 col-md-3 col-lg-3 col-xl-3 col-xxl-3'}>
                  <img
                      className={'ms-3 me-3'}
                      src={male}
                      alt={'male'}
                      style={{
                        width: 'auto',
                        height: '74px',
                        objectFit: 'contain',
                      }}
                  />
                  <div className={'row'}>
                    <span className={'font-fanzine font-53 Montserrat-SemiBold'}>
                      92%
                    </span>
                    <span className={'text-white font-23 Montserrat-Regular'}>MALE</span>
                  </div>
                </div>
                <div className={'col-xs-3 col-sm-3 col-md-3 col-lg-3 col-xl-3 col-xxl-3'}>
                  <img
                      className={'ms-3 me-3'}
                      src={female}
                      alt={'female'}
                      style={{
                        width: '43px',
                        height: '74px',
                        objectFit: 'contain',
                      }}
                  />
                  <div className={'row'}>
                    <span className={'female-pink font-53 Montserrat-SemiBold'}>
                      8%
                    </span>
                    <span className={'female-pink font-23 Montserrat-Regular'}>FEMALE</span>
                  </div>
                </div>
                <div className={'col-xs-3 col-sm-3 col-md-3 col-lg-3 col-xl-3 col-xxl-3'}>
                  <img
                      className={'ms-3 me-3'}

                      src={phone}
                      alt={'phone'}
                      style={{
                        width: '43px',
                        height: '74px',
                        objectFit: 'contain',
                      }}
                  />
                  <div className={'row'}>
                    <span className={'text-white font-53 Montserrat-SemiBold'}>
                      80%
                    </span>
                    <span className={'text-white font-23 Montserrat-Regular'}>MOBILE</span>
                  </div>
                </div>
                <div className={'col-xs-3 col-sm-3 col-md-3 col-lg-3 col-xl-3 col-xxl-3'}>
                  <img
                      className={'ms-3 me-3'}

                      src={macbook}
                      alt={'laptop'}
                      style={{
                        width: '43px',
                        height: '74px',
                        objectFit: 'contain',
                      }}
                  />
                  <div className={'row'}>
                    <span className={'text-white font-53 Montserrat-SemiBold'}>
                      20%
                    </span>
                    <span className={'text-white Montserrat-Regular font-23'}>DESKTOP</span>
                  </div>
                </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    );
  };
  const renderMobileView = () => {
    return (
        <div
            style={{
              height: '730px',
            }}
            className={'container-fluid blackBackgroundTwo flex-grow-1 '}>
          <div className={'container '}>
            <div className={'row pt-5'}>
              <div className={'col-md-6 col-lg-6 col-xl-6 col-xxl-6'}>
                <div className={'row'}>
                <span
                    className={'font-30 gold-one text-center Montserrat-Bold'}
                >
                  AUDIENCE
                </span>
                  <span
                      className={'font-30 text-white text-center Montserrat-Bold'}
                  >
                  STATISTICS
                </span>
                </div>

                <div className={'row justify-content-center'}>
                  <img
                      style={{
                        height:261,
                        width:'auto',
                        objectFit:'contain',
                      }}
                      src={audienceDemographic}
                      alt={'audience demographic'}
                  />
                </div>
              </div>
              <div className={'col-12'}>
                <div className={'row justify-content-center'}>
                  <img
                      src={demographics}
                      alt={'demographics'}
                      className={'w-100 align-self-center'}
                  />
                </div>
                <div className={'row mt-5'}>
                  <div className={'col-3'}>
                    <div className={'d-flex justify-content-center align-items-center'}>
                      <img
                          src={male}
                          alt={'male'}
                          style={{
                            width: '19px',
                            height: '32px',
                            objectFit: 'contain',
                          }}
                      />
                    </div>
                    <div className={'row d-flex justify-content-center'}>
                    <span className={'font-fanzine text-center font-23 font-weight-800'}>
                      92%
                    </span>
                      <span className={'text-white text-center font-9'}>MALE</span>
                    </div>
                  </div>
                  <div className={'col-3'}>
                    <div className={'d-flex justify-content-center align-items-center'}>
                      <img
                        src={female}
                        alt={'female'}
                        style={{
                          width: '19px',
                          height: '32px',
                          objectFit: 'contain',
                        }}
                    />
                    </div>

                    <div className={'row d-flex justify-content-center'}>
                    <span className={'female-pink text-center font-23 font-weight-800'}>
                      8%
                    </span>
                      <span className={'female-pink font-9 text-center'}>FEMALE</span>
                    </div>
                  </div>
                  <div className={'col-3'}>
                    <div className={'d-flex justify-content-center align-items-center'}>
                      <img
                        src={phone}
                        alt={'phone'}
                        style={{
                          width: '19px',
                          height: '32px',
                          objectFit: 'contain',
                        }}
                    />
                    </div>
                    <div className={'row d-flex justify-content-center'}>
                    <span className={'text-white text-center font-23 font-weight-800'}>
                      80%
                    </span>
                      <span className={'text-white font-9 text-center'}>MOBILE</span>
                    </div>
                  </div>
                  <div className={'col-3'}>
                    <div className={'d-flex justify-content-center align-items-center'}>
                      <img
                        src={macbook}
                        alt={'laptop'}
                        style={{
                          width: 28,
                          height: 29,
                          objectFit: 'contain',
                        }}
                    />
                    </div>
                    <div className={'row d-flex justify-content-center'}>
                    <span className={'text-white font-23 text-center font-weight-800'}>
                      20%
                    </span>
                      <span className={'text-white text-center font-9'}>DESKTOP</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    );
  };
  const renderMain = () => {
    return isMobile ? renderMobileView() : renderDesktopView();
  };

  return renderMain();
};

export default Demographics;
