import reachBottomPicture from "../../assets/images/reachBottomPicture.png";
import {useEffect, useState} from 'react';
const ReachBottom = (props) => {

  const [backgroundSize,setBackGroundSize] = useState('800px');

  useEffect(() => {
    if (window.screen.width >= 2550) {
      setBackGroundSize('800px')
    } else if (window.screen.width >= 1360 && window.screen.width < 1920) {
      setBackGroundSize('800px')
    }
  }, [window.screen.width]);

  const { isMobile } = props;
  const renderMobileView = () => {
    return (
      <div className={"container-fluid blackBackgroundTwo"}>
        <div className={"container"}>
          <div className={"row pt-5 align-items-center justify-content-center"}>
            <div className={"col-md-5 col-lg-5 col-xl-5 col-xxl-5"}>
              <div className={"row pb-4"}>
                <span className={"font-30 font-fanzine Montserrat-Bold text-center"}>
                  APP & WEB
                  <span className={"font-30 text-white Montserrat-Bold text-center"}>{' REACH'}</span>
                </span>
              </div>
              <div className={"row justify-content-center mb-4"}>
                <div className={"col-5 me-2"}
                >
                  <div
                      className={"row p-2 border border-1 border-white "}>
                    <span className={"font-fanzine Montserrat-Bold font-30 text-center"}>
                      360K
                    </span>
                    <span className={"Montserrat-Regular font-8 text-white text-center"}>
                      APP DOWNLOADS
                    </span>

                  </div>
                </div>
                <div className={"col-5 ms-2"}>
                  <div
                    className={"row p-2 border border-1 border-white "}
                  >
                    <span className={"font-fanzine Montserrat-Bold font-30 text-center"}>
                      264K
                    </span>
                    <span className={"font-8 text-white Montserrat-Regular text-center"}>
                      MAU
                    </span>
                  </div>
                </div>
              </div>

              <div className={"row justify-content-center mb-4"}>
                <div className={"col-5 me-2"}>
                  <div
                    className={"row p-2 border border-1 border-white "}
                  >
                    <span className={"font-fanzine Montserrat-Bold font-30 text-center"}>
                      197
                    </span>
                    <span className={"font-8 text-white Montserrat-Regular text-center"}>
                      COUNTRIES
                    </span>
                  </div>
                </div>
                <div className={"col-5 ms-2"}>
                  <div
                    className={"row p-2 border border-1 border-white "}
                  >
                    <span className={"font-fanzine Montserrat-Bold font-30 text-center text-nowrap"}>
                      7m 03s
                    </span>
                    <span className={"font-8 text-white Montserrat-Regular text-center"}>
                      DWELL TIME
                    </span>
                  </div>
                </div>
              </div>

              <div className={"row justify-content-center mb-4"}>
                <div className={"col-5 me-2"}>
                  <div
                    className={"row p-2 border border-1 border-white "}
                  >
                    <span className={"font-fanzine Montserrat-Bold font-30 text-center"}>
                      4.6M
                    </span>
                    <span className={"font-8 text-white Montserrat-Regular text-center"}>
                      AD REQUESTS P/M
                    </span>
                  </div>
                </div>
                <div className={"col-5 ms-2"}>
                  <div
                    className={"row p-2 border border-1 border-white "}
                  >
                    <span className={"font-fanzine Montserrat-Bold font-30 text-center"}>
                      50
                    </span>
                    <span className={"font-8 text-white Montserrat-Regular text-center"}>
                      APPS/WEBSITES
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div className={"col-md-5 col-lg-5 col-xl-5 col-xxl-5 pb-3"}>
              <div className={"row justify-content-center align-items-center"}>
                <img
                  src={reachBottomPicture}
                  alt={"reach bottom picture."}
                  className={"w-100"}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderDesktopView = () => {
    return (
      <div  style={{
        height:backgroundSize,
      }} className={"container-fluid blackBackgroundTwo"}>
        <div className={"container"}>
          <div className={"row "}>
            <div className={"col-md-12 col-lg-12 col-xl-12 col-xxl-12 mt-5"}>
              <div className={"row justify-content-center"}>
                <span
                    style={{fontWeight:'bold'}}
                    className={"font-fanzine font-50 text-center Montserrat-Bold"}>APP & WEB <span
                    className={"text-white font-50 text-center Montserrat-Bold"}
                >REACH</span></span>
              </div>
            </div>
          </div>

          <div className={"row align-items-center justify-content-center pt-5"}>
            <div className={"col-xs-5 col-sm-5 col-md-5 col-lg-5 col-xl-5 col-xxl-5"}>
              <div className={"row justify-content-between mb-4 pt-5"}>
                <div className={"col-md-5 col-lg-5 col-xl-5 col-xxl-5 "}>
                  <div
                      style={{borderColor:'#74dbef'}}
                      className={"row border p-2 border-1 border-white"}>
                    <span
                        style={{
                          fontWeight:'bold',
                        }}
                        className={"font-fanzine font-50 Montserrat-Bold text-center"}>
                      360K
                    </span>
                    <span
                        style={{
                          fontWeight:300,
                        }}
                        className={"font-19 Montserrat-Regular text-white text-center"}>
                      APP DOWNLOADS
                    </span>
                  </div>
                </div>
                <div className={"col-md-5 col-lg-5 col-xl-5 col-xxl-5 "}>
                  <div
                      style={{borderColor:'#74dbef'}}
                      className={"row border p-2 border-1 border-white"}>
                    <span
                        style={{
                          fontWeight:'bold',
                        }}
                        className={"font-fanzine font-50 Montserrat-Bold text-center"}>
                      264K
                    </span>
                    <span
                        style={{
                          fontWeight:300,
                        }}
                        className={"font-19 Montserrat-Regular text-white text-center"}>
                      MAU
                    </span>
                  </div>
                </div>
              </div>
              <div className={"row justify-content-between mb-4"}>
                <div className={"col-md-5 col-lg-5 col-xl-5 col-xxl-5 "}>
                  <div
                      style={{borderColor:'#74dbef'}}
                      className={"row border p-2 border-1 border-white"}>
                    <span
                        style={{
                          fontWeight:'bold',
                        }}
                        className={"font-fanzine font-50 Montserrat-Bold text-center"}>
                      197
                    </span>
                    <span
                        style={{
                          fontWeight:300,
                        }}
                        className={"font-19 Montserrat-Regular text-white text-center"}>
                      COUNTRIES
                    </span>
                  </div>
                </div>
                <div className={"col-md-5 col-lg-5 col-xl-5 col-xxl-5 "}>
                  <div
                      style={{borderColor:'#74dbef'}}
                      className={"row border p-2 border-1 border-white"}>
                    <span
                        style={{
                          fontWeight:'bold',
                        }}
                        className={"font-fanzine text-nowrap font-50 Montserrat-Bold text-center"}>
                      7m 03s
                    </span>
                    <span
                        style={{
                          fontWeight:300,
                        }}
                        className={"font-19 Montserrat-Regular text-white text-center"}>
                      DWELL TIME
                    </span>
                  </div>
                </div>
              </div>
              <div className={"row justify-content-between mb-4"}>
                <div className={"col-md-5 col-lg-5 col-xl-5 col-xxl-5 "}>
                  <div
                      style={{borderColor:'#74dbef'}}
                      className={"row border p-2 border-1 border-white"}>
                    <span
                        style={{
                          fontWeight:'bold',
                        }}
                        className={"font-fanzine font-50 Montserrat-Bold text-center"}>
                      4.6M
                    </span>
                    <span
                        style={{
                          fontWeight:300,
                        }}
                        className={"font-19 Montserrat-Regular text-white text-center"}>
                      AD REQUESTS P/M
                    </span>
                  </div>
                </div>
                <div className={"col-md-5 col-lg-5 col-xl-5 col-xxl-5 "}>
                  <div
                      style={{borderColor:'#74dbef'}}
                      className={"row border p-2 border-1 border-white"}>
                    <span
                        style={{
                          fontWeight:'bold',
                        }}
                        className={"font-fanzine font-50 Montserrat-Bold text-center"}>
                      50
                    </span>
                    <span
                        style={{
                          fontWeight:300,
                        }}
                        className={"font-19 Montserrat-Regular text-white text-center"}>
                      APPS/WEBSITES
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className={"col-xs-7 col-sm-7 col-md-7 col-lg-7 col-xl-7 col-xxl-7"}>
              <div className={"row justify-content-center align-items-center"}>
                <img
                  src={reachBottomPicture}
                  alt={"reach bottom picture."}
                  className={"w-100"}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderMain = () => {
    return isMobile ? renderMobileView() : renderDesktopView();
  };

  return renderMain();
};

export default ReachBottom;
