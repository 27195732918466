import {useSearchParams} from 'react-router-dom';
import {FOOTBALL} from '../../../Axios/EndPoints';
import moment from 'moment-timezone';
import React, {useEffect, useState} from 'react';
import {get} from '../../../Axios/get';
import FanzineNewsCard from '../../Cards/FanzineNewsCard';
import LeftNewsSkeleton from '../../Skeleton/LeftNewsSkeleton';
import GoogleAd from '../../Ads/GoogleAd';
import NewsSectionHeading from "../../NewsSectionHeading";
import RightNewsAds from '../News/RightNewsAds';
import NewNewsFanzineCard from '../../Cards/fanzine/newNewsCard';

const SpecificLeagueNews = (props) => {
    const {leagueData, isMobile} = props;

    const [searchParams] = useSearchParams();
    const [specificLeagueNews, setSpecificLeagueNews] = useState([]);
    const [specificLeagueNewsLoading, setSpecificLeagueNewsLoading] = useState(
        true);

    const fetchSpecificLeagueNews = async () => {

        let league_id = (leagueData?.league_id || searchParams.get('league_id') ||
            '');
        let parameters = leagueData?.league_id ? '?league_id=' + league_id : '';
        let URL = FOOTBALL.LatestNewsEndPoint + parameters;

        await get(URL).then((response) => {
            let tempNewsData = [];
            // eslint-disable-next-line array-callback-return
            response.data.map((data) => {
                let newsTime = moment.tz(data.datetime, 'Europe/London').local().startOf('seconds').fromNow();
                newsTime = newsTime.replace('an', '1');
                newsTime = newsTime.replace('a ', '1 ');
                newsTime = newsTime.replace('hours', 'h');
                newsTime = newsTime.replace('hour', 'h');
                newsTime = newsTime.replace('minutes', 'm');
                newsTime = newsTime.replace('minute', 'm');
                newsTime = newsTime.replace('days', 'd');
                newsTime = newsTime.replace('day', 'd');
                let news = {
                    news_id: data.news_id,
                    slug: data.slug,
                    news_image: data.image,
                    news_title: data.title,
                    news_url: data.url,
                    news_time: newsTime,
                    news_date: moment(data.datetime).format('D MMM YYYY, H:mm'),
                    news_publisher: {
                        publisher_name: data.publisher.name,
                        publisher_icon: data.publisher.icon,
                    },
                };
                tempNewsData.push(news);
            });
            setSpecificLeagueNews(tempNewsData);
            setSpecificLeagueNewsLoading(false);
        }).catch((err) => {
            console.error(err);
        });
    };

    useEffect(() => {
        setSpecificLeagueNewsLoading(true);
        fetchSpecificLeagueNews();
    }, [searchParams.get('league_id')]);

    const renderSpecificLeagueNews = (News, Sport, isMobile, title) => {
        const SportNews = News;
        const SportName = Sport;

        const renderLeftSpecificLeagueNews = () => {
            return (
                <div
                    className={'col-xxl-8 col-xl-8 mt-2 col-lg-8 col-md-8 col-sm-12'}>
                    <NewsSectionHeading title={`${title} News`} bgColor={leagueData?.bg_color} fontColor={leagueData?.font_color}/>
                    <div className={`col-12 `}>
                        <div className={`w-100`}>
                            <div className={'row '}>
                                {SportNews.map((news, index) => {
                                    return index < 9 &&
                                        (
                                            <div
                                                className={` ${index === 0 ? 'col-xl-12 m-0 p-1 col-lg-12 col-md-12' : 'col-xl-6 m-0 p-1 col-lg-6 col-md-6'} col-sm-12 col-xs-12`}
                                            >
                                                <NewNewsFanzineCard
                                                    news={news}
                                                    isMobile={isMobile}
                                                    showNewsSide={index !== 0}
                                                />
                                                {
                                                    index === 0 && <GoogleAd id={'4009154580'}/>
                                                }
                                                {/*<FanzineNewsCard*/}
                                                {/*    news={news}*/}
                                                {/*    imageHeight={(!isMobile && index === 0) ? '500px' : '277px'}*/}
                                                {/*    cardTextSize={''}*/}
                                                {/*    isNewsPage={''}*/}
                                                {/*    publisherIconSize={''}*/}
                                                {/*    isMain={''}*/}
                                                {/*/>*/}
                                            </div>
                                        );
                                })}
                            </div>
                            <GoogleAd id={'4009154580'}/>
                        </div>
                    </div>
                </div>
            );
        };

        const renderRightAds = () => {

            return (
                <div className={'col-4 mt-4 '}>
                    {
                        !isMobile &&
                        <>
                            <GoogleAd id={'4009154580'}/>
                            <div
                                className={`col-12`}
                            >
                                <FanzineNewsCard
                                    news={SportNews[SportNews.length - 4]}
                                    imageHeight={'277px'}
                                    cardTextSize={''}
                                    isNewsPage={''}
                                    publisherIconSize={''}
                                    isMain={''}
                                />
                            </div>
                            <div
                                className={`col-12`}
                            >
                                <FanzineNewsCard
                                    news={SportNews[SportNews.length - 3]}
                                    imageHeight={'277px'}
                                    cardTextSize={''}
                                    isNewsPage={''}
                                    publisherIconSize={''}
                                    isMain={''}
                                />
                            </div>
                            <GoogleAd id={'4009154580'}/>
                            <div
                                className={`col-12`}
                            >
                                <FanzineNewsCard
                                    news={SportNews[SportNews.length - 2]}
                                    imageHeight={'277px'}
                                    cardTextSize={''}
                                    isNewsPage={''}
                                    publisherIconSize={''}
                                    isMain={''}
                                />
                            </div>
                            <div
                                className={`col-12`}
                            >
                                <FanzineNewsCard
                                    news={SportNews[SportNews.length - 1]}
                                    imageHeight={'277px'}
                                    cardTextSize={''}
                                    isNewsPage={''}
                                    publisherIconSize={''}
                                    isMain={''}
                                />
                            </div>
                            <GoogleAd id={'4009154580'}/>

                        </>
                    }
                </div>
            );
        };

        const renderMain = () => {
            return (
                <section className={'container'}>
                    <div className={`${isMobile ? 'w-100' : 'container'}`}>
                        <div className={`row `}>
                            {!specificLeagueNewsLoading && News?.length > 0 &&
                                renderLeftSpecificLeagueNews()}
                            {specificLeagueNewsLoading && <LeftNewsSkeleton/>}
                            {/*isMobile is here because of articles not showing properly in mobile view*/}
                            {/*{!specificLeagueNewsLoading && !isMobile && renderRightAds()}*/}
                            {!specificLeagueNewsLoading && !isMobile && <RightNewsAds isMobile={isMobile} leagueId={leagueData?.league_id} />}
                        </div>
                    </div>
                </section>
            );
        };

        return renderMain();
    };

    return renderSpecificLeagueNews(specificLeagueNews, 'football', isMobile,
        (leagueData?.name || ''));

};

export default SpecificLeagueNews;
