import {MATCH_STATES} from '../../Configs/Constants';
import React from 'react';
import moment from 'moment-timezone';
import DateOnly from './DateOnly';
import {Link, useLocation} from 'react-router-dom';
import '../../assets/slider/TopMatchesSlider.css';

const TopMatchSliderItem = (props) => {
  const location = useLocation();
  const {
    displayArray,
    selectedSport,
    dateValue,
    dateIndex,
    currentDate,
    isMobile,
    target,
  } = props;

  let matchPreparedData = {};

  // eslint-disable-next-line no-restricted-globals
  switch (location.pathname.split('/')[1]) {
    case 'football':
      matchPreparedData =
          {
            date: moment(dateValue?.datetime).format('YYYY-MM-DD'),

            homeIcon: dateValue?.home_team?.icon,
            awayIcon: dateValue?.guest_team?.icon,
            homeTeam: dateValue?.home_team?.abbr?.toUpperCase() ||
                dateValue?.home_team?.name.slice(0, 3)?.toUpperCase(),
            awayTeam: dateValue?.guest_team?.abbr?.toUpperCase() ||
                dateValue?.guest_team?.name.slice(0, 3)?.toUpperCase(),
            homeScore: MATCH_STATES.IN_PLAY.includes(dateValue?.state) ||
            MATCH_STATES.FINISHED.includes(dateValue?.state) ?
                dateValue?.total.slice(0, 1) :
                '',
            awayScore: MATCH_STATES.IN_PLAY.includes(dateValue?.state) ||
            MATCH_STATES.FINISHED.includes(dateValue?.state) ?
                dateValue?.total.slice(-1) :
                '',
            currentTime: dateValue?.current_time_match,
            isLive: (dateValue?.state !== 1 &&
                MATCH_STATES.IN_PLAY.includes(dateValue?.state)) ? 'Live' :
                MATCH_STATES.FINISHED.includes(dateValue?.state) ? 'Full-Time' :
                    MATCH_STATES.UPCOMING.includes(dateValue?.state) ?
                        moment(dateValue?.datetime)
                            .add(1, 'hours')
                            .format('HH:mm') + ' KO' :
                        '',
          };
      break;
    default:
      if (dateValue?.local_team) {
        matchPreparedData =
            {
              date: moment(dateValue?.datetime).format('YYYY-MM-DD'),
              homeIcon: dateValue?.local_team?.icon,
              awayIcon: dateValue?.visitor_team?.icon,
              homeTeam: dateValue?.local_team?.abbr ||
                  dateValue?.local_team?.name.slice(0, 3),
              awayTeam: dateValue?.visitor_team?.abbr ||
                  dateValue?.visitor_team?.name.slice(0, 3),
              homeScore: MATCH_STATES.IN_PLAY.includes(dateValue?.state) ?
                  dateValue?.total.slice(0, 1) :
                  '',
              awayScore: MATCH_STATES.IN_PLAY.includes(dateValue?.state) ?
                  dateValue?.total.slice(-1) :
                  '',
              currentTime: dateValue?.current_time_match || '',
              isLive: (dateValue?.state !== 1 &&
                  MATCH_STATES.IN_PLAY.includes(dateValue?.state)) ? 'Live' :
                  MATCH_STATES.FINISHED.includes(dateValue?.state) ?
                      'Full-Time' :
                      MATCH_STATES.UPCOMING.includes(dateValue?.state) ?
                          moment(dateValue?.datetime).format('ddd HH:mm') +
                          ' KO' :
                          '',
            };
      } else {
        matchPreparedData =
            {
              date: moment(dateValue?.datetime).format('YYYY-MM-DD'),

              homeIcon: dateValue?.home_team?.icon,
              awayIcon: dateValue?.guest_team?.icon,
              homeTeam: dateValue?.home_team?.abbr?.toUpperCase() ||
                  dateValue?.home_team?.name.slice(0, 3)?.toUpperCase(),
              awayTeam: dateValue?.guest_team?.abbr?.toUpperCase() ||
                  dateValue?.guest_team?.name.slice(0, 3)?.toUpperCase(),
              homeScore: MATCH_STATES.IN_PLAY.includes(dateValue?.state) ||
              MATCH_STATES.FINISHED.includes(dateValue?.state) ?
                  dateValue?.total.slice(0, 1) :
                  '',
              awayScore: MATCH_STATES.IN_PLAY.includes(dateValue?.state) ||
              MATCH_STATES.FINISHED.includes(dateValue?.state) ?
                  dateValue?.total.slice(-1) :
                  '',
              currentTime: dateValue?.current_time_match,
              isLive: (dateValue?.state !== 1 &&
                  MATCH_STATES.IN_PLAY.includes(dateValue?.state)) ? 'Live' :
                  MATCH_STATES.FINISHED.includes(dateValue?.state) ?
                      'Full-Time' :
                      MATCH_STATES.UPCOMING.includes(dateValue?.state) ?
                          moment(dateValue?.datetime).format('HH:mm') + ' KO' :
                          '',
            };
      }
      break;

  }


  return (
      <Link
          to={(location.pathname.includes('football') || target !=='fanzine') ? MATCH_STATES.FINISHED.includes(dateValue?.state) ?
              `/football/matches/${dateValue?.id}/overview`
              :
              MATCH_STATES.IN_PLAY.includes(dateValue?.state) ?
                  `/football/matches/${dateValue?.id}/overview`
                  :
                  MATCH_STATES.UPCOMING.includes(dateValue?.state) ?
                      `/football/matches/${dateValue?.id}/preview`
                      :
                      `/football/matches/${dateValue?.id}/preview`
              :
              '/'
          }
          style={{
            height: '120px',
            // flex: isMobile ? '' : '0.5 0',
            width: moment(matchPreparedData?.date)
                .diff(moment(
                    moment(currentDate?.datetime).format('YYYY-MM-DD'))) !==
            0 && displayArray[dateIndex] ? '168px' : '168px',
          }}
          className={`shadow bg-white d-flex ms-1 slick-slide text-decoration-none text-black`}>
        {moment(matchPreparedData?.date)
                .diff(
                    moment(moment(currentDate?.datetime).format('YYYY-MM-DD'))) !==
            0 && displayArray[dateIndex] &&
            <DateOnly isMobile={isMobile} currentDate={currentDate}
                      value={matchPreparedData} index={dateIndex}
                      target={target}/>}
        <div className={displayArray[dateIndex] ?
            ' shadow-bottom' :
            ' shadow-bottom'}>
          <div className={'row ms-0 w-100'}>
            <div className={''}>
              <div className={'row'}>
                <div
                    className={`${matchPreparedData?.isLive !== 'Live' ?
                        'col-12 justify-content-center' :
                        'col-5'} ${matchPreparedData?.isLive === 'Live' &&
                    'bg-yellow'}`}
                    style={{height: isMobile ? '15px' : '20px'}}
                >
                  <p className={`d-flex robotoCondensedBold ${(matchPreparedData?.isLive !==
                      'Live' || matchPreparedData?.isLive !== 'Full-Time') ?
                      isMobile ? 'font-8' : 'font-14' :
                      'font-12'} text-center justify-content-center`}>
                    {matchPreparedData?.isLive === '' &&
                        moment(dateValue?.datetime).format('HH:mm')}
                    {matchPreparedData?.isLive}
                    &nbsp;
                    {/*<AiOutlineCaretRight size={14}/>*/}
                  </p>
                </div>
                {
                  matchPreparedData.isLive === 'Live' ?
                      (
                          <div
                              className={`col pt-1 text-end`}
                              style={{height: isMobile ? '15px' : '20px'}}
                          >
                            <p className={`d-flex robotoCondensedBold font-11 justify-content-end`}>
                              {dateValue?.currentTime}
                            </p>
                          </div>
                      ) : (<></>)
                }
              </div>
            </div>
            <div className={`col-12 ${'mt-2'} `}>
              <div className={'row'}>
                <div className={'col-4 d-flex justify-content-center'}>
                  <img
                      style={{
                        width: isMobile ? '20px' : '30px',
                        height: isMobile ? '20px' : '30px',
                      }}
                      alt={'homeTeam'}
                      src={matchPreparedData?.homeIcon}
                  />
                </div>
                <div className={`col-4 ${isMobile ?
                    'font-12' : 'font-20'} robotoCondensedBold text-center`}>
                  {matchPreparedData?.homeTeam}
                </div>
                <div
                    className={` ${matchPreparedData?.homeScore === '' ?
                        'col-1' :
                        'col-4'} robotoCondensedBold text-center ${isMobile ?
                        'font-12' : 'font-20'}`}>
                  {matchPreparedData?.homeScore}
                </div>
              </div>
            </div>
            <div className={'col-12 pb-2 mt-2'}>
              <div className={'row'}>
                <div className={'col-4 d-flex justify-content-center'}>
                  <img
                      style={{
                        width: isMobile ? '20px' : '30px',
                        height: isMobile ? '20px' : '30px',
                      }}
                      alt={'awayTeam'}
                      src={matchPreparedData?.awayIcon}
                  />
                </div>
                <div className={`col-4 ${isMobile ?
                    'font-12' : 'font-20'} robotoCondensedBold text-center`}>
                  {matchPreparedData?.awayTeam}
                </div>
                <div
                    className={` ${matchPreparedData?.awayScore === '' ?
                        '' :
                        'col-4'} robotoCondensedBold text-center ${isMobile ?
                        'font-12' : 'font-20'}`}>
                  {matchPreparedData?.awayScore}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Link>
  );
};

export default TopMatchSliderItem;
