import Loader from '../../Components/Loader/Loader';
import React, {useEffect, useState} from 'react';
import {get} from '../../Axios/get';
import {FOOTBALL, MLB} from '../../Axios/EndPoints';
import moment from 'moment-timezone';
import {Container} from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton';
import {SCREEN_SIZE} from '../../Configs/Constants';
import {SubNavbar} from '../../Components/Navbar/SubNavbar';
import NewsPageLayout from '../../Components/Layout/News/NewsPageLayout';
import {chunk} from "../../Components/Helper/chunk";
import LatestNews from "../../Components/Layout/LatestSection/LatestNews";
import {useSearchParams} from "react-router-dom";
import GoogleAdManager from "../../Components/Ads/GoogleAdManager";

const FootballNewsPage = () => {

    const [searchParams,] = useSearchParams();

    let [isMobile, setIsMobile] = useState(false);
    let [loading, setLoading] = useState(true);

    let [allLeagues, setAllLeagues] = useState(null);
    let [allLeaguesLoading, setAllLeaguesLoading] = useState(true);
    let [transferNews, setTransferNews] = useState([]);
    let [transferNewsLoading, setTransferNewsLoading] = useState(true);

    let [latestNews, setLatestNews] = useState([]);
    let [latestNewsLoading, setLatestNewsLoading] = useState(true);

    let [allLeagueNewsDataLoading, setAllLeagueNewsDataLoading] = useState(true);
    let [allLeagueVideoDataLoading, setAllLeagueVideoDataLoading] = useState(
        true);
    let [allLeagueNewsData, setAllLeagueNewsData] = useState([]);
    let [allLeagueVideoData, setAllLeagueVideoData] = useState([]);

    const fetchTransferNews = () => {
        let page = '&page=1';
        let limit = '?limit=17';
        let url = MLB.NewsEndPoint + limit + page;
        get(FOOTBALL.TransferNewsEndPoint).then((response) => {
            let tempNewsData = [];
            // eslint-disable-next-line array-callback-return
            response.data.map((data) => {
                let news = {
                    is_snack: data.is_snack,
                    news_id: data.news_id,
                    slug: data.slug,
                    news_image: data.image,
                    news_title: data.title,
                    news_url: data.url,
                    news_time: moment.tz(data.datetime, 'Europe/London').local().startOf('seconds').fromNow(),
                    news_date: moment(data.datetime).format('D MMM YYYY, H:mm'),
                    news_publisher: {
                        publisher_name: data.publisher.name,
                        publisher_icon: data.publisher.icon,
                    },
                };
                tempNewsData.push(news);
            });
            setTransferNews(tempNewsData);
            // setLoading(false);
            setTransferNewsLoading(false);
        }).catch((err) => {
            console.error(err);
        });
    };

    const fetchLeagues = () => {
        // let parameters = '?league_id=1204&limit=15';

        get(FOOTBALL.AllLeaguesEndPoint).then((response) => {
            setAllLeagues(response.data?.leagues);
            // setLoading(false);
            setAllLeaguesLoading(false);
        }).catch((err) => {
            console.error(err);
        });
    };
    const fetchLatestNews = () => {
        let page = '&page=1';
        let limit = '?limit=' + (allLeagues && (((allLeagues.length + 1) * 15) + ((allLeagues.length + 1) * 2)) || 10);


        get(FOOTBALL.LatestNewsEndPoint + limit).then((response) => {
            let tempNewsData = [];
            // eslint-disable-next-line array-callback-return
            response.data.map((data) => {
                let news = {
                    is_snack: data.is_snack,
                    news_id: data.news_id,
                    slug: data.slug,
                    news_image: data.image,
                    news_title: data.title,
                    news_url: data.url,
                    news_time: moment.tz(data.datetime, 'Europe/London').local().startOf('seconds').fromNow(),
                    news_date: moment(data.datetime).format('D MMM YYYY, H:mm'),
                    news_publisher: {
                        publisher_name: data.publisher.name,
                        publisher_icon: data.publisher.icon,
                    },
                };
                tempNewsData.push(news);
            });
            let tmp = chunk(tempNewsData, 18);
            console.log('tmp', tmp);
            setLatestNews(tmp);
            // setLoading(false);
            setLatestNewsLoading(false);
        }).catch((err) => {
            console.error(err);
        });
    };

    const fetchSpecificLeagueNews = async (league_Data) => {
        let leagueData = league_Data;
        let page = '&page=1';
        let limit = '?limit=20';
        let leagueId = '&league_id=' + leagueData?.league_id;
        let leagueName = leagueData?.name;
        await get(FOOTBALL.LatestNewsEndPoint + limit + leagueId).then((response) => {
            // eslint-disable-next-line array-callback-return

            let tempNewsData = [];
            // eslint-disable-next-line array-callback-return
            response.data.map((data, index) => {
                let news = {
                    is_snack: data.is_snack,
                    leagueId: leagueData?.league_id,
                    leagueName: leagueName,
                    leagueBG: leagueData?.bg_color,
                    leagueCL: leagueData?.font_color,
                    news_id: data.news_id,
                    slug: data.slug,
                    news_image: data.image,
                    news_title: data.title,
                    news_url: data.url,
                    news_time: moment.tz(data.datetime, 'Europe/London').local().startOf('seconds').fromNow(),
                    news_date: moment(data.datetime).format('D MMM YYYY, H:mm'),
                    news_publisher: {
                        publisher_name: data.publisher.name,
                        publisher_icon: data.publisher.icon,
                    },
                };
                tempNewsData.push(news);
            });
            (allLeagueNewsData.length <= allLeagues.length) &&
            setAllLeagueNewsData(
                allLeagueNewsData => [...allLeagueNewsData, tempNewsData]);
        }).catch((err) => {
            console.error(err);
        });
    };
    const fetchSpecificLeagueVideos = async (league_Data) => {
        let leagueData = league_Data;
        let limit = '?limit=15';
        let leagueId = '&league_id=' + leagueData?.league_id;

        await get(FOOTBALL.VideoEndPoint + limit + leagueId).then((response) => {
            // eslint-disable-next-line array-callback-return
            setAllLeagueVideoData(
                allLeagueVideoData => [...allLeagueVideoData, response.data]);
            // setLatestVideos(response.data);
        }).catch((err) => {
            console.error(err);
        });
    };

    useEffect(() => {
        if (window.screen.width <= SCREEN_SIZE.MOBILE) {
            setIsMobile(true);
        } else {
            setIsMobile(false);
        }
    }, [window.screen.width, isMobile]);

    useEffect(() => {
        fetchLeagues();
        fetchLatestNews();
        fetchTransferNews();
    }, [allLeaguesLoading]);

    useEffect(() => {
        if (allLeagues && allLeagueNewsData.length === 0 &&
            allLeagueVideoData.length === 0) {
            for (let i = 0; i < allLeagues?.length; i++) {
                (allLeagueNewsData.length <= allLeagues.length) &&
                fetchSpecificLeagueNews(allLeagues[i]);
                (allLeagueVideoData.length <= allLeagues.length) &&
                fetchSpecificLeagueVideos(allLeagues[i]);
            }
        }
        setAllLeagueNewsDataLoading(false);
        setAllLeagueVideoDataLoading(false);
        setLoading(false);
    }, [allLeagues]);

    const renderNewsSkeleton = (sport, isMobile) => {
        return (
            <Container
                className={`mt-xxl-5 mt-xl-5 mt-lg-5 mt-md-5 mt-sm-1 mt-2 mb-5`}>
                <div className={'customContainer'}>
                    <div className={'row'}>
                        <div className={'col-8'}>
                            <Skeleton count={1} height={isMobile ? '300px' : '600px'}/>
                        </div>
                        <div className={'col-4'}>
                            <Skeleton count={2} height={isMobile ? '150px' : '300px'}/>
                        </div>
                    </div>
                    <Skeleton count={3} height={isMobile ? '150px' : '300px'}
                              width={'32%'} inline={true} className={'ms-3'}/>
                </div>
            </Container>
        );
    };

    const renderMain = () => {
        return (
            <div className={'bg-white'}>
                <SubNavbar isMobile={isMobile} items={['news', 'teams', 'matches']}/>
                {
                    loading && <Loader loading={loading}/>
                }
                <GoogleAdManager isMobile={isMobile} id={isMobile ? '1704953268243' : '1704953333715'}/>
                {
                    !latestNewsLoading &&
                    <NewsPageLayout news={latestNews[0]} title={'Latest News'} isMobile={isMobile}/>
                }

                {/*{!transferNewsLoading ?
                    <NewsPageLayout news={transferNews} isMobile={isMobile} title={'Transfer News'}/> :
                    renderNewsSkeleton('Football')}*/}

                {!loading && !allLeagueNewsDataLoading && !allLeagueVideoDataLoading ?
                    <NewsPageLayout news={allLeagueNewsData} isMobile={isMobile} title={'all'} leagues={allLeagues}
                                    latestNews={latestNews} transferNews={transferNews}/>
                    // renderSpecificLeagues(allLeagueNewsData, allLeagueVideoData)
                    :
                    renderNewsSkeleton('Football')}
            </div>
        );

    };

    const renderSnackNewsList = () => {
        return (
            <div className={'bg-white'}>
                <SubNavbar isMobile={isMobile} items={['news', 'teams', 'matches']}/>
                {
                    loading && <Loader loading={loading}/>
                }
                <GoogleAdManager isMobile={isMobile} id={isMobile ? '1704953268243' : '1704953333715'}/>
                <LatestNews newsEndpoint={'/api-almet/v2.0/Football/news?mad=1&main_site=1'} isMad={true}/>
            </div>
        );
    }

    return searchParams.get('mad') ? renderSnackNewsList() : renderMain();

};

export default FootballNewsPage;
