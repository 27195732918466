import React from 'react';

const OnOffTargetAttemps = (props) => {

  const {value, index,homeColor,guestColor, homeFontColor,guestFontColor,matchStats} = props;
  let homeOffTarget = parseFloat(matchStats[0]?.stats[index + 1]?.home_value || 0);
  let awayOffTarget = parseFloat(matchStats[0]?.stats[index + 1]?.guest_value || 0);
  let total = homeOffTarget + awayOffTarget;
  let homePerc = homeOffTarget * 100 / total;
  let awayPerc = awayOffTarget * 100 / total;
  let homeOnTarget = parseFloat(value?.home_value || 0);
  let awayOnTarget = parseFloat(value?.guest_value || 0);
  let totalOT = homeOnTarget + awayOnTarget;
  let homePercOT = homeOnTarget * 100 / totalOT;
  let awayPercOT = awayOnTarget * 100 / totalOT;



  return (
      <div className={'container'}>
        <div className={'row'}>
          <div className="d-flex flex-row">
            <p className={'sfProDisplayFontBold d-flex align-items-center text-white mt-2 position-absolute font-12'}
               style={{left: '43%'}}
            >
              Off target
            </p>
            <div style={{
              height: '100px ',
              width: `${homePerc}%`,
              backgroundColor: homeColor,
            }}>
              <p className={'sfProDisplayFontBold ms-5 position-absolute mt-1 font-18'} style={{left: '0', color: homeFontColor}}>
                {homeOffTarget}
              </p>
              <div style={{
                position: 'relative',
                top: '50% ',
                left: `${(25 / homePerc) * 100}%`,
                // left: `20%`,
                backgroundColor: homeColor,
                height: '50px ',
                width: `${homeOffTarget === 0 ? 50 : homePercOT + (homePercOT < 50 && ((homePercOT/100)*50)) }%`,
                borderLeft: '5px solid white',
                borderTop: '5px solid white',
                // zIndex: awayPercOT < homePercOT ? 1 : 0,
              }}>
                <p className={'sfProDisplayFontBold position-absolute mt-2 font-18'} style={{left: '0.5em', color: homeFontColor}}>
                  {value?.home_value || 0}
                </p>
              </div>
            </div>
            <p
                style={{left: '40%', zIndex: 1}}
                className={'sfProDisplayFontBold p-1 mt-5 pt-3 d-flex align-items-center text-white mt-2 position-absolute font-12'}>
              On target
            </p>
            <div style={{
              height: '100px ',
              width: `${awayPerc}%`,
              backgroundColor: guestColor,
            }}>
              <p className={'sfProDisplayFontBold position-absolute mt-1 font-18'} style={{right: '3em', color: guestFontColor}}>
                {awayOffTarget}
              </p>
              <div style={{
                position: 'relative',
                left: `${(awayPercOT > 50 ? -awayPerc : (50 - homePerc))}%`,
                top: '50% ',
                backgroundColor: guestColor,
                height: '50px ',
                width: `${awayOffTarget === 0 ? 50 : (awayPercOT > 50 ? -awayPerc : (awayPercOT + (awayPercOT > 50 && ((awayPercOT/100)*50))))  }%`,
                // border: '5px solid white',
                borderRight: '5px solid white',
                borderTop: '5px solid white',
                // zIndex: awayPercOT > homePercOT ? 1 : 0,
              }}>
                <p className={'sfProDisplayFontBold ms-2 ps-5 mt-2 position-absolute font-18 float-end'} style={{right: '0.5em', color: guestFontColor}}>
                  {value?.guest_value || 0}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
  );
};

export default OnOffTargetAttemps