
import greenCheck from "../../assets/images/greenCheck.png";
import { BsCheckLg } from "react-icons/bs";
import desktop from "../../assets/images/desktop.png";
import {useEffect, useState} from 'react';
const LetsConnect = (props) => {
  const { isMobile } = props;
  const [backgroundSize,setBackGroundSize] = useState('600px');

  useEffect(() => {
    if (window.screen.width >= 2550) {
      setBackGroundSize('600px')
    } else if (window.screen.width >= 1360 && window.screen.width < 1920) {
      setBackGroundSize('600px')
    }
  }, [window.screen.width]);

  const renderDesktopView = () => {
    return (
        <div
            style={{height:backgroundSize}}
            className={"container-fluid blackBackgroundTwo flex-grow-1 "}>
          <div className={"container "}>
            <div className={"row "}>
              <div className={"col-md-12 col-lg-12 col-xl-12 col-xxl-12 mt-5 pt-5 d-flex align-items-center justify-content-center"}>
                <img
                    src={require('../../assets/images/v2/newFanzineLogoBlack01@3x.png')} alt={'fanzineLogo'}
                style={{
                  height:'10em',
                  width:'auto'
                }}
                />
                </div>
              <div className={"col-md-12 col-lg-12 col-xl-12 col-xxl-12 pt-3"}>
                <div className={"row justify-content-center"}>
                <span className={"text-white font-60 font-weight-800 text-center"}>
                  LET'S
                  &nbsp;
                                  <span className={"font-fanzine font-60 font-weight-800 text-center"}>
                  CONNECT
                </span>
                </span>
                </div>

                <div className={"row justify-content-center"}>
                <a href={'mailto:advertising@fanzine.com'} className={"text-white font-34 text-center text-decoration-none"}>
                  advertising@fanzine.com
                </a>
                </div>
              </div>
            </div>
          </div>
        </div>
    );
  };

  const renderMobileView = () => {
    return (
        <div
            className={"blackBackgroundTwo container-fluid"}>
          <div className={"row pb-5"}>
            <div className={"col-md-12 col-lg-12 col-xl-12 col-xxl-12 mt-3 pt-3 d-flex align-items-center justify-content-center"}>
              <img
                  src={require('../../assets/images/v2/newFanzineLogoBlack01@3x.png')} alt={'fanzineLogo'}
                  style={{
                    height:'5em',
                    width:'auto'
                  }}
              />
            </div>
          <span
              className={" font-28 text-white font-weight-800 text-center pt-3"}
          >
            LET'S CONNECT
          </span>
            <a
                href={'mailto:advertising@fanzine.com'}
                style={{
                  color:'#74dbef',
                }}
                className={"font-fanzine font-16 font-weight-300 text-center text-decoration-none"}>
                  advertising@fanzine.com
          </a>
          </div>

        </div>
    );
  };

  const renderMain = () =>
      isMobile ? renderMobileView() : renderDesktopView();

  return renderMain();
};

export default LetsConnect;
