import {BrowserRouter, Route, Routes} from 'react-router-dom';
import ScrollToTop from '../Components/ScrollToTop';
import FanzineNav from '../Components/Navbar/FanzineNav';
import HomePage from '../Pages/HomePage/HomePage';
import FootballHome from '../Pages/Football/FootballHome';
import FootballTeams from '../Pages/Football/FootballTeams';
import FanzineAllFootballMatches from '../Pages/Football/FanzineAllFootballMatches';
import FootballNewsPage from '../Pages/Football/FootballNewsPage';
import FanzineCricket from '../Pages/Cricket/Cricket';
import CricketMatches from '../Pages/Cricket/CricketMatches';
import {CricketContent} from '../Pages/Cricket/CricketContent';
import FanzineMLB from '../Pages/MLB/FanzineMLB';
import MLBMatches from '../Pages/MLB/MLBMatches';
import {MLBContent} from '../Pages/MLB/MLBContent';
import FanzineNBA from '../Pages/NBA/FanzineNBA';
import NBAMatches from '../Pages/NBA/NBAMatches';
import {NBAContent} from '../Pages/NBA/NBAContent';
import FanzineNFL from '../Pages/NFL/FanzineNFL';
import NFLMatches from '../Pages/NFL/NFLMatches';
import {NFLContent} from '../Pages/NFL/NFLContent';
import ContactUs from '../Pages/Contact';
import SingleVideoPage from '../Pages/Football/SingleVideoPage';
import FanzineFanaticDetails from '../Pages/Shop/FanzineFanaticDetails';
import Footer from '../Components/Footer';
import {Football} from '../Components/SEO/Football';
import FanzineNCAA from '../Pages/NCAA/FanzineNCAA';
import NCAAMatches from '../Pages/NCAA/NCAAMatches';
import {NCAAContent} from '../Pages/NCAA/NCAAContent';
import ProductsListPage from '../Pages/Clubs/Shop/ProductsListPage';
import NewProductListPage from '../Pages/Clubs/Shop/NewProductListPage/NewProductListPage';
import ProductDetailPage from '../Pages/Clubs/Shop/ProductDetailPage';
import NewSingleProductPage from '../Pages/Clubs/Shop/NewSingleProductPage/NewSingleProductPage';
import ShopMobile from '../Pages/Clubs/Shop/ShopMobile';
import ShopDesktop from '../Pages/Clubs/Shop/ShopDesktop';
import {useEffect, useState} from 'react';
import {SCREEN_SIZE} from '../Configs/Constants';
import Checkout from '../Pages/Clubs/Shop/Checkout';
import NewShopBag from '../Pages/Clubs/Shop/NewShopBag/NewShopBag';
import FanzineAllFootballSingleMatch from '../Pages/Football/FanzineAllFootballSingleMatch';
import FanzineFBS from "../Pages/FBS/FanzineFBS";
import FBSMatches from "../Pages/FBS/FBSMatches";
import {FBSContent} from "../Pages/FBS/FBSContent";
import FanzineShop from '../Pages/Shop/FanzineShop';
import Standings from '../Components/Standings/Standings';
import NFLStandings from '../Pages/NFL/NFLStandings';
import NBAStandings from '../Pages/NBA/NBAStandings';
import MLBStandings from '../Pages/MLB/MLBStandings';
import CricketTable from '../Pages/Cricket/CricketTable';
import VideosMain from '../Pages/FootballVideos/VideosMain';
import FanzineClubMatches from '../Pages/Football/FanzineClubMatches';
import Advertisement from '../Pages/Advertisement/Advertisement';
import NewSingleNewsPage from "../Pages/Football/NewSingleNewsPage";

const FanzineRoutes = () => {

    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        if (window.screen.width <= SCREEN_SIZE.MOBILE) {
            setIsMobile(true);
        } else {
            setIsMobile(false);
        }
    }, [window.screen.width, isMobile]);

    return (
        <BrowserRouter>
            {/*<Loader loading={loading}/>*/}
            <ScrollToTop>
                <FanzineNav/>
                <Routes>
                    <Route exact path="/" element={<HomePage/>}/>
                    <Route exact path="/advertisement" element={<Advertisement/>}/>
                    <Route exact path={'/football/teams'} element={<FootballTeams/>}/>
                    <Route exact path={'/football/video'} element={<VideosMain/>}/>
                    <Route exact path="/football/matches"
                           element={<FanzineAllFootballMatches/>}/>
                    <Route
                        exact
                        path="/football/matches/:id"
                        element={<FanzineAllFootballSingleMatch/>}
                    />
                    <Route
                        exact
                        path="/football/matches/:id/preview"
                        element={<FanzineAllFootballSingleMatch/>}
                    />
                    <Route
                        exact
                        path="/football/matches/:id/h2h"
                        element={<FanzineAllFootballSingleMatch/>}
                    />
                    <Route
                        exact
                        path="/football/matches/:id/social"
                        element={<FanzineAllFootballSingleMatch/>}
                    />
                    <Route
                        exact
                        path="/football/matches/:id/overview"
                        element={<FanzineAllFootballSingleMatch/>}
                    />
                    <Route
                        exact
                        path="/football/matches/:id/stats"
                        element={<FanzineAllFootballSingleMatch/>}
                    />
                    <Route
                        exact
                        path="/football/matches/:id/stats"
                        element={<FanzineAllFootballSingleMatch/>}
                    />
                    <Route
                        exact
                        path="/football/matches/:id/lineups"
                        element={<FanzineAllFootballSingleMatch/>}
                    />
                    <Route
                        exact
                        path="/football/matches/:id/commentary"
                        element={<FanzineAllFootballSingleMatch/>}
                    />
                    <Route path="/football/news" element={<FootballNewsPage/>}
                    />
                    <Route exact path="/football_content" element={<Football/>}/>
                    <Route
                        exact
                        path="/football/table"
                        element={<Standings/>}
                    />
                    <Route exact path={'/football'} element={<FootballHome/>}/>

                    <Route exact path={'/football/:team/'}>
                        <Route exact path={''} element={<FootballHome/>}/>
                        <Route exact path={'table'} element={<Standings/>}/>
                        <Route exact path={'matches'} element={<FanzineClubMatches/>}/>
                        <Route exact path={'video'} element={<VideosMain/>}/>
                    </Route>

                    <Route exact path="/cricket" element={<FanzineCricket/>}/>
                    <Route exact path="/cricket/matches" element={<CricketMatches/>}/>
                    <Route exact path="/cricket/:team" element={<FanzineCricket/>}/>
                    <Route exact path="/cricket/table" element={<CricketTable/>}/>
                    <Route exact path="/cricket_content" element={<CricketContent/>}/>
                    <Route exact path={'/cricket/video'} element={<VideosMain/>}/>


                    <Route exact path="/mlb" element={<FanzineMLB/>}/>
                    <Route exact path="/mlb/matches" element={<MLBMatches/>}/>
                    <Route exact path="/mlb/table" element={<MLBStandings/>}/>
                    <Route exact path="/mlb/:team" element={<FanzineMLB/>}/>
                    <Route exact path="/mlb_content" element={<MLBContent/>}/>
                    <Route exact path={'/mlb/video'} element={<VideosMain/>}/>


                    <Route exact path="/nba" element={<FanzineNBA/>}/>
                    <Route exact path="/nba/matches" element={<NBAMatches/>}/>
                    <Route exact path="/nba/table" element={<NBAStandings/>}/>
                    <Route exact path="/nba/:team" element={<FanzineNBA/>}/>
                    <Route exact path="/nba_content" element={<NBAContent/>}/>
                    <Route exact path={'/nba/video'} element={<VideosMain/>}/>


                    <Route exact path="/ncaam" element={<FanzineNCAA/>}/>
                    <Route exact path="/ncaam/matches" element={<NCAAMatches/>}/>
                    <Route exact path="/ncaam/:team" element={<FanzineNCAA/>}/>
                    <Route exact path="/ncaam_content" element={<NCAAContent/>}/>


                    <Route exact path="/nfl" element={<FanzineNFL/>}/>
                    <Route exact path="/nfl/matches" element={<NFLMatches/>}/>
                    <Route exact path="/nfl/table" element={<NFLStandings/>}/>
                    <Route exact path="/nfl/:team" element={<FanzineNFL/>}/>
                    <Route exact path="/nfl_content" element={<NFLContent/>}/>
                    <Route exact path={'/nfl/video'} element={<VideosMain/>}/>


                    <Route exact path="/ncaaf" element={<FanzineFBS/>}/>
                    <Route exact path="/ncaaf/matches" element={<FBSMatches/>}/>
                    <Route exact path="/ncaaf/:team" element={<FanzineFBS/>}/>
                    <Route exact path="/ncaaf_content" element={<FBSContent/>}/>

                    <Route exact path="/contact" element={<ContactUs/>}/>

                    {/*<Route path="/:sport/:team/:id" element={<SingleNewsPage/>}/>*/}
                    {/*<Route path="/:sport/news/:id" element={<SingleNewsPage/>}/>*/}
                    <Route path="/:sport/:team/:id" element={<NewSingleNewsPage/>}/>
                    <Route path="/:sport/news/:id" element={<NewSingleNewsPage/>}/>
                    <Route path="/:sport/video/:id" element={<SingleVideoPage/>}/>

                    {/*<Route path="/shop" element={<FanzineFanatics/>}/>*/}
                    <Route path="/shop/product/:id" element={<FanzineFanaticDetails/>}/>
                    <Route exact path="/:teamName/shop/">

                        <Route path=":filter" element={isMobile ?
                            <ProductsListPage/> :
                            <NewProductListPage/>}>
                            <Route path=":filter1" element={isMobile ?
                                <ProductsListPage/> :
                                <NewProductListPage/>}>
                                <Route path=":filter2" element={isMobile ?
                                    <ProductsListPage/> :
                                    <NewProductListPage/>}/>
                            </Route>
                        </Route>

                        <Route exact path="product/:id" element={isMobile ?
                            <ProductDetailPage/> :
                            <NewSingleProductPage/>}
                        />

                        <Route exact path="checkout" element={isMobile ? <Checkout/> : <NewShopBag/>}/>

                        <Route exact path=""
                               element={isMobile ? <ShopMobile/> : <ShopDesktop/>}/>

                    </Route>
                    <Route path="/shop" element={<FanzineShop/>}/>
                </Routes>
                <Footer/>
            </ScrollToTop>
        </BrowserRouter>
    );
};

export default FanzineRoutes;
