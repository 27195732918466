import FanzineNewsCard from '../../Cards/FanzineNewsCard';
import Social from '../Social/Social';
import React from 'react';
import SkySportsAd from '../../Ads/SkySportsAd';
import NewsOnSlider from '../../Slider/NewsOnSlider';
import DMPU from '../../Ads/DMPU';
import GoogleAd from '../../Ads/GoogleAd';
import Billboard from '../../Ads/Billboard';
import RightNewsAds from './RightNewsAds';

const NewsPageLayout = (props) => {
    const {news, isMobile, title, leagues, latestNews, transferNews} = props;
    let LeagueName = '';
    const renderNews = (data, tmpTitle) => {

        return (
            <div className={`container ${isMobile ? 'pt-5' : 'mt-5'}`}>
                <div className={`container ${isMobile && 'pt-5'} `}>
                    <div className={`row ${isMobile && 'pt-3'}`}>
                        <div
                            className={`col-xxl-8 col-xl-8 col-lg-8 col-md-8 col-sm-12`}>
                            {title !== 'Latest News' && <GoogleAd id={'4009154580'}/>}
                            <div className={'row pt-5'}>
                                {(data !== undefined && data !== null && tmpTitle !== 'all') && <div
                                    className={'position-absolute mt-n2r pb-1  bg-fanzine-dark robotoCondensedBold font-28'}
                                    style={{
                                        zIndex: 1,
                                        width: '250px',
                                        padding: '8px 0 8px 10px',
                                        transform: 'skew(-10deg)',
                                    }}
                                >
                                    {tmpTitle === 'all' ? data[0]?.leagueName : tmpTitle}
                                </div>
                                }
                                {(data !== undefined && data !== null && tmpTitle !== 'all') && <hr style={{height: '3px'}}/>}
                                {(data !== undefined && data !== null) && data?.map((newsInData, index) => {
                                    let bigImage = (index === 0 || index === 3);
                                    return index <= 15 &&
                                        (
                                            <div
                                                className={`m-0 ${bigImage ?
                                                    `col-12 mt-4 p-1 ${index === 3 && 'd-flex justify-content-center'} ` :
                                                    'col-xl-6 col-lg-6 col-md-6 p-1'} col-sm-12 col-xs-12`}
                                            >
                                                {index === 3 ? <SkySportsAd/> :
                                                    <FanzineNewsCard
                                                        news={newsInData}
                                                        imageHeight={bigImage && !isMobile ? '500px' : '245px'}
                                                    />
                                                }
                                            </div>
                                        );
                                })
                                }
                                {/*{(data === undefined || data === null) && <LatestNews isMobile={isMobile} isNewsPage={true} limit={leagueCount}/>}*/}
                            </div>
                            <GoogleAd id={'4009154580'}/>
                        </div>

                        {!isMobile && <RightNewsAds isMobile={isMobile} />}
                    </div>
                </div>
            </div>
        );
    };

    const renderSpecificLeagueNews = (data, latestNews, transferNews) => {

        return data?.map((singleLeague, singleIndex) => {
            return (
                <>
                    <NewsOnSlider isMobile={isMobile} id={singleIndex}
                                  bgColor={singleLeague[0]?.leagueBG}
                                  fontColor={singleLeague[0]?.leagueCL}
                                  matchData={singleLeague} loading={false}
                                  leagueName={title === 'all' ? singleLeague[0]?.leagueName : title}
                    />
                    {singleIndex === 0 ? renderNews(transferNews, 'Transfer News') : renderNews(latestNews[singleIndex + 1], singleIndex === 1 ? 'More Stories' : 'all')}
                </>
            );
        });

    };

    const renderMain = (data, latestNews, transferNews) => {
        return (title === 'all' ? renderSpecificLeagueNews(data, latestNews, transferNews) : renderNews(data, title));
    };

    return renderMain(news, latestNews, transferNews);

};
export default NewsPageLayout;
