import {Link, useLocation} from 'react-router-dom';
import FanzineSportsHomeMobileMenuItem from './FanzineSportsHomeSingleNavItem';
import {useEffect, useState} from 'react';
import {NAVBAR_ITEMS} from '../../../Configs/Constants';
import {MLB, NBA, NFL} from '../../../Axios/EndPoints';
import {IoAmericanFootballOutline, IoBasketballOutline} from 'react-icons/io5';
import {GiBaseballBat, GiCricketBat} from 'react-icons/gi';
import {useSelector} from 'react-redux';
import {LOGO} from '../../../Configs/Targets';
import {
  FaApple,
  FaFacebook,
  FaGlobe,
  FaInstagram,
  FaTwitter,
} from 'react-icons/fa';
import {
  FANZINESPORTS_HOMEPAGE_LEAGUES,
} from '../../../Constants/JS/FanzineSportsLeague';

const FanzineSportsHomeSubNavBar = (props) => {

  const location = useLocation();
  const store = useSelector(state => state);
  const {isMobile, data, teamData} = props;

  const [currentActive, setCurrentActive] = useState('');
  const [clubIcon, setClubIcon] = useState(null);
  const [sportName, setSportName] = useState((location.pathname.split('/'))[1]);

  useEffect(() => {

    setSportName((location.pathname.split('/'))[1]);

    if (data || teamData?.icon) {
      if (data !== null && store.misc.targetsApps !== null) {
        let ifApp = store.misc.targetsApps.filter(
            (value) => value.team_id === (data?.clubId || 0),
        );

        if (ifApp.length > 0 && ifApp[0].betting_urls.web_url !== '') {
          let targetTitle = ifApp[0].betting_urls.web_url.split('.')[1];
          setClubIcon(LOGO[targetTitle]);
          Object.assign(data, {clubUrl: ifApp[0].betting_urls.web_url});
          // setClubIcon(ifApp[0].)
        } else {
          setClubIcon(data?.clubIcon || teamData?.icon);
        }
      } else {
        setClubIcon(data?.clubIcon || teamData?.icon);
      }
    } else {
      setClubIcon(require('../../../assets/images/cfootball.png'));
    }

    if (isMobile && (location.pathname.includes('table') ||
        location.pathname.includes('matches'))) {
      const element = document.getElementById('slider-mobile');
      element.scrollLeft = 350;
    }

  }, [data, teamData, props.items, clubIcon]);

  const renderMobileMenu = () => {

    return (
        <div className={'container-fluid'}
        >
          <div
              id={'slider-mobile'}
              className={`slider-mobile-news-container`}>
            {/*<FanzineSportsHomeMobileMenuItem*/}
            {/*    inactiveFontColor={'#1f1f1f'} isMobile={isMobile}*/}
            {/*    setCurrentActive={setCurrentActive} title={'Premier League'}*/}
            {/*    uri={'/premier-league'}*/}
            {/*    isFanzineSports={true}*/}
            {/*    // selected={location.pathname === '/football'}*/}
            {/*    selected={location.pathname === '/premier-league'}*/}
            {/*/>*/}
            {
              FANZINESPORTS_HOMEPAGE_LEAGUES?.map((value, index) => (
                  <FanzineSportsHomeMobileMenuItem
                      inactiveFontColor={'#1F1F1FFF'}
                      isMobile={isMobile}
                      setCurrentActive={setCurrentActive}
                      title={value?.leagueName}
                      isFanzineSports={true}
                      uri={`/${value?.slug}`}
                      selected={location.pathname.includes(value?.slug)}/>
              ))
            }
          </div>
        </div>
    );
  };

  const renderBigView = () => {

    let defaultImage = '';
    let sportNameHeader = '';
    switch (location.pathname.split('/')[1]) {
      case 'football':
        defaultImage = require('../../../assets/images/default-league.png');
        sportNameHeader = 'Football';
        break;
      case 'cricket':
        sportNameHeader = 'Cricket';
        break;
      case 'nfl':
        sportNameHeader = 'NFL';
        break;
      case 'nba':
        sportNameHeader = 'NBA';
        break;
      case 'mlb':
        sportNameHeader = 'MLB';
        break;
      default:
        defaultImage = require('../../../assets/images/default-league.png');
        sportNameHeader = 'Football';

        break;
    }

    let tempSocial = {
      twitter: '',
      facebook: '',
      instagram: '',
      ios: '',
      android: '',
      web: '',
    };
    let leagueName = '';

    for (let i = 0; i < store?.misc?.targetsApps?.length; i++) {

      if (store?.misc?.targetsApps[i]?.team_id === data?.clubId) {

        tempSocial.twitter = store?.misc?.targetsApps[i].twitter_url;
        tempSocial.facebook = store?.misc?.targetsApps[i].facebook_url;
        tempSocial.instagram = store?.misc?.targetsApps[i].instagram_url;
        tempSocial.ios = store?.misc?.targetsApps[i].ios_default_app_url;
        tempSocial.android = store?.misc?.targetsApps[i].android_default_app_url;
        tempSocial.web = store?.misc?.targetsApps[i].web_default_app_url;
        leagueName = store?.misc?.targetsApps[i]?.league_name;

      }
    }

    let clubAltName = '';
    if (data) {
      clubAltName = data?.clubUrl?.split('.')[1];
      console.log('clubAltName =', clubAltName);
    }

    return (
        <div className={'container-fluid'}
        >
          {isMobile && <div
              className={`row -flex align-items-xxl-baseline ${!props?.isClub &&
              'pt-2'}`}>
            {<div
                className={`${'d-flex align-items-center col-xxl-2 col-xl-2 col-lg-3 col-md-4 col-sm-5'} text-black ${(data ||
                    teamData) &&
                `robotoCondensedBold ${data?.clubName?.length >
                10 ? `font-16` : `font-20`} font-weight-800`}`}>
              {
                  (!data && !teamData?.icon) && sportNameHeader ===
                  'Football' &&
                  <img src={(teamData?.sportIcon || defaultImage)}
                       alt={`Fanzine Football Logo`} height={50}
                       style={{zIndex: -1}}/>
              }
              {
                  sportNameHeader === 'NFL' &&
                  <IoAmericanFootballOutline size={'50'}/>
              }
              {
                  sportNameHeader === 'NBA' &&
                  <IoBasketballOutline size={'50'}/>
              }
              {
                  sportNameHeader === 'MLB' &&
                  <GiBaseballBat size={'50'}/>
              }
              {
                  sportNameHeader === 'Cricket' &&
                  <GiCricketBat size={'50'}/>
              }
              {props?.isClub && location.pathname.includes('football') &&
                  !location.pathname.includes('women') &&
                  <img src={(data?.clubIcon || teamData?.icon || defaultImage)}
                       alt={`Fanzine Football Logo`} height={59} width={59}
                       style={{zIndex: -1, objectFit: 'contain'}}/>
              }
              {props?.isClub && !location.pathname.includes('football') &&
                  <img src={(data?.clubIcon || teamData?.icon || defaultImage)}
                       alt={`Fanzine Football Logo`} height={50} width={50}
                       className={'mt-2'}
                       style={{zIndex: -1, objectFit: 'contain'}}/>
              }
              {props?.isClub && location.pathname.includes('women') &&
                  <img src={(data?.clubIcon || teamData?.icon || defaultImage)}
                       className={'mt-2'}
                       alt={`Fanzine Football Logo`} height={50} width={50}
                       style={{zIndex: -1, objectFit: 'contain'}}/>
              }
              <div className={'ms-2'}>
                <p className={'m-0 p-0 font-24 text-decoration-none sfProDisplayFontBold text-nowrap'}>
                  {data?.clubName || teamData?.name ||
                      sportNameHeader}
                </p>
                <div className={'mt-n1'}>
                  {props?.isClub && <p
                      style={{
                        fontWeight: 500,
                      }}
                      className={'m-0 p-0 font-14 text-decoration-none sfProDisplayFont text-nowrap'}>
                    {leagueName || ''}
                  </p>
                  }
                </div>

              </div>
            </div>}
            <div className={'col-10 pt-2'}>
              {props?.isClub && <div
                  className={'row d-flex align-items-end justify-content-end'}>
                <div
                    className={`${'d-flex align-items-center justify-content-end col-10'}`}>
                  {/*{(data || teamData?.icon) && <div*/}
                  {/*    >*/}
                  {
                    <img src={clubIcon}
                         alt={(data?.clubName || teamData?.name) +
                             'logo'}
                         style={{
                           height: '60.5px',
                           width: '58.5px',
                           objectFit: 'contain',
                           display: data?.clubUrl?.split('.')[1] ?
                               'block' :
                               'none',
                         }}
                         onClick={() => {
                           if (data.clubUrl) {
                             window.open(data.clubUrl,
                                 '_blank');
                           }
                         }
                         }
                    />
                  }
                  <span
                      className={'p-0 m-0 font-weight-800 font-16  '}>
                                    &nbsp;{data?.clubUrl?.split('.')[1] ?
                      data?.clubUrl?.split('.')[1].toUpperCase() :
                      ''}
                    {/*data?.clubName || teamData?.name*/}
                    <br/>
                    {/*<p className={'font-12 pt-n150 pe-3'}>*/}
                    {/*    Fan Community*/}
                    {/*</p>*/}
                                </span>
                  {/*</div>*/}
                  {/*}*/}
                </div>
                <div className={'col-2'}>
                  <div className={'row'}>
                    <div className={'col-12 pb-1'}>
                      <div
                          className={'d-flex align-items-center'}>
                        {(tempSocial?.twitter !== null &&
                                tempSocial?.twitter !== '') &&
                            <a style={{
                              color: '#1F1F1FFF',
                              cursor: 'pointer',
                            }}
                               href={tempSocial.twitter}
                               target="_blank"
                               rel="noopener noreferrer">
                              <FaTwitter size={25}/>
                            </a>
                        }                            &nbsp;
                        &nbsp;
                        &nbsp;
                        {(tempSocial?.facebook !== null &&
                                tempSocial?.facebook !== '') &&
                            <a style={{
                              color: '#1F1F1FFF',
                              cursor: 'pointer',
                            }}
                               href={tempSocial.facebook}
                               target="_blank"
                               rel="noopener noreferrer">
                              <FaFacebook size={25}/>
                            </a>
                        }                            &nbsp;
                        &nbsp;
                        &nbsp;
                        {(tempSocial?.instagram !== null &&
                                tempSocial?.instagram !== '') &&
                            <a style={{
                              color: '#1F1F1FFF',
                              cursor: 'pointer',
                            }}
                               href={tempSocial.instagram}
                               target="_blank"
                               rel="noopener noreferrer">
                              <FaInstagram size={25}/>
                            </a>
                        }
                      </div>

                    </div>
                    <div className={'col-12'}>
                      <div
                          className={'d-flex align-items-center'}>
                        {(tempSocial?.twitter !== null &&
                                tempSocial?.twitter !== '') &&
                            <a style={{
                              color: '#1F1F1FFF',
                              cursor: 'pointer',
                            }}
                               href={tempSocial.ios}
                               target="_blank"
                               rel="noopener noreferrer">
                              <FaApple size={25}/>
                            </a>
                        }                            &nbsp;
                        &nbsp;
                        &nbsp;
                        {(tempSocial?.facebook !== null &&
                                tempSocial?.facebook !== '') &&
                            <a style={{
                              color: '#1F1F1FFF',
                              cursor: 'pointer',
                            }}
                               href={tempSocial.android}
                               target="_blank"
                               rel="noopener noreferrer">
                              <img src={require(
                                  '../../../assets/images/v2/android-black.png')}
                                   alt={'android-logo'}
                                   style={{
                                     height: '25px',
                                     width: '21px',
                                   }}/>
                            </a>
                        }                            &nbsp;
                        &nbsp;
                        &nbsp;
                        {(tempSocial?.instagram !== null &&
                                tempSocial?.instagram !== '') &&
                            <a style={{
                              color: '#1F1F1FFF',
                              cursor: 'pointer',
                            }}
                               className={'ps-1'}
                               href={tempSocial.web}
                               target="_blank"
                               rel="noopener noreferrer">
                              <FaGlobe size={25}/>
                            </a>
                        }
                      </div>

                    </div>
                  </div>
                </div>
              </div>
              }                    </div>
          </div>
          }
          {isMobile && <hr style={{opacity: 0.1, border: '1px solid  black'}}/>}
          <div>
            {
              isMobile ? renderMobileMenu()
              :
                  (
                      <div
                          className={`d-flex flex-row  justify-content-between ps-3 pt-4 mt-2`}>
                        {FANZINESPORTS_HOMEPAGE_LEAGUES?.map((value, index) => {

                          return value?.main && (
                              <Link to={`/${value?.slug}`}
                                    className={`${isMobile ?
                                        'text-black' :
                                        'text-black pt-3 pb-3'} pe-5   text-decoration-underline-fanzine
                                                          ${(location.pathname.includes(
                                        `/${value?.slug}`)) ?
                                        'active font-weight-800' :
                                        ''}
                                            `}>
                                {value?.leagueName}
                              </Link>
                          );
                        })}
                      </div>
                  )
            }
          </div>
        </div>

    );
  };

  const render = () => {
    return (
        <div
            className={`${isMobile ?
                'w-100  align-items-center mt-0 p-1' :
                ''} d-flex fanzine-football-nav`}
            style={{
              // marginTop: isMobile ? '40px' : '',
              height: isMobile ? '8.5em' : '5.5em',
              position: isMobile ? '' : 'relative',
              zIndex: 0,
            }}
            id={'subNavbarBg'}
        >
          <div
              className={`${isMobile ? '' : ''} container`}>
            <div className={`${isMobile ? '' : 'ps-5'} row ${isMobile ? 'pt-3' :
                'ms-3'}`}
                 style={{
                   // zIndex: 99999,
                   // zIndex: 1027,
                   position: 'sticky',
                   background: '#74dbef',
                 }}
                 id={'subNavbar'}
            >
              {isMobile ?
                  renderMobileMenu()
                  : renderBigView()}
            </div>
          </div>
        </div>
    );
  };

  return render();
};

export default FanzineSportsHomeSubNavBar;
