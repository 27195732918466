import {useSelector} from 'react-redux';
import React, {useEffect, useState} from 'react';
import ImageCarousel from '../Components/Carousel/ImageCarousel';
import {SCREEN_SIZE} from '../Configs/Constants';
import {graphql} from '../Axios/graphql';
import {ShopifyGraphQlQueries} from '../Configs/ShopifyGraphQlQueries';
import ProductComponent from '../Components/ClubShop/ProductComponent';
import {FONT_COLOR, TEXT_COLOR} from '../Configs/Targets';

const Shop = (props) => {

  const {target,isMobile} = props;

  const store = useSelector(state => state);
  const [bestSelling, setBestSelling] = useState([]);

  const fetchBestSelling = () => {
    graphql(ShopifyGraphQlQueries('BEST_SELLING'))
        .then((response) => {
          let products = response.data.data['collectionByHandle']['products'].edges;
          if (products.length) {
            let tempBestSelling = [];
            products.map((value) => {
              let product = value.node;
              tempBestSelling.push({
                id: product.id,
                name: product.title,
                src: product.images && product.images.edges[0].node.src,
                alt: product.images && product.images.edges[0].node.alt,
                handle: product.handle,
                price: product['variants'].edges[0].node.price,
                oldPrice: product['variants'].edges[0].node.compareAtPrice,
              });
              return tempBestSelling;
            });
            setBestSelling(tempBestSelling);
          }
        });
  };

  useEffect(() => {
    fetchBestSelling();
  }, []);

  const render2 = () => {
    if (bestSelling.length) {
      return (
          <div className={`w-100`}>
            <div className={'row'}>
              <div className={isMobile ? 'd-none' : ''}>
                <h2 className={`robotoCondensedBold font-italic font-28 ${[
                  'theyellows',
                  'wearewolves'].includes(target) ?
                    FONT_COLOR[target] :
                    TEXT_COLOR[target]}`}>
                  New Releases
                </h2>
              </div>
              <div className={`${isMobile ? 'd-none col-6' : 'col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12'}`}>
                <ProductComponent item={bestSelling[0]} showColors={true}
                                  showDetail={true}/>
              </div>
              <div className={`${isMobile ? 'd-none col-6' : 'col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12'}`}>
                <ProductComponent item={bestSelling[1]} showColors={true}
                                  showDetail={true}/>
              </div>
            </div>
          </div>
      );
    }
    return (<></>);
  };

  const render = () => {

    let shopContainerImages = [];

    store.shopify.containerImages.map((value, index) => (
        shopContainerImages.push({
          id: value.product_id,
          slug: value.product_slug,
          title: value.overlay_text,
          buttonText: value.button_text,
          src: value.image,
        })
    ));

    const bestSellingSliced = bestSelling?.length > 10 ? bestSelling.slice(0,10) : bestSelling;

    return (
        <div className={`${isMobile ? 'w-100' : 'container'}`}>
          {!isMobile && shopContainerImages?.length > 0 &&
              <ImageCarousel images={shopContainerImages} showLabel={true}
                             showTitle={true} showProduct={true}
                             shopMobile={window.innerWidth <=
                                 SCREEN_SIZE.MOBILE}/>
          }
          {isMobile && bestSellingSliced?.length > 0 &&
              <ImageCarousel images={bestSellingSliced} showLabel={true}
                             showTitle={true} showProduct={true}
                             shopMobile={window.innerWidth <=
                                 SCREEN_SIZE.MOBILE}/>
          }
          {!isMobile && bestSelling.length && render2()}
        </div>
    );
  };

  return render();

};

export default Shop;
