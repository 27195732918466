import React, {useEffect, useState} from 'react';
import {SCREEN_SIZE} from '../../Configs/Constants';
import {SiteMetaHelmet} from '../../Components/SiteMetaHelmet';
import {SubNavbar} from '../../Components/Navbar/SubNavbar';
import GoogleAd from '../../Components/Ads/GoogleAd';
import {useSelector} from 'react-redux';
import {useLocation} from 'react-router-dom';
import {get} from '../../Axios/get';
import {FANZINE, NBA} from '../../Axios/EndPoints';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faAngleUp} from '@fortawesome/free-solid-svg-icons';
import {faAngleDown} from '@fortawesome/free-solid-svg-icons/faAngleDown';
import ItemLoader from '../../Components/Loader/ItemLoader';
import {getHostname} from '../../CustomFunctions/getHostname';
import {
  COLORS,
  COLORS_RGBA,
  FONT_COLOR,
  NAV_CLASS,
  TEXT_COLOR,
} from '../../Configs/Targets';
import {Button} from 'react-bootstrap';
import RightNewsAds from '../../Components/Layout/News/RightNewsAds';

const NBAStandings = () => {
  // store
  const allSportsStore = useSelector(
      state => state.football.fanzineTeamsLeagues);

  // states
  let [isMobile, setIsMobile] = useState(false);
  let [selectedTeam, setSelectedTeam] = useState(null);
  let [fullTable, setFullTable] = useState(false);
  let [standings, setStanding] = useState(null);
  let [moreStandings, setMoreStanding] = useState(null);
  let [loading, setLoading] = useState(true);
  let [conferences, setConferences] = useState(null);
  let [selectedConference, setSelectedConferece] = useState(null);
  let [divisions, setDivisions] = useState(null);

  // hooks call
  const location = useLocation();

  // fetch data Methods
  const fetchStandingsData = () => {

    let params = '?by=conference';
    let URL = FANZINE.SportsStandingsEndPoint.replace('__sport__', 'NBA');

    get(URL + params).then((res) => {

      let tempConferences = [];

      res?.data?.map((value) => {
        let tmpResponse = [];

        tmpResponse = value?.standings;

        tmpResponse.sort(function(a, b) {
          return b?.wins - a?.wins;
        });

        let temp = {
          league_name: value?.league_name,
          league_id: value?.league_id,
          standings: tmpResponse,
        };
        tempConferences.push(temp);
      });
      setSelectedConferece(tempConferences[0] ? tempConferences[0] : null);
      setConferences(tempConferences);
      setStanding(res.data);
      setLoading(false);

    }).catch(error => console.error('error =', error));
  };

  const fetchDivisions = () => {
    let params = '?by=division';
    let URL = FANZINE.SportsStandingsEndPoint.replace('__sport__', 'NBA');

    get(URL + params).then((res) => {

      let tempDivisions = [];

      console.log('res .data =', res.data);

      res?.data?.map((value) => {
        let tmpResponse = [];
        tmpResponse = value?.standings;
        tmpResponse.sort(function(a, b) {
          return b?.wins - a?.wins;
        });

        let temp = {
          league_icon: value?.league_icon,
          league_name: value?.league_name,
          league_id: value?.league_id,
          standings: tmpResponse,
          league_new_icon: value?.league_new_icon,
        };
        tempDivisions.push(temp);
      });
      // setSelectedConferece(tempDivisions[0] ? tempDivisions[0] : null);
      // setConferences(tempDivisions);
      // setStanding(res.data);
      setDivisions(tempDivisions);

      setLoading(false);

    }).catch(error => console.error('error =', error));
  };

  // useEffect
  useEffect(() => {
    if (window.screen.width <= SCREEN_SIZE.MOBILE) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, [window.screen.width, isMobile]);

  useEffect(() => {

    let sportName = location.pathname.split('/')[1];
    let teamName = location.pathname.split('/')[2];

    let selectedSport = [];
    let teamDataToBeSend = [];

    selectedSport = allSportsStore?.filter(sport => {
      return sport?.name?.toLowerCase() === sportName;
    });

    for (let i = 0; i < selectedSport[0]?.leagues?.length; i++) {
      for (let j = 0; j < selectedSport[0]?.leagues[i]?.teams?.length; j++) {
        if (selectedSport[0]?.leagues[i]?.teams[j].name.toLowerCase()
                .replaceAll(' ', '-') ===
            teamName) {
          teamDataToBeSend = selectedSport[0]?.leagues[i]?.teams[j];
        }
      }
    }

    Object.assign(teamDataToBeSend, {
      sportIcon: selectedSport[0]?.icon || selectedSport?.icon,
      name: teamDataToBeSend.name || 'NBA',
    });

    setSelectedTeam(teamDataToBeSend || null);

  }, [allSportsStore]);

  useEffect(() => {
    fetchStandingsData();
    fetchDivisions();
  }, []);

  const handleConferenceSelect = (value) => {
    setSelectedConferece(value);
  };

  // render Methods
  const renderConferenceButtons = () => {
    return (
        <div
            className={isMobile ? 'slider-mobile-news-container' : 'container'}>
          <Button className={isMobile ? 'p-0 slider-mobile-news-menu' : 'p-2'}
                  onClick={() => handleConferenceSelect(divisions)}
                  style={{
                    borderRadius: '21px',
                    fontSize: isMobile && '14px',
                    height: isMobile ? '37px' : '42px',
                    width: isMobile ? '137px' : '200px',
                    margin: isMobile ?
                        '20px 1px 20px 14px' :
                        '32px 20px 41px 11px',
                    padding: isMobile ? '11px 21px' : '12px 71px 11px',
                    backgroundColor: selectedConference === divisions ?
                        '#74dbef' :
                        '#f3f3f3',
                    border: selectedConference !== divisions ?
                        'solid 0.5px #bebebe' :
                        '0px solid transparent',
                  }}
                  variant={'outline-dark'}
          >
            Division
          </Button>

          {
            conferences?.map((value, index) => (
                <Button
                    className={isMobile ? 'p-0 slider-mobile-news-menu' : 'p-2'}
                    key={index}
                    onClick={() => handleConferenceSelect(value)}
                    style={{
                      borderRadius: '21px',
                      fontSize: isMobile && '14px',
                      height: isMobile ? '37px' : '42px',
                      width: isMobile ? '137px' : '200px',
                      margin: isMobile ?
                          '20px 1px 20px 14px' :
                          '32px 20px 41px 11px',
                      padding: isMobile ? '11px 21px' : '12px 71px 11px',
                      backgroundColor: selectedConference?.league_name ===
                      value?.league_name ? '#74dbef' : '#f3f3f3',
                      border: selectedConference?.league_name !==
                      value?.league_name ?
                          'solid 0.5px #bebebe' :
                          '0px solid transparent',
                    }}
                    variant={'outline-dark'}
                >
                  {value?.league_name}
                </Button>
            ))
          }
        </div>);
  };
  const renderMobilePartialTable = (standingBgColor, textColor, color) => {
    return (
        <div className={'w-100 ms-auto me-auto'}>
          {/*<div className={`bebasFont font-weight-800 text-header`}*/}
          {/*     style={{fontSize: '30px'}}>*/}
          {/*  {'English Premier League'}*/}
          {/*</div>*/}

          {renderConferenceButtons()}
          <div className={'ps-3 bg-background'}>
            <p
                style={{
                  width: '154px',
                  height: '24px',
                  fontSize: '14px',
                  fontWeight: '900',
                  fontStretch: 'normal',
                  fontStyle: 'normal',
                  letterSpacing: 'normal',
                  textAlign: 'left',
                  color: '#000',
                }}
            >
              {selectedConference?.league_name}
            </p>
          </div>
          <div className={'container-fluid bg-white pb-2 mb-2'}>
            <div

                style={{
                  color: '#a3a3a3',
                }}
                className={`font-14 rounded-bottom-custom text-center sfProDisplayFont  row`}>
              <div className={'col-1'}>#</div>
              <div className={'col-5'}
                   style={{marginLeft: '-15px'}}
              >Teams
              </div>
              <div className={'col-1'}>W</div>
              <div className={'col-1'}>L</div>
              <div className={'col-1'}>PCT</div>
              <div className={'col-1'}>GB</div>
              <div className={'col-1'}>L10</div>
              <div className={'col-1'}>STRK</div>
            </div>
            {(selectedConference !== null &&
                selectedConference?.standings)?.map((value, index) => (
                <div className={`row sfProDisplayFont`}>
                  <div className={`text-center font-11 col-1`}><span
                      className={'sfProDisplayFont'}>{index + 1}.</span>
                  </div>
                  <div className={'font-11 col-5'}
                       style={{marginLeft: '-15px'}}
                  >
                    <img src={value.team.icon} height={20} alt=""/>
                    &nbsp;
                    &nbsp;
                    <span
                        className={'sfProDisplayFont text-center text-nowrap'}>{value.team.short_name}</span>
                  </div>
                  <div
                      className={`text-center font-11 col-1 text-nowrap`}>{value?.wins}</div>
                  <div
                      className={`text-center font-11 col-1 text-nowrap`}>{value?.lost}</div>
                  <div className={`text-center font-11 col-1 text-nowrap`}>
                    {(parseFloat(value?.percentage) * 100).toFixed(1)}
                  </div>
                  <div
                      className={`text-center font-11 col-1 text-nowrap`}>{value?.gb}</div>
                  <div
                      className={`text-center font-11 col-1 text-nowrap`}>{value?.last_10}</div>
                  <div
                      className={`text-center font-11 col-1 text-nowrap`}>{value?.streak}</div>
                </div>
            ))}
          </div>
        </div>
    );
  };
  const renderMobileFullTable = (standingBgColor, textColor, color) => {
    return (
        <>
          <div className={`bebasFont font-weight-800 text-header`}
               style={{fontSize: '30px'}}>
            {'English Premier League'}
          </div>
          <div className={`mt-3`}>

          </div>
          <table>
            <thead>
            <tr className={`bg-fixtures rounded-bottom-custom text-center sfProDisplayFont small`}>
              <td></td>
              <td style={{width: '130px'}}>Teams</td>
              <td>P</td>
              <td>W</td>
              <td>D</td>
              <td>L</td>
              <td>F/A</td>
              <td>GD</td>
              <td>Pts</td>
            </tr>
            </thead>
            <tbody>
            {moreStandings?.length &&
                moreStandings?.map(((value, index, array) => (
                    <tr className={`text-light-black sfProDisplayFont small`}
                        style={value.background}>
                      <td className={`ps-1 text-center`}><span
                          className={'sfProDisplayFont'}>{value.position}. &nbsp;</span>
                      </td>
                      <td className={`text-center`}>
                        <img src={value.team.icon} height={20}
                             alt=""/>
                        <span className={'sfProDisplayFont ms-2 text-wrap'}>
                                                         {value.team.short_name}
                                                    </span>
                      </td>
                      <td className={`text-center`}>{value.pl}</td>
                      <td className={`text-center`}>{value.win}</td>
                      <td className={`text-center`}>{value.draw}</td>
                      <td className={`text-center`}>{value.lose}</td>
                      <td className={`text-center`}>{value.gd2}</td>
                      <td className={`text-center`}>{value.gd}</td>
                      <td className={`text-center`}>{value.pts}</td>
                    </tr>
                )))}

            </tbody>
          </table>
          {moreStandings?.length &&
              <li className={`p-3 bg-white text-end sfProDisplayFontBold ${textColor} rounded-bottom-custom btn`}
                  onClick={() => {
                    setFullTable(!fullTable);
                    window.scroll({
                      top: 1850,
                      behavior: 'smooth',
                    });
                  }}
              >
                {fullTable ? 'Collapse' : 'See full table'} &nbsp;&nbsp;&nbsp;
                <FontAwesomeIcon icon={fullTable ? faAngleUp : faAngleDown}
                                 color={color}
                                 size={'1x'}/>
              </li>}
          {/**/}
        </>
    );
  };

  const renderMobilePartialTableDivision = (
      standingBgColor, textColor, color, Value, ind) => {
    return (
        <div
            key={ind}
            className={'w-100 ms-auto me-auto'}>
          {/*<div className={`bebasFont font-weight-800 text-header`}*/}
          {/*     style={{fontSize: '30px'}}>*/}
          {/*  {'English Premier League'}*/}
          {/*</div>*/}

          {ind === 0 && renderConferenceButtons()}
          <div className={'ps-3 bg-background'}>
            <p
                style={{
                  width: '154px',
                  height: '24px',
                  fontSize: '14px',
                  fontWeight: '900',
                  fontStretch: 'normal',
                  fontStyle: 'normal',
                  letterSpacing: 'normal',
                  textAlign: 'left',
                  color: '#000',
                }}
            >
              {Value?.league_name}
            </p>
          </div>
          <div className={'container-fluid bg-white pb-2 mb-2'}>
            <div

                style={{
                  color: '#a3a3a3',
                }}
                className={`font-14 rounded-bottom-custom text-center sfProDisplayFont  row`}>
              <div className={'col-1'}>#</div>
              <div className={'col-5'}
                   style={{marginLeft: '-15px'}}
              >Teams
              </div>
              <div className={'col-1'}>W</div>
              <div className={'col-1'}>L</div>
              <div className={'col-1'}>PCT</div>
              <div className={'col-1'}>GB</div>
              <div className={'col-1'}>L10</div>
              <div className={'col-1'}>STRK</div>
            </div>
            {(Value?.standings)?.map((value, index) => (
                <div className={`row sfProDisplayFont`}>
                  <div className={`text-center font-11 col-1`}><span
                      className={'sfProDisplayFont'}>{index + 1}.</span>
                  </div>
                  <div className={'font-11 col-5'}
                       style={{marginLeft: '-15px'}}
                  >
                    <img src={value.team.icon} height={20} alt=""/>
                    &nbsp;
                    &nbsp;
                    <span
                        className={'sfProDisplayFont text-center text-nowrap'}>{value.team.short_name}</span>
                  </div>
                  <div
                      className={`text-center font-11 col-1 text-nowrap`}>{value?.wins}</div>
                  <div
                      className={`text-center font-11 col-1 text-nowrap`}>{value?.lost}</div>
                  <div className={`text-center font-11 col-1 text-nowrap`}>
                    {(parseFloat(value?.percentage) * 100).toFixed(1)}
                  </div>
                  <div
                      className={`text-center font-11 col-1 text-nowrap`}>{value?.gb}</div>
                  <div
                      className={`text-center font-11 col-1 text-nowrap`}>{value?.last_10}</div>
                  <div
                      className={`text-center font-11 col-1 text-nowrap`}>{value?.streak}</div>
                </div>
            ))}
          </div>
        </div>
    );
  };

  const renderLaptop = (
      standingBgColor, textColor, color, navColor, fontColor, Value, ind) => {

    return (
        <div className={'container bg-white'}
             key={ind || 1}
        >
          {<div className={` rounded-custom-1 text-start ps-4`}>
                        <span className={`${fontColor} text-header`}>
                            <span
                                className={'sfProDisplayFont font-28 text-black'}
                                style={{fontWeight: '800'}}
                            >
                              NBA 2022-23 SEASON STANDING
                              {/*{selectedConference?.standings ?*/}
                              {/*    selectedConference?.league_name :*/}
                              {/*    ''*/}
                              {/*}*/}
                            </span>
                          {/*To DO add Later
                            <FontAwesomeIcon icon={faSortDown} color={'#FFFFFF'} size={'1x'} style={{verticalAlign: '0'}}/>*/}
                        </span>
          </div>
          }
          <div className="col-12 border-1 border-secondary rounded">
            {renderConferenceButtons()}
            <div>
              <p
                  className={'text-nowrap ps-4'}
                  style={{
                    width: '154px',
                    height: '24px',
                    fontSize: '20px',
                    fontWeight: '900',
                    textAlign: 'left',
                    color: '#000',
                  }}
              >
                {selectedConference?.league_name}
              </p>
            </div>
            <ul className={'bg-fixtures rounded-bottom-custom text-center standings-list list-unstyled p-0 ms-3'}>
              <ItemLoader loading={loading}/>
              <li className={'bg-white p-2'}>
                <div className={'row p-1'}>
                  <div
                      className={'col-1 text-start text-gray sfProDisplayFontBold text-nowrap font-12'}>#
                  </div>
                  <div
                      style={{
                        marginLeft:'-5%',
                      }}
                      className={'col-2  text-start text-gray sfProDisplayFontBold text-nowrap font-12'}>Team
                  </div>
                  <div
                      className={'col-1 text-center text-gray sfProDisplayFontBold font-12 m-0 p-0'}>W
                  </div>
                  <div
                      className={'col-1 text-center text-gray sfProDisplayFontBold font-12 m-0 p-0'}>L
                  </div>
                  <div
                      className={'col-1 text-center text-gray sfProDisplayFontBold font-12 m-0 p-0'}>Win%
                  </div>
                  <div
                      className={'col-1 text-center text-gray sfProDisplayFontBold font-12 m-0 p-0'}>GB
                  </div>
                  <div
                      className={'col-1 text-center text-gray sfProDisplayFontBold font-12 m-0 p-0'}>Home
                  </div>
                  <div
                      className={'col-1 text-center text-gray sfProDisplayFontBold font-12 m-0 p-0'}>Away
                  </div>
                  <div
                      className={'col-1 text-center text-gray sfProDisplayFontBold font-12 m-0 p-0'}>Last
                    10
                  </div>
                  <div
                      className={'col-1 text-center text-gray sfProDisplayFontBold font-12 m-0 p-0'}>Streak
                  </div>
                </div>
              </li>
              {
                (selectedConference !== null &&
                    (Value?.standings || selectedConference?.standings))?.map(
                    ((value, index) => {
                      let bgColor = '';
                      return (
                          <li key={index}
                              className={` ${index + 1 ===
                              (value?.team?.name).length ?
                                  'rounded-bottom-custom' :
                                  ''} ${bgColor === '' && 'bg-white'} p-2`}
                              style={{
                                background: bgColor !== '' &&
                                    value?.team?.hex_color,
                                color: bgColor !== '' &&
                                    value?.team?.font_color,
                              }}
                          >
                            <div className={'row p-1'}>
                              <div
                                  className={`col-1 ${bgColor === '' &&
                                  'text-dark'} text-start text-nowrap font-12 `}>
                                {index + 1 || value?.position}.
                              </div>
                              <div
                                  style={{
                                    marginLeft:'-5%',
                                  }}

                                  className={'col-2  text-start text-light-black sfProDisplayFontBold text-nowrap font-12'}>
                                <img
                                    width={27}
                                    style={{objectFit: 'contain'}}
                                    src={value?.team?.icon}
                                    height={20}
                                    alt=""
                                />
                                <span className={'sfProDisplayFont'}
                                      style={{fontWeight: '500'}}
                                >
                                    {value?.team?.name}
                                </span>
                              </div>
                              <div
                                  className={'col-1 text-center text-light-black sfProDisplayFontBold font-12 m-0 p-0'}>
                                {value?.wins}
                              </div>
                              <div
                                  className={'col-1 text-center text-light-black sfProDisplayFontBold font-12 m-0 p-0'}>
                                {value?.losses}
                              </div>
                              <div
                                  className={'col-1 text-center text-light-black sfProDisplayFontBold font-12 m-0 p-0'}>
                                {(parseFloat(value?.percentage) * 100).toFixed(
                                    1)}
                              </div>
                              <div
                                  className={'col-1 text-center text-light-black sfProDisplayFontBold font-12 m-0 p-0'}>
                                {value?.gb}
                              </div>
                              <div
                                  className={'col-1 text-center text-light-black sfProDisplayFontBold font-12 m-0 p-0'}>
                                {value?.home_record}
                              </div>
                              <div
                                  className={'col-1 text-center text-light-black sfProDisplayFontBold font-12 m-0 p-0'}>{value?.road_record}
                              </div>
                              <div
                                  className={'col-1 text-center text-light-black sfProDisplayFontBold font-12 m-0 p-0'}>{value?.last_10}
                              </div>
                              <div
                                  className={'col-1 text-center text-light-black sfProDisplayFontBold font-12 m-0 p-0'}>{value?.streak}
                              </div>
                            </div>
                          </li>
                      );
                    }))
              }
              {
                  moreStandings?.length &&
                  moreStandings?.map(((value, index, array) => {
                    return (
                        <li className={`p-3 ${fullTable ? '' : 'd-none'}`}>
                          <div className={'row'}>
                            <div
                                className={'col-4 text-start text-dark text-nowrap font-14'}>
                              <span
                                  className={'sfProDisplayFont'}>{value.position}. &nbsp;</span>
                              <img src={value.team.icon} height={20} alt=""/>
                              <span className={'sfProDisplayFont'}>
                                                &nbsp; {value.team.name}
                                            </span>
                            </div>
                            <div className={'col text-end text-light-black'}>
                              {() => {
                                if (value.arrow === 'up') {
                                  return (<FontAwesomeIcon icon={faAngleUp}
                                                           color={'#0e7d45'}
                                                           size={'1x'}/>);
                                }
                                if (value.arrow === 'down') {
                                  return (
                                      <FontAwesomeIcon icon={faAngleDown}
                                                       color={'#e02020'}
                                                       size={'1x'}/>);
                                }
                              }}
                            </div>
                            <div
                                className={'col text-end text-light-black sfProDisplayFont font-14'}>{value.mp}</div>
                            <div
                                className={'col text-end text-light-black sfProDisplayFont font-14'}>{value.gd}</div>
                            <div
                                className={'col text-end text-light-black sfProDisplayFont font-14'}>{value.pts}</div>
                          </div>
                        </li>
                    );
                  }))
              }
            </ul>
          </div>
        </div>
    );
  };

  const renderLaptopDivision = (
      standingBgColor, textColor, color, navColor, fontColor, Value, ind) => {

    return (
        <div className={'container'}
             key={ind || 1}
        >
          {ind === 0 && <div className={` rounded-custom-1 text-start ps-4`}>
                        <span className={`${fontColor} text-header`}>
                            <span
                                className={'sfProDisplayFont font-28 text-black'}
                                style={{fontWeight: '800'}}
                            >
                              NBA 2022-23 SEASON STANDING
                            </span>
                        </span>
          </div>
          }
          <div className="col-12 border-1 border-secondary rounded">
            {ind === 0 && renderConferenceButtons()}
            <div>
              <p
                  className={'text-nowrap ps-4'}
                  style={{
                    width: '154px',
                    height: '24px',
                    fontSize: '20px',
                    fontWeight: '900',
                    textAlign: 'left',
                    color: '#000',
                  }}
              >
                {Value?.league_name}
              </p>
            </div>
            <ul className={'bg-fixtures rounded-bottom-custom text-center standings-list list-unstyled p-0 ms-3'}>
              <ItemLoader loading={loading}/>
              <li className={'bg-white p-2'}>
                <div className={'row p-1'}>
                  <div
                      className={'col-1 text-start text-gray sfProDisplayFontBold text-nowrap font-12'}>
                    #
                  </div>
                  <div
                      style={{
                        marginLeft:'-5%',
                      }}

                      className={'col-2  text-start text-gray sfProDisplayFontBold text-nowrap font-12'}>
                    Team
                  </div>
                  <div
                      className={'col-1 text-center text-gray sfProDisplayFontBold font-12 m-0 p-0'}>
                    W
                  </div>
                  <div
                      className={'col-1 text-center text-gray sfProDisplayFontBold font-12 m-0 p-0'}>L
                  </div>
                  <div
                      className={'col-1 text-center text-gray sfProDisplayFontBold font-12 m-0 p-0'}>Win%
                  </div>
                  <div
                      className={'col-1 text-center text-gray sfProDisplayFontBold font-12 m-0 p-0'}>GB
                  </div>
                  <div
                      className={'col-1 text-center text-gray sfProDisplayFontBold font-12 m-0 p-0'}>Home
                  </div>
                  <div
                      className={'col-1 text-center text-gray sfProDisplayFontBold font-12 m-0 p-0'}>Away
                  </div>
                  <div
                      className={'col-1 text-center text-gray sfProDisplayFontBold font-12 m-0 p-0'}>Last
                    10
                  </div>
                  <div
                      className={'col-1 text-center text-gray sfProDisplayFontBold font-12 m-0 p-0'}>Streak
                  </div>
                </div>
              </li>
              {
                (Value?.standings)?.map(
                    ((value, index) => {
                      let bgColor = '';
                      return (
                          <li key={index}
                              className={` ${index + 1 ===
                              (value?.team?.name).length ?
                                  'rounded-bottom-custom' :
                                  ''} ${bgColor === '' && 'bg-white'} p-2`}
                              style={{
                                background: bgColor !== '' &&
                                    value?.team?.hex_color,
                                color: bgColor !== '' &&
                                    value?.team?.font_color,
                              }}
                          >
                            <div className={'row p-1'}>
                              <div
                                  className={`col-1 ${bgColor === '' &&
                                  'text-dark'} text-start text-nowrap font-12 `}>
                                {index + 1 || value?.position}.
                              </div>
                              <div
                                  style={{
                                    marginLeft:'-5%',
                                  }}

                                  className={'col-2  text-start text-light-black sfProDisplayFontBold text-nowrap font-12'}>

                                <img
                                    width={27}
                                    style={{objectFit: 'contain'}}

                                    src={value?.team?.icon} height={20} alt=""/>
                                <span className={'sfProDisplayFont'}
                                      style={{fontWeight: '500'}}
                                >
                                                         {value?.team?.name}
                                                    </span>
                              </div>
                              <div
                                  className={'col-1 text-center text-light-black sfProDisplayFontBold font-12 m-0 p-0'}>
                                {value?.wins}
                              </div>
                              <div
                                  className={'col-1 text-center text-light-black sfProDisplayFontBold font-12 m-0 p-0'}>
                                {value?.losses}
                              </div>
                              <div
                                  className={'col-1 text-center text-light-black sfProDisplayFontBold font-12 m-0 p-0'}>
                                {(parseFloat(value?.percentage) * 100).toFixed(
                                    1)}
                              </div>
                              <div
                                  className={'col-1 text-center text-light-black sfProDisplayFontBold font-12 m-0 p-0'}>
                                {value?.gb}
                              </div>
                              <div
                                  className={'col-1 text-center text-light-black sfProDisplayFontBold font-12 m-0 p-0'}>
                                {value?.home_record}
                              </div>
                              <div
                                  className={'col-1 text-center text-light-black sfProDisplayFontBold font-12 m-0 p-0'}>{value?.road_record}
                              </div>
                              <div
                                  className={'col-1 text-center text-light-black sfProDisplayFontBold font-12 m-0 p-0'}>{value?.last_10}
                              </div>
                              <div
                                  className={'col-1 text-center text-light-black sfProDisplayFontBold font-12 m-0 p-0'}>{value?.streak}
                              </div>
                            </div>
                          </li>
                      );
                    }))
              }
              {
                  moreStandings?.length &&
                  moreStandings?.map(((value, index, array) => {
                    return (
                        <li className={`p-3 ${fullTable ? '' : 'd-none'}`}>
                          <div className={'row'}>
                            <div
                                className={'col-4 text-start text-dark text-nowrap font-14'}>
                              <span
                                  className={'sfProDisplayFont'}>{value.position}. &nbsp;</span>
                              <img src={value.team.icon} height={20} alt=""/>
                              <span className={'sfProDisplayFont'}>
                                                &nbsp; {value.team.name}
                                            </span>
                            </div>
                            <div className={'col text-end text-light-black'}>
                              {() => {
                                if (value.arrow === 'up') {
                                  return (<FontAwesomeIcon icon={faAngleUp}
                                                           color={'#0e7d45'}
                                                           size={'1x'}/>);
                                }
                                if (value.arrow === 'down') {
                                  return (
                                      <FontAwesomeIcon icon={faAngleDown}
                                                       color={'#e02020'}
                                                       size={'1x'}/>);
                                }
                              }}
                            </div>
                            <div
                                className={'col text-end text-light-black sfProDisplayFont font-14'}>{value.mp}</div>
                            <div
                                className={'col text-end text-light-black sfProDisplayFont font-14'}>{value.gd}</div>
                            <div
                                className={'col text-end text-light-black sfProDisplayFont font-14'}>{value.pts}</div>
                          </div>
                        </li>
                    );
                  }))
              }
              {/*{moreStandings.length &&
                            <li className={`p-3 bg-white text-center sfProDisplayFontBold ${textColor} rounded-bottom-custom btn`}
                                onClick={() => {
                                    setFullTable(!fullTable)
                                }}
                            >
                                {fullTable ? 'Collapse' : 'See full table'} &nbsp;&nbsp;&nbsp;
                                <FontAwesomeIcon icon={fullTable ? faAngleUp : faAngleDown} color={color}
                                                 size={'1x'}/>
                            </li>}*/}
            </ul>
          </div>
        </div>
    );
  };

  const withData = () => {
    const hostName = window.location.hostname;
    const domainObject = getHostname(hostName);
    const target = domainObject.host;
    const navColor = NAV_CLASS[target];
    const textColor = TEXT_COLOR[target];
    const fontColor = FONT_COLOR[target];
    const color = COLORS[target];
    const standingBgColor = COLORS_RGBA[target] + '0.3)';

    let isGroup = divisions && selectedConference === divisions;
    let tmp = [];
    if (divisions !== null && divisions !== undefined) {
      if (divisions && isGroup) {
        let arr = [];
        let allGroups = divisions.map((item) => item?.standings); //gets all groups if any

        for (let i = 0; i < divisions?.length; i++) {
          if (divisions[i]?.standings === allGroups[i]) {
            arr.push(divisions[i]);
            if (arr.length === 4) {
              tmp.push(arr);
              arr = [];
            }
          }
        }
      } else if (divisions && selectedConference === divisions && !isGroup) {
        let arr = [];
        for (let i = 0; i < divisions?.length; i++) {

          arr.push(divisions[i]);
        }
        tmp.push(arr);
      }

    }

    const renderDefault = () => {

      return ((isMobile && standings) ?
          !fullTable ?
              renderMobilePartialTable(standingBgColor, textColor, color) :
              renderMobileFullTable(standingBgColor, textColor, color) :
          renderLaptop(standingBgColor, textColor, color, navColor,
              fontColor, standings));
    };

    const renderGroups = () => {

      return (
          <div className={'bg-white'}>
            {
                selectedConference === divisions && divisions &&
                divisions.map((value, index) => (
                    <>
                      {
                        (isMobile ?
                                renderMobilePartialTableDivision(
                                    standingBgColor,
                                    textColor, color, value, index) :
                                renderLaptopDivision(standingBgColor, textColor,
                                    color,
                                    navColor,
                                    fontColor, value, index)
                        )
                      }
                    </>
                ))
            }
          </div>
      );
    };

    return loading ?
        (
            <ItemLoader loading={loading}/>
        )
        : (
            <div className={isMobile ? '' : 'container'}>
              <div className={isMobile ? '' : 'row'}>
                <div className={isMobile ? '' : 'col-8'}>
                  {tmp?.length === 0 && renderDefault()}
                  {tmp?.length > 0 && renderGroups()}
                </div>
                {!isMobile && <RightNewsAds
                    sportEndpoint={NBA.NewsEndPoint}
                    isMobile={isMobile}
                    limit={3}
                />}

              </div>
            </div>
        );
  };

  const renderMain = () => {
    return (
        <div className={isMobile && 'bg-white'}>
          <SiteMetaHelmet sport={'NBA'} urlEndpoint={'nba'}/>
          {/*<Loader loading={loading}/>*/}
          <SubNavbar isMobile={isMobile} teamData={selectedTeam}/>
          <div className="d-flex justify-content-center align-items-start h1">
            <GoogleAd id={'4009154580'}/>
          </div>
          {standings !== null && standings !== undefined && withData()}
        </div>
    );
  };
  return renderMain();
};

export default NBAStandings;
