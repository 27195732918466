import {getHostname} from "../CustomFunctions/getHostname";

import FanzineSportsRoutes from "./FanzineSportsRoutes";
import FanzineRoutes from "./FanzineRoutes";
import ClubRoutes from "./ClubRoutes";

const AppRoutes = (props) => {

	const render = () => {
		const hostName = window.location.hostname;
		const domainObject = getHostname(hostName);
		const target = domainObject.host;

		if (target === "fanzinesports") {
			return <FanzineSportsRoutes/>
		}

		if (target === "fanzine") {
			return <FanzineRoutes/>;
		}

		return <ClubRoutes {...props}/>;
	};

	return render() || '';
};

export default AppRoutes;
