import React, {useEffect} from 'react';
import {SkySportsVideoItem} from './SkySportsVideoItem';
import {getHostname} from "../../CustomFunctions/getHostname";
import GoogleAd from "./GoogleAd";
import {SKY_ADS} from "../../Configs/Targets";
import {useSelector} from "react-redux";
import {useParams} from "react-router-dom";

const SkySportsAd = () => {

    const store = useSelector(state => state);
    let {team} = useParams();

    useEffect(() => {
    }, [store.football?.navbarTeamSlugsId, team]);

    React.useEffect(() => {
        const script = document.createElement("script")
        script.src = "//fluid.4strokemedia.com/www/fluid/player.php"
        script.async = true;
        document.head.appendChild(script);
    });

    const render = () => {
        const hostName = window.location.hostname;
        const domainObject = getHostname(hostName);
        const target = domainObject.host;
        let zoneId = SKY_ADS[target];

        let data = null;
        store.football?.navbarTeamSlugsId?.length && store.football.navbarTeamSlugsId.map((value, index) => {
                if (team === value?.club) {
                    data = value;
                }
            },
        );

        let tempSocial = {
            twitter: null,
            facebook: null,
            instagram: null,
            ios: null,
            android: null,
            web: null,
        }
        for (let i = 0; i < store?.misc?.targetsApps?.length; i++) {

            if (store?.misc?.targetsApps[i]?.team_id === data?.clubId) {
                tempSocial.twitter = store?.misc?.targetsApps[i]?.twitter_url;
                tempSocial.facebook = store?.misc?.targetsApps[i]?.facebook_url;
                tempSocial.instagram = store?.misc?.targetsApps[i]?.instagram_url;
                tempSocial.ios = store?.misc?.targetsApps[i]?.ios_default_app_url;
                tempSocial.androd = store?.misc?.targetsApps[i]?.android_default_app_url;
                tempSocial.web = store?.misc?.targetsApps[i]?.web_default_app_url;
            }
        }

        let webUrlArr = [];
        let clubDomain = null;

        if (target === 'fanzine') {
            zoneId = '17979';
            if (tempSocial.web) {
                webUrlArr = tempSocial.web.split('.');
                clubDomain = webUrlArr[1] || null;
                if (clubDomain) {
                    zoneId = SKY_ADS[clubDomain] || '17979';
                }
            }
        }

        if (zoneId) {
            return (<SkySportsVideoItem zoneId={zoneId}/>);
        }

        return (<GoogleAd id={'4009154580'}/>);
    }

    return render();

}

export default SkySportsAd;
