import {useEffect} from "react";
import {useLocation} from "react-router-dom";


const GoogleAdManager = (props) => {
    const {isMobile} = props;

    // console.log("props on google ad manager >>>", props)

    const location = useLocation();
    // let id = 'div-gpt-ad-1706189241175-0';  //props.id ? `div-gpt-ad-${props.id}-0` : (isMobile ? 'div-gpt-ad-1704953333715-0' : 'div-gpt-ad-1704953268243-0');
    let id = 'div-gpt-ad-1706189241175-0';  //props.id ? `div-gpt-ad-${props.id}-0` : (isMobile ? 'div-gpt-ad-1704953333715-0' : 'div-gpt-ad-1704953268243-0');
    
    // <div id={id} style={{maxWidth: isMobile ? '350px' : '1024px'}}>
    // </div>

    useEffect(() => {
        window.googletag.cmd.push(function () {
            window.googletag.display(id);
        });
    }, []);

    useEffect(() => {
        window.googletag.cmd.push(function () {
            window.googletag.display(id);
            window.googletag.pubads().refresh();
        });
    }, [location.pathname]);

    const render = () => {
        return (
            <div
            className={'w-100 d-flex text-center justify-content-center pt-3 mt-5 mb-3'}
            >
            <div class="clever-core-ads">
            </div>
            </div>
            );
    }


    return render();
}
export default GoogleAdManager;
