let FIREBASE_CONFIG = {
    'localhost': {
        apiKey: "AIzaSyD9l9SZLxrUPOOVO50znrefDFiR-sJP5Cw",
        authDomain: "coys-com.firebaseapp.com",
        projectId: "coys-com",
        storageBucket: "coys-com.appspot.com",
        messagingSenderId: "719813225555",
        appId: "1:719813225555:web:7df690359308bbd9182aa6",
        measurementId: "G-ESZESNTTJ5"
    },
    '127.0.0.1': {
        apiKey: "AIzaSyD9l9SZLxrUPOOVO50znrefDFiR-sJP5Cw",
        authDomain: "coys-com.firebaseapp.com",
        projectId: "coys-com",
        storageBucket: "coys-com.appspot.com",
        messagingSenderId: "719813225555",
        appId: "1:719813225555:web:7df690359308bbd9182aa6",
        measurementId: "G-ESZESNTTJ5"
    },
    'cfc-blues': {
        apiKey: "AIzaSyAOVGtNnjVpl2IymY6eSfC6yjbrviNTTfc",
        authDomain: "cfc-blues-com.firebaseapp.com",
        projectId: "cfc-blues-com",
        storageBucket: "cfc-blues-com.appspot.com",
        messagingSenderId: "866753313360",
        appId: "1:866753313360:web:1be58cf8e591eb484eef55",
        measurementId: "G-DFSM8MLCPH"
    },
    'gunners': {
        apiKey: "AIzaSyCUCNGCDw08xEUyM0QTeispxN-haZhpWB0",
        authDomain: "gunners-com.firebaseapp.com",
        projectId: "gunners-com",
        storageBucket: "gunners-com.appspot.com",
        messagingSenderId: "399166048662",
        appId: "1:399166048662:web:e8dfc66a6bf8d22b5cb5b7",
        measurementId: "G-TC2FMVV431"
    },
    'celtic1967': {
        apiKey: "AIzaSyDDuAPzVHTJPhsUrCanuVSJEFvdlKvPvpA",
        authDomain: "celtic1967-com.firebaseapp.com",
        projectId: "celtic1967-com",
        storageBucket: "celtic1967-com.appspot.com",
        messagingSenderId: "230431236149",
        appId: "1:230431236149:web:e94901fec2b36fc889d17c",
        measurementId: "G-EDRS4R1YBK"
    },
    'theyellows': {
        apiKey: "AIzaSyA_maUgDdgAWBIf5TaKBUwzBn6nrHdR6Ig",
        authDomain: "theyellows-com.firebaseapp.com",
        projectId: "theyellows-com",
        storageBucket: "theyellows-com.appspot.com",
        messagingSenderId: "556145695467",
        appId: "1:556145695467:web:2426e347d3128593ccbcae",
        measurementId: "G-GQX80TG5M1"
    },
    'villatillidie': {
        apiKey: "AIzaSyDyhjbTNk14rLmiN5AUi1vovdBRL0jB21U",
        authDomain: "villatillidie-com.firebaseapp.com",
        projectId: "villatillidie-com",
        storageBucket: "villatillidie-com.appspot.com",
        messagingSenderId: "562436018311",
        appId: "1:562436018311:web:9d0d284fbce28e5282a822",
        measurementId: "G-NR3DG2F9Q8"
    },
    'coywhites': {
        apiKey: "AIzaSyAQKDw5wG6mwsyBT7OMkN5i3dWhKzX3qMo",
        authDomain: "coywhites-com.firebaseapp.com",
        projectId: "coywhites-com",
        storageBucket: "coywhites-com.appspot.com",
        messagingSenderId: "788732437384",
        appId: "1:788732437384:web:7ee026187c48fd9718103d",
        measurementId: "G-0HDWNPYEHX"
    },
    'coyirons': {
        apiKey: "AIzaSyDCEWYVQtxCo3NIW0YkG6AdOdcEJ1zXALE",
        authDomain: "coyirons-com.firebaseapp.com",
        projectId: "coyirons-com",
        storageBucket: "coyirons-com.appspot.com",
        messagingSenderId: "13643797936",
        appId: "1:13643797936:web:5f71dd1d7abea15098b275",
        measurementId: "G-ZR0ZFTSBLB"
    },
    'watp': {
        apiKey: "AIzaSyCqzJl300zk8_F6S0BIFNEqWpVw8KqZz8s",
        authDomain: "watp-com.firebaseapp.com",
        projectId: "watp-com",
        storageBucket: "watp-com.appspot.com",
        messagingSenderId: "1096654730088",
        appId: "1:1096654730088:web:97dac17ea8cebf3b5e9cdd",
        measurementId: "G-EKR1M9FSZH"
    },
    'lufcmot': {
        apiKey: "AIzaSyAvDJsYNFluAruKQVHBj3JT_PSQVJIqhmI",
        authDomain: "lufcmot-com.firebaseapp.com",
        projectId: "lufcmot-com",
        storageBucket: "lufcmot-com.appspot.com",
        messagingSenderId: "12301946948",
        appId: "1:12301946948:web:156891c7656a41c4c5ac11",
        measurementId: "G-W7Q8YG0P3M"
    },
    'the-toffees': {
        apiKey: "AIzaSyCFy4EQLfnhfGZn6j_RYc3zDuX2iUA3ej8",
        authDomain: "the-toffees-com.firebaseapp.com",
        projectId: "the-toffees-com",
        storageBucket: "the-toffees-com.appspot.com",
        messagingSenderId: "422545191174",
        appId: "1:422545191174:web:249808343c6a6bfa3cae87",
        measurementId: "G-J9M7QP2M1W"
    },
    'unitedreds': {
        apiKey: "AIzaSyD-KODngBJbVD4EopJt7HYWV0aX7L3kirg",
        authDomain: "unitedreds-com.firebaseapp.com",
        projectId: "unitedreds-com",
        storageBucket: "unitedreds-com.appspot.com",
        messagingSenderId: "922112103598",
        appId: "1:922112103598:web:5922a532c719780219093f",
        measurementId: "G-J2DX8TJLLC"
    },
    'thekop': {
        apiKey: "AIzaSyBybLU4hDRZhtYF7IJvFE6UOrnxVitxwJg",
        authDomain: "thekop-com.firebaseapp.com",
        projectId: "thekop-com",
        storageBucket: "thekop-com.appspot.com",
        messagingSenderId: "63356875951",
        appId: "1:63356875951:web:0c04c98f44f0de3eba105a",
        measurementId: "G-FJRCTXXTJ6"
    },
    'toonarmy': {
        apiKey: "AIzaSyCxVPTsw4SdF8vdDcHkPujtQZI1jTgQh2E",
        authDomain: "toonarmy-com.firebaseapp.com",
        projectId: "toonarmy-com",
        storageBucket: "toonarmy-com.appspot.com",
        messagingSenderId: "342051580658",
        appId: "1:342051580658:web:7d6a57d5cd75bf7babfe6b",
        measurementId: "G-FS8706BDK1"
    },
    'coys': {
        apiKey: "AIzaSyD9l9SZLxrUPOOVO50znrefDFiR-sJP5Cw",
        authDomain: "coys-com.firebaseapp.com",
        projectId: "coys-com",
        storageBucket: "coys-com.appspot.com",
        messagingSenderId: "719813225555",
        appId: "1:719813225555:web:7df690359308bbd9182aa6",
        measurementId: "G-ESZESNTTJ5"
    },
    'mancityzens': {
        apiKey: "AIzaSyBwYMsba3alDbC_tteLTgZd6AR-531gCiw",
        authDomain: "mancityzens-com.firebaseapp.com",
        projectId: "mancityzens-com",
        storageBucket: "mancityzens-com.appspot.com",
        messagingSenderId: "754997988620",
        appId: "1:754997988620:web:b771ae7c6cfb5aa5e88d29",
        measurementId: "G-FFSZ1PQG3E"
    },
    'comeonleicester': {
        apiKey: "AIzaSyBAB0mgcBaKrnBHzqSIHLs8sR3nns_CqFI",
        authDomain: "comeonleicester-com.firebaseapp.com",
        projectId: "comeonleicester-com",
        storageBucket: "comeonleicester-com.appspot.com",
        messagingSenderId: "488073194363",
        appId: "1:488073194363:web:900e6e445f7692830c6bde",
        measurementId: "G-7984YF95ES"
    },
    'wearewolves': {
        apiKey: "AIzaSyBJ6B7QeUIjmdY2eJRu1cnsuXXmfRI5S9Y",
        authDomain: "wearewolves-com.firebaseapp.com",
        projectId: "wearewolves-com",
        storageBucket: "wearewolves-com.appspot.com",
        messagingSenderId: "712332094472",
        appId: "1:712332094472:web:305b710d8cda5ed26035bd",
        measurementId: "G-D3JSQEJEPZ"
    },
    'saints1885': {
        apiKey: "AIzaSyCd9_8iPFqAOFMT-j71pbIlxLi1eGUxcvc",
        authDomain: "saints1885-com.firebaseapp.com",
        projectId: "saints1885-com",
        storageBucket: "saints1885-com.appspot.com",
        messagingSenderId: "330276507234",
        appId: "1:330276507234:web:6d1572d27ff9808d1d4b3b",
        measurementId: "G-Q0BEE0WX7M"
    },
    'greasychipbutty': {
        apiKey: "AIzaSyDygwGDDoRqzZSsAkhlLDj-_zGCUPMdv7g",
        authDomain: "greasychipbutty-app.firebaseapp.com",
        projectId: "greasychipbutty-app",
        storageBucket: "greasychipbutty-app.appspot.com",
        messagingSenderId: "367555258926",
        appId: "1:367555258926:web:fcaaf51b0f182280b3cdbf",
        measurementId: "G-LQWFP7NXYZ"
    },
    'palaceeagles': {
        apiKey: "AIzaSyDl144ctAE_H_UDsVJNHQnGopxdm8OTkGA",
        authDomain: "palaceeagles-com.firebaseapp.com",
        projectId: "palaceeagles-com",
        storageBucket: "palaceeagles-com.appspot.com",
        messagingSenderId: "535000303785",
        appId: "1:535000303785:web:7dfa55920c74720a5ac1b8",
        measurementId: "G-F08PMMB4YH"
    },
    'coyhorns': {
        apiKey: "AIzaSyCb79c_4bMuS1_xsdywNyK964kJ7srf0mU",
        authDomain: "coyhorns-com.firebaseapp.com",
        projectId: "coyhorns-com",
        storageBucket: "coyhorns-com.appspot.com",
        messagingSenderId: "83589761473",
        appId: "1:83589761473:web:4dd6890fb3477ca8fcc740",
        measurementId: "G-JXMEWP9RX6"
    },
    'citybluebirds': {
        apiKey: "AIzaSyA8fcHsmiiR-X-BIMzY8ihf5NL2TV-QL1U",
        authDomain: "citybluebirds-com.firebaseapp.com",
        projectId: "citybluebirds-com",
        storageBucket: "citybluebirds-com.appspot.com",
        messagingSenderId: "1010006284682",
        appId: "1:1010006284682:web:1c89d30747722e52eb5073",
        measurementId: "G-FY8WG7SJVP"
    },
    'redandblackarmy': {
        apiKey: "AIzaSyDl5M5DqF8KTHPdkp0QdRWj1Y779oV5uF0",
        authDomain: "redandblackarmy-com.firebaseapp.com",
        projectId: "redandblackarmy-com",
        storageBucket: "redandblackarmy-com.appspot.com",
        messagingSenderId: "594436908474",
        appId: "1:594436908474:web:092fc0a6a8c9c4b4436983",
        measurementId: "G-MPTGPLXFHR"
    },
    'the-clarets': {
        apiKey: "AIzaSyA02X-x1NQanXzY5S2zXlDq691m6_Og2z0",
        authDomain: "the-clarets-com.firebaseapp.com",
        projectId: "the-clarets-com",
        storageBucket: "the-clarets-com.appspot.com",
        messagingSenderId: "930433159090",
        appId: "1:930433159090:web:8b8b0cb8c302fe1051deab",
        measurementId: "G-JM85RFYSCY"
    },
    'the-terriers': {
        apiKey: "AIzaSyCtR52FGiX7v7WUWLRGNGMNvYrj0d1tR4Q",
        authDomain: "the-terriers-com.firebaseapp.com",
        projectId: "the-terriers-com",
        storageBucket: "the-terriers-com.appspot.com",
        messagingSenderId: "786677197245",
        appId: "1:786677197245:web:4ee90c1ff405f65ba3cad4",
        measurementId: "G-7X6D5W7YZJ"
    },
    'foresttilidie': {
        apiKey: "AIzaSyCeJ5AyvINaTYafKCfQxmYk-qTrDcrYJS4",
        authDomain: "foresttilidie-com.firebaseapp.com",
        projectId: "foresttilidie-com",
        storageBucket: "foresttilidie-com.appspot.com",
        messagingSenderId: "685876730373",
        appId: "1:685876730373:web:7772bddf74fc41f9021a6c",
        measurementId: "G-GCSQG71WYT"
    },
    'goonstoke': {
        apiKey: "AIzaSyDrY2gXb3zwkrraMMvnvtgwzhCAogCZFUM",
        authDomain: "goonstoke-com.firebaseapp.com",
        projectId: "goonstoke-com",
        storageBucket: "goonstoke-com.appspot.com",
        messagingSenderId: "300914901105",
        appId: "1:300914901105:web:fe55ee05291f0273081bd0",
        measurementId: "G-6VM7LQTGZP"
    },
    'theteessiders': {
        apiKey: "AIzaSyBn8_3uzL-hN2bpz1HuDcpksjAvU9D8RZU",
        authDomain: "theteessiders-com.firebaseapp.com",
        projectId: "theteessiders-com",
        storageBucket: "theteessiders-com.appspot.com",
        messagingSenderId: "783350317548",
        appId: "1:783350317548:web:81a7ca2161648bcb0640c9",
        measurementId: "G-T647LBEQ9E"
    },
    'roverstilidie': {
        apiKey: "AIzaSyDxP9owrmyeHn2Z2BxowZx-p5y8MRk8n5w",
        authDomain: "roverstilidie-com.firebaseapp.com",
        projectId: "roverstilidie-com",
        storageBucket: "roverstilidie-com.appspot.com",
        messagingSenderId: "806989452648",
        appId: "1:806989452648:web:1578c39fca835e01dbb2dd",
        measurementId: "G-JM0WN7HQPW"
    },
    'boingboingbaggies': {
        apiKey: "AIzaSyCz1JpV4TobnWGRlScTzu7-J0CC951OJ5g",
        authDomain: "boingboingbaggies-com.firebaseapp.com",
        projectId: "boingboingbaggies-com",
        storageBucket: "boingboingbaggies-com.appspot.com",
        messagingSenderId: "195745232848",
        appId: "1:195745232848:web:d37804909a88d9e392ebd5",
        measurementId: "G-5VTCZFVRH6"
    },
    'bcfckro': {
        apiKey: "AIzaSyCQTAolRLe1vbYu7mYIRUm-3ibZ7Oj5FAM",
        authDomain: "bcfckro-com.firebaseapp.com",
        projectId: "bcfckro-com",
        storageBucket: "bcfckro-com.appspot.com",
        messagingSenderId: "231433038963",
        appId: "1:231433038963:web:3c176eb6bcb52abe484e06",
        measurementId: "G-80QDMERJ5J"
    },
    'comeonderby': {
        apiKey: "AIzaSyDjo87RC3M_THAyc9X6vBxGx7rnVN51nIY",
        authDomain: "comeonderby-com.firebaseapp.com",
        projectId: "comeonderby-com",
        storageBucket: "comeonderby-com.appspot.com",
        messagingSenderId: "874983288807",
        appId: "1:874983288807:web:3f08785548e586c07dd742",
        measurementId: "G-R9JY9LNYHH"
    },
    'fanzinesports': {
        apiKey: "AIzaSyBoX5SgG-rZ34uiZTqiwJXMOMPN4M-0jeE",
        authDomain: "fanzine-com.firebaseapp.com",
        projectId: "fanzine-com",
        storageBucket: "fanzine-com.appspot.com",
        messagingSenderId: "309612088792",
        appId: "1:309612088792:web:7bbd78677182928c6536ad",
        measurementId: "G-7S6H9KPRX0"
    },
    'fanzine': {
        apiKey: "AIzaSyBoX5SgG-rZ34uiZTqiwJXMOMPN4M-0jeE",
        authDomain: "fanzine-com.firebaseapp.com",
        projectId: "fanzine-com",
        storageBucket: "fanzine-com.appspot.com",
        messagingSenderId: "309612088792",
        appId: "1:309612088792:web:7bbd78677182928c6536ad",
        measurementId: "G-7S6H9KPRX0"
    },
    'weareswansea': {
        apiKey: "AIzaSyB0KAY5Dl0u4gsbZgluFr2rrpyXBgqUti8",
        authDomain: "weareswansea-com.firebaseapp.com",
        projectId: "weareswansea-com",
        storageBucket: "weareswansea-com.appspot.com",
        messagingSenderId: "1026611234890",
        appId: "1:1026611234890:web:11e28ebfc4672c256f3eb3",
        measurementId: "G-MWQSNK6ZWP"
    },
    'oneteaminbristol': {
        apiKey: "AIzaSyDZrVLMff_NmtBJY-UZZ1Y1VdF44Ph9eEg",
        authDomain: "oneteaminbristol-com.firebaseapp.com",
        projectId: "oneteaminbristol-com",
        storageBucket: "oneteaminbristol-com.appspot.com",
        messagingSenderId: "375901191001",
        appId: "1:375901191001:web:74134bb888cfaeb1ecea48",
        measurementId: "G-P76WYFL8X2"
    },
    'coybees': {
        apiKey: "AIzaSyAVS-eQp2I9zrCn4y6nP5glprHX--o3btQ",
        authDomain: "coybees-com.firebaseapp.com",
        projectId: "coybees-com",
        storageBucket: "coybees-com.appspot.com",
        messagingSenderId: "943896861468",
        appId: "1:943896861468:web:b59337a0464e692f1678d9",
        measurementId: "G-EVFW31P1BB"
    },
    'rokerroar': {
        apiKey: "AIzaSyCWkLjA9SDQgGrcwMmoIfHP5Wz2NC-8gN8",
        authDomain: "rokerroar-com.firebaseapp.com",
        projectId: "rokerroar-com",
        storageBucket: "rokerroar-com.appspot.com",
        messagingSenderId: "195234947079",
        appId: "1:195234947079:web:7594664cc359364b5b8aa2",
        measurementId: "G-G7B17DMHDH"
    },
    'weareqpr': {
        apiKey: "AIzaSyA9X8powKbOJdFJZ1ckvty1bq-DMO-7EV0",
        authDomain: "weareqpr-com.firebaseapp.com",
        projectId: "weareqpr-com",
        storageBucket: "weareqpr-com.appspot.com",
        messagingSenderId: "412854184008",
        appId: "1:412854184008:web:bee65a1323bc8d0ac4d07d",
        measurementId: "G-LQ8T0644PS"
    },
    'wednesdayites': {
        apiKey: "AIzaSyCSzvwYN7AobMzisTfKuxUFy54FOCZarwE",
        authDomain: "wednesdayites-com.firebaseapp.com",
        projectId: "wednesdayites-com",
        storageBucket: "wednesdayites-com.appspot.com",
        messagingSenderId: "1083131045160",
        appId: "1:1083131045160:web:d7c5e582eb3ff7eb105c1d",
        measurementId: "G-WY1XQ25N2K"
    },
    'thetractorboys': {
        apiKey: "AIzaSyAFkCfklsYh0cpXZjLn37nisLSjwJVxzBk",
        authDomain: "thetractorboys-com.firebaseapp.com",
        projectId: "thetractorboys-com",
        storageBucket: "thetractorboys-com.appspot.com",
        messagingSenderId: "206102928829",
        appId: "1:206102928829:web:163af13313ad62d023c6d4",
        measurementId: "G-E4D1CYT3YT"
    },
    'playuppompey': {
        apiKey: "AIzaSyDcQAQydzaSCXTV3NJco-lrgiWwjkk8DeQ",
        authDomain: "playuppompey-com.firebaseapp.com",
        projectId: "playuppompey-com",
        storageBucket: "playuppompey-com.appspot.com",
        messagingSenderId: "701323664079",
        appId: "1:701323664079:web:3e532b24176576e4c275ba",
        measurementId: "G-7KM6455P5V"
    },
    'bhaseagulls': {
        apiKey: "AIzaSyBICcqAIb_YOy0c_J7FcJKMK48t5u2Oyz0",
        authDomain: "bhaseagulls-com.firebaseapp.com",
        projectId: "bhaseagulls-com",
        storageBucket: "bhaseagulls-com.appspot.com",
        messagingSenderId: "897217084439",
        appId: "1:897217084439:web:664a1a38e9169b125ab052",
        measurementId: "G-MWTDMCM9PF"
    },
    'lablaugrana': {
        apiKey: "AIzaSyA0yGW4rctFwf0NuhsXTDzJXFdjpQfkTrI",
        authDomain: "barca-club.firebaseapp.com",
        projectId: "barca-club",
        storageBucket: "barca-club.appspot.com",
        messagingSenderId: "506226315336",
        appId: "1:506226315336:web:d8332a8fa986a9c481887d",
        measurementId: "G-3YK0YTFZZF"
    },
    'cim-bom': {
        apiKey: "AIzaSyB5yAE7jC8Rm9OXwMblQcF56n8E2WFA2ts",
        authDomain: "cim-bom-com.firebaseapp.com",
        projectId: "cim-bom-com",
        storageBucket: "cim-bom-com.appspot.com",
        messagingSenderId: "816132582464",
        appId: "1:816132582464:web:47455bad8f9396a3a803a5",
        measurementId: "G-ZNC2CRXL5F"
    },
    'barca': {
        apiKey: "AIzaSyA0yGW4rctFwf0NuhsXTDzJXFdjpQfkTrI",
        authDomain: "barca-club.firebaseapp.com",
        projectId: "barca-club",
        storageBucket: "barca-club.appspot.com",
        messagingSenderId: "506226315336",
        appId: "1:506226315336:web:d8332a8fa986a9c481887d",
        measurementId: "G-3YK0YTFZZF"
    },
    'hala-madrid': {
        apiKey: "AIzaSyCaVeDTEHlQnrFxxJVWOTBHQHGII2MpVDk",
        authDomain: "hala-madrid-net.firebaseapp.com",
        projectId: "hala-madrid-net",
        storageBucket: "hala-madrid-net.appspot.com",
        messagingSenderId: "1122047987",
        appId: "1:1122047987:web:91a95f5d53a8c3fed46486",
        measurementId: "G-WS1RCKS589"
    },
    'die-bayern': {
        apiKey: "AIzaSyAyzGSzHjm7W5cP0ANYRbY2YU-zTG8Uw-Y",
        authDomain: "die-bayern-net.firebaseapp.com",
        projectId: "die-bayern-net",
        storageBucket: "die-bayern-net.appspot.com",
        messagingSenderId: "681066489072",
        appId: "1:681066489072:web:63aa28b5160ab4e7570e35",
        measurementId: "G-Z8G7D1B0V7"
    },
    'forza-juve': {
        apiKey: "AIzaSyAozDrOSVz2SUi3mBZ6WBsAw5VDGxT8GUA",
        authDomain: "forza-juve-com.firebaseapp.com",
        projectId: "forza-juve-com",
        storageBucket: "forza-juve-com.appspot.com",
        messagingSenderId: "1083083625186",
        appId: "1:1083083625186:web:a3c8f83498016b562c44bb",
        measurementId: "G-M2RHVS00HZ"
    },
    'allezparis': {
        apiKey: "AIzaSyAIMmyJW4Uc7YpixKLGzJJBwaPfYGdRi7E",
        authDomain: "allezparis-net.firebaseapp.com",
        projectId: "allezparis-net",
        storageBucket: "allezparis-net.appspot.com",
        messagingSenderId: "15868715790",
        appId: "1:15868715790:web:33b329926267b2c8b6ed5f",
        measurementId: "G-FF85HVDEH1"
    },
    'wxmafc': {
        apiKey: "AIzaSyAsBVMyWDVK6XtfgedQ2GcvtJetm_bqYak",
        authDomain: "wxmafc-com.firebaseapp.com",
        projectId: "wxmafc-com",
        storageBucket: "wxmafc-com.appspot.com",
        messagingSenderId: "458634008030",
        appId: "1:458634008030:web:063a58178c86b0450bf94f",
        measurementId: "G-8VMR8KNE3C"
    },
};

export {FIREBASE_CONFIG}
