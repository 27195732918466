import React, {useEffect, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faInfoCircle} from "@fortawesome/free-solid-svg-icons";
import {COLORS_RGBA, FONT_COLOR, LOADING_GIF, NAV_CLASS, TEXT_COLOR} from "../../../Configs/Targets";
import '../../../assets/styles/styles.css';
import '../../../assets/styles/shop.css';
import {ButtonGroup, Table, ToggleButton} from "react-bootstrap";
import {useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {useWindowSize} from "../../../CustomFunctions/useWindowSize";
import {shuffleArray} from "../../../CustomFunctions/shuffleArray";
import {getHostname} from "../../../CustomFunctions/getHostname";
import {SCREEN_SIZE, SHOPIFY} from "../../../Configs/Constants";
import {graphql} from "../../../Axios/graphql";
import {ShopifyGraphQlQueries} from "../../../Configs/ShopifyGraphQlQueries";
import {setCartItems, setGrandTotal} from "../../../redux/actions/CartActions";
import {ProductSizeChart} from "../../../Axios/EndPoints";
import {get} from "../../../Axios/get";
import {Banner} from "../../../Components/ClubShop/Banner";
import ProductComponent from "../../../Components/ClubShop/ProductComponent";
import {withRouter} from "../../../CustomFunctions/withRouter";
import {shopPageImages} from "../../../Components/Carousel/images";

const ProductDetailPage = (props) => {
    // hooks
    const dispatch = useDispatch();
    const cart = useSelector((state) => state);
    let windowSize = useWindowSize();

    /* States Start */
    const [selectedSideImage, setSelectedSideImage] = useState('');
    const [selectedColorImage, setSelectedColorImage] = useState('');
    const [productColors, setProductColors] = useState(null);
    const [productColorSizes, setProductColorSizes] = useState(null);
    const [selectedColor, setSelectedColor] = useState('');
    const [selectedMainImage, setSelectedMainImage] = useState(null);
    const [selectedSize, setSelectedSize] = useState('');
    const [productDetail, setProductDetail] = useState(null);
    const [sizes, setSizes] = useState(null);
    const [cartItems, setCartItem] = useState(cart.cart.cartItems);
    const [selectedItemPrice, setSelectedItemPrice] = useState(null);
    const [showSize, setShowSize] = useState(false);
    const [productTagType, setProductTagType] = useState('');
    const [sizeChart, setSizeChart] = useState(null);
    const [oneSize, setOneSize] = useState(true);
    const [checkoutId, setCheckoutId] = useState(false);
    const [loading, setLoading] = useState(false);
    const [productModel, setProductModel] = useState(false);
    const [productStyle, setProductStyle] = useState(false);
    const [productFinish, setProductFinish] = useState(false);
    const [isAccessory, setIsAccessory] = useState(false);
    const [selectedPhoneCoverModel, setSelectedPhoneCoverModel] = useState("");
    const [selectedPhoneCoverStyle, setSelectedPhoneCoverStyle] = useState("");
    const [selectedPhoneCoverFinish, setSelectedPhoneCoverFinish] = useState("");
    const [selectedCushionSize, setSelectedCushionSize] = useState("");
    const [selectedCushionMaterial, setSelectedCushionMaterial] = useState("");
    const [selectedFramedPrintSize, setSelectedFramedPrintSize] = useState("");
    const [selectedFramedPrintFrame, setSelectedFramedPrintFrame] = useState("");
    const [selectedScarfColor, setSelectedScarfColor] = useState("");
    const [selectedScarfSize, setSelectedScarfSize] = useState("");
    const [selectedSnoodColor, setSelectedSnoodColor] = useState("");
    const [selectedSnoodSize, setSelectedSnoodSize] = useState("");
    const [selectedMugsSize, setSelectedMugsSize] = useState("");
    const [selectedMugsColor, setSelectedMugsColor] = useState("");
    const [selectedNotebookSize, setSelectedNotebookSize] = useState("");
    const [selectedNotebookStyle, setSelectedNotebookStyle] = useState("");
    const [selectedCoasterQuantity, setSelectedCoasterQuantity] = useState("");
    const [selectedTowelSize, setSelectedTowelSize] = useState("");
    const [itemQuantity, setItemQuantity] = useState(1);
    const [randomProducts] = useState(shuffleArray(cart.shopify.randomProducts).slice(0,4));
    // let [grandTotal, setGrandTotal] = useState(cart.cart.grandTotal);
    /* States End */

    /* Variables Start */
    const hostName = window.location.hostname;
    const domainObject = getHostname(hostName);
    let target = domainObject.host;
    let bgColor = NAV_CLASS[target], textColor = TEXT_COLOR[target], fontColor = FONT_COLOR[target],
        colorRgba = COLORS_RGBA[target], topBannerStyle = {
            width: '100%',
            height: '144px',
            background: `linear-gradient(to bottom, ${colorRgba}0.71) 45%, ${colorRgba}1)), url(${require('../../../assets/images/shop_top_banner.png')})`,
            backgroundSize: 'cover'
        }, currentItem = shopPageImages[1], loadingGif = LOADING_GIF[target];
    ;
    let productId = props.match.params.id;
    let params = useParams();
    let isMobile = false;

    if (windowSize.width <= SCREEN_SIZE.MOBILE) {
        isMobile = true;
    }
    /* Variables End */

    /* Use Effect Start */
    useEffect(() => {
        fetchData();
    }, [props.match.params.id]);
    /* Use Effect End */

    /*useEffect(() => {
        localStorage.removeItem('checkoutUrl')
    })*/

    /* API Call Start */

    const getShopifyCart = () => {
        let checkoutId = localStorage.getItem("checkoutId");
        let data = {checkout_id: checkoutId};
        graphql(ShopifyGraphQlQueries(SHOPIFY.GET_SHOPIFY_CART, null, null, data))
            .then((response) => {
                if (response.data.data !== undefined && response.data.data.node !== undefined && response.data.data.node.lineItems !== undefined) {
                    console.log("get shopify cart in product detail: ", response);
                    let tempCartItems = [];
                    response.data.data.node.lineItems.edges.map((item) => {
                        let tempVariant = {
                            id: item.node.variant.id,
                            title: item.node.variant.title,
                            price: item.node.variant.price,
                            image: item.node.variant.image,
                        };
                        let node = {
                            id: item.node.variant.product.id,
                            product_name: item.node.title,
                            variant: tempVariant,
                            size: item.node.variant.selectedOptions[0].value,
                            color: item.node.variant.selectedOptions[1] ? item.node.variant.selectedOptions[1] : "",
                            lineItemId: item.node.id,
                            quantity: item.node.quantity,
                        };
                        tempCartItems.push(node);

                    });
                    console.log("tempCartItems: ", tempCartItems);
                    dispatch(setCartItems(tempCartItems));
                } else {
                    localStorage.clear()
                }

            })
            .catch((err) => {
                console.log(err);
            })
    }

    const fetchData = () => {
        setLoading(true);
        graphql(ShopifyGraphQlQueries(SHOPIFY.PRODUCT_DETAIL, null, params.id))
            .then((response) => {

                let productDetail = response.data.data.productByHandle;
                let tempColors = [];
                let tempColorSizes = [];
                let tempProductColors = [];
                if (productDetail) {
                    setLoading(false);
                    if (productDetail.productType === "Accessories" && productDetail.tags.includes("Phone Cases")) {
                        setIsAccessory(true);
                        setSelectedPhoneCoverModel(productDetail.options[0].values[0]);
                        setSelectedPhoneCoverStyle(productDetail.options[1].values[0]);
                        setSelectedPhoneCoverFinish(productDetail.options[2].values[0]);
                        setSelectedItemPrice(productDetail.variants.edges[0].node);
                    }
                    if (productDetail.productType === "Accessories" && productDetail.tags.includes("Cushions")) {
                        setIsAccessory(true);
                        setSelectedCushionMaterial(productDetail.options[0].values[0]);
                        setSelectedCushionSize(productDetail.options[1].values[0]);
                    }
                    if (productDetail.productType === "Accessories" && productDetail.tags.includes("Wall Art")) {
                        setIsAccessory(true);
                        setSelectedFramedPrintSize(productDetail.options[0].values[0]);
                        setSelectedFramedPrintFrame(productDetail.options[1].values[0]);
                    }
                    if (productDetail.productType === "Accessories" && productDetail.tags.includes("Scarves")) {
                        setIsAccessory(true);
                        setSelectedScarfSize(productDetail.options[0].values[0]);
                        setSelectedScarfColor(productDetail.options[1].values[0]);
                    }
                    if (productDetail.productType === "Accessories" && productDetail.tags.includes("Snood")) {
                        setIsAccessory(true);
                        setSelectedSnoodSize(productDetail.options[0].values[0]);
                        setSelectedSnoodColor(productDetail.options[1].values[0]);
                    }
                    if (productDetail.productType === "Accessories" && productDetail.tags.includes("Mugs")) {
                        setIsAccessory(true);
                        setSelectedMugsSize(productDetail.options[0].values[0]);
                        setSelectedItemPrice(productDetail.variants.edges[0].node);
                        if (productDetail.options[1]) {
                            setSelectedMugsColor(productDetail.options[1].values[0]);
                        }
                    }
                    if (productDetail.productType === "Accessories" && productDetail.tags.includes("Notebooks")) {
                        setIsAccessory(true);
                        setSelectedNotebookSize(productDetail.options[0].values[0]);
                        setSelectedNotebookStyle(productDetail.options[1].values[0]);
                    }
                    if (productDetail.productType === "Accessories" && productDetail.tags.includes("Coasters")) {
                        setIsAccessory(true);
                        setSelectedItemPrice(productDetail.variants.edges[0].node);
                        setSelectedCoasterQuantity(productDetail.options[0].values[0]);
                    }
                    if (productDetail.productType === "Accessories" && productDetail.tags.includes("Towels")) {
                        setIsAccessory(true);
                        setSelectedTowelSize(productDetail.options[0].values[0]);
                        setSelectedItemPrice(productDetail.variants.edges[0].node);
                    }
                    setProductDetail(productDetail)
                    setSelectedMainImage(productDetail.images.edges[0].node.src);
                    tempColors = productDetail.options[1].values;
                    tempColors.map((color) => {
                        tempColorSizes.push();
                        tempColorSizes[`${color}`] = productDetail.variants.edges.filter(
                            (variant) => variant.node.title.split(" / ")[variant.node.title.split(" / ").length - 1] === color);
                    });
                    setProductColorSizes(tempColorSizes);
                    tempColors.map((color) => {
                        tempProductColors.push(tempColorSizes[color][0].node);
                    });
                    setProductColors(tempProductColors);
                    setProductColorSizes(tempColorSizes);
                    setSizes(tempColorSizes[tempColors[0]]);
                    if (tempColorSizes[tempColors[0]][0].node.title.split(" / ")[0] === "One Size") {
                        setOneSize(false);
                    }
                    setSelectedColor(tempColors[0]);
                    setSelectedItemPrice(tempColorSizes[tempColors[0]][0].node);

                    let tagForSizeGuide = productDetail.tags[0] === 'Featured' ? productDetail.tags[1] : productDetail.tags[0];
                    setProductTagType(tagForSizeGuide + " " + productDetail.productType);

                }
            })
            .catch((err) => {
                console.error(err);
            });
    }

    const fetchSizeChart = () => {
        if (!sizeChart) {
            let tagType = convertToSlug(productTagType);
            let url = ProductSizeChart + tagType;
            get(url)
                .then((response) => {
                    if (response.status === 200) {
                        setSizeChart(response.data.table);
                    } else {
                    }
                    setShowSize(true);
                })
                .catch((err) => {
                    console.error(err);
                    setShowSize(false);
                })
        }
    }
    /* API Call End */

    /* Event Methods Start */

    const convertToSlug = (string) => {
        return string
            .toLowerCase()
            .replace(/ /g, '-')
            .replace(/[^\w-]+/g, '')
            ;
    }
    const addToCart = () => {
        if (selectedSize !== "" ||
            (selectedCushionSize !== "" && selectedCushionMaterial !== "") ||
            (selectedPhoneCoverModel !== "" && selectedPhoneCoverStyle !== "" && selectedPhoneCoverFinish !== "") ||
            (selectedScarfColor !== "" && selectedScarfSize !== "") ||
            (selectedNotebookSize !== "" && selectedNotebookStyle !== "") ||
            (selectedFramedPrintFrame !== "" && selectedFramedPrintSize !== "") ||
            (selectedSnoodSize !== "" && selectedSnoodColor !== "") ||
            (selectedMugsSize !== "" || selectedMugsColor !== "") ||
            selectedCoasterQuantity !== "" || selectedTowelSize !== "") {
            setLoading(true);
            let selectedVariant = {};
            console.log("productDetail: ", productDetail);
            if (productDetail.productType !== "Accessories") {
                selectedVariant = productColorSizes[selectedColor].filter((value) => value.node.title === selectedSize + " / " + selectedColor)[0].node;
            }
            if (productDetail.productType === "Accessories" && productDetail.tags.includes("Phone Cases")) {
                selectedVariant = productDetail.variants.edges.filter((value) => value.node.title === selectedPhoneCoverModel + " / " + selectedPhoneCoverStyle + " / " + selectedPhoneCoverFinish)[0].node;
            }
            if (productDetail.productType === "Accessories" && productDetail.tags.includes("Cushions")) {
                selectedVariant = productDetail.variants.edges.filter((value) => value.node.title === selectedCushionMaterial + " / " + selectedCushionSize)[0].node;
            }
            if (productDetail.productType === "Accessories" && productDetail.tags.includes("Wall Art")) {
                selectedVariant = productDetail.variants.edges.filter((value) => value.node.title === selectedFramedPrintSize + " / " + selectedFramedPrintFrame)[0].node;
            }
            if (productDetail.productType === "Accessories" && productDetail.tags.includes("Scarves")) {
                selectedVariant = productDetail.variants.edges.filter((value) => value.node.title === selectedScarfSize + " / " + selectedScarfColor)[0].node;
            }
            if (productDetail.productType === "Accessories" && productDetail.tags.includes("Snood")) {
                selectedVariant = productDetail.variants.edges.filter((value) => value.node.title === selectedSnoodSize + " / " + selectedSnoodColor)[0].node;
            }
            if (productDetail.productType === "Accessories" && productDetail.tags.includes("Mugs")) {
                if (selectedMugsColor !== "") {
                    selectedVariant = productDetail.variants.edges.filter((value) => value.node.title === selectedMugsSize + " / " + selectedMugsColor)[0].node;
                } else {
                    selectedVariant = productDetail.variants.edges.filter((value) => value.node.title === selectedMugsSize)[0].node;
                }
            }
            if (productDetail.productType === "Accessories" && productDetail.tags.includes("Notebooks")) {
                selectedVariant = productDetail.variants.edges.filter((value) => value.node.title === selectedNotebookSize + " / " + selectedNotebookStyle)[0].node;
            }
            if (productDetail.productType === "Accessories" && productDetail.tags.includes("Coasters")) {
                selectedVariant = productDetail.variants.edges.filter((value) => value.node.title === selectedCoasterQuantity)[0].node;
            }
            if (productDetail.productType === "Accessories" && productDetail.tags.includes("Towels")) {
                selectedVariant = productDetail.variants.edges.filter((value) => value.node.title === selectedTowelSize)[0].node;
            }
            /*let item = {
                id: productDetail.id,
                product_name: productDetail.title,
                variant: selectedVariant,
                size: selectedSize,
                color: selectedColor,
            };*/
            let filterCheck = cart.cart.cartItems.filter((value) => value.variant.id === selectedVariant.id);
            console.log("variant id: ", selectedVariant.id);
            if (filterCheck.length === 0) {
                let checkoutId = localStorage.getItem("checkoutId");
                if (!checkoutId) {
                    let data = {variant_quantity: 1, variant_id: selectedVariant.id};
                    graphql(ShopifyGraphQlQueries(SHOPIFY.CHECKOUT_CREATE, null, null, data))
                        .then((response) => {
                            if (response.data.data !== undefined && response.data.data.checkoutCreate !== undefined) {
                                localStorage.setItem("checkoutId", response.data.data.checkoutCreate.checkout.id);
                                localStorage.setItem("checkoutUrl", response.data.data.checkoutCreate.checkout.webUrl);
                                setCheckoutId(true);
                                setLoading(false);
                                getShopifyCart();
                            } else {
                                localStorage.removeItem('checkoutUrl')
                                setLoading(false);
                                dispatch(setCartItems([]));
                                console.log("Coming Here When Adding New Cart.");
                                console.log("creating new cart:", response);
                                alert("Something went wrong. Please try again.");
                            }

                        }).catch((err) => {
                        console.error(err);
                    })
                } else {
                    let data = {checkout_id: checkoutId, variant_quantity: 1, variant_id: selectedVariant.id};
                    graphql(ShopifyGraphQlQueries(SHOPIFY.CHECKOUT_LINE_ITEMS_ADD, null, null, data))
                        .then((response) => {
                            if (response.data.data !== undefined && response.data.data.checkoutLineItemsAdd !== undefined) {
                                localStorage.removeItem("checkoutUrl");
                                localStorage.setItem('checkoutUrl', response.data.data.checkoutLineItemsAdd.checkout.webUrl);
                                setCheckoutId(true);
                                setLoading(false);
                                getShopifyCart();
                            } else {
                                localStorage.removeItem('checkoutUrl')
                                setLoading(false);
                                dispatch(setCartItems([]));
                                console.log("coming here.");
                                alert("Something went wrong. Please try again.");
                            }

                        })
                        .catch((err) => {
                            console.error(err);
                        })
                }
                /*setCartItem([...cartItems, ...[item]]);
                dispatch(setCartItems([...cartItems, ...[item]]));*/

                dispatch(setGrandTotal(parseFloat(cart.cart.grandTotal) + parseFloat(selectedVariant.price)));
            } else {
                let checkoutId = localStorage.getItem("checkoutId");
                let tempQuantity = itemQuantity + 1;
                setItemQuantity(itemQuantity + 1);
                console.log("lineitem id: ", filterCheck);
                let data = {
                    checkout_id: checkoutId,
                    variant_quantity: tempQuantity,
                    linkItem_id: filterCheck[0].lineItemId
                }
                graphql(ShopifyGraphQlQueries(SHOPIFY.CHECKOUT_LINE_ITEMS_UPDATE, null, null, data))
                    .then((response) => {
                        if (response.data.data !== undefined && response.data.data.checkoutLineItemsUpdate !== undefined) {
                            localStorage.removeItem("checkoutUrl");
                            localStorage.setItem('checkoutUrl', response.data.data.checkoutLineItemsUpdate.checkout.webUrl);
                            /*setSubtotal(subtotal + parseFloat(currentItem[0].node.variant.price));
                            getShopifyCart();*/
                            setCheckoutId(true);
                            setLoading(false);
                            getShopifyCart();
                        } else {
                            localStorage.removeItem('checkoutUrl')
                            setLoading(false);
                            dispatch(setCartItems([]));
                            alert("Something went wrong. Please try again.");
                        }

                    })
                    .catch((err) => {
                        console.error(err);
                    })
                // setLoading(false);
            }
        } else {
            alert("Please select the Options first.");
        }
    }

    const selectImage = (img) => {
        setSelectedSideImage(img.currentTarget.id);
        setSelectedMainImage(img.currentTarget.src);
    }

    const selectColor = (img) => {
        setSelectedColorImage(img.currentTarget.value);
        setSizes(productColorSizes[img.currentTarget.value]);
        isMobile && setSelectedMainImage(img.currentTarget[img.currentTarget.selectedIndex].dataset.src)
        !isMobile && setSelectedMainImage(img.currentTarget[img.currentTarget.selectedIndex].dataset.src);
        setSelectedColor(img.currentTarget.value);
    }

    const selectSize = (e) => {
        setSelectedSize(e.currentTarget.value);
        let selectedItem = productColorSizes[selectedColor]
            .filter((value) => value.node.title === e.currentTarget.value + " / " + selectedColor);
        setSelectedItemPrice(selectedItem[0].node);
    }

    const showSizeGuideTable = () => {
        fetchSizeChart();
        if (showSize) {
            setShowSize(false);
        } else {
            setShowSize(true);
        }
    }

    const setSelectedAccessoriesOption = (event) => {
        let key = event.currentTarget.dataset.option;
        let selectedItem;
        console.log("selectedItem: ", productDetail);
        if (productDetail.tags.includes("Phone Cases")) {
            switch (key) {
                case "Model":
                    setSelectedPhoneCoverModel(event.currentTarget.value);
                    try {
                        selectedItem = productDetail.variants
                            .filter((value) => value.node.title === event.currentTarget.value + " / " + selectedPhoneCoverStyle + " / " + selectedPhoneCoverFinish);
                    } catch (e) {
                        selectedItem = productDetail.variants.edges
                            .filter((value) => value.node.title === event.currentTarget.value + " / " + selectedPhoneCoverStyle + " / " + selectedPhoneCoverFinish);
                    }
                    setSelectedItemPrice(selectedItem[0].node);
                    setSelectedMainImage(selectedItem[0].node.image.src);
                    break;
                case "Style":
                    setSelectedPhoneCoverStyle(event.currentTarget.value);
                    try {
                        selectedItem = productDetail.variants
                            .filter((value) => value.node.title === selectedPhoneCoverModel + " / " + event.currentTarget.value + " / " + selectedPhoneCoverFinish);
                    } catch (e) {
                        selectedItem = productDetail.variants.edges
                            .filter((value) => value.node.title === selectedPhoneCoverModel + " / " + event.currentTarget.value + " / " + selectedPhoneCoverFinish);
                    }
                    setSelectedItemPrice(selectedItem[0].node);
                    break;
                case "Finish":
                    setSelectedPhoneCoverFinish(event.currentTarget.value);
                    try {
                        selectedItem = productDetail.variants
                            .filter((value) => value.node.title === selectedPhoneCoverModel + " / " + selectedPhoneCoverStyle + " / " + event.currentTarget.value);
                    } catch (e) {
                        selectedItem = productDetail.variants
                            .filter((value) => value.node.title === selectedPhoneCoverModel + " / " + selectedPhoneCoverStyle + " / " + event.currentTarget.value);
                    }
                    setSelectedItemPrice(selectedItem[0].node);
                    break;
                default:
                    return false;
            }
        }
        if (productDetail.tags.includes("Cushions")) {
            switch (key) {
                case "Material":
                    setSelectedCushionMaterial(event.currentTarget.value);
                    break;
                case "Size":
                    setSelectedCushionSize(event.currentTarget.value);
                    selectedItem = productDetail.variants.edges
                        .filter((value) => value.node.title === selectedCushionMaterial + " / " + event.currentTarget.value);
                    setSelectedItemPrice(selectedItem[0].node);
                    break;
                default:
                    return false;
            }
        }
        if (productDetail.tags.includes("Wall Art")) {
            console.log('productColorSizes', productColorSizes, event.currentTarget.value, selectedColor, key);
            switch (key) {
                case "Frame":
                    setSelectedFramedPrintFrame(event.currentTarget.value);
                    break;
                case "Size":
                    setSelectedFramedPrintSize(event.currentTarget.value);
                    let selectedItem = productColorSizes[selectedColor]
                        .filter((value) => value.node.title === event.currentTarget.value + " / " + selectedColor);
                    setSelectedItemPrice(selectedItem[0].node);
                    break;
                default:
                    return false;
            }
        }
        if (productDetail.tags.includes("Scarves")) {
            switch (key) {
                case "Color":
                    setSelectedScarfColor(event.currentTarget.value);
                    break;
                case "Size":
                    setSelectedScarfSize(event.currentTarget.value);
                    break;
                default:
                    return false;
            }
        }
        if (productDetail.tags.includes("Snoods")) {
            switch (key) {
                case "Color":
                    setSelectedSnoodColor(event.currentTarget.value);
                    break;
                case "Size":
                    setSelectedSnoodSize(event.currentTarget.value);
                    break;
                default:
                    return false;
            }
        }
        if (productDetail.tags.includes("Mugs")) {
            switch (key) {
                case "Size":
                    setSelectedMugsSize(event.currentTarget.value);
                    break;
                default:
                    return false;
            }
        }
        if (productDetail.tags.includes("Notebooks")) {
            switch (key) {
                case "Size":
                    setSelectedNotebookSize(event.currentTarget.value);
                    break;
                case "Style":
                    setSelectedNotebookStyle(event.currentTarget.value);
                    break;
                default:
                    return false;
            }
        }
        if (productDetail.tags.includes("Coasters")) {
            switch (key) {
                case "Quantity":
                    setSelectedCoasterQuantity(event.currentTarget.value);
                    selectedItem = productDetail.variants.edges
                        .filter((value) => value.node.title === event.currentTarget.value);
                    setSelectedItemPrice(selectedItem[0].node);
                    break;
                default:
                    return false;
            }
        }
        if (productDetail.tags.includes("Towels")) {
            switch (key) {
                case "Size":
                    setSelectedTowelSize(event.currentTarget.value);
                    selectedItem = productDetail.variants.edges
                        .filter((value) => value.node.title === event.currentTarget.value);
                    setSelectedItemPrice(selectedItem[0].node);
                    break;
                default:
                    return false;
            }
        }
    }
    /* Event Methods End */

    /* Render Methods Start */

    const renderImageList = () => {
        return (
            <div className={`row mt-2`}>
                {
                    productDetail && productDetail.images.edges.map((value, index) => {
                        let imageId = `sideImage${value.node.id}`;
                        let activeClass = '';
                        if (selectedSideImage === imageId) {
                            activeClass = 'shadow-nav-dark';
                        }
                        return (
                            <div className={'col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-4 col-4'}>
                                <img src={value.node.src} alt={value.node.altText}
                                     className={`img-fluid ${index > 0 && !isMobile ? 'mt-2' : ''} btn p-0 ${activeClass}`}
                                     id={imageId}
                                     data-id={index} onClick={selectImage}
                                />
                            </div>
                        );
                    })
                }
            </div>
        );
    }

    const renderMainImage = (item) => {
        return item && (
            <div key={item.id} className={'slide border'}>
                <img src={item} alt={`Main Image`} className={'img-fluid'}/>
            </div>
        );
    }

    const renderColorsList = () => {
        return (
            <span className={`mt-2 mb-4 w-100 col-4 bebasFont justify-content-start ${textColor} d-block`}>
                Color
                <select className="form-select w-100 color-list fanzine-select text-dark" onChange={selectColor}>
                    {productColors && productColors.map((value, index) => (
                        <option value={value.title.split(" / ")[1]} data-src={value.image.src}>
                            {value.title.split(" / ")[1]}
                        </option>

                    ))}
                </select>
            </span>
        );
    }

    const renderAccessoryOptions = () => {
        return (
            isAccessory && productDetail && productDetail.options.map((option) => (
                <span className={`mt-2 mb-4 w-100 col-4 bebasFont justify-content-start ${textColor} d-block`}>
                {option.name}
                    <select className="form-select w-100 color-list fanzine-select text-dark"
                            onChange={setSelectedAccessoriesOption} data-option={option.name}>
                    {option.values.map((value, index) => (
                        <option value={value}>
                            {value}
                        </option>

                    ))}
                </select>
            </span>
            ))
        );
    }

    const renderDetails = () => {
        return (
            <>
                {!isMobile && (<div className={`col-12 bg-grey p-3`}>
                    <div className={`d-flex flex-row w-100`}>
                        <span
                            className={`d-flex flex-row w-50 sfProDisplayFontBold justify-content-start h4`}>{productDetail && productDetail.title}</span>
                        {selectedItemPrice &&
                        <span
                            className={`d-flex flex-row w-50 sfProDisplayFontBold justify-content-end h4 float-end`}>
                        <span
                            className={`sfProDisplayFontBold ${selectedItemPrice?.compareAtPrice ? selectedItemPrice?.compareAtPrice  : selectedItemPrice?.price ? selectedItemPrice?.price : selectedItemPrice?.amount ? 'text-muted text-decoration-line-through' : ''}`}>£{selectedItemPrice?.compareAtPrice ? selectedItemPrice?.compareAtPrice  : selectedItemPrice?.price ? selectedItemPrice?.price : selectedItemPrice?.amount}</span>
                            {(selectedItemPrice?.compareAtPrice ? selectedItemPrice?.compareAtPrice  : selectedItemPrice?.price ? selectedItemPrice?.price : selectedItemPrice?.amount) &&
                            <span className={`sfProDisplayFontBold ms-2`}>£{(selectedItemPrice?.compareAtPrice ? selectedItemPrice?.compareAtPrice  : selectedItemPrice?.price ?  selectedItemPrice?.price : selectedItemPrice?.amount)}</span>
                            }
                        </span>}
                    </div>
                    {renderColorSizesLaptop()}
                </div>)}
                {isMobile && (
                    <div className={'col-12'}>
                        {isAccessory ? renderAccessoryOptions() : renderColorsList()}
                        {renderSizes()}
                    </div>
                )}
                <div className={`col-12 ${isMobile ? 'bg-transparent' : 'bg-grey'} p-3 pt-5 mt-2`}>
                    <div className={`d-flex flex-row w-100 bebasFont`}>
                        <span
                            className={`d-flex flex-row bebasFont w-50 justify-content-start h4 ${isMobile ? textColor : 'text-black sfProDisplayFont'}`}>Description</span>
                    </div>
                    {productDetail && <div style={{color: '#707070'}}
                                           dangerouslySetInnerHTML={{__html: productDetail?.descriptionHtml}}/>}
                </div>
            </>
        );
    }

    const renderSizes = () => {
        return (
            <>
                {!isAccessory && <>
                    <div className={`d-flex flex-row w-100 mb-4`}>
                    <span className={`mt-2 d-flex flex-row w-50 bebasFont justify-content-start ${textColor}`}>
                        Select Size
                    </span>
                        {oneSize ? (<span
                            className={`btn d-flex flex-row w-50 bebasFont justify-content-end small text-light-black`}>
                        <div
                            className={isMobile ? `${bgColor} text-white ps-4 pe-2 pt-1 pb-1 rounded-pill text-end` : 'shadow-nav'}
                            onClick={showSizeGuideTable}>
                            Size Guide {" "}
                            <FontAwesomeIcon icon={faInfoCircle}/>
                        </div>
                    </span>) : <></>}
                    </div>
                    {sizeChart && <div className={`${showSize ? '' : 'd-none'} text-center`}>
                        <Table striped bordered hover className="bg-white" width="900">
                            <thead className="bg-black text-white">
                            <tr>
                                {sizeChart.title.map((item) => (
                                    <th width="750">{item}</th>
                                ))}
                            </tr>
                            </thead>
                            <tbody>
                            {Object.values(sizeChart.size).map((item) => (
                                <tr>
                                    {item.map((size) => (
                                        <td>{size}</td>
                                    ))}
                                </tr>
                            ))}
                            </tbody>
                        </Table>
                    </div>}
                    <div className={`d-flex flex-row w-100 flex-wrap`}>
                        <ButtonGroup>
                            <div className="row pe-2">
                                {
                                    sizes && sizes.map((value, index) => {
                                        return (
                                            /*<ProductSizeToggleButton index={index} value={value} isMobile={isMobile}/>*/
                                            <div className="col-2 mt-2">
                                                <ToggleButton
                                                    key={index}
                                                    id={`radio-${index}`}
                                                    type="radio"
                                                    variant={selectedSize === value.node.title.split(" / ")[0] ? 'dark' : 'light'}
                                                    name="radio"
                                                    value={value.node.title.split(" / ")[0]}
                                                    checked={selectedSize === value.node.title.split(" / ")[0]}
                                                    className={`ms-1 me-1 sizeBox ${selectedSize === value.node.title.split(" / ")[0] && bgColor}`}
                                                    onChange={selectSize}
                                                >
                                                    {value.node.title.split(" / ")[0]}
                                                </ToggleButton>
                                            </div>
                                        );
                                    })
                                }
                            </div>
                        </ButtonGroup>
                    </div>
                </>}
                <div className={`d-flex flex-row w-100 mt-3 ${isMobile ? 'justify-content-center' : ''}`}>
                    <span
                        className={`btn btn-dark p-2 text-uppercase ${bgColor} border-0 sfProDisplayFont ${isMobile ? 'w-auto rounded-pill btn-lg p-3' : 'small w-25 rounded'} ${fontColor}`}
                        onClick={addToCart}>Add To Cart</span>
                </div>
            </>
        );
    }

    const renderColorSizesLaptop = () => {
        return (
            <>
                <div className={`d-flex row`}>
                    {isAccessory && productDetail && productDetail.options.map((option) => (
                        <span className={`mt-2 col-4 bebasFont justify-content-start text-gray d-block`}>
                            {option.name}
                            <div className={`${isMobile ? 'col-12' : 'col-6'}`}>
                                <select className="form-select w-165 color-list fanzine-select"
                                        onChange={setSelectedAccessoriesOption} data-option={option.name}>
                                {option.values.map((value, index) => (
                                    <option value={value}>
                                        {value}
                                    </option>

                                ))}
                                </select>
                            </div>
                        </span>
                    ))}
                    {!isAccessory && <>
                        <span className={`mt-2 col-4 bebasFont justify-content-start text-gray d-block`}>
                            Color
                            <div className={`${isMobile ? 'col-12' : 'col-6'}`}>
                                <select className="form-select w-165 color-list fanzine-select" onChange={selectColor}>
                                {productColors && productColors.map((value, index) => (
                                    <option value={value.title.split(" / ")[1]} data-src={value.image.src}>
                                        {value.title.split(" / ")[1]}
                                    </option>

                                ))}
                                </select>
                            </div>
                        </span>
                        <span className={`mt-2 col-5 bebasFont justify-content-start text-gray`}>
                            Size
                            <div className={`${isMobile ? 'col-12' : 'col-6'}`}>
                                <select className="form-select w-165 fanzine-select" onChange={selectSize}>
                                    <option value="">Select Size</option>
                                    {sizes && sizes.map((value, index) => (

                                        <option value={value.node.title.split(" / ")[0]}>
                                            {value.node.title.split(" / ")[0]}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </span>
                    </>}
                    {oneSize && !isAccessory ?
                        <span className={`btn col-3 bebasFont justify-content-end small text-gray`}>
                        <div
                            className={isMobile ? 'bg-gunners text-white ps-4 pe-2 pt-1 pb-1 rounded-pill text-end' : 'bg-white rounded-pill p-1 shadow-nav'}
                            onClick={showSizeGuideTable}>
                            Size Guide {" "}
                            <FontAwesomeIcon icon={faInfoCircle}/>
                        </div>
                    </span> : <></>}
                    {sizeChart && <div id="size-guide-table" className={`${showSize ? '' : 'd-none'} text-center`}>
                        <Table striped bordered hover className="bg-white" width="900">
                            <thead className="bg-black text-white">
                            <tr>
                                {sizeChart.title.map((item) => (
                                    <th width="750">{item}</th>
                                ))}
                            </tr>
                            </thead>
                            <tbody>
                            {Object.values(sizeChart.size).map((item) => (
                                <tr>
                                    {item.map((size) => (
                                        <td>{size}</td>
                                    ))}
                                </tr>
                            ))}
                            </tbody>
                        </Table>
                    </div>}
                </div>
                <div className={`d-flex flex-row w-100 mt-3 ${isMobile ? 'justify-content-center' : ''}`}>
                    <span
                        className={`btn btn-dark p-2 text-uppercase ${bgColor} border-0 sfProDisplayFont ${isMobile ? 'w-auto rounded-pill btn-lg' : 'small w-25 rounded'} ${fontColor}`}
                        onClick={addToCart}>Add To Cart</span>
                </div>

            </>
        );
    }

    const render = () => {
        if (loading) {
            return (
                <>
                    <div className={`${isMobile ? "container" : ""} loader`}>
                        <img src={loadingGif}
                             alt="loader" width="150"/>
                    </div>
                </>
            )
        }
        return (
            <>
                {productDetail && <Banner checkoutSet={checkoutId} breadcrums={true} productId={productId}
                                          product={productDetail.title}
                                          grandTotal={cart.cart.grandTotal}/>}

                <div                        // mt-5 added on isMobile as title was hidden behind topNav
                    className={`container ${isMobile ? 'mt-5' : 'mt-5'} text-gunners d-block d-md-none d-lg-none d-xl-none d-xxl-none`}>
                    <div className={`d-flex flex-row w-100`}>
                        <span
                            className={`d-flex flex-row w-50 bebasFont justify-content-start h1 ${isMobile ? textColor : ""}`}>{productDetail && productDetail.title}</span>
                        {selectedItemPrice &&
                        <span
                            className={`d-flex flex-row w-50 bebasFont justify-content-end h1 float-end ${isMobile ? textColor : ""}`}>
                            <span
                                className={`bebasFont ${selectedItemPrice?.compareAtPrice ? 'text-muted text-decoration-line-through' : ''}`}>
                                £{selectedItemPrice?.compareAtPrice ? selectedItemPrice?.compareAtPrice?.amount : selectedItemPrice?.price?.amount}
                            </span>
                            {selectedItemPrice?.compareAtPrice &&
                            <span className={`bebasFont ms-2`}>{selectedItemPrice?.price?.amount}</span>
                            }
                        </span>}
                    </div>
                    <div className={`d-flex flex-row w-100 sfProDisplayFont justify-content-end h4 text-muted`}>tax
                        included
                    </div>
                </div>

                <div className={`container ${isMobile ? '' : 'mt-5'}`}>
                    <div className={'row'}>
                        {/*{!isMobile && <div className={'col-2'}>
                            {renderImageList(shopPageImages)}
                        </div>}*/}
                        <div className={'col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12'}>
                            <div className={'col-12'}>
                                {renderMainImage(selectedMainImage)}
                            </div>
                            {isMobile && <div className={'col-12'}>
                                {renderImageList()}
                            </div>}
                            <div className={'col-12 mt-2'}>
                                {/*{renderColorsList(shopPageImages.slice(0, isMobile ? 4 : 5))}*/}
                                {/*<div className="row">*/}
                                {!isMobile && renderImageList()}
                                {/*</div>*/}
                            </div>
                        </div>
                        <div className={'col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12'}>
                            {renderDetails(currentItem)}
                        </div>
                    </div>
                </div>

                {
                    cart.shopify.randomProducts &&
                    (
                        <div className={'container mt-5 d-none d-md-block d-lg-block d-xl-block d-xxl-block'}>
                            <div className={`d-flex flex-row w-100`}>
                        <span
                            className={`d-flex flex-row w-50 bebasFont justify-content-start h1`}>You may also like</span>
                                <span className={`d-flex flex-row w-50 bebasFont justify-content-end h4 float-end`}>
                            <div className={'col'}>
                                <div
                                    className={'w-100 h-100 text-end h5 bebasFont d-flex flex-column justify-content-center'}>
                                    <div
                                        className={'h-100 w-100 text-end h5 bebasFont d-flex flex-row justify-content-end'}>
                                        <div
                                            className={'text-end h5 bebasFont d-flex flex-column justify-content-center'}>
                                            <a href={`/shop/`}
                                               className={'text-decoration-none bg-yellow text-blue sfProDisplayFont font-weight-800 small text-uppercase pt-2 ps-5 pe-5 pb-2 ' +
                                               'rounded-pill float-end text-end w-fit-content'}>
                                                View More
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </span>
                            </div>
                            <div className={'row'}>
                                {
                                    randomProducts.map((value) => {
                                        return (
                                            <div className={'col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-6'}>
                                                <ProductComponent item={value} showColors={true} showDetail={true}/>
                                            </div>
                                        );
                                    })
                                }
                            </div>
                        </div>
                    )
                }
            </>
        );
    }
    /* Render Methods End */

    // Return Main Render
    return render();
}

export default withRouter(ProductDetailPage);
