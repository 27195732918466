import {useEffect, useState} from "react";
import WeekCalendarContainerItem from "./WeekCalendarContainerItem";
import moment from "moment";
import {RiArrowLeftSLine, RiArrowRightSLine} from "react-icons/ri";
import {useSearchParams} from 'react-router-dom';

const WeekCalendarContainer = (props) => {
  const {selectedDay, isMobile, sport,currentWeek,setCurrentWeek,weekNumber,setWeekNumber, allWeeks} = props;

  const [dateCollection, setDateCollection] = useState("");
  const [searchParams,] = useSearchParams();
  const [selectedDate, setSelectedDate] = useState(sport === 'nfl' ? searchParams.get('week') : searchParams.get('date') || props.selectedDate)
  const [isWeek, setIsWeek] = useState(sport === 'nfl');
  let [preparedWeeks, setPreparedWeeks] = useState([])
  const [lastIndex, setLastIndex] = useState(5);

  const updateDateCollection = (numberOfDays, clickType) => {

    if(clickType === 'left')
    {
      setCurrentWeek(currentWeek-1);
    }
    else {
      setCurrentWeek(currentWeek+1);
    }

    let tempCollection = [];

    if (clickType === "left") {
      let firstDate = moment(selectedDate);
      if (allWeeks.length > 0) {
        if(((lastIndex-1)-5) < 0)
        {
          if(lastIndex === 0)
          {
            setLastIndex(allWeeks?.length);
            firstDate = allWeeks[ allWeeks?.length + (0-2)-5];
          }
          else {
            setLastIndex(lastIndex-1);
            firstDate = allWeeks[ allWeeks?.length + (lastIndex-1)-5];
          }
        }
        else {
          setLastIndex(lastIndex-1);
          firstDate = allWeeks[(lastIndex-1)-5];
        }
      }
      // for (let i = 1; i > 0; i--) {
        tempCollection.push(firstDate);
      // }
      preparedWeeks.pop();
      setPreparedWeeks((dateCollection) => [
        tempCollection[0],
        ...dateCollection,
      ]);
    }
    else if (clickType === "right") {
      let lastDate = moment(selectedDate);
      if (allWeeks.length > 0) {
        if(lastIndex === allWeeks?.length)
        {
          setLastIndex(0);
          lastDate = allWeeks[(0+1) - 1];

        }
        else {
          setLastIndex(lastIndex+1);
          lastDate = allWeeks[(lastIndex+1) - 1];

        }
      }
      // for (let i = 1; i < 2; i++) {
        tempCollection.push(lastDate);
      // }
      preparedWeeks.shift();
      setPreparedWeeks((dateCollection) => [
        ...dateCollection,
        tempCollection[0],
      ]);
    }

    if (!clickType) {
      // pushes the number of days minus current date
      // for (let i = numberOfDays; i > 0; i--) {
        tempCollection.push(allWeeks[0]);
        tempCollection.push(allWeeks[1]);
      // }

      // pushes the current date
      tempCollection.push(allWeeks[2]);

      // pushes the number of days after current date
      // for (let i = 1; i <= numberOfDays; i++) {
        tempCollection.push(allWeeks[3]);
        tempCollection.push(allWeeks[4]);
      // }

        setPreparedWeeks(tempCollection);


    }

  };

  const itemClicked = (e) => {
    console.log(moment(e).format("YYYY-MM-DD"));
    selectedDay(e);
    setSelectedDate(e)
    localStorage.setItem('selectedDate', e)
  };

  useEffect(() => {
    if (sport === 'nfl') {
      setIsWeek(true);
      setSelectedDate(searchParams.get('week') || props.selectedDate)
    }

    if (dateCollection === "" && allWeeks !== null) {
      // enter number of days to have a range in calendar
      // if 2 selected the range will be from (currentDate - 2 to currentDate + 2)
      updateDateCollection(2);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.allWeeks,props.selectedDate, searchParams.get('date'), searchParams.get('week')]);

  return (
      <>
        <div
            style={{
              padding: isMobile && 10 ,
              marginTop: isMobile && 10,
              position: isMobile && 'fixed',
            }}
            className={`container ${isMobile ? 'bg-black pt-3' : 'bg-white p-4'} `}>
          {/*{moment((selectedDate || new Date())).format('MMMM')}*/}
          <div className={`row ${isMobile ? 'pt-5 mt-2' : ''}`}>
            <div
                className={`col-1 d-flex align-items-center justify-content-start`}
                style={{cursor: 'pointer'}}
                onClick={() => {
                  updateDateCollection(0, "left");
                }}
            >
              <div className={'bg-background'}
                   style={{borderRadius: '50%'}}
              >
                <RiArrowLeftSLine size={isMobile ? 20 : 30} style={{margin: isMobile ? "2px" : "5px"}}/>
              </div>
            </div>
            <div className="col-10">
              <div className={`${isMobile ? 'slider-mobile-news-container p-1' : "row"}`}>
                {preparedWeeks?.length > 0 &&
                    preparedWeeks.map((value, index) => (
                        <WeekCalendarContainerItem
                            key={index}
                            data={value}
                            weekNumber={weekNumber}
                            setWeekNumber={setWeekNumber}
                            selectedDate={selectedDate}
                            setSelectedDate={setSelectedDate}
                            itemClicked={itemClicked}
                            isMobile={isMobile}
                            sport={sport}
                        >
                        </WeekCalendarContainerItem>
                    ))}
              </div>
            </div>
            <div
                className="col-1 d-flex align-items-center justify-content-end"
                style={{cursor: 'pointer'}}
                onClick={() => {
                  updateDateCollection(0, "right");
                }}
            >
              <div className={'bg-background'}
                   style={{borderRadius: '50%'}}
              >
                <RiArrowRightSLine size={isMobile ? 20 : 30} style={{margin: isMobile ? "2px" : "5px"}}/>
              </div>
            </div>
          </div>
        </div>
      </>
  );
};

export default WeekCalendarContainer;
