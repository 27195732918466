import {get} from '../../../Axios/get';
import {useEffect, useState} from 'react';
import {useLocation, useSearchParams} from 'react-router-dom';
import {FOOTBALL} from '../../../Axios/EndPoints';
import {Button} from 'react-bootstrap';
import {AiOutlineLeft, AiOutlineRight} from 'react-icons/ai';
import VideoCard from '../../../Components/Cards/VideoCard';
import {getHostname} from "../../../CustomFunctions/getHostname";
import {COLORS, FONT_COLOR} from "../../../Configs/Targets";
import VideoCardSlider from '../../FootballVideos/SliderVideos/videoCardSlider';
import FanzineSportsVideoCardSlider from './FanzineSportsVideoCardSlider';
import {useSelector} from 'react-redux';

const FanzineSportsSliderNoBackground = (props) => {
  const {isMobile, data, leagueId, leagueName, leagueBg,leagueColor,endpoint,isClub,setSelectedVideo,isGoal} = props;
  const [searchParams, setSearchParams] = useSearchParams()
  const [latestVideos, setLatestVideos] = useState([])
  const [latestVideosLoading, setLatestVideosLoading] = useState(true)
  const location = useLocation();
  const LoginStore = useSelector(state => state);


  const fetchLatestVideos = (endpoint = null) => {

    let league_id = (leagueId || searchParams.get('team_id') || searchParams.get('league_id') || data?.clubId || '');
    let parameters = leagueId ? '&league_id='+ leagueId : searchParams.get('league_id') ?
        '&league_id=' + league_id : searchParams.get('team_id') || data?.clubId ?
            '&team_id=' + league_id :
            '&main_site=1';


    if(endpoint?.includes('undefined'))
    {
      endpoint = endpoint.replace('?team_id=undefined','')
    }

    let url = endpoint ? endpoint : ((leagueId || searchParams.get('league_id')) ?
        FOOTBALL.LatestClubVideosEndPoint + parameters : searchParams.get('team_id') || data?.clubId ?
            FOOTBALL.LatestClubVideosEndPoint + parameters :
            FOOTBALL.LatestVideosEndPoint + parameters);

    get(url).then((response) => {
      // eslint-disable-next-line array-callback-return
      let tempData = response.data;
      if(!endpoint)
      {
        tempData.sort(function (a, b) {
          let keyA = new Date(a.published_at.date),
              keyB = new Date(b.published_at.date);
          // Compare the 2 dates
          if (keyA < keyB) return 1;
          if (keyA > keyB) return -1;
          return 0;
        });
      }
      setLatestVideos(tempData);
      setLatestVideosLoading(false);
    }).catch((err) => {
      console.error(err);
    });
  };


  useEffect(() => {
    setLatestVideosLoading(true);
    // if (props.videosData) {
    //     setLatestVideos(props.videosData);
    //     setLatestVideosLoading(props.loading);
    // } else {
    LoginStore?.fanzineSports?.isLogin && fetchLatestVideos(props.videosEndpoint || endpoint)
    // }
  }, [ LoginStore,location.pathname,endpoint, props.videosEndpoint, data])


  const renderLeftButton = (id) => {

    let style =
        {
          width: '63px',
          height: '68px',
          backgroundColor: '#fff',
          color: 'black',
          border: '0px',
          borderRadius: '0px',
        };

    const handleLeftClick = () => {
      document.getElementById('nobgvideoSliderContainer' + id).scrollLeft -= 400;

    };

    return (
        <div
            style={{
              marginLeft: '-3em',
              marginRight: '-2em',
              zIndex: '1',
              marginTop: '-4em',
            }}
            onClick={handleLeftClick}
        >
          <Button className={''} style={style}>
            <AiOutlineLeft/>
          </Button>
        </div>
    );
  };

  const renderRightButton = (id) => {

    let style =
        {
          width: '63px',
          height: '68px',
          backgroundColor: '#fff',
          color: 'black',
          border: '0px',
          borderRadius: '0px',
        };

    const handleRightClick = () => {
      document.getElementById('nobgvideoSliderContainer' + id).scrollLeft += 400;
    };

    return (
        <div
            onClick={handleRightClick}
            style={{
              marginLeft: '-3em',
              zIndex: '1',
              marginTop: '-4em',

            }}
        >
          <Button
              style={style}
          > <AiOutlineRight/>
          </Button>
        </div>
    );
  };

  const renderVideos = (videoData, title) => {
    const hostName = window.location.hostname;
    const domainObject = getHostname(hostName);
    const target = domainObject.host;
    let style = {
      // background:  '#fff',
    };

    return (
        <div className={`w-100 mb-5 ${target === 'fanzine' ? 'text-black' : FONT_COLOR[target]}`}
             style={style}
        >
          <div className={'container'}>
            <div className={`${isMobile ? 'w-100' : 'container'}`}>
              <div className={`row ${isMobile ? '' : 'ps-5 pe-5'}`}>
                <p className={`font-30 pt-4 pb-3 m-0 text-start robotoCondensedBold ${'text-black'}`}>
                  {title}
                  <hr
                      className={'w-25 m-0 p-0'}
                      style={{
                        borderBottom: '3px solid #000',
                        background:'#000'
                      }}
                  />
                  <hr className={'m-0 p-0'}
                      style={{
                        borderBottom: 'solid 1px #9c9c9c',
                      }}
                  />
                </p>
                <div
                    className={'d-flex justify-content-center align-items-center'}>
                  {!isMobile && renderLeftButton(videoData[0]?.id)}
                  <div
                      id={'nobgvideoSliderContainer'+videoData[0]?.id }
                      className={`p-2 m-1 slider-mobile-container ${videoData?.length <=
                      3 ?
                          'justify-content-center' :
                          'overflow-scroll-x'}`}>
                    {videoData?.map((match) => (
                        <FanzineSportsVideoCardSlider
                            isGoal={isGoal}
                            setSelectedVideo={setSelectedVideo}
                            isClub={true}
                            video={match} isFanzineAllFootball={true}
                            videoHeight={isMobile ? '100px' : '180px'} isMain={false}
                            extraClass={`${target === 'fanzine' ? 'text-black' : FONT_COLOR[target]} m-2`}
                            league={'Latest Videos'}
                            sport={props.sport || 'football'}
                            isMobile={isMobile}
                            target={target}
                        />
                    ),)}
                  </div>
                  {!isMobile && renderRightButton(videoData[0]?.id)}
                </div>
              </div>
            </div>
          </div>
        </div>
    );
  };

  const renderMain = () => {

    return latestVideos?.length > 0 && renderVideos(latestVideos, leagueName || 'MATCH HIGHLIGHTS');

  };

  return renderMain();

}

export default FanzineSportsSliderNoBackground;
