import {useLocation, useParams, useSearchParams} from 'react-router-dom';
import {useSelector} from 'react-redux';
import React, {useEffect, useState} from 'react';
import moment from 'moment-timezone';
import {Container} from 'react-bootstrap';
import {GiSpeaker} from 'react-icons/gi';
import {get} from '../../Axios/get';
import {FANZINE, HomePageVideoEndPoint} from '../../Axios/EndPoints';
import {MATCH_STATES, SCREEN_SIZE} from '../../Configs/Constants';
import Social from '../../Components/Layout/Social/Social';
import SingleMatchLeagueHeading
  from '../../Components/SingleMatchPageFootball/SingleMatchLeagueHeading';
import SingleFootballMatchItem
  from '../../Components/SingleMatchPageFootball/SingleFootballMatchItem';
import SingleFootballMatchContent
  from '../../Components/SingleMatchPageFootball/SingleFootballMatchContent';
import {SiteMetaHelmet} from '../../Components/SiteMetaHelmet';
import Loader from '../../Components/Loader/Loader';
import {SubNavbar} from '../../Components/Navbar/SubNavbar';
import {getHostname} from '../../CustomFunctions/getHostname';
import {COLORS_RGBA} from '../../Configs/Targets';
import SingleMatchLeagueStandings
  from '../../Components/SingleMatchPageFootball/SubComponentsSingleFootballPage/SingleMatchLeagueStandings';
import '../../assets/styles/SingleMatchItem.css'
import RightNewsAds from '../../Components/Layout/News/RightNewsAds';


const FanzineAllFootballSingleMatch = (props) => {
  let params = useParams();
  let store = useSelector((state) => state);
  // console.log("store in all football =", store);
  let queryParams = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  let [apiPage, setApiPage] = useState(1);
  let [restrictApiCall, setRestrictApiCall] = useState(true);
  let [loading, setLoading] = useState(true);
  let [isMobile, setIsMobile] = useState(false);
  let [matchData, setMatchData] = useState(null);
  let [matchDataLoading, setMatchDataLoading] = useState(true);
  // let [latestVideos, setLatestVideos] = useState([]);
  let [footballLeagues, setFootballLeagues] = useState(null);
  let [selectedLeague, setSelectedLeague] = useState(0);
  let [selectedDate, setSelectedDate] = useState(
      moment(Date.now()).format('YYYY-MM-DD'));
  let [selectedDatePicker, setSelectedDatePicker] = useState();
  let [liveMatchData, setLiveMatchData] = useState(null);
  let [leagueMatchesGroupedByDate, setLeagueMatchesGroupedByDate] = useState(
      null);
  let [isLeagueSelected, setIsLeagueSelected] = useState(false);
  let [singleMatchData, setSingleMatchData] = useState(null);

  useEffect(() => {
    setSelectedDate(
        searchParams.get('date') || moment(new Date()).format('YYYY-MM-DD'));
    matchData === null && setLoading(true);
    setRestrictApiCall(true);
    window.addEventListener('scroll', handleScroll);
    setApiPage(1);
    // fetchVideos(true);
    matchData === null && fetchMatchDetails();
    return () => window.removeEventListener('scroll', handleScroll);
  }, [queryParams.search, selectedLeague, searchParams]);

  useEffect(() => {
    setFootballLeagues(store?.football?.fanzineLeagues);
  }, [store?.football?.fanzineLeagues]);

  useEffect(() => {
    if (window.innerWidth <= SCREEN_SIZE.MOBILE) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, [window.innerWidth, isMobile]);

  useEffect(() => {
    fetchLeaguesMatchesByDate();
  }, [searchParams.get('league_id')]);

  // fetching Live Matches After 10s
  useEffect(() => {
    let isParamDate = !!searchParams.get('date');

    let checkDateWith = isParamDate ? searchParams.get('date') : selectedDate;

    // if (checkDateWith == moment(Date.now()).format('YYYY-MM-DD')) {
    //     console.log('today')
    //     const interval = setInterval(() => {
    //         fetchLiveMatches();
    //     }, 10000);
    //
    //     return () => clearInterval(interval);
    // }
  }, []);

  useEffect(() => {
  }, [isLeagueSelected]);

  const addDefaultSrc = (ev) => {
    ev.target.src = require('../../assets/images/default-league.png');
  };

  const handleScroll = (e) => {
    const pageBottom =
        document.body.scrollHeight - document.documentElement.clientHeight -
        10 <=
        document.documentElement.scrollTop;
    if (pageBottom) {
      setApiPage((prevState) => {
        return prevState + 1;
      });
      setRestrictApiCall(false);
    }
  };

  const fetchMatchDetails = () => {
    let Params = queryParams.search;
    if (!Params) {
      Params = `?date=${selectedDate ||
      moment(Date.now()).format('YYYY-MM-DD')}`;
    }

    get(FANZINE.SingleMatchEndPoint + params.id +
        FANZINE.MatchesPreviewSubEndPoint).then((response) => {
      // eslint-disable-next-line array-callback-return
      setMatchData(response?.data);
      setMatchDataLoading(false);
      setLoading(!loading);
    }).catch((err) => {
      console.error(err);
    });
  };

  // const selectedDay = (e) => {
  //     let tmpDate = moment(e).format('YYYY-MM-DD');
  //     setSelectedDate(tmpDate);
  //     setSelectedDatePicker(e);
  // };

  const fetchLiveMatches = async () => {
    let params = queryParams.search;
    if (!params) {
      params = `?date=${selectedDate ||
      moment(Date.now()).format('YYYY-MM-DD')}`;
    }
    await get(FANZINE.MatchesLiveEndPoint + params).then((response) => {
      setLiveMatchData(response?.data);
      setMatchDataLoading(false);
    }).catch((err) => {
      console.error(err);
    });
  };

  const fetchLeaguesMatchesByDate = () => {
    let params = searchParams.get('league_id');
    if (params) {
      get(FANZINE.MatchesByDateEndPoint + params + '/matchesByDate?limit=1000').
      then((response) => {
        setLeagueMatchesGroupedByDate(response.data.matches);
        setIsLeagueSelected(true);
      }).
      catch((err) => {
        console.error(err);
      });
    }

  };

  // console.log('cronAPiCAll Data=', liveMatchData || "getting data ...")

  const renderLeftContent = () => {
    return (
        <div
            className="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-xs-12"
            style={{
              marginLeft: 'auto',
            }}
        >
          <div
              className={`bg-white rounded-custom`}
          >
            <SingleMatchLeagueStandings singleMatchData={singleMatchData}
                                        isMobile={isMobile}/>
          </div>
          <div className="bg-white rounded-custom">
                            <span>
                                <h5 className={'ps-2 pt-2'}>
                                    <GiSpeaker size={30}
                                               color={'green'}/> {'\t'}
                                  Social Media
                                </h5>
                            </span>
            {window.innerWidth > SCREEN_SIZE.MOBILE ? (
                <Social
                    isSinglePage={true}
                    isFanzine={true}
                    isNewsPage={true}
                    setLoading={setLoading}
                />
            ) : null}
          </div>

        </div>
    );
  };

  const renderMatchPrediction = () => {
    return (
        <div className={'bg-white rounded-custom mb-2'}
        >
          <div className={'container p-3'}>
            <div className={'row'}>
              <p
                  style={{fontWeight: '600'}}
                  className={'text-center sfProDisplayFont font-16'}>
                Who will win?
              </p>
            </div>
            <div className={'row'}>
              <div className={'col-4 d-flex justify-content-center'}>
                <img src={singleMatchData?.home_team?.icon} alt={'home team'}
                     height={42.8} width={'auto'}/>
              </div>
              <div
                  className={'col-4 d-flex align-items-center justify-content-center azo-sans font-16 '}
                  style={{color: '#878787'}}
              >
                Draw
              </div>
              <div className={'col-4 d-flex justify-content-center'}>
                <img src={singleMatchData?.guest_team?.icon} alt={'guest team'}
                     height={42.8} width={'auto'}/>
              </div>

              <div className={'col-4 d-flex justify-content-center mt-3'}
              >
                <div
                    className={'d-flex justify-content-center align-items-center'}
                    style={{
                      background: '#f1f1f1',
                      width: '79px',
                      height: '42px',
                      borderRadius: '28px',
                      color: '#009245',
                    }}
                >
                  13/8
                </div>
              </div>
              <div
                  className={'col-4 d-flex align-items-center justify-content-center azo-sans font-16 mt-3'}
              >
                <div
                    className={'d-flex justify-content-center align-items-center'}
                    style={{
                      background: '#f1f1f1',
                      width: '79px',
                      height: '42px',
                      borderRadius: '28px',
                      color: '#009245',
                    }}
                >
                  21/10
                </div>
              </div>
              <div className={'col-4 d-flex justify-content-center mt-3'}>
                <div
                    className={'d-flex justify-content-center align-items-center'}
                    style={{
                      background: '#f1f1f1',
                      width: '79px',
                      height: '42px',
                      borderRadius: '28px',
                      color: '#009245',
                    }}
                >
                  8/13
                </div>
              </div>

              <div className={'col-12 d-flex justify-content-center mt-3'}>
                <img src={require('../../assets/images/bet365.png')} alt={'bet365'}
                     height={37} width={74}/>
              </div>

            </div>
          </div>
        </div>
    );
  };

  const renderNews = (news, sport, isMobile) => {

    const hostName = window.location.hostname;
    const domainObject = getHostname(hostName);
    const target = domainObject.host;
    const colorRgba = COLORS_RGBA[target];
    const BackgroundImage = singleMatchData?.venue_image ||
        require('../../assets/images/Default.jpeg');

    const backgroundImageSetting = `backgroundSize: 'cover',backgroundPositionY:'center'`;
    const backgroundWithOverlay = `linear-gradient(to bottom, ${colorRgba}0.8) 0%, ${colorRgba}0.8)), url(${BackgroundImage}) no-repeat scroll center center / cover`;

    const fetchSingleMatch = () => {
      if (singleMatchData === null) {
        get(FANZINE.MatchEndpoint + params.id).then((res) => {
          setSingleMatchData(res.data);
        }).catch((error) => console.error(error));
      }
    };

    singleMatchData === null && fetchSingleMatch();

    return (
        <div className="bg-background">
          <div className={`${isMobile ? '' : 'container'}`}>
            <div
                className={isMobile ? '' : 'row p-3'}
                style={{marginLeft: '0%', marginRight: '0%'}}
            >
              {/* left content */}
              {!isMobile && renderLeftContent()}
              {/* Center Content */}
              <div
                  className={`col-xl-5 col-lg-5 col-md-5 col-sm-12 col-xs-12 ${!isMobile &&
                  'bg-white container'} border-radius-15px`}
                  style={{
                    background: isMobile && '#ededed',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    borderRadius: '15px',
                  }}
              >
                <div
                    className={`mt-xxl-12 mt-xl-12 mt-lg-12 mt-md-12 mt-sm-1 mt-2 mb-5`}

                >
                  <div
                      className={`${isMobile ? 'container' : 'container p-1'}`}>
                    {!isMobile &&
                        (<div><SingleMatchLeagueHeading isMobile={isMobile}
                                                        matchData={matchData?.data}
                                                        addDefaultSrc={addDefaultSrc}/>
                          <SingleFootballMatchItem isMobile={isMobile}
                                                   matchData={matchData?.data}
                                                   addDefaultSrc={addDefaultSrc}/>
                        </div>)
                    }

                    {isMobile &&
                        <div
                            className={` ${isMobile && 'mt-n2'}`}
                            style={{
                              background: backgroundWithOverlay,
                              backgroundSize: 'cover',
                              backgroundPositionY: 'center',
                            }}
                        >
                          <SingleFootballMatchItem isMobile={isMobile}
                                                   matchData={matchData?.data}
                                                   addDefaultSrc={addDefaultSrc}/>
                        </div>
                    }
                    <SingleFootballMatchContent isMobile={isMobile}
                                                matchData={matchData?.data}
                                                singleMatchData={singleMatchData}
                                                addDeafultSrc={addDefaultSrc}/>

                  </div>
                </div>
              </div>
              {/* Right content */}
              {!isMobile && <Container
                  className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12"
                  style={{
                    marginLeft: 'auto',
                  }}
              >
                <RightNewsAds  isMobile={isMobile} limit={30} leagueId={'1204'} fullWidth={true} apiPage={1} />
              </Container>
              }
            </div>
          </div>
        </div>
    );
  };

  const render = () => {

    const hostName = window.location.hostname;
    const domainObject = getHostname(hostName);
    const target =  domainObject.host;

    return (
        <>
          <SiteMetaHelmet sport={'Football'} urlEndpoint={'football'}/>
          <Loader loading={loading}/>

          {
            target === 'fanzine' ?
            <SubNavbar isMobile={isMobile}/>
                :
                <div className={'mt-5 pt-5'} />
          }          <div className="d-flex justify-content-center align-items-center h1">
            {/*<GoogleAd id={"3349434796"}/>*/}
            {/*{isMobile ? <Leaderboard id={'snack_mob_top'}/> : <Billboard id={'snack_dex3'}/>}*/}
          </div>
          {renderNews('', 'Football', isMobile)}
          {/*{isSingleMatchSelected && latestVideos.length && renderSingleMatch(latestVideos, "Football", isMobile)}*/}
        </>
    );
  };

  return render();
};

export default FanzineAllFootballSingleMatch;
