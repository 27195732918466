import Card from "react-bootstrap/Card";
import playButton from "../../assets/images/playButton.png";
import "./videostyle.css";
import moment from 'moment-timezone';
import {useNavigate} from 'react-router-dom';
import SocialShareButton from '../../Components/Layout/Social/SocialShareButton';
import PlayIcon from "../../Components/PlayIcon";
import {COLORS, TEXT_COLOR} from "../../Configs/Targets";
import {getHostname} from "../../CustomFunctions/getHostname";
import LinesEllipsis from "react-lines-ellipsis";

const VideoCards = (props) => {
    const {data, setSelectedVideo, isClub, isMobile, textOnSide} = props;

    const navigate = useNavigate();
    const hostName = window.location.hostname;
    const domainObject = getHostname(hostName);
    const target = domainObject.host;
    const playIconColor = COLORS[target];
    const handleClick = () => {
        if (setSelectedVideo && !isMobile) {
            setSelectedVideo(data);
            // window.scrollTo(0, 0);
        } else {
            navigate(`${isClub ? '' : '/football'}/video/${data?.id}`);
        }
    };
    const addDefaultSrc = (ev) => {
        ev.target.src = require('../../assets/images/football-shots-you-need-to-know.jpg');
    };
    const addDefaultPublisherIcon = (ev) => {
        ev.target.src = require('../../assets/images/default_publisher_icon.png');
    };

    const render = () => {
        let newsTime = moment.tz(data?.published_at.date, 'Europe/London').local().startOf('seconds').fromNow();
        newsTime = newsTime.replace('an', '1');
        newsTime = newsTime.replace('hours', 'h');
        newsTime = newsTime.replace('hour', 'h');
        newsTime = newsTime.replace('minutes', 'm');
        newsTime = newsTime.replace('minute', 'm');
        newsTime = newsTime.replace('days', 'd');
        newsTime = newsTime.replace('day', 'd');
        if (textOnSide) {
            return renderNewsOnSide(newsTime);
        }
        return (
            <div onClick={handleClick} className={`text-decoration-none text-black`}>
                <Card
                    className={`${isMobile ? '' : 'video_card_top_component'} cursor-pointer shadow`}>
                    <Card.Header className={`p-0 rounded-0 border-0`}>
                        <Card.Img variant="top" src={data?.preview_image}
                                  className={`video_card_img rounded-0`}/>
                        <Card.ImgOverlay
                            className={`d-flex video_card_img_overlay ${isMobile ? 'flex-column justify-content-end' : 'justify-content-center align-items-center'}`}>
                            <div
                                className={`d-flex ${isMobile ? 'flex-column justify-content-end' : 'justify-content-center align-items-center'}`}>
                                {
                                    isMobile ?
                                        <PlayIcon fill={playIconColor}/>
                                        :
                                        <img
                                            src={playButton}
                                            alt={"play button"}
                                            className={"video_card_play_btn"}
                                        />
                                }
                            </div>
                        </Card.ImgOverlay>
                    </Card.Header>
                    {
                        textOnSide ? renderNewsOnSide(newsTime) : renderTextBelow(newsTime)
                    }
                </Card>
            </div>
        );
    }

    const renderTextBelow = (newsTime) => {
        if (isClub) {
            return renderTextBelowClub(newsTime);
        }
        return (
            <Card.Body>
                <div className={"d-flex justify-content-between align-items-center"}>
                    <div className={"d-flex align-items-center justify-content-center"} style={{flex: 0.75}}>
                        <img
                            style={{
                                height: 26,
                                width: 26,
                                objectFit: 'cover',
                            }}
                            src={data?.publisher?.icon}
                            onError={addDefaultPublisherIcon}
                            alt={"logo" + data?.publisher?.name}
                        />
                        &nbsp;
                        <span className={'font-10 font-weight-600'}>{data?.publisher?.name?.length > 20 ?
                            data?.publisher?.name.slice(0, 25) + '...'
                            :
                            data?.publisher?.name
                        }</span>
                    </div>
                    <div
                        className={
                            "d-flex align-items-center justify-content-center flex-grow-1"
                        }
                    >
                        {!isClub && <SocialShareButton tmpSocialNews={data}/>}
                    </div>
                    <div className={'sfProDisplayFont font-16'}>
                        {/*{data?.news_title}*/}
                        <LinesEllipsis
                            text={data?.title}
                            maxLine='3'
                            ellipsis='...'
                            trimRight
                            basedOn='letters'
                        />
                    </div>
                    <div className={'w-50 d-flex align-items-end justify-content-start'}>
                        <div
                            className={`sfProDisplayFont font-12 ${TEXT_COLOR[target]}`}>
                            {newsTime}
                        </div>
                    </div>
                </div>
            </Card.Body>
        );
    }

    const renderTextBelowClub = (newsTime) => {
        return (
            <Card.Body>
                <div className={'sfProDisplayFont font-22'}>
                    {/*{data?.news_title}*/}
                    <LinesEllipsis
                        text={data?.title}
                        maxLine='3'
                        ellipsis='...'
                        trimRight
                        basedOn='letters'
                        style={{lineHeight: '25px'}}
                    />
                </div>
                <div className={"d-flex justify-content-between align-items-center"}>
                    <div className={"d-flex align-items-center justify-content-start"} style={{flex: 0.75}}>
                        <img
                            style={{
                                height: 26,
                                width: 26,
                                objectFit: 'cover',
                            }}
                            src={data?.publisher?.icon}
                            onError={addDefaultPublisherIcon}
                            alt={"logo" + data?.publisher?.name}
                        />
                        &nbsp;
                        <span className={`font-12 font-weight-600 sfProDisplayFont ${TEXT_COLOR[target]}`}>
                            {data?.publisher?.name?.length > 20 ?
                                data?.publisher?.name.slice(0, 25) + '...'
                                :
                                data?.publisher?.name
                            }</span>
                    </div>
                    <div className={'w-50 d-flex align-items-end justify-content-end'}>
                        <div
                            className={`sfProDisplayFont font-12 ${TEXT_COLOR[target]}`}>
                            {newsTime}
                        </div>
                    </div>
                </div>
            </Card.Body>
        );
    }

    const renderNewsOnSide = (newsTime) => {
        return (
            <Card onClick={handleClick} className={'border-radius-none border-0 shadow-nav mb-2'}>
                <Card.Body>
                    <Card.Text className={'text-black'}>
                        <div className={'d-flex align-items-center'}>
                            <Card.Img variant="top" src={data?.preview_image}
                                      className={`${isClub ? 'video_card_img_club rounded-0' : 'video_card_img'}`}/>
                            <Card.ImgOverlay
                                className={`d-flex video_card_img_overlay_club ${isMobile ? 'flex-column justify-content-end' : 'justify-content-center align-items-center'}`}>
                                <div
                                    className={`d-flex ${isMobile ? 'flex-column justify-content-end' : 'justify-content-center align-items-center'}`}>
                                    {
                                        isMobile ?
                                            <PlayIcon fill={playIconColor}/>
                                            :
                                            <img
                                                src={playButton}
                                                alt={"play button"}
                                                className={"video_card_play_btn"}
                                            />
                                    }
                                </div>
                            </Card.ImgOverlay>
                            <div className={'w-70 ps-2'}
                                 style={{height: '104px'}}
                            >
                                <div className={'d-flex'}>
                                    <div className={'w-100 d-flex'}>
                                        <img
                                            style={{
                                                width: '25px',
                                                height: 'auto',
                                                objectFit: 'contain',
                                            }}
                                            src={data?.publisher?.icon}
                                            onError={addDefaultPublisherIcon}
                                            alt={'publisher-image'}/>
                                        <div
                                            className={`sfProDisplayFont font-12 d-flex w-fit justify-content-start ps-2 ${TEXT_COLOR[target]}`}>
                                            {data?.publisher?.name}
                                        </div>
                                    </div>

                                </div>

                                <div className={'sfProDisplayFont font-16 fw-light'}>
                                    {/*{data?.news_title}*/}
                                    <LinesEllipsis
                                        text={data?.title}
                                        maxLine='2'
                                        ellipsis='...'
                                        trimRight
                                        basedOn='words'
                                        style={{lineHeight: '18px'}}
                                    />
                                </div>
                                <div className={'w-50 d-flex align-items-end justify-content-start pt-1 pb-1'}>
                                    <div
                                        className={`sfProDisplayFont font-12 ${TEXT_COLOR[target]}`}>
                                        {newsTime}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Card.Text>
                </Card.Body>
            </Card>
        );
    };

    return render();
};
export default VideoCards;
