export const CartSVG = (props) => {

  return (
      <svg id="bag-shopping" xmlns="http://www.w3.org/2000/svg" width={props.width}
           height={props.height} viewBox="0 0 28.488 32.558">
        <defs>
          <style
              dangerouslySetInnerHTML={{__html: `fill: ${props.fill};`}}/>
        </defs>
        <path id="bag-shopping-2" data-name="bag-shopping" className={props.className} fill={props.fill}
              d="M10.174,7.122a4.07,4.07,0,1,1,8.14,0v3.052h-8.14ZM7.122,10.174H3.052A3.053,3.053,0,0,0,0,13.227V26.453a6.106,6.106,0,0,0,6.1,6.1H22.384a6.106,6.106,0,0,0,6.1-6.1V13.227a3.053,3.053,0,0,0-3.052-3.052h-4.07V7.122a7.122,7.122,0,1,0-14.244,0Zm1.526,6.1a1.526,1.526,0,1,1,1.526-1.526A1.522,1.522,0,0,1,8.648,16.279Zm12.718-1.526a1.526,1.526,0,1,1-1.526-1.526A1.522,1.522,0,0,1,21.366,14.753Z"/>
      </svg>

  );
}
