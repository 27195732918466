const TypesOfSports = (props) => {
  const { sport, isMobile } = props;

  const renderMobileView = () => {
    const sportArrayWithBorder = ['FOOTBALL','NFL','CRICKET', 'MLB']
    return (
      <div
          style={{
            borderRight: sportArrayWithBorder.includes(sport) &&  '1px solid white',
          }}
          className={"col-4 mb-2 d-flex justify-content-center"}>
        <div className={"row align-items-center "}>
          <div className={"col-3 "}>
            <span className={"font-14 Montserrat-Bold  text-white"}>{sport}</span>
          </div>
        </div>
      </div>
    );
  };

  const renderDesktopView = () => {

    return (
      <div
          style={{
            borderLeft: sport !== 'FOOTBALL' && 'solid 4px #fff',
          }}
          className={"col-md-2 col-lg-2 col-xl-2 col-xxl-2 d-flex align-items-center justify-content-center"}>
        <span
            style={{fontWeight:600}}
            className={"font-24 font-fanzine text-center Montserrat-SemiBold"}>{sport}</span>
      </div>
    );
  };

  const renderMain = () => {
    return isMobile ? renderMobileView() : renderDesktopView();
  };

  return renderMain();
};

export default TypesOfSports;
