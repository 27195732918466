import {get} from '../../../Axios/get';
import {useEffect, useState} from 'react';
import {useSearchParams} from 'react-router-dom';
import {FOOTBALL} from '../../../Axios/EndPoints';
import {Button} from 'react-bootstrap';
import {AiOutlineLeft, AiOutlineRight} from 'react-icons/ai';
import VideoCard from '../../Cards/VideoCard';

const SpecificLeagueVideos = (props) => {
    const {leagueData, isMobile, setSelectedVideo, isClub} = props;

    const [searchParams, setSearchParams] = useSearchParams()
    const [specificLeagueVideosData, setSpecificLeagueVideosData] = useState([])
    const [specificLeagueVideosLoading, setSpecificLeagueVideosLoading] = useState(true)

    const fetchSpecificLeagueVideos = async () => {

        let league_id = (leagueData?.league_id || searchParams.get('league_id') || '');
        let parameters = leagueData?.league_id ? '?league_id=' + league_id : '';

        await get(FOOTBALL.VideoEndPoint + parameters).then((response) => {
            // eslint-disable-next-line array-callback-return
            setSpecificLeagueVideosData(response.data);
            setSpecificLeagueVideosLoading(false);
        }).catch((err) => {
            console.error(err);
        });
    };


    useEffect(() => {
        setSpecificLeagueVideosLoading(true);
        fetchSpecificLeagueVideos()
    }, [searchParams.get('league_id')])


    const renderLeftButton = (id) => {

        let style =
            {
                width: '63px',
                height: '68px',
                backgroundColor: '#fff',
                color: 'black',
                border: '0px',
                borderRadius: '0px',
            };

        const handleLeftClick = () => {
            document.getElementById('videoSliderContainer' + id).scrollLeft -= 400;

        };

        return (
            <div
                style={{
                    marginLeft: '-3em',
                    marginRight: '-2em',
                    zIndex: '1',
                    marginTop: '-4em',
                }}
                onClick={handleLeftClick}
            >
                <Button className={''} style={style}>
                    <AiOutlineLeft/>
                </Button>
            </div>
        );
    };

    const renderRightButton = (id) => {

        let style =
            {
                width: '63px',
                height: '68px',
                backgroundColor: '#fff',
                color: 'black',
                border: '0px',
                borderRadius: '0px',
            };

        const handleRightClick = () => {
            document.getElementById('videoSliderContainer' + id).scrollLeft += 400;
        };

        return (
            <div
                onClick={handleRightClick}
                style={{
                    marginLeft: '-3em',
                    zIndex: '1',
                    marginTop: '-4em',

                }}
            >
                <Button
                    style={style}
                > <AiOutlineRight/>
                </Button>
            </div>
        );
    };

    const renderVideos = (videoData, title) => {
        let style = {
            background: (leagueData?.bg_color || 'linear-gradient(to bottom, #74dbef, #0092af)'),
            color: (leagueData?.font_color || '#000000'),
        };

        return (
            <div className={'w-100 mt-5 mb-5'}
                 style={style}
            >
                <div className={'container'}>
                    <div className={`${isMobile ? 'w-100' : 'container'}`}>
                        <div className={`row ${isMobile ? '' : 'ps-5 pe-5'}`}>
                            <p className={'text-start font-30 pt-4 pb-3 m-0 bebasFont'}
                            >
                                {title} Videos
                            </p>
                            <div
                                className={'d-flex justify-content-center align-items-center'}>
                                {!isMobile && renderLeftButton(videoData[0]?.id)}
                                <div
                                    id={'videoSliderContainer' + videoData[0]?.id}
                                    className={`m-1 slider-mobile-container ${videoData?.length <=
                                    3 ?
                                        'justify-content-center' :
                                        'overflow-scroll-x'}`}>
                                    {videoData?.map((match) => (
                                        <VideoCard
                                            isClub={isClub}
                                            setSelectedVideo={setSelectedVideo}
                                            video={match} isFanzineAllFootball={true}
                                            videoHeight={isMobile ? '160px' : '180px'} isMain={false}
                                            extraClass={'m-2'}
                                            league={leagueData}
                                            sport={'football'}
                                            isMobile={isMobile}/>
                                    ),)}
                                </div>
                                {!isMobile && renderRightButton(videoData[0]?.id)}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const renderMain = (data, leagueName) => {

        return data?.length > 0 ? renderVideos(data, leagueName) : '';

    };

    return renderMain(specificLeagueVideosData, (leagueData?.name || ''));

}

export default SpecificLeagueVideos;
