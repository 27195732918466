import NewNavbar from '../../../../Components/NewNavbar/NewNavbar';
import React, {useEffect, useRef, useState} from 'react';
import {COLORS, COLORS_RGBA, FONT_COLOR, NAV_CLASS, TEXT_COLORS,} from '../../../../Configs/Targets';
import {getHostname} from '../../../../CustomFunctions/getHostname';
import NewProductComponent from '../../../../Components/NewProductComponent/NewProductComponent';
import {Breadcrumb, Overlay, Tooltip} from 'react-bootstrap';
import {graphql} from '../../../../Axios/graphql';
import {ShopifyGraphQlQueries} from '../../../../Configs/ShopifyGraphQlQueries';
import {COMPARE_CATEGORY, SCREEN_SIZE, SHOPIFY,} from '../../../../Configs/Constants';
import {setAllProducts} from '../../../../redux/actions/StoreActions';
import {useDispatch, useSelector} from 'react-redux';
import {NavLink, useLocation, useNavigate, useParams, useSearchParams,} from 'react-router-dom';
import {withRouter} from '../../../../CustomFunctions/withRouter';
import ReusableCheckbox from '../../../../Components/Checkbox/ReuseableCheckbox';

const NewProductListPage = (props) => {

    const cart = useSelector((state) => state);
    const location = useLocation();
    const shopStore = useSelector(state => state.shopify);

    const [showSort, setShowSort] = useState(false);
    const [showFilter, setShowFilter] = useState(false);
    const newTarget = useRef(null);
    const sortTarget = useRef(null);

    const store = useSelector(state => state);
    const dispatch = useDispatch();
    const {filter, filter1} = useParams();

    const hostName = window.location.hostname;
    const domainObject = getHostname(hostName);
    const target = domainObject.host;
    const colorRgba = COLORS_RGBA[target];
    const fontColor = FONT_COLOR[target];
    const bgColor = COLORS[target];
    const textColor = TEXT_COLORS[target];
    const navColor = NAV_CLASS[target];

    let link = target === 'fanzine' ?
        `/${location.pathname.split('/')[1]}/shop`  : '/shop';

    const [horizontalScroll, setHorizontalScroll] = useState(false);
    const [categories, setCategories] = useState([]);
    const [data, setData] = useState([]);
    const [filterType, setFilterType] = useState(null);
    const [paramFilter, setParamFilter] = useState('');
    const [paramFilter1, setParamFilter1] = useState('');
    const [breadcrumbs, setBreadcrumbs] = useState([]);
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const [isMobile, setIsMobile] = useState(false);
    const [showMobileFilters, setShowMobileFilters] = useState(false);
    const [loading, setLoading] = useState(true);
    const [colorsForFilters, setColorsForFilters] = useState([]);
    const [sizesForFilters, setSizesForFilters] = useState([]);

    const [selectedCategories, setSelectedCategories] = useState([]);
    const [selectedColors, setSelectedColors] = useState([]);
    const [selectedSizes, setSelectedSizes] = useState([]);
    const [selectedGenders, setSelectedGenders] = useState([]);
    const [selectedFiltersChanged, setSelectedFiltersChanged] = useState(false);
    const [hasFiltersChanged, setHasFiltersChanged] = useState(false);
    const [minPrice, setMinPrice] = useState(0);
    const [maxPrice, setMaxPrice] = useState(500);
    const [checkSale, setCheckSale] = useState(false);
    const [clearFilter, setClearFilter] = useState(false);
    const [showWebPriceRange, setShowWebPriceRange] = useState(false);

    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();

    const [selectedGenderRadio, setSelectedGenderRadio] = useState(
        location.pathname.split('/')[2] || 'Mens');
    const [selectedCategoryRadio, setSelectedCategoryRadio] = useState('');
    const [selectedSizeRadio, setSelectedSizeRadio] = useState('');
    const [selectedColorRadio, setSelectedColorRadio] = useState('');

    const genderArray = ['Mens', 'Womens', 'Childrens'];
    const TopHeader = genderArray?.includes(location.pathname.split('/')[2]) ?
        location.pathname.split('/')[2] === 'Childrens' ?
            'Kids' :
            location.pathname.split('/')[2] :
        '';

    const fetchProductsByTags = (tag, sortKey = ', sortKey: RELEVANCE') => {
        graphql(ShopifyGraphQlQueries(SHOPIFY.PRODUCT_LIST, null, null,
            {sort_key: sortKey}))
            .then((response) => {
                let products = response.data.data['collectionByHandle']['products'].edges;

                if (products.length) {
                    let tempCategories = [];
                    let tempData = [];
                    let tempColorsForFilters = [];
                    let tempSizesForFilters = [];
                    let apiCategories = store.shopify.categories.map((value => {
                        return value.shopify_name;
                    }));
                    products.map((value) => {
                        let product = value.node;
                        if (!tempCategories.includes(product.productType) &&
                            apiCategories.includes(product.productType) &&
                            product.tags.includes(tag)) {
                            tempCategories.push(product.productType);
                        }
                        return tempCategories;
                    });
                    tempCategories.map(cat => {
                        let tempDataByCat = [];
                        products.map((value) => {
                            let productColorImages = [];
                            let product = value.node;
                            if (product.productType === cat && product.tags.includes(tag)) {
                                let productSizes = [];
                                let productColors = [];
                                product.options.map((option) => {
                                    if (option.name === 'Size') {
                                        productSizes = option.values;
                                    }
                                    if (option.name === 'Color') {
                                        productColors = option.values;
                                    }
                                });
                                productColors.map((color) => {
                                        productColorImages[color] = product.variants.edges.filter(
                                            (variant) => {
                                                let tempColor = variant.node.selectedOptions.filter(option => option.name.toLowerCase() === 'color')[0].value;
                                                return color.toLowerCase() === tempColor.toLowerCase();
                                            }
                                        )[0]?.node?.image?.src || '';
                                    },
                                );

                                product.variants.edges.map((value1, index) => {
                                    // tempColorsForFilters.push(value1.node.selectedOptions);
                                    // debugger
                                    let tempColorArr = value1.node.selectedOptions &&
                                    value1.node.selectedOptions.length ?
                                        (value1.node.selectedOptions.filter(
                                            option => option.name === 'Color')) :
                                        null;

                                    let tempColor = '';
                                    if (tempColorArr !== null && tempColorArr.length) {
                                        if (tempColorArr.constructor === Object) {
                                            tempColor = tempColorArr.value;
                                        }
                                        if (tempColorArr.constructor === Array) {
                                            tempColor = tempColorArr[0].value;
                                        }
                                    }
                                    if (tempColor.includes('/')) {
                                        tempColor = (tempColor.split('/'))[0];
                                    }
                                    if (tempColor !== '' &&
                                        !tempColorsForFilters.includes(tempColor)) {
                                        tempColorsForFilters.push(tempColor);
                                    }
                                    let tempSizeArr = value1.node.selectedOptions &&
                                    value1.node.selectedOptions.length ?
                                        (value1.node.selectedOptions.filter(
                                            option => option.name === 'Size')) :
                                        null;
                                    let tempSize = '';
                                    if (tempSizeArr !== null && tempSizeArr.length) {
                                        if (tempSizeArr.constructor === Object) {
                                            tempSize = tempSizeArr.value;
                                        }
                                        if (tempSizeArr.constructor === Array) {
                                            tempSize = tempSizeArr[0].value;
                                        }
                                    }
                                    if (tempSize !== '' &&
                                        !tempSizesForFilters.includes(tempSize)) {
                                        tempSizesForFilters.push(tempSize);
                                    }
                                });
                                tempDataByCat.push({
                                    id: product?.id,
                                    name: product?.title,
                                    handle: product?.handle,
                                    src: product?.images && product?.images?.edges[0]?.node?.src,
                                    alt: product?.images && product?.images?.edges[0]?.node?.alt,
                                    price: product['variants']?.edges[0]?.node?.price?.amount,
                                    oldPrice: product['variants']?.edges[0]?.node?.compareAtPrice?.amount,
                                    onSale: (product['variants']?.edges[0]?.node?.compareAtPrice?.amount !==
                                        null && product['variants']?.edges[0]?.node?.compareAtPrice?.amount !==
                                        undefined),
                                    sizes: productSizes,
                                    tags: product?.tags,
                                    colors: productColors,
                                    variants: product?.variants,
                                    colorImages: productColorImages,
                                });
                            }
                            return tempDataByCat;
                        });
                        return tempData.push({
                            name: cat, products: tempDataByCat,
                        });
                    });
                    setCategories(tempCategories);
                    // tempColorsForFilters = Array.from(new Set(tempColorsForFilters));
                    setColorsForFilters(tempColorsForFilters);
                    setSizesForFilters(tempSizesForFilters);
                    console.log('filtered 1 tmp=', tempData)
                    let filteredProducts = showFilteredProducts(tempData);
                    console.log('filtered 1=', filteredProducts)
                    setData(filteredProducts);
                    dispatch(setAllProducts(tempData));
                }
            });
    };
    // console.log('ColorForFilters = ',selectedColors);
    const fetchProductsByTypes = (type, sortKey = '') => {
        graphql(ShopifyGraphQlQueries(SHOPIFY.PRODUCT_LIST, null, null,
            {sort_key: sortKey}))
            .then((response) => {
                let products = response.data.data['collectionByHandle']['products'].edges;
                if (products.length) {
                    let tempCategories = [];
                    let tempData = [];
                    let tempDataByTag = [];
                    let tempColorsForFilters = [];
                    let tempSizesForFilters = [];
                    let apiCategories = store.shopify.categories.map((value => {
                        return value.shopify_name;
                    }));
                    products.map((value) => {
                        let productColorImages = [];
                        let product = value.node;
                        if (!tempCategories.includes(product.productType) &&
                            apiCategories.includes(product.productType)) {
                            tempCategories.push(product.productType);
                        }

                        let productSizes = [];
                        let productColors = [];
                        product.options.map((option) => {
                            if (option.name === 'Size') {
                                productSizes = option.values;
                            }
                            if (option.name === 'Color') {
                                productColors = option.values;
                            }
                        });
                        productColors.map((color) => {
                                productColorImages[color] = product.variants.edges.filter(
                                    (variant) => {
                                        let tempColor = variant.node.selectedOptions.filter(option => option.name.toLowerCase() === 'color')[0].value;
                                        return color.toLowerCase() === tempColor.toLowerCase();
                                    }
                                )[0]?.node?.image?.src || '';
                            },
                        );
                        product.variants.edges.map((value1, index) => {
                            // tempColorsForFilters.push(value1.node.selectedOptions);
                            // debugger
                            let tempColorArr = value1.node.selectedOptions &&
                            value1.node.selectedOptions.length ?
                                (value1.node.selectedOptions.filter(
                                    option => option.name === 'Color')) :
                                null;
                            let tempColor = '';
                            if (tempColorArr !== null && tempColorArr.length) {
                                if (tempColorArr.constructor === Object) {
                                    tempColor = tempColorArr.value;
                                }
                                if (tempColorArr.constructor === Array) {
                                    tempColor = tempColorArr[0].value;
                                }
                            }
                            if (tempColor !== '' &&
                                !tempColorsForFilters.includes(tempColor)) {
                                tempColorsForFilters.push(tempColor);
                            }
                            let tempSizeArr = value1.node.selectedOptions &&
                            value1.node.selectedOptions.length ?
                                (value1.node.selectedOptions.filter(
                                    option => option.name === 'Size')) :
                                null;
                            let tempSize = '';
                            if (tempSizeArr !== null && tempSizeArr.length) {
                                if (tempSizeArr.constructor === Object) {
                                    tempSize = tempSizeArr.value;
                                }
                                if (tempSizeArr.constructor === Array) {
                                    tempSize = tempSizeArr[0].value;
                                }
                            }
                            if (tempSize !== '' &&
                                !tempSizesForFilters.includes(tempSize)) {
                                tempSizesForFilters.push(tempSize);
                            }
                        });

                        if (product.productType === decodeURI(type)) {

                            tempDataByTag.push({
                                id: product?.id,
                                name: product?.title,
                                handle: product?.handle,
                                src: product?.images && product?.images?.edges[0]?.node?.src,
                                alt: product?.images && product?.images?.edges[0]?.node?.alt,
                                price: product['variants']?.edges[0]?.node?.price?.amount,
                                oldPrice: product['variants']?.edges[0]?.node?.compareAtPrice?.amount,
                                onSale: (product['variants']?.edges[0]?.node?.compareAtPrice?.amount !==
                                    null && product['variants']?.edges[0]?.node?.compareAtPrice?.amount !==
                                    undefined),
                                sizes: productSizes,
                                tags: product?.tags,
                                colors: productColors,
                                variants: product?.variants,
                                colorImages: productColorImages,
                            });
                        }

                        return tempCategories;
                    });
                    tempData.push({
                        name: type, products: tempDataByTag,
                    });
                    setCategories(tempCategories);
                    setColorsForFilters(tempColorsForFilters);
                    setSizesForFilters(tempSizesForFilters);
                    let filteredProducts = showFilteredProducts(tempData);
                    console.log('filtered 2 =', filteredProducts)
                    setData(filteredProducts);
                    dispatch(setAllProducts(tempData));
                }
            });
    };

    const fetchProductsByTypeAndTag = (type, tag, sortKey = '') => {
        graphql(ShopifyGraphQlQueries(SHOPIFY.PRODUCT_LIST, null, null,
            {sort_key: sortKey}))
            .then((response) => {
                let products = response.data.data['collectionByHandle']['products'].edges;

                if (products.length) {
                    let tempCategories = [tag + ' ' + type];
                    let tempData = [];
                    let tempColorsForFilters = [];
                    let tempSizesForFilters = [];
                    let apiCategories = store.shopify.categories.map((value => {
                        return value.shopify_name;
                    }));
                    let tempDataByTag = [];
                    products.map((value) => {
                        let productColorImages = [];
                        let product = value.node;
                        if (product.tags.includes(tag) && product.productType ===
                            decodeURI(type)) {
                            let productSizes = [];
                            let productColors = [];
                            let productColorImages = [];
                            product.options.map((option) => {
                                if (option.name === 'Size') {
                                    productSizes = option.values;
                                }
                                if (option.name === 'Color') {
                                    productColors = option.values;
                                }
                            });
                            productColors.map((color) => {
                                    productColorImages[color] = product.variants.edges.filter(
                                        (variant) => {
                                            let tempColor = variant.node.selectedOptions.filter(option => option.name.toLowerCase() === 'color')[0].value;
                                            return color.toLowerCase() === tempColor.toLowerCase();
                                        }
                                    )[0]?.node?.image?.src || '';
                                },
                            );

                            product.variants.edges.map((value1, index) => {
                                // tempColorsForFilters.push(value1.node.selectedOptions);
                                // debugger
                                let tempColorArr = value1.node.selectedOptions &&
                                value1.node.selectedOptions.length ?
                                    (value1.node.selectedOptions.filter(
                                        option => option.name === 'Color')) :
                                    null;
                                let tempColor = '';
                                if (tempColorArr !== null && tempColorArr.length) {
                                    if (tempColorArr.constructor === Object) {
                                        tempColor = tempColorArr.value;
                                    }
                                    if (tempColorArr.constructor === Array) {
                                        tempColor = tempColorArr[0].value;
                                    }
                                }
                                if (tempColor !== '' &&
                                    !tempColorsForFilters.includes(tempColor)) {
                                    tempColorsForFilters.push(tempColor);
                                }
                                let tempSizeArr = value1.node.selectedOptions &&
                                value1.node.selectedOptions.length ?
                                    (value1.node.selectedOptions.filter(
                                        option => option.name === 'Size')) :
                                    null;
                                let tempSize = '';
                                if (tempSizeArr !== null && tempSizeArr.length) {
                                    if (tempSizeArr.constructor === Object) {
                                        tempSize = tempSizeArr.value;
                                    }
                                    if (tempSizeArr.constructor === Array) {
                                        tempSize = tempSizeArr[0].value;
                                    }
                                }
                                if (tempSize !== '' &&
                                    !tempSizesForFilters.includes(tempSize)) {
                                    tempSizesForFilters.push(tempSize);
                                }
                            });
                            tempDataByTag.push({
                                id: product?.id,
                                name: product?.title,
                                handle: product?.handle,
                                src: product?.images && product?.images?.edges[0]?.node?.src,
                                alt: product?.images && product?.images?.edges[0]?.node?.alt,
                                price: product['variants']?.edges[0]?.node?.price?.amount,
                                oldPrice: product['variants']?.edges[0]?.node?.compareAtPrice?.amount,
                                onSale: (product['variants']?.edges[0]?.node?.compareAtPrice?.amount !==
                                null && product['variants']?.edges[0]?.node?.compareAtPrice?.amount !==
                                undefined),
                                sizes: productSizes,
                                tags: product?.tags,
                                colors: productColors,
                                variants: product?.variants,
                                colorImages: productColorImages,
                            });
                        }
                        return tempDataByTag;
                    });
                    setCategories(tempCategories);
                    setColorsForFilters(tempColorsForFilters);
                    setSizesForFilters(tempSizesForFilters);
                    tempData.push({
                        name: tag + ' ' + type, products: tempDataByTag,
                    });
                    let filteredProducts = showFilteredProducts(tempData);
                    console.log('filtered 3 =', filteredProducts)
                    setData(filteredProducts);
                    dispatch(setAllProducts(tempData));
                }
            });
    };

    const showFilteredProducts = (dataToFilter) => {
        let tempDataArray = dataToFilter;
        let tempFilteredData = [];
        if (selectedCategories.length) {
            tempFilteredData = tempDataArray.filter(
                (value) => selectedCategories.some((item) => value.name === item) ===
                    true);
        }

        if (selectedColors.length) {
            if (tempFilteredData.length) {
                Object.values(tempFilteredData).map((value) => {
                    value.products = value.products.filter(
                        item => Object.values(item.colors)
                            .filter(color => selectedColors.includes(color)).length > 0);
                });
            } else {
                Object.values(tempDataArray).map((value) => {
                    value.products = value.products.filter(
                        item => Object.values(item.colors)
                            .filter(color => selectedColors.includes(color)).length > 0);
                });
                tempFilteredData = tempDataArray;
            }
        }

        if (selectedSizes.length) {
            if (tempFilteredData.length) {
                Object.values(tempFilteredData).map((value) => {
                    value.products = value.products.filter(
                        item => Object.values(item.sizes)
                            .filter(size => selectedSizes.includes(size)).length > 0);
                });
            } else {
                Object.values(tempDataArray).map((value) => {
                    value.products = value.products.filter(
                        item => Object.values(item.sizes)
                            .filter(size => selectedSizes.includes(size)).length > 0);
                });
                tempFilteredData = tempDataArray;
            }
        }

        if (selectedGenders.length) {
            if (tempFilteredData.length) {
                Object.values(tempFilteredData).map((value) => {
                    value.products = value.products.filter(item =>
                        Object.values(item.tags).filter(tag =>
                            selectedGenders.includes(tag),
                        ).length > 0,
                    );
                });
            } else {
                Object.values(tempDataArray).map((value) => {
                    value.products = value.products.filter(item =>
                        Object.values(item.tags).filter(tag =>
                            selectedGenders.includes(tag),
                        ).length > 0,
                    );
                });
                tempFilteredData = tempDataArray;
            }
        }

        if (checkSale) {
            if (tempFilteredData.length) {
                Object.values(tempFilteredData).map((value) => {
                    value.products = value.products.filter(item => item.onSale === true);
                });
            } else {
                Object.values(tempDataArray).map((value) => {
                    value.products = value.products.filter(item => item.onSale === true);
                });
                tempFilteredData = tempDataArray;
            }
        }
        if (tempFilteredData.length) {
            Object.values(tempFilteredData).map((value) => {
                value.products = value.products.filter(
                    item => parseFloat(item.price) > minPrice &&
                        parseFloat(item.price) < maxPrice);
            });
        } else {
            Object.values(tempDataArray).map((value) => {
                value.products = value.products.filter(
                    item => parseFloat(item.price) > minPrice &&
                        parseFloat(item.price) < maxPrice);
            });
            tempFilteredData = tempDataArray;
        }

        // debugger
        tempFilteredData = tempFilteredData.length ?
            tempFilteredData :
            tempDataArray;
        /*setData(tempFilteredData);*/
        setShowMobileFilters(false);
        setHasFiltersChanged(false);
        setLoading(false);
        // debugger
        return tempFilteredData.filter(value => value.products.length);
    };

    const initialize = (routeParams, sortKey = ', sortKey: RELEVANCE') => {
        setCategories([]);
        setData([]);
        let tempBreadcrumbs = Object.values(routeParams);
        setBreadcrumbs(tempBreadcrumbs);
        setCategories([routeParams.filter1]);
        setParamFilter(routeParams.filter);
        setParamFilter1(routeParams.filter1);
        if (target === 'fanzine' ? tempBreadcrumbs?.length === 2 : tempBreadcrumbs.length === 1) {
            if (store.shopify.productTags.includes(routeParams.filter)) {
                fetchProductsByTags(routeParams.filter, sortKey);
                setFilterType(SHOPIFY.PRODUCT_TAGS);
            }
            setHorizontalScroll(true);
            if (store.shopify.productTypes.includes(routeParams.filter)) {
                fetchProductsByTypes(routeParams.filter, sortKey);
                setFilterType(SHOPIFY.PRODUCT_TYPES);
                setParamFilter1(routeParams.filter);
                setHorizontalScroll(false);
            }
        }
        if (target === 'fanzine' ? tempBreadcrumbs?.length === 3 : tempBreadcrumbs.length === 2 ) {

            let type = routeParams.filter;
            let tag = routeParams.filter1;
            fetchProductsByTypeAndTag(tag, type, sortKey);
            setParamFilter1(type);
            setHorizontalScroll(false);
        }
    };

    const handleProductSort = (event) => {
        let sortKey = '';
        setLoading(true);
        switch (event.currentTarget.value) {
            case'BEST_SELLING':
                sortKey = ', sortKey: BEST_SELLING';
                break;
            case'NEWEST':
                sortKey = ', sortKey: CREATED, reverse: true';
                break;
            case'PRICE_ASC':
                sortKey = ', sortKey: PRICE, reverse: false';
                break;
            case'PRICE_DESC':
                sortKey = ', sortKey: PRICE, reverse: true';
                break;
            default:
                sortKey = '';
                break;
        }
        initialize(props.match.params, sortKey);
    };

    const clearFilters = () => {
        setLoading(true);
        initialize(props.match.params);
        setShowMobileFilters(false);
        setClearFilter(!setParamFilter);
    };

    const handlePriceRangeChange = (e) => {
        setMinPrice(e.minValue);
        setMaxPrice(e.maxValue);
    };

    const setColorForProducts = (value, index) => {
        let tempSrc = '';
        if (value.colorImages[selectedColors[index % selectedColors.length]]) {
            tempSrc = value.colorImages[selectedColors[index %
            selectedColors.length]];
        }
        return tempSrc;
    };

    useEffect(() => {
        if (selectedCategoryRadio !== '' && selectedGenderRadio !== '') {
            let maxColors = [];
            let maxSizes = [];
            for (let i = 0; i < data?.length; i++) {
                for (let j = 0; j < data[i]?.products?.length; j++) {
                    if (maxSizes === []) {
                        maxSizes = data[i]?.products[j]?.sizes;
                    } else if (maxSizes?.length < data[i]?.products[j]?.sizes?.length) {
                        maxSizes = data[i]?.products[j]?.sizes;
                    }
                    if (maxColors === []) {
                        maxColors = data[i]?.products[j]?.colors;
                    } else if (maxColors?.length < data[i]?.products[j]?.colors?.length) {
                        maxColors = data[i]?.products[j]?.colors;
                    }
                }
            }
            setSizesForFilters(maxSizes);
            setColorsForFilters(maxColors);
        }
    }, [data]);

    useEffect(() => {
        if (selectedSizeRadio !== '') {
            let tempSize = [
                {
                    name: data[0]?.name,
                    products: [],
                }];
            for (let i = 0; i < data?.length; i++) {
                for (let j = 0; j < data[i]?.products?.length; j++) {
                    if (data[i]?.products[j]?.sizes?.includes(selectedSizeRadio)) {
                        tempSize[0]?.products.push(data[i]?.products[j]);
                    }
                }
            }
            setData(tempSize);
            setHasFiltersChanged(!hasFiltersChanged);
        }
    }, [selectedSizeRadio]);

    useEffect(() => {
        if (selectedColorRadio !== '') {
            let tempColors = [
                {
                    name: data[0]?.name,
                    products: [],
                }];

            for (let i = 0; i < data?.length; i++) {
                for (let j = 0; j < data[i]?.products?.length; j++) {
                    if (data[i]?.products[j]?.colors?.includes(selectedColorRadio)) {
                        tempColors[0]?.products.push(data[i]?.products[j]);
                    }
                }
            }
            setData(tempColors);
            setHasFiltersChanged(!hasFiltersChanged);
        }

    }, [selectedColorRadio]);

    useEffect(() => {
        let url = ``;
        if (selectedCategoryRadio !== '') {
            if(target === 'fanzine')
            {
                url =   `/${location.pathname?.split('/')[1]}/shop/` + selectedGenderRadio + '/' + selectedCategoryRadio;
            }
            setSelectedSizeRadio('');
            setSelectedColorRadio('');
            navigate(target === 'fanzine' ? url : '/shop/' + selectedGenderRadio + '/' + selectedCategoryRadio);
            // setSearchParams(`?${new URLSearchParams({gender: selectedGenderRadio})}`);
        }
    }, [selectedCategoryRadio]);

    useEffect(() => {
        let url = ``;
        if (selectedGenderRadio !== '') {
            if(target === 'fanzine')
            {
                url = `/${location.pathname?.split('/')[1]}/shop/`+ selectedGenderRadio;
            }
            setSelectedCategoryRadio('');
            setSelectedSizeRadio('');
            setSelectedColorRadio('');
            navigate(target === 'fanzine' ? url : '/shop/' + selectedGenderRadio);
            // setSearchParams(`?${new URLSearchParams({gender: selectedGenderRadio})}`);
        }
    }, [selectedGenderRadio]);

    useEffect(() => {
        initialize(props.match.params);
    }, [store.shopify.productTypes, store.shopify.productTags]);

    useEffect(() => {
        // setHasFiltersChanged(true);
        initialize(props.match.params);
    }, [, filter, filter1]);

    useEffect(() => {
        if (hasFiltersChanged) {
            setLoading(true);
            initialize(props.match.params);
        }
    }, [hasFiltersChanged]);

    useEffect(() => {
        setScreenWidth(window.innerWidth);
        if (screenWidth <= SCREEN_SIZE.MOBILE) {
            setIsMobile(true);
        }
    }, [screenWidth]);

    const renderSortByDropdown = () => {

        return (
            <div className={'d-flex flex-column p-3 position-absolute'} style={{
                boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
                width: '279px',
                backgroundColor: 'white',
                top: '0px',
                right: '0px',
                left: '-50px',
            }}>
                <div className={'d-flex align-items-center mb-2'}>
                    <div className={`${navColor}-checked ${fontColor}  round`}>
                        <input type="radio" name={'sort_radios'} value={'BEST_SELLING'}
                               id="checkboxOne" onClick={handleProductSort}/>
                        <label htmlFor="checkboxOne">
                        </label>
                        <span className={'sfProDisplayFont font-16 ms-4 text-dark'}
                              color={'#171717'}>Best Selling</span>
                    </div>

                </div>
                <div className={'d-flex align-items-center mb-2'}>
                    <div className={`${navColor}-checked ${fontColor}  round`}>
                        <input type="radio" name={'sort_radios'} value={'PRICE_ASC'}
                               id="checkboxTwo" onClick={handleProductSort}/>
                        <label htmlFor="checkboxTwo">
                        </label>
                        <span className={'sfProDisplayFont font-16 ms-4 text-dark'}
                              color={'#171717'}>Price, Low to High</span>
                    </div>
                </div>

                <div className={'d-flex align-items-center mb-2'}>
                    <div className={`${navColor}-checked ${fontColor}  round`}>
                        <input type="radio" name={'sort_radios'} value={'PRICE_DESC'}
                               id="checkboxThree" onClick={handleProductSort}/>
                        <label htmlFor="checkboxThree">
                        </label>
                        <span className={'sfProDisplayFont font-16 ms-4 text-dark'}
                              color={'#171717'}>Price, High to Low</span>
                    </div>
                </div>

                <div className={'d-flex align-items-center mb-2'}>
                    <div className={`${navColor}-checked ${fontColor}  round`}>
                        <input type="radio" name={'sort_radios'} value={'NEWEST'}
                               id="checkboxFour" onClick={handleProductSort}/>
                        <label htmlFor="checkboxFour">
                        </label>
                        <span className={'sfProDisplayFont font-16 ms-4 text-dark'}
                              color={'#171717'}>Newest</span>
                    </div>
                </div>
            </div>
        );
    };

    const filterDropDown = () => {

        let avaiableMensCategory = [];
        let avaiableWomensCategory = [];
        let avaiableChildrensCategory = [];
        let avaiableUnisexCategory = [];

        shopStore?.productTags?.map((value, index) => {

            if (value.includes('womens') || value.includes('women')) {
                for (let i = 0; i < COMPARE_CATEGORY?.length; i++) {
                    if (value?.includes(COMPARE_CATEGORY[i].compareName)) {
                        let tempWomen = COMPARE_CATEGORY[i].displayName;
                        if (!avaiableWomensCategory?.includes(tempWomen)) {
                            avaiableWomensCategory.push(tempWomen);
                        }
                    }
                }
            }
            if (value.includes('unisex') || value.includes('Unisex')) {
                for (let i = 0; i < COMPARE_CATEGORY?.length; i++) {
                    if (value?.includes(COMPARE_CATEGORY[i].compareName)) {
                        let tempUnisex = COMPARE_CATEGORY[i].displayName;
                        if (!avaiableUnisexCategory?.includes(tempUnisex)) {
                            avaiableUnisexCategory.push(tempUnisex);
                        }
                    }
                }
            }

            if (value.includes('mens') || value.includes('men')) {
                for (let i = 0; i < COMPARE_CATEGORY?.length; i++) {
                    if (value?.includes(COMPARE_CATEGORY[i].compareName)) {
                        let tempMen = COMPARE_CATEGORY[i].displayName;
                        if (!avaiableMensCategory?.includes(tempMen)) {
                            if (tempMen === 'tshirt') {
                                continue;
                            } else {
                                avaiableMensCategory.push(tempMen);
                            }
                        }
                    }
                }
            }
            if (value.includes('children') || value.includes('juniors')) {
                for (let i = 0; i < COMPARE_CATEGORY?.length; i++) {
                    if (value?.includes(COMPARE_CATEGORY[i].compareName)) {
                        let tempChildren = COMPARE_CATEGORY[i].displayName;
                        if (!avaiableChildrensCategory?.includes(tempChildren)) {
                            avaiableChildrensCategory.push(tempChildren);
                        }
                    }
                }
            }

        });

        return (
            <div className={'d-flex align-items-start p-3 position-absolute'}
                 style={{
                     width: '1284px',
                     backgroundColor: 'white',
                     zIndex: 100,
                     top: '0px',
                 }}>
                <div className={'d-flex flex-column'} style={{flex: 0.15}}>
                    <span
                        className={'sfProDisplayFont font-20 mb-4 align-self-start'}
                        style={{
                            letterSpacing: '0.36px',
                            color: textColor,
                        }}>Gender</span>
                    <div>
                        {['Mens', 'Womens', 'Childrens'].map((value, index) => {
                            return <ReusableCheckbox
                                className={`font-16  ${fontColor} ${navColor}-filters-checked `}
                                selectedRadio={selectedGenderRadio}
                                setSelectedRadio={setSelectedGenderRadio}
                                checkboxName={value} id={index}/>;
                        })}
                    </div>
                </div>
                <div className={'d-flex flex-column'} style={{flex: 0.15}}>
                    <span
                        className={'sfProDisplayFont font-20 mb-4 align-self-start'}
                        style={{
                            letterSpacing: '0.36px', color: textColor,
                        }}>Product Type</span>
                    <div>
                        {(selectedGenderRadio === 'Mens' ? avaiableMensCategory :
                                selectedGenderRadio === 'Womens' ?
                                    avaiableWomensCategory :
                                    selectedGenderRadio === 'Childrens' ?
                                        avaiableChildrensCategory :
                                        selectedGenderRadio === 'UniSex' ?
                                            avaiableUnisexCategory :
                                            categories
                        )?.map((value, index) => {
                            return <ReusableCheckbox
                                selectedRadio={selectedCategoryRadio}
                                setSelectedRadio={setSelectedCategoryRadio}
                                className={`font-16  ${fontColor} ${navColor}-filters-checked`}
                                checkboxName={value} id={index}/>;
                        })}
                    </div>
                </div>
                <div className={'d-flex flex-column'} style={{flex: 0.15}}>
                    <span
                        className={'sfProDisplayFont font-20 mb-4 align-self-start'}
                        style={{
                            letterSpacing: '0.36px',
                            color: textColor,
                        }}>Size</span>
                    <div>
                        {sizesForFilters.map((value, index) => {
                            return <ReusableCheckbox
                                className={`font-16  ${fontColor} ${navColor}-filters-checked`}
                                selectedRadio={selectedSizeRadio}
                                setSelectedRadio={setSelectedSizeRadio}
                                setSelectedGenderRadio={setSelectedGenderRadio}
                                checkboxName={value} id={index}/>;
                        })}
                    </div>
                </div>
                <div className={'d-flex flex-column'} style={{flex: 0.15}}>
                    <span
                        className={'sfProDisplayFont font-20 mb-4 align-self-start'}
                        style={{
                            letterSpacing: '0.36px',
                            color: textColor,
                        }}>Colour</span>
                    <div>
                        {colorsForFilters.map((value, index) => {
                            return <ReusableCheckbox
                                className={`font-16  ${fontColor} ${navColor}-filters-checked`}
                                selectedRadio={selectedColorRadio}
                                setSelectedRadio={setSelectedColorRadio}
                                setSelectedGenderRadio={setSelectedGenderRadio}
                                checkboxName={value} id={index}/>;
                        })}
                    </div>
                </div>
            </div>
        );
    };

    const renderMain = () => {
            return (
                <div className={'bg-white pb-5'}>
                    <NewNavbar/>
                    <div className={'container '}>
                        <div className={'row mt-2'}>
                            <div className={'container mt-3'}>
                                <Breadcrumb>
                                    <Breadcrumb.Item>
                                        <NavLink to={'/shop'}
                                                 style={{
                                                     textDecoration: 'none',
                                                     color: '#000000',
                                                 }}
                                                 className={`text-decoration-none font-16 sfProDisplayFont font-weight-800 `}>
                                            Home
                                        </NavLink>
                                    </Breadcrumb.Item>
                                    {breadcrumbs.length &&
                                        breadcrumbs.map((value, index, breadcrumbs) => {

                                                link  +=  (target === 'fanzine' && index === 0) ? '' :  '/' + value;

                                            return (<Breadcrumb.Item
                                                active={index + 1 === breadcrumbs.length}>
                                                <NavLink to={link}
                                                         style={{
                                                             color: '#737373',
                                                         }}
                                                         className={` text-decoration-none sfProDisplayFont ${(index +
                                                             1 === breadcrumbs.length) ?
                                                             'text-light-gray' :
                                                             'text-black'}`}>
                                                    {value}
                                                </NavLink>
                                            </Breadcrumb.Item>);
                                        })}
                                </Breadcrumb>
                            </div>
                            <p className={'text-dark text-center bebasFont'}
                               style={{fontSize: 70, letterSpacing: -1.4}}>
                                {TopHeader} T-SHIRTS, HOODIES, JACKETS, POLOS & MORE</p>
                            <p className={'text-dark font-20 text-center bebas'}>Explore our
                                range of
                                fan made merchandise! We pride ourselves with high quality
                                products.</p>
                        </div>
                    </div>

                    <div className={'d-flex mx-3 position-relative'}>
                        <button
                            className={`py-2 px-5 border-0 font-26 bebasFont ${navColor} ${[
                                'theyellows',
                                'wearewolves'].includes(target) ? textColor : fontColor}`}
                            ref={newTarget} onClick={() => setShowFilter(!showFilter)}
                            style={{
                                color: ['theyellows', 'wearewolves'].includes(target) ?
                                    textColor :
                                    fontColor,
                            }}>
                            Filter
                            &nbsp;
                            <img src={require(
                                '../../../../assets/images/v2/filterList@3x.png')} height={15}
                                 width={22} alt={'filter-icon'}/>
                        </button>
                        <Overlay target={newTarget.current} show={showFilter}
                                 placement="bottom">
                            {(props) => (
                                <Tooltip id="overlay-example" {...props} >
                                    {filterDropDown()}
                                </Tooltip>
                            )}
                        </Overlay>
                        <button
                            className={`py-2 px-5 border-0 font-26  bebasFont ${navColor} ms-2 ${[
                                'theyellows',
                                'wearewolves'].includes(target) ? textColor : fontColor}`}
                            ref={sortTarget} onClick={() => setShowSort(!showSort)} style={{
                            color: ['theyellows', 'wearewolves'].includes(target) ?
                                textColor :
                                fontColor,
                        }}>
                            SORT BY  &nbsp;
                            <img src={require(
                                '../../../../assets/images/v2/component85613@3x.png')}
                                 height={9} width={21} alt={'filter-icon'}/>
                        </button>
                        <Overlay target={sortTarget.current} show={showSort}
                                 placement="bottom">
                            {(props) => (
                                <Tooltip  {...props}>
                                    {renderSortByDropdown()}
                                </Tooltip>
                            )}
                        </Overlay>
                    </div>


                    <div className={'container-fluid bg-white  border-0 mt-5'}>
                        <div className={'row  border-0'}>
                            {data.length === 1 && data[0]?.products.map((value, index) => {
                                return (
                                    <div
                                        key={index}
                                        className={'col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 col-xxl-3'}>
                                        <NewProductComponent
                                            cart={cart}
                                            showPriceFirst={true}
                                            item={value} showColors={true}
                                            showDetail={true}
                                            newShopItems={true}/>
                                    </div>
                                );
                            })}

                            {data.length > 1 &&

                                data?.map((item, itemIndex) => {
                                    return item?.products.map((value, index) => {

                                        // if (selectedColors.length > 0) {
                                        //   let imageSrc = setColorForProducts(value,
                                        //       index);
                                        //   value.src = imageSrc === '' ?
                                        //       value.src :
                                        //       imageSrc;
                                        // }
                                        return (
                                            <div
                                                className={'col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 col-xxl-3 justify-content-center d-flex'}>
                                                <NewProductComponent item={value}
                                                                     showPriceFirst={true}
                                                                     showColors={true}
                                                                     showDetail={true}
                                                                     newShopItems={true}/>
                                            </div>
                                        );
                                    });
                                })}
                        </div>
                    </div>
                </div>
            );
        }
    ;
    return renderMain();
};

export default withRouter(NewProductListPage);
