export const ShopifyActionTypes = {
    SET_PRODUCT_TAGS: "SET_PRODUCT_TAGS",
    SET_PRODUCT_TYPES: "SET_PRODUCT_TYPES",
    SET_CATEGORIES: "SET_CATEGORIES",
    SET_PRODUCT_COLORS: "SET_PRODUCT_COLORS",
    SET_CONTAINER_IMAGES: "SET_CONTAINER_IMAGES",
    SET_BANNER_IMAGES: "SET_BANNER_IMAGES",
    SET_PRODUCTS: "SET_PRODUCTS",
    SET_RANDOM_PRODUCTS: "SET_RANDOM_PRODUCTS",
}
