import {FiShare} from 'react-icons/fi';
import React, {useEffect, useRef, useState} from 'react';
import {
  Button,
  Overlay,
  OverlayTrigger,
  Popover,
  Tooltip,
} from 'react-bootstrap';

const SocialShareButton = (props) => {
  const {tmpSocialNews} = props;
  const [copyToClipboardBool, setCopyToClipboardBool] = useState(false);
  const [show, setShow] = useState(false);
  const target = useRef(null);
  const handleCopyToClipboard = () => {
    setCopyToClipboardBool(!show);
    setShow(!copyToClipboardBool);
    navigator.clipboard.writeText(tmpSocialNews?.url);
  };

  useEffect(() => {
    setTimeout(()=>{
      setShow(false)
    },1000)
  }, [copyToClipboardBool]);

  const popover = (
      <Popover id="popover-basic">
        {/*<Popover.Header as="h3">Copied!</Popover.Header>*/}
        <Popover.Body>
          Link Copied!
        </Popover.Body>
      </Popover>
  );

  const renderShareButton = () => (
      <OverlayTrigger
          popperConfig={{}}
          flip={false}
          trigger="click" placement="right" overlay={popover} defaultShow={show}
          delay={0} target={target.current} onHide={() => setShow(!show)}
          onToggle={() => console.log('hello')}
          show={show}
      >
        <Button style={{background:'#FFFFFF',
          border: '0px',
        }}>
          <FiShare size={'20'} color={'#65b4ee'}/>
        </Button>
      </OverlayTrigger>
  );

  return (
      <div
          style={{cursor: 'pointer'}}
          onClick={handleCopyToClipboard}
          ref={target}
          className={'col d-flex justify-content-center align-items-center'}>


        {renderShareButton()}

        &nbsp;

      </div>

  );

};

export default SocialShareButton;
