import webAdvertisement from "../../assets/images/web-fanzine-ad.png";
import {useEffect, useState} from 'react';
import Slider from 'react-slick';
import '../../assets/styles/WebAdvertisement.css';
const WebAdvertisement = (props) => {
  const { isMobile } = props;
  const [backgroundSize,setBackGroundSize] = useState('1000px');



  useEffect(() => {
    if (window.screen.width >= 2550) {
      setBackGroundSize('1000px')
    } else if (window.screen.width >= 1360 && window.screen.width < 1920) {
      setBackGroundSize('900px')
    }
  }, [window.screen.width]);

  const renderDesktopView = () => {
    return (
      <div
          style={{
            height: backgroundSize,
          }}
          className={"container-fluid blackBackgroundTwo flex-grow-1 "}>
        <div className={"container "}>
          <div className={"row "}>
            <div className={"col-md-12 col-lg-12 col-xl-12 col-xxl-12 mt-5"}>

              <div className={"row justify-content-center"}>
                <span
                    style={{
                      fontWeight:'bold'
                    }}
                    className={"font-fanzine font-50 Montserrat-Bold text-center"}>
                  WEB
                  &nbsp;
                  <span
                      style={{
                        fontWeight:'bold'
                      }}
                      className={"text-white font-50 Montserrat-Bold text-center"}>
                  ADVERTISING
                </span>
                </span>
              </div>
            </div>
          </div>

          <div className={"row justify-content-between align-items-center"}>
            <div className={" col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12"}>
              <div className={"row"}>
                <img
                  className={"w-100 pt-5"}
                  src={webAdvertisement}
                  alt={"webAdvertisement"}
                  style={{ objectFit: "contain",height:'792px',width:'auto' }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };


  const renderMobileViewDomainName = () =>
  {
    return(
        <div>
          <div className={"row pt-5 mb-2 justify-content-evenly"}>
            <div className={"col-5 "}>
            <span className={"font-14 text-white Montserrat-Regular"}>
              Gunners.com
            </span>
            </div>
            <div className={"col-5 "}>
            <span className={"font-14 text-white Montserrat-Regular"}>
              COYS.com
            </span>
            </div>
          </div>
          <div className={"row mt-2 justify-content-evenly"}>
            <div className={"col-5 "}>
            <span className={"font-14 text-white Montserrat-Regular"}>
              CFC-Blues.com
            </span>
            </div>
            <div className={"col-5 "}>
            <span className={"font-14 text-white Montserrat-Regular"}>
              TheKop.com
            </span>
            </div>
          </div>
          <div className={"row mt-2 justify-content-evenly"}>
            <div className={"col-5 "}>
            <span className={"font-14 text-white Montserrat-Regular"}>
              WATP.com
            </span>
            </div>
            <div className={"col-5"}>
            <span className={"font-14 text-white Montserrat-Regular"}>
              ManCityzens.com
            </span>
            </div>
          </div>
        </div>
    )
  }
  const renderMobileViewDomainName1 = () =>
  {
    return(
        <div>
          <div className={"row pt-5 mb-2 justify-content-evenly"}>
            <div className={"col-5 "}>
            <span className={"font-14 text-white Montserrat-Regular"}>
            COYIrons.com
            </span>
            </div>
            <div className={"col-5 "}>
            <span className={"font-14 text-white Montserrat-Regular"}>
            LUFCMOT.com
            </span>
            </div>
          </div>
          <div className={"row mt-2 justify-content-evenly"}>
            <div className={"col-5 "}>
            <span className={"font-14 text-white Montserrat-Regular"}>
              UnitedReds.com
            </span>
            </div>
            <div className={"col-5 "}>
            <span className={"font-14 text-white Montserrat-Regular"}>
              ToonArmy.com
            </span>
            </div>
          </div>
          <div className={"row mt-2 justify-content-evenly"}>
            <div className={"col-5 "}>
            <span className={"font-14 text-white Montserrat-Regular"}>
              Celtic1967.com
            </span>
            </div>
            <div className={"col-5"}>
            <span className={"font-14 text-white Montserrat-Regular"}>
              The-Toffees.com
            </span>
            </div>
          </div>
        </div>
    )
  }
  const renderMobileViewDomainName2 = () =>
  {
    return(
        <div>
          <div className={"row pt-5 mb-2 justify-content-evenly"}>
            <div className={"col-5 "}>
            <span className={"font-14 text-white Montserrat-Regular"}>
              PalaceEagles.com
            </span>
            </div>
            <div className={"col-5 "}>
            <span className={"font-14 text-white Montserrat-Regular"}>
              ComeOnLeicester.com
            </span>
            </div>
          </div>
          <div className={"row mt-2 justify-content-evenly"}>
            <div className={"col-5 "}>
            <span className={"font-14 text-white Montserrat-Regular"}>
              WeAreWolves.com
            </span>
            </div>
            <div className={"col-5 "}>
            <span className={"font-14 text-white Montserrat-Regular"}>
              CityBluebirds.com
            </span>
            </div>
          </div>
          <div className={"row mt-2 justify-content-evenly"}>
            <div className={"col-5 "}>
            <span className={"font-14 text-white Montserrat-Regular"}>
              The-Clarets.com
            </span>
            </div>
            <div className={"col-5"}>
            <span className={"font-14 text-white Montserrat-Regular"}>
              WeAreQPR.com
            </span>
            </div>
          </div>
        </div>
    )
  }

  const renderMobileView = () => {

    const settings = {
      dots: true,
      infinite: true,
      arrows: false,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 2000,
      cssEase: "linear",
    };

    return (
      <div
          style={{
            height:'650px',
          }}
          className={"blackBackground container"}>
        <div className={"row"}>
          <span
            className={"font-25 text-white Montserrat-Bold text-center pt-5"}
          >
            WEB ADVERTISEMENT
          </span>
          <span className={"font-fanzine font-16 Montserrat-Bold text-center pt-3"}>
            50+ PREMIUM DOMAINS
          </span>
        </div>
        <Slider {...settings}>
          {renderMobileViewDomainName()}
          {renderMobileViewDomainName1()}
          {renderMobileViewDomainName2()}
        </Slider>
        <div className={"row mt-5 justify-content-center"}>
          <div className={"col-12"}>
            <img src={require('../../assets/images/webAdvertising.png')} alt={'web adv mob'}
                 className={'img-fluid'}
            />
          </div>
        </div>
      </div>
    );
  };

  const renderMain = () =>
    isMobile ? renderMobileView() : renderDesktopView();

  return renderMain();
};

export default WebAdvertisement;
