import {Button, Modal} from "react-bootstrap";
import PropTypes from "prop-types";
import {faTimes} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useEffect, useRef, useState} from "react";
import {useDispatch} from "react-redux";
import {getHostname} from "../../CustomFunctions/getHostname";
import {COLORS, NAV_CLASS} from "../../Configs/Targets";
import {SCREEN_SIZE} from "../../Configs/Constants";
import Cookies from "universal-cookie";

const LeagueCodePopup = (props) => {
    let dispatch = useDispatch();
    const cookies = new Cookies();

    const campaign = props.campaign.length ? props.campaign[0] : null;

    const [answers, setAnswers] = useState(campaign && campaign.questions);
    const [isMobile, setIsMobile] = useState(false);
    const [width, setWidth] = useState(window.innerWidth);
    const [copySuccess, setCopySuccess] = useState('');
    const copyCodeRef = useRef(null);

    const updateScreenWidth = () => {
        setWidth(window.innerWidth);
    }

    useEffect(() => {
        updateScreenWidth();
    }, []);

    useEffect(() => {
        if (width <= SCREEN_SIZE.MOBILE) {
            setIsMobile(true);
        }
    }, [width]);

    const submitResponse = () => {
        props.onHideLeagueCode();
        props.onHide();
        const hostName = window.location.hostname;
        const domainObject = getHostname(hostName);
        const target = domainObject.host;
        cookies.set(`fanzine_campaign_${campaign.campaign_id}_${target}`, '1', {path: '/'});
        window.open(`https://fantasy.premierleague.com/leagues/auto-join/${campaign.leagueCode}`, '_blank').focus();
    }

    const copyToClipboard = (e) => {
        copyCodeRef.current.select();
        document.execCommand('copy');
        // This is just personal preference.
        // I prefer to not show the whole text area selected.
        e.target.focus();
        setCopySuccess('Copied!');
    };

    const renderModal = () => {
        const hostName = window.location.hostname;
        const domainObject = getHostname(hostName);
        const target = domainObject.host;
        const navColor = NAV_CLASS[target];
        const color = COLORS[target];
        return (<Modal
            {...props}
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Body style={{marginLeft: '10%', width: '90%'}}>
                <Button className={`btn rounded-circle bg-white border-0 float-end`} onClick={props.onHideLeagueCode}>
                    <FontAwesomeIcon icon={faTimes} size={'2x'} color={color}/>
                </Button>
                <div style={{width: '90%'}}>
                    <div className={'offset-2 col-8'}>
                        <img src={require('../../assets/images/fanzine_logo.png')} alt={campaign.title} className={'img-fluid'}/>
                    </div>
                    <div className={'row'}>
                        <div className={`bg-dark-grey rounded-pill p-3`}>
                            <label className={`font-22 text-center ms-4 pt-2 sfProDisplayFontBold`}>{campaign.leagueCode}</label>
                            <textarea className={`visually-hidden-focusable`} ref={copyCodeRef}>{campaign.leagueCode}</textarea>
                            <button type="button" className={`btn border-0 rounded-pill btn-lg ps-4 pe-4 bg-dark-green text-white float-end`} onClick={copyToClipboard}>
                                Copy Code
                            </button>
                        </div>
                    </div>
                    <Modal.Footer className={`w-100 border-0 justify-content-center`}>
                        {copySuccess}
                        <Button className={`${navColor} border-0 rounded-pill btn-lg ps-4 pe-4`} onClick={submitResponse}>
                            <div className={`d-inline-flex justify-content-center align-items-center`}>
                                <span className={`${isMobile ? 'font-24' : 'font-30'}`}>Join League</span>
                                &nbsp;&nbsp;&nbsp;
                                <div className={'col-3 text-start'}>
                                    <img src={require('../../assets/images/epl_logo.png')} alt={campaign.title} className={'img-fluid'}/>
                                </div>
                            </div>
                        </Button>
                    </Modal.Footer>
                    <Modal.Footer className={`w-100 border-0 justify-content-end`}>
                        <a className={`border-0 rounded-pill btn-lg ps-4 pe-4 btn-link`} href={'https://fanzine.com/terms'} target={'_blank'} style={{color: color}} rel="noreferrer">Terms &
                            Conditions</a>
                    </Modal.Footer>
                </div>
            </Modal.Body>
        </Modal>);
    }

    const render = () => {
        if (campaign) {
            return renderModal();
        } else {
            return null;
        }
    }

    return render();

}

LeagueCodePopup.propTypes = {
    campaign: PropTypes.array.isRequired,
}

export default LeagueCodePopup;