import {MiscActionTypes} from "../constants/MiscActionTypes";

export const setAppInit = (data) => {
  return {
      type: MiscActionTypes.SET_APP_INIT,
      payload: data,
  }
}
export const setSingleVideo = (data) => {
  return {
      type: MiscActionTypes.SET_SINGLE_VIDEO,
      payload: data,
  }
}
export const setCampaignAnswered = (data) => {
  return {
      type: MiscActionTypes.SET_CAMPAIGN_ANSWERED,
      payload: data,
  }
}
export const setTargetApps = (data) => {
  return {
      type: MiscActionTypes.SET_TARGETS_APPS,
      payload: data,
  }
}
export const setCurrencies = (data) => {
  return {
      type: MiscActionTypes.SET_CURRENCIES,
      payload: data,
  }
}
export const setCurrentIp = (data) => {
  return {
      type: MiscActionTypes.SET_CURRENT_IP,
      payload: data,
  }
}
export const setCurrentCurrency = (data) => {
  return {
      type: MiscActionTypes.SET_CURRENT_CURRENCY,
      payload: data,
  }
}
