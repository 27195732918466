import leoMessi from '../../../assets/images/messi.webp';
import UpNextComponent from "./UpNextComponent";
import React, {useEffect, useState} from 'react';
import {useLocation, useSearchParams} from 'react-router-dom';
import {CRICKET, FBS, FOOTBALL, MLB, NBA, NCAA, NFL,} from '../../../Axios/EndPoints';
import {get} from '../../../Axios/get';
import ReactPlayer from 'react-player'
import VideoCards from '../VideoCardComponent';
import GoogleAd from '../../../Components/Ads/GoogleAd';
import axios from 'axios';
import {getHostname} from '../../../CustomFunctions/getHostname';
import SpecificLeagueVideosOnly from "./SpecificLeagueVideosOnly";
import GoogleAdManager from "../../../Components/Ads/GoogleAdManager";


const LatestVideosAndUpNext = (props) => {

    const {
        isMobile,
        data,
        leagueId,
        leagueName,
        leagueBg,
        leagueColor,
        isClub,
        isFanzineClub,
        fanzineClubData,
        teamData
    } = props;

    const hostName = window.location.hostname;
    const domainObject = getHostname(hostName);
    const target = domainObject.host;

    const location = useLocation();
    const [searchParams, setSearchParams] = useSearchParams()
    const [latestVideos, setLatestVideos] = useState([])
    const [latestVideosLoading, setLatestVideosLoading] = useState(true)

    let [apiPage, setApiPage] = useState(1);
    let [restrictApiCall, setRestrictApiCall] = useState(true);

    const [selectedVideo, setSelectedVideo] = useState(null);

    const handleScroll = (e) => {
        const pageBottom = document.body.scrollHeight -
            document.documentElement.clientHeight - 10 <=
            document.documentElement.scrollTop;
        if (pageBottom) {
            setApiPage((prevState) => {
                return prevState + 1;
            });
            setRestrictApiCall(false);
        }
    };

    const fetchLatestVideos = (endpoint = null, isNew) => {

        // let league_id = (leagueId || searchParams.get('team_id') || searchParams.get('league_id') || data?.clubId || '');
        // let parameters = leagueId ? '&league_id='+ leagueId : searchParams.get('league_id') ?
        //     '&league_id=' + league_id : searchParams.get('team_id') || data?.clubId ?
        //         '&team_id=' + league_id :
        //         '&main_site=1';
        // console.log('endpoint =',endpoint)
        // if(endpoint?.includes('undefined'))
        // {
        //   endpoint = endpoint.replace('?team_id=undefined','')
        // }
        //
        // let url = endpoint ? endpoint : FOOTBALL.LatestFanzineVideosEndPoint + parameters


        let id = (leagueId || searchParams.get('team_id') || searchParams.get('league_id') ||
            data?.clubId ||
            data?.id || '');

        let parameters = leagueId ? '?league_id=' + id : searchParams.get('league_id') ?
            '?league_id=' + id :
            '?team_id=' + id;

        let URL = '';

        switch (location.pathname.split('/')[1]) {
            case 'football':
                URL += (isFanzineClub ? FOOTBALL.LatestClubVideosEndPoint : FOOTBALL.LatestFanzineVideosEndPoint);
                break;
            case 'cricket':
                URL += CRICKET.VideoEndPoint;
                break;
            case 'nfl':
                URL += NFL.VideoEndPoint;
                break;
            case 'nba':
                URL += NBA.VideoEndPoint;
                break;
            case 'ncaam':
                URL += NCAA.VideoEndPoint;
                break;
            case 'ncaaf':
                URL += FBS.VideoEndPoint;
                break;
            case 'mlb':
                URL += MLB.VideoEndPoint;
                break;
            default:
                URL += (isClub ? FOOTBALL.LatestClubVideosEndPoint : FOOTBALL.LatestFanzineVideosEndPoint);
                break;

        }

        parameters = '&page=' + apiPage;

        if (id !== '') {
            URL += parameters;
        }

        if (isFanzineClub) {
            let baseURL = '';
            baseURL = fanzineClubData?.clubUrl?.replace('www', 'api2');

            axios.get(baseURL + URL).then((response) => {
                // eslint-disable-next-line array-callback-return
                let tempData = response.data;
                tempData.sort(function (a, b) {
                    let keyA = new Date(a.published_at.date),
                        keyB = new Date(b.published_at.date);
                    // Compare the 2 dates
                    if (keyA < keyB) return 1;
                    if (keyA > keyB) return -1;
                    return 0;
                });


                if (isNew) {
                    setSelectedVideo(tempData[0]);
                    setLatestVideos(tempData);
                } else {
                    setLatestVideos([...latestVideos, ...tempData]);
                }

                setLatestVideosLoading(false);
            }).catch((err) => {
                console.error(err);
            });
        } else {
            get(URL).then((response) => {
                // eslint-disable-next-line array-callback-return
                let tempData = response.data;
                tempData.sort(function (a, b) {
                    let keyA = new Date(a.published_at.date),
                        keyB = new Date(b.published_at.date);
                    // Compare the 2 dates
                    if (keyA < keyB) return 1;
                    if (keyA > keyB) return -1;
                    return 0;
                });
                if (isNew) {
                    setSelectedVideo(tempData[0]);
                    setLatestVideos(tempData);
                } else {
                    setLatestVideos([...latestVideos, ...tempData]);
                }

                // setSelectedVideo(tempData[0]);
                // setLatestVideos(tempData);
                setLatestVideosLoading(false);
            }).catch((err) => {
                console.error(err);
            });
        }
    };

    useEffect(() => {
        if (props?.selectedVideo) {
            setSelectedVideo(props?.selectedVideo);
        }

        return (() => {
            if (props?.setSelectedVideo) {
                props?.setSelectedVideo(null);
            }
        })

    }, [props])


    useEffect(() => {
        setRestrictApiCall(true);
        window.addEventListener('scroll', handleScroll);
        setApiPage(1);
        setLatestVideosLoading(true);
        fetchLatestVideos(props.videosEndpoint, true)
        return () => {
            window.removeEventListener('scroll', handleScroll);
            setSelectedVideo(null);
            setLatestVideos(null)
            setLatestVideosLoading(true);
            setApiPage(1);
        };

    }, [searchParams.get('league_id'), searchParams.get('team_id'), props.videosEndpoint, data, fanzineClubData])

    useEffect(() => {
        setLatestVideosLoading(true);
        !restrictApiCall && fetchLatestVideos(props.videosEndpoint, false);
    }, [apiPage, restrictApiCall]);


    const renderFanzineVideosMobile = () => {
        return (
            <div className={"w-100"}>
                <div className={"col-12"}>
                    {latestVideos?.map((items, index) => {
                        return (index !== 0 && index % 4 === 0) ? (
                            <div className={"d-flex justify-content-center"}>
                                <GoogleAd id={'4009154580'}/>
                            </div>
                        ) : (
                            isMobile && isClub && index === 14 ?
                                <SpecificLeagueVideosOnly isClub={isClub} teamData={teamData}
                                                          isMobile={isMobile}/>
                                :
                                (
                                    <>
                                        <VideoCards isMobile={isMobile} data={items} setSelectedVideo={setSelectedVideo}
                                                    selectedVideo={selectedVideo} textOnSide={index % 7 !== 0}
                                                    isClub={isClub}
                                        />
                                        {isMobile && isClub && index === 0 && <GoogleAdManager isMobile={isMobile} id={'1704953268243'}/>}
                                    </>
                                )
                        )
                            ;
                    })}
                </div>
            </div>
        )
    }


    const renderMain = () => {
        return isMobile ?
            renderFanzineVideosMobile()
            : (
                <div
                    className={`container-fluid bg-black ${isMobile ? 'pt-2' : target !== 'fanzine' ? 'mt-5 pt-5' : ''} `}
                >
                    <div className={'row  d-flex justify-content-center'}>
                        <div className={'col-md-6 col-lg-6 col-xl-6 col-xxl-6 mb-5'}>
                            <p className={`font-30 pt-4 pb-3 m-0 text-start robotoCondensedBold text-white`}>
                                {'LATEST VIDEOS'}
                            </p>
                            <ReactPlayer
                                height={'480px'}
                                width={'auto'}
                                controls={true}
                                playing={true}
                                url={selectedVideo?.url}/>
                            {console.log('selected Video =', selectedVideo)}
                        </div>
                        <div
                            style={{height: '519px'}}
                            className={
                                'col-md-3 col-lg-3 col-xl-3 col-xxl-3 overflow-auto mt-5 pt-1'
                            }
                        >
                            <div>
                                <p className={`font-20 pt-4 pb-3 m-0 text-start robotoCondensedBold text-white`}>
                                    {'UP NEXT'}
                                </p>
                            </div>
                            {latestVideos?.map((items, index) => {
                                return (
                                    <UpNextComponent
                                        item={items}
                                        selectedVideo={selectedVideo}
                                        setSelectedVideo={setSelectedVideo}
                                        news={items?.title}
                                        src={items?.preview_image || leoMessi}
                                        index={index}
                                    />
                                );
                            })}
                        </div>
                    </div>
                </div>
            );
    };

    return renderMain();
};

export default LatestVideosAndUpNext;
