import {Link, useLocation} from 'react-router-dom';
import MobileNewsMenuItem from './MobileNewsMenuItem';
import {useEffect, useState} from 'react';
import {NAVBAR_ITEMS} from "../../Configs/Constants";
import {MLB, NBA, NFL} from '../../Axios/EndPoints';
import {IoAmericanFootballOutline, IoBasketballOutline} from 'react-icons/io5';
import {GiBaseballBat, GiCricketBat} from 'react-icons/gi';
import {useSelector} from "react-redux";
import {LOGO} from "../../Configs/Targets";
import {FaApple, FaFacebook, FaGlobe, FaInstagram, FaTwitter,} from 'react-icons/fa';

export const SubNavbar = (props) => {

    const location = useLocation();
    const store = useSelector(state => state);
    const {isMobile, data, teamData} = props;

    const [currentActive, setCurrentActive] = useState('');
    const [clubIcon, setClubIcon] = useState(null);
    const [sportName, setSportName] = useState((location.pathname.split('/'))[1]);

    useEffect(() => {

        setSportName((location.pathname.split('/'))[1]);

        if (data || teamData?.icon) {
            if (data !== null && store.misc.targetsApps !== null) {
                let ifApp = store.misc.targetsApps.filter(
                    (value) => value.team_id === (data?.clubId || 0),
                );

                if (ifApp.length > 0 && ifApp[0].betting_urls.web_url !== '') {
                    let targetTitle = ifApp[0].betting_urls.web_url.split('.')[1];
                    setClubIcon(LOGO[targetTitle]);
                    Object.assign(data, {clubUrl: ifApp[0].betting_urls.web_url});
                    // setClubIcon(ifApp[0].)
                } else {
                    setClubIcon(data?.clubIcon || teamData?.icon);
                }
            } else {
                setClubIcon(data?.clubIcon || teamData?.icon);
            }
        } else {
            setClubIcon(require('../../assets/images/cfootball.png'));
        }


        if (isMobile && (location.pathname.includes('table') || location.pathname.includes('matches'))) {
            const element = document.getElementById('slider-mobile');
            element.scrollLeft = 350;
        }


    }, [data, teamData, props.items, clubIcon])


    const renderMobileMenu = () => {
        let defaultImage = '';
        let sportNameHeader = ''
        switch (location.pathname.split('/')[1]) {
            case 'football':
                defaultImage = require('../../assets/images/default-league.png')
                sportNameHeader = 'Football';
                break;
            case 'cricket':
                sportNameHeader = 'Cricket';
                break;
            case 'nfl':
                sportNameHeader = 'NFL';

                break;
            case 'nba':
                sportNameHeader = 'NBA';

                break;
            case 'mlb':
                sportNameHeader = 'MLB';

                break;
            default:
                defaultImage = require('../../assets/images/default-league.png')
                sportNameHeader = 'Football';

                break;
        }


        let tempSocial = {
            twitter: '',
            facebook: '',
            instagram: '',
            ios: '',
            android: '',
            web: '',
        }
        let leagueName = ''

        for (let i = 0; i < store?.misc?.targetsApps?.length; i++) {

            if (store?.misc?.targetsApps[i]?.team_id === data?.clubId) {
                tempSocial.twitter = store?.misc?.targetsApps[i].twitter_url;
                tempSocial.facebook = store?.misc?.targetsApps[i].facebook_url;
                tempSocial.instagram = store?.misc?.targetsApps[i].instagram_url;
                tempSocial.ios = store?.misc?.targetsApps[i].ios_default_app_url;
                tempSocial.android = store?.misc?.targetsApps[i].android_default_app_url;
                tempSocial.web = store?.misc?.targetsApps[i].web_default_app_url;
                leagueName = store?.misc?.targetsApps[i]?.league_name;
            }
        }

        return (
            <div className={'container-fluid'}
            >
                {props?.isClub && (data || teamData?.icon) && <div
                    className={'ms-auto d-flex'}>
                    <img src={data?.clubIcon || teamData?.icon}
                         alt={(data?.clubName || teamData?.name) + 'logo'}
                         style={{height: '54px', width: '54px', objectFit: 'contain'}}/>
                    &nbsp;
                    &nbsp;
                    <div className={` ${leagueName ? '' : 'd-flex align-items-center'} font-weight-800 font-16`}>
                        {data?.clubName || teamData?.name}
                        <div className={'font-12 d-flex align-items-center'}>
                            {leagueName || ''}
                        </div>
                    </div>
                    {/*<div className={'d-flex align-items-center'}>
                            <a style={{color: '#000000', cursor: 'pointer'}}
                               href={data?.twitter_url} target="_blank"
                               rel="noopener noreferrer">
                                <FaTwitter size={25}/>
                            </a>
                            &nbsp;
                            &nbsp;
                            &nbsp;
                            <a style={{color: '#000000', cursor: 'pointer'}}
                               href={data?.facebook_url} target="_blank"
                               rel="noopener noreferrer">
                                <FaFacebook size={25}/>
                            </a>
                            &nbsp;
                            &nbsp;
                            &nbsp;
                            <a style={{color: '#000000', cursor: 'pointer'}}
                               href={data?.instagram_url} target="_blank"
                               rel="noopener noreferrer">
                                <FaInstagram size={25}/>
                            </a>
                        </div>*/}
                </div>
                }
                {props?.isClub &&
                    <hr
                        className={'m-0'}
                        style={{border: '1px solid black', opacity: 0.1}}/>
                }
                <div
                    id={'slider-mobile'}
                    className={`slider-mobile-news-container`}>
                    {
                        (!data && !teamData?.icon) &&
                        <MobileNewsMenuItem
                            inactiveFontColor={'#000000'} isMobile={isMobile}
                            setCurrentActive={setCurrentActive} title={''}
                            uri={teamData?.clubLink || `/${sportName}`}
                            // selected={location.pathname === '/football'}
                            selected={false}
                        >
                            {
                                (!data && !teamData?.icon) && sportNameHeader === 'Football' &&
                                <img src={(teamData?.sportIcon || defaultImage)}
                                     alt={`Fanzine Football Logo`} height={30} style={{zIndex: -1}}/>
                            }
                            {
                                sportNameHeader === 'NFL' &&
                                <IoAmericanFootballOutline size={'30'}/>
                            }
                            {
                                sportNameHeader === 'NBA' &&
                                <IoBasketballOutline size={'30'}/>
                            }
                            {
                                sportNameHeader === 'MLB' &&
                                <GiBaseballBat size={'30'}/>
                            }
                            {
                                sportNameHeader === 'Cricket' &&
                                <GiCricketBat size={'30'}/>
                            }
                            &nbsp;&nbsp;&nbsp;
                            &nbsp;&nbsp;&nbsp;
                            {props?.isClub && <span style={{
                                borderLeft: 'solid 1.5px #000',
                                height: '24.5px',
                            }}/>}
                        </MobileNewsMenuItem>
                    }
                    <MobileNewsMenuItem
                        inactiveFontColor={'#000000'} isMobile={isMobile}
                        setCurrentActive={setCurrentActive} title={'Home'}
                        uri={`/${sportName + '/' + (location?.pathname?.split('/')[2] || '')}`}
                        // selected={location.pathname === '/football'}
                        selected={(location.pathname.includes(`/${sportName}`)
                            && !location.pathname.includes(`/news`)
                            && !location.pathname.includes(`/teams`)
                            && !location.pathname.includes(`/matches`)
                            && !location.pathname.includes(`/table`)
                            && !location.pathname.includes(`/video`)

                        ) || location?.pathname === (teamData?.clubLink || data?.clubLink)}
                    />
                    {
                        NAVBAR_ITEMS.news.includes(sportName) && !props.isClub &&
                        <MobileNewsMenuItem inactiveFontColor={'#000000'}
                                            isMobile={isMobile}
                                            setCurrentActive={setCurrentActive}
                                            title={'News'}
                                            uri={`/${sportName}/news`}
                                            selected={location.pathname.includes('/news')}/>
                    }
                    {
                        NAVBAR_ITEMS.teams.includes(sportName) && !props.isClub &&
                        <MobileNewsMenuItem inactiveFontColor={'#000000'}
                                            isMobile={isMobile}
                                            setCurrentActive={setCurrentActive}
                                            title={'Teams'}
                                            uri={`/${sportName}/teams`}
                                            selected={location.pathname.includes('/teams')}/>
                    }
                    {
                        NAVBAR_ITEMS.matches.includes(sportName) && (location.pathname.includes('football') || !props.isClub) &&
                        <MobileNewsMenuItem inactiveFontColor={'#000000'}
                                            isMobile={isMobile}
                                            setCurrentActive={setCurrentActive}
                                            title={'Matches'}
                                            uri={`/${props.isClub
                                                ? sportName + '/' + location?.pathname?.split('/')[2] + '/matches'
                                                :
                                                sportName + '/matches'}
                                                `}
                                            selected={location.pathname.includes('/matches')}/>
                    }
                    {
                        NAVBAR_ITEMS.standings.includes(sportName) && (location.pathname.includes('football') || !props.isClub) &&
                        <MobileNewsMenuItem inactiveFontColor={'#000000'}
                                            isMobile={isMobile}
                                            setCurrentActive={setCurrentActive}
                                            title={sportName?.toLowerCase() === 'football' ? 'Table' : 'Standings'}
                                            uri={`/${props.isClub
                                                ? sportName + '/' + location?.pathname?.split('/')[2] + '/table'
                                                :
                                                sportName + '/table'}
                                                `}
                            // uri={`/${sportName}/table`}
                                            selected={location.pathname.includes('/table')}/>
                    }
                    {
                        NAVBAR_ITEMS.videos.includes(sportName) && (location.pathname.includes('football') || !props.isClub) &&
                        <MobileNewsMenuItem inactiveFontColor={'#000000'}
                                            isMobile={isMobile}
                                            setCurrentActive={setCurrentActive}
                                            title={'Videos'}
                                            uri={`/${props.isClub
                                                ? sportName + '/' + location?.pathname?.split('/')[2] + '/video'
                                                :
                                                sportName + '/video'}
                                                `}
                            // uri={`/${sportName}/table`}
                                            selected={location.pathname.includes('/video')}/>
                    }
                    {/*<MobileNewsMenuItem inactiveFontColor={'#eeeeee'} setCurrentActive={setCurrentActive} title={'Matches'} uri={`/football/matches`} selected={location.pathname === '/football/matches'}/>
                <MobileNewsMenuItem inactiveFontColor={'#eeeeee'} setCurrentActive={setCurrentActive} title={'Tables'} uri={`/football/table`} selected={location.pathname === '/football/table'}/>*/}
                </div>
            </div>
        );
    };


    const renderBigView = () => {

        let defaultImage = '';
        let sportNameHeader = ''
        switch (location.pathname.split('/')[1]) {
            case 'football':
                defaultImage = require('../../assets/images/default-league.png')
                sportNameHeader = 'Football';
                break;
            case 'cricket':
                sportNameHeader = 'Cricket';
                break;
            case 'nfl':
                sportNameHeader = 'NFL';
                break;
            case 'nba':
                sportNameHeader = 'NBA';
                break;
            case 'mlb':
                sportNameHeader = 'MLB';
                break;
            default:
                defaultImage = require('../../assets/images/default-league.png')
                sportNameHeader = 'Football';

                break;
        }

        let tempSocial = {
            twitter: '',
            facebook: '',
            instagram: '',
            ios: '',
            android: '',
            web: '',
        }
        let leagueName = ''

        for (let i = 0; i < store?.misc?.targetsApps?.length; i++) {

            if (store?.misc?.targetsApps[i]?.team_id === data?.clubId) {

                tempSocial.twitter = store?.misc?.targetsApps[i].twitter_url;
                tempSocial.facebook = store?.misc?.targetsApps[i].facebook_url;
                tempSocial.instagram = store?.misc?.targetsApps[i].instagram_url;
                tempSocial.ios = store?.misc?.targetsApps[i].ios_default_app_url;
                tempSocial.android = store?.misc?.targetsApps[i].android_default_app_url;
                tempSocial.web = store?.misc?.targetsApps[i].web_default_app_url;
                leagueName = store?.misc?.targetsApps[i]?.league_name;

            }
        }

        let clubAltName = ''
        if (data) {
            clubAltName = data?.clubUrl?.split('.')[1];
            console.log('clubAltName =', clubAltName)
        }

        return (
            <div className={'container-fluid'}
            >
                <div className={`row -flex align-items-xxl-baseline ${!props?.isClub && 'pt-2'}`}>
                    {<div
                        className={`${'d-flex align-items-center col-xxl-2 col-xl-2 col-lg-3 col-md-4 col-sm-5'} text-black ${(data ||
                            teamData) &&
                        `robotoCondensedBold ${data?.clubName?.length >
                        10 ? `font-16` : `font-20`} font-weight-800`}`}>
                        {
                            (!data && !teamData?.icon) && sportNameHeader ===
                            'Football' &&
                            <img src={(teamData?.sportIcon || defaultImage)}
                                 alt={`Fanzine Football Logo`} height={50}
                                 style={{zIndex: -1}}/>
                        }
                        {
                            sportNameHeader === 'NFL' &&
                            <IoAmericanFootballOutline size={'50'}/>
                        }
                        {
                            sportNameHeader === 'NBA' &&
                            <IoBasketballOutline size={'50'}/>
                        }
                        {
                            sportNameHeader === 'MLB' &&
                            <GiBaseballBat size={'50'}/>
                        }
                        {
                            sportNameHeader === 'Cricket' &&
                            <GiCricketBat size={'50'}/>
                        }
                        {props?.isClub && location.pathname.includes('football') && !location.pathname.includes('women') &&
                            <img src={(data?.clubIcon || teamData?.icon || defaultImage)}
                                 alt={`Fanzine Football Logo`} height={59} width={59}
                                 style={{zIndex: -1, objectFit: 'contain'}}/>
                        }
                        {props?.isClub && !location.pathname.includes('football') &&
                            <img src={(data?.clubIcon || teamData?.icon || defaultImage)}
                                 alt={`Fanzine Football Logo`} height={50} width={50}
                                 className={'mt-2'}
                                 style={{zIndex: -1, objectFit: 'contain'}}/>
                        }
                        {props?.isClub && location.pathname.includes('women') &&
                            <img src={(data?.clubIcon || teamData?.icon || defaultImage)}
                                 className={'mt-2'}
                                 alt={`Fanzine Football Logo`} height={50} width={50}
                                 style={{zIndex: -1, objectFit: 'contain'}}/>
                        }
                        <div className={'ms-2'}>
                            <p className={'m-0 p-0 font-24 text-decoration-none sfProDisplayFontBold text-nowrap'}>
                                {data?.clubName || teamData?.name ||
                                    sportNameHeader}
                            </p>
                            <div className={'mt-n1'}>
                                {props?.isClub && <p
                                    style={{
                                        fontWeight: 500,
                                    }}
                                    className={'m-0 p-0 font-14 text-decoration-none sfProDisplayFont text-nowrap'}>
                                    {leagueName || ''}
                                </p>
                                }
                            </div>

                        </div>
                    </div>}
                    <div className={'col-10 pt-2'}>
                        {props?.isClub && <div
                            className={'row d-flex align-items-end justify-content-end'}>
                            <div
                                className={`${'d-flex align-items-center justify-content-end col-10'}`}>
                                {/*{(data || teamData?.icon) && <div*/}
                                {/*    >*/}
                                {
                                    <img src={clubIcon}
                                         alt={(data?.clubName || teamData?.name) +
                                             'logo'}
                                         style={{
                                             height: '60.5px',
                                             width: '58.5px',
                                             objectFit: 'contain',
                                             display: data?.clubUrl?.split('.')[1] ? 'block' : 'none',
                                         }}
                                         onClick={() => {
                                             if (data.clubUrl) {
                                                 window.open(data.clubUrl,
                                                     '_blank');
                                             }
                                         }
                                         }
                                    />
                                }
                                <span
                                    className={'p-0 m-0 font-weight-800 font-16  '}>
                                    &nbsp;{data?.clubUrl?.split('.')[1] ? data?.clubUrl?.split('.')[1].toUpperCase() : ''}
                                    {/*data?.clubName || teamData?.name*/}
                                    <br/>
                                    {/*<p className={'font-12 pt-n150 pe-3'}>*/}
                                    {/*    Fan Community*/}
                                    {/*</p>*/}
                                </span>
                                {/*</div>*/}
                                {/*}*/}
                            </div>
                            <div className={'col-2'}>
                                <div className={'row'}>
                                    <div className={'col-12 pb-1'}>
                                        <div
                                            className={'d-flex align-items-center'}>
                                            {(tempSocial?.twitter !== null &&
                                                    tempSocial?.twitter !== '') &&
                                                <a style={{
                                                    color: '#000000',
                                                    cursor: 'pointer',
                                                }}
                                                   href={tempSocial.twitter}
                                                   target="_blank"
                                                   rel="noopener noreferrer">
                                                    <FaTwitter size={25}/>
                                                </a>
                                            }                            &nbsp;
                                            &nbsp;
                                            &nbsp;
                                            {(tempSocial?.facebook !== null &&
                                                    tempSocial?.facebook !== '') &&
                                                <a style={{
                                                    color: '#000000',
                                                    cursor: 'pointer',
                                                }}
                                                   href={tempSocial.facebook}
                                                   target="_blank"
                                                   rel="noopener noreferrer">
                                                    <FaFacebook size={25}/>
                                                </a>
                                            }                            &nbsp;
                                            &nbsp;
                                            &nbsp;
                                            {(tempSocial?.instagram !== null &&
                                                    tempSocial?.instagram !== '') &&
                                                <a style={{
                                                    color: '#000000',
                                                    cursor: 'pointer',
                                                }}
                                                   href={tempSocial.instagram}
                                                   target="_blank"
                                                   rel="noopener noreferrer">
                                                    <FaInstagram size={25}/>
                                                </a>
                                            }
                                        </div>

                                    </div>
                                    <div className={'col-12'}>
                                        <div
                                            className={'d-flex align-items-center'}>
                                            {(tempSocial?.twitter !== null &&
                                                    tempSocial?.twitter !== '') &&
                                                <a style={{
                                                    color: '#000000',
                                                    cursor: 'pointer',
                                                }}
                                                   href={tempSocial.ios}
                                                   target="_blank"
                                                   rel="noopener noreferrer">
                                                    <FaApple size={25}/>
                                                </a>
                                            }                            &nbsp;
                                            &nbsp;
                                            &nbsp;
                                            {(tempSocial?.facebook !== null &&
                                                    tempSocial?.facebook !== '') &&
                                                <a style={{
                                                    color: '#000000',
                                                    cursor: 'pointer',
                                                }}
                                                   href={tempSocial.android}
                                                   target="_blank"
                                                   rel="noopener noreferrer">
                                                    <img src={require(
                                                        '../../assets/images/v2/android-black.png')}
                                                         alt={'android-logo'}
                                                         style={{
                                                             height: '25px',
                                                             width: '21px',
                                                         }}/>
                                                </a>
                                            }                            &nbsp;
                                            &nbsp;
                                            &nbsp;
                                            {(tempSocial?.instagram !== null &&
                                                    tempSocial?.instagram !== '') &&
                                                <a style={{
                                                    color: '#000000',
                                                    cursor: 'pointer',
                                                }}
                                                   className={'ps-1'}
                                                   href={tempSocial.web}
                                                   target="_blank"
                                                   rel="noopener noreferrer">
                                                    <FaGlobe size={25}/>
                                                </a>
                                            }
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                        }                    </div>
                </div>
                <hr style={{opacity: 0.1, border: '1px solid  black'}}
                />
                <div>
                    {
                        isMobile ? renderMobileMenu() :
                            (
                                <div className={`d-flex flex-row  justify-content-start`}>
                                    <Link to={`/${sportName}/${data?.club || teamData?.slug || ''}`}
                                          className={`text-black pe-5  text-decoration-underline-fanzine
                                                          ${((location.pathname.includes(`/${sportName}`)
                                              && !location.pathname.includes(`/news`)
                                              && !location.pathname.includes(`/teams`)
                                              && !location.pathname.includes(`/matches`)
                                              && !location.pathname.includes(`/table`)
                                              && !location.pathname.includes(`/standings`)
                                              && !location.pathname.includes(`/video`)

                                          ) || location?.pathname === (teamData?.clubLink || data?.clubLink))
                                              ?
                                              'active font-weight-800' :
                                              ''}
                                            `}>
                                        Home
                                    </Link>
                                    {
                                        NAVBAR_ITEMS.news.includes(sportName) && !props.isClub &&
                                        (
                                            <Link
                                                to={props.isClub ? `/${sportName}/${data?.club}/news` : `/${sportName}/news`}
                                                className={`text-black pe-5 text-decoration-underline-fanzine ${
                                                    location.pathname.includes(
                                                        '/news') ?
                                                        'active font-weight-800' : ''}`}>
                                                {props.isClub ? `News & Social` : `News`}
                                            </Link>
                                        )
                                    }

                                    {
                                        NAVBAR_ITEMS.teams.includes(sportName) && !props.isClub &&
                                        (
                                            <Link to={`/${sportName}/teams`}
                                                  className={`text-black pe-5 text-decoration-underline-fanzine ${location.pathname.includes(
                                                      '/teams') ?
                                                      'active font-weight-800' :
                                                      ''}`}>
                                                Teams
                                            </Link>
                                        )
                                    }
                                    {!location.pathname.includes('women') &&
                                        NAVBAR_ITEMS.matches.includes(sportName) && (location.pathname.includes('football') || !props.isClub) &&
                                        (
                                            <Link to={
                                                `/${props.isClub
                                                    ? sportName + '/' + location?.pathname?.split('/')[2] + '/matches'
                                                    :
                                                    sportName + '/matches'}
                                                `}
                                                  className={`text-black pe-5 text-decoration-underline-fanzine ${location.pathname.includes(
                                                      '/matches') ?
                                                      'active font-weight-800' :
                                                      ''}`}>
                                                Matches
                                            </Link>
                                        )
                                    }
                                    {!location.pathname.includes('women') && NAVBAR_ITEMS.standings.includes(sportName) && (location.pathname.includes('football') || !props.isClub) &&
                                        <Link
                                            to={`/${props.isClub
                                                ? sportName + '/' + location?.pathname?.split('/')[2] + '/table'
                                                :
                                                sportName + '/table'}
                                                `}

                                            className={`text-black pe-5 text-decoration-underline-fanzine ${(location.pathname.includes(`/table`)) ?
                                                'active font-weight-800' :
                                                ''}`}>
                                            {sportName?.toLowerCase() ===
                                            'football' ? 'Table' : 'Standings'}
                                        </Link>
                                    }
                                    {!location.pathname.includes('women') && NAVBAR_ITEMS.videos.includes(sportName) && (location.pathname.includes('football') || !props.isClub) &&
                                        <Link
                                            to={`/${props.isClub
                                                ? sportName + '/' + location?.pathname?.split('/')[2] + '/video'
                                                :
                                                sportName + '/video'}
                                                `}

                                            className={`text-black pe-5 text-decoration-underline-fanzine ${(location.pathname.includes(`/video`)) ?
                                                'active font-weight-800' :
                                                ''}`}>
                                            {'Videos'}
                                        </Link>
                                    }
                                    {NAVBAR_ITEMS.shop.includes(sportName) && props?.isClub && !location.pathname.includes('women') &&
                                        <Link
                                            to={'/' + clubAltName + '/shop'}
                                            className={`text-black pe-5 text-decoration-underline-fanzine ${(location.pathname.includes(`/shop`)) ?
                                                'active font-weight-800' :
                                                ''}`}>
                                            {'Shop'}
                                        </Link>
                                    }
                                </div>
                            )
                    }
                </div>
            </div>

        );
    };

    const render = () => {
        return (
            <div
                className={`${isMobile ?
                    'w-100  align-items-center p-2' :
                    'ps-5'} bg-fanzine-dark d-flex fanzine-football-nav`}
                style={{
                    marginTop: isMobile ? '-25px' : '',
                    height: isMobile ? props?.isClub ? '7em' : '3.5em' : !props?.isClub ? '8.5em' : '9em',
                    position: isMobile ? 'fixed' : 'relative',
                    zIndex: isMobile ? 9999 : 1027,
                }}
                id={'subNavbarBg'}
            >
                <div
                    className={`${isMobile ? '' : 'ps-5'} container`}>
                    <div className={`${isMobile ? '' : 'ps-5'} row ${isMobile ?
                        'pt-2' :
                        'ms-3'}`}
                         style={{
                             // zIndex: 99999,
                             zIndex: 1027,
                             position: 'relative',
                         }}
                         id={'subNavbar'}
                    >
                        {isMobile ? renderMobileMenu() : renderBigView()}
                    </div>
                </div>
            </div>
        );
    };

    return render();
};
