import React, {useEffect, useState} from 'react';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import '../../assets/styles/customContainer.css';
import {useLocation, useParams, useSearchParams} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {getCountryForTimezone} from 'countries-and-timezones';
import {get} from '../../Axios/get';
import {MLB} from '../../Axios/EndPoints';
import {MLB_MATCH_STATES, SCREEN_SIZE} from '../../Configs/Constants';
import CalendarContainer from '../../Components/Calendar/CalendarContainer';
import Matches from '../../Components/Layout/Football/Matches';
import {SiteMetaHelmet} from '../../Components/SiteMetaHelmet';
import Loader from '../../Components/Loader/Loader';
import {SubNavbar} from '../../Components/Navbar/SubNavbar';
import GoogleAd from '../../Components/Ads/GoogleAd';
import RightNewsAds from '../../Components/Layout/News/RightNewsAds';
import {GenderNavbar} from '../../Components/Navbar/GenderNavbar';

const MLBMatches = (props) => {
  let params = useParams();
  let store = useSelector((state) => state);
  // console.log("store in all football =", store);
  let queryParams = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  let [apiPage, setApiPage] = useState(1);
  let [loading, setLoading] = useState(true);
  let [isMobile, setIsMobile] = useState(false);
  let [matchData, setMatchData] = useState(null);
  let [matchDataLoading, setMatchDataLoading] = useState(true);
  let [latestNews, setLatestNews] = useState([]);
  let [selectedLeague, setSelectedLeague] = useState(0);
  let [selectedDate, setSelectedDate] = useState(
      moment(Date.now()).format('YYYY-MM-DD'));
  let [selectedDatePicker, setSelectedDatePicker] = useState();
  let [liveMatchData, setLiveMatchData] = useState(null);
  let [isMLBMatches, setIsMLBMatches] = useState(
      true);
  let [leagueMatchesGroupedByDate, setLeagueMatchesGroupedByDate] = useState(
      null);
  let [selectedLeagueDate, setSelectedLeagueData] = useState(null);
  let [isLeagueSelected, setIsLeagueSelected] = useState(false);
  const [conference, setConference] = useState(null);

  const handleScroll = (e) => {
    const pageBottom =
        document.body.scrollHeight - document.documentElement.clientHeight -
        10 <=
        document.documentElement.scrollTop;
    if (pageBottom && document.body.scrollHeight > 1000) {
      setApiPage((prevState) => {
        return prevState + 1;
      });
    }
  };

  const fetchNews = (isNew) => {
    let searchParams = queryParams.search;

    if (!searchParams) {
      searchParams = '?main_site=1';
    }

    let page = `&page=${apiPage}`;
    let limit = `&limit=48`;

    get(MLB.NewsEndPoint + searchParams + limit + page).then((responseNews) => {
          let tempNewsData = [];
          responseNews.data.map((data) => {
            let news = {
              news_id: data.news_id,
              slug: data.slug,
              news_image: data.image,
              news_title: data.title,
              news_url: data.url,
              news_time: moment.tz(data.datetime, 'Europe/London')
                  .local()
                  .startOf('seconds')
                  .fromNow(),
              news_date: moment(data.datetime).format('D MMM YYYY, H:mm'),
              news_publisher: {
                publisher_name: data.publisher.name,
                publisher_icon: data.publisher.icon,
              },
              is_snack: data.is_snack,
              main_team: data.main_team,
              type: 'news',
            };
            tempNewsData.push(news);
          });
          if (isNew) {
            setLatestNews(tempNewsData);
          } else {
            setLatestNews([...latestNews, ...tempNewsData]);
          }
          setLoading(false);
        },
    ).catch((error) => console.log(error));
  };

  const fetchFixtures = () => {
    let params = queryParams.search;
    if (!params) {
      params = `?date=${selectedDate ||
      moment(Date.now()).format('YYYY-MM-DD')}`;
    }
    let timezone = getCountryForTimezone(
        Intl.DateTimeFormat().resolvedOptions().timeZone);
    params = params + '&by=conference';

    let url = MLB.MatchesEndPoint +
        params;

    get(url).then((response) => {
      // eslint-disable-next-line array-callback-return
      let tempData = [];

      if (conference === null) {
        tempData = response.data;
      } else {
        tempData = response.data.filter((value) => {
          return value.league_name === conference;
        });
      }

      for (let i = 0; i < tempData.length; i++) {
        if (tempData[i].new_web_order == 0) {
          tempData[i].new_web_order = '100';
        }
        if (tempData[i].league_id == 0) {
          tempData[i].new_web_order = '1000';
        }
        for (let j = 0; j < tempData[i].matches.length; j++) {
          tempData[i].matches[j].home_team = tempData[i].matches[j].local_team;
          tempData[i].matches[j].guest_team = tempData[i].matches[j].visitor_team;
          tempData[i].matches[j].state = tempData[i].matches[j].state_id;
          tempData[i].matches[j].total = `${tempData[i].matches[j].local_team.total_score} - ${tempData[i].matches[j].visitor_team.total_score}`;
        }
      }
      tempData.sort(function(a, b) {
        return a.new_web_order - b.new_web_order;
      });
      setMatchData(tempData);
      setMatchDataLoading(false);
      setLoading(false);
    }).catch((err) => {
      console.error(err);
    });
  };

  const selectedDay = (e) => {
    let tmpDate = moment(e).format('YYYY-MM-DD');
    setSelectedDate(tmpDate);
    setSelectedDatePicker(e);
    console.log('tmpdate =', tmpDate);
  };

  useEffect(() => {
    setSelectedDate(
        searchParams.get('date') || moment(new Date()).format('YYYY-MM-DD'));
    setLoading(true);
    window.addEventListener('scroll', handleScroll);
    setApiPage(1);
    fetchNews(true);
    searchParams.get('league_id') == null && fetchFixtures();
    return () => window.removeEventListener('scroll', handleScroll);
  }, [queryParams.search, selectedLeague, searchParams, conference]);

  useEffect(() => {
    if (window.innerWidth <= SCREEN_SIZE.MOBILE) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, [window.innerWidth, isMobile]);

  // fetching Live Matches After 10s
  useEffect(() => {
  }, []);

  const renderNews = (news, sport, isMobile) => {

    return (
        <div className="bg-background overflow-hidden">
          <div className={`${!isMobile ? 'container' : 'w-100'}`}>
            <div
                className={`${!isMobile ? 'row' : 'mb-5 '}`}
                style={{marginLeft: '0%', marginRight: '0%'}}
            >
              <div
                  className={`col-xl-8 col-lg-8 col-md-8 col-sm-12 col-xs-12 ${isMobile ?
                      '' :
                      ' border-radius-15px'} h-100`}>
                <div className={`${isMobile ? '' : 'mb-5'}`}>
                  <div className={'customContainer'}>
                    <div className={`row ${isMobile && 'ms-auto me-auto'} `}>
                      {leagueMatchesGroupedByDate == null &&
                          <CalendarContainer selectedDay={selectedDay}
                                             setSelectedDate={setSelectedDate}
                                             selectedDate={selectedDate}
                                             isMobile={isMobile}
                                             sport={'mlb'}
                          />
                      }
                      <GenderNavbar setParams={setConference}
                                    isMobile={isMobile}/>
                      <Matches
                          isMobile={isMobile}
                          setSelectedLeagueData={setSelectedLeagueData}
                          selectedLeagueDate={selectedLeagueDate}
                          isLeagueSelected={isLeagueSelected}
                          setIsLeagueSelected={setIsLeagueSelected}
                          setLeagueMatchesGroupedByDate={setLeagueMatchesGroupedByDate}
                          leagueMatchesGroupedByDate={leagueMatchesGroupedByDate}
                          selectedDate={selectedDate}
                          liveMatchData={liveMatchData}
                          matchData={matchData}
                          store={store}
                          matchStates={MLB_MATCH_STATES}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <RightNewsAds sportEndpoint={MLB.NewsEndPoint} isMobile={isMobile}
                            limit={25}/>
            </div>
          </div>
        </div>
    );
  };

  const render = () => {
    return (
        <>
          <SiteMetaHelmet sport={'mlb'} urlEndpoint={'mlb'}/>
          <Loader loading={loading}/>
          <SubNavbar isMobile={isMobile} items={['matches']}/>
          <div className="d-flex justify-content-center align-items-center h1">
            {!isMobile && <GoogleAd id={'3349434796'}/>}
          </div>
          {renderNews(latestNews, 'mlb', isMobile)}
        </>
    );
  };

  return render();
};

MLBMatches.propTypes = {
  snack: PropTypes.bool,
};

export default MLBMatches;
