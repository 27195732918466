import {Form} from "react-bootstrap";
import * as Icon from "react-bootstrap-icons";
import React from 'react';
import {GetConvertedPrice} from '../../CustomFunctions/getConvertedPrice';

const NewShoppingItems = (props)=>{
	const renderShoppingItems = (data,store) => {
		return(
			<div className={"d-flex justify-content-between ms-4 flex-grow-1"}>
				<div className={"d-flex flex-column justify-content-between"}>
					<span className={"sfProDisplayFont font-16 text-dark fw-bold"}>{data?.title || 'Premium Toffees Hoodie'}</span>
					{/*<span className={"sfProDisplayFont font-16 text-dark"}>Mens</span>*/}
					<div className={"d-flex align-items-center"}>
						<span className={"sfProDisplayFont font-16 text-dark"}></span>
						{/*<select aria-label="Default select example" className={"border-0"} style={{width: 80, backgroundColor: "#F1F1F1"}}>*/}
						{/*	<option value="1" className={"border-0"} >XL</option>*/}
						{/*	<option value="2">L</option>*/}
						{/*	<option value="3">M</option>*/}
						{/*</select>*/}
						{data?.variant?.selectedOptions?.map((option) => (
								<div style={{color: "black", fontSize: "15px"}}>
									{option.name === 'Color'? 'Colour' :option.name}:&nbsp;{option.value} &nbsp;&nbsp;&nbsp;
								</div>
						))}

					</div>
					<div className={"d-flex align-items-center"}>
						<span className={"sfProDisplayFont font-16 text-dark"}>Quantity: {data?.quantity}</span>

						{/*<Form.Select aria-label="Default select example" className={"border-0"} style={{width: 80, backgroundColor: "#F1F1F1"}}>*/}
						{/*	<option value="1" className={"border-0"} >1</option>*/}
						{/*	<option value="2">2</option>*/}
						{/*	<option value="3">3</option>*/}
						{/*</Form.Select>*/}
					</div>
				</div>
				<div className={"d-flex flex-column justify-content-between"}>
					<span className={"align-self-end sfProDisplayFont font-16 text-dark fw-bold"}>
						{/*{GetConvertedPrice(data?.variant?.price, store)}*/}
						£{data?.variant?.price?.amount}
					</span>
					<div onClick={() => props?.removeItem(data.variant.id)}	className={"d-flex cursor-pointer align-items-center"}>
						<span className={"sfProDisplayFont font-16 text-dark me-2"}>Remove</span>
						<Icon.TrashFill/>
					</div>
				</div>
			</div>
		)
	}

	return(
		<>
			<div key={props.index} className={"d-flex flex-wrap mb-3 p-3"}>
				<div>
					<img
						src={props?.item?.node?.variant?.image?.src || require("../../assets/images/messi.webp")}
						style={{
							width: 146,
							height: 146,
							objectFit: "cover"
						}}
						alt={"shop items"}
					/>
				</div>
				{renderShoppingItems(props?.item?.node,props.store)}
			</div>
			<div className={"mb-4 mt-4"}>
				<hr/>
			</div>
		</>
	)
}

export default NewShoppingItems
