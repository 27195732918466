import React, {useEffect, useState} from 'react';
import Card from 'react-bootstrap/Card';
import '../../../Components/Cards/styles/NavbarCard.css';
import '../../../assets/styles/styles.css';
import YoutubeEmbed from '../../../Components/YoutubeEmbed';
import PropTypes from 'prop-types';
import HTMLEllipsis from 'react-lines-ellipsis/lib/html.modern';
import {getHostname} from '../../../CustomFunctions/getHostname';
import {FONT_COLOR} from '../../../Configs/Targets';
import {setSingleVideo} from '../../../redux/actions/MiscActions';
import {useDispatch} from 'react-redux';
import {Link, useNavigate} from 'react-router-dom';
import moment from "moment";

const VideoCardMoreStories = (props) => {

  const {isMobile,setSelectedVideo,selectedVideo} = props;
  let dispatch = useDispatch();

  let [isOpen, setIsOpen] = useState(false);
  let [stopMain, setStopMain] = useState(true);
  let [videoData, setVideoData] = useState(props.video);

  const navigate = useNavigate()
  const hostName = window.location.hostname;
  const domainObject = getHostname(hostName);
  const target = domainObject.host;
  const textColor = props?.league?.font_color ? props?.league?.font_color :  'text-black';

  const openModal = () => {
    setIsOpen(true);
    setStopMain(false);
  };


  const addDefaultSrc = (ev) => {
    ev.target.src = require('../../../assets/images/default_publisher_icon.png');
  };

  useEffect(() => {
    setVideoData(props.video);
  }, [props.video]);

  const render = () => {
    let extraClass = props.extraClass;
    let videoHeight = props.videoHeight;
    let isMain = typeof props.main !== 'undefined';
    // let isMobile = typeof props.isMobile !== 'undefined';
    let titleClass = isMain && !isMobile ? 'h3' : 'small';
    let bottomClass = isMain && !isMobile ? 'h5' : 'small';
    let publisherImageHeight = isMain && props.isMobile ? 30 : 15;
    let titleWidth = isMobile && isMain ? 'w-100' : 'w-100';
    if (!videoData) {
      return (<></>);
    }

    // const handleClick = () => {
    //   // console.log('videoData =', videoData);
    //   dispatch(setSingleVideo(videoData));
    //   window.location.replace(`/${props.sport}/video/${videoData?.id}`);
    // };

    const handleClick = () => {
      if(setSelectedVideo && !isMobile)
      {
        setSelectedVideo(videoData);
        window.scrollTo(0, 0);
      }
      else
      {
        navigate(`/${props.sport || 'football'}/video/${videoData?.id}`);
      }
    };

    return (
        <div
            onClick={handleClick} className={`col shadow  text-decoration-none text-black cursor-pointer ${extraClass}`}>
          <Card
              style={{
                width: props.isFanzineAllFootball && (isMobile ? '170px' : '360px'),
                // height: videoHeight
              }}
              className="col border-0 bg-transparent">
            {
              isMain ?
                  <YoutubeEmbed videoUrl={videoData.url}
                                videoHeight={videoHeight} stopVideo={stopMain}
                                autoplay={videoData.autoplay}/> :
                  <img src={videoData.preview_image}
                       style={{objectFit: props.isFanzineAllFootball && 'cover', height: videoHeight}}
                       alt={'Fanzine'}/>
            }

            {
                !isMain &&
                (<Card.ImgOverlay
                    className={`d-flex align-items-start flex-column justify-content-end ${props.isFanzineAllFootball ?
                        'video-overlay-slider' :
                        'news-overlay'} `}
                    onClick={openModal}>
                  <Card.Text
                      className={`text-white ${props?.isFanzineAllFootball ?
                          (isMobile ? 'robotoFont font-12' : 'robotoCondensedBold font-18') :
                          'robotoCondensedBold'}`}>
                    {/*Dont require atm*/}

                    {/*{*/}
                    {/*    !props?.isFanzineAllFootball &&*/}
                    {/*    <> <img onError={addDefaultSrc}*/}
                    {/*            src={videoData.publisher?.icon} alt="News"*/}
                    {/*            height={20}/> &nbsp; </>*/}
                    {/*}*/}
                    {/*{props?.isFanzineAllFootball ?*/}
                    {/*    (!isMobile ?*/}
                    {/*        <HTMLEllipsis*/}
                    {/*            unsafeHTML={videoData?.title}*/}
                    {/*            maxLine="3"*/}
                    {/*            ellipsis="..."*/}
                    {/*            basedOn="letters"*/}
                    {/*            style={{*/}
                    {/*              height: '2em',*/}
                    {/*              color: props?.league?.font_color ? props?.league?.font_color : FONT_COLOR[target],*/}
                    {/*            }}*/}
                    {/*            className={'robotoFont font-12'}*/}
                    {/*        />*/}
                    {/*        : '') :*/}
                    {/*    videoData.publisher?.name*/}
                    {/*}*/}
                  </Card.Text>
                </Card.ImgOverlay>)
            }
          </Card>
          <div className="row p-2">
            <div className={`justify-content-between ${isMain ?
                'col-9' :
                'col-12'}`}>
              <div>
                {props?.isFanzineAllFootball &&
                    <HTMLEllipsis
                        unsafeHTML={videoData?.title}
                        maxLine="2"
                        ellipsis="..."
                        basedOn="letters"
                        style={{
                          height: '3.5em',
                          color: props?.league?.font_color ? props?.league?.font_color : FONT_COLOR[target],
                          className: `roboto-medium ${textColor}`,
                          marginBottom: '1em',
                          fontWeight:'700',
                          fontsize: '13px'
                        }}
                        className={`robotoFont text-start text-wrap ${textColor}`}
                    />}
              </div>
              <span
                  className={`${titleClass} ${titleWidth} d-flex justify-content-between`}
                  /*dangerouslySetInnerHTML={{__html: videoData.title}}*/>
                            <HTMLEllipsis
                                unsafeHTML={props?.isFanzineAllFootball ?
                                    videoData.publisher?.name :
                                    videoData?.title}
                                maxLine="3"
                                ellipsis="..."
                                basedOn="letters"
                                style={{
                                  opacity:0.5,
                                  height: '2em',
                                  color: props?.league?.font_color ? props?.league?.font_color : FONT_COLOR[target],
                                  className: `robotoFont ${textColor}`,
                                }}
                                className={`${isMobile ? 'robotoFont font-12' : 'robotoFont font-14'} ${textColor}`}
                            />
                {
                    props.isFanzineAllFootball &&
                    <span
                        style={{
                          opacity:0.5,
                          color: props?.league?.font_color ? props?.league?.font_color : FONT_COLOR[target],
                        }}
                        className={`${isMobile ? 'robotoFont font-12' : 'robotoFont font-14'} ${textColor}`}>
                                    {
                                      moment.tz(videoData?.published_at.date, 'Europe/London').local().startOf('seconds').fromNow()
                                    }
                                </span>
                }
                        </span>
              <span className={`text-muted robotoFont ${bottomClass}`}>
                            {
                              isMain ?
                                  (
                                      <>
                                        <div className={`row`}>
                                          <div
                                              className={`col-9 d-flex justify-content-start`}>
                                                    <span
                                                        className={`d-flex align-items-center`}>
                                                        <img
                                                            onError={addDefaultSrc}
                                                            src={videoData.publisher.icon}
                                                            alt="News"
                                                            height={publisherImageHeight}/>
                                                    </span>
                                            &nbsp; <span
                                              className={`d-flex align-items-center robotoFont font-16 ${textColor}`}>{videoData.publisher.name}</span>
                                          </div>
                                        </div>
                                      </>
                                  ) :
                                  (
                                      <span className={`${isMobile ?
                                          'float-start' :
                                          'float-end'} text-muted font-16 font-weight-800 ${isMobile ? 'robotoFont' : 'robotoCondensedBold'} ${textColor}`}>

                                        </span>
                                  )
                            }

                        </span>
            </div>

            {
                isMain && (
                    <div className={`col-3 d-flex justify-content-end p-2`}>
                                <span
                                    className={`d-flex align-items-start robotoCondensedBold font-16 font-weight-800 ${textColor}`}>
                                    {
                                      moment.tz(videoData?.published_at.date, 'Europe/London').local().startOf('seconds').fromNow()
                                    }
                                </span>
                    </div>
                )
            }
          </div>
        </div>
    );
  };

  return render();
};

VideoCardMoreStories.propTypes = {
  videoHeight: PropTypes.string.isRequired,
  main: PropTypes.bool,
  extraClass: PropTypes.string.isRequired,
  isMobile: PropTypes.bool,
};

export default VideoCardMoreStories;
