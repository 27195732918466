import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheckCircle, faTimes} from "@fortawesome/free-solid-svg-icons";
import {COLORS, FONT_COLOR, TEXT_COLORS} from "../../Configs/Targets";
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {getHostname} from '../../CustomFunctions/getHostname';
import {NavLink, useLocation} from 'react-router-dom';
import {graphql} from "../../Axios/graphql";
import {ShopifyGraphQlQueries} from "../../Configs/ShopifyGraphQlQueries";
import {SHOPIFY} from "../../Configs/Constants";
import {setCartItems, setGrandTotal} from "../../redux/actions/CartActions";
import {GetConvertedPrice} from '../../CustomFunctions/getConvertedPrice';

const NewMiniCart = (props) => {
    const {setShowMiniCart, showMiniCart} = props;
    const location = useLocation();
    const store = useSelector(state => state)
    const hostName = window.location.hostname;
    const domainObject = getHostname(hostName);
    const target = domainObject.host;
    const fontColor = FONT_COLOR[target];
    const textColor = TEXT_COLORS[target];

    const cartStore = useSelector(state => state.cart);
    const [cartData, setCartData] = useState(null);


    useEffect(() => {
        console.log('cartStore =', cartStore, showMiniCart)

        setCartData(cartStore);
    }, [cartStore]);

    return (
        <div
            style={{
                width: "max-content",
                height: "auto",
                backgroundColor: "#ffffff",
                top: "50px",
                marginLeft: '65%',
            }}
            className={"px-3 position-absolute me-auto"}
        >
            <div className={"d-flex justify-content-between align-items-center mt-3"}>
                <div className={"d-flex align-items-center"}>
                    <FontAwesomeIcon icon={faCheckCircle} color={"#0fb958"} size={'1x'} className={'align-middle justify-content-center me-2'}/>
                    <span className={"text-dark"}>Added to Bag</span>
                </div>
                <FontAwesomeIcon onClick={() => setShowMiniCart(false)} icon={faTimes} color={"#171717"} size={'1x'} className={'cursor-pointer align-middle justify-content-center'}/>
            </div>

            {
                cartData !== null && cartData?.cartItems?.map((value, index) => {
                    return (
                        <div className={"d-flex mt-4"} style={{zIndex: 100, opacity: 1}}>
                            <img
                                alt={"shopping-cart-item"}
                                src={value?.variant?.image?.src || require("../../assets/images/messi.webp")}
                                style={{
                                    width: "104px",
                                    objectFit: "cover",
                                }}
                            />
                            <div className={"d-flex flex-column justify-content-between ms-3"}>
                                <span className={"sfProDisplayFont font-16 text-dark fw-bold align-self-start"}>{value?.product_name}</span>
                                <span className={"sfProDisplayFont font-16 text-dark align-self-start"}>Quantity: {value?.quantity}</span>
                                <div className={"d-flex align-items-center"}>
                                    <span className={"sfProDisplayFont font-16 text-dark me-2 align-self-start"}>Size</span>
                                    <span>{value?.size}</span>
                                </div>
                                <div className={"d-flex align-items-center"}>
                                    <span className={"sfProDisplayFont font-16 text-dark fw-bold"}>
                                      {/*{GetConvertedPrice(value?.variant?.price,store)}*/}
                                      £{value?.variant?.price}
                                    </span>
                                </div>
                            </div>
                        </div>
                    )
                })
            }
            <div className={"d-flex bg-white justify-content-between w-100 mt-5 mb-2"}>
                <NavLink to={target === 'fanzine' ? `/${location.pathname.split('/')[1]}/${location.pathname.split('/')[2]}/checkout` : '/checkout'} className={"text-decoration-none text-center d-flex justify-content-center align-items-center text-dark sfProDisplayFont w-50 me-4"}
                         style={{
                             borderRadius: "6px",
                             backgroundColor: "#c0c0c0"
                         }}
                >
                    View Bag ({cartData?.cartItems?.length || 0})
                </NavLink>

                <button className={`py-2 sfProDisplayFont w-50 ${['theyellows', 'wearewolves'].includes(target) ? textColor : fontColor}`}
                        style={{
                            border: "1px solid #c0c0c0",
                            borderRadius: "6px",
                            backgroundColor: COLORS[target],
                            color: ['theyellows', 'wearewolves'].includes(target) ? textColor : fontColor
                        }}
                >
                    Checkout
                </button>
            </div>
        </div>
    )
}

export default NewMiniCart;
