import PreviewNews from './PreviewNews';
import {useParams} from 'react-router-dom';
import {useState} from 'react';
import ItemLoader from '../../Loader/ItemLoader';
import {useSelector} from 'react-redux';
import moment from 'moment';

const SingleFootballMatchContentH2H = (props) => {
  let params = useParams()
  let store = useSelector((state) => state);
  const [loading, setLoading] = useState(false)
  const {addDefaultSrc, isMobile} = props
  const isCurrent = props.currentActive === 'h2h';

  let generalResult;
  let totalMatches = 0;
  let matches;
  let matchData = [];
  if (store?.football?.singleFootballMatch !== []) {
    matchData = store?.football?.singleFootballMatch;
  }
  // const fetch
  if (matchData) {
    matches = matchData?.h2h?.matches;
    generalResult = matchData?.h2h?.general_result;
    totalMatches = generalResult?.draws_count + generalResult?.local_team_wins_count + generalResult?.visitor_team_wins_count;
  }


  const renderPreviousMatches = () => {

    return (
        <div className={`w-100`}>
          <div className={'row'}>
            <div className={'col-12 sfProDisplayFontLight font-22 text-center mb-3'}>
              Previous Matches ({totalMatches})
            </div>
            <div className={'d-flex flex-row'}>
              {generalResult?.local_team_wins_count !== 0 && <div
                  className={`m-0 text-center`}
                  style={{
                    borderBottom: '6px solid #0d0d0d',
                    width: `${Math.round((generalResult?.local_team_wins_count / totalMatches) * 100)}%`
                  }}></div>
              }
              {generalResult?.draws_count !== 0 &&
                  <div
                      className={`m-0 text-center`}
                      style={{
                        borderBottom: '6px solid #c6c6c6',
                        width: `${Math.round((generalResult?.draws_count / totalMatches) * 100)}%`
                      }}></div>}
              {
                  generalResult?.visitor_team_wins_count !== 0 &&
                  <div
                      className={`m-0 text-center`}
                      style={{
                        borderBottom: '6px solid #d00027',
                        width: `${Math.round((generalResult?.visitor_team_wins_count / totalMatches) * 100)}%`
                      }}></div>}
            </div>
          </div>
        </div>
    )

  }

  const renderWinPercentage = () => {
    return (
        <div className={'container-fluid mt-3'}>
          <div className={'d-flex justify-content-between align-items-center'}>
            <div
                className={`row`}
            >
              <div
                  className={`col bg-dark text-center text-white sfProDisplayFontBold font-18 d-flex align-items-center`}>
                {generalResult?.local_team_wins_count}
              </div>
              <div className={'col ms-2'}>
                <div className={`row text-center sfProDisplayFontBold font-14`}>
                  Win
                </div>
                <div className={`row text-center sfProDisplayFontBold font-12`}
                     style={{color: '#878787'}}
                >
                  {Math.round((generalResult?.local_team_wins_count / totalMatches) * 100) + '%'}
                </div>
              </div>
            </div>
            <div
                className={`row`}
            >
              <div
                  style={{background: '#c6c6c6'}}
                  className={'col text-center text-black sfProDisplayFontBold font-18 d-flex align-items-center'}>
                {generalResult?.draws_count}
              </div>
              <div className={'col ms-2'}>
                <div className={`row text-center sfProDisplayFontBold font-14`}>
                  Draws
                </div>
                <div className={`row text-center sfProDisplayFontBold font-12`}
                     style={{color: '#878787'}}
                >
                  {Math.round((generalResult?.draws_count / totalMatches) * 100) + '%'}
                </div>
              </div>

            </div>
            <div
                className={`row`}
            >
              <div
                  className={`col bg-danger text-center text-white sfProDisplayFontBold font-18 d-flex align-items-center`}>
                {generalResult?.visitor_team_wins_count}
              </div>
              <div className={'col ms-2'}>
                <div className={`row text-center sfProDisplayFontBold font-14`}>
                  Win
                </div>
                <div className={`row text-center sfProDisplayFontBold font-12`}
                     style={{color: '#878787'}}
                >
                  {Math.round((generalResult?.visitor_team_wins_count / totalMatches) * 100) + '%'}
                </div>
              </div>
            </div>
          </div>
        </div>
    )
  }

  const renderH2HMatches = () => {
    return (
        <div>
          <div className={'row mt-3'}>
            {/*<p className={'sfProDisplayFontLight font-22 text-center'}>*/}
            {/*    H2H*/}
            {/*</p>*/}
          </div>
          <div className={'row custom-margin-horizontal-n5'}>
            <div className={'col'} style={{borderRight: '1px solid #f1f1f1'}}>
              {
                  matches &&
                  matches?.map((value, index) => (
                      <div
                          className={`row  ${index === 0 ? 'mt-1' : 'mt-4'} pb-3 center-items text-decoration-none text-black`}
                          key={index}
                          style={{cursor: 'pointer', borderBottom: '1px solid #ededed'}}
                      >
                        <div
                            className={`${isMobile ? 'font-12' : 'font-14'} justify-content-center sfProDisplayFont row `}
                            style={{color: '#c6c6c6'}}>
                          {value?.competition}
                        </div>
                        <div
                            className={value?.home_team?.name > 20 ? `col text-end fixed d-flex align-items-center sfProDisplayFontBold ${isMobile ? 'font-12' : 'font-14'}`
                                :
                                `${isMobile ? 'font-10' : 'font-14'} justify-content-center sfProDisplayFontBold col text-end fixed`}
                            style={{color: '#252525'}}>
                          {value?.home_team?.name}
                          <img
                              className={`${isMobile ? 'ms-1' : 'ms-2'}`}
                              height={isMobile ? 30 : 40}
                              width={'auto'}
                              alt={"team badge"}
                              src={value?.home_team?.icon}
                              onError={addDefaultSrc}
                          ></img>
                        </div>

                        <div
                            className={`col-2 text-center ${isMobile ? 'font-11' : 'font-14'} sfProDisplayFontBold`}>
                          <div className={'row'}>
                            <div
                                className={'col-12 justify-content-center align-items-center'}>
                              {value?.total}
                            </div>
                          </div>
                        </div>

                        <div
                            className={value?.home_team?.name > 20 ? `col text-end fixed d-flex align-items-center sfProDisplayFontBold ${isMobile ? 'font-12' : 'font-14'}`
                                : `${isMobile ? 'font-10' : 'font-14'} justify-content-start sfProDisplayFontBold col text-end fixed`}
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              color: '#252525',
                              marginRight: '4px'
                            }}>
                          <img
                              className={`${isMobile ? 'me-1' : 'me-2'}`}
                              height={isMobile ? 30 : 40}
                              alt={"team badge"}
                              src={value?.guest_team?.icon}
                              onError={addDefaultSrc}
                          ></img>
                          {value?.guest_team?.name}
                        </div>
                        <div
                            className={`${isMobile ? 'font-12' : 'font-14'} justify-content-center sfProDisplayFont row `}
                            style={{color: '#c6c6c6'}}>
                          {moment(value?.date).format('ddd DD MMM')}
                        </div>

                      </div>
                  ))
              }
            </div>
          </div>
        </div>
    )
  }


  return (
      <div>
        {loading ? <ItemLoader loading={loading}/> :
            <div>
              <div className={'bg-white p-3 rounded-custom mb-5'}>
                {matches !== undefined && renderPreviousMatches()}
                {matches !== undefined && renderWinPercentage()}
                {matches !== undefined && renderH2HMatches()}
              </div>
              <PreviewNews isCurrent={isCurrent}/>
            </div>
        }
      </div>
  )

}

export default SingleFootballMatchContentH2H;
