import {SiteMetaHelmet} from "../../Components/SiteMetaHelmet";

export const MLBContent = () => {
    return (
        <div className={`container mt-5`}>
            <SiteMetaHelmet sport={'MLB'} urlEndpoint={'mlb'}/>
            <h1 className={`bebasFont`}>
                Latest MLB News and Headlines in the UK
            </h1>
            <div className={`sfProDisplayFont mt-3`}>
                Tune in to Fanzine for the latest MLB headlines and sports news. From off-season rumours and signings to the World Series, we bring you the latest MLB news as soon as it happens. From
                the latest team rostrums and changes in managers to which players are hot and which are not, at Fanzine we share the Major League Baseball sports news that fans want to read.
            </div>
            <div className={`sfProDisplayFont mt-3`}>
                From the Dodgers and the Astros to the Yankees and the Phillies, at Fanzine we bring you the best MLB headlines and news stories. Major League Baseball is split into two divisions the
                American League and the National League. At Fanzine we collate the latest MLB news from a range of different sources to bring you the stories that matter the most to you.
            </div>
            <div className={`bebasFont h3 mt-5`}>
                24/7 MLB headlines in the UK
            </div>
            <div className={`sfProDisplayFont mt-3`}>
                We’ll bring you news about who’s hot such as Shohei Ohtani and Mike Trout, as well as the biggest moves and the upcoming talent. Our news feed is updated 24 hours a day so as soon as a
                new story hits the press, you’ll be able to read about it here at Fanzine. Keep up to date with MLB sports news by heading to our online platform.
            </div>
            <div className={`sfProDisplayFont mt-3`}>
                Browse the biggest MLB headlines below and read up on the latest MLB news from the top news sites in the UK and USA. Whether you are a Yankees fan, a Phillies fan or Indians fan, at
                Fanzine we bring you the newest stories in the world of Major League Baseball.
            </div>
            <div className={`bebasFont h3 mt-5`}>
                The ultimate online UK MLB platform
            </div>
            <div className={`sfProDisplayFont mt-3`}>
                We go beyond the game and find the MLB stories that matter. We aren’t here to respin the hundreds of latest MLB headlines you’ve already read. We deliver the information you need to be
                ahead of the curve, before, during and after kick-off.
            </div>
            <div className={`sfProDisplayFont mt-3`}>
                As the ultimate online UK MLB platform, our mission is to bring fans everything they want when they want it when it comes to the latest MLB news headlines, updates, rumours, and
                previews. Our content is written and edited by professionals with the aim of bringing you the latest MLB headlines and the best comment and analysis on the stories that matter.
            </div>
            <div className={`sfProDisplayFont mt-3 mb-5`}>
                We provide daily updates to our readers and tend to stay on top of the trends when it comes to
                mlb news. Looking for footie stats, scores, analysis, and more? Then look no further. Fanzine is
                the website where you can find all recent news in mlb from the UK and all around the world.
            </div>
        </div>
    )
}
