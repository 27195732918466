import {TimeZoneActionTypes} from "../constants/TimeZoneActionTypes";

const initialState = {
    timezone: [],
}

export const TimeZoneReducer = (state = initialState, action) => {
  switch (action.type) {
      case TimeZoneActionTypes.SET_TIME_ZONE:
          return {...state, timezone: action.payload};
      default:
          return state;
  }
}