import PropTypes from 'prop-types';
import MobileNewsMenuItem from './MobileNewsMenuItem';
import {IoAmericanFootballOutline, IoBasketballOutline} from 'react-icons/io5';
import {GiBaseballBat, GiCricketBat} from 'react-icons/gi';
import {NAVBAR_ITEMS} from '../../Configs/Constants';
import {Link, useLocation} from 'react-router-dom';
import {FaFacebook, FaInstagram, FaTwitter} from 'react-icons/fa';
import {useSelector} from 'react-redux';
import {useState} from 'react';

const FanzineShopNavBar = (props) => {

  const {isMobile, data, teamData, preparedStoreData, clubName, leagueNameFromParams} = props;

  const location = useLocation();
  const store = useSelector(state => state);

  const [currentActive, setCurrentActive] = useState('');
  const [clubIcon, setClubIcon] = useState(null);
  const [sportName, setSportName] = useState((location.pathname.split('/'))[1]);

  const renderMobileMenu = () => {
    let defaultImage = '';
    let sportNameHeader = '';
    switch (location.pathname.split('/')[1]) {
      case 'football':
        defaultImage = require('../../assets/images/default-league.png');
        sportNameHeader = 'Football';
        break;
      case 'cricket':
        sportNameHeader = 'Cricket';
        break;
      case 'nfl':
        sportNameHeader = 'NFL';

        break;
      case 'nba':
        sportNameHeader = 'NBA';

        break;
      case 'mlb':
        sportNameHeader = 'MLB';

        break;
      default:
        defaultImage = require('../../assets/images/default-league.png');
        sportNameHeader = 'Football';

        break;
    }

    return (
        <div className={`slider-mobile-news-container`}>
          {
              (!data && !teamData?.icon) &&
              <MobileNewsMenuItem
                  inactiveFontColor={'#000000'} isMobile={isMobile}
                  setCurrentActive={setCurrentActive} title={''}
                  uri={teamData?.clubLink || `/${sportName}`}
                  // selected={location.pathname === '/football'}
                  selected={false}
              >
                {
                    (!data && !teamData?.icon) && sportNameHeader ===
                    'Football' &&
                    <img src={(teamData?.sportIcon || defaultImage)}
                         alt={`Fanzine Football Logo`} height={30}
                         style={{zIndex: -1}}/>
                }
                {
                    sportNameHeader === 'NFL' &&
                    <IoAmericanFootballOutline size={'30'}/>
                }
                {
                    sportNameHeader === 'NBA' &&
                    <IoBasketballOutline size={'30'}/>
                }
                {
                    sportNameHeader === 'MLB' &&
                    <GiBaseballBat size={'30'}/>
                }
                {
                    sportNameHeader === 'Cricket' &&
                    <GiCricketBat size={'30'}/>
                }
                &nbsp;&nbsp;&nbsp;
                &nbsp;&nbsp;&nbsp;
                <span
                    style={{borderLeft: 'solid 1.5px #000', height: '24.5px'}}/>
              </MobileNewsMenuItem>
          }
          {preparedStoreData && preparedStoreData.map((value,index)=> {

            return index === 0 && value?.leagueName === 'League One' ? '' : (
                <MobileNewsMenuItem
                    key={index}
                    inactiveFontColor={'#000000'} isMobile={isMobile}
                    setCurrentActive={setCurrentActive}
                    title={value?.leagueName}
                    uri={`?league_name=${value?.leagueName}`}
                    className={'p-2'}
                    selected={leagueNameFromParams?.toLowerCase() ===
                        value?.leagueName?.toLowerCase()}
                />
            );
          })}
          {(data || teamData?.icon) && <div
              className={'ms-auto d-flex'}>
            <img src={data?.clubIcon || teamData?.icon}
                 alt={(data?.clubName || teamData?.name) + 'logo'}
                 style={{height: '62px', width: '60px'}}/>
            &nbsp;
            &nbsp;

            <div className={'font-weight-800 font-16 pt-3 pe-2 '}>
              {data?.clubName || teamData?.name}
              <br/>
              <p className={'font-12 pe-3'}>
                Fan Community
              </p>
            </div>

          </div>
          }
        </div>
    );
  };

  const renderBigView = () => {

    let defaultImage = '';
    let sportNameHeader = '';
    switch (location.pathname.split('/')[1]) {
      case 'football':
        defaultImage = require('../../assets/images/default-league.png');
        sportNameHeader = 'Football';
        break;
      case 'cricket':
        sportNameHeader = 'Cricket';
        break;
      case 'nfl':
        sportNameHeader = 'NFL';

        break;
      case 'nba':
        sportNameHeader = 'NBA';

        break;
      case 'mlb':
        sportNameHeader = 'MLB';

        break;
      default:
        defaultImage = require('../../assets/images/default-league.png');
        sportNameHeader = 'Football';

        break;
    }

    let tempSocial = {
      twitter: '',
      facebook: '',
      instagram: '',

    };
    for (let i = 0; i < store?.misc?.targetsApps?.length; i++) {

      if (store?.misc?.targetsApps[i]?.team_id === data?.clubId) {
        tempSocial.twitter = store?.misc?.targetsApps[i].twitter_url;
        tempSocial.facebook = store?.misc?.targetsApps[i].facebook_url;
        tempSocial.instagram = store?.misc?.targetsApps[i].instagram_url;

      }
    }

    return (
        <div className={`slider-mobile-news-container d-flex`}>
          {preparedStoreData && preparedStoreData.map((value,index)=> {

            return index === 0 && value?.leagueName === 'League One' ? '' : (
                <MobileNewsMenuItem
                    key={index}
                    inactiveFontColor={'#000000'} isMobile={true}
                    setCurrentActive={setCurrentActive}
                    title={value?.leagueName}
                    uri={`?league_name=${value?.leagueName}`}
                    className={'p-2'}
                    selected={leagueNameFromParams?.toLowerCase() ===
                        value?.leagueName?.toLowerCase()}
                />
            );
          })}
          {(data || teamData?.icon) && <div
              className={'ms-auto d-flex'}>
            <img src={data?.clubIcon || teamData?.icon}
                 alt={(data?.clubName || teamData?.name) + 'logo'}
                 style={{height: '62px', width: '60px'}}/>
            &nbsp;
            &nbsp;

            <div className={'font-weight-800 font-16 pt-3 pe-2 '}>
              {data?.clubName || teamData?.name}
              <br/>
              <p className={'font-12 pe-3'}>
                Fan Community
              </p>
            </div>

          </div>
          }
        </div>
    );
  };

  const renderMain = () => {
    return (
        <div
            className={`${isMobile ?
                'w-100' :
                'ps-5'} bg-fanzine-dark d-flex align-items-center  fanzine-football-nav`}
            style={{
              height: '4.4em',
              position: isMobile ? 'fixed' : 'relative',
              zIndex: isMobile && 9999,
            }}
            id={'subNavbarBg'}
        >
          <div
              className={`${isMobile ? '' : 'ps-5'} container`}>
            <div className={`${isMobile ? '' : 'ps-5'} row ${isMobile ?
                '' :
                'ms-3'}`}
                 style={{
                   zIndex: 99999,
                   position: 'relative',
                 }}
                 id={'subNavbar'}
            >
              {isMobile ? renderMobileMenu() : renderBigView()}
            </div>
          </div>
        </div>
    );
  };

  return renderMain();

};

FanzineShopNavBar.propTypes = {
  isMobile: PropTypes.bool,
};

export default FanzineShopNavBar;
