import { useEffect, useState } from "react";
import { SCREEN_SIZE } from "../../Configs/Constants";
import "../../assets/styles/Advertisement.css";
import WhoWeAre from "./WhoWeAre";
import Reach from "./Reach";
import Demographics from "./Demographics";
import AppAdvertising from "./AppAdvertising";
import AppSponsorship from "./AppSponsorship";
import SocialAdvertising from "./SocialAdvertising";
import WebAdvertisement from "./WebAdvertisement";
import Opportunities from "./Opportunities";
import LetsConnect from './LetsConnet';
const Advertisement = () => {
  const [isMobile, setIsMobile] = useState(false);
  const [width, setWidth] = useState(window.screen.width);

  const updateScreenWidth = () => {
    setWidth(window.screen.width);
  };

  useEffect(() => {
    updateScreenWidth();
  }, []);

  useEffect(() => {
    if (width <= SCREEN_SIZE.MOBILE) {
      setIsMobile(true);
    }

    return () => {
      setIsMobile(false);
    };
  }, [width]);

  return (
    <div style={{
      marginTop: isMobile && '-2em',
      width: isMobile ? 'null' : "100%" }}>
      <WhoWeAre isMobile={isMobile} />
      <Reach isMobile={isMobile} />
      <Demographics isMobile={isMobile} />
      <AppAdvertising isMobile={isMobile} />
      <AppSponsorship isMobile={isMobile} />
      <SocialAdvertising isMobile={isMobile} />
      <WebAdvertisement isMobile={isMobile} />
      <Opportunities isMobile={isMobile} />
      <LetsConnect isMobile={isMobile} />
    </div>
  );
};

export default Advertisement;
