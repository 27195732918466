import React from 'react';
import moment from 'moment-timezone';
import {FONT_COLOR, NAV_CLASS} from "../../Configs/Targets";

const DateOnlyItem = (props) => {
	const {value, index, currentDate, isMobile, target} = props;



	return (
		<div
			key={index}
			className={`col-4 pt-1 shadow ${target==='fanzine' ? 'text-black' : FONT_COLOR[target]} justify-content-center 
						${target==='fanzine' ? 'bg-fanzine-dark' : NAV_CLASS[target]} robotoCondensedBold text-center`}>
			<div className={'col-12'}>
				<h6 className={`robotoCondensedBold ${target==='fanzine' ? 'text-black' : FONT_COLOR[target]} ${isMobile ?
					'font-9' :
					'font-14'}`}>
					{moment(value?.date).format('ddd') || 'Mon'}
				</h6>
			</div>
			<div className={'col-12'}>
				<h3 className={`robotoCondensedBold ${target==='fanzine' ? 'text-black' : FONT_COLOR[target]} ${isMobile ?
					'font-11' :
					'font-30'}`}>
					{moment(value?.date).format('DD') || '26'}
				</h3>
			</div>
			<div className={'col-12'}>
				<h6 className={`robotoCondensedBold ${target==='fanzine' ? 'text-black' : FONT_COLOR[target]} ${isMobile ?
					'font-9' :
					'font-14'}`}>
					{moment(value?.date).format('MMM') || 'Dec'}
				</h6>
			</div>
		</div>
	);
};

export default DateOnlyItem;
