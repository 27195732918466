import {useSelector} from "react-redux";
import {getHostname} from "../../CustomFunctions/getHostname";
import {useLocation, useParams} from "react-router-dom";
import React, {useEffect, useRef, useState} from "react";
import {PodcastEndPoint} from "../../Axios/EndPoints";
import {get} from "../../Axios/get";
import Loader from "../../Components/Loader/Loader";
import {SCREEN_SIZE} from "../../Configs/Constants";
import 'react-h5-audio-player/lib/styles.css';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faVolumeOff, faVolumeUp} from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import RangeSlider from 'react-range-slider-input';
import '../../assets/styles/rangeInput.css';
import {RiForward30Line, RiReplay15Line} from "react-icons/ri";
import {ImPodcast} from "react-icons/im";
import {COLORS_RGB} from "../../Configs/Targets";

const SinglePodcastPage = (props) => {
    const {id} = useParams();
    let interval;
    const store = useSelector((state) => state);
    const hostName = window.location.hostname;
    const domainObject = getHostname(hostName);
    const target = domainObject.host;
    const location = useLocation();

    const audioRef = useRef();
    const audio = new Audio('https://sphinx.acast.com/p/acast/s/arseblog/e/6543c23c29dc900012e3f6de/media.mp3');

    const [isMobile, setIsMobile] = useState(false);
    const [podcast, setPodcast] = useState({});
    const [loading, setLoading] = useState(true);
    const [isPlaying, setIsPlaying] = useState(false);
    const [progress, setProgress] = useState(0);
    const [volume, setVolume] = useState(100);

    const start = moment(); // current minute
    const [end, setEnd] = useState(start);
    const [endDisplay, setEndDisplay] = useState('0:00');
    const [nowDisplay, setNowDisplay] = useState('0:00');
    const [currentSecond, setCurrentSecond] = useState(0.00);
    const [durationInSeconds, setDurationInSeconds] = useState(0);

    const [intervalId, setIntervalId] = useState();

    const fetchPodcast = () => {
        get(PodcastEndPoint + `/${id}`)
            .then((response) => {
                setPodcast(response?.data);
            });
    }

    const handleLoadMetadata = (meta) => {
        const {duration} = meta.target;
        setDurationInSeconds(duration);
        let diff = end.diff(start);
        let durationMinutes = duration / 60;
        let tempEnd = start.add(duration, 'seconds');
        setEnd(tempEnd);
        // setEndDisplay(durationMinutes);
        setEndDisplay(parseFloat(durationMinutes.toString()).toFixed(2).replace('.', ':'));
        setLoading(false);
    }

    useEffect(() => {
        if (window.screen.width <= SCREEN_SIZE.MOBILE) {
            setIsMobile(true);
        } else {
            setIsMobile(false);
        }
    }, [window.screen.width, isMobile]);

    useEffect(() => {
        fetchPodcast();
    }, [id]);

    const delay = ms => new Promise(
        resolve => setTimeout(resolve, ms)
    );

    const updateTimer = async (seconds) => {
        const tempProgress = progress + (seconds);
        if (tempProgress < (durationInSeconds / 60)) {
            await delay(1000);
            return seconds;
        }
    }

    useEffect(async () => {
        /*const id = setInterval(() => {
        }, 1000);*/
        // return () => clearInterval(intervalId);
        updateTimer(0.01).then((seconds) => {
            if (isPlaying) {
                setProgress(prevState => {
                    let temp = prevState + seconds;
                    let temp1 = Math.floor(temp);
                    let temp2 = temp;
                    if ((temp - temp1) >= 0.59) {
                        temp2 = temp + (1 - 0.59);
                    }
                    return temp2;
                });
            }
        });
    }, [isPlaying, progress]);

    useEffect(() => {
    }, [end, endDisplay]);

    const playAudio = () => {
        audioRef.current.play();
        setIsPlaying(true);
    }
    const pauseAudio = () => {
        audioRef.current.pause();
        setIsPlaying(false);
    }
    const forwardAudio = () => {
        setIsPlaying(false);
        audioRef.current.pause();
        audioRef.current.currentTime += 30;
        let newProgress = progress + 0.30;
        if (newProgress < (durationInSeconds / 60)) {
            let temp1 = Math.floor(newProgress);
            let temp2 = newProgress;
            if ((newProgress - temp1) >= 0.59) {
                temp2 = newProgress + (1 - 0.59);
            }
            updateTimer(0.30).then((seconds) => {
                setProgress(prevState => {
                    let temp = prevState + seconds;
                    let temp1 = Math.floor(temp);
                    let temp2 = temp;
                    if ((temp - temp1) >= 0.59) {
                        temp2 = temp + (1 - 0.59);
                    }
                    return temp2;
                });
                setIsPlaying(true);
                audioRef.current.play();
            });
            // setIsPlaying(true);
            /*setProgress(prevState => {
                let temp = prevState + 0.30;
                let temp1 = Math.floor(temp);
                let temp2 = temp;
                if ((temp - temp1) > 0.59) {
                    temp2 = temp + (1 - 0.59);
                }
                return temp2;
            });*/
            // setIsPlaying(true);
        } else {
            setProgress(durationInSeconds / 60);
            setIsPlaying(false);
        }
    }
    const rewindAudio = () => {
        setIsPlaying(false);
        audioRef.current.pause();
        audioRef.current.currentTime -= 15;
        let newProgress = progress - 0.15;
        if (newProgress > 0) {
            updateTimer(0.15).then((seconds) => {
                setProgress(prevState => {
                    let temp = prevState - seconds;
                    let temp1 = Math.floor(temp);
                    let temp2 = temp;
                    if ((temp - temp1) >= 0.59) {
                        temp2 = temp - 0.4;
                    }
                    return temp2;
                });
                setIsPlaying(true);
                audioRef.current.play();
            });
            /*setProgress(prevState => {
                let temp = prevState - 0.15;
                let temp1 = Math.floor(temp);
                let temp2 = temp;
                if ((temp - temp1) >= 0.59) {
                    temp2 = temp + (1 - 0.59);
                }
                return temp2;
            });*/
        } else {
            setProgress(0);
            setIsPlaying(true);
        }
    }

    const handleVolume = (e) => {
        let tempVol = e[1] || e[0] || 0;
        console.log('volume', tempVol);
        setVolume(tempVol);
        audioRef.current.volume = tempVol / 100;
    }
    const ProgressBar = ({status, progressColor}) => (
        <div style={{background: '#EFEFF4', border: 'none', overflow: 'hidden'}}>
            <div style={{
                transform: `scaleX(${status / 60})`,
                transformOrigin: '0 0',
                transition: 'transform 1s',
                height: 5,
                background: progressColor
            }}/>
        </div>
    );
    const renderMobile = () => {
        return (
            <section className={'pb-5 bg-white'}>
                <div className={'offset-1 col-10 pt-5'}>
                    <img src={podcast?.image_url} alt="podcast image" className={'img-fluid'}/>
                </div>
                <div className={'mt-2 mb-2 offset-1 col-10 pt-3'}>
                    <ProgressBar progressColor={'#8E8E93'} status={progress}/>
                    <div className={'d-flex justify-content-between'}>
                        <div>{(parseFloat(progress.toString()).toFixed(2).replace('.', ':'))}</div>
                        <div>{endDisplay}</div>
                    </div>
                </div>
                <div className={'col-12 justify-content-center p-4 pb-5 sfProDisplayFont font-22 fw-bold text-center'}>
                    {podcast?.title}
                </div>
                <div className={'offset-1 col-10 justify-content-center mb-4'}>
                    <div className={'d-flex justify-content-around'}>
                        {/*<FontAwesomeIcon icon={faAngleDoubleLeft} size={'3x'} onClick={rewindAudio}
                                         className={'cursor-pointer'}/>*/}
                        <img src={require('../../assets/images/podcasts/15 minutes.png')} alt={'15 rewind'} width={60}
                             className={'cursor-pointer'} onClick={rewindAudio}/>
                        {
                            isPlaying ?
                                <img src={require('../../assets/images/podcasts/Pause.png')} alt={'pause'} width={40}
                                     className={'cursor-pointer'} onClick={pauseAudio}/>
                                :
                                <img src={require('../../assets/images/podcasts/play button.png')} alt={'play'}
                                     width={50} className={'cursor-pointer'} onClick={playAudio}/>
                        }
                        <img src={require('../../assets/images/podcasts/30 minutes.png')} alt={'15 rewind'} width={60}
                             className={'cursor-pointer'} onClick={forwardAudio}/>
                    </div>
                    <audio src={podcast.url} ref={audioRef} id={'player'} controls={false}
                           onLoadedMetadata={handleLoadMetadata} className={'w-100'}
                    />
                </div>
                {
                    !['iPhone', 'iPad'].includes(window.navigator.platform) &&
                    <div className={'mt-2 mb-2 offset-1 col-10 pt-3'}>
                        <div className={'d-flex'}>
                            <FontAwesomeIcon icon={faVolumeOff} size={'2x'} className={'me-3 align-self-center'}
                                             style={{color: '#8E8E93'}}/>
                            <RangeSlider className="single-thumb align-self-center"
                                         defaultValue={[0, volume]}
                                         thumbsDisabled={[true, false]}
                                         rangeSlideDisabled={true}
                                         onInput={handleVolume}
                            />
                            <FontAwesomeIcon icon={faVolumeUp} size={'2x'} className={'ms-3 align-self-center'}
                                             style={{color: '#8E8E93'}}/>
                        </div>
                    </div>
                }
                {/*<div className={'col-12 pt-5'}>
                    <div className={'d-flex justify-content-center w-100'}>
                        <ImPodcast size={40} color={COLORS_RGB[target]}/>
                    </div>
                </div>*/}
            </section>
        );
    }
    const renderDesktop = () => {
        return renderMobile();
        return (
            <section className={'mt-5 pt-5 mb-5 pb-5'}>
                Podcasts page Desktop
            </section>
        );
    }
    const render = () => {
        return (
            <>
                <Loader loading={loading}/>
                {isMobile ? renderMobile() : renderDesktop()}
            </>
        );
    }

    return render();
}

export default SinglePodcastPage;
