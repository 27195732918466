import React, {useEffect, useState} from 'react';
import '../assets/styles/footer.css';
import '../assets/styles/styles.css';
import {LOGO, LOGO_MEDIUM, TEAM_NAME} from '../Configs/Targets';
import {getHostname} from '../CustomFunctions/getHostname';
import {useSelector} from 'react-redux';
import {SCREEN_SIZE} from '../Configs/Constants';

const Footer = () => {
  const store = useSelector(state => state);
  let [imageProps, setImageProps] = useState({});
  let [isMobile, setIsMobile] = useState(false);
  const hostName = window.location.hostname;
  const domainObject = getHostname(hostName);
  const target = domainObject.host;

  let [navLogo, setNavLogo] = useState(LOGO[target]);

  useEffect(() => {

    const navLogoSmall = LOGO[target];
    const navLogoBig = LOGO[target];
    if (window.screen.width <= SCREEN_SIZE.MOBILE) {
      setNavLogo(navLogoSmall);
      setImageProps({
        height: 50,
      });
      setIsMobile(true);
    } else {
      setNavLogo(navLogoBig);
      if ([
        'the-toffees',
        'unitedreds',
        'coys',
        'playuppompey',
        'foresttilidie',
        'comeonderby',
        'fanzinesports'].includes(target)) {
        setImageProps({
          style: {marginTop: '-2em'}, height: 85,
        });
      } else if (['fanzine'].includes(target)) {
        setImageProps({
          style: {marginTop: '2.5em'}, height: 120, width: 110,
        });
      } else {
        setImageProps({
          height: 75,
        });
      }
      setIsMobile(false);
    }
  }, []);
  const render = () => {

    const renderFooter = () => {
      const navLogo = LOGO_MEDIUM[target];
      const navLogoMedium = LOGO_MEDIUM[target];
      const navName = TEAM_NAME[target];
      // let urls = store.misc.appInit;

      const renderLaptopFooter = () => {
        return (
            <div className={'container'}
                 style={{width: '50%'}}
            >
              <div className={'row d-flex align-items-center'}>
                <div className={'col-lg-5 col-md-5 col-sm-5 col-xs-5'}>
                  <hr/>
                </div>
                <div
                    className={'col-lg-2 col-md-2 col-sm-2 col-xs-2 text-center align-items-center justify-content-center'}>
                  <img src={navLogo}
                       className={''}
                       alt={navName}
                       {...imageProps}
                  />
                </div>
                <div className={'col-lg-5 col-md-5 col-sm-5 col-xs-5'}>
                  <hr/>
                </div>
              </div>
              <div
                  className={'row pb-2 justify-content-center  d-flex align-items-center'}>
                <div
                    className={'col-lg-3 col-md-3 col-sm-3 col-xs-3 text-start'}>
                  Download
                </div>
                <a href={'/privacy'}
                   className={'col-lg-3 col-md-3 col-sm-3 col-xs-3 text-start text-decoration-none text-white'}>
                  Privacy Policy
                </a>
                <a href={'/terms'}
                   className={'col-lg-3 col-md-3 col-sm-3 col-xs-3 text-end  text-decoration-none text-white'}>
                  Terms & Conditions
                </a>
                <a
                    target={'_blank'} rel={'noreferrer'}
                    href={target !== 'fanzine' ?
                        'https://www.fanzine.com/contact'
                        : '/contact'}
                    className={'col-lg-3 col-md-3 col-sm-3 col-xs-3 text-end text-decoration-none text-white'}>
                  Contact Us
                </a>
              </div>
              <div
                  className={'row  pb-2 justify-content-center  d-flex align-items-center'}>
                <a target="_blank" rel="noreferrer" href={target !== 'fanzine' ?
                    store?.misc?.appInit?.non_betting_urls?.ios_appstore_url :
                    'https://apps.apple.com/gb/developer/fanzine/id1427178132?see-all=i-phone-apps'}
                   className={'col-lg-3 col-md-3 col-sm-3 col-xs-3 text-center text-decoration-none text-white'}>
                  <span
                      className={'d-flex align-items-center justify-content-start text-start '}>
                  <img src={require('../assets/images/v2/baselineApple@3x.png')}
                       alt={'applelogo'}
                       style={{height: '25px', width: '21px'}}/>
                    &nbsp;
                    App Store
                  </span>
                </a>
                <div
                    className={'col-lg-3 col-md-3 col-sm-3 col-xs-3 text-start  text-decoration-none text-white'}>
                </div>
                <div
                    className={'col-lg-3 col-md-3 col-sm-3 col-xs-3 text-center'}>
                </div>
                <div
                    className={'col-lg-3 col-md-3 col-sm-3 col-xs-3 text-center'}>
                </div>
              </div>
              <a
                  target="_blank" rel="noreferrer"
                  href={target !== 'fanzine' ?
                      store?.misc?.appInit?.non_betting_urls?.android_play_store_url
                      :
                      'https://play.google.com/store/apps/developer?id=Fanzine+Sports'}
                  className={'col-lg-3 col-md-3 col-sm-3 col-xs-3 text-center  text-decoration-none text-white'}>
                  <span
                      className={'d-flex align-items-center justify-content-start text-start'}>
                  <img src={require('../assets/images/v2/androidFilled@3x.png')}
                       alt={'applelogo'}
                       style={{height: '25px', width: '21px'}}/>
                    &nbsp;
                    Play Store
                  </span>
              </a>
              <div
                  className={'col-lg-3 col-md-3 col-sm-3 col-xs-3 text-start '}>
              </div>
              <div
                  className={'col-lg-3 col-md-3 col-sm-3 col-xs-3 text-center'}>
              </div>
              <div
                  className={'col-lg-3 col-md-3 col-sm-3 col-xs-3 text-center'}>
              </div>
              <div className={'col-lg-12 col-md-12 col-sm-12 col-xs-12'}>
                <hr/>
              </div>
              <div
                  className={'col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center align-items-center justify-content-center '}>
                FOLLOW US
              </div>
              <div
                  className={'pt-3 col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center align-items-center justify-content-center '}>
                <span>
                  <a href={target !== 'fanzine' ?
                      store.misc.appInit && store.misc.appInit.twitter_url
                      : 'https://twitter.com/Fanzine_com'}>
                  <img src={require('../assets/images/v2/component8651@3x.png')}
                       className={'text-decoration-none text-white'}
                       alt={'twitterlogo'}
                       style={{height: '48px', width: '48px'}}/>
                  </a>
                  &nbsp;
                  &nbsp;
                  <a href={target !== 'fanzine' ?
                      store.misc.appInit && store.misc.appInit.facebook_url
                      : 'https://www.facebook.com/fanzinesoccer'}>
                  <img
                      className={'text-decoration-none text-white'}
                      src={require('../assets/images/v2/component8661@3x.png')}
                      alt={'twitterlogo'}
                      style={{height: '48px', width: '48px'}}/>
                  </a>
                  &nbsp;
                  &nbsp;
                  <a href={
                    target !== 'fanzine' ?
                        store.misc.appInit && store.misc.appInit.instagram_url
                        :
                        'https://www.instagram.com/fanzine_com/'}
                  >
                  <img

                      className={'text-decoration-none text-white'}
                      src={require('../assets/images/v2/component8671@3x.png')}
                      alt={'twitterlogo'}
                      style={{height: '48px', width: '48px'}}/>
                  </a>
                </span>
              </div>
              <div
                  className={'pt-3 pb-3 col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center'}>
                2023 Fanzine All rights reserved

              </div>
            </div>
        );
      };

      const renderMobileFooter = () => {
        return (
            <div className={'container'}
                 style={{width: '80%'}}
            >
              <div className={'row d-flex align-items-center'}>
                <div className={'col-5'}>
                  <hr/>
                </div>
                <div
                    className={'col-2 ps-1 text-center align-items-center justify-content-center'}>
                  <img src={navLogo}
                       className={''}
                       alt={navName}
                       {...imageProps}
                  />
                </div>
                <div className={'col-5'}>
                  <hr/>
                </div>
              </div>
              <div className={'row pb-2  d-flex align-items-center'}>
                <div className={'col-6 text-start'}>
                  Download
                </div>
                <a href={'/privacy'}
                   className={'col-6 text-end text-decoration-none text-white'}>
                  Privacy Policy
                </a>
                <a
                    target="_blank" rel="noreferrer"
                    href={target !== 'fanzine' ?
                        store?.misc?.appInit?.non_betting_urls?.ios_appstore_url :
                        'https://apps.apple.com/gb/developer/fanzine/id1427178132?see-all=i-phone-apps'}
                    className={'col-6 mt-3 text-decoration-none text-white'}>
                  <span
                      className={'d-flex align-items-center justify-content-start text-start'}>
                  <img src={require('../assets/images/v2/baselineApple@3x.png')}
                       alt={'applelogo'}
                       style={{height: '25px', width: '21px'}}/>
                    &nbsp;
                    App Store
                  </span>
                </a>
                <a href={'/terms'}
                   className={'col-6 text-end  mt-3 text-decoration-none text-white'}>
                  Terms & Conditions
                </a>
                <a
                    target="_blank" rel="noreferrer"
                    href={target !== 'fanzine' ?
                        store?.misc?.appInit?.non_betting_urls?.android_play_store_url
                        :
                        'https://play.google.com/store/apps/developer?id=Fanzine+Sports'}
                    className={'col-6 mt-3 text-start text-decoration-none text-white '}>
                  <span
                      className={'d-flex align-items-center justify-content-start text-start'}>
                  <img src={require('../assets/images/v2/androidFilled@3x.png')}
                       alt={'applelogo'}
                       style={{height: '25px', width: '21px'}}/>
                    &nbsp;
                    Play Store
                  </span>
                </a>
                <a href={target !== 'fanzine' ?
                    'https://www.fanzine.com/contact'
                    : '/contact'}
                   className={'col-6 mt-3 text-end text-decoration-none text-white'}>
                  Contact Us
                </a>
                {/*<a href={'/contact'} className={'col-12 mt-3  text-end text-decoration-none text-white'} >*/}
                {/*</a>*/}

              </div>
              <div className={'col-lg-12 col-md-12 col-sm-12 col-xs-12'}>
                <hr/>
              </div>
              <div
                  className={'col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center align-items-center justify-content-center '}>
                FOLLOW US
              </div>
              <div
                  className={'pt-3 col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center align-items-center justify-content-center '}>
                <span>
                  <a href={target !== 'fanzine' ?
                      store.misc.appInit && store.misc.appInit.twitter_url
                      :
                      'https://twitter.com/Fanzine_com'}>
                  <img src={require('../assets/images/v2/component8651@3x.png')}
                       className={'text-decoration-none text-white'}
                       alt={'twitterlogo'}
                       style={{height: '48px', width: '48px'}}/>
                  </a>
                  &nbsp;
                  &nbsp;
                  <a href={target !== 'fanzine' ?
                      store.misc.appInit && store.misc.appInit.facebook_url
                      :
                      'https://www.facebook.com/fanzinesoccer'}>
                  <img src={require('../assets/images/v2/component8661@3x.png')}
                       className={'text-decoration-none text-white'}
                       alt={'twitterlogo'}
                       style={{height: '48px', width: '48px'}}/>
                  </a>
                  &nbsp;
                  &nbsp;
                  <a href={target !== 'fanzine' ?
                      store.misc.appInit && store.misc.appInit.instagram_url
                      :
                      'https://www.instagram.com/fanzine_com/'}>
                  <img src={require('../assets/images/v2/component8671@3x.png')}
                       className={'text-decoration-none text-white'}
                       alt={'twitterlogo'}
                       style={{height: '48px', width: '48px'}}/>
                  </a>
                </span>
              </div>
              <div
                  className={'pt-3 pb-3 col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center'}>
                2023 Fanzine All rights reserved

              </div>
            </div>
        );
      };

      return (
          <div
              style={{
                width: isMobile ?
                    'fit-content' :
                    '-webkit-fill-available',
              }}
              className={' bg-black text-white'}>
            {isMobile ? renderMobileFooter() : renderLaptopFooter()}
          </div>

      );

    };

    return renderFooter();

    // return (
    //     <div className={'bg-footer pt-4 pb-4'}>
    //       <div
    //           className={'container-xxl container-xl container-lg container-md container-fluid'}>
    //         <div className={'row'}>
    //           <div
    //               className={'col text-footer d-none d-lg-block d-xl-block d-xxl-block'}>
    //             <img src={navLogo} alt={navName} height={119}/>
    //             <div className={'sfProDisplayFont font-18'}>2022 {navName}</div>
    //             <div className={'sfProDisplayFont font-18'}>All rights
    //               reserved
    //             </div>
    //           </div>
    //           <div className={'col text-footer'}>
    //             <div className={'sfProDisplayFont font-20'}>Follow Us</div>
    //             <div className={'sfProDisplayFont font-18'}>
    //               <a href={urls && urls.facebook_url}
    //                  className={'text-decoration-none'}
    //                  style={{color: '#686868'}}>
    //                 <FontAwesomeIcon icon={faFacebook} color={'#686868'}
    //                                  size={'1x'}/>
    //                 &nbsp;&nbsp;
    //                 Facebook
    //               </a>
    //             </div>
    //             <div className={'sfProDisplayFont font-18'}>
    //               <a href={urls && urls.twitter_url}
    //                  className={'text-decoration-none'}
    //                  style={{color: '#686868'}}>
    //                 <FontAwesomeIcon icon={faTwitter} color={'#686868'}
    //                                  size={'1x'}/>
    //                 &nbsp;&nbsp;
    //                 Twitter
    //               </a>
    //             </div>
    //             <div className={'sfProDisplayFont font-18'}>
    //               <a href={urls && urls.instagram_url}
    //                  className={'text-decoration-none'}
    //                  style={{color: '#686868'}}>
    //                 <FontAwesomeIcon icon={faInstagram} color={'#686868'}
    //                                  size={'1x'}/>
    //                 &nbsp;&nbsp;
    //                 Instagram
    //               </a>
    //             </div>
    //           </div>
    //           <div className={'col text-footer'}>
    //             <div className={'sfProDisplayFont font-20'}>Legal</div>
    //             <div className={'sfProDisplayFont font-18'}>
    //               <a href={'/terms'} className={'text-decoration-none'}
    //                  style={{color: '#686868'}}>
    //                 Terms of Use
    //               </a>
    //             </div>
    //             <div className={'sfProDisplayFont font-18'}>
    //               <a href={'/privacy'} className={'text-decoration-none'}
    //                  style={{color: '#686868'}}>
    //                 Privacy Policy
    //               </a>
    //             </div>
    //             <div className={'sfProDisplayFont font-18'}>
    //               <a href={'/cookies'} className={'text-decoration-none'}
    //                  style={{color: '#686868'}}>
    //                 Cookie Policy
    //               </a>
    //             </div>
    //           </div>
    //           <div className={'col text-footer'}>
    //             <div className={'sfProDisplayFont font-20'}>Download the App
    //             </div>
    //             <div className={'sfProDisplayFont font-18'}>
    //               <a href={urls && urls.ios_default_app_url}
    //                  className={'text-decoration-none'}
    //                  style={{color: '#686868'}}>
    //                 <FontAwesomeIcon icon={faApple} color={'#686868'}
    //                                  size={'1x'}/>
    //                 &nbsp;&nbsp;
    //                 App Store
    //               </a>
    //             </div>
    //             <div className={'sfProDisplayFont font-18'}>
    //               <a href={urls && urls.android_default_app_url}
    //                  className={'text-decoration-none'}
    //                  style={{color: '#686868'}}>
    //                 <GrAndroid color={'#686868'}/>
    //                 &nbsp;&nbsp;
    //                 Google Play
    //               </a>
    //             </div>
    //           </div>
    //           {
    //               target === 'fanzine' && (
    //                   <div className={'col text-footer'}>
    //                     <div className={'sfProDisplayFont font-18'}>
    //                       <Link to={'football_content'}
    //                             className={'text-decoration-none'}
    //                             style={{color: '#686868'}}>Football</Link>
    //                     </div>
    //                     <div className={'sfProDisplayFont font-18'}>
    //                       <Link to={'cricket_content'}
    //                             className={'text-decoration-none'}
    //                             style={{color: '#686868'}}>Cricket</Link>
    //                     </div>
    //                     <div className={'sfProDisplayFont font-18'}>
    //                       <Link to={'nba_content'}
    //                             className={'text-decoration-none'}
    //                             style={{color: '#686868'}}>NBA</Link>
    //                     </div>
    //                     <div className={'sfProDisplayFont font-18'}>
    //                       <Link to={'nfl_content'}
    //                             className={'text-decoration-none'}
    //                             style={{color: '#686868'}}>NFL</Link>
    //                     </div>
    //                     <div className={'sfProDisplayFont font-18'}>
    //                       <Link to={'mlb_content'}
    //                             className={'text-decoration-none'}
    //                             style={{color: '#686868'}}>MLB</Link>
    //                     </div>
    //                   </div>
    //               )
    //           }
    //         </div>
    //
    //         <div
    //             className={'col text-footer mt-3 d-block d-lg-none d-xl-none d-xxl-none'}>
    //           <img src={navLogoMedium} alt={navName} height={'75'}/>
    //           <span className={'d-inline-flex flex-column align-middle ms-3'}>
    //                         <div
    //                             className={'sfProDisplayFont'}>2022 {navName}</div>
    //                         <div className={'sfProDisplayFont'}>All rights reserved</div>
    //                     </span>
    //         </div>
    //       </div>
    //     </div>
    // );
  };

  return render();
};

export default Footer;
