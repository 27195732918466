import React from "react";
import {getHostname} from "../../CustomFunctions/getHostname";
import {LOADING_GIF} from "../../Configs/Targets";
import PropTypes from "prop-types";

const ItemLoader = (props) => {

    const hostName = window.location.hostname;
    const domainObject = getHostname(hostName);
    const target = domainObject.host;
    const loadingGif = LOADING_GIF[target];

    return props.loading ? (
        <div className={`item-loader`} style={{top: '100px'}}>
            <img src={loadingGif}
                 alt="loader" width={props.small ? '30' : "150"}/>
        </div>
    ) : null;

}

ItemLoader.propTypes = {
    loading: PropTypes.bool.isRequired,
    small: PropTypes.bool
}

export default ItemLoader;