import {useDispatch, useSelector} from "react-redux";
import {useWindowSize} from "../../CustomFunctions/useWindowSize";
import React, {useLayoutEffect, useState} from "react";
import {COLORS, COLORS_RGBA, LOADING_GIF, NAV_CLASS, TEXT_COLOR} from "../../Configs/Targets";
import {SCREEN_SIZE, SHOPIFY} from "../../Configs/Constants";
import {graphql} from "../../Axios/graphql";
import {ShopifyGraphQlQueries} from "../../Configs/ShopifyGraphQlQueries";
import {Link} from "react-router-dom";
import {setCartItems, setGrandTotal} from "../../redux/actions/CartActions";
import {getHostname} from "../../CustomFunctions/getHostname";
import ItemLoader from "../Loader/ItemLoader";

export const MiniCart = (props) => {

    // Hooks
    const cart = useSelector((state) => state);
    const dispatch = useDispatch();
    let windowSize = useWindowSize();

    /*States*/
    let [subtotal, setSubtotal] = useState(0);
    let [discount, setDiscount] = useState(null);
    let [discountType, setDiscountType] = useState(null);
    let [discountValue, setDiscountValue] = useState(null);
    let [discountCode, setDiscountCode] = useState(null);
    let [shopifyCart, setShopifyCart] = useState(null);
    let [loading, setLoading] = useState(true);
    let [checkoutId, setCheckoutId] = useState(props.checkoutSet);
    /*States Ends*/

    /*Variables Start*/
    const hostName = window.location.hostname;
    const domainObject = getHostname(hostName);
    const target = domainObject.host;
    let bgColor = NAV_CLASS[target], textColor = TEXT_COLOR[target], color = COLORS[target],
        colorRgba = COLORS_RGBA[target], topBannerStyle = {
            width: '100%',
            height: '144px',
            background: `linear-gradient(to bottom, ${colorRgba}0.71) 45%, ${colorRgba}1)), url(${require('../../assets/images/shop_top_banner.png')})`,
            backgroundSize: 'cover'
        }, loadingGif = LOADING_GIF[target];
    let isMobile = false;
    if (windowSize.width <= SCREEN_SIZE.MOBILE) {
        isMobile = true;
    }
    /*Variables Ends*/

    /* Use Effect Start */
    useLayoutEffect(() => {
        getShopifyCart();
        // calculateInitialSubtotal();
    }, [props.checkoutSet]);
    /* Use Effect End */

    /*API Call*/
    const getShopifyCart = () => {
        setLoading(true);
        let checkoutId = localStorage.getItem("checkoutId");
        let data = {checkout_id: checkoutId};
        graphql(ShopifyGraphQlQueries(SHOPIFY.GET_SHOPIFY_CART, null, null, data))
            .then((response) => {
                if (response.data.data !== undefined && response.data.data.node !== undefined && response.data.data.node.lineItems !== undefined) {
                    let tempCartItems = [];
                    console.log("cart in minicart: ", response);
                    // localStorage.removeItem("checkoutUrl");
                    // localStorage.setItem('checkoutUrl', response.data.data.node.webUrl);
                    response.data.data.node.lineItems.edges.map((item) => {
                        let tempVariant = {
                            id: item.node.variant.id,
                            title: item.node.variant.title,
                            price: item.node.variant.price,
                            image: item.node.variant.image,
                        };
                        let node = {
                            id: item.node.variant.product.id,
                            product_name: item.node.title,
                            variant: tempVariant,
                            size: item.node.variant.selectedOptions[0].value,
                            color: item.node.variant.selectedOptions[1] ? item.node.variant.selectedOptions[1].value : "",
                            lineItemId: item.node.id,
                            quantity: item.node.quantity,
                        };
                        tempCartItems.push(node);
                    });

                    setShopifyCart(response.data.data.node);
                    let total = Math.round(response.data.data.node.subtotalPrice.amount * 100) / 100;
                    dispatch(setGrandTotal(total));
                    if (response.data.data.node.discountApplications.edges.length === 0) {
                        setSubtotal(Math.round(parseFloat(response.data.data.node.subtotalPrice.amount) * 100) / 100);
                    } else {
                        let tempSubtotal = 0;
                        setDiscountCode(response.data.data.node.discountApplications.edges[0].node.code);
                        setDiscountValue(response.data.data.node.discountApplications.edges[0].node.value.percentage);
                        setDiscount(true);
                        response.data.data.node.lineItems.edges.map((item) => {
                            tempSubtotal += parseFloat(item.node.variant.price.amount);
                        })
                        setSubtotal(tempSubtotal);
                    }
                    dispatch(setCartItems(tempCartItems));
                } else {
                    localStorage.removeItem('checkoutUrl')
                    dispatch(setCartItems([]));
                }
                setLoading(false);

            })
            .catch((err) => {
                console.error(err)
            })
    }
    /*API Call Ends*/

    /*Event Methods*/

    const handlePromoDiscount = () => {
        setLoading(true);
        let discountCode = document.getElementById('discountCodeMiniCheckout').value;
        let checkoutId = localStorage.getItem("checkoutId");
        let data = {checkout_id: checkoutId, discount_code: discountCode};
        graphql(ShopifyGraphQlQueries(SHOPIFY.APPLY_DISCOUNT_CODE, null, null, data))
            .then((response) => {
                let edges = response.data.data.checkoutDiscountCodeApply.checkout.discountApplications.edges
                if (edges.length > 0) {
                    localStorage.removeItem("checkoutUrl");
                    localStorage.setItem('checkoutUrl', response.data.data.checkoutDiscountCodeApply.checkout.webUrl);
                    let discount = edges[0].node.value;
                    setDiscountType(discount.__typename);
                    setDiscountValue(discount.percentage);
                    setDiscount(true);
                    setDiscountCode(edges[0].node.code);
                    getShopifyCart();
                }
            })
            .catch((err) => {
                console.error(err)
            })
    }

    const removePromoDiscount = () => {
        setLoading(true);
        let checkoutId = localStorage.getItem("checkoutId");
        let data = {checkout_id: checkoutId}
        graphql(ShopifyGraphQlQueries(SHOPIFY.REMOVE_DISCOUNT_CODE, null, null, data))
            .then((response) => {
                getShopifyCart();
            })
            .catch((err) => {
                console.error(err)
            })
        setDiscount(null);
        document.getElementById('discountCodeMiniCheckout').value = "";
    }

    const handleCheckout = () => {
        let checkoutUrl = localStorage.getItem("checkoutUrl");
        localStorage.removeItem('checkoutUrl')
        window.location.href = checkoutUrl;
    }

    /*item event methods*/
    const incrementQuantity = (event) => {
        setLoading(true);
        let variantId = event.currentTarget.dataset.id;
        let currentItem = shopifyCart.lineItems.edges.filter((item) => item.node.variant.id === variantId);
        let quantity = currentItem[0].node.quantity;
        let tempQuantity = quantity + 1;
        let checkoutId = localStorage.getItem("checkoutId");
        let data = {checkout_id: checkoutId, variant_quantity: tempQuantity, linkItem_id: currentItem[0].node.id}
        graphql(ShopifyGraphQlQueries(SHOPIFY.CHECKOUT_LINE_ITEMS_UPDATE, null, null, data))
            .then((response) => {
                localStorage.removeItem("checkoutUrl");
                localStorage.setItem('checkoutUrl', response.data.data.checkoutLineItemsUpdate.checkout.webUrl);
                setSubtotal(subtotal + parseFloat(currentItem[0].node.variant.price?.amount));
                getShopifyCart();
            })
            .catch((err) => {
                console.error(err)
            })

        // dispatch(setGrandTotal(parseFloat(cart.cart.grandTotal) + Math.round((price * parseInt(tempQuantity)) * 100) / 100));
    }

    const decrementQuantity = (event) => {
        setLoading(true);
        let variantId = event.currentTarget.dataset.id;
        let currentItem = shopifyCart.lineItems.edges.filter((item) => item.node.variant.id === variantId);
        let quantity = currentItem[0].node.quantity;
        let price = parseFloat(currentItem[0].node.variant.price?.amount);
        let tempQuantity = quantity - 1;
        if (tempQuantity >= 1) {
            let checkoutId = localStorage.getItem("checkoutId");
            let data = {checkout_id: checkoutId, variant_quantity: tempQuantity, linkItem_id: currentItem[0].node.id};
            graphql(ShopifyGraphQlQueries(SHOPIFY.CHECKOUT_LINE_ITEMS_UPDATE, null, null, data))
                .then((response) => {
                    localStorage.removeItem("checkoutUrl");
                    localStorage.setItem('checkoutUrl', response.data.data.checkoutLineItemsUpdate.checkout.webUrl);
                    setSubtotal(subtotal - parseFloat(currentItem[0].node.variant.price.amount));
                    getShopifyCart();
                })
                .catch((err) => {
                    console.error(err)
                })
            // dispatch(setGrandTotal(parseFloat(cart.cart.grandTotal) - Math.round((price * parseInt(tempQuantity)) * 100) / 100));
        }
    }

    const removeItem = (id) => {
        // setLoading(true);
        let currentItem = shopifyCart.lineItems.edges.filter((item) => item.node.variant.id === id);
        let checkoutId = localStorage.getItem("checkoutId");
        let data = {checkout_id: checkoutId, lineItem_id: currentItem[0].node.id};
        graphql(ShopifyGraphQlQueries(SHOPIFY.CHECKOUT_LINE_ITEMS_REMOVE, null, null, data))
            .then((response) => {
                localStorage.removeItem("checkoutUrl");
                localStorage.setItem('checkoutUrl', response.data.data.checkoutLineItemsRemove.checkout.webUrl);
                getShopifyCart();
            })
            .catch((err) => {
                console.error(err)
            })
        let cartAfterFilter = cart.cart.cartItems.filter((item, index) => item.variant.id !== id);
        if (cartAfterFilter.length > 0) {
            dispatch(setCartItems(cartAfterFilter));
            getShopifyCart();
        } else {
            dispatch(setCartItems(cartAfterFilter));
            dispatch(setGrandTotal(0));
            props.setShowMiniCart(false);
        }


    }

    return (
        <>

            <div className={'container sfProDisplayFontBold mt-3 p-3 bg-grey'}>
                {
                    shopifyCart && shopifyCart.lineItems.edges.length > 0 ? (
                        <div className={`container d-flex m-auto p-5 row bg-white`}>
                            <>
                                <ItemLoader loading={loading}/>
                                {shopifyCart.lineItems.edges.map((cartItem, index) => (
                                    <>
                                        {/*<MiniCartItem item={cartItem} subtotal={() => calculateSubtotal} reload={() => reloadCart}/>*/}
                                        <div
                                            key={index}
                                            className={`mt-3 mb-1 col-8 justify-content-start`}>
                                            <div className={`col-12`}>
                                                <div className={`row`}>
                                                    <div className={`col-4`}>
                                                        <Link
                                                            to={`/shop/product/${cartItem.node.variant.product.handle}`}>
                                                            <img src={cartItem.node.variant.image.src} width={`100`}
                                                                 alt="variant-image"/>
                                                        </Link>
                                                        <br/>
                                                        <div
                                                            className={`mt-2 d-flex justify-content-center text-black`}>
                                                            <div className={`sfProDisplayFont font-18`}
                                                                 onClick={() => removeItem(cartItem.node.variant.id)}
                                                                 role={`button`}>
                                                                Remove
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className={`col-6`}>
                                                        <div
                                                            className={`d-flex justify-content-start text-start text-wrap text-mini-cart sfProDisplayFontBold font-22 col-12`}>
                                                            {cartItem.node.title}
                                                        </div>
                                                        {cartItem.node.variant.selectedOptions.map((option, optIndex) => (
                                                            <div
                                                                key={optIndex}
                                                                className={`d-flex justify-content-start text-mini-cart sfProDisplayFont font-18 font-weight-500`}>
                                                                {option.name}: {option.value}
                                                            </div>
                                                        ))}
                                                        <div className={`bg-green text-center w-75 text-white`}>
                                                            In Stock
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                        <div className={`mt-3 mb-1 col-2`}>
                                            <div className={`col-12`}>
                                                <button
                                                    className="w-50 mt-n2 mb-3 me-3 ms-3 fanzine-select bg-white text-black sfProDisplayFontBold font-22"
                                                    data-id={cartItem.node.variant.id}
                                                    onClick={incrementQuantity}>+
                                                </button>
                                                <input
                                                    className="form-control h-25 remove-control border-0 sfProDisplayFontBold font-32"
                                                    type="number"
                                                    onChange={(event => console.log('event =', event))}
                                                    id={`quantity${cartItem.node.variant.id}`}
                                                    style={{textAlign: "center"}}
                                                    value={cartItem.node.quantity}/>
                                                {cartItem.node.quantity === 1 ? (
                                                    <button
                                                        className="w-50 m-3 fanzine-select bg-grey text-black sfProDisplayFontBold font-22"
                                                        id={`decrement-btn${cartItem.node.variant.id}`}
                                                        data-id={cartItem.node.variant.id}
                                                        disabled="disabled"
                                                        onClick={decrementQuantity}>-
                                                    </button>
                                                ) : (
                                                    <button
                                                        className="w-50 m-3 fanzine-select bg-white text-black sfProDisplayFontBold font-22"
                                                        id={`decrement-btn${cartItem.node.variant.id}`}
                                                        onClick={decrementQuantity}
                                                        data-id={cartItem.node.variant.id}>-
                                                    </button>
                                                )}

                                            </div>
                                        </div>
                                        <div className={`mt-3 mb-1 col-2 d-flex align-items-center`}>
                                            <div className={`${textColor} font-xxl`}
                                                 id={`total${cartItem.node.variant.id}`}>
                                                £{Math.round((parseFloat(cartItem.node.variant.price?.amount) * cartItem.node.quantity) * 100) / 100}
                                            </div>
                                        </div>
                                        <div className="dropdown-divider"/>
                                    </>
                                ))}
                                <div className={`row mt-3`}>
                                    <div className={`col-12`}>
                                        <div className="input-group mb-3">
                                            {discount ? (
                                                <>
                                                    <input type="text"
                                                           className="sfProDisplayFont form-control text-center"
                                                           placeholder="Enter Discount Code"
                                                           id={`discountCodeMiniCheckout`}
                                                           aria-label="Enter Discount Code"
                                                           aria-describedby="button-addon2"
                                                           onChange={(event => console.log('event =', event))}
                                                           autoComplete="off" value={discountCode} disabled="disabled"/>
                                                    <button className="btn promo-code-redeem-btn sfProDisplayFont"
                                                            type="button"
                                                            id="button-addon2"
                                                            onClick={removePromoDiscount}>Remove
                                                    </button>
                                                </>
                                            ) : (
                                                <>
                                                    <input type="text"
                                                           className="sfProDisplayFont form-control text-center"
                                                           placeholder="Enter Discount Code"
                                                           id={`discountCodeMiniCheckout`}
                                                           aria-label="Enter Discount Code"
                                                           aria-describedby="button-addon2"
                                                           autoComplete="off"/>
                                                    <button className="btn promo-code-redeem-btn sfProDisplayFont"
                                                            type="button"
                                                            id="button-addon2"
                                                            onClick={handlePromoDiscount}>Redeem
                                                    </button>
                                                </>
                                            )}

                                        </div>
                                    </div>
                                </div>
                                <div className={`row mt-3`}>
                                    <div
                                        className={`col-6 font-18 text-gray d-flex justify-content-end align-items-center`}>
                                        Subtotal:
                                    </div>
                                    {shopifyCart.discountApplications.edges.length > 0 ? (
                                        <div className={`col-6 ${textColor} d-flex justify-content-end font-xxl`}>
                                            £{subtotal}
                                        </div>
                                    ) : (
                                        <div className={`col-6 ${textColor} d-flex justify-content-end font-xxl`}>
                                            £{subtotal}
                                        </div>
                                    )}
                                </div>
                                {shopifyCart.discountApplications.edges.length > 0 ? (
                                    <>
                                        <div className={`row mt-3`}>
                                            <div
                                                className={`col-6 font-18 text-gray d-flex justify-content-end align-items-center`}>
                                                Discount:
                                            </div>
                                            <div className={`col-6 d-flex justify-content-end font-18 text-black`}>
                                                -{discountValue}% {discountCode}
                                            </div>
                                        </div>
                                        <div className={`row mt-3`}>
                                            <div
                                                className={`col-6 font-18 text-gray d-flex justify-content-end align-items-center`}>
                                                Total:
                                            </div>
                                            <div className={`col-6 ${textColor} d-flex justify-content-end font-xxl`}>
                                                £{shopifyCart.subtotalPrice.amount}
                                            </div>
                                        </div>
                                    </>
                                ) : (
                                    <></>
                                )
                                }
                                <div className={`mt-3`}>
                                    <div
                                        className={`col-11 font-18 text-gray d-flex justify-content-end align-items-center`}>
                                        Exclusive Delivery*
                                    </div>
                                    {/*<div className={`col-11 font-18 text-gray d-flex justify-content-end align-items-center`}>*/}
                                    {/*    Shipping is calculated at checkout*/}
                                    {/*</div>*/}
                                </div>
                                <div className="mt-3 col-12 font-18">
                                    <button type="button" className={`btn ${bgColor} text-white btn-lg w-100`}
                                            onClick={handleCheckout}>
                                        Checkout Securely
                                        <span className={`float-end`}> > </span>
                                    </button>
                                </div>
                            </>
                        </div>
                    ) : (
                        <>
                            <div className={`bebasFont`}>
                                No Items found.
                            </div>
                        </>
                    )
                }
            </div>
        </>
    );
};
