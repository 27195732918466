import React, {useEffect, useState} from 'react';
import {Container} from 'react-bootstrap';
import FanzineNewsCard from '../../Components/Cards/FanzineNewsCard';
import {get} from '../../Axios/get';
import {NFL} from '../../Axios/EndPoints';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import {SCREEN_SIZE} from '../../Configs/Constants';
import Loader from '../../Components/Loader/Loader';
import '../../assets/styles/customContainer.css';
import {SiteMetaHelmet} from '../../Components/SiteMetaHelmet';
import GoogleAd from '../../Components/Ads/GoogleAd';
import TopMatchesSlider from '../../Components/Slider/TopMatchesSlider';
import {useSelector} from 'react-redux';
import {
    useLocation,
    useNavigate,
    useParams,
    useSearchParams,
} from 'react-router-dom';
import {SubNavbar} from '../../Components/Navbar/SubNavbar';
import LatestVideos from "../../Components/Layout/LatestSection/LatestVideos";
import SkySportsAd from "../../Components/Ads/SkySportsAd";
import NewsSectionHeading from "../../Components/NewsSectionHeading";

const FanzineNFL = props => {
    const navigation = useNavigate();
    const allSportsStore = useSelector(
        state => state.football.fanzineTeamsLeagues);
    const location = useLocation();
    let {team} = useParams();
    let [apiPage, setApiPage] = useState(1);
    let [apiLimit, setApiLimit] = useState(48);
    let [newsData, setNewsData] = useState([]);
    let [restrictApiCall, setRestrictApiCall] = useState(true);
    let [loading, setLoading] = useState(true);
    let [isMobile, setIsMobile] = useState(false);
    let [matchDataNFL, setMatchDataNFL] = useState([]);
    let [matchDataNFLLoading, setMatchDataNFLLoading] = useState(true);
    let [selectedTeam, setSelectedTeam] = useState(null);
    const [videoEndpoint, setVideoEndpoint] = useState(NFL.VideoEndPoint);

    const [searchParams] = useSearchParams();
    const handleScroll = (e) => {
        const pageBottom = document.body.scrollHeight -
            document.documentElement.clientHeight - 10 <=
            document.documentElement.scrollTop;
        if (pageBottom) {
            setApiPage((prevState) => {
                return prevState + 1;
            });
            setRestrictApiCall(false);
        }
    };

    const fetchNews = (isNew) => {
        setLoading(true);

        let id = (searchParams.get('team_id') || searchParams.get('league_id') ||
            selectedTeam?.id || '');
        let parameters = searchParams.get('league_id') ?
            '&league_id=' + id :
            searchParams.get('team_id') ?
                '&team_id=' + id :
                selectedTeam?.id ? '&team_id=' + selectedTeam?.id : '';


        if (location.pathname.split('/')?.length === 3 && parameters === '') {
            return
        }

        let page = `&page=${apiPage}`;
        let limit = `?limit=${apiLimit}`;

        let url = NFL.NewsEndPoint + limit + page + parameters;

        get(url)
            .then((responseNews) => {
                let tempNewsData = [];
                let tempData = [];
                responseNews.data.map((data) => {
                    let news = {
                        news_id: data.news_id,
                        slug: data.slug,
                        news_image: data.image,
                        news_title: data.title,
                        news_url: data.url,
                        news_time: moment.tz(data.datetime, 'Europe/London').local().startOf('seconds').fromNow(),
                        news_date: moment(data.datetime).format('D MMM YYYY, H:mm'),
                        news_publisher: {
                            publisher_name: data.publisher.name, publisher_icon: data.publisher.icon,
                        },
                        is_snack: data.is_snack,
                        type: 'news',
                    };
                    tempNewsData.push(news);
                });

                if (isNew) {
                    setNewsData(tempNewsData);
                } else {
                    setNewsData([...newsData, ...tempNewsData]);
                }
                setLoading(false);
            })
            .catch(error => console.log(error));
    }

    const fetchNFLFixtures = () => {

        let id = (searchParams.get('team_id') || searchParams.get('league_id') ||
            selectedTeam?.id || '');
        let parameters = searchParams.get('league_id') ?
            '?league_id=' + id :
            searchParams.get('team_id') ?
                '?team_id=' + id :
                selectedTeam?.id ? '?team_id=' + selectedTeam?.id : '';

        let URL = '';

        get(NFL.HomepageMatchesEndPoint + parameters)
            .then((response) => {
                let tempMatchData = [];
                // eslint-disable-next-line array-callback-return
                response.data.data.matches.map((data) => {
                    let match = {
                        id: data.id,
                        league_id: data.league_id,
                        leagueName: data.tournament,
                        state: data.state_id,
                        status: data.status,
                        date: data.date,
                        datetime: data.datetime,
                        time: data.time,
                        teams: {
                            home: {
                                name: data.local_team.name,
                                icon: data.local_team.icon,
                                score: data.local_team.total_score,
                            },
                            away: {
                                name: data.visitor_team.name,
                                icon: data.visitor_team.icon,
                                score: data.visitor_team.total_score,
                            },
                        },
                    };
                    tempMatchData.push(match);
                });
                setMatchDataNFL(tempMatchData);
                setMatchDataNFLLoading(false);
            })
            .catch((err) => {
                console.error(err);
            });
    };

    useEffect(() => {

        let sportName = location.pathname.split('/')[1];
        let teamName = location.pathname.split('/')[2];

        let selectedSport = [];
        let teamDataToBeSend = [];

        selectedSport = allSportsStore?.filter(sport => {
            return sport?.name?.toLowerCase() === sportName;
        });

        for (let i = 0; i < selectedSport[0]?.leagues?.length; i++) {
            for (let j = 0; j < selectedSport[0]?.leagues[i]?.teams?.length; j++) {
                if (selectedSport[0]?.leagues[i]?.teams[j].name.toLowerCase()
                        .replaceAll(' ', '-') ===
                    teamName) {
                    teamDataToBeSend = selectedSport[0]?.leagues[i]?.teams[j];
                }
            }
        }

        Object.assign(teamDataToBeSend, {
            sportIcon: selectedSport[0]?.icon,
            clubIcon: teamDataToBeSend?.icon,
            name: teamDataToBeSend.name || 'NFL'
        });


        setSelectedTeam(teamDataToBeSend || null);


    }, [allSportsStore, location.pathname]);


    useEffect(() => {
        setLoading(true);
        setRestrictApiCall(true);
        window.addEventListener('scroll', handleScroll);
        setApiPage(1);
        if (location.pathname.split('/')?.length === 2) {
            fetchNews(true);
        } else if (location.pathname.split('/')?.length === 3) {
            selectedTeam && fetchNews(true);
        }
        fetchNFLFixtures();
        selectedTeam && setVideoEndpoint(`${NFL.VideoEndPoint}?team_id=${selectedTeam.id}`);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [selectedTeam]);

    useEffect(() => {


    }, [videoEndpoint]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    // useEffect(() => {
    //     setLoading(true);
    //     !restrictApiCall && location.pathname?.split('/').length === 2 && fetchNews() && console.log('im called in length restrict');
    // }, [apiPage, restrictApiCall]);
    useEffect(() => {
        !restrictApiCall && fetchNews();
    }, [apiPage, restrictApiCall]);

    useEffect(() => {
        if(location.pathname === '/nfl/')
        {
            navigation('/nfl');
        }

        if (window.screen.width <= SCREEN_SIZE.MOBILE) {
            setIsMobile(true);
        } else {
            setIsMobile(false);
        }
    }, [window.screen.width, isMobile]);

    const renderNews = (news, sport, isMobile) => {
        return (
            <>
                {isMobile ? null : <TopMatchesSlider isMobile={isMobile} teamData={selectedTeam}/>}
                <Container className={`${isMobile &&
                'mt-xxl-5 mt-xl-5 mt-lg-5 mt-md-5 mt-sm-1 mt-2'} mb-5`}>
                    <div className={'customContainer'}>
                        <div className="row">
                            <div
                                className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                <div className={`row`}>
                                    <div
                                        className={`mt-4 col-xl-8 col-lg-8 col-md-8 col-sm-12 col-xs-12`}>
                                        <FanzineNewsCard isNewDesign={true} classProps={''}
                                                         news={news[0]} imageHeight={'510px'}
                                                         sport={'nfl'}/>
                                    </div>
                                    <div
                                        className={`mt-4 col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12`}>
                                        <div className={`col-12`}>
                                            <FanzineNewsCard isNewDesign={true} isMobile={isMobile}
                                                             classProps={''} news={news[1]}
                                                             sport={'nfl'}/>
                                        </div>
                                        <div className={`mt-2 pt-1 col-12`}>
                                            <FanzineNewsCard isNewDesign={true} isMobile={isMobile}
                                                             classProps={''} news={news[2]}
                                                             sport={'nfl'}/>
                                        </div>
                                    </div>
                                </div>
                                <div className={`row ${isMobile ? '' : 'd-flex justify-content-center'}`}>
                                    <div
                                        className={`mt-4 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12`}
                                    >
                                        <SkySportsAd/>
                                    </div>
                                    <div
                                        className={'col-lg-4 mt-3 col-xl-4 col-md-4 col-sm-12 col-xs-12'}>
                                        <FanzineNewsCard
                                            news={news[3]}
                                            imageHeight={'245px'}
                                            cardTextSize={''}
                                            isNewsPage={''}
                                            publisherIconSize={''}
                                            isMain={''}
                                        />
                                        <FanzineNewsCard
                                            news={news[4]}
                                            imageHeight={'245px'}
                                            cardTextSize={''}
                                            isNewsPage={''}
                                            publisherIconSize={''}
                                            isMain={''}
                                        />
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </Container>
                {<LatestVideos data={selectedTeam} videosEndpoint={videoEndpoint} sport={'nfl'}/>}
                {/*{!matchDataNFLLoading ? renderMatches(matchDataNFL, 'nfl') : renderMatchesSkeleton()}*/}
                <Container
                    className={`mt-xxl-5 mt-xl-5 mt-lg-5 mt-md-5 mt-sm-1 mt-2 mb-5`}>
                    <NewsSectionHeading title={'More Stories'}/>
                    <div className={'customContainer'}>

                        <div className="row">
                            <div
                                className="col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-sm-12">
                                <div className={`row`}>
                                    {
                                        newsData.map((news, index) => {
                                            if ([0, 1, 2, 3, 4].includes(index)) {
                                                return null;
                                            }
                                            return (
                                                <>
                                                    <div
                                                        className={`mt-3 pt-3 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12`}>
                                                        <FanzineNewsCard news={news}
                                                                         isNewsPage={''}
                                                                         sport={'nfl'}/>
                                                    </div>
                                                    {(index + 1) % 6 === 0 ?
                                                        (<div
                                                            className={'billboard d-flex justify-content-center flex-column align-items-center mt-4 mb-4 col-xxl-10 col-xl-10 col-lg-10' +
                                                                ' col-md-10 col-sm-10 offset-xxl-1 offset-xl-1 offset-lg-1 offset-md-1 offset-sm-1'}>
                                                            <GoogleAd id={'4009154580'}/>
                                                        </div>) : null}
                                                </>
                                            );
                                        })
                                    }
                                </div>
                            </div>
                            {renderRightAds(news)}
                        </div>
                    </div>
                </Container>
            </>
        );
    };
    const renderRightAds = (news, isUnlimited) => {

        return (
            <div className={'col-4 sticky-top'}>
                <GoogleAd id={'4009154580'}/>
                {
                    news.map((value, index) => {

                        if (!isUnlimited && index > 5) {
                            return '';
                        }

                        return (index % 3 !== 0) ? (
                                <div
                                    className={`col-12 ${index === 0 ? 'mt-n1' : 'mt-3'}`}
                                >
                                    <FanzineNewsCard
                                        news={value}
                                        imageHeight={'277px'}
                                        cardTextSize={''}
                                        isNewsPage={false}
                                        showNewsBelow={false}
                                        publisherIconSize={''}
                                        isMain={''}
                                    />
                                </div>
                            ) :
                            (
                                <GoogleAd id={'4009154580'}/>
                            );
                    })
                }
            </div>
        );
    };

    const render = () => {
        return (<>
            <SiteMetaHelmet sport={'NFL'} urlEndpoint={'nfl'}/>
            <Loader loading={loading}/>
            <SubNavbar isMobile={isMobile} teamData={selectedTeam} isClub={team} items={[]}/>
            <div className="d-flex justify-content-center align-items-center h1">
                <GoogleAd id={'4009154580'}/>
            </div>
            {newsData.length && renderNews(newsData, 'NFL', isMobile)}

        </>);
    };

    return render();
};

FanzineNFL.propTypes = {
    snack: PropTypes.bool,
};

export default FanzineNFL;
