import React, {useEffect, useState} from 'react';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import '../../assets/styles/customContainer.css';
import {useLocation, useParams, useSearchParams} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {getCountryForTimezone} from 'countries-and-timezones';
import axios from 'axios';
import {get} from "../../Axios/get";
import {FANZINE, NewsEndPoint} from "../../Axios/EndPoints";
import {MATCH_STATES, SCREEN_SIZE} from "../../Configs/Constants";
import CalendarContainer from "../../Components/Calendar/CalendarContainer";
import Matches from "../../Components/Layout/Football/Matches";
import {SiteMetaHelmet} from "../../Components/SiteMetaHelmet";
import Loader from "../../Components/Loader/Loader";
import {SubNavbar} from "../../Components/Navbar/SubNavbar";
import GoogleAd from "../../Components/Ads/GoogleAd";
import RightNewsAds from "../../Components/Layout/News/RightNewsAds";
import {GenderNavbar} from '../../Components/Navbar/GenderNavbar';
import GoogleAdManager from "../../Components/Ads/GoogleAdManager";

const FanzineAllFootballMatches = (props) => {
    let params = useParams();
    let store = useSelector((state) => state);
    // console.log("store in all football =", store);
    let queryParams = useLocation();
    const [searchParams, setSearchParams] = useSearchParams();

    let [apiPage, setApiPage] = useState(1);
    let [loading, setLoading] = useState(true);
    let [isMobile, setIsMobile] = useState(false);
    let [matchData, setMatchData] = useState(null);
    let [matchDataLoading, setMatchDataLoading] = useState(true);
    let [latestNews, setLatestNews] = useState([]);
    let [footballLeagues, setFootballLeagues] = useState(null);
    let [selectedLeague, setSelectedLeague] = useState(0);
    let [selectedDate, setSelectedDate] = useState(moment(Date.now()).format('YYYY-MM-DD'));
    let [selectedDatePicker, setSelectedDatePicker] = useState();
    let [liveMatchData, setLiveMatchData] = useState(null);
    let [isFanzineAllFootballMatches, setIsFanzineAllFootballMatches] = useState(
        true);
    let [leagueMatchesGroupedByDate, setLeagueMatchesGroupedByDate] = useState(
        null);
    let [selectedLeagueDate, setSelectedLeagueData] = useState(null);
    let [isLeagueSelected, setIsLeagueSelected] = useState(false);
    const [teamType, setTeamType] = useState('mens')


    const handleScroll = (e) => {
        const pageBottom =
        document.body.scrollHeight - document.documentElement.clientHeight -
        10 <=
        document.documentElement.scrollTop;
        if (pageBottom && document.body.scrollHeight > 1000) {
            setApiPage((prevState) => {
                return prevState + 1;
            });
        }
    };

    const fetchNews = (isNew) => {
        let searchParams = queryParams.search;

        if (!searchParams) {
            searchParams = '?main_site=1&snack=1';
        }

        let page = `&page=${apiPage}`;
        let limit = `&limit=48`;

        get(NewsEndPoint + searchParams + limit + page).then((responseNews) => {
            let tempNewsData = [];
            responseNews.data.map((data) => {
                let news = {
                    news_id: data.news_id,
                    slug: data.slug,
                    news_image: data.image,
                    news_title: data.title,
                    news_url: data.url,
                    news_time: moment.tz(data.datetime, 'Europe/London').local().startOf('seconds').fromNow(),
                    news_date: moment(data.datetime).format('D MMM YYYY, H:mm'),
                    news_publisher: {
                        publisher_name: data.publisher.name,
                        publisher_icon: data.publisher.icon,
                    },
                    is_snack: data.is_snack,
                    main_team: data.main_team,
                    type: 'news',
                };
                tempNewsData.push(news);
            });
            if (isNew) {
                setLatestNews(tempNewsData);
            } else {
                setLatestNews([...latestNews, ...tempNewsData]);
            }
            setLoading(false);
        },
        ).catch((error) => console.log(error));
    };

    const fetchFixtures = () => {
        let params = queryParams.search;
        if (!params) {
            params = `?date=${selectedDate ||
            moment(Date.now()).format('YYYY-MM-DD')}`;
        }
        let timezone = getCountryForTimezone(
            Intl.DateTimeFormat().resolvedOptions().timeZone);
        params = params + ('&team=' + teamType) + '&timezone=utc';

        let url = 'https://api2.fanzine.com/api-almet/v2.0/Football/matches' +
        params;

        axios.get(url).then((response) => {
            // eslint-disable-next-line array-callback-return
            let tempData = response.data;
            for (let i = 0; i < tempData.length; i++) {
                if (tempData[i].new_web_order == 0) {
                    tempData[i].new_web_order = '100'
                }
                if (tempData[i].league_id == 0) {
                    tempData[i].new_web_order = '1000'
                }
                for (let j = 0; j < tempData[i].matches.length; j++) {
                    if (tempData[i].matches[j].current_time_match !=='FT'){
                        tempData[i].matches[j].current_time_match += "'";
                    }
                    if (tempData[i].matches[j].current_time_match === ''){
                        tempData[i].matches[j].current_time_match = null;
                    }
                    // tempData[i].matches[j].current_time_match = !['', 'FT'].includes(tempData[i].matches[j].current_time_match) ? tempData[i].matches[j].current_time_match + "'" : null;
                }
            }
            tempData.sort(function (a, b) {
                return a.new_web_order - b.new_web_order;
            });
            tempData.pop()
            setMatchData(response?.data);
            setMatchDataLoading(false);
            setLoading(false);
        }).catch((err) => {
            console.error(err);
        });
    };

    const selectedDay = (e) => {
        let tmpDate = moment(e).format('YYYY-MM-DD');
        setSelectedDate(tmpDate);
        setSelectedDatePicker(e);
        console.log('tmpdate =', tmpDate);
    };

    const fetchLiveMatches = () => {
        let params = queryParams.search;
        if (!params) {
            params = `?date=${selectedDate ||
            moment(Date.now()).format('YYYY-MM-DD')}`;
        }

        let url = 'https://api2.fanzine.com/api-almet/v2.0/Football/matches/live' +
        params;
        axios.get(url).then((response) => {
            setLiveMatchData(response?.data);
            setMatchDataLoading(false);
        }).catch((err) => {
            console.error(err);
        });
    };

    const fetchLeaguesMatchesByDate = () => {
        let params = searchParams.get('league_id');

        if (params) {
            let timezone = getCountryForTimezone(
                Intl.DateTimeFormat().resolvedOptions().timeZone);
            params = params + '/matchesByDate?limit=1000&timezone=utc';
            // + timezone.timezones[0]

            get(FANZINE.MatchesByDateEndPoint + params).then((response) => {
                setLeagueMatchesGroupedByDate(response.data.matches);
                setIsLeagueSelected(true);
                setLoading(false);

            }).catch((err) => {
                console.error(err);
            });
        }

    };

    useEffect(() => {
        setSelectedDate(
            searchParams.get('date') || moment(new Date()).format('YYYY-MM-DD'));
        console.log('searchParams.get(\'date\') =',
            searchParams.get('date') || moment(new Date()).format('YYYY-MM-DD') +
            '  today');
        setLoading(true);
        window.addEventListener('scroll', handleScroll);
        setApiPage(1);
        fetchNews(true);
        searchParams.get('league_id') == null && fetchFixtures();
        return () => window.removeEventListener('scroll', handleScroll);
    }, [queryParams.search, selectedLeague, searchParams, teamType]);

    useEffect(() => {
        setFootballLeagues(store?.football?.fanzineLeagues);
    }, [store?.football?.fanzineLeagues]);

    useEffect(() => {
        if (window.screen.width <= SCREEN_SIZE.MOBILE) {
            setIsMobile(true);
        } else {
            setIsMobile(false);
        }
    }, [window.screen.width, isMobile]);

    useEffect(() => {
        fetchLeaguesMatchesByDate();
        // setIsLeagueSelected(true);
    }, [searchParams.get('league_id')]);

    useEffect(() => {
    }, [isLeagueSelected]);

    // fetching Live Matches After 10s
    useEffect(() => {
        let isParamDate = !!searchParams.get('date');

        let checkDateWith = isParamDate ? searchParams.get('date') : selectedDate;

        if (checkDateWith == moment(Date.now()).format('YYYY-MM-DD')) {
            console.log('today');
            const interval = setInterval(() => {
                fetchLiveMatches();
            }, 30000);

            return () => clearInterval(interval);
        }
    }, []);

    useEffect(() => {
    }, [isLeagueSelected]);

    const renderNews = (news, sport, isMobile) => {

        return (
            <div className="bg-background overflow-hidden">
            <div className={`${!isMobile ? 'container' : 'w-100'}`}>
            <div
            className={`${!isMobile ? 'row' : 'mb-5'}`}
            style={{marginLeft: '0%', marginRight: '0%'}}
            >
            <div className={`col-xl-8 col-lg-8 col-md-8 col-sm-12 col-xs-12 ${isMobile ? '' : ' border-radius-15px'} h-100`}>
            <div className={`${isMobile ? '' : 'mb-5'}`}>
            <div className={'customContainer'}>
            <div className={`row ${isMobile && 'me-auto ms-auto'}`}>
            {leagueMatchesGroupedByDate == null &&
            <CalendarContainer selectedDay={selectedDay}
            setSelectedDate={setSelectedDate}
            selectedDate={selectedDate}
            isMobile={isMobile}
            sport={'football'}
            />
        }
                                        {/*<GenderNavbar setParams={setTeamType} isMobile={isMobile}/>*/}
        <Matches
        isMobile={isMobile}
        setSelectedLeagueData={setSelectedLeagueData}
        selectedLeagueDate={selectedLeagueDate}
        isLeagueSelected={isLeagueSelected}
        setIsLeagueSelected={setIsLeagueSelected}
        setLeagueMatchesGroupedByDate={setLeagueMatchesGroupedByDate}
        leagueMatchesGroupedByDate={leagueMatchesGroupedByDate}
        selectedDate={selectedDate}
        liveMatchData={liveMatchData}
        matchData={matchData}
        store={store}
        matchStates={MATCH_STATES}
        />
        </div>
        </div>
        </div>
        </div>
        <RightNewsAds isMobile={isMobile} limit={25}/>
        </div>
        </div>
        </div>
        );
    };

    const render = () => {
        return (
        <>
        <SiteMetaHelmet sport={'Football'} urlEndpoint={'football'}/>
        <Loader loading={loading}/>
        <SubNavbar isMobile={isMobile} items={['news', 'teams', 'matches']}/>
            <GoogleAdManager isMobile={isMobile} id={isMobile ? '1704953268243' : '1704953333715'}/>
        {renderNews(latestNews, 'Football', isMobile)}
        </>
        );
    };

    return render();
};

FanzineAllFootballMatches.propTypes = {
    snack: PropTypes.bool,
};

export default FanzineAllFootballMatches;
