import {SiteMetaHelmet} from "../../Components/SiteMetaHelmet";

export const NFLContent = () => {
    return (
        <div className={`container mt-5`}>
            <SiteMetaHelmet sport={'NFL'} urlEndpoint={'nfl'}/>
            <h1 className={`bebasFont`}>
                American Football NFL News Headlines in the UK
            </h1>
            <div className={`sfProDisplayFont mt-3`}>
                From the Baltimore Ravens and Tampa Bay Buccaneers to the Kansas City Chiefs and the Philadelphia Eagles, we bring you the top American Football sports news and headlines right here in
                the UK. Our online news feed is refreshed 24/7 so as soon as the news drops, we’ll have it covered. Start your day with the NFL news headlines at Fanzine your one stop American
                Football news outlet right here in the UK.
            </div>
            <div className={`sfProDisplayFont mt-3`}>
                Whether you want the latest game reports, to find out the news as and when it happens, or just want to hear the gossip, we’ve got you covered. At Fanzine we bring you the freshest
                stories and latest news headlines hot off the press. As soon as the story breaks, you’ll find it here.
            </div>
            <div className={`bebasFont h3 mt-5`}>
                American Football sports news
            </div>
            <div className={`sfProDisplayFont mt-3`}>
                From the AFC to the NFC, we bring you the latest NFL news headlines from both conferences. Whether you’re rooting for the Cowboys, the 49ers, the Giants, or the Bears, we’ll bring you
                the stories that matter to you.
            </div>
            <div className={`sfProDisplayFont mt-3`}>
                On our site you’ll find a daily rundown of the most popular stories and NFL news headlines as well as team specific American football sports news. Stop browsing different news websites
                and get all the biggest stories in one place at Fanzine.
            </div>
            <div className={`bebasFont h3 mt-5`}>
                American Football news online
            </div>
            <div className={`sfProDisplayFont mt-3 mb-5`}>
                We bring you the NFL stories that really matter to UK fans and the biggest headlines from the best writers. We collate news from the most trustworthy sources to bring you all of the
                highlights from the world of the NFL. Our news feed is updated 24/7 so as soon as the stories break you’ll see them on our website.
            </div>
        </div>
    )
}
