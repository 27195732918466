import twitterLogo from '../../assets/images/twitterLogo.png';
import fbLogo from '../../assets/images/fbLogo.png';
import instaLogo from '../../assets/images/instaLogo.png';
import fourMillionFans from '../../assets/images/fourpointeightMillion.png';
import fiveMillionFans from '../../assets/images/fivepointtwoMillion.png';
import BiggestAccounts from './BiggestAccounts';
import {useEffect, useState} from 'react';

const ReachTop = (props) => {
  const {isMobile} = props;
  const [backgroundSize, setBackGroundSize] = useState('950px');

  useEffect(() => {
    if (window.screen.width >= 2550) {
      setBackGroundSize('1000px');
    } else if (window.screen.width >= 1360 && window.screen.width < 1920) {
      setBackGroundSize('850px');
    }
  }, [window.screen.width]);
  const renderDesktopView = () => {
    return (
        <div
            style={{
              height: backgroundSize,
            }}
            className={'container-fluid blackBackgroundTwo flex-grow-1 '}>
          <div className={'container '}>
            <div className={'row '}>
              <div className={'col-md-12 col-lg-12 col-xl-12 col-xxl-12 mt-5'}>
                <div
                    style={{
                      fontWeight: 'bold',
                    }}
                    className={'row Montserrat-Bold justify-content-center'}>
                <span
                    className={'font-fanzine Montserrat-Bold font-50 text-center'}>SOCIAL <span
                    className={'text-white font-50 Montserrat-Bold text-center'}
                >REACH</span></span>
                </div>
              </div>
            </div>
            <div className={'row justify-content-evenly align-items-center'}>
              <div
                  className={'col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6'}>
                <div className={'row'}>
                  <div className={'col-md-4 col-lg-4 col-xl-4 col-xxl-4'}>
                    <div className={'row justify-content-center'}>
                      <img
                          src={twitterLogo}
                          alt={'twitter logo'}
                          className={'w-50'}
                      />
                      <p style={{fontWeight: 'bold'}}
                         className={'font-58 text-white Montserrat-Bold '}>+2.2M</p>
                      <p
                          className={
                            'font-20 text-center Montserrat-Regular font-fanzine text-center align-self-center'
                          }
                      >
                        TWITTER
                      </p>
                    </div>
                  </div>
                  <div className={'col-md-4 col-lg-4 col-xl-4 col-xxl-4'}>
                    <div className={'row justify-content-center'}>
                      <img src={fbLogo} alt={'Fb Logo'} className={'w-50'}/>
                      <p style={{fontWeight: 'bold'}}
                         className={'font-58  text-white Montserrat-Bold '}>+1.5M</p>
                      <p className={'font-20 text-center Montserrat-Regular font-fanzine'}>FACEBOOK</p>
                    </div>
                  </div>
                  <div className={'col-md-4 col-lg-4 col-xl-4 col-xxl-4'}>
                    <div className={'row justify-content-center'}>
                      <img
                          src={instaLogo}
                          alt={'instagram logo'}
                          className={'w-50'}
                      />
                      <p style={{fontWeight: 'bold'}}
                         className={'font-58 text-white Montserrat-Bold '}>+700K</p>
                      <p className={'font-20 text-center Montserrat-Regular font-fanzine'}>INSTAGRAM</p>
                    </div>
                  </div>
                </div>
                <div className={'row'}>
                  <div
                      className={
                        'row mt-4 justify-content-center align-items-center'
                      }
                  >
                    <div className={'col-3'}>
                      <hr style={{color: 'white'}}/>
                    </div>
                    <div className={'col-4'}>
                      <div className={'row'}>
                      <span
                          style={{fontWeight: 'bold'}}
                          className={'font-64 text-white Montserrat-Bold text-center'}>
                        446M
                      </span>
                        <span
                            className={'font-21 Montserrat-Regular font-fanzine text-center'}>
                        IMPRESSIONS
                      </span>
                      </div>
                    </div>
                    <div className={'col-3'}>
                      <hr style={{color: 'white'}}/>
                    </div>
                  </div>
                </div>
              </div>

              <div
                  className={'col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6'}>
                <div className={'row'}>
                  <img
                      src={fiveMillionFans}
                      alt={'4M fans'}
                      className={'img-fluid'}
                  />
                </div>
              </div>

              <div className={'row align-items-center pt-2 pb-2'}>
                <div className={'col-3'}>
                  <hr style={{color: 'white', width: '100%'}}/>
                </div>
                <div
                    className={'col-3 justify-content-center text-center d-flex'}>
                <span style={{
                  fontWeight: 'bold',
                }}
                      className={'font-26 text-nowrap Montserrat-Regular text-white'}>
                  BIGGEST ACCOUNTS
                </span>
                </div>
                <div className={'col-6'}>
                  <hr style={{color: 'white', width: '100%'}}/>
                </div>
              </div>
              <div className={'row align-items-center'}>
                <BiggestAccounts teamName={'Fanzine'}
                                 teamHandle={'All football'}/>
                <BiggestAccounts teamName={'Gunners'} teamHandle={'Arsenal'}/>
                <BiggestAccounts teamName={'CFC-Blues'} teamHandle={'Chelsea'}/>
                <BiggestAccounts teamName={'ManCityzens'}
                                 teamHandle={'Manchester City'}/>
                <BiggestAccounts teamName={'TheKop'} teamHandle={'Liverpool'}/>
                <BiggestAccounts teamName={'COYS'} teamHandle={'Tottenham'}/>
              </div>
            </div>
          </div>
        </div>
    );
  };
  const renderMobileView = () => {
    return (
        <div className={'container-fluid blackBackgroundTwo flex-grow-1'}>
          <div className={'container'}>
            <div className={'row pt-5'}>
              <div className={'col-xs-12 col-sm-12'}>
                <div className={'row pb-4'}>
                <span
                    className={'font-fanzine font-30 Montserrat-Bold text-center'}
                >
                  SOCIAL
                  &nbsp;
                  <span
                      className={'text-white font-30 Montserrat-Bold text-center'}
                  >
                  REACH
                </span>

                </span>
                </div>
                <div className={'row justify-content-between mt-3'}>
                  <div className={'col-3'}>
                    <img
                        style={{
                          height: 41,
                          width: 41,
                          objectFit: 'contain',
                        }}
                        src={twitterLogo}
                        alt={'twitter logo'}
                        className={'w-100 ms-auto me-auto'}
                    />
                    <div className={'row'}>
                      <span
                          className={'font-26 text-white Montserrat-Bold text-center'}>+2.2M</span>
                      <span
                          className={'font-9 text-white Montserrat-Regular text-center'}>
                      TWITTER
                    </span>
                    </div>
                  </div>
                  <div className={'col-3'}>
                    <img
                        style={{
                          height: 41,
                          width: 41,
                          objectFit: 'contain',
                        }}
                        src={fbLogo} alt={'Fb Logo'}
                        className={'w-100 ms-auto me-auto'}
                    />
                    <div className={'row'}>
                      <span
                          className={'font-26 text-white Montserrat-Bold text-center'}>+1.5M</span>
                      <span
                          className={'font-9 text-white Montserrat-Regular text-center'}>
                      FACEBOOK
                    </span>
                    </div>
                  </div>
                  <div className={'col-3'}>
                    <img
                        style={{
                          height: 41,
                          width: 41,
                          objectFit: 'contain',
                        }}
                        src={instaLogo}
                        alt={'instagram logo'}
                        className={'w-100 ms-auto me-auto'}
                    />
                    <div className={'row'}>
                      <span
                          className={'font-26 text-white Montserrat-Bold text-center'}>+700K</span>
                      <span
                          className={'font-9 text-white Montserrat-Regular text-center'}>
                      INSTAGRAM
                    </span>
                    </div>
                  </div>
                </div>

                <div
                    className={'row mt-4 justify-content-center align-items-center'}
                >
                  <div className={'col-3'}>
                    <hr style={{color: 'white', padding: '0px'}}/>
                  </div>
                  <div className={'col-3'}>
                    <div className={'row'}>
                    <span className={'font-26 text-white Montserrat-Bold text-center'}>
                      446M
                    </span>
                      <span className={'font-8 text-white Montserrat-Regular text-center'}>
                      IMPRESSIONS
                    </span>
                    </div>
                  </div>
                  <div className={'col-3'}>
                    <hr style={{color: 'white'}}/>
                  </div>
                </div>

                <div className={'row mt-3'}>
                  <div className={'col-xs-12 col-sm-12'}>
                    <div className={'row justify-content-center'}>
                      <img
                          style={{
                            height: 392,
                            width: 368,
                            objectFit: 'cover',
                          }}
                          src={fiveMillionFans}
                          alt={'5.2 million fans'}
                          className={'w-100'}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    );
  };

  const renderMain = () => {
    return isMobile ? renderMobileView() : renderDesktopView();
  };

  return renderMain();
};

export default ReachTop;
